// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesMobileBg2 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)', // Replace all <stop id="...-stroke-1" stopColor="#initial-color" /> with this value
  };

  return (
    <AnimatedSvg
      id="eaFI8OTBWJd1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 320 250"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="320"
      height="250"
      className="source-background"
      {...props}
    >
      <defs>
        <linearGradient
          id="eaFI8OTBWJd7-stroke"
          x1="0.311309"
          y1="0.695205"
          x2="0.32485"
          y2="0.997707"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eaFI8OTBWJd7-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eaFI8OTBWJd7-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <filter id="eaFI8OTBWJd8-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eaFI8OTBWJd8-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eaFI8OTBWJd8-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eaFI8OTBWJd8-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eaFI8OTBWJd8-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eaFI8OTBWJd8-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eaFI8OTBWJd8-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eaFI8OTBWJd8-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eaFI8OTBWJd9-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="matrix(-161.522399 0.860628 -0.860628 -161.522399 11609.180447 0.063086)"
        >
          <stop
            id="eaFI8OTBWJd9-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eaFI8OTBWJd9-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="eaFI8OTBWJd10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eaFI8OTBWJd10-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eaFI8OTBWJd10-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eaFI8OTBWJd10-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eaFI8OTBWJd10-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eaFI8OTBWJd10-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eaFI8OTBWJd10-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eaFI8OTBWJd10-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eaFI8OTBWJd10-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eaFI8OTBWJd12-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eaFI8OTBWJd12-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eaFI8OTBWJd12-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eaFI8OTBWJd12-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eaFI8OTBWJd12-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eaFI8OTBWJd12-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eaFI8OTBWJd12-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eaFI8OTBWJd12-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eaFI8OTBWJd13-stroke"
          x1="7990.916788"
          y1="1.03934"
          x2="7990.916788"
          y2="0.074521"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eaFI8OTBWJd13-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="eaFI8OTBWJd13-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eaFI8OTBWJd14-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eaFI8OTBWJd14-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eaFI8OTBWJd14-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eaFI8OTBWJd14-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eaFI8OTBWJd14-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eaFI8OTBWJd14-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eaFI8OTBWJd14-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eaFI8OTBWJd14-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eaFI8OTBWJd14-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eaFI8OTBWJd16-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eaFI8OTBWJd16-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eaFI8OTBWJd16-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eaFI8OTBWJd16-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eaFI8OTBWJd16-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eaFI8OTBWJd16-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eaFI8OTBWJd16-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eaFI8OTBWJd16-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eaFI8OTBWJd17-stroke"
          x1="11432.251334"
          y1="1"
          x2="11806.96777"
          y2="0.092287"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eaFI8OTBWJd17-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="eaFI8OTBWJd17-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eaFI8OTBWJd18-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eaFI8OTBWJd18-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eaFI8OTBWJd18-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eaFI8OTBWJd18-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eaFI8OTBWJd18-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eaFI8OTBWJd18-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eaFI8OTBWJd18-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eaFI8OTBWJd18-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eaFI8OTBWJd18-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <linearGradient
          id="eaFI8OTBWJd21-stroke"
          x1="0.5"
          y1="0.690629"
          x2="0.5"
          y2="0.997707"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eaFI8OTBWJd21-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eaFI8OTBWJd21-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <filter id="eaFI8OTBWJd22-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eaFI8OTBWJd22-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eaFI8OTBWJd22-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eaFI8OTBWJd22-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eaFI8OTBWJd22-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eaFI8OTBWJd22-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eaFI8OTBWJd22-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eaFI8OTBWJd22-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eaFI8OTBWJd23-stroke"
          x1="5940.262673"
          y1="1.161515"
          x2="5940.262673"
          y2="0.096616"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eaFI8OTBWJd23-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="eaFI8OTBWJd23-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eaFI8OTBWJd24-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eaFI8OTBWJd24-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eaFI8OTBWJd24-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eaFI8OTBWJd24-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eaFI8OTBWJd24-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eaFI8OTBWJd24-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eaFI8OTBWJd24-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eaFI8OTBWJd24-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eaFI8OTBWJd24-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eaFI8OTBWJd26-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eaFI8OTBWJd26-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eaFI8OTBWJd26-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eaFI8OTBWJd26-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eaFI8OTBWJd26-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eaFI8OTBWJd26-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eaFI8OTBWJd26-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eaFI8OTBWJd26-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eaFI8OTBWJd27-stroke"
          x1="5940.262673"
          y1="1.161515"
          x2="5940.262673"
          y2="0.096616"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eaFI8OTBWJd27-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="eaFI8OTBWJd27-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eaFI8OTBWJd28-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eaFI8OTBWJd28-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eaFI8OTBWJd28-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eaFI8OTBWJd28-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eaFI8OTBWJd28-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eaFI8OTBWJd28-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eaFI8OTBWJd28-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eaFI8OTBWJd28-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eaFI8OTBWJd28-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <linearGradient
          id="eaFI8OTBWJd31-stroke"
          x1="0.5"
          y1="0.690629"
          x2="0.5"
          y2="0.997707"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eaFI8OTBWJd31-stroke-0" offset="0%" stopColor="#6069ca" />
          <stop id="eaFI8OTBWJd31-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <filter id="eaFI8OTBWJd32-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eaFI8OTBWJd32-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eaFI8OTBWJd32-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eaFI8OTBWJd32-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eaFI8OTBWJd32-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eaFI8OTBWJd32-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eaFI8OTBWJd32-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eaFI8OTBWJd32-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eaFI8OTBWJd33-stroke"
          x1="5940.262673"
          y1="1.161515"
          x2="5940.262673"
          y2="0.096616"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eaFI8OTBWJd33-stroke-0" offset="0%" stopColor="rgba(36,99,84,0)" />
          <stop id="eaFI8OTBWJd33-stroke-1" offset="100%" stopColor="#48e8c0" />
        </linearGradient>
        <filter id="eaFI8OTBWJd34-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eaFI8OTBWJd34-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eaFI8OTBWJd34-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eaFI8OTBWJd34-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eaFI8OTBWJd34-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eaFI8OTBWJd34-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eaFI8OTBWJd34-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eaFI8OTBWJd34-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eaFI8OTBWJd34-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eaFI8OTBWJd36-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eaFI8OTBWJd36-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eaFI8OTBWJd36-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eaFI8OTBWJd36-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eaFI8OTBWJd36-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eaFI8OTBWJd36-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eaFI8OTBWJd36-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eaFI8OTBWJd36-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eaFI8OTBWJd37-stroke"
          x1="5940.262673"
          y1="1.161515"
          x2="5940.262673"
          y2="0.096616"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eaFI8OTBWJd37-stroke-0" offset="0%" stopColor="rgba(36,99,84,0)" />
          <stop id="eaFI8OTBWJd37-stroke-1" offset="100%" stopColor="#48e8c0" />
        </linearGradient>
        <filter id="eaFI8OTBWJd38-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eaFI8OTBWJd38-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eaFI8OTBWJd38-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eaFI8OTBWJd38-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eaFI8OTBWJd38-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eaFI8OTBWJd38-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eaFI8OTBWJd38-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eaFI8OTBWJd38-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eaFI8OTBWJd38-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
      </defs>
      <g transform="translate(-22-2.25894)" mask="url(#eaFI8OTBWJd40)">
        <g transform="translate(.000001 0)">
          <g transform="translate(.088013 1.012683)">
            <g transform="matrix(-1 0 0 1 374.225189 0)">
              <path
                d="M96.7109,218.88v-97.202l35.869585-44.145397L132.817,0.34375"
                fill="none"
                stroke="url(#eaFI8OTBWJd7-stroke)"
              />
              <g transform="translate(-64 105.000001)" filter="url(#eaFI8OTBWJd8-filter)">
                <path
                  id="eaFI8OTBWJd9"
                  d="M96.711985,215.439636L96.7109,127.987317h.001085c0,0,0,0,0,0"
                  transform="translate(64-105.000001)"
                  fill="none"
                  stroke="url(#eaFI8OTBWJd9-stroke)"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="eaFI8OTBWJd10_to" transform="translate(160.8,22.504306)">
                  <g id="eaFI8OTBWJd10_tr" transform="rotate(0)">
                    <rect
                      id="eaFI8OTBWJd10"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0.7"
                      filter="url(#eaFI8OTBWJd10-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eaFI8OTBWJd11_to" transform="translate(160.8,22.504307)">
                  <g id="eaFI8OTBWJd11_tr" transform="rotate(0)">
                    <rect
                      id="eaFI8OTBWJd11"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-64 105.000001)" filter="url(#eaFI8OTBWJd12-filter)">
                <path
                  id="eaFI8OTBWJd13"
                  d="M96.711985,295.439636L96.7109,227.987317h.001085c0,0,0,0,0,0"
                  transform="translate(64-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eaFI8OTBWJd13-stroke)"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="eaFI8OTBWJd14_to" transform="translate(160.8,126.504306)">
                  <g id="eaFI8OTBWJd14_tr" transform="rotate(0)">
                    <rect
                      id="eaFI8OTBWJd14"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eaFI8OTBWJd14-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eaFI8OTBWJd15_to" transform="translate(160.8,126.504307)">
                  <g id="eaFI8OTBWJd15_tr" transform="rotate(0)">
                    <rect
                      id="eaFI8OTBWJd15"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-64 105.000001)" filter="url(#eaFI8OTBWJd16-filter)">
                <path
                  id="eaFI8OTBWJd17"
                  d="M96.711985,275.439636L96.7109,227.987317h.001085c0,0,0,0,0,0"
                  transform="translate(64-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eaFI8OTBWJd17-stroke)"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="eaFI8OTBWJd18_to" transform="translate(160.8,126.504306)">
                  <rect
                    id="eaFI8OTBWJd18"
                    width="7"
                    height="12"
                    rx="0"
                    ry="0"
                    transform="translate(-3.5,-6)"
                    opacity="0"
                    filter="url(#eaFI8OTBWJd18-filter)"
                    fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eaFI8OTBWJd19_to" transform="translate(160.8,126.504307)">
                  <rect
                    id="eaFI8OTBWJd19"
                    width="10"
                    height="20"
                    rx="0"
                    ry="0"
                    transform="scale(0.5,0.5) translate(-5,-10)"
                    opacity="0"
                    fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
              </g>
            </g>
            <g transform="translate(-10.999999 0)">
              <path
                d="M96.7109,218.88v-97.202l35.869585-44.145397L132.817,0.34375"
                fill="none"
                stroke="url(#eaFI8OTBWJd21-stroke)"
              />
              <g transform="translate(-64 105.000001)" filter="url(#eaFI8OTBWJd22-filter)">
                <path
                  id="eaFI8OTBWJd23"
                  d="M96.711985,302.439636L96.7109,234.987317h.001085c0,0,0,0,0,0"
                  transform="translate(64-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eaFI8OTBWJd23-stroke)"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="eaFI8OTBWJd24_to" transform="translate(160.8,124.504306)">
                  <g id="eaFI8OTBWJd24_tr" transform="rotate(0)">
                    <rect
                      id="eaFI8OTBWJd24"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eaFI8OTBWJd24-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eaFI8OTBWJd25_to" transform="translate(160.8,124.504307)">
                  <g id="eaFI8OTBWJd25_tr" transform="rotate(0)">
                    <rect
                      id="eaFI8OTBWJd25"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-64 105.000001)" filter="url(#eaFI8OTBWJd26-filter)">
                <path
                  id="eaFI8OTBWJd27"
                  d="M96.711985,302.439636L96.7109,234.987317h.001085c0,0,0,0,0,0"
                  transform="translate(64-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eaFI8OTBWJd27-stroke)"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="eaFI8OTBWJd28_to" transform="translate(160.8,124.504306)">
                  <g id="eaFI8OTBWJd28_tr" transform="rotate(0)">
                    <rect
                      id="eaFI8OTBWJd28"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eaFI8OTBWJd28-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eaFI8OTBWJd29_to" transform="translate(160.8,124.504306)">
                  <g id="eaFI8OTBWJd29_tr" transform="rotate(0)">
                    <rect
                      id="eaFI8OTBWJd29"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <mask id="eaFI8OTBWJd40" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="825.762291"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #eaFI8OTBWJd9 {
    animation-name: eaFI8OTBWJd9__m, eaFI8OTBWJd9_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eaFI8OTBWJd9__m {
    0% {
      d: path(
        'M96.711985,215.439636L96.7109,127.987317L96.711985,127.987317C96.711985,127.987317,96.711985,127.987317,96.711985,127.987317'
      );
    }
    1.25% {
      d: path(
        'M96.7109,175.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,97.711985,120.683001,97.711985,120.683001'
      );
    }
    8.75% {
      d: path(
        'M96.7109,135.000465L96.7109,121.68L111.798426,103.148788C111.798426,103.148788,111.798426,103.148788,111.798426,103.148788'
      );
    }
    16.25% {
      d: path(
        'M96.7109,121.678L96.7109,121.678L132.580485,77.671631C132.580485,77.671631,132.580485,77.67163,132.580485,77.67163'
      );
    }
    30% {
      d: path(
        'M132.580485,77.671631L132.580485,77.671631L132.580485,77.671631C132.580485,77.671631,132.580485,-3.79188,132.580485,-3.79188'
      );
    }
    100% {
      d: path(
        'M132.580485,77.671631L132.580485,77.671631L132.580485,77.671631C132.580485,77.671631,132.580485,-3.79188,132.580485,-3.79188'
      );
    }
  }
  @keyframes eaFI8OTBWJd9_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    28.75% {
      opacity: 1;
    }
    33.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eaFI8OTBWJd9-stroke {
    animation: eaFI8OTBWJd9-stroke__t 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd9-stroke__t {
    0% {
      transform: matrix(-161.522399, 0.860628, -0.860628, -161.522399, 11609.180447, 0.063086);
    }
    1.25% {
      transform: matrix(-0.828244, 1.143485, -1.143485, -0.828244, 1.712586, 0.188391);
    }
    100% {
      transform: matrix(-0.828244, 1.143485, -1.143485, -0.828244, 1.712586, 0.188391);
    }
  }
  #eaFI8OTBWJd9-stroke-0 {
    animation: eaFI8OTBWJd9-stroke-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd9-stroke-0__c {
    0% {
      stop-color: #7ea6ff;
    }
    1.25% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #eaFI8OTBWJd9-stroke-1 {
    animation: eaFI8OTBWJd9-stroke-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd9-stroke-1__c {
    0% {
      stop-color: rgba(126, 166, 255, 0);
    }
    1.25% {
      stop-color: rgba(126, 166, 255, 0);
    }
    100% {
      stop-color: rgba(126, 166, 255, 0);
    }
  }
  #eaFI8OTBWJd10_to {
    animation: eaFI8OTBWJd10_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd10_to__to {
    0% {
      transform: translate(160.8px, 22.504306px);
    }
    1.25% {
      transform: translate(163.798427px, 12.502826px);
    }
    16.25% {
      transform: translate(193.801035px, -23.911387px);
    }
    17.5% {
      transform: translate(196.811985px, -34.712684px);
    }
    30% {
      transform: translate(196.811985px, -114.712684px);
    }
    100% {
      transform: translate(196.811985px, -114.712684px);
    }
  }
  #eaFI8OTBWJd10_tr {
    animation: eaFI8OTBWJd10_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd10_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(39deg);
    }
    16.25% {
      transform: rotate(39deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eaFI8OTBWJd10 {
    animation: eaFI8OTBWJd10_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd10_c_o {
    0% {
      opacity: 0.7;
    }
    30% {
      opacity: 0.7;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eaFI8OTBWJd11_to {
    animation: eaFI8OTBWJd11_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd11_to__to {
    0% {
      transform: translate(160.8px, 22.504307px);
    }
    1.25% {
      transform: translate(163.798427px, 12.502826px);
    }
    16.25% {
      transform: translate(193.801035px, -23.891881px);
    }
    17.5% {
      transform: translate(196.851035px, -34.791881px);
    }
    30% {
      transform: translate(196.851035px, -114.791881px);
    }
    100% {
      transform: translate(196.851035px, -114.791881px);
    }
  }
  #eaFI8OTBWJd11_tr {
    animation: eaFI8OTBWJd11_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd11_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(39deg);
    }
    16.25% {
      transform: rotate(39deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eaFI8OTBWJd11 {
    animation: eaFI8OTBWJd11_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd11_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eaFI8OTBWJd13 {
    animation-name: eaFI8OTBWJd13__m, eaFI8OTBWJd13_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eaFI8OTBWJd13__m {
    0% {
      d: path(
        'M96.711985,295.439636L96.7109,227.987317L96.711985,227.987317C96.711985,227.987317,96.711985,227.987317,96.711985,227.987317'
      );
    }
    35% {
      d: path(
        'M96.711985,295.439636L96.7109,227.987317L96.711985,227.987317C96.711985,227.987317,96.711985,227.987317,96.711985,227.987317'
      );
    }
    46.25% {
      d: path(
        'M96.711985,215.439636L96.7109,127.987317L96.711985,127.987317C96.711985,127.987317,96.711985,127.987317,96.711985,127.987317'
      );
    }
    47.5% {
      d: path(
        'M96.7109,175.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.711985,121.683001,96.711985,121.683001'
      );
    }
    62.5% {
      d: path(
        'M96.7109,121.678L96.7109,121.678L132.580485,77.671631C132.580485,77.671631,132.580485,77.67163,132.580485,77.67163'
      );
    }
    76.25% {
      d: path(
        'M132.580485,77.671631L132.580485,77.671631L132.580485,77.671631C132.580485,77.671631,132.580485,-3.79188,132.580485,-3.79188'
      );
    }
    100% {
      d: path(
        'M132.580485,77.671631L132.580485,77.671631L132.580485,77.671631C132.580485,77.671631,132.580485,-3.79188,132.580485,-3.79188'
      );
    }
  }
  @keyframes eaFI8OTBWJd13_c_o {
    0% {
      opacity: 0;
    }
    38.75% {
      opacity: 0;
    }
    43.75% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    76.25% {
      opacity: 1;
    }
    77.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eaFI8OTBWJd14_to {
    animation: eaFI8OTBWJd14_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd14_to__to {
    0% {
      transform: translate(160.8px, 126.504306px);
    }
    35% {
      transform: translate(160.8px, 126.504306px);
    }
    46.25% {
      transform: translate(160.8px, 24.504306px);
    }
    47.5% {
      transform: translate(164.798427px, 11.502826px);
    }
    62.5% {
      transform: translate(193.801035px, -23.911387px);
    }
    63.75% {
      transform: translate(196.811985px, -34.712684px);
    }
    76.25% {
      transform: translate(196.811985px, -114.712684px);
    }
    100% {
      transform: translate(196.811985px, -114.712684px);
    }
  }
  #eaFI8OTBWJd14_tr {
    animation: eaFI8OTBWJd14_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd14_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    46.25% {
      transform: rotate(0deg);
    }
    47.5% {
      transform: rotate(39deg);
    }
    62.5% {
      transform: rotate(39deg);
    }
    63.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eaFI8OTBWJd14 {
    animation: eaFI8OTBWJd14_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd14_c_o {
    0% {
      opacity: 0;
    }
    37.5% {
      opacity: 0;
    }
    42.5% {
      opacity: 0.7;
    }
    46.25% {
      opacity: 0.7;
    }
    76.25% {
      opacity: 0.7;
    }
    77.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eaFI8OTBWJd15_to {
    animation: eaFI8OTBWJd15_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd15_to__to {
    0% {
      transform: translate(160.8px, 126.504307px);
    }
    35% {
      transform: translate(160.8px, 126.504307px);
    }
    46.25% {
      transform: translate(160.8px, 24.504307px);
    }
    47.5% {
      transform: translate(164.798427px, 11.502826px);
    }
    62.5% {
      transform: translate(193.801035px, -23.891881px);
    }
    63.75% {
      transform: translate(196.851035px, -34.791881px);
    }
    76.25% {
      transform: translate(196.851035px, -114.791881px);
    }
    100% {
      transform: translate(196.851035px, -114.791881px);
    }
  }
  #eaFI8OTBWJd15_tr {
    animation: eaFI8OTBWJd15_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    46.25% {
      transform: rotate(0deg);
    }
    47.5% {
      transform: rotate(39deg);
    }
    62.5% {
      transform: rotate(39deg);
    }
    63.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eaFI8OTBWJd15 {
    animation: eaFI8OTBWJd15_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd15_c_o {
    0% {
      opacity: 0;
    }
    37.5% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    46.25% {
      opacity: 1;
    }
    76.25% {
      opacity: 1;
    }
    77.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eaFI8OTBWJd17 {
    animation-name: eaFI8OTBWJd17__m, eaFI8OTBWJd17_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eaFI8OTBWJd17__m {
    0% {
      d: path(
        'M96.711985,275.439636L96.7109,227.987317L96.711985,227.987317C96.711985,227.987317,96.711985,227.987317,96.711985,227.987317'
      );
    }
    88.75% {
      d: path(
        'M96.711985,275.439636L96.7109,227.987317L96.711985,227.987317C96.711985,227.987317,96.711985,227.987317,96.711985,227.987317'
      );
    }
    100% {
      d: path(
        'M96.711985,209.439636L96.7109,127.987317L96.711985,127.987317C96.711985,127.987317,96.711985,127.987317,96.711985,127.987317'
      );
    }
  }
  @keyframes eaFI8OTBWJd17_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  #eaFI8OTBWJd18_to {
    animation: eaFI8OTBWJd18_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd18_to__to {
    0% {
      transform: translate(160.8px, 126.504306px);
    }
    88.75% {
      transform: translate(160.8px, 126.504306px);
    }
    100% {
      transform: translate(160.8px, 22.504306px);
    }
  }
  #eaFI8OTBWJd18 {
    animation: eaFI8OTBWJd18_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd18_c_o {
    0% {
      opacity: 0;
    }
    91.25% {
      opacity: 0;
    }
    96.25% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #eaFI8OTBWJd19_to {
    animation: eaFI8OTBWJd19_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd19_to__to {
    0% {
      transform: translate(160.8px, 126.504307px);
    }
    88.75% {
      transform: translate(160.8px, 126.504307px);
    }
    100% {
      transform: translate(160.8px, 22.504307px);
    }
  }
  #eaFI8OTBWJd19 {
    animation: eaFI8OTBWJd19_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd19_c_o {
    0% {
      opacity: 0;
    }
    91.25% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eaFI8OTBWJd23 {
    animation-name: eaFI8OTBWJd23__m, eaFI8OTBWJd23_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eaFI8OTBWJd23__m {
    0% {
      d: path(
        'M96.711985,302.439636L96.7109,234.987317L96.711985,234.987317C96.711985,234.987317,96.711985,234.987317,96.711985,234.987317'
      );
    }
    5% {
      d: path(
        'M96.711985,302.439636L96.7109,234.987317L96.711985,234.987317C96.711985,234.987317,96.711985,234.987317,96.711985,234.987317'
      );
    }
    18.75% {
      d: path(
        'M96.711985,195.439636L96.7109,127.987317L96.711985,127.987317C96.711985,127.987317,96.711985,127.987317,96.711985,127.987317'
      );
    }
    20% {
      d: path(
        'M96.7109,175.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.711985,121.683001,96.711985,121.683001'
      );
    }
    35% {
      d: path(
        'M96.7109,121.678L96.7109,121.678L132.580485,77.671631C132.580485,77.671631,132.580485,77.67163,132.580485,77.67163'
      );
    }
    48.75% {
      d: path(
        'M132.580485,77.671631L132.580485,77.671631L132.580485,77.671631C132.580485,77.671631,132.580485,-3.79188,132.580485,-3.79188'
      );
    }
    100% {
      d: path(
        'M132.580485,77.671631L132.580485,77.671631L132.580485,77.671631C132.580485,77.671631,132.580485,-3.79188,132.580485,-3.79188'
      );
    }
  }
  @keyframes eaFI8OTBWJd23_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    15% {
      opacity: 0.3;
    }
    20% {
      opacity: 1;
    }
    46.25% {
      opacity: 1;
    }
    53.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eaFI8OTBWJd24_to {
    animation: eaFI8OTBWJd24_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd24_to__to {
    0% {
      transform: translate(160.8px, 124.504306px);
    }
    6.25% {
      transform: translate(160.8px, 124.504306px);
    }
    18.75% {
      transform: translate(160.8px, 22.504306px);
    }
    20% {
      transform: translate(164px, 12.502826px);
    }
    35% {
      transform: translate(193.801035px, -23.911387px);
    }
    36.25% {
      transform: translate(196.811985px, -34.712684px);
    }
    48.75% {
      transform: translate(196.811985px, -114.712684px);
    }
    100% {
      transform: translate(196.811985px, -114.712684px);
    }
  }
  #eaFI8OTBWJd24_tr {
    animation: eaFI8OTBWJd24_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd24_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    18.75% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(39deg);
    }
    35% {
      transform: rotate(39deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eaFI8OTBWJd24 {
    animation: eaFI8OTBWJd24_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd24_c_o {
    0% {
      opacity: 0;
    }
    8.75% {
      opacity: 0;
    }
    13.75% {
      opacity: 0.7;
    }
    18.75% {
      opacity: 0.7;
    }
    48.75% {
      opacity: 0.7;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eaFI8OTBWJd25_to {
    animation: eaFI8OTBWJd25_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd25_to__to {
    0% {
      transform: translate(160.8px, 124.504307px);
    }
    6.25% {
      transform: translate(160.8px, 124.504307px);
    }
    18.75% {
      transform: translate(160.8px, 22.504307px);
    }
    20% {
      transform: translate(164px, 12.502826px);
    }
    35% {
      transform: translate(193.801035px, -23.891881px);
    }
    36.25% {
      transform: translate(196.851035px, -34.791881px);
    }
    48.75% {
      transform: translate(196.851035px, -114.791881px);
    }
    100% {
      transform: translate(196.851035px, -114.791881px);
    }
  }
  #eaFI8OTBWJd25_tr {
    animation: eaFI8OTBWJd25_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd25_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    18.75% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(39deg);
    }
    35% {
      transform: rotate(39deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eaFI8OTBWJd25 {
    animation: eaFI8OTBWJd25_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd25_c_o {
    0% {
      opacity: 0;
    }
    8.75% {
      opacity: 0;
    }
    13.75% {
      opacity: 1;
    }
    18.75% {
      opacity: 1;
    }
    48.75% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eaFI8OTBWJd27 {
    animation-name: eaFI8OTBWJd27__m, eaFI8OTBWJd27_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eaFI8OTBWJd27__m {
    0% {
      d: path(
        'M96.711985,302.439636L96.7109,234.987317L96.711985,234.987317C96.711985,234.987317,96.711985,234.987317,96.711985,234.987317'
      );
    }
    51.25% {
      d: path(
        'M96.711985,302.439636L96.7109,234.987317L96.711985,234.987317C96.711985,234.987317,96.711985,234.987317,96.711985,234.987317'
      );
    }
    65% {
      d: path(
        'M96.711985,195.439636L96.7109,127.987317L96.711985,127.987317C96.711985,127.987317,96.711985,127.987317,96.711985,127.987317'
      );
    }
    66.25% {
      d: path(
        'M96.7109,175.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.711985,121.683001,96.711985,121.683001'
      );
    }
    81.25% {
      d: path(
        'M96.7109,121.678L96.7109,121.678L132.580485,77.671631C132.580485,77.671631,132.580485,77.67163,132.580485,77.67163'
      );
    }
    95% {
      d: path(
        'M132.580485,77.671631L132.580485,77.671631L132.580485,77.671631C132.580485,77.671631,132.580485,-3.79188,132.580485,-3.79188'
      );
    }
    100% {
      d: path(
        'M132.580485,77.671631L132.580485,77.671631L132.580485,77.671631C132.580485,77.671631,132.580485,-3.79188,132.580485,-3.79188'
      );
    }
  }
  @keyframes eaFI8OTBWJd27_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    61.25% {
      opacity: 0.3;
    }
    66.25% {
      opacity: 1;
    }
    92.5% {
      opacity: 1;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eaFI8OTBWJd28_to {
    animation: eaFI8OTBWJd28_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd28_to__to {
    0% {
      transform: translate(160.8px, 124.504306px);
    }
    52.5% {
      transform: translate(160.8px, 124.504306px);
    }
    65% {
      transform: translate(160.8px, 22.504306px);
    }
    66.25% {
      transform: translate(163.3px, 13.502826px);
    }
    81.25% {
      transform: translate(193.801035px, -23.911387px);
    }
    82.5% {
      transform: translate(196.811985px, -32.712684px);
    }
    95% {
      transform: translate(196.811985px, -114.712684px);
    }
    100% {
      transform: translate(196.811985px, -114.712684px);
    }
  }
  #eaFI8OTBWJd28_tr {
    animation: eaFI8OTBWJd28_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd28_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(39deg);
    }
    81.25% {
      transform: rotate(39deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eaFI8OTBWJd28 {
    animation: eaFI8OTBWJd28_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd28_c_o {
    0% {
      opacity: 0;
    }
    55% {
      opacity: 0;
    }
    60% {
      opacity: 0.7;
    }
    65% {
      opacity: 0.7;
    }
    95% {
      opacity: 0.7;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eaFI8OTBWJd29_to {
    animation: eaFI8OTBWJd29_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd29_to__to {
    0% {
      transform: translate(160.8px, 124.504306px);
    }
    52.5% {
      transform: translate(160.8px, 124.504306px);
    }
    65% {
      transform: translate(160.8px, 22.504307px);
    }
    66.25% {
      transform: translate(163.3px, 13.502826px);
    }
    81.25% {
      transform: translate(193.801035px, -23.891881px);
    }
    82.5% {
      transform: translate(196.851035px, -32.791881px);
    }
    95% {
      transform: translate(196.851035px, -114.791881px);
    }
    100% {
      transform: translate(196.851035px, -114.791881px);
    }
  }
  #eaFI8OTBWJd29_tr {
    animation: eaFI8OTBWJd29_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd29_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(39deg);
    }
    81.25% {
      transform: rotate(39deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eaFI8OTBWJd29 {
    animation: eaFI8OTBWJd29_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd29_c_o {
    0% {
      opacity: 0;
    }
    55% {
      opacity: 0;
    }
    60% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eaFI8OTBWJd34_to {
    animation: eaFI8OTBWJd34_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd34_to__to {
    0% {
      transform: translate(160.8px, 124.504306px);
    }
    6.25% {
      transform: translate(160.8px, 124.504306px);
    }
    18.75% {
      transform: translate(160.8px, 22.504306px);
    }
    20% {
      transform: translate(164px, 12.502826px);
    }
    35% {
      transform: translate(193.801035px, -23.911387px);
    }
    36.25% {
      transform: translate(196.811985px, -34.712684px);
    }
    48.75% {
      transform: translate(196.811985px, -114.712684px);
    }
    100% {
      transform: translate(196.811985px, -114.712684px);
    }
  }
  #eaFI8OTBWJd34_tr {
    animation: eaFI8OTBWJd34_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd34_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    18.75% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(39deg);
    }
    35% {
      transform: rotate(39deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eaFI8OTBWJd35_to {
    animation: eaFI8OTBWJd35_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd35_to__to {
    0% {
      transform: translate(160.8px, 124.504307px);
    }
    6.25% {
      transform: translate(160.8px, 124.504307px);
    }
    18.75% {
      transform: translate(160.8px, 22.504307px);
    }
    20% {
      transform: translate(164px, 12.502826px);
    }
    35% {
      transform: translate(193.801035px, -23.891881px);
    }
    36.25% {
      transform: translate(196.851035px, -34.791881px);
    }
    48.75% {
      transform: translate(196.851035px, -114.791881px);
    }
    100% {
      transform: translate(196.851035px, -114.791881px);
    }
  }
  #eaFI8OTBWJd35_tr {
    animation: eaFI8OTBWJd35_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd35_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    18.75% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(39deg);
    }
    35% {
      transform: rotate(39deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eaFI8OTBWJd38_to {
    animation: eaFI8OTBWJd38_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd38_to__to {
    0% {
      transform: translate(160.8px, 124.504306px);
    }
    52.5% {
      transform: translate(160.8px, 124.504306px);
    }
    65% {
      transform: translate(160.8px, 22.504306px);
    }
    66.25% {
      transform: translate(163.3px, 13.502826px);
    }
    81.25% {
      transform: translate(193.801035px, -23.911387px);
    }
    82.5% {
      transform: translate(196.811985px, -32.712684px);
    }
    95% {
      transform: translate(196.811985px, -114.712684px);
    }
    100% {
      transform: translate(196.811985px, -114.712684px);
    }
  }
  #eaFI8OTBWJd38_tr {
    animation: eaFI8OTBWJd38_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd38_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(39deg);
    }
    81.25% {
      transform: rotate(39deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eaFI8OTBWJd39_to {
    animation: eaFI8OTBWJd39_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd39_to__to {
    0% {
      transform: translate(160.8px, 124.504306px);
    }
    52.5% {
      transform: translate(160.8px, 124.504306px);
    }
    65% {
      transform: translate(160.8px, 22.504307px);
    }
    66.25% {
      transform: translate(163.3px, 13.502826px);
    }
    81.25% {
      transform: translate(193.801035px, -23.891881px);
    }
    82.5% {
      transform: translate(196.851035px, -32.791881px);
    }
    95% {
      transform: translate(196.851035px, -114.791881px);
    }
    100% {
      transform: translate(196.851035px, -114.791881px);
    }
  }
  #eaFI8OTBWJd39_tr {
    animation: eaFI8OTBWJd39_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eaFI8OTBWJd39_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(39deg);
    }
    81.25% {
      transform: rotate(39deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

export default FeedSourcesMobileBg2;
