// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesBg1 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)', // Replace all <stop id="...-stroke-1" stopColor="#initial-color" /> with this value
  };

  return (
    <AnimatedSvg
      id="emsxNwGe9B21"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 885 228"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="885"
      height="228"
      className="source-background"
      {...props}
    >
      <defs>
        <filter id="emsxNwGe9B27-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emsxNwGe9B27-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emsxNwGe9B27-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="emsxNwGe9B27-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="emsxNwGe9B27-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emsxNwGe9B27-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emsxNwGe9B27-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emsxNwGe9B27-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="emsxNwGe9B28-stroke"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="1"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="emsxNwGe9B28-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="emsxNwGe9B28-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="emsxNwGe9B29-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emsxNwGe9B29-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="emsxNwGe9B29-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="emsxNwGe9B29-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="emsxNwGe9B29-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emsxNwGe9B29-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emsxNwGe9B29-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emsxNwGe9B29-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="emsxNwGe9B29-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="emsxNwGe9B211-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emsxNwGe9B211-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emsxNwGe9B211-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="emsxNwGe9B211-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="emsxNwGe9B211-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emsxNwGe9B211-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emsxNwGe9B211-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emsxNwGe9B211-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="emsxNwGe9B212-stroke"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="1"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="emsxNwGe9B212-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="emsxNwGe9B212-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="emsxNwGe9B213-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emsxNwGe9B213-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="emsxNwGe9B213-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="emsxNwGe9B213-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="emsxNwGe9B213-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emsxNwGe9B213-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emsxNwGe9B213-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emsxNwGe9B213-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="emsxNwGe9B213-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="emsxNwGe9B215-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emsxNwGe9B215-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="emsxNwGe9B215-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="emsxNwGe9B215-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="emsxNwGe9B215-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emsxNwGe9B215-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emsxNwGe9B215-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emsxNwGe9B215-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="emsxNwGe9B216-stroke"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="1"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="emsxNwGe9B216-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="emsxNwGe9B216-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="emsxNwGe9B217-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="emsxNwGe9B217-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="emsxNwGe9B217-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="emsxNwGe9B217-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="emsxNwGe9B217-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="emsxNwGe9B217-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="emsxNwGe9B217-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="emsxNwGe9B217-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="emsxNwGe9B217-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
      </defs>
      <g transform="translate(-1.579205-186.439885)">
        <g transform="translate(86 185.893628)" mask="url(#emsxNwGe9B219)">
          <g>
            <line
              x1="443.301"
              y1="169.502"
              x2="443.301"
              y2="325.462"
              transform="translate(-86.018-178.955743)"
              fill="none"
              stroke="#6069ca"
            />
            <g transform="translate(-65 105.000001)" filter="url(#emsxNwGe9B27-filter)">
              <path
                id="emsxNwGe9B28"
                d="M357.283,138.189v-16.506l-.103795-25.007435"
                transform="translate(65-104.797494)"
                opacity="0.8"
                fill="none"
                stroke="url(#emsxNwGe9B28-stroke)"
                strokeLinecap="round"
              />
              <g id="emsxNwGe9B29_to" transform="translate(422.3,-7.409246)">
                <rect
                  id="emsxNwGe9B29"
                  width="5"
                  height="10"
                  rx="0"
                  ry="0"
                  transform="scale(0.8,0.8) translate(-2.5,-5)"
                  opacity="0.7"
                  filter="url(#emsxNwGe9B29-filter)"
                  fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                />
              </g>
              <g id="emsxNwGe9B210_to" transform="translate(422.3,-8.02346)">
                <rect
                  id="emsxNwGe9B210"
                  width="10"
                  height="20"
                  rx="0"
                  ry="0"
                  transform="scale(0.5,0.5) translate(-5,-10)"
                  fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                />
              </g>
            </g>
            <g transform="translate(-65 105.000001)" filter="url(#emsxNwGe9B211-filter)">
              <path
                id="emsxNwGe9B212"
                d="M357.283,198.189v-16.506l-.103795-25.007435"
                transform="translate(65-104.797494)"
                opacity="0"
                fill="none"
                stroke="url(#emsxNwGe9B212-stroke)"
                strokeLinecap="round"
              />
              <g id="emsxNwGe9B213_to" transform="translate(422.3,52.590754)">
                <rect
                  id="emsxNwGe9B213"
                  width="5"
                  height="10"
                  rx="0"
                  ry="0"
                  transform="scale(0.8,0.8) translate(-2.5,-5)"
                  opacity="0"
                  filter="url(#emsxNwGe9B213-filter)"
                  fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                />
              </g>
              <g id="emsxNwGe9B214_to" transform="translate(422.3,51.97654)">
                <rect
                  id="emsxNwGe9B214"
                  width="10"
                  height="20"
                  rx="0"
                  ry="0"
                  transform="scale(0.5,0.5) translate(-5,-10)"
                  opacity="0"
                  fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                />
              </g>
            </g>
            <g transform="translate(-65 105.000001)" filter="url(#emsxNwGe9B215-filter)">
              <path
                id="emsxNwGe9B216"
                d="M357.283,198.189v-16.506l-.103795-25.007435"
                transform="translate(65-104.797494)"
                opacity="0"
                fill="none"
                stroke="url(#emsxNwGe9B216-stroke)"
                strokeLinecap="round"
              />
              <g id="emsxNwGe9B217_to" transform="translate(422.3,52.590754)">
                <rect
                  id="emsxNwGe9B217"
                  width="5"
                  height="10"
                  rx="0"
                  ry="0"
                  transform="scale(0.8,0.8) translate(-2.5,-5)"
                  opacity="0"
                  filter="url(#emsxNwGe9B217-filter)"
                  fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                />
              </g>
              <g id="emsxNwGe9B218_to" transform="translate(422.3,51.97654)">
                <rect
                  id="emsxNwGe9B218"
                  width="10"
                  height="20"
                  rx="0"
                  ry="0"
                  transform="scale(0.5,0.5) translate(-5,-10)"
                  opacity="0"
                  fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                />
              </g>
            </g>
          </g>
          <mask id="emsxNwGe9B219" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
            <rect
              width="80"
              height="142.70764"
              rx="0"
              ry="0"
              transform="matrix(1 0 0 0.963299 341.931986 0.75214)"
              fill="#fff"
              strokeWidth="0"
            />
          </mask>
        </g>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #emsxNwGe9B28 {
    animation-name: emsxNwGe9B28__m, emsxNwGe9B28_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes emsxNwGe9B28__m {
    0% {
      d: path('M357.283,138.189L357.283,121.683L357.179205,96.675565');
    }
    6.25% {
      d: path('M357.283,118.189L357.283,101.683L357.1,66.229573');
    }
    10% {
      d: path('M357.283,98.189L357.283,55.160244L357.166667,20.404876');
    }
    16.25% {
      d: path('M357.283,78.189L357.283,37.637489L357.233334,-2.41982');
    }
    20% {
      d: path('M357.283,58.189L357.283,20.114733L357.3,-13.244517');
    }
    100% {
      d: path('M357.283,58.189L357.283,20.114733L357.3,-13.244517');
    }
  }
  @keyframes emsxNwGe9B28_c_o {
    0% {
      opacity: 0.8;
    }
    15% {
      opacity: 0.8;
    }
    23.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emsxNwGe9B29_to {
    animation: emsxNwGe9B29_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes emsxNwGe9B29_to__to {
    0% {
      transform: translate(422.3px, -7.409246px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    6.25% {
      transform: translate(422.3px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(422.3px, -118.878703px);
    }
    100% {
      transform: translate(422.3px, -118.878703px);
    }
  }
  #emsxNwGe9B29 {
    animation: emsxNwGe9B29_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes emsxNwGe9B29_c_o {
    0% {
      opacity: 0.7;
    }
    20% {
      opacity: 0.7;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emsxNwGe9B210_to {
    animation: emsxNwGe9B210_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes emsxNwGe9B210_to__to {
    0% {
      transform: translate(422.3px, -8.02346px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    6.25% {
      transform: translate(422.3px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(422.3px, -118.878703px);
    }
    100% {
      transform: translate(422.3px, -118.878703px);
    }
  }
  #emsxNwGe9B210 {
    animation: emsxNwGe9B210_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes emsxNwGe9B210_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    20% {
      opacity: 0.8;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emsxNwGe9B212 {
    animation-name: emsxNwGe9B212__m, emsxNwGe9B212_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes emsxNwGe9B212__m {
    0% {
      d: path('M357.283,198.189L357.283,181.683L357.179205,156.675565');
    }
    32.5% {
      d: path('M357.283,198.189L357.283,181.683L357.179205,156.675565');
    }
    50% {
      d: path('M357.283,138.189L357.283,121.683L357.179205,96.675565');
    }
    56.25% {
      d: path('M357.283,118.189L357.283,101.683L357.1,66.229573');
    }
    62.5% {
      d: path('M357.283,98.189L357.283,55.160244L357.166667,20.404876');
    }
    66.25% {
      d: path('M357.283,78.189L357.283,37.637489L357.233334,-2.41982');
    }
    70% {
      d: path('M357.283,58.189L357.283,20.114733L357.3,-13.244517');
    }
    100% {
      d: path('M357.283,58.189L357.283,20.114733L357.3,-13.244517');
    }
  }
  @keyframes emsxNwGe9B212_c_o {
    0% {
      opacity: 0;
    }
    31.25% {
      opacity: 0;
    }
    32.5% {
      opacity: 0.8;
    }
    50% {
      opacity: 0.8;
    }
    65% {
      opacity: 0.8;
    }
    73.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emsxNwGe9B213_to {
    animation: emsxNwGe9B213_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes emsxNwGe9B213_to__to {
    0% {
      transform: translate(422.3px, 52.590754px);
    }
    32.5% {
      transform: translate(422.3px, 52.590754px);
    }
    50% {
      transform: translate(422.3px, -7.409246px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    56.25% {
      transform: translate(422.3px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    70% {
      transform: translate(422.3px, -118.878703px);
    }
    100% {
      transform: translate(422.3px, -118.878703px);
    }
  }
  #emsxNwGe9B213 {
    animation: emsxNwGe9B213_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes emsxNwGe9B213_c_o {
    0% {
      opacity: 0;
    }
    31.25% {
      opacity: 0;
    }
    32.5% {
      opacity: 0.7;
    }
    50% {
      opacity: 0.7;
    }
    70% {
      opacity: 0.7;
    }
    71.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emsxNwGe9B214_to {
    animation: emsxNwGe9B214_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes emsxNwGe9B214_to__to {
    0% {
      transform: translate(422.3px, 51.97654px);
    }
    32.5% {
      transform: translate(422.3px, 51.97654px);
    }
    50% {
      transform: translate(422.3px, -8.02346px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    56.25% {
      transform: translate(422.3px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    70% {
      transform: translate(422.3px, -118.878703px);
    }
    100% {
      transform: translate(422.3px, -118.878703px);
    }
  }
  #emsxNwGe9B214 {
    animation: emsxNwGe9B214_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes emsxNwGe9B214_c_o {
    0% {
      opacity: 0;
    }
    31.25% {
      opacity: 0;
    }
    32.5% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    70% {
      opacity: 0.8;
    }
    71.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #emsxNwGe9B216 {
    animation-name: emsxNwGe9B216__m, emsxNwGe9B216_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes emsxNwGe9B216__m {
    0% {
      d: path('M357.283,198.189L357.283,181.683L357.179205,156.675565');
    }
    82.5% {
      d: path('M357.283,198.189L357.283,181.683L357.179205,156.675565');
    }
    100% {
      d: path('M357.283,138.189L357.283,121.683L357.179205,96.675565');
    }
  }
  @keyframes emsxNwGe9B216_c_o {
    0% {
      opacity: 0;
    }
    81.25% {
      opacity: 0;
    }
    82.5% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.8;
    }
  }
  #emsxNwGe9B217_to {
    animation: emsxNwGe9B217_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes emsxNwGe9B217_to__to {
    0% {
      transform: translate(422.3px, 52.590754px);
    }
    82.5% {
      transform: translate(422.3px, 52.590754px);
    }
    100% {
      transform: translate(422.3px, -7.409246px);
    }
  }
  #emsxNwGe9B217 {
    animation: emsxNwGe9B217_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes emsxNwGe9B217_c_o {
    0% {
      opacity: 0;
    }
    81.25% {
      opacity: 0;
    }
    82.5% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #emsxNwGe9B218_to {
    animation: emsxNwGe9B218_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes emsxNwGe9B218_to__to {
    0% {
      transform: translate(422.3px, 51.97654px);
    }
    82.5% {
      transform: translate(422.3px, 51.97654px);
    }
    100% {
      transform: translate(422.3px, -8.02346px);
    }
  }
  #emsxNwGe9B218 {
    animation: emsxNwGe9B218_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes emsxNwGe9B218_c_o {
    0% {
      opacity: 0;
    }
    81.25% {
      opacity: 0;
    }
    82.5% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`;

export default FeedSourcesBg1;
