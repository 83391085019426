// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesBg12 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)', // Replace all <stop id="...-stroke-1" stopColor="#initial-color" /> with this value
  };

  return (
    <AnimatedSvg
      id="edpgNVz5OyZ1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 885 228"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="885"
      height="228"
      className="source-background"
      {...props}
    >
      <defs>
        <filter id="edpgNVz5OyZ8-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ8-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ8-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ8-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ8-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ8-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ8-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ8-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ9-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ9-stroke-0"
            offset="0%"
            stopColor={selected === 11 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="edpgNVz5OyZ9-stroke-1"
            offset="49%"
            stopColor={selected === 11 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ9-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ10-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ10-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ10-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ10-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ10-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ10-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ10-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ10-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ12-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ12-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ12-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ12-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ12-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ12-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ12-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ12-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ13-stroke"
          x1="-17.402414"
          y1="0.109461"
          x2="-17.402414"
          y2="0.844851"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ13-stroke-0"
            offset="0%"
            stopColor={selected === 11 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ13-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ14-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ14-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ14-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ14-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ14-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ14-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ14-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ14-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ14-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ16-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ16-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ16-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ16-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ16-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ16-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ16-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ16-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ17-stroke"
          x1="-1.846806"
          y1="0.013594"
          x2="-1.846806"
          y2="0.5"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ17-stroke-0"
            offset="0%"
            stopColor={selected === 11 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ17-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ18-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ18-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ18-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ18-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ18-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ18-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ18-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ18-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ18-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ23-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ23-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ23-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ23-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ23-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ23-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ23-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ23-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ24-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="matrix(0.642788 0.766044 -0.766044 0.642788 0.178606 0.116978)"
        >
          <stop
            id="edpgNVz5OyZ24-stroke-0"
            offset="0%"
            stopColor={selected === 10 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="edpgNVz5OyZ24-stroke-1"
            offset="50%"
            stopColor={selected === 10 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ24-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <linearGradient
          id="edpgNVz5OyZ24-stroke-g1"
          x1="632.3"
          y1="15.752932"
          x2="663.719354"
          y2="75.879298"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="edpgNVz5OyZ24-stroke-g1-0" offset="0%" stopColor="#7ea6ff" />
          <stop id="edpgNVz5OyZ24-stroke-g1-1" offset="50%" stopColor="#6069ca" />
          <stop id="edpgNVz5OyZ24-stroke-g1-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ25-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ25-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ25-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ25-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ25-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ25-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ25-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ25-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ25-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ27-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ27-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ27-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ27-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ27-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ27-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ27-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ27-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ28-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ28-stroke-0"
            offset="0%"
            stopColor={selected === 10 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="edpgNVz5OyZ28-stroke-1"
            offset="51%"
            stopColor={selected === 10 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ28-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ29-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ29-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ29-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ29-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ29-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ29-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ29-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ29-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ29-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ35-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ35-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ35-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ35-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ35-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ35-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ35-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ35-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ36-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ36-stroke-0"
            offset="0%"
            stopColor={selected === 9 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="edpgNVz5OyZ36-stroke-1"
            offset="51%"
            stopColor={selected === 9 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ36-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ37-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ37-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ37-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ37-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ37-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ37-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ37-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ37-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ37-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ39-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ39-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ39-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ39-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ39-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ39-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ39-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ39-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ40-stroke"
          x1="-5521.716483"
          y1="0.086887"
          x2="-5521.716483"
          y2="0.88259"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ40-stroke-0"
            offset="0%"
            stopColor={selected === 9 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ40-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ41-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ41-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ41-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ41-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ41-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ41-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ41-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ41-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ41-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ43-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ43-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ43-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ43-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ43-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ43-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ43-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ43-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ44-stroke"
          x1="-263.905713"
          y1="0.041399"
          x2="-263.905713"
          y2="0.872849"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ44-stroke-0"
            offset="0%"
            stopColor={selected === 9 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="edpgNVz5OyZ44-stroke-1"
            offset="52%"
            stopColor={selected === 9 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ44-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ45-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ45-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ45-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ45-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ45-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ45-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ45-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ45-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ45-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ50-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ50-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ50-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ50-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ50-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ50-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ50-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ50-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ51-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ51-stroke-0"
            offset="0%"
            stopColor={selected === 8 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="edpgNVz5OyZ51-stroke-1"
            offset="51%"
            stopColor={selected === 8 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ51-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ52-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ52-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ52-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ52-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ52-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ52-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ52-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ52-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ52-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ54-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ54-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ54-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ54-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ54-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ54-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ54-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ54-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ55-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ55-stroke-0"
            offset="0%"
            stopColor={selected === 8 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="edpgNVz5OyZ55-stroke-1"
            offset="50%"
            stopColor={selected === 8 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ55-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ56-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ56-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ56-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ56-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ56-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ56-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ56-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ56-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ56-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ62-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ62-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ62-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ62-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ62-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ62-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ62-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ62-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ63-stroke"
          x1="-47.324893"
          y1="0.048226"
          x2="-47.324893"
          y2="0.816237"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ63-stroke-0"
            offset="0%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="edpgNVz5OyZ63-stroke-1"
            offset="51%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ63-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ64-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ64-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ64-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ64-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ64-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ64-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ64-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ64-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ64-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ66-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ66-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ66-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ66-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ66-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ66-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ66-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ66-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ67-stroke"
          x1="-30.34715"
          y1="0"
          x2="-30.34715"
          y2="0.81369"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ67-stroke-0"
            offset="0%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ67-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ68-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ68-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ68-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ68-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ68-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ68-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ68-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ68-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ68-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ70-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ70-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ70-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ70-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ70-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ70-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ70-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ70-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ71-stroke"
          x1="-88.524176"
          y1="0.080685"
          x2="-88.524176"
          y2="0.892015"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ71-stroke-0"
            offset="0%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ71-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ72-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ72-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ72-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ72-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ72-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ72-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ72-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ72-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ72-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ77-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ77-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ77-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ77-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ77-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ77-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ77-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ77-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ78-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ78-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="edpgNVz5OyZ78-stroke-1"
            offset="53%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ78-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ79-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ79-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ79-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ79-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ79-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ79-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ79-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ79-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ79-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ81-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ81-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ81-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ81-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ81-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ81-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ81-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ81-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ82-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ82-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="edpgNVz5OyZ82-stroke-1"
            offset="52%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ82-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ83-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ83-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ83-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ83-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ83-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ83-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ83-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ83-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ83-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ85-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ85-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ85-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ85-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ85-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ85-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ85-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ85-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ86-stroke"
          x1="6.744956"
          y1="-0.114067"
          x2="6.744956"
          y2="1.819877"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ86-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="edpgNVz5OyZ86-stroke-1"
            offset="53%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ86-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ87-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ87-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ87-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ87-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ87-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ87-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ87-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ87-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ87-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ94-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ94-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ94-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ94-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ94-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ94-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ94-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ94-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ95-stroke"
          x1="0.586824"
          y1="0.007596"
          x2="0.413176"
          y2="0.992404"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ95-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="edpgNVz5OyZ95-stroke-1"
            offset="52%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ95-stroke-2" offset="96%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ96-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ96-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ96-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ96-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ96-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ96-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ96-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ96-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ96-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ98-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ98-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ98-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ98-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ98-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ98-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ98-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ98-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ99-stroke"
          x1="0.883022"
          y1="0.178606"
          x2="0.116978"
          y2="0.821394"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ99-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="edpgNVz5OyZ99-stroke-1"
            offset="54%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ99-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ100-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ100-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ100-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ100-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ100-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ100-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ100-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ100-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ100-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ102-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ102-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ102-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ102-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ102-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ102-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ102-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ102-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ103-stroke"
          x1="1.264643"
          y1="0.397928"
          x2="0.517956"
          y2="1.472121"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ103-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="edpgNVz5OyZ103-stroke-1"
            offset="53%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ103-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ104-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ104-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ104-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ104-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ104-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ104-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ104-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ104-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ104-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ110-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ110-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ110-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ110-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ110-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ110-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ110-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ110-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ111-stroke"
          x1="40.789917"
          y1="0"
          x2="40.789917"
          y2="0.720413"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ111-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ111-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ112-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ112-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ112-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ112-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ112-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ112-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ112-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ112-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ112-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ114-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ114-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ114-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ114-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ114-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ114-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ114-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ114-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ115-stroke"
          x1="0"
          y1="0.041281"
          x2="0.060771"
          y2="1.028927"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ115-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ115-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ116-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ116-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ116-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ116-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ116-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ116-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ116-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ116-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ116-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ118-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ118-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ118-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ118-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ118-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ118-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ118-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ118-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ119-stroke"
          x1="60.62164"
          y1="0"
          x2="60.62164"
          y2="1"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ119-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="edpgNVz5OyZ119-stroke-1"
            offset="53%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ119-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ120-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ120-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ120-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ120-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ120-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ120-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ120-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ120-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ120-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ125-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ125-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ125-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ125-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ125-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ125-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ125-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ125-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ126-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.021396"
          y2="1.049288"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ126-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="edpgNVz5OyZ126-stroke-1"
            offset="52%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ126-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ127-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ127-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ127-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ127-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ127-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ127-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ127-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ127-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ127-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ129-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ129-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ129-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ129-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ129-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ129-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ129-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ129-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ130-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.021396"
          y2="1.049288"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ130-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="edpgNVz5OyZ130-stroke-1"
            offset="53%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ130-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ131-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ131-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ131-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ131-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ131-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ131-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ131-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ131-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ131-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ137-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ137-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ137-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ137-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ137-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ137-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ137-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ137-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ138-stroke"
          x1="9.624102"
          y1="0.188669"
          x2="9.624102"
          y2="0.933858"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ138-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ138-stroke-1" offset="100%" stopColor="rgba(80,85,136,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ139-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ139-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ139-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ139-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ139-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ139-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ139-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ139-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ139-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ141-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ141-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ141-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ141-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ141-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ141-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ141-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ141-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ142-stroke"
          x1="14.62799"
          y1="0.105037"
          x2="14.62799"
          y2="0.830636"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ142-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ142-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ143-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ143-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ143-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ143-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ143-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ143-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ143-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ143-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ143-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ148-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ148-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ148-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ148-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ148-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ148-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ148-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ148-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ149-stroke"
          x1="0.714238"
          y1="0.580009"
          x2="0.93509"
          y2="0.042275"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="edpgNVz5OyZ149-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="edpgNVz5OyZ149-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="edpgNVz5OyZ150-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ150-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ150-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ150-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ150-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ150-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ150-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ150-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ150-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ152-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ152-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ152-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ152-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ152-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ152-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ152-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ152-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ153-stroke"
          x1="0.154831"
          y1="1.309289"
          x2="1.076686"
          y2="0.41993"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="edpgNVz5OyZ153-stroke-0" offset="0%" stopColor="rgba(126,166,255,0)" />
          <stop
            id="edpgNVz5OyZ153-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="edpgNVz5OyZ154-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ154-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ154-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ154-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ154-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ154-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ154-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ154-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ154-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ156-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ156-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ156-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ156-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ156-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ156-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ156-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ156-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ157-stroke"
          x1="-0.142435"
          y1="1.238891"
          x2="0.883069"
          y2="-0.083996"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="edpgNVz5OyZ157-stroke-0" offset="2%" stopColor="rgba(64,73,168,0)" />
          <stop
            id="edpgNVz5OyZ157-stroke-1"
            offset="51%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="edpgNVz5OyZ157-stroke-2"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="edpgNVz5OyZ158-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ158-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ158-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ158-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ158-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ158-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ158-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ158-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ158-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ159-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ159-filter-blur-0" stdDeviation="0,0" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ164-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ164-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ164-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ164-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ164-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ164-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ164-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ164-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ165-stroke"
          x1="22.562545"
          y1="0.100064"
          x2="22.562545"
          y2="0.878936"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ165-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ165-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ166-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ166-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ166-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ166-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ166-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ166-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ166-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ166-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ166-filter-blur-0" stdDeviation="3,3" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ167-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ167-filter-blur-0" stdDeviation="0,0" result="result" />
        </filter>
        <filter id="edpgNVz5OyZ168-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ168-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="edpgNVz5OyZ168-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ168-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="edpgNVz5OyZ168-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ168-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ168-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ168-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="edpgNVz5OyZ169-stroke"
          x1="19.350054"
          y1="0.076354"
          x2="19.350054"
          y2="0.760237"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="edpgNVz5OyZ169-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="edpgNVz5OyZ169-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="edpgNVz5OyZ170-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="edpgNVz5OyZ170-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="edpgNVz5OyZ170-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="edpgNVz5OyZ170-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="edpgNVz5OyZ170-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="edpgNVz5OyZ170-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="edpgNVz5OyZ170-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="edpgNVz5OyZ170-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="edpgNVz5OyZ170-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
      </defs>
      <g transform="translate(0-2.321263)" mask="url(#edpgNVz5OyZ172)">
        <g transform="translate(.000001 0)">
          <g transform="translate(.088013 1.012683)">
            <g>
              <path
                d="M853.309,232.641v-111.14137L665.296,76.728v-73.62253"
                transform="matrix(.993835 0 0 1.020627 4.6811-2.431371)"
                fill="none"
                stroke="#6069ca"
              />
              <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ8-filter)">
                <path
                  id="edpgNVz5OyZ9"
                  d="M853.309,172.852398v-51.352768l-88.567326-21.284376-.520849-.000001"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  fill="none"
                  stroke="url(#edpgNVz5OyZ9-stroke)"
                  strokeLinecap="round"
                />
                <g id="edpgNVz5OyZ10_to" transform="translate(828.317602,-5.431736)">
                  <g id="edpgNVz5OyZ10_tr" transform="rotate(-75)">
                    <rect
                      id="edpgNVz5OyZ10"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0.7"
                      filter="url(#edpgNVz5OyZ10-filter)"
                      fill={selected === 11 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="edpgNVz5OyZ11_to" transform="translate(828.317602,-5.431736)">
                  <g id="edpgNVz5OyZ11_tr" transform="rotate(-75)">
                    <rect
                      id="edpgNVz5OyZ11"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      fill={selected === 11 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ12-filter)">
                <path
                  id="edpgNVz5OyZ13"
                  d="M853.309,252.2368v-9.24321-1.766709-3.184316"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  opacity="0"
                  fill="none"
                  stroke="url(#edpgNVz5OyZ13-stroke)"
                  strokeLinecap="round"
                />
                <g id="edpgNVz5OyZ14_to" transform="translate(917.8,138.546297)">
                  <g id="edpgNVz5OyZ14_tr" transform="rotate(0)">
                    <rect
                      id="edpgNVz5OyZ14"
                      width="8.235294"
                      height="14.117647"
                      rx="0"
                      ry="0"
                      transform="scale(0.85,0.85) translate(-4.117647,-7.058824)"
                      opacity="0"
                      filter="url(#edpgNVz5OyZ14-filter)"
                      fill={selected === 11 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="edpgNVz5OyZ15_to" transform="translate(917.8,138.546297)">
                  <g id="edpgNVz5OyZ15_tr" transform="rotate(0)">
                    <rect
                      id="edpgNVz5OyZ15"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 11 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ16-filter)">
                <path
                  id="edpgNVz5OyZ17"
                  d="M853.309,252.2368v-9.24321-1.766709-3.184316"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  opacity="0"
                  fill="none"
                  stroke="url(#edpgNVz5OyZ17-stroke)"
                  strokeLinecap="round"
                />
                <g id="edpgNVz5OyZ18_to" transform="translate(917.8,140.146297)">
                  <g id="edpgNVz5OyZ18_tr" transform="rotate(0)">
                    <rect
                      id="edpgNVz5OyZ18"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#edpgNVz5OyZ18-filter)"
                      fill={selected === 11 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="edpgNVz5OyZ19_to" transform="translate(917.8,140.146297)">
                  <g id="edpgNVz5OyZ19_tr" transform="rotate(0)">
                    <rect
                      id="edpgNVz5OyZ19"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 11 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g mask="url(#edpgNVz5OyZ31)">
              <g>
                <path d="M781.258,138.241v-16.559L628.386,76.7624v-76.41865" fill="none" stroke="#6069ca" />
                <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ23-filter)">
                  <path
                    id="edpgNVz5OyZ24"
                    d="M781.258,158.241v-7.559l.242-1.040176-.242-.654506"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#edpgNVz5OyZ24-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="edpgNVz5OyZ25_to" transform="translate(846.5,51.646297)">
                    <g id="edpgNVz5OyZ25_tr" transform="rotate(0)">
                      <rect
                        id="edpgNVz5OyZ25"
                        width="7.129631"
                        height="12.222224"
                        rx="0"
                        ry="0"
                        transform="scale(0.981818,0.981818) translate(-3.564816,-6.111112)"
                        opacity="0"
                        filter="url(#edpgNVz5OyZ25-filter)"
                        fill={selected === 10 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="edpgNVz5OyZ26_to" transform="translate(846.5,51.646297)">
                    <g id="edpgNVz5OyZ26_tr" transform="rotate(0)">
                      <rect
                        id="edpgNVz5OyZ26"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 10 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ27-filter)">
                  <path
                    id="edpgNVz5OyZ28"
                    d="M781.258,158.241v-7.559l.242-1.040176-.242-.654506"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#edpgNVz5OyZ28-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="edpgNVz5OyZ29_to" transform="translate(846.5,51.146297)">
                    <g id="edpgNVz5OyZ29_tr" transform="rotate(0)">
                      <rect
                        id="edpgNVz5OyZ29"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0"
                        filter="url(#edpgNVz5OyZ29-filter)"
                        fill={selected === 10 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="edpgNVz5OyZ30_to" transform="translate(846.5,51.146297)">
                    <g id="edpgNVz5OyZ30_tr" transform="rotate(0)">
                      <rect
                        id="edpgNVz5OyZ30"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 10 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="edpgNVz5OyZ31" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 605.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(.000001 0.624996)">
              <path d="M721.199,232.865v-112.051L591.532,76.7311l.008-76.992999" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ35-filter)">
                <path
                  id="edpgNVz5OyZ36"
                  d="M692.384111,111.206804v-.118885L591.532,76.7311c0,0,.008-7.785355.008-7.785355"
                  transform="translate(65-105.000001)"
                  fill="none"
                  stroke="url(#edpgNVz5OyZ36-stroke)"
                  strokeLinecap="round"
                />
                <g id="edpgNVz5OyZ37_to" transform="translate(656.5,-35.643085)">
                  <rect
                    id="edpgNVz5OyZ37"
                    width="8.75"
                    height="15"
                    rx="0"
                    ry="0"
                    transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                    opacity="0.7"
                    filter="url(#edpgNVz5OyZ37-filter)"
                    fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="edpgNVz5OyZ38_to" transform="translate(656.5,-35.643085)">
                  <rect
                    id="edpgNVz5OyZ38"
                    width="10"
                    height="20"
                    rx="0"
                    ry="0"
                    transform="scale(0.5,0.5) translate(-5,-10)"
                    fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ39-filter)">
                <path
                  id="edpgNVz5OyZ40"
                  d="M721.199,252.865v-4.051l-.006-.917698c0,0,0-.755649,0-.755649"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#edpgNVz5OyZ40-stroke)"
                  strokeLinecap="round"
                />
                <g id="edpgNVz5OyZ41_to" transform="translate(786.2,139.146297)">
                  <g id="edpgNVz5OyZ41_tr" transform="rotate(0)">
                    <rect
                      id="edpgNVz5OyZ41"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#edpgNVz5OyZ41-filter)"
                      fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="edpgNVz5OyZ42_to" transform="translate(786.2,139.146297)">
                  <g id="edpgNVz5OyZ42_tr" transform="rotate(0)">
                    <rect
                      id="edpgNVz5OyZ42"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ43-filter)">
                <path
                  id="edpgNVz5OyZ44"
                  d="M721.199,252.865v-4.051l-.006-.917698c0,0,0-.755649,0-.755649"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#edpgNVz5OyZ44-stroke)"
                  strokeLinecap="round"
                />
                <g id="edpgNVz5OyZ45_to" transform="translate(786.5,138.146297)">
                  <g id="edpgNVz5OyZ45_tr" transform="rotate(0)">
                    <rect
                      id="edpgNVz5OyZ45"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#edpgNVz5OyZ45-filter)"
                      fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="edpgNVz5OyZ46_to" transform="translate(786.5,138.146297)">
                  <g id="edpgNVz5OyZ46_tr" transform="rotate(0)">
                    <rect
                      id="edpgNVz5OyZ46"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g mask="url(#edpgNVz5OyZ58)">
              <g>
                <path d="M650.977,138.082v-16.4L554.656,76.7305l-.004-76.414006" fill="none" stroke="#6069ca" />
                <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ50-filter)">
                  <path
                    id="edpgNVz5OyZ51"
                    d="M650.977,158.082v-3.4l.052.000001v-2.410703"
                    transform="translate(65-105.000001)"
                    fill="none"
                    stroke="url(#edpgNVz5OyZ51-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="edpgNVz5OyZ52_to" transform="translate(715.8,52.146297)">
                    <g id="edpgNVz5OyZ52_tr" transform="rotate(0)">
                      <rect
                        id="edpgNVz5OyZ52"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0.7"
                        filter="url(#edpgNVz5OyZ52-filter)"
                        fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="edpgNVz5OyZ53_to" transform="translate(715.8,52.146297)">
                    <g id="edpgNVz5OyZ53_tr" transform="rotate(0)">
                      <rect
                        id="edpgNVz5OyZ53"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ54-filter)">
                  <path
                    id="edpgNVz5OyZ55"
                    d="M650.977,158.082v-3.4l.052.000001v-2.410703"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#edpgNVz5OyZ55-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="edpgNVz5OyZ56_to" transform="translate(715.298427,50.146297)">
                    <g id="edpgNVz5OyZ56_tr" transform="rotate(0)">
                      <rect
                        id="edpgNVz5OyZ56"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0"
                        filter="url(#edpgNVz5OyZ56-filter)"
                        fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="edpgNVz5OyZ57_to" transform="translate(715.298427,50.146297)">
                    <g id="edpgNVz5OyZ57_tr" transform="rotate(0)">
                      <rect
                        id="edpgNVz5OyZ57"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="edpgNVz5OyZ58" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="140"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 535.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(.000001-4)">
              <path
                d="M590.336,232.597v-106.78L517.819,80.7272l-.001-77.20376"
                transform="translate(0 0.805403)"
                fill="none"
                stroke="#6069ca"
              />
              <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ62-filter)">
                <path
                  id="edpgNVz5OyZ63"
                  d="M590.336,238.051545v-62.057573l.000001.267378-.242448-.267377"
                  transform="translate(65-104.194598)"
                  fill="none"
                  stroke="url(#edpgNVz5OyZ63-stroke)"
                  strokeLinecap="round"
                />
                <g id="edpgNVz5OyZ64_to" transform="translate(655.5,73.020302)">
                  <g id="edpgNVz5OyZ64_tr" transform="rotate(0)">
                    <rect
                      id="edpgNVz5OyZ64"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0.7"
                      filter="url(#edpgNVz5OyZ64-filter)"
                      fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="edpgNVz5OyZ65_to" transform="translate(655.5,73.020302)">
                  <g id="edpgNVz5OyZ65_tr" transform="rotate(0)">
                    <rect
                      id="edpgNVz5OyZ65"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ66-filter)">
                <path
                  id="edpgNVz5OyZ67"
                  d="M590.336,252.597v-2.381105v0h.018"
                  transform="translate(65-104.194598)"
                  opacity="0"
                  fill="none"
                  stroke="url(#edpgNVz5OyZ67-stroke)"
                  strokeLinecap="round"
                />
                <g id="edpgNVz5OyZ68_to" transform="translate(655.4,137.771297)">
                  <g id="edpgNVz5OyZ68_tr" transform="rotate(0)">
                    <rect
                      id="edpgNVz5OyZ68"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#edpgNVz5OyZ68-filter)"
                      fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="edpgNVz5OyZ69_to" transform="translate(655.4,137.771297)">
                  <g id="edpgNVz5OyZ69_tr" transform="rotate(0)">
                    <rect
                      id="edpgNVz5OyZ69"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ70-filter)">
                <path
                  id="edpgNVz5OyZ71"
                  d="M590.336,252.597v-2.381105v0h.018"
                  transform="translate(65-104.194598)"
                  opacity="0"
                  fill="none"
                  stroke="url(#edpgNVz5OyZ71-stroke)"
                  strokeLinecap="round"
                />
                <g id="edpgNVz5OyZ72_to" transform="translate(655.5,137.646297)">
                  <rect
                    id="edpgNVz5OyZ72"
                    width="7"
                    height="12"
                    rx="0"
                    ry="0"
                    transform="translate(-3.5,-6)"
                    opacity="0"
                    filter="url(#edpgNVz5OyZ72-filter)"
                    fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="edpgNVz5OyZ73_to" transform="translate(655.5,137.646297)">
                  <rect
                    id="edpgNVz5OyZ73"
                    width="10"
                    height="20"
                    rx="0"
                    ry="0"
                    transform="scale(0.5,0.5) translate(-5,-10)"
                    opacity="0"
                    fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
              </g>
            </g>
            <g mask="url(#edpgNVz5OyZ89)">
              <g>
                <path d="M520.668,138.079v-16.398L480.969,76.7549l.005-76.508643" fill="none" stroke="#6069ca" />
                <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ77-filter)">
                  <path
                    id="edpgNVz5OyZ78"
                    d="M520.668,121.038711v.642289L480.969,76.7549l.005-4.679761"
                    transform="translate(65-105.000001)"
                    fill="none"
                    stroke="url(#edpgNVz5OyZ78-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="edpgNVz5OyZ79_to" transform="translate(546.2,-36.480793)">
                    <rect
                      id="edpgNVz5OyZ79"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0.7"
                      filter="url(#edpgNVz5OyZ79-filter)"
                      fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="edpgNVz5OyZ80_to" transform="translate(546.2,-37.480793)">
                    <rect
                      id="edpgNVz5OyZ80"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ81-filter)">
                  <path
                    id="edpgNVz5OyZ82"
                    d="M520.668,153.079v-2.777975-.741319-1.788408"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#edpgNVz5OyZ82-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="edpgNVz5OyZ83_to" transform="translate(585.8,49.646297)">
                    <g id="edpgNVz5OyZ83_tr" transform="rotate(0)">
                      <rect
                        id="edpgNVz5OyZ83"
                        width="8.75"
                        height="15"
                        rx="0"
                        ry="0"
                        transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                        opacity="0"
                        filter="url(#edpgNVz5OyZ83-filter)"
                        fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="edpgNVz5OyZ84_to" transform="translate(585.8,49.646297)">
                    <g id="edpgNVz5OyZ84_tr" transform="rotate(0)">
                      <rect
                        id="edpgNVz5OyZ84"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ85-filter)">
                  <path
                    id="edpgNVz5OyZ86"
                    d="M520.668,153.079v-2.777975-.741319-1.788408"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#edpgNVz5OyZ86-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="edpgNVz5OyZ87_to" transform="translate(585.8,49.146297)">
                    <g id="edpgNVz5OyZ87_tr" transform="rotate(0)">
                      <rect
                        id="edpgNVz5OyZ87"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0"
                        filter="url(#edpgNVz5OyZ87-filter)"
                        fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="edpgNVz5OyZ88_to" transform="translate(585.8,49.146297)">
                    <g id="edpgNVz5OyZ88_tr" transform="rotate(0)">
                      <rect
                        id="edpgNVz5OyZ88"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="edpgNVz5OyZ89" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="80"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 459.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g mask="url(#edpgNVz5OyZ106)">
              <g>
                <path
                  d="M357.283,138.189v-16.506l49.955-44.9838v-76.35545"
                  transform="translate(0 0.202507)"
                  fill="none"
                  stroke="#6069ca"
                />
                <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ94-filter)">
                  <path
                    id="edpgNVz5OyZ95"
                    d="M357.283,138.189v-16.506l27.346502-25.007435c0,0,0,.176087,0,.176087"
                    transform="translate(65-104.797494)"
                    opacity="0.8"
                    fill="none"
                    stroke="url(#edpgNVz5OyZ95-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="edpgNVz5OyZ96_to" transform="translate(449.275947,-6.937495)">
                    <g id="edpgNVz5OyZ96_tr" transform="rotate(45)">
                      <rect
                        id="edpgNVz5OyZ96"
                        width="8.75"
                        height="15"
                        rx="0"
                        ry="0"
                        transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                        opacity="0.7"
                        filter="url(#edpgNVz5OyZ96-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="edpgNVz5OyZ97_to" transform="translate(449.275947,-6.937495)">
                    <g id="edpgNVz5OyZ97_tr" transform="rotate(45)">
                      <rect
                        id="edpgNVz5OyZ97"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ98-filter)">
                  <path
                    id="edpgNVz5OyZ99"
                    d="M357.283,138.189v-.338507h.01v.338507"
                    transform="translate(65-104.797494)"
                    opacity="0"
                    fill="none"
                    stroke="url(#edpgNVz5OyZ99-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="edpgNVz5OyZ100_to" transform="translate(423.548427,50.146297)">
                    <g id="edpgNVz5OyZ100_tr" transform="rotate(0)">
                      <rect
                        id="edpgNVz5OyZ100"
                        width="8.75"
                        height="15"
                        rx="0"
                        ry="0"
                        transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                        opacity="0"
                        filter="url(#edpgNVz5OyZ100-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="edpgNVz5OyZ101_to" transform="translate(423.548427,48.146297)">
                    <g id="edpgNVz5OyZ101_tr" transform="rotate(0)">
                      <rect
                        id="edpgNVz5OyZ101"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ102-filter)">
                  <path
                    id="edpgNVz5OyZ103"
                    d="M357.283,138.189v1.139493l.01-1.478h-.01"
                    transform="translate(65-104.797494)"
                    opacity="0"
                    fill="none"
                    stroke="url(#edpgNVz5OyZ103-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="edpgNVz5OyZ104_to" transform="translate(422.8,49.146297)">
                    <g id="edpgNVz5OyZ104_tr" transform="rotate(0)">
                      <rect
                        id="edpgNVz5OyZ104"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0"
                        filter="url(#edpgNVz5OyZ104-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="edpgNVz5OyZ105_to" transform="translate(422.8,49.146297)">
                    <g id="edpgNVz5OyZ105_tr" transform="rotate(0)">
                      <rect
                        id="edpgNVz5OyZ105"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="edpgNVz5OyZ106" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="80"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.963299 341.931986 0.75214)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g>
              <path d="M292.146,229.76v-108.078l78.228-44.9059v-76.43235" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ110-filter)">
                <path
                  id="edpgNVz5OyZ111"
                  d="M292.146,232.836923v-53.640071v0l.354003-.176549"
                  transform="translate(65-105.000001)"
                  fill="none"
                  stroke="url(#edpgNVz5OyZ111-stroke)"
                  strokeLinecap="round"
                />
                <g id="edpgNVz5OyZ112_to" transform="translate(357,70.792183)">
                  <g id="edpgNVz5OyZ112_tr" transform="rotate(0)">
                    <rect
                      id="edpgNVz5OyZ112"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0.7"
                      filter="url(#edpgNVz5OyZ112-filter)"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="edpgNVz5OyZ113_to" transform="translate(357,70.792183)">
                  <g id="edpgNVz5OyZ113_tr" transform="rotate(0)">
                    <rect
                      id="edpgNVz5OyZ113"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ114-filter)">
                <path
                  id="edpgNVz5OyZ115"
                  d="M292.146,239.76v-4.238702v0l1.354003-.75"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#edpgNVz5OyZ115-stroke)"
                  strokeLinecap="round"
                />
                <g id="edpgNVz5OyZ116_to" transform="translate(357.5,139.246297)">
                  <g id="edpgNVz5OyZ116_tr" transform="rotate(0)">
                    <rect
                      id="edpgNVz5OyZ116"
                      width="7.291667"
                      height="12.5"
                      rx="0"
                      ry="0"
                      transform="scale(0.96,0.96) translate(-3.645833,-6.25)"
                      opacity="0"
                      filter="url(#edpgNVz5OyZ116-filter)"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="edpgNVz5OyZ117_to" transform="translate(357.5,139.246297)">
                  <g id="edpgNVz5OyZ117_tr" transform="rotate(0)">
                    <rect
                      id="edpgNVz5OyZ117"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ118-filter)">
                <path
                  id="edpgNVz5OyZ119"
                  d="M292.146,239.76v-1.238702l.354001-2.512982.000002-.237018"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#edpgNVz5OyZ119-stroke)"
                  strokeLinecap="round"
                />
                <g id="edpgNVz5OyZ120_to" transform="translate(357.5,138.396297)">
                  <rect
                    id="edpgNVz5OyZ120"
                    width="7"
                    height="12"
                    rx="0"
                    ry="0"
                    transform="translate(-3.5,-6)"
                    opacity="0"
                    filter="url(#edpgNVz5OyZ120-filter)"
                    fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="edpgNVz5OyZ121_to" transform="translate(357.5,138.396297)">
                  <rect
                    id="edpgNVz5OyZ121"
                    width="10"
                    height="20"
                    rx="0"
                    ry="0"
                    transform="scale(0.5,0.5) translate(-5,-10)"
                    opacity="0"
                    fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
              </g>
            </g>
            <g mask="url(#edpgNVz5OyZ133)">
              <g transform="translate(.000001 0)">
                <path d="M226.994,138.053v-16.37L333.531,76.7383l.004-76.414117" fill="none" stroke="#6069ca" />
                <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ125-filter)">
                  <path
                    id="edpgNVz5OyZ126"
                    d="M226.994002,161.5213l-.000002-7.468298.106.000002-.070999-.000002"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#edpgNVz5OyZ126-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="edpgNVz5OyZ127_to" transform="translate(291.8,52.271297)">
                    <g id="edpgNVz5OyZ127_tr" transform="rotate(0)">
                      <rect
                        id="edpgNVz5OyZ127"
                        width="8.75"
                        height="15"
                        rx="0"
                        ry="0"
                        transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                        opacity="0"
                        filter="url(#edpgNVz5OyZ127-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="edpgNVz5OyZ128_to" transform="translate(291.8,52.271297)">
                    <g id="edpgNVz5OyZ128_tr" transform="rotate(0)">
                      <rect
                        id="edpgNVz5OyZ128"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ129-filter)">
                  <path
                    id="edpgNVz5OyZ130"
                    d="M226.994002,161.5213l-.000002-7.468298.106.000002-.070999-.000002"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#edpgNVz5OyZ130-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="edpgNVz5OyZ131_to" transform="translate(291.8,49.646297)">
                    <g id="edpgNVz5OyZ131_tr" transform="rotate(0)">
                      <rect
                        id="edpgNVz5OyZ131"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0"
                        filter="url(#edpgNVz5OyZ131-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="edpgNVz5OyZ132_to" transform="translate(291.8,49.646297)">
                    <g id="edpgNVz5OyZ132_tr" transform="rotate(0)">
                      <rect
                        id="edpgNVz5OyZ132"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="edpgNVz5OyZ133" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="140"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 209.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g>
              <path d="M161.867,229.879v-108.202L296.68,76.7222v-76.413669" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ137-filter)">
                <path
                  id="edpgNVz5OyZ138"
                  d="M161.867,259.879v-6.389002c0,0-.017-2.218699-.017-2.218699l-.051573-1.875005"
                  transform="translate(64.996501-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#edpgNVz5OyZ138-stroke)"
                  strokeLinecap="round"
                />
                <g id="edpgNVz5OyZ139_to" transform="translate(226.81,140.246297)">
                  <g id="edpgNVz5OyZ139_tr" transform="rotate(0)">
                    <rect
                      id="edpgNVz5OyZ139"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#edpgNVz5OyZ139-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="edpgNVz5OyZ140_to" transform="translate(226.81,140.246297)">
                  <g id="edpgNVz5OyZ140_tr" transform="rotate(0)">
                    <rect
                      id="edpgNVz5OyZ140"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ141-filter)">
                <path
                  id="edpgNVz5OyZ142"
                  d="M161.867,259.879v-6.389002l-.017-2.218699-.051573-1.875005"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#edpgNVz5OyZ142-stroke)"
                  strokeLinecap="round"
                />
                <g id="edpgNVz5OyZ143_to" transform="translate(226.8,139.146297)">
                  <g id="edpgNVz5OyZ143_tr" transform="rotate(0)">
                    <rect
                      id="edpgNVz5OyZ143"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#edpgNVz5OyZ143-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="edpgNVz5OyZ144_to" transform="translate(226.8,139.146297)">
                  <g id="edpgNVz5OyZ144_tr" transform="rotate(0)">
                    <rect
                      id="edpgNVz5OyZ144"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g mask="url(#edpgNVz5OyZ160)">
              <g transform="translate(.000001 0)">
                <path d="M96.7109,138.118v-16.438L259.817,76.7298v-76.38605" fill="none" stroke="#6069ca" />
                <g transform="translate(-64 105.000001)" filter="url(#edpgNVz5OyZ148-filter)">
                  <path
                    id="edpgNVz5OyZ149"
                    d="M96.711985,145.439636L96.7109,127.987317h.001085c0,0,0,0,0,0"
                    transform="translate(64-105.000001)"
                    fill="none"
                    stroke="url(#edpgNVz5OyZ149-stroke)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="edpgNVz5OyZ150_to" transform="translate(160.8,25.504306)">
                    <g id="edpgNVz5OyZ150_tr" transform="rotate(0)">
                      <rect
                        id="edpgNVz5OyZ150"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0.7"
                        filter="url(#edpgNVz5OyZ150-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="edpgNVz5OyZ151_to" transform="translate(160.8,25.504306)">
                    <g id="edpgNVz5OyZ151_tr" transform="rotate(0)">
                      <rect
                        id="edpgNVz5OyZ151"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ152-filter)">
                  <path
                    id="edpgNVz5OyZ153"
                    d="M96.7109,143.118v-.064999l.1973-.999999c0,0-.109773,1.999999-.109773,1.999999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#edpgNVz5OyZ153-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="edpgNVz5OyZ154_to" transform="translate(161.8,50.787316)">
                    <g id="edpgNVz5OyZ154_tr" transform="rotate(0)">
                      <rect
                        id="edpgNVz5OyZ154"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0"
                        filter="url(#edpgNVz5OyZ154-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="edpgNVz5OyZ155_to" transform="translate(161.8,50.787316)">
                    <g id="edpgNVz5OyZ155_tr" transform="rotate(0)">
                      <rect
                        id="edpgNVz5OyZ155"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ156-filter)">
                  <path
                    id="edpgNVz5OyZ157"
                    d="M96.7109,143.118v-.064999l.1973-.999999c0,0-.109773,1.999999-.109773,1.999999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#edpgNVz5OyZ157-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="edpgNVz5OyZ158_to" transform="translate(162,49.521297)">
                    <rect
                      id="edpgNVz5OyZ158"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#edpgNVz5OyZ158-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="edpgNVz5OyZ159_to" transform="translate(162,49.521297)">
                    <rect
                      id="edpgNVz5OyZ159"
                      width="5"
                      height="10"
                      rx="0"
                      ry="0"
                      transform="translate(-2.5,-5)"
                      opacity="0"
                      filter="url(#edpgNVz5OyZ159-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <mask id="edpgNVz5OyZ160" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 75.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g>
              <path d="M32.0957,229.88v-108.202L222.955,76.73v-76.413594" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ164-filter)">
                <path
                  id="edpgNVz5OyZ165"
                  d="M32.0957,249.88v-9.358702v0l1-.996864"
                  transform="translate(64.996286-105.187118)"
                  opacity="0"
                  fill="none"
                  stroke="url(#edpgNVz5OyZ165-stroke)"
                  strokeLinecap="round"
                />
                <g id="edpgNVz5OyZ166_to" transform="translate(96.798427,143.537316)">
                  <g id="edpgNVz5OyZ166_tr" transform="rotate(0)">
                    <rect
                      id="edpgNVz5OyZ166"
                      width="10"
                      height="17.142857"
                      rx="0"
                      ry="0"
                      transform="scale(0.7,0.7) translate(-5,-8.571429)"
                      opacity="0"
                      filter="url(#edpgNVz5OyZ166-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="edpgNVz5OyZ167_to" transform="translate(96.798427,142.237316)">
                  <g id="edpgNVz5OyZ167_tr" transform="rotate(0)">
                    <rect
                      id="edpgNVz5OyZ167"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      filter="url(#edpgNVz5OyZ167-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      strokeMiterlimit="8"
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#edpgNVz5OyZ168-filter)">
                <path
                  id="edpgNVz5OyZ169"
                  d="M32.0957,249.88v-10.732683h-.003714l1-1.001023"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#edpgNVz5OyZ169-stroke)"
                  strokeLinecap="round"
                />
                <g id="edpgNVz5OyZ170_to" transform="translate(96.8,140.121297)">
                  <g id="edpgNVz5OyZ170_tr" transform="rotate(0)">
                    <rect
                      id="edpgNVz5OyZ170"
                      width="10"
                      height="17.142857"
                      rx="0"
                      ry="0"
                      transform="scale(0.7,0.7) translate(-5,-8.571428)"
                      opacity="0"
                      filter="url(#edpgNVz5OyZ170-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="edpgNVz5OyZ171_to" transform="translate(96.8,140.121297)">
                  <g id="edpgNVz5OyZ171_tr" transform="rotate(0)">
                    <rect
                      id="edpgNVz5OyZ171"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <mask id="edpgNVz5OyZ172" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="825.762291"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #edpgNVz5OyZ9 {
    animation-name: edpgNVz5OyZ9__m, edpgNVz5OyZ9_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ9__m {
    0% {
      d: path('M853.309,172.852398L853.309,121.49963L764.741674,100.215254L764.220825,100.215253');
    }
    8.75% {
      d: path('M853.309,121.605025L853.309,121.49963L665.296,76.728L665.296,76.180546');
    }
    10% {
      d: path('M829.807375,115.995397L829.807375,115.903177L665.296,76.728L665.296,67.046162');
    }
    20% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
    100% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
  }
  @keyframes edpgNVz5OyZ9_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    16.25% {
      opacity: 1;
    }
    23.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ10_to {
    animation: edpgNVz5OyZ10_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ10_to__to {
    0% {
      transform: translate(828.317602px, -5.431736px);
    }
    7.5% {
      transform: translate(736.801035px, -27.5px);
    }
    8.75% {
      transform: translate(730.920607px, -32.028703px);
    }
    10% {
      transform: translate(730.9px, -38.280793px);
    }
    20% {
      transform: translate(731.219355px, -122.688381px);
    }
    100% {
      transform: translate(731.219355px, -122.688381px);
    }
  }
  #edpgNVz5OyZ10_tr {
    animation: edpgNVz5OyZ10_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ10_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    7.5% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ10 {
    animation: edpgNVz5OyZ10_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ10_c_o {
    0% {
      opacity: 0.7;
    }
    20% {
      opacity: 0.7;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ11_to {
    animation: edpgNVz5OyZ11_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ11_to__to {
    0% {
      transform: translate(828.317602px, -5.431736px);
    }
    7.5% {
      transform: translate(736.801035px, -27.63967px);
    }
    8.75% {
      transform: translate(730.851035px, -32.48073px);
    }
    10% {
      transform: translate(730.9px, -38.514307px);
    }
    20% {
      transform: translate(731.219354px, -122.688381px);
    }
    100% {
      transform: translate(731.219354px, -122.688381px);
    }
  }
  #edpgNVz5OyZ11_tr {
    animation: edpgNVz5OyZ11_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ11_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    7.5% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ11 {
    animation: edpgNVz5OyZ11_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ11_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ13 {
    animation-name: edpgNVz5OyZ13__m, edpgNVz5OyZ13_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ13__m {
    0% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    25% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    40% {
      d: path('M853.309,232.641L853.309,121.49963L853.309,121.605025L852.281356,122.594792');
    }
    56.25% {
      d: path('M853.309,121.605025L853.309,121.49963L665.296,76.728L665.296,76.180546');
    }
    68.75% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
    100% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
  }
  @keyframes edpgNVz5OyZ13_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    73.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ14_to {
    animation: edpgNVz5OyZ14_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ14_to__to {
    0% {
      transform: translate(917.8px, 138.546297px);
    }
    25% {
      transform: translate(917.8px, 138.546297px);
    }
    38.75% {
      transform: translate(917.798427px, 23.271297px);
    }
    40% {
      transform: translate(913.798427px, 15.771297px);
    }
    41.25% {
      transform: translate(905.3352px, 13.841812px);
    }
    55% {
      transform: translate(736.801035px, -27.5px);
    }
    56.25% {
      transform: translate(730.920607px, -32.510292px);
    }
    57.5% {
      transform: translate(730.9px, -38.49755px);
    }
    68.75% {
      transform: translate(730.9px, -120.728703px);
    }
    100% {
      transform: translate(730.9px, -120.728703px);
    }
  }
  #edpgNVz5OyZ14_tr {
    animation: edpgNVz5OyZ14_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ14_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-75deg);
    }
    55% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ14 {
    animation: edpgNVz5OyZ14_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ14_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 0.7;
    }
    68.75% {
      opacity: 0.7;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ15_to {
    animation: edpgNVz5OyZ15_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ15_to__to {
    0% {
      transform: translate(917.8px, 138.546297px);
    }
    25% {
      transform: translate(917.8px, 138.546297px);
    }
    38.75% {
      transform: translate(917.798427px, 23.271297px);
    }
    40% {
      transform: translate(913.568884px, 15.76033px);
    }
    41.25% {
      transform: translate(904.798427px, 13.4px);
    }
    55% {
      transform: translate(736.801035px, -27.43967px);
    }
    56.25% {
      transform: translate(730.920607px, -32.510292px);
    }
    57.5% {
      transform: translate(730.9px, -38.49755px);
    }
    68.75% {
      transform: translate(730.9px, -120.228703px);
    }
    100% {
      transform: translate(730.9px, -120.228703px);
    }
  }
  #edpgNVz5OyZ15_tr {
    animation: edpgNVz5OyZ15_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-75deg);
    }
    55% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ15 {
    animation: edpgNVz5OyZ15_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ15_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    68.75% {
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ17 {
    animation-name: edpgNVz5OyZ17__m, edpgNVz5OyZ17_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ17__m {
    0% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    76.25% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    90% {
      d: path('M853.309,235.090475L853.309,136.673426L853.091651,128.614266L853.091651,125.153139');
    }
    91.25% {
      d: path('M853.309,232.641L853.309,121.484831L853.060601,121.48483L850.265078,120.712728');
    }
    100% {
      d: path('M853.309,172.852398L853.309,121.49963L762.497203,100.468958L762.736434,100.468959');
    }
  }
  @keyframes edpgNVz5OyZ17_c_o {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    76.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #edpgNVz5OyZ18_to {
    animation: edpgNVz5OyZ18_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ18_to__to {
    0% {
      transform: translate(917.8px, 140.146297px);
    }
    75% {
      transform: translate(917.8px, 140.146297px);
    }
    90% {
      transform: translate(917.798427px, 23.771297px);
    }
    91.25% {
      transform: translate(913.798427px, 15.771297px);
    }
    92.5% {
      transform: translate(905.3352px, 13.841812px);
    }
    100% {
      transform: translate(826.798304px, -5.828881px);
    }
  }
  #edpgNVz5OyZ18_tr {
    animation: edpgNVz5OyZ18_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ18_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #edpgNVz5OyZ18 {
    animation: edpgNVz5OyZ18_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ18_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #edpgNVz5OyZ19_to {
    animation: edpgNVz5OyZ19_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ19_to__to {
    0% {
      transform: translate(917.8px, 140.146297px);
    }
    75% {
      transform: translate(917.8px, 140.146297px);
    }
    90% {
      transform: translate(917.798427px, 22.271297px);
    }
    91.25% {
      transform: translate(913.3px, 15.271297px);
    }
    92.5% {
      transform: translate(904.798427px, 13.4px);
    }
    100% {
      transform: translate(827.121519px, -5.98073px);
    }
  }
  #edpgNVz5OyZ19_tr {
    animation: edpgNVz5OyZ19_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ19_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #edpgNVz5OyZ19 {
    animation: edpgNVz5OyZ19_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ19_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #edpgNVz5OyZ24 {
    animation-name: edpgNVz5OyZ24__m, edpgNVz5OyZ24_c_o, edpgNVz5OyZ24_s_p;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ24__m {
    0% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    12.5% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    17.5% {
      d: path('M781.258,146.812429L781.258,144.110571L781.527118,145.664782L780.755126,143.963766');
    }
    21.25% {
      d: path('M781.258,138.241L781.258,121.682L779.797456,121.682L778.627971,122.696102');
    }
    36.25% {
      d: path('M781.258,121.622403L781.258,121.682L628.386,76.7624L628.386,77.3561');
    }
    47.5% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
    100% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
  }
  @keyframes edpgNVz5OyZ24_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    43.75% {
      opacity: 1;
    }
    52.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes edpgNVz5OyZ24_s_p {
    0% {
      stroke: url(#edpgNVz5OyZ24-stroke);
    }
    43.75% {
      stroke: url(#edpgNVz5OyZ24-stroke);
    }
    45% {
      stroke: url(#edpgNVz5OyZ24-stroke-g1);
    }
    46.25% {
      stroke: url(#edpgNVz5OyZ24-stroke-g1);
    }
    100% {
      stroke: url(#edpgNVz5OyZ24-stroke-g1);
    }
  }
  #edpgNVz5OyZ24-stroke-g1 {
    animation: edpgNVz5OyZ24-stroke-g1__t 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ24-stroke-g1__t {
    0% {
      transform: matrix(31.419354, 60.126366, -60.126366, 31.419354, 632.3, 15.752932);
    }
    45% {
      transform: matrix(31.419354, 60.126366, -60.126366, 31.419354, 632.3, 15.752932);
    }
    46.25% {
      transform: matrix(0, 60.177113, -60.177113, 0, 640.480978, 1.308581);
    }
    100% {
      transform: matrix(0, 60.177113, -60.177113, 0, 640.480978, 1.308581);
    }
  }
  #edpgNVz5OyZ24-stroke-g1-0 {
    animation: edpgNVz5OyZ24-stroke-g1-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ24-stroke-g1-0__c {
    0% {
      stop-color: #7ea6ff;
    }
    45% {
      stop-color: #7ea6ff;
    }
    46.25% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #edpgNVz5OyZ24-stroke-g1-1 {
    animation: edpgNVz5OyZ24-stroke-g1-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ24-stroke-g1-1__c {
    0% {
      stop-color: #6069ca;
    }
    45% {
      stop-color: #6069ca;
    }
    46.25% {
      stop-color: #6069ca;
    }
    100% {
      stop-color: #6069ca;
    }
  }
  #edpgNVz5OyZ24-stroke-g1-2 {
    animation: edpgNVz5OyZ24-stroke-g1-2__c 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ24-stroke-g1-2__c {
    0% {
      stop-color: rgba(64, 73, 168, 0);
    }
    45% {
      stop-color: rgba(64, 73, 168, 0);
    }
    46.25% {
      stop-color: rgba(64, 73, 168, 0);
    }
    100% {
      stop-color: rgba(64, 73, 168, 0);
    }
  }
  #edpgNVz5OyZ25_to {
    animation: edpgNVz5OyZ25_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ25_to__to {
    0% {
      transform: translate(846.5px, 51.646297px);
    }
    12.5% {
      transform: translate(846.5px, 51.646297px);
    }
    20% {
      transform: translate(846.5px, 23.771297px);
    }
    21.25% {
      transform: translate(842.798427px, 15.771297px);
    }
    22.5% {
      transform: translate(831.3352px, 12.841812px);
    }
    35% {
      transform: translate(699.801035px, -26.231881px);
    }
    36.25% {
      transform: translate(693.5px, -31.028703px);
    }
    37.5% {
      transform: translate(693.5px, -38.280793px);
    }
    47.5% {
      transform: translate(693.9px, -120.728703px);
    }
    100% {
      transform: translate(693.9px, -120.728703px);
    }
  }
  #edpgNVz5OyZ25_tr {
    animation: edpgNVz5OyZ25_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ25_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-73deg);
    }
    35% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ25 {
    animation: edpgNVz5OyZ25_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ25_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.7;
    }
    47.5% {
      opacity: 0.7;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ26_to {
    animation: edpgNVz5OyZ26_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ26_to__to {
    0% {
      transform: translate(846.5px, 51.646297px);
    }
    12.5% {
      transform: translate(846.5px, 51.646297px);
    }
    20% {
      transform: translate(846.5px, 22.271297px);
    }
    21.25% {
      transform: translate(842.3px, 15.5px);
    }
    22.5% {
      transform: translate(830.604721px, 12.350134px);
    }
    35% {
      transform: translate(699.801035px, -26.390926px);
    }
    36.25% {
      transform: translate(693.411987px, -31.390926px);
    }
    37.5% {
      transform: translate(693.5px, -38.514307px);
    }
    47.5% {
      transform: translate(693.9px, -122.228703px);
    }
    100% {
      transform: translate(693.9px, -122.228703px);
    }
  }
  #edpgNVz5OyZ26_tr {
    animation: edpgNVz5OyZ26_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ26_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-73deg);
    }
    35% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ26 {
    animation: edpgNVz5OyZ26_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ26_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    47.5% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ28 {
    animation-name: edpgNVz5OyZ28__m, edpgNVz5OyZ28_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ28__m {
    0% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    51.25% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    56.25% {
      d: path('M781.258,146.812429L781.258,144.110571L781.527118,145.664782L780.755126,143.963766');
    }
    60% {
      d: path('M781.258,138.241L781.258,121.682L779.797456,121.682L778.627971,122.696102');
    }
    73.75% {
      d: path('M781.258,121.622403L781.258,121.682L628.386,76.7624L628.386,77.3561');
    }
    86.25% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
    100% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
  }
  @keyframes edpgNVz5OyZ28_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 1;
    }
    91.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ29_to {
    animation: edpgNVz5OyZ29_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ29_to__to {
    0% {
      transform: translate(846.5px, 51.146297px);
    }
    51.25% {
      transform: translate(846.5px, 51.146297px);
    }
    58.75% {
      transform: translate(846.5px, 23.771297px);
    }
    60% {
      transform: translate(842.798427px, 15.771297px);
    }
    61.25% {
      transform: translate(831.3352px, 12.841812px);
    }
    72.5% {
      transform: translate(699.801035px, -26.231881px);
    }
    73.75% {
      transform: translate(693.5px, -31.028703px);
    }
    75% {
      transform: translate(693.5px, -38.280793px);
    }
    86.25% {
      transform: translate(693.9px, -119.728703px);
    }
    100% {
      transform: translate(693.9px, -119.728703px);
    }
  }
  #edpgNVz5OyZ29_tr {
    animation: edpgNVz5OyZ29_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ29_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-73deg);
    }
    72.5% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ29 {
    animation: edpgNVz5OyZ29_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ29_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 0.7;
    }
    86.25% {
      opacity: 0.7;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ30_to {
    animation: edpgNVz5OyZ30_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ30_to__to {
    0% {
      transform: translate(846.5px, 51.146297px);
    }
    51.25% {
      transform: translate(846.5px, 51.146297px);
    }
    58.75% {
      transform: translate(846.5px, 22.271297px);
    }
    60% {
      transform: translate(841.104721px, 14.850134px);
    }
    61.25% {
      transform: translate(830.798427px, 12.350134px);
    }
    72.5% {
      transform: translate(698.801035px, -26.8px);
    }
    73.75% {
      transform: translate(693.411987px, -31.290926px);
    }
    75% {
      transform: translate(693.5px, -38.514307px);
    }
    86.25% {
      transform: translate(693.9px, -121.228703px);
    }
    100% {
      transform: translate(693.9px, -121.228703px);
    }
  }
  #edpgNVz5OyZ30_tr {
    animation: edpgNVz5OyZ30_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ30_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-73deg);
    }
    72.5% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ30 {
    animation: edpgNVz5OyZ30_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ30_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    86.25% {
      opacity: 1;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ36 {
    animation-name: edpgNVz5OyZ36__m, edpgNVz5OyZ36_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ36__m {
    0% {
      d: path(
        'M692.384111,111.206804L692.384111,111.087919L591.532,76.7311C591.532,76.7311,591.54,68.945745,591.54,68.945745'
      );
    }
    10% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-20.261899,591.54,-20.261899'
      );
    }
    100% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-20.261899,591.54,-20.261899'
      );
    }
  }
  @keyframes edpgNVz5OyZ36_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    3.75% {
      opacity: 1;
    }
    12.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ37_to {
    animation: edpgNVz5OyZ37_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ37_to__to {
    0% {
      transform: translate(656.5px, -35.643085px);
    }
    10% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #edpgNVz5OyZ37 {
    animation: edpgNVz5OyZ37_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ37_c_o {
    0% {
      opacity: 0.7;
    }
    10% {
      opacity: 0.7;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ38_to {
    animation: edpgNVz5OyZ38_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ38_to__to {
    0% {
      transform: translate(656.5px, -35.643085px);
    }
    10% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #edpgNVz5OyZ38 {
    animation: edpgNVz5OyZ38_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ38_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    10% {
      opacity: 1;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ40 {
    animation-name: edpgNVz5OyZ40__m, edpgNVz5OyZ40_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ40__m {
    0% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    22.5% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    36.25% {
      d: path(
        'M721.199,234.531667L721.199,136.064L721.192993,131.635822C721.192993,131.635822,721.192993,128.271298,721.192993,128.271298'
      );
    }
    37.5% {
      d: path(
        'M721.199,232.865L721.199,120.814L721.192993,120.949437C721.192993,120.949437,721.192993,120.410429,721.192993,120.410429'
      );
    }
    51.25% {
      d: path('M721.199,121.057004L721.199,120.814L591.532,76.7311C591.532,76.7311,591.54,75.862215,591.54,75.862215');
    }
    60% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-0.261899,591.54,-0.261899'
      );
    }
    100% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-0.261899,591.54,-0.261899'
      );
    }
  }
  @keyframes edpgNVz5OyZ40_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    57.5% {
      opacity: 1;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ41_to {
    animation: edpgNVz5OyZ41_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ41_to__to {
    0% {
      transform: translate(786.2px, 139.146297px);
    }
    22.5% {
      transform: translate(786.2px, 139.146297px);
    }
    36.25% {
      transform: translate(786.2px, 23.521297px);
    }
    37.5% {
      transform: translate(784.46587px, 14.771297px);
    }
    38.75% {
      transform: translate(774.3352px, 10.841812px);
    }
    50% {
      transform: translate(660.801035px, -27.231881px);
    }
    51.25% {
      transform: translate(656.920607px, -31.028703px);
    }
    52.5% {
      transform: translate(656.9px, -38.280793px);
    }
    62.5% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #edpgNVz5OyZ41_tr {
    animation: edpgNVz5OyZ41_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ41_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(-70deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ41 {
    animation: edpgNVz5OyZ41_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ41_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 0.7;
    }
    62.5% {
      opacity: 0.7;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ42_to {
    animation: edpgNVz5OyZ42_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ42_to__to {
    0% {
      transform: translate(786.2px, 139.146297px);
    }
    22.5% {
      transform: translate(786.2px, 139.146297px);
    }
    36.25% {
      transform: translate(786.2px, 23.521297px);
    }
    37.5% {
      transform: translate(784.46587px, 14.771297px);
    }
    38.75% {
      transform: translate(773.8px, 10.9px);
    }
    50% {
      transform: translate(659.801035px, -27.7px);
    }
    51.25% {
      transform: translate(656.851035px, -32.387789px);
    }
    52.5% {
      transform: translate(656.9px, -38.514307px);
    }
    62.5% {
      transform: translate(656.9px, -121.228703px);
    }
    100% {
      transform: translate(656.9px, -121.228703px);
    }
  }
  #edpgNVz5OyZ42_tr {
    animation: edpgNVz5OyZ42_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ42_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(-70deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ42 {
    animation: edpgNVz5OyZ42_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ42_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    62.5% {
      opacity: 1;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ44 {
    animation-name: edpgNVz5OyZ44__m, edpgNVz5OyZ44_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ44__m {
    0% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    68.75% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    82.5% {
      d: path(
        'M721.199,234.531667L721.199,137.56998L721.168089,132.142195C721.168089,132.142195,721.168089,127.896302,721.168089,127.896302'
      );
    }
    83.75% {
      d: path(
        'M721.199,232.865L721.199,120.72838L721.199,120.72838C721.199,120.72838,719.29007,120.132167,719.29007,120.132167'
      );
    }
    97.5% {
      d: path('M721.199,121.057004L721.199,120.814L591.532,76.7311C591.532,76.7311,591.54,75.862215,591.54,75.862215');
    }
    100% {
      d: path(
        'M692.384111,111.206804L692.384111,111.087919L591.532,76.7311C591.532,76.7311,591.54,68.945745,591.54,68.945745'
      );
    }
  }
  @keyframes edpgNVz5OyZ44_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #edpgNVz5OyZ45_to {
    animation: edpgNVz5OyZ45_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ45_to__to {
    0% {
      transform: translate(786.5px, 138.146297px);
    }
    68.75% {
      transform: translate(786.5px, 138.146297px);
    }
    82.5% {
      transform: translate(786.5px, 23.771297px);
    }
    83.75% {
      transform: translate(784.29007px, 15.771297px);
    }
    85% {
      transform: translate(774.3352px, 11.841812px);
    }
    96.25% {
      transform: translate(661.801035px, -26.231881px);
    }
    97.5% {
      transform: translate(656.920607px, -31.028703px);
    }
    98.75% {
      transform: translate(656.9px, -38.280793px);
    }
    100% {
      transform: translate(656.9px, -40.096471px);
    }
  }
  #edpgNVz5OyZ45_tr {
    animation: edpgNVz5OyZ45_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ45_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-70deg);
    }
    96.25% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ45 {
    animation: edpgNVz5OyZ45_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ45_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #edpgNVz5OyZ46_to {
    animation: edpgNVz5OyZ46_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ46_to__to {
    0% {
      transform: translate(786.5px, 138.146297px);
    }
    68.75% {
      transform: translate(786.5px, 138.146297px);
    }
    82.5% {
      transform: translate(786.5px, 23.271297px);
    }
    83.75% {
      transform: translate(784.29007px, 15.771297px);
    }
    85% {
      transform: translate(773.8px, 11.9px);
    }
    96.25% {
      transform: translate(660.801035px, -26.846729px);
    }
    97.5% {
      transform: translate(656.851035px, -31.791881px);
    }
    98.75% {
      transform: translate(656.9px, -38.514307px);
    }
    100% {
      transform: translate(656.9px, -40.096471px);
    }
  }
  #edpgNVz5OyZ46_tr {
    animation: edpgNVz5OyZ46_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ46_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-70deg);
    }
    96.25% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ46 {
    animation: edpgNVz5OyZ46_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ46_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #edpgNVz5OyZ51 {
    animation-name: edpgNVz5OyZ51__m, edpgNVz5OyZ51_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ51__m {
    0% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    5% {
      d: path('M650.977,146.653429L650.977,145.824857L650.761566,146.078382L650.761569,145.329073');
    }
    8.75% {
      d: path('M650.977,138.082L650.977,121.682L650.560991,122.125667L650.560995,122.622405');
    }
    22.5% {
      d: path('M650.977,122.082L650.977,121.682L554.656,76.7305L554.652,76.316494');
    }
    35% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
    100% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
  }
  @keyframes edpgNVz5OyZ51_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    31.25% {
      opacity: 1;
    }
    40% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ52_to {
    animation: edpgNVz5OyZ52_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ52_to__to {
    0% {
      transform: translate(715.8px, 52.146297px);
    }
    7.5% {
      transform: translate(715.798427px, 23.771297px);
    }
    8.75% {
      transform: translate(712.798427px, 15.771297px);
    }
    10% {
      transform: translate(706.3352px, 12.841812px);
    }
    21.25% {
      transform: translate(624.801035px, -25.231881px);
    }
    22.5% {
      transform: translate(619.920607px, -31.216326px);
    }
    23.75% {
      transform: translate(619.5px, -38.280793px);
    }
    35% {
      transform: translate(619.9px, -121.728703px);
    }
    100% {
      transform: translate(619.9px, -121.728703px);
    }
  }
  #edpgNVz5OyZ52_tr {
    animation: edpgNVz5OyZ52_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ52_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-68deg);
    }
    21.25% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ52 {
    animation: edpgNVz5OyZ52_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ52_c_o {
    0% {
      opacity: 0.7;
    }
    35% {
      opacity: 0.7;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ53_to {
    animation: edpgNVz5OyZ53_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ53_to__to {
    0% {
      transform: translate(715.8px, 52.146297px);
    }
    7.5% {
      transform: translate(715.798427px, 22.271297px);
    }
    8.75% {
      transform: translate(711.798427px, 15.271297px);
    }
    10% {
      transform: translate(705.798427px, 12.6px);
    }
    21.25% {
      transform: translate(623.801035px, -26.140845px);
    }
    22.5% {
      transform: translate(619.911987px, -31.216326px);
    }
    23.75% {
      transform: translate(619.5px, -38.514307px);
    }
    35% {
      transform: translate(619.9px, -122.228703px);
    }
    100% {
      transform: translate(619.9px, -122.228703px);
    }
  }
  #edpgNVz5OyZ53_tr {
    animation: edpgNVz5OyZ53_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ53_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-68deg);
    }
    21.25% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ53 {
    animation: edpgNVz5OyZ53_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ53_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    35% {
      opacity: 1;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ55 {
    animation-name: edpgNVz5OyZ55__m, edpgNVz5OyZ55_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ55__m {
    0% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    43.75% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    48.75% {
      d: path('M650.977,146.653429L650.977,145.824857L650.761566,146.078382L650.761569,145.329073');
    }
    52.5% {
      d: path('M650.977,138.082L650.977,121.682L650.560991,122.125667L650.560995,122.622405');
    }
    66.25% {
      d: path('M650.977,122.082L650.977,121.682L554.656,76.7305L554.652,76.316494');
    }
    78.75% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
    100% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
  }
  @keyframes edpgNVz5OyZ55_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    83.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ56_to {
    animation: edpgNVz5OyZ56_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ56_to__to {
    0% {
      transform: translate(715.298427px, 50.146297px);
    }
    43.75% {
      transform: translate(715.298427px, 50.146297px);
    }
    51.25% {
      transform: translate(715.798427px, 23.521297px);
    }
    52.5% {
      transform: translate(713.798427px, 15.771297px);
    }
    53.75% {
      transform: translate(707.3352px, 12.841812px);
    }
    65% {
      transform: translate(624.801035px, -25.231881px);
    }
    66.25% {
      transform: translate(619.920607px, -29.810292px);
    }
    67.5% {
      transform: translate(619.5px, -36.79755px);
    }
    78.75% {
      transform: translate(619.9px, -117.878703px);
    }
    100% {
      transform: translate(619.9px, -117.878703px);
    }
  }
  #edpgNVz5OyZ56_tr {
    animation: edpgNVz5OyZ56_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ56_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-68deg);
    }
    65% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ56 {
    animation: edpgNVz5OyZ56_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ56_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 0.7;
    }
    78.75% {
      opacity: 0.7;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ57_to {
    animation: edpgNVz5OyZ57_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ57_to__to {
    0% {
      transform: translate(715.298427px, 50.146297px);
    }
    43.75% {
      transform: translate(715.298427px, 50.146297px);
    }
    51.25% {
      transform: translate(715.798427px, 23.521297px);
    }
    52.5% {
      transform: translate(713.798427px, 15.771297px);
    }
    53.75% {
      transform: translate(707.3352px, 12.841812px);
    }
    65% {
      transform: translate(626.801035px, -24.6px);
    }
    66.25% {
      transform: translate(619.920607px, -29.810292px);
    }
    67.5% {
      transform: translate(619.5px, -36.79755px);
    }
    78.75% {
      transform: translate(619.9px, -117.878703px);
    }
    100% {
      transform: translate(619.9px, -117.878703px);
    }
  }
  #edpgNVz5OyZ57_tr {
    animation: edpgNVz5OyZ57_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ57_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-68deg);
    }
    65% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ57 {
    animation: edpgNVz5OyZ57_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ57_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 1;
    }
    78.75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ63 {
    animation-name: edpgNVz5OyZ63__m, edpgNVz5OyZ63_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ63__m {
    0% {
      d: path('M590.336,238.051545L590.336,175.993972L590.336001,176.26135L590.093553,175.993973');
    }
    7.5% {
      d: path('M590.336,232.597L590.336,125.817L590.336001,127.215895L588.964636,125.817002');
    }
    21.25% {
      d: path('M590.336,125.761512L590.336,125.817L517.819,80.7272L517.818,79.9168');
    }
    32.5% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
    100% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
  }
  @keyframes edpgNVz5OyZ63_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    37.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ64_to {
    animation: edpgNVz5OyZ64_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ64_to__to {
    0% {
      transform: translate(655.5px, 73.020302px);
    }
    6.25% {
      transform: translate(655.798427px, 27.771297px);
    }
    7.5% {
      transform: translate(653.798427px, 20.771297px);
    }
    8.75% {
      transform: translate(643.3352px, 13.841812px);
    }
    20% {
      transform: translate(586.801035px, -20.7px);
    }
    21.25% {
      transform: translate(582.920607px, -27.028703px);
    }
    22.5% {
      transform: translate(582.9px, -38.280793px);
    }
    33.75% {
      transform: translate(582.9px, -115.728703px);
    }
    100% {
      transform: translate(582.9px, -115.728703px);
    }
  }
  #edpgNVz5OyZ64_tr {
    animation: edpgNVz5OyZ64_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ64_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    6.25% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(-60deg);
    }
    20% {
      transform: rotate(-60deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ64 {
    animation: edpgNVz5OyZ64_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ64_c_o {
    0% {
      opacity: 0.7;
    }
    33.75% {
      opacity: 0.7;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ65_to {
    animation: edpgNVz5OyZ65_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ65_to__to {
    0% {
      transform: translate(655.5px, 73.020302px);
    }
    6.25% {
      transform: translate(655.798427px, 26.271297px);
    }
    7.5% {
      transform: translate(652.726569px, 19.844367px);
    }
    8.75% {
      transform: translate(642.798427px, 13.25px);
    }
    20% {
      transform: translate(586.801035px, -20.855633px);
    }
    21.25% {
      transform: translate(582.851035px, -27.791881px);
    }
    22.5% {
      transform: translate(582.9px, -38.514307px);
    }
    33.75% {
      transform: translate(582.9px, -115.228703px);
    }
    100% {
      transform: translate(582.9px, -115.228703px);
    }
  }
  #edpgNVz5OyZ65_tr {
    animation: edpgNVz5OyZ65_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ65_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    6.25% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(-60deg);
    }
    20% {
      transform: rotate(-60deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ65 {
    animation: edpgNVz5OyZ65_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ65_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    33.75% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ67 {
    animation-name: edpgNVz5OyZ67__m, edpgNVz5OyZ67_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ67__m {
    0% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    36.25% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    51.25% {
      d: path('M590.336,232.597L590.336,125.817L590.336,125.817002L589.889103,125.553177');
    }
    66.25% {
      d: path('M590.336,125.761512L590.336,125.817L517.819,80.7272L517.818,79.9168');
    }
    78.75% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
    100% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
  }
  @keyframes edpgNVz5OyZ67_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    72.5% {
      opacity: 1;
    }
    83.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ68_to {
    animation: edpgNVz5OyZ68_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ68_to__to {
    0% {
      transform: translate(655.4px, 137.771297px);
    }
    37.5% {
      transform: translate(655.4px, 137.771297px);
    }
    50% {
      transform: translate(655.4px, 27.521297px);
    }
    51.25% {
      transform: translate(653.798427px, 20.771297px);
    }
    52.5% {
      transform: translate(647.3352px, 16.841812px);
    }
    65% {
      transform: translate(586.801035px, -20.7px);
    }
    66.25% {
      transform: translate(582.920607px, -27.028703px);
    }
    67.5% {
      transform: translate(582.960993px, -38.280793px);
    }
    78.75% {
      transform: translate(582.9px, -116.728703px);
    }
    100% {
      transform: translate(582.9px, -116.728703px);
    }
  }
  #edpgNVz5OyZ68_tr {
    animation: edpgNVz5OyZ68_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ68_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(-60deg);
    }
    65% {
      transform: rotate(-60deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ68 {
    animation: edpgNVz5OyZ68_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ68_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 0.7;
    }
    78.75% {
      opacity: 0.7;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ69_to {
    animation: edpgNVz5OyZ69_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ69_to__to {
    0% {
      transform: translate(655.4px, 137.771297px);
    }
    37.5% {
      transform: translate(655.4px, 137.771297px);
    }
    50% {
      transform: translate(655.4px, 27.521297px);
    }
    51.25% {
      transform: translate(653.798427px, 20.771297px);
    }
    52.5% {
      transform: translate(647.3352px, 16.841812px);
    }
    65% {
      transform: translate(586.801035px, -20.855633px);
    }
    66.25% {
      transform: translate(582.911986px, -27.262684px);
    }
    67.5% {
      transform: translate(582.560992px, -38.762684px);
    }
    78.75% {
      transform: translate(582.9px, -118.228703px);
    }
    100% {
      transform: translate(582.9px, -118.228703px);
    }
  }
  #edpgNVz5OyZ69_tr {
    animation: edpgNVz5OyZ69_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ69_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(-60deg);
    }
    65% {
      transform: rotate(-60deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ69 {
    animation: edpgNVz5OyZ69_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ69_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    78.75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ71 {
    animation-name: edpgNVz5OyZ71__m, edpgNVz5OyZ71_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ71__m {
    0% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    90% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    100% {
      d: path('M590.336,239.869727L590.336,177.052962L590.336001,176.943168L590.469859,177.052963');
    }
  }
  @keyframes edpgNVz5OyZ71_c_o {
    0% {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    91.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #edpgNVz5OyZ72_to {
    animation: edpgNVz5OyZ72_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ72_to__to {
    0% {
      transform: translate(655.5px, 137.646297px);
    }
    90% {
      transform: translate(655.5px, 137.646297px);
    }
    100% {
      transform: translate(655.5px, 71.825773px);
    }
  }
  #edpgNVz5OyZ72 {
    animation: edpgNVz5OyZ72_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ72_c_o {
    0% {
      opacity: 0;
    }
    88.75% {
      opacity: 0;
    }
    90% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #edpgNVz5OyZ73_to {
    animation: edpgNVz5OyZ73_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ73_to__to {
    0% {
      transform: translate(655.5px, 137.646297px);
    }
    90% {
      transform: translate(655.5px, 137.646297px);
    }
    100% {
      transform: translate(655.5px, 71.825773px);
    }
  }
  #edpgNVz5OyZ73 {
    animation: edpgNVz5OyZ73_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ73_c_o {
    0% {
      opacity: 0;
    }
    88.75% {
      opacity: 0;
    }
    90% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #edpgNVz5OyZ78 {
    animation-name: edpgNVz5OyZ78__m, edpgNVz5OyZ78_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ78__m {
    0% {
      d: path('M520.668,121.038711L520.668,121.681L480.969,76.7549L480.974,72.075139');
    }
    11.25% {
      d: path('M480.974,77.356098L480.974,77.356096L480.969,76.7549L480.974,-19.753743');
    }
    100% {
      d: path('M480.974,77.356098L480.974,77.356096L480.969,76.7549L480.974,-19.753743');
    }
  }
  @keyframes edpgNVz5OyZ78_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ79_to {
    animation: edpgNVz5OyZ79_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ79_to__to {
    0% {
      transform: translate(546.2px, -36.480793px);
    }
    11.25% {
      transform: translate(545.9px, -121.728703px);
    }
    100% {
      transform: translate(545.9px, -121.728703px);
    }
  }
  #edpgNVz5OyZ79 {
    animation: edpgNVz5OyZ79_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ79_c_o {
    0% {
      opacity: 0.7;
    }
    11.25% {
      opacity: 0.7;
    }
    12.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ80_to {
    animation: edpgNVz5OyZ80_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ80_to__to {
    0% {
      transform: translate(546.2px, -37.480793px);
    }
    11.25% {
      transform: translate(545.9px, -121.228703px);
    }
    100% {
      transform: translate(545.9px, -121.228703px);
    }
  }
  #edpgNVz5OyZ80 {
    animation: edpgNVz5OyZ80_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ80_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    11.25% {
      opacity: 1;
    }
    12.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ82 {
    animation-name: edpgNVz5OyZ82__m, edpgNVz5OyZ82_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ82__m {
    0% {
      d: path('M520.668,153.079L520.668,150.301025L520.668,149.559706L520.668,147.771298');
    }
    21.25% {
      d: path('M520.668,153.079L520.668,150.301025L520.668,149.559706L520.668,147.771298');
    }
    31.25% {
      d: path('M520.668,138.079L520.668,121.681L520.096524,122.539067L520.900001,121.569396');
    }
    45% {
      d: path('M520.668,122.079L520.668,121.681L480.969,76.7549L480.974,74.246257');
    }
    56.25% {
      d: path('M480.974,77.356098L480.974,77.356096L480.969,76.7549L480.974,-19.753743');
    }
    100% {
      d: path('M480.974,77.356098L480.974,77.356096L480.969,76.7549L480.974,-19.753743');
    }
  }
  @keyframes edpgNVz5OyZ82_c_o {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    21.25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    58.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ83_to {
    animation: edpgNVz5OyZ83_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ83_to__to {
    0% {
      transform: translate(585.8px, 49.646297px);
    }
    21.25% {
      transform: translate(585.8px, 49.646297px);
    }
    30% {
      transform: translate(585.798427px, 23.521297px);
    }
    31.25% {
      transform: translate(583.798427px, 15.728404px);
    }
    32.5% {
      transform: translate(580.3352px, 10.841812px);
    }
    43.75% {
      transform: translate(547.7px, -26.2px);
    }
    45% {
      transform: translate(545.920607px, -30.028703px);
    }
    46.25% {
      transform: translate(545.9px, -38.280793px);
    }
    56.25% {
      transform: translate(545.9px, -119.728703px);
    }
    100% {
      transform: translate(545.9px, -119.728703px);
    }
  }
  #edpgNVz5OyZ83_tr {
    animation: edpgNVz5OyZ83_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ83_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    31.25% {
      transform: rotate(-45deg);
    }
    43.75% {
      transform: rotate(-45deg);
    }
    45% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ83 {
    animation: edpgNVz5OyZ83_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ83_c_o {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    21.25% {
      opacity: 0.7;
    }
    56.25% {
      opacity: 0.7;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ84_to {
    animation: edpgNVz5OyZ84_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ84_to__to {
    0% {
      transform: translate(585.8px, 49.646297px);
    }
    21.25% {
      transform: translate(585.8px, 49.646297px);
    }
    30% {
      transform: translate(585.798427px, 23.521297px);
    }
    31.25% {
      transform: translate(583.798427px, 15.728404px);
    }
    32.5% {
      transform: translate(579.798427px, 10.099627px);
    }
    43.75% {
      transform: translate(547.624298px, -26.300373px);
    }
    45% {
      transform: translate(545.851035px, -30.262684px);
    }
    46.25% {
      transform: translate(545.9px, -38.514307px);
    }
    56.25% {
      transform: translate(545.9px, -120.228703px);
    }
    100% {
      transform: translate(545.9px, -120.228703px);
    }
  }
  #edpgNVz5OyZ84_tr {
    animation: edpgNVz5OyZ84_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ84_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    31.25% {
      transform: rotate(-45deg);
    }
    43.75% {
      transform: rotate(-45deg);
    }
    45% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ84 {
    animation: edpgNVz5OyZ84_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ84_c_o {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    21.25% {
      opacity: 1;
    }
    56.25% {
      opacity: 1;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ86 {
    animation-name: edpgNVz5OyZ86__m, edpgNVz5OyZ86_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ86__m {
    0% {
      d: path('M520.668,153.079L520.668,150.301025L520.668,149.559706L520.668,147.771298');
    }
    75% {
      d: path('M520.668,153.079L520.668,150.301025L520.668,149.559706L520.668,147.771298');
    }
    83.75% {
      d: path('M520.668,138.079L520.668,121.681L520.096524,122.539067L520.900001,121.569396');
    }
    98.75% {
      d: path('M520.668,122.079L520.668,121.681L480.969,76.7549L480.974,74.246257');
    }
    100% {
      d: path('M520.668,127.412333L520.668,121.681L480.969,76.7549L480.974,66.135146');
    }
  }
  @keyframes edpgNVz5OyZ86_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #edpgNVz5OyZ87_to {
    animation: edpgNVz5OyZ87_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ87_to__to {
    0% {
      transform: translate(585.8px, 49.146297px);
    }
    75% {
      transform: translate(585.8px, 49.146297px);
    }
    82.5% {
      transform: translate(585.798427px, 23.521297px);
    }
    83.75% {
      transform: translate(583.798427px, 15.728404px);
    }
    85% {
      transform: translate(580.3352px, 10.841812px);
    }
    97.5% {
      transform: translate(547.7px, -26.2px);
    }
    98.75% {
      transform: translate(545.920607px, -30.028703px);
    }
    100% {
      transform: translate(546.2px, -38.021739px);
    }
  }
  #edpgNVz5OyZ87_tr {
    animation: edpgNVz5OyZ87_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ87_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-45deg);
    }
    97.5% {
      transform: rotate(-45deg);
    }
    98.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ87 {
    animation: edpgNVz5OyZ87_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ87_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #edpgNVz5OyZ88_to {
    animation: edpgNVz5OyZ88_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ88_to__to {
    0% {
      transform: translate(585.8px, 49.146297px);
    }
    75% {
      transform: translate(585.8px, 49.146297px);
    }
    82.5% {
      transform: translate(585.798427px, 23.521297px);
    }
    83.75% {
      transform: translate(583.798427px, 15.728404px);
    }
    85% {
      transform: translate(579.798427px, 10px);
    }
    97.5% {
      transform: translate(547.124298px, -26.800373px);
    }
    98.75% {
      transform: translate(545.851035px, -30.028703px);
    }
    100% {
      transform: translate(546.2px, -38.021739px);
    }
  }
  #edpgNVz5OyZ88_tr {
    animation: edpgNVz5OyZ88_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ88_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-45deg);
    }
    97.5% {
      transform: rotate(-45deg);
    }
    98.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ88 {
    animation: edpgNVz5OyZ88_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ88_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #edpgNVz5OyZ95 {
    animation-name: edpgNVz5OyZ95__m, edpgNVz5OyZ95_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ95__m {
    0% {
      d: path(
        'M357.283,138.189L357.283,121.683L384.629502,96.675565C384.629502,96.675565,384.629502,96.851652,384.629502,96.851652'
      );
    }
    7.5% {
      d: path(
        'M357.293,122.419896L357.283,121.683L407.238,76.6992C407.238,76.6992,407.900001,71.065917,407.900001,71.065917'
      );
    }
    8.75% {
      d: path(
        'M367.282,113.129698L367.274,112.540182L407.238,76.553141C407.238,76.553141,407.9,63.316701,407.9,63.316701'
      );
    }
    18.75% {
      d: path(
        'M407.238,75.676791L407.238,75.67679L407.238,75.67679C407.238,75.67679,407.238,-4.231209,407.238,-4.231209'
      );
    }
    100% {
      d: path(
        'M407.238,75.676791L407.238,75.67679L407.238,75.67679C407.238,75.67679,407.238,-4.231209,407.238,-4.231209'
      );
    }
  }
  @keyframes edpgNVz5OyZ95_c_o {
    0% {
      opacity: 0.8;
    }
    15% {
      opacity: 0.8;
    }
    23.75% {
      opacity: 0.5;
    }
    100% {
      opacity: 0.5;
    }
  }
  #edpgNVz5OyZ96_to {
    animation: edpgNVz5OyZ96_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ96_to__to {
    0% {
      transform: translate(449.275947px, -6.937495px);
    }
    6.25% {
      transform: translate(470.801035px, -27.231881px);
    }
    7.5% {
      transform: translate(472.202997px, -32.010292px);
    }
    8.75% {
      transform: translate(472.2px, -38.280793px);
    }
    20% {
      transform: translate(472.2px, -118.878703px);
    }
    100% {
      transform: translate(472.2px, -118.878703px);
    }
  }
  #edpgNVz5OyZ96_tr {
    animation: edpgNVz5OyZ96_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ96_tr__tr {
    0% {
      transform: rotate(45deg);
    }
    6.25% {
      transform: rotate(45deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ96 {
    animation: edpgNVz5OyZ96_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ96_c_o {
    0% {
      opacity: 0.7;
    }
    20% {
      opacity: 0.7;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ97_to {
    animation: edpgNVz5OyZ97_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ97_to__to {
    0% {
      transform: translate(449.275947px, -6.937495px);
    }
    6.25% {
      transform: translate(470.4px, -26.891881px);
    }
    7.5% {
      transform: translate(472.202997px, -32.010292px);
    }
    8.75% {
      transform: translate(472.2px, -38.514307px);
    }
    20% {
      transform: translate(472.2px, -118.878703px);
    }
    100% {
      transform: translate(472.2px, -118.878703px);
    }
  }
  #edpgNVz5OyZ97_tr {
    animation: edpgNVz5OyZ97_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ97_tr__tr {
    0% {
      transform: rotate(45deg);
    }
    6.25% {
      transform: rotate(45deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ97 {
    animation: edpgNVz5OyZ97_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ97_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    20% {
      opacity: 0.8;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ99 {
    animation-name: edpgNVz5OyZ99__m, edpgNVz5OyZ99_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ99__m {
    0% {
      d: path('M357.283,138.189L357.283,137.850493L357.293,137.850493L357.293,138.189');
    }
    38.75% {
      d: path('M357.283,138.189L357.283,137.850493L357.293,137.850493L357.293,138.189');
    }
    42.5% {
      d: path('M357.283,138.189L357.283,121.683L359.798427,118.801026L362.626854,116.450401');
    }
    56.25% {
      d: path('M357.283,121.683L357.283,121.683L407.238,76.6992L407.265249,74.260509');
    }
    57.5% {
      d: path('M365.608833,114.1857L365.608833,114.155782L407.238,76.571399L407.344593,64.549044');
    }
    66.25% {
      d: path('M407.238,75.676792L407.238,75.676791L407.238,75.67679L407.9,-19.431209');
    }
    100% {
      d: path('M407.238,75.676792L407.238,75.676791L407.238,75.67679L407.9,-19.431209');
    }
  }
  @keyframes edpgNVz5OyZ99_c_o {
    0% {
      opacity: 0;
    }
    35% {
      opacity: 0;
    }
    36.25% {
      opacity: 0.8;
    }
    61.25% {
      opacity: 0.8;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ100_to {
    animation: edpgNVz5OyZ100_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ100_to__to {
    0% {
      transform: translate(423.548427px, 50.146297px);
    }
    32.5% {
      transform: translate(423.548427px, 50.146297px);
    }
    41.25% {
      transform: translate(422.798427px, 23.771297px);
    }
    42.5% {
      transform: translate(424.798427px, 15.771297px);
    }
    43.75% {
      transform: translate(430.3352px, 9.841812px);
    }
    55% {
      transform: translate(470.801035px, -27.231881px);
    }
    56.25% {
      transform: translate(472.920607px, -31.028703px);
    }
    57.5% {
      transform: translate(472.9px, -38.280793px);
    }
    65% {
      transform: translate(472.9px, -120.728703px);
    }
    100% {
      transform: translate(472.9px, -120.728703px);
    }
  }
  #edpgNVz5OyZ100_tr {
    animation: edpgNVz5OyZ100_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ100_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    42.5% {
      transform: rotate(45deg);
    }
    55% {
      transform: rotate(45deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ100 {
    animation: edpgNVz5OyZ100_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ100_c_o {
    0% {
      opacity: 0;
    }
    31.25% {
      opacity: 0;
    }
    32.5% {
      opacity: 0.7;
    }
    65% {
      opacity: 0.7;
    }
    66.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ101_to {
    animation: edpgNVz5OyZ101_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ101_to__to {
    0% {
      transform: translate(423.548427px, 48.146297px);
    }
    32.5% {
      transform: translate(423.548427px, 48.146297px);
    }
    41.25% {
      transform: translate(422.798427px, 22.271297px);
    }
    42.5% {
      transform: translate(424.798427px, 15.771297px);
    }
    43.75% {
      transform: translate(431.124298px, 8.699627px);
    }
    55% {
      transform: translate(471.4px, -27.891881px);
    }
    56.25% {
      transform: translate(472.851035px, -32.791881px);
    }
    57.5% {
      transform: translate(472.9px, -38.514307px);
    }
    65% {
      transform: translate(472.9px, -121.228703px);
    }
    100% {
      transform: translate(472.9px, -121.228703px);
    }
  }
  #edpgNVz5OyZ101_tr {
    animation: edpgNVz5OyZ101_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ101_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    42.5% {
      transform: rotate(45deg);
    }
    55% {
      transform: rotate(45deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ101 {
    animation: edpgNVz5OyZ101_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ101_c_o {
    0% {
      opacity: 0;
    }
    31.25% {
      opacity: 0;
    }
    32.5% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    66.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ103 {
    animation-name: edpgNVz5OyZ103__m, edpgNVz5OyZ103_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ103__m {
    0% {
      d: path('M357.283,138.189L357.283,139.328493L357.293,137.850493L357.283,137.850493');
    }
    86.25% {
      d: path('M357.283,138.189L357.283,139.328493L357.293,137.850493L357.283,137.850493');
    }
    90% {
      d: path('M357.283,138.189L357.283,121.683L359.798427,118.801026L362.626854,116.450401');
    }
    98.75% {
      d: path('M357.283,138.189L357.283,121.683L378.447064,102.839971L380.992649,101.014044');
    }
    100% {
      d: path('M357.283,138.189L357.283,121.683L364.111115,114.973587L382.557334,99.694926');
    }
  }
  @keyframes edpgNVz5OyZ103_c_o {
    0% {
      opacity: 0;
    }
    86.25% {
      opacity: 0;
    }
    87.5% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.8;
    }
  }
  #edpgNVz5OyZ104_to {
    animation: edpgNVz5OyZ104_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ104_to__to {
    0% {
      transform: translate(422.8px, 49.146297px);
    }
    81.25% {
      transform: translate(422.8px, 49.146297px);
    }
    88.75% {
      transform: translate(422.798427px, 23.771297px);
    }
    90% {
      transform: translate(424.798427px, 15.771297px);
    }
    91.25% {
      transform: translate(430.3352px, 9.841812px);
    }
    100% {
      transform: translate(449.063292px, -7.316354px);
    }
  }
  #edpgNVz5OyZ104_tr {
    animation: edpgNVz5OyZ104_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ104_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    88.75% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(45deg);
    }
  }
  #edpgNVz5OyZ104 {
    animation: edpgNVz5OyZ104_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ104_c_o {
    0% {
      opacity: 0;
    }
    80% {
      opacity: 0;
    }
    81.25% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #edpgNVz5OyZ105_to {
    animation: edpgNVz5OyZ105_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ105_to__to {
    0% {
      transform: translate(422.8px, 49.146297px);
    }
    81.25% {
      transform: translate(422.8px, 49.146297px);
    }
    88.75% {
      transform: translate(422.798427px, 22.271297px);
    }
    90% {
      transform: translate(425.798427px, 15px);
    }
    91.25% {
      transform: translate(430.798427px, 9px);
    }
    100% {
      transform: translate(449.052153px, -7.536834px);
    }
  }
  #edpgNVz5OyZ105_tr {
    animation: edpgNVz5OyZ105_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ105_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    88.75% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(45deg);
    }
  }
  #edpgNVz5OyZ105 {
    animation: edpgNVz5OyZ105_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ105_c_o {
    0% {
      opacity: 0;
    }
    80% {
      opacity: 0;
    }
    81.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #edpgNVz5OyZ111 {
    animation-name: edpgNVz5OyZ111__m, edpgNVz5OyZ111_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ111__m {
    0% {
      d: path('M292.146,232.836923L292.146,179.196852L292.146,179.196852L292.500003,179.020303');
    }
    3.75% {
      d: path('M292.146,229.76L292.146,121.682L292.146,121.683L293.341323,121.000881');
    }
    18.75% {
      d: path('M292.146,121.885507L292.146,121.682L370.374,76.7761L370.374,77.208121');
    }
    30% {
      d: path('M346.303846,91.179651L346.303846,90.994836L370.374,76.7761L370.374,-14.851828');
    }
    35% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
    100% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
  }
  @keyframes edpgNVz5OyZ111_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    41.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ112_to {
    animation: edpgNVz5OyZ112_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ112_to__to {
    0% {
      transform: translate(357px, 70.792183px);
    }
    3.75% {
      transform: translate(357px, 20.521297px);
    }
    5% {
      transform: translate(361.798427px, 14.771297px);
    }
    6.25% {
      transform: translate(367.3352px, 10.841812px);
    }
    17.5% {
      transform: translate(432.801035px, -26.4px);
    }
    18.75% {
      transform: translate(435.920607px, -31.028703px);
    }
    20% {
      transform: translate(435.7px, -38.280793px);
    }
    31.25% {
      transform: translate(435.7px, -121.728703px);
    }
    100% {
      transform: translate(435.7px, -121.728703px);
    }
  }
  #edpgNVz5OyZ112_tr {
    animation: edpgNVz5OyZ112_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ112_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    3.75% {
      transform: rotate(0deg);
    }
    5% {
      transform: rotate(65deg);
    }
    17.5% {
      transform: rotate(65deg);
    }
    18.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ112 {
    animation: edpgNVz5OyZ112_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ112_c_o {
    0% {
      opacity: 0.7;
    }
    31.25% {
      opacity: 0.7;
    }
    32.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ113_to {
    animation: edpgNVz5OyZ113_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ113_to__to {
    0% {
      transform: translate(357px, 70.792183px);
    }
    3.75% {
      transform: translate(357px, 20.521297px);
    }
    5% {
      transform: translate(361.798427px, 14.771297px);
    }
    6.25% {
      transform: translate(367.3352px, 10.841812px);
    }
    17.5% {
      transform: translate(433.502724px, -26.826559px);
    }
    18.75% {
      transform: translate(435.851035px, -31.791881px);
    }
    20% {
      transform: translate(435.7px, -38.514307px);
    }
    31.25% {
      transform: translate(435.7px, -121.228703px);
    }
    100% {
      transform: translate(435.7px, -121.228703px);
    }
  }
  #edpgNVz5OyZ113_tr {
    animation: edpgNVz5OyZ113_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ113_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    3.75% {
      transform: rotate(0deg);
    }
    5% {
      transform: rotate(65deg);
    }
    17.5% {
      transform: rotate(65deg);
    }
    18.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ113 {
    animation: edpgNVz5OyZ113_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ113_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    31.25% {
      opacity: 1;
    }
    32.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ115 {
    animation-name: edpgNVz5OyZ115__m, edpgNVz5OyZ115_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ115__m {
    0% {
      d: path('M292.146,239.76L292.146,235.521298L292.146,235.521298L293.500003,234.771298');
    }
    38.75% {
      d: path('M292.146,239.76L292.146,235.521298L292.146,235.521298L293.500003,234.771298');
    }
    53.75% {
      d: path('M292.146,229.76L292.146,121.682L292.146,121.683L293.4,121.086957');
    }
    67.5% {
      d: path('M292.146,121.885507L292.146,121.682L370.374,76.7761L370.374,77.208121');
    }
    83.75% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
    100% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
  }
  @keyframes edpgNVz5OyZ115_c_o {
    0% {
      opacity: 0;
    }
    37.5% {
      opacity: 0;
    }
    38.75% {
      opacity: 1;
    }
    78.75% {
      opacity: 1;
    }
    88.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ116_to {
    animation: edpgNVz5OyZ116_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ116_to__to {
    0% {
      transform: translate(357.5px, 139.246297px);
    }
    37.5% {
      transform: translate(357.5px, 139.246297px);
    }
    52.5% {
      transform: translate(357.5px, 23.771297px);
    }
    53.75% {
      transform: translate(361.68px, 14.771297px);
    }
    55% {
      transform: translate(367.3352px, 10.841812px);
    }
    66.25% {
      transform: translate(432.801035px, -26.4px);
    }
    67.5% {
      transform: translate(435.920607px, -31.028703px);
    }
    68.75% {
      transform: translate(435.7px, -38.280793px);
    }
    82.5% {
      transform: translate(435.7px, -120.728703px);
    }
    100% {
      transform: translate(435.7px, -120.728703px);
    }
  }
  #edpgNVz5OyZ116_tr {
    animation: edpgNVz5OyZ116_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ116_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(65deg);
    }
    66.25% {
      transform: rotate(65deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ116 {
    animation: edpgNVz5OyZ116_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ116_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 0.7;
    }
    82.5% {
      opacity: 0.7;
    }
    83.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ117_to {
    animation: edpgNVz5OyZ117_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ117_to__to {
    0% {
      transform: translate(357.5px, 139.246297px);
    }
    37.5% {
      transform: translate(357.5px, 139.246297px);
    }
    52.5% {
      transform: translate(357.5px, 22.271297px);
    }
    53.75% {
      transform: translate(362.798427px, 14.271297px);
    }
    55% {
      transform: translate(368.202724px, 10.173441px);
    }
    66.25% {
      transform: translate(433.202724px, -26.826559px);
    }
    67.5% {
      transform: translate(435.851035px, -31.791881px);
    }
    68.75% {
      transform: translate(435.7px, -39.514307px);
    }
    82.5% {
      transform: translate(435.7px, -121.228703px);
    }
    100% {
      transform: translate(435.7px, -121.228703px);
    }
  }
  #edpgNVz5OyZ117_tr {
    animation: edpgNVz5OyZ117_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ117_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(65deg);
    }
    66.25% {
      transform: rotate(65deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ117 {
    animation: edpgNVz5OyZ117_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ117_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    82.5% {
      opacity: 0.8;
    }
    83.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ119 {
    animation-name: edpgNVz5OyZ119__m, edpgNVz5OyZ119_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ119__m {
    0% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    88.75% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    95% {
      d: path('M292.146,245.913846L292.146,243.521298L292.363848,245.008315L292.363849,245.771298');
    }
    100% {
      d: path('M292.146,237.836923L292.146,182.601649L292.254926,183.81536L292.254926,184.196852');
    }
  }
  @keyframes edpgNVz5OyZ119_c_o {
    0% {
      opacity: 0;
    }
    87.5% {
      opacity: 0;
    }
    88.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #edpgNVz5OyZ120_to {
    animation: edpgNVz5OyZ120_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ120_to__to {
    0% {
      transform: translate(357.5px, 138.396297px);
    }
    92.5% {
      transform: translate(357.5px, 138.396297px);
    }
    100% {
      transform: translate(357.5px, 69.650389px);
    }
  }
  #edpgNVz5OyZ120 {
    animation: edpgNVz5OyZ120_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ120_c_o {
    0% {
      opacity: 0;
    }
    91.25% {
      opacity: 0;
    }
    92.5% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #edpgNVz5OyZ121_to {
    animation: edpgNVz5OyZ121_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ121_to__to {
    0% {
      transform: translate(357.5px, 138.396297px);
    }
    92.5% {
      transform: translate(357.5px, 138.396297px);
    }
    100% {
      transform: translate(357.5px, 69.650389px);
    }
  }
  #edpgNVz5OyZ121 {
    animation: edpgNVz5OyZ121_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ121_c_o {
    0% {
      opacity: 0;
    }
    91.25% {
      opacity: 0;
    }
    92.5% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #edpgNVz5OyZ126 {
    animation-name: edpgNVz5OyZ126__m, edpgNVz5OyZ126_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ126__m {
    0% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    18.75% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    23.75% {
      d: path('M226.994001,149.78715L226.994,145.868001L227.0645,146.865005L226.907042,146.549048');
    }
    28.75% {
      d: path('M226.994,138.053L226.994,121.683L227.029,121.677006L228.785083,121.045093');
    }
    40% {
      d: path('M227.029,121.683L226.994,121.683L333.531,76.7383L333.535,76.696467');
    }
    42.5% {
      d: path('M245.992733,113.68997L245.963955,113.721882L333.531,76.7383L333.535,65.343379');
    }
    55% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
    100% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
  }
  @keyframes edpgNVz5OyZ126_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ127_to {
    animation: edpgNVz5OyZ127_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ127_to__to {
    0% {
      transform: translate(291.8px, 52.271297px);
    }
    18.75% {
      transform: translate(291.8px, 52.271297px);
    }
    27.5% {
      transform: translate(291.798427px, 23.771297px);
    }
    28.75% {
      transform: translate(295.798427px, 14.771297px);
    }
    30% {
      transform: translate(304.3352px, 12.1px);
    }
    40% {
      transform: translate(396.801035px, -27.5px);
    }
    41.25% {
      transform: translate(398.920607px, -31.028703px);
    }
    42.5% {
      transform: translate(398.699999px, -38.280793px);
    }
    53.75% {
      transform: translate(398.699999px, -119.728703px);
    }
    100% {
      transform: translate(398.699999px, -119.728703px);
    }
  }
  #edpgNVz5OyZ127_tr {
    animation: edpgNVz5OyZ127_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ127_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(68deg);
    }
    30% {
      transform: rotate(68deg);
    }
    40% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ127 {
    animation: edpgNVz5OyZ127_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ127_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 0.7;
    }
    53.75% {
      opacity: 0.7;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ128_to {
    animation: edpgNVz5OyZ128_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ128_to__to {
    0% {
      transform: translate(291.8px, 52.271297px);
    }
    18.75% {
      transform: translate(291.8px, 52.271297px);
    }
    27.5% {
      transform: translate(291.798427px, 22.271297px);
    }
    28.75% {
      transform: translate(296.798427px, 14.51295px);
    }
    30% {
      transform: translate(305.474547px, 11.51295px);
    }
    40% {
      transform: translate(396.801035px, -27.5px);
    }
    41.25% {
      transform: translate(398.851035px, -31.791881px);
    }
    42.5% {
      transform: translate(398.699999px, -38.514307px);
    }
    53.75% {
      transform: translate(398.699999px, -120.228703px);
    }
    100% {
      transform: translate(398.699999px, -120.228703px);
    }
  }
  #edpgNVz5OyZ128_tr {
    animation: edpgNVz5OyZ128_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ128_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(68deg);
    }
    40% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ128 {
    animation: edpgNVz5OyZ128_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ128_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    53.75% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ130 {
    animation-name: edpgNVz5OyZ130__m, edpgNVz5OyZ130_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ130__m {
    0% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    58.75% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    63.75% {
      d: path('M226.994001,149.78715L226.994,145.868001L227.0645,146.865005L226.907042,146.549048');
    }
    68.75% {
      d: path('M226.994,138.053L226.994,121.683L227.029,121.677006L228.785083,121.045093');
    }
    82.5% {
      d: path('M227.029,121.683L226.994,121.683L333.531,76.7383L333.535,76.687317');
    }
    95% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
    100% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
  }
  @keyframes edpgNVz5OyZ130_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ131_to {
    animation: edpgNVz5OyZ131_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ131_to__to {
    0% {
      transform: translate(291.8px, 49.646297px);
    }
    58.75% {
      transform: translate(291.8px, 49.646297px);
    }
    67.5% {
      transform: translate(291.798427px, 23.771297px);
    }
    68.75% {
      transform: translate(295.798427px, 14.771297px);
    }
    70% {
      transform: translate(304.3352px, 12.1px);
    }
    81.25% {
      transform: translate(396.801035px, -27.5px);
    }
    82.5% {
      transform: translate(398.920607px, -31.028703px);
    }
    83.75% {
      transform: translate(398.699999px, -38.280793px);
    }
    95% {
      transform: translate(398.699999px, -119.378703px);
    }
    100% {
      transform: translate(398.699999px, -119.378703px);
    }
  }
  #edpgNVz5OyZ131_tr {
    animation: edpgNVz5OyZ131_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ131_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(68deg);
    }
    70% {
      transform: rotate(68deg);
    }
    81.25% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ131 {
    animation: edpgNVz5OyZ131_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ131_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 0.7;
    }
    91.25% {
      opacity: 0.7;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ132_to {
    animation: edpgNVz5OyZ132_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ132_to__to {
    0% {
      transform: translate(291.8px, 49.646297px);
    }
    58.75% {
      transform: translate(291.8px, 49.646297px);
    }
    67.5% {
      transform: translate(291.798427px, 22.271297px);
    }
    68.75% {
      transform: translate(296.798427px, 14px);
    }
    70% {
      transform: translate(305.174547px, 11.51295px);
    }
    81.25% {
      transform: translate(396.801035px, -27.740845px);
    }
    82.5% {
      transform: translate(398.851035px, -31.791881px);
    }
    83.75% {
      transform: translate(398.699999px, -38.280793px);
    }
    95% {
      transform: translate(398.699999px, -119.378703px);
    }
    100% {
      transform: translate(398.699999px, -119.378703px);
    }
  }
  #edpgNVz5OyZ132_tr {
    animation: edpgNVz5OyZ132_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ132_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(68deg);
    }
    81.25% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ132 {
    animation: edpgNVz5OyZ132_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ132_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ138 {
    animation-name: edpgNVz5OyZ138__m, edpgNVz5OyZ138_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ138__m {
    0% {
      d: path(
        'M161.867,259.879L161.867,253.489998C161.867,253.489998,161.85,251.271299,161.85,251.271299L161.798427,249.396294'
      );
    }
    6.25% {
      d: path(
        'M161.867,259.879L161.867,253.489998C161.867,253.489998,161.85,251.271299,161.85,251.271299L161.798427,249.396294'
      );
    }
    8.75% {
      d: path(
        'M161.867,255.990111L161.801926,231.833798C161.801926,231.833798,161.801926,231.771298,161.801926,231.771298L162.807023,227.684828'
      );
    }
    21.25% {
      d: path(
        'M161.867,236.545667L161.867,127.968777C161.867,127.968777,161.870499,128.146298,161.870499,128.146298L162.85,127.127495'
      );
    }
    22.5% {
      d: path(
        'M161.867,229.879L161.867,121.677C161.867,121.677,161.85,122.622406,161.85,122.622406L164.089459,122.622409'
      );
    }
    32.5% {
      d: path(
        'M161.867,143.3214L161.867,121.677C161.867,121.677,277.422107,83.120632,277.422107,83.120632L280.268001,82.288075'
      );
    }
    35% {
      d: path('M161.867,121.682L161.867,121.677C161.867,121.677,296.68,76.7222,296.68,76.7222L296.854534,76.35604');
    }
    48.75% {
      d: path('M296.5,76.422261L296.9,76.422262C296.9,76.422262,296.68,76.7222,296.68,76.7222L296.68,-19.691469');
    }
    100% {
      d: path('M296.5,76.422261L296.9,76.422262C296.9,76.422262,296.68,76.7222,296.68,76.7222L296.68,-19.691469');
    }
  }
  @keyframes edpgNVz5OyZ138_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    42.5% {
      opacity: 1;
    }
    52.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ139_to {
    animation: edpgNVz5OyZ139_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ139_to__to {
    0% {
      transform: translate(226.81px, 140.246297px);
    }
    6.25% {
      transform: translate(226.81px, 140.246297px);
    }
    21.25% {
      transform: translate(226.798427px, 23.771297px);
    }
    22.5% {
      transform: translate(230.798427px, 15.771297px);
    }
    23.75% {
      transform: translate(239.436776px, 12.841812px);
    }
    33.75% {
      transform: translate(359.801035px, -27.5px);
    }
    35% {
      transform: translate(361.920607px, -31.410292px);
    }
    36.25% {
      transform: translate(361.9px, -38.280793px);
    }
    48.75% {
      transform: translate(361.9px, -120.728703px);
    }
    100% {
      transform: translate(361.9px, -120.728703px);
    }
  }
  #edpgNVz5OyZ139_tr {
    animation: edpgNVz5OyZ139_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ139_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    23.75% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ139 {
    animation: edpgNVz5OyZ139_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ139_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 0.7;
    }
    48.75% {
      opacity: 0.7;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ140_to {
    animation: edpgNVz5OyZ140_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ140_to__to {
    0% {
      transform: translate(226.81px, 140.246297px);
    }
    6.25% {
      transform: translate(226.81px, 140.246297px);
    }
    21.25% {
      transform: translate(226.798427px, 22.271297px);
    }
    22.5% {
      transform: translate(230.798427px, 15.771297px);
    }
    23.75% {
      transform: translate(239.436777px, 12.841812px);
    }
    33.75% {
      transform: translate(360.801035px, -27.891881px);
    }
    35% {
      transform: translate(361.920607px, -31.410292px);
    }
    36.25% {
      transform: translate(361.9px, -38.514307px);
    }
    48.75% {
      transform: translate(361.9px, -121.228703px);
    }
    100% {
      transform: translate(361.9px, -121.228703px);
    }
  }
  #edpgNVz5OyZ140_tr {
    animation: edpgNVz5OyZ140_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ140_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ140 {
    animation: edpgNVz5OyZ140_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ140_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    48.75% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ142 {
    animation-name: edpgNVz5OyZ142__m, edpgNVz5OyZ142_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ142__m {
    0% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    51.25% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    65% {
      d: path('M161.867,232.379L161.867,128.661417L161.867,128.661417L162.981873,127.186899');
    }
    66.25% {
      d: path('M161.867,229.879L161.867,121.677L161.85,122.622406L163.089459,122.622409');
    }
    78.75% {
      d: path('M161.867,121.682L161.867,121.677L296.68,76.7222L296.68,76.687317');
    }
    93.75% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
    100% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
  }
  @keyframes edpgNVz5OyZ142_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    88.75% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ143_to {
    animation: edpgNVz5OyZ143_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ143_to__to {
    0% {
      transform: translate(226.8px, 139.146297px);
    }
    52.5% {
      transform: translate(226.8px, 139.146297px);
    }
    65% {
      transform: translate(226.798427px, 23.521297px);
    }
    66.25% {
      transform: translate(230.798427px, 15.771297px);
    }
    67.5% {
      transform: translate(239.3352px, 12.841812px);
    }
    78.75% {
      transform: translate(359.801035px, -27.5px);
    }
    80% {
      transform: translate(361.920607px, -31.028703px);
    }
    81.25% {
      transform: translate(361.9px, -38.280793px);
    }
    93.75% {
      transform: translate(361.9px, -119.728703px);
    }
    100% {
      transform: translate(361.9px, -119.728703px);
    }
  }
  #edpgNVz5OyZ143_tr {
    animation: edpgNVz5OyZ143_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ143_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(72deg);
    }
    67.5% {
      transform: rotate(72deg);
    }
    78.75% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ143 {
    animation: edpgNVz5OyZ143_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ143_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 0.7;
    }
    93.75% {
      opacity: 0.7;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ144_to {
    animation: edpgNVz5OyZ144_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ144_to__to {
    0% {
      transform: translate(226.8px, 139.146297px);
    }
    52.5% {
      transform: translate(226.8px, 139.146297px);
    }
    65% {
      transform: translate(226.798427px, 23.521297px);
    }
    66.25% {
      transform: translate(231.798427px, 15.271297px);
    }
    67.5% {
      transform: translate(240.798427px, 12.3px);
    }
    78.75% {
      transform: translate(359.801035px, -27.452471px);
    }
    80% {
      transform: translate(361.851035px, -31.791881px);
    }
    81.25% {
      transform: translate(361.9px, -38.514307px);
    }
    93.75% {
      transform: translate(361.9px, -120.228703px);
    }
    100% {
      transform: translate(361.9px, -120.228703px);
    }
  }
  #edpgNVz5OyZ144_tr {
    animation: edpgNVz5OyZ144_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ144_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(72deg);
    }
    78.75% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ144 {
    animation: edpgNVz5OyZ144_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ144_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    93.75% {
      opacity: 0.8;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ149 {
    animation-name: edpgNVz5OyZ149__m, edpgNVz5OyZ149_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ149__m {
    0% {
      d: path(
        'M96.711985,145.439636L96.7109,127.987317L96.711985,127.987317C96.711985,127.987317,96.711985,127.987317,96.711985,127.987317'
      );
    }
    1.25% {
      d: path(
        'M96.7109,145.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.711985,121.683001,96.711985,121.683001'
      );
    }
    16.25% {
      d: path(
        'M96.7109,124.229631L96.7109,121.68L258.531964,77.088615C258.531964,77.088615,258.531964,77.088614,258.531964,77.088614'
      );
    }
    17.5% {
      d: path(
        'M96.7109,122.271298L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817001,75.687789,259.817001,75.687789'
      );
    }
    30% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,-9.65625,259.817,-9.65625');
    }
    100% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,-9.65625,259.817,-9.65625');
    }
  }
  @keyframes edpgNVz5OyZ149_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ150_to {
    animation: edpgNVz5OyZ150_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ150_to__to {
    0% {
      transform: translate(160.8px, 25.504306px);
    }
    1.25% {
      transform: translate(166.798427px, 15.088613px);
    }
    16.25% {
      transform: translate(322.801035px, -27.911387px);
    }
    17.5% {
      transform: translate(323.81px, -31.712684px);
    }
    30% {
      transform: translate(323.81px, -122.212684px);
    }
    100% {
      transform: translate(323.81px, -122.212684px);
    }
  }
  #edpgNVz5OyZ150_tr {
    animation: edpgNVz5OyZ150_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ150_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(73deg);
    }
    16.25% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ150 {
    animation: edpgNVz5OyZ150_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ150_c_o {
    0% {
      opacity: 0.7;
    }
    30% {
      opacity: 0.7;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ151_to {
    animation: edpgNVz5OyZ151_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ151_to__to {
    0% {
      transform: translate(160.8px, 25.504306px);
    }
    1.25% {
      transform: translate(166.798427px, 15.088613px);
    }
    16.25% {
      transform: translate(322.801035px, -27.891881px);
    }
    17.5% {
      transform: translate(323.81px, -31.712684px);
    }
    30% {
      transform: translate(323.81px, -122.228703px);
    }
    100% {
      transform: translate(323.81px, -122.228703px);
    }
  }
  #edpgNVz5OyZ151_tr {
    animation: edpgNVz5OyZ151_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ151_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(73deg);
    }
    16.25% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ151 {
    animation: edpgNVz5OyZ151_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ151_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ153 {
    animation-name: edpgNVz5OyZ153__m, edpgNVz5OyZ153_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ153__m {
    0% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    42.5% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    48.75% {
      d: path(
        'M96.7109,144.776311L96.7109,139.694875L96.839592,139.321751C96.839592,139.321751,96.867036,140.071751,96.867036,140.071751'
      );
    }
    52.5% {
      d: path(
        'M96.7109,145.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.908201,121.683001,96.908201,121.683001'
      );
    }
    66.25% {
      d: path(
        'M96.7109,124.229631L96.7109,121.68L259.817002,76.7624C259.817002,76.7624,259.817002,76.767317,259.817002,76.767317'
      );
    }
    67.5% {
      d: path(
        'M96.7109,122.271298L96.7109,121.68L259.817,76.767317C259.817,76.767317,259.817001,75.687789,259.817001,75.687789'
      );
    }
    72.5% {
      d: path(
        'M96.798427,121.438996L259.821985,76.72858L259.817,76.7298C259.817,76.7298,259.821985,47.289957,259.821985,47.289957'
      );
    }
    78.75% {
      d: path('M252.817,78.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
    100% {
      d: path('M252.817,78.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
  }
  @keyframes edpgNVz5OyZ153_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ154_to {
    animation: edpgNVz5OyZ154_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ154_to__to {
    0% {
      transform: translate(161.8px, 50.787316px);
    }
    42.5% {
      transform: translate(161.8px, 50.787316px);
    }
    51.25% {
      transform: translate(161.798427px, 21.771297px);
    }
    52.5% {
      transform: translate(166.798427px, 15.771297px);
    }
    66.25% {
      transform: translate(322.801035px, -27.448195px);
    }
    67.5% {
      transform: translate(324.811985px, -31.712684px);
    }
    81.25% {
      transform: translate(324.9px, -120.728703px);
    }
    100% {
      transform: translate(324.9px, -120.728703px);
    }
  }
  #edpgNVz5OyZ154_tr {
    animation: edpgNVz5OyZ154_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ154_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(73deg);
    }
    66.25% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ154 {
    animation: edpgNVz5OyZ154_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ154_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 0.7;
    }
    81.25% {
      opacity: 0.7;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ155_to {
    animation: edpgNVz5OyZ155_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ155_to__to {
    0% {
      transform: translate(161.8px, 50.787316px);
    }
    42.5% {
      transform: translate(161.8px, 50.787316px);
    }
    51.25% {
      transform: translate(161.798427px, 21.687316px);
    }
    52.5% {
      transform: translate(166.798427px, 15.671297px);
    }
    66.25% {
      transform: translate(321.801035px, -27.560064px);
    }
    67.5% {
      transform: translate(324.811985px, -31.791881px);
    }
    81.25% {
      transform: translate(324.9px, -121.228703px);
    }
    100% {
      transform: translate(324.9px, -121.228703px);
    }
  }
  #edpgNVz5OyZ155_tr {
    animation: edpgNVz5OyZ155_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ155_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(73deg);
    }
    66.25% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ155 {
    animation: edpgNVz5OyZ155_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ155_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    81.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ157 {
    animation-name: edpgNVz5OyZ157__m, edpgNVz5OyZ157_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ157__m {
    0% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    96.25% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    100% {
      d: path(
        'M96.7109,144.112987L96.7109,141.038125L96.867035,140.414251C96.867035,140.414251,96.839592,141.664251,96.839592,141.664251'
      );
    }
  }
  @keyframes edpgNVz5OyZ157_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #edpgNVz5OyZ158_to {
    animation: edpgNVz5OyZ158_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ158_to__to {
    0% {
      transform: translate(162px, 49.521297px);
    }
    96.25% {
      transform: translate(162px, 49.521297px);
    }
    100% {
      transform: translate(161.798427px, 24.987316px);
    }
  }
  #edpgNVz5OyZ158 {
    animation: edpgNVz5OyZ158_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ158_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #edpgNVz5OyZ159_to {
    animation: edpgNVz5OyZ159_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ159_to__to {
    0% {
      transform: translate(162px, 49.521297px);
    }
    96.25% {
      transform: translate(162px, 49.521297px);
    }
    100% {
      transform: translate(161.798427px, 24.987316px);
    }
  }
  #edpgNVz5OyZ159 {
    animation: edpgNVz5OyZ159_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ159_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #edpgNVz5OyZ165 {
    animation-name: edpgNVz5OyZ165__m, edpgNVz5OyZ165_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ165__m {
    0% {
      d: path('M32.0957,249.88L32.0957,240.521298L32.0957,240.521298L33.0957,239.524434');
    }
    11.25% {
      d: path('M32.0957,249.88L32.0957,240.521298L32.0957,240.521298L33.0957,239.524434');
    }
    27.5% {
      d: path('M32.0957,229.88L32.0957,121.861988L32.0957,121.861988L33.0957,121.626113');
    }
    31.25% {
      d: path('M32.0957,202.407312L32.0957,121.678L80.044445,110.329273L80.044445,110.329272');
    }
    41.25% {
      d: path('M32.0957,129.146811L32.0957,121.678L218.801035,77.955237L218.801035,77.954434');
    }
    42.5% {
      d: path('M32.0957,119.989248L32.0957,121.678L222.955,76.874434L222.920607,76.874434');
    }
    55% {
      d: path('M222.955,76.874434L222.9557,76.874434L222.955,76.874434L222.955,-19.683594');
    }
    100% {
      d: path('M222.955,76.874434L222.9557,76.874434L222.955,76.874434L222.955,-19.683594');
    }
  }
  @keyframes edpgNVz5OyZ165_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ166_to {
    animation: edpgNVz5OyZ166_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ166_to__to {
    0% {
      transform: translate(96.798427px, 143.537316px);
    }
    11.25% {
      transform: translate(96.798427px, 143.537316px);
    }
    26.25% {
      transform: translate(97.111986px, 20.771297px);
    }
    27.5% {
      transform: translate(100.798427px, 15.771297px);
    }
    41.25% {
      transform: translate(284.801035px, -27.679029px);
    }
    42.5% {
      transform: translate(287.920607px, -33.028703px);
    }
    56.25% {
      transform: translate(287.921986px, -120.728703px);
    }
    100% {
      transform: translate(287.921986px, -120.728703px);
    }
  }
  #edpgNVz5OyZ166_tr {
    animation: edpgNVz5OyZ166_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ166_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(75deg);
    }
    41.25% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ166 {
    animation: edpgNVz5OyZ166_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ166_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.7;
    }
    56.25% {
      opacity: 0.7;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ167_to {
    animation: edpgNVz5OyZ167_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ167_to__to {
    0% {
      transform: translate(96.798427px, 142.237316px);
    }
    11.25% {
      transform: translate(96.798427px, 142.237316px);
    }
    26.25% {
      transform: translate(97.111986px, 20.643116px);
    }
    27.5% {
      transform: translate(100.798427px, 15.771297px);
    }
    41.25% {
      transform: translate(284.833827px, -27.703774px);
    }
    42.5% {
      transform: translate(288.011986px, -33.028703px);
    }
    56.25% {
      transform: translate(288.011986px, -120.728703px);
    }
    100% {
      transform: translate(288.011986px, -120.728703px);
    }
  }
  #edpgNVz5OyZ167_tr {
    animation: edpgNVz5OyZ167_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ167_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(75deg);
    }
    41.25% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ167 {
    animation: edpgNVz5OyZ167_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ167_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 1;
    }
    56.25% {
      opacity: 1;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ169 {
    animation-name: edpgNVz5OyZ169__m, edpgNVz5OyZ169_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes edpgNVz5OyZ169__m {
    0% {
      d: path('M32.0957,249.88L32.0957,239.147317L32.091986,239.147317L33.091986,238.146294');
    }
    57.5% {
      d: path('M32.0957,249.88L32.0957,239.147317L32.091986,239.147317L33.091986,238.146294');
    }
    71.25% {
      d: path('M32.0957,229.88L32.0957,121.683L32.0957,121.683L33.000094,121.438997');
    }
    86.25% {
      d: path('M32.0957,121.677317L32.0957,121.678L222.955,76.73L222.951986,76.45812');
    }
    87.5% {
      d: path('M53.302289,116.648978L53.296178,116.649585L222.955,76.73L222.952321,74.775707');
    }
    97.5% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
    100% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
  }
  @keyframes edpgNVz5OyZ169_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 1;
    }
    92.5% {
      opacity: 1;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ170_to {
    animation: edpgNVz5OyZ170_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ170_to__to {
    0% {
      transform: translate(96.8px, 140.121297px);
    }
    57.5% {
      transform: translate(96.8px, 140.121297px);
    }
    70% {
      transform: translate(96.798427px, 23.771297px);
    }
    71.25% {
      transform: translate(100.798427px, 15.771297px);
    }
    72.5% {
      transform: translate(114.3352px, 12.841812px);
    }
    86.25% {
      transform: translate(283.801035px, -27.231881px);
    }
    87.5% {
      transform: translate(287.920607px, -31.028703px);
    }
    88.75% {
      transform: translate(287.9px, -38.280793px);
    }
    96.25% {
      transform: translate(287.9px, -120.728703px);
    }
    100% {
      transform: translate(287.9px, -120.728703px);
    }
  }
  #edpgNVz5OyZ170_tr {
    animation: edpgNVz5OyZ170_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ170_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(75deg);
    }
    86.25% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ170 {
    animation: edpgNVz5OyZ170_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ170_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 0.7;
    }
    96.25% {
      opacity: 0.7;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #edpgNVz5OyZ171_to {
    animation: edpgNVz5OyZ171_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ171_to__to {
    0% {
      transform: translate(96.8px, 140.121297px);
    }
    57.5% {
      transform: translate(96.8px, 140.121297px);
    }
    70% {
      transform: translate(96.798427px, 22.271297px);
    }
    71.25% {
      transform: translate(100.798427px, 15.771297px);
    }
    72.5% {
      transform: translate(114.3352px, 12.841812px);
    }
    86.25% {
      transform: translate(283.801035px, -27.302775px);
    }
    87.5% {
      transform: translate(287.851035px, -31.791881px);
    }
    88.75% {
      transform: translate(287.9px, -38.514307px);
    }
    96.25% {
      transform: translate(287.9px, -120.228703px);
    }
    100% {
      transform: translate(287.9px, -120.228703px);
    }
  }
  #edpgNVz5OyZ171_tr {
    animation: edpgNVz5OyZ171_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ171_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(75deg);
    }
    86.25% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #edpgNVz5OyZ171 {
    animation: edpgNVz5OyZ171_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes edpgNVz5OyZ171_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 1;
    }
    96.25% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesBg12;
