// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesBg9 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)', // Replace all <stop id="...-stroke-1" stopColor="#initial-color" /> with this value
  };

  return (
    <AnimatedSvg
      id="esUN0FPgdH11"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 885 228"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="885"
      height="228"
      className="source-background"
      {...props}
    >
      <defs>
        <filter id="esUN0FPgdH18-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH18-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="esUN0FPgdH18-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH18-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="esUN0FPgdH18-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH18-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH18-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH18-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="esUN0FPgdH19-stroke"
          x1="2.921901"
          y1="0.126688"
          x2="2.921901"
          y2="0.85618"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="esUN0FPgdH19-stroke-0"
            offset="0%"
            stopColor={selected === 8 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="esUN0FPgdH19-stroke-1"
            offset="49%"
            stopColor={selected === 8 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="esUN0FPgdH19-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="esUN0FPgdH110-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH110-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="esUN0FPgdH110-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH110-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="esUN0FPgdH110-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH110-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH110-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH110-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="esUN0FPgdH110-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="esUN0FPgdH112-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH112-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="esUN0FPgdH112-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH112-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="esUN0FPgdH112-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH112-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH112-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH112-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="esUN0FPgdH113-stroke"
          x1="15.300783"
          y1="0.198162"
          x2="15.300783"
          y2="0.876913"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="esUN0FPgdH113-stroke-0"
            offset="0%"
            stopColor={selected === 8 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="esUN0FPgdH113-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="esUN0FPgdH114-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH114-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="esUN0FPgdH114-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH114-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="esUN0FPgdH114-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH114-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH114-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH114-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="esUN0FPgdH114-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="esUN0FPgdH116-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH116-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="esUN0FPgdH116-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH116-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="esUN0FPgdH116-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH116-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH116-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH116-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="esUN0FPgdH117-stroke"
          x1="-44.827333"
          y1="0.109894"
          x2="-44.827333"
          y2="0.783728"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="esUN0FPgdH117-stroke-0"
            offset="0%"
            stopColor={selected === 8 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="esUN0FPgdH117-stroke-1"
            offset="52%"
            stopColor={selected === 8 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="esUN0FPgdH117-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="esUN0FPgdH118-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH118-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="esUN0FPgdH118-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH118-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="esUN0FPgdH118-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH118-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH118-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH118-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="esUN0FPgdH118-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="esUN0FPgdH123-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH123-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="esUN0FPgdH123-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH123-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="esUN0FPgdH123-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH123-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH123-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH123-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="esUN0FPgdH124-stroke"
          x1="13.979501"
          y1="0.215315"
          x2="13.979501"
          y2="0.925486"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="esUN0FPgdH124-stroke-0"
            offset="0%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="esUN0FPgdH124-stroke-1"
            offset="50%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="esUN0FPgdH124-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="esUN0FPgdH125-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH125-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="esUN0FPgdH125-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH125-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="esUN0FPgdH125-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH125-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH125-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH125-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="esUN0FPgdH125-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="esUN0FPgdH127-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH127-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="esUN0FPgdH127-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH127-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="esUN0FPgdH127-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH127-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH127-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH127-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="esUN0FPgdH128-stroke"
          x1="13.979501"
          y1="0.215315"
          x2="13.979501"
          y2="0.874417"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="esUN0FPgdH128-stroke-0"
            offset="0%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="esUN0FPgdH128-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="esUN0FPgdH129-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH129-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="esUN0FPgdH129-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH129-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="esUN0FPgdH129-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH129-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH129-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH129-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="esUN0FPgdH129-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="esUN0FPgdH135-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH135-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="esUN0FPgdH135-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH135-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="esUN0FPgdH135-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH135-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH135-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH135-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="esUN0FPgdH136-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="esUN0FPgdH136-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="esUN0FPgdH136-stroke-1"
            offset="51%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="esUN0FPgdH136-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="esUN0FPgdH137-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH137-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="esUN0FPgdH137-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH137-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="esUN0FPgdH137-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH137-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH137-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH137-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="esUN0FPgdH137-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="esUN0FPgdH139-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH139-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="esUN0FPgdH139-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH139-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="esUN0FPgdH139-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH139-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH139-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH139-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="esUN0FPgdH140-stroke"
          x1="-3165.000304"
          y1="0.026697"
          x2="-3165.000304"
          y2="0.758429"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="esUN0FPgdH140-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="esUN0FPgdH140-stroke-1"
            offset="50%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="esUN0FPgdH140-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="esUN0FPgdH141-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH141-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="esUN0FPgdH141-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH141-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="esUN0FPgdH141-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH141-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH141-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH141-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="esUN0FPgdH141-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="esUN0FPgdH143-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH143-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="esUN0FPgdH143-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH143-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="esUN0FPgdH143-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH143-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH143-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH143-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="esUN0FPgdH144-stroke"
          x1="-758.58869"
          y1="0"
          x2="-758.58869"
          y2="0.86255"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="esUN0FPgdH144-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="esUN0FPgdH144-stroke-1"
            offset="52%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="esUN0FPgdH144-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="esUN0FPgdH145-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH145-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="esUN0FPgdH145-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH145-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="esUN0FPgdH145-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH145-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH145-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH145-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="esUN0FPgdH145-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="esUN0FPgdH150-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH150-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="esUN0FPgdH150-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH150-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="esUN0FPgdH150-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH150-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH150-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH150-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="esUN0FPgdH151-stroke"
          x1="0.03364"
          y1="0.187843"
          x2="0.961993"
          y2="1.416888"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="esUN0FPgdH151-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="esUN0FPgdH151-stroke-1"
            offset="51%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="esUN0FPgdH151-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="esUN0FPgdH152-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH152-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="esUN0FPgdH152-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH152-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="esUN0FPgdH152-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH152-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH152-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH152-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="esUN0FPgdH152-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="esUN0FPgdH154-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH154-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="esUN0FPgdH154-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH154-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="esUN0FPgdH154-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH154-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH154-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH154-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="esUN0FPgdH155-stroke"
          x1="0.094428"
          y1="0.166815"
          x2="1"
          y2="1.442446"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="esUN0FPgdH155-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="esUN0FPgdH155-stroke-1"
            offset="50%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="esUN0FPgdH155-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="esUN0FPgdH156-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH156-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="esUN0FPgdH156-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH156-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="esUN0FPgdH156-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH156-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH156-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH156-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="esUN0FPgdH156-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="esUN0FPgdH163-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH163-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="esUN0FPgdH163-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH163-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="esUN0FPgdH163-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH163-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH163-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH163-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="esUN0FPgdH164-stroke"
          x1="79.064282"
          y1="0.048175"
          x2="79.064284"
          y2="0.76207"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="esUN0FPgdH164-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="esUN0FPgdH164-stroke-1"
            offset="52%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="esUN0FPgdH164-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="esUN0FPgdH165-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH165-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="esUN0FPgdH165-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH165-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="esUN0FPgdH165-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH165-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH165-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH165-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="esUN0FPgdH165-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="esUN0FPgdH167-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH167-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="esUN0FPgdH167-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH167-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="esUN0FPgdH167-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH167-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH167-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH167-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="esUN0FPgdH168-stroke"
          x1="76.616948"
          y1="0.070676"
          x2="76.616948"
          y2="0.681148"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="esUN0FPgdH168-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="esUN0FPgdH168-stroke-1"
            offset="52%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="esUN0FPgdH168-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="esUN0FPgdH169-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH169-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="esUN0FPgdH169-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH169-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="esUN0FPgdH169-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH169-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH169-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH169-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="esUN0FPgdH169-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="esUN0FPgdH176-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH176-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="esUN0FPgdH176-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH176-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="esUN0FPgdH176-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH176-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH176-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH176-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="esUN0FPgdH177-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.021396"
          y2="1.049288"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="esUN0FPgdH177-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="esUN0FPgdH177-stroke-1"
            offset="52%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="esUN0FPgdH177-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="esUN0FPgdH178-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH178-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="esUN0FPgdH178-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH178-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="esUN0FPgdH178-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH178-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH178-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH178-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="esUN0FPgdH178-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="esUN0FPgdH180-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH180-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="esUN0FPgdH180-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH180-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="esUN0FPgdH180-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH180-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH180-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH180-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="esUN0FPgdH181-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.021396"
          y2="1.049288"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="esUN0FPgdH181-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="esUN0FPgdH181-stroke-1"
            offset="53%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="esUN0FPgdH181-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="esUN0FPgdH182-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH182-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="esUN0FPgdH182-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH182-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="esUN0FPgdH182-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH182-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH182-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH182-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="esUN0FPgdH182-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="esUN0FPgdH188-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH188-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="esUN0FPgdH188-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH188-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="esUN0FPgdH188-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH188-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH188-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH188-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="esUN0FPgdH189-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="matrix(0 0.818006 -0.818006 0 47.157845 0.039384)"
        >
          <stop
            id="esUN0FPgdH189-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="esUN0FPgdH189-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
          <stop id="esUN0FPgdH189-stroke-2" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <linearGradient
          id="esUN0FPgdH189-stroke-g1"
          x1="736.553247"
          y1="0.009907"
          x2="736.553247"
          y2="0.852432"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="esUN0FPgdH189-stroke-g1-0" offset="0%" stopColor="#7ea6ff" />
          <stop id="esUN0FPgdH189-stroke-g1-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <linearGradient
          id="esUN0FPgdH189-stroke-g2"
          x1="0.878768"
          y1="0.0108"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="esUN0FPgdH189-stroke-g2-0" offset="0%" stopColor="#7ea6ff" />
          <stop id="esUN0FPgdH189-stroke-g2-1" offset="51%" stopColor="#6069ca" />
          <stop id="esUN0FPgdH189-stroke-g2-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <linearGradient
          id="esUN0FPgdH189-stroke-g3"
          x1="0.925458"
          y1="-0.055162"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="esUN0FPgdH189-stroke-g3-0" offset="0%" stopColor="#7ea6ff" />
          <stop id="esUN0FPgdH189-stroke-g3-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <linearGradient
          id="esUN0FPgdH189-stroke-g4"
          x1="0.819076"
          y1="-0.040557"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="esUN0FPgdH189-stroke-g4-0" offset="0%" stopColor="#7ea6ff" />
          <stop id="esUN0FPgdH189-stroke-g4-1" offset="53%" stopColor="#6069ca" />
          <stop id="esUN0FPgdH189-stroke-g4-2" offset="100%" stopColor="rgba(64,65,69,0)" />
        </linearGradient>
        <filter id="esUN0FPgdH190-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH190-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="esUN0FPgdH190-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH190-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="esUN0FPgdH190-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH190-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH190-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH190-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="esUN0FPgdH190-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="esUN0FPgdH192-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH192-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="esUN0FPgdH192-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH192-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="esUN0FPgdH192-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH192-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH192-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH192-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="esUN0FPgdH193-stroke"
          x1="570.204843"
          y1="0.018299"
          x2="570.204843"
          y2="0.813183"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="esUN0FPgdH193-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="esUN0FPgdH193-stroke-1"
            offset="52%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="esUN0FPgdH193-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="esUN0FPgdH194-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH194-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="esUN0FPgdH194-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH194-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="esUN0FPgdH194-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH194-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH194-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH194-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="esUN0FPgdH194-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="esUN0FPgdH199-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH199-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="esUN0FPgdH199-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH199-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="esUN0FPgdH199-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH199-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH199-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH199-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="esUN0FPgdH1100-stroke"
          x1="0.318912"
          y1="0.924232"
          x2="1"
          y2="0.171081"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="esUN0FPgdH1100-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="esUN0FPgdH1100-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="esUN0FPgdH1101-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH1101-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="esUN0FPgdH1101-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH1101-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="esUN0FPgdH1101-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH1101-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH1101-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH1101-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="esUN0FPgdH1101-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="esUN0FPgdH1103-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH1103-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="esUN0FPgdH1103-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH1103-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="esUN0FPgdH1103-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH1103-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH1103-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH1103-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="esUN0FPgdH1104-stroke"
          x1="0.168108"
          y1="1.446572"
          x2="1.040808"
          y2="0.208569"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="esUN0FPgdH1104-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="esUN0FPgdH1104-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="esUN0FPgdH1105-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH1105-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="esUN0FPgdH1105-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH1105-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="esUN0FPgdH1105-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH1105-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH1105-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH1105-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="esUN0FPgdH1105-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="esUN0FPgdH1107-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH1107-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="esUN0FPgdH1107-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH1107-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="esUN0FPgdH1107-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH1107-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH1107-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH1107-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="esUN0FPgdH1108-stroke"
          x1="-0.142435"
          y1="1.238891"
          x2="0.883069"
          y2="-0.083996"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="esUN0FPgdH1108-stroke-0" offset="2%" stopColor="rgba(64,73,168,0)" />
          <stop
            id="esUN0FPgdH1108-stroke-1"
            offset="51%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="esUN0FPgdH1108-stroke-2"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="esUN0FPgdH1109-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH1109-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="esUN0FPgdH1109-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH1109-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="esUN0FPgdH1109-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH1109-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH1109-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH1109-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="esUN0FPgdH1109-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="esUN0FPgdH1110-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH1110-filter-blur-0" stdDeviation="0,0" result="result" />
        </filter>
        <filter id="esUN0FPgdH1115-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH1115-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="esUN0FPgdH1115-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH1115-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="esUN0FPgdH1115-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH1115-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH1115-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH1115-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="esUN0FPgdH1116-stroke"
          x1="10.985875"
          y1="0.113073"
          x2="10.985875"
          y2="0.655069"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="esUN0FPgdH1116-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="esUN0FPgdH1116-stroke-1"
            offset="55%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="esUN0FPgdH1116-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="esUN0FPgdH1117-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH1117-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="esUN0FPgdH1117-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH1117-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="esUN0FPgdH1117-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH1117-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH1117-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH1117-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="esUN0FPgdH1117-filter-blur-0" stdDeviation="3,3" result="result" />
        </filter>
        <filter id="esUN0FPgdH1118-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH1118-filter-blur-0" stdDeviation="0,0" result="result" />
        </filter>
        <filter id="esUN0FPgdH1119-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH1119-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="esUN0FPgdH1119-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH1119-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="esUN0FPgdH1119-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH1119-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH1119-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH1119-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="esUN0FPgdH1120-stroke"
          x1="26447.012347"
          y1="0.065889"
          x2="26447.012347"
          y2="0.571822"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="esUN0FPgdH1120-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="esUN0FPgdH1120-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="esUN0FPgdH1121-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="esUN0FPgdH1121-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="esUN0FPgdH1121-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="esUN0FPgdH1121-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="esUN0FPgdH1121-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="esUN0FPgdH1121-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="esUN0FPgdH1121-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="esUN0FPgdH1121-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="esUN0FPgdH1121-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
      </defs>
      <g transform="translate(0-1.25894)" mask="url(#esUN0FPgdH1123)">
        <g transform="translate(.000001 0)">
          <g transform="translate(.088013 1.012683)">
            <g transform="translate(-17 0)">
              <path
                d="M853.309,232.641v-111.14137L665.296,76.728v-73.62253"
                transform="matrix(.993835 0 0 1.020627 4.6811-2.431371)"
                fill="none"
                stroke="#6069ca"
              />
              <g transform="translate(-65 105.000001)" filter="url(#esUN0FPgdH18-filter)">
                <path
                  id="esUN0FPgdH19"
                  d="M853.309,172.852398v-51.352768l-88.567326-21.284376-.520849-.000001"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  fill="none"
                  stroke="url(#esUN0FPgdH19-stroke)"
                  strokeLinecap="round"
                />
                <g id="esUN0FPgdH110_to" transform="translate(828.317602,-5.431736)">
                  <g id="esUN0FPgdH110_tr" transform="rotate(-75)">
                    <rect
                      id="esUN0FPgdH110"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0.7"
                      filter="url(#esUN0FPgdH110-filter)"
                      fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="esUN0FPgdH111_to" transform="translate(828.317602,-5.431736)">
                  <g id="esUN0FPgdH111_tr" transform="rotate(-75)">
                    <rect
                      id="esUN0FPgdH111"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#esUN0FPgdH112-filter)">
                <path
                  id="esUN0FPgdH113"
                  d="M853.309,252.2368v-9.24321-1.766709-3.184316"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  opacity="0"
                  fill="none"
                  stroke="url(#esUN0FPgdH113-stroke)"
                  strokeLinecap="round"
                />
                <g id="esUN0FPgdH114_to" transform="translate(917.5,138.546297)">
                  <g id="esUN0FPgdH114_tr" transform="rotate(0)">
                    <rect
                      id="esUN0FPgdH114"
                      width="8.235294"
                      height="14.117647"
                      rx="0"
                      ry="0"
                      transform="scale(0.85,0.85) translate(-4.117647,-7.058824)"
                      opacity="0"
                      filter="url(#esUN0FPgdH114-filter)"
                      fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="esUN0FPgdH115_to" transform="translate(917.5,138.546297)">
                  <g id="esUN0FPgdH115_tr" transform="rotate(0)">
                    <rect
                      id="esUN0FPgdH115"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#esUN0FPgdH116-filter)">
                <path
                  id="esUN0FPgdH117"
                  d="M853.309,252.2368v-9.24321-1.766709-3.184316"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  opacity="0"
                  fill="none"
                  stroke="url(#esUN0FPgdH117-stroke)"
                  strokeLinecap="round"
                />
                <g id="esUN0FPgdH118_to" transform="translate(917.5,140.146297)">
                  <g id="esUN0FPgdH118_tr" transform="rotate(0)">
                    <rect
                      id="esUN0FPgdH118"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#esUN0FPgdH118-filter)"
                      fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="esUN0FPgdH119_to" transform="translate(917.5,140.146297)">
                  <g id="esUN0FPgdH119_tr" transform="rotate(0)">
                    <rect
                      id="esUN0FPgdH119"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(-30 0)" mask="url(#esUN0FPgdH131)">
              <g>
                <path d="M781.258,138.241v-16.559L628.386,76.7624v-76.41865" fill="none" stroke="#6069ca" />
                <g transform="translate(-65 105.000001)" filter="url(#esUN0FPgdH123-filter)">
                  <path
                    id="esUN0FPgdH124"
                    d="M781.258,158.241v-7.559l.242-1.040176-.242-.654506"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#esUN0FPgdH124-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="esUN0FPgdH125_to" transform="translate(846,51.646297)">
                    <g id="esUN0FPgdH125_tr" transform="rotate(0)">
                      <rect
                        id="esUN0FPgdH125"
                        width="7.129631"
                        height="12.222224"
                        rx="0"
                        ry="0"
                        transform="scale(0.981818,0.981818) translate(-3.564816,-6.111112)"
                        opacity="0"
                        filter="url(#esUN0FPgdH125-filter)"
                        fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="esUN0FPgdH126_to" transform="translate(846,51.646297)">
                    <g id="esUN0FPgdH126_tr" transform="rotate(0)">
                      <rect
                        id="esUN0FPgdH126"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#esUN0FPgdH127-filter)">
                  <path
                    id="esUN0FPgdH128"
                    d="M781.258,158.241v-7.559l.242-1.040176-.242-.654506"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#esUN0FPgdH128-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="esUN0FPgdH129_to" transform="translate(846,51.146297)">
                    <g id="esUN0FPgdH129_tr" transform="rotate(0)">
                      <rect
                        id="esUN0FPgdH129"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0"
                        filter="url(#esUN0FPgdH129-filter)"
                        fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="esUN0FPgdH130_to" transform="translate(846,51.146297)">
                    <g id="esUN0FPgdH130_tr" transform="rotate(0)">
                      <rect
                        id="esUN0FPgdH130"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="esUN0FPgdH131" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 605.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(-44.999999 0.624996)">
              <path d="M721.199,232.865v-112.051L591.532,76.7311l.008-76.992999" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#esUN0FPgdH135-filter)">
                <path
                  id="esUN0FPgdH136"
                  d="M692.384111,111.206804v-.118885L591.532,76.7311c0,0,.008-7.785355.008-7.785355"
                  transform="translate(65-105.000001)"
                  fill="none"
                  stroke="url(#esUN0FPgdH136-stroke)"
                  strokeLinecap="round"
                />
                <g id="esUN0FPgdH137_to" transform="translate(657,-35.643085)">
                  <rect
                    id="esUN0FPgdH137"
                    width="8.75"
                    height="15"
                    rx="0"
                    ry="0"
                    transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                    opacity="0.7"
                    filter="url(#esUN0FPgdH137-filter)"
                    fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="esUN0FPgdH138_to" transform="translate(657,-35.643085)">
                  <rect
                    id="esUN0FPgdH138"
                    width="10"
                    height="20"
                    rx="0"
                    ry="0"
                    transform="scale(0.5,0.5) translate(-5,-10)"
                    fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#esUN0FPgdH139-filter)">
                <path
                  id="esUN0FPgdH140"
                  d="M721.199,252.865v-4.051l-.006-.917698c0,0,0-.755649,0-.755649"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#esUN0FPgdH140-stroke)"
                  strokeLinecap="round"
                />
                <g id="esUN0FPgdH141_to" transform="translate(786.5,139.146297)">
                  <g id="esUN0FPgdH141_tr" transform="rotate(0)">
                    <rect
                      id="esUN0FPgdH141"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#esUN0FPgdH141-filter)"
                      fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="esUN0FPgdH142_to" transform="translate(786.5,139.146297)">
                  <g id="esUN0FPgdH142_tr" transform="rotate(0)">
                    <rect
                      id="esUN0FPgdH142"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#esUN0FPgdH143-filter)">
                <path
                  id="esUN0FPgdH144"
                  d="M721.199,252.865v-4.051l-.006-.917698c0,0,0-.755649,0-.755649"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#esUN0FPgdH144-stroke)"
                  strokeLinecap="round"
                />
                <g id="esUN0FPgdH145_to" transform="translate(786.5,138.146297)">
                  <g id="esUN0FPgdH145_tr" transform="rotate(0)">
                    <rect
                      id="esUN0FPgdH145"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#esUN0FPgdH145-filter)"
                      fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="esUN0FPgdH146_to" transform="translate(786.5,138.146297)">
                  <g id="esUN0FPgdH146_tr" transform="rotate(0)">
                    <rect
                      id="esUN0FPgdH146"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(-60 0)" mask="url(#esUN0FPgdH158)">
              <g>
                <path d="M650.977,138.082v-16.4L554.656,76.7305l-.004-76.414006" fill="none" stroke="#6069ca" />
                <g transform="translate(-65 105.000001)" filter="url(#esUN0FPgdH150-filter)">
                  <path
                    id="esUN0FPgdH151"
                    d="M650.977,158.082v-3.4l.052.000001v-2.410703"
                    transform="translate(65-105.000001)"
                    fill="none"
                    stroke="url(#esUN0FPgdH151-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="esUN0FPgdH152_to" transform="translate(715.5,52.146297)">
                    <g id="esUN0FPgdH152_tr" transform="rotate(0)">
                      <rect
                        id="esUN0FPgdH152"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0.7"
                        filter="url(#esUN0FPgdH152-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="esUN0FPgdH153_to" transform="translate(715.5,52.146297)">
                    <g id="esUN0FPgdH153_tr" transform="rotate(0)">
                      <rect
                        id="esUN0FPgdH153"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#esUN0FPgdH154-filter)">
                  <path
                    id="esUN0FPgdH155"
                    d="M650.977,158.082v-3.4l.052.000001v-2.410703"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#esUN0FPgdH155-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="esUN0FPgdH156_to" transform="translate(715.298427,50.146297)">
                    <g id="esUN0FPgdH156_tr" transform="rotate(0)">
                      <rect
                        id="esUN0FPgdH156"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0"
                        filter="url(#esUN0FPgdH156-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="esUN0FPgdH157_to" transform="translate(715.298427,50.146297)">
                    <g id="esUN0FPgdH157_tr" transform="rotate(0)">
                      <rect
                        id="esUN0FPgdH157"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="esUN0FPgdH158" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="140"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 535.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(166 0)" mask="url(#esUN0FPgdH171)">
              <g transform="translate(.000001 0)">
                <line
                  x1="444.915"
                  y1="171"
                  x2="444.915"
                  y2="326.96"
                  transform="translate(-166-169.74106)"
                  fill="none"
                  stroke="#5d66c4"
                />
                <g transform="translate(-65 105.000001)" filter="url(#esUN0FPgdH163-filter)">
                  <path
                    id="esUN0FPgdH164"
                    d="M278.699999,292.722204v.179506l-.168999-.16341.004-86.414117"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#esUN0FPgdH164-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="esUN0FPgdH165_to" transform="translate(344,52.271297)">
                    <rect
                      id="esUN0FPgdH165"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#esUN0FPgdH165-filter)"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="esUN0FPgdH166_to" transform="translate(344,52.271297)">
                    <rect
                      id="esUN0FPgdH166"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#esUN0FPgdH167-filter)">
                  <path
                    id="esUN0FPgdH168"
                    d="M278.699999,292.722204v.179506l-.168999-.16341.004-86.414117"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#esUN0FPgdH168-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="esUN0FPgdH169_to" transform="translate(344,52.271297)">
                    <rect
                      id="esUN0FPgdH169"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#esUN0FPgdH169-filter)"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="esUN0FPgdH170_to" transform="translate(344,52.271297)">
                    <rect
                      id="esUN0FPgdH170"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <mask id="esUN0FPgdH171" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="140"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 209.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(60 0)" mask="url(#esUN0FPgdH184)">
              <g transform="translate(.000001 0)">
                <path d="M226.994,138.053v-16.37L333.531,76.7383l.004-76.414117" fill="none" stroke="#6069ca" />
                <g transform="translate(-65 105.000001)" filter="url(#esUN0FPgdH176-filter)">
                  <path
                    id="esUN0FPgdH177"
                    d="M226.994002,161.5213l-.000002-7.468298.106.000002-.070999-.000002"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#esUN0FPgdH177-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="esUN0FPgdH178_to" transform="translate(292,52.271297)">
                    <g id="esUN0FPgdH178_tr" transform="rotate(0)">
                      <rect
                        id="esUN0FPgdH178"
                        width="8.75"
                        height="15"
                        rx="0"
                        ry="0"
                        transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                        opacity="0"
                        filter="url(#esUN0FPgdH178-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="esUN0FPgdH179_to" transform="translate(292,52.271297)">
                    <g id="esUN0FPgdH179_tr" transform="rotate(0)">
                      <rect
                        id="esUN0FPgdH179"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#esUN0FPgdH180-filter)">
                  <path
                    id="esUN0FPgdH181"
                    d="M226.994002,161.5213l-.000002-7.468298.106.000002-.070999-.000002"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#esUN0FPgdH181-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="esUN0FPgdH182_to" transform="translate(292,49.646297)">
                    <g id="esUN0FPgdH182_tr" transform="rotate(0)">
                      <rect
                        id="esUN0FPgdH182"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0"
                        filter="url(#esUN0FPgdH182-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="esUN0FPgdH183_to" transform="translate(292,49.646297)">
                    <g id="esUN0FPgdH183_tr" transform="rotate(0)">
                      <rect
                        id="esUN0FPgdH183"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="esUN0FPgdH184" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="140"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 209.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(45 0)">
              <path d="M161.867,229.879v-108.202L296.68,76.7222v-76.413669" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#esUN0FPgdH188-filter)">
                <path
                  id="esUN0FPgdH189"
                  d="M161.867,259.879v-6.389002c0,0-.017-2.218699-.017-2.218699l-.051573-1.875005"
                  transform="translate(64.996501-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#esUN0FPgdH189-stroke)"
                  strokeLinecap="round"
                />
                <g id="esUN0FPgdH190_to" transform="translate(227,140.246297)">
                  <g id="esUN0FPgdH190_tr" transform="rotate(0)">
                    <rect
                      id="esUN0FPgdH190"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#esUN0FPgdH190-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="esUN0FPgdH191_to" transform="translate(227,140.246297)">
                  <g id="esUN0FPgdH191_tr" transform="rotate(0)">
                    <rect
                      id="esUN0FPgdH191"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#esUN0FPgdH192-filter)">
                <path
                  id="esUN0FPgdH193"
                  d="M161.867,259.879v-6.389002l-.017-2.218699-.051573-1.875005"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#esUN0FPgdH193-stroke)"
                  strokeLinecap="round"
                />
                <g id="esUN0FPgdH194_to" transform="translate(227,139.146297)">
                  <g id="esUN0FPgdH194_tr" transform="rotate(0)">
                    <rect
                      id="esUN0FPgdH194"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#esUN0FPgdH194-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="esUN0FPgdH195_to" transform="translate(227,139.146297)">
                  <g id="esUN0FPgdH195_tr" transform="rotate(0)">
                    <rect
                      id="esUN0FPgdH195"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(30 0)" mask="url(#esUN0FPgdH1111)">
              <g transform="translate(.000001 0)">
                <path d="M96.7109,138.118v-16.438L259.817,76.7298v-76.38605" fill="none" stroke="#6069ca" />
                <g transform="translate(-64 105.000001)" filter="url(#esUN0FPgdH199-filter)">
                  <path
                    id="esUN0FPgdH1100"
                    d="M96.711985,145.439636L96.7109,127.987317h.001085c0,0,0,0,0,0"
                    transform="translate(64-105.000001)"
                    fill="none"
                    stroke="url(#esUN0FPgdH1100-stroke)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="esUN0FPgdH1101_to" transform="translate(161,25.504306)">
                    <g id="esUN0FPgdH1101_tr" transform="rotate(0)">
                      <rect
                        id="esUN0FPgdH1101"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0.7"
                        filter="url(#esUN0FPgdH1101-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="esUN0FPgdH1102_to" transform="translate(161,25.504306)">
                    <g id="esUN0FPgdH1102_tr" transform="rotate(0)">
                      <rect
                        id="esUN0FPgdH1102"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#esUN0FPgdH1103-filter)">
                  <path
                    id="esUN0FPgdH1104"
                    d="M96.7109,143.118v-.064999l.1973-.999999c0,0-.109773,1.999999-.109773,1.999999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#esUN0FPgdH1104-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="esUN0FPgdH1105_to" transform="translate(161.8,50.787316)">
                    <g id="esUN0FPgdH1105_tr" transform="rotate(0)">
                      <rect
                        id="esUN0FPgdH1105"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0"
                        filter="url(#esUN0FPgdH1105-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="esUN0FPgdH1106_to" transform="translate(161.8,50.787316)">
                    <g id="esUN0FPgdH1106_tr" transform="rotate(0)">
                      <rect
                        id="esUN0FPgdH1106"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#esUN0FPgdH1107-filter)">
                  <path
                    id="esUN0FPgdH1108"
                    d="M96.7109,143.118v-.064999l.1973-.999999c0,0-.109773,1.999999-.109773,1.999999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#esUN0FPgdH1108-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="esUN0FPgdH1109_to" transform="translate(161.5,49.521297)">
                    <rect
                      id="esUN0FPgdH1109"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#esUN0FPgdH1109-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="esUN0FPgdH1110_to" transform="translate(161.5,49.521297)">
                    <rect
                      id="esUN0FPgdH1110"
                      width="5"
                      height="10"
                      rx="0"
                      ry="0"
                      transform="translate(-2.5,-5)"
                      opacity="0"
                      filter="url(#esUN0FPgdH1110-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <mask id="esUN0FPgdH1111" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 75.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(15 0)">
              <path d="M32.0957,229.88v-108.202L222.955,76.73v-76.413594" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#esUN0FPgdH1115-filter)">
                <path
                  id="esUN0FPgdH1116"
                  d="M32.0957,249.88v-9.358702v0v.003136"
                  transform="translate(64.996286-105.187118)"
                  opacity="0"
                  fill="none"
                  stroke="url(#esUN0FPgdH1116-stroke)"
                  strokeLinecap="round"
                />
                <g id="esUN0FPgdH1117_to" transform="translate(96.798427,143.537316)">
                  <g id="esUN0FPgdH1117_tr" transform="rotate(0)">
                    <rect
                      id="esUN0FPgdH1117"
                      width="10"
                      height="17.142857"
                      rx="0"
                      ry="0"
                      transform="scale(0.7,0.7) translate(-5,-8.571429)"
                      opacity="0"
                      filter="url(#esUN0FPgdH1117-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="esUN0FPgdH1118_to" transform="translate(96.798427,142.237316)">
                  <g id="esUN0FPgdH1118_tr" transform="rotate(0)">
                    <rect
                      id="esUN0FPgdH1118"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      filter="url(#esUN0FPgdH1118-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      strokeMiterlimit="8"
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#esUN0FPgdH1119-filter)">
                <path
                  id="esUN0FPgdH1120"
                  d="M32.0957,249.88v-10.732683h-.003714v-.001023"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#esUN0FPgdH1120-stroke)"
                  strokeLinecap="round"
                />
                <g id="esUN0FPgdH1121_to" transform="translate(97,140.121297)">
                  <g id="esUN0FPgdH1121_tr" transform="rotate(0)">
                    <rect
                      id="esUN0FPgdH1121"
                      width="10"
                      height="17.142857"
                      rx="0"
                      ry="0"
                      transform="scale(0.7,0.7) translate(-5,-8.571428)"
                      opacity="0"
                      filter="url(#esUN0FPgdH1121-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="esUN0FPgdH1122_to" transform="translate(97,140.121297)">
                  <g id="esUN0FPgdH1122_tr" transform="rotate(0)">
                    <rect
                      id="esUN0FPgdH1122"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <mask id="esUN0FPgdH1123" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="825.762291"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #esUN0FPgdH19 {
    animation-name: esUN0FPgdH19__m, esUN0FPgdH19_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes esUN0FPgdH19__m {
    0% {
      d: path('M853.309,172.852398L853.309,121.49963L764.741674,100.215254L764.220825,100.215253');
    }
    8.75% {
      d: path('M853.309,121.605025L853.309,121.49963L665.296,76.728L665.296,76.180546');
    }
    10% {
      d: path('M829.807375,115.995397L665.320603,76.728004L665.296,76.728L665.296,67.046162');
    }
    13.75% {
      d: path('M759.302501,99.166512L665.296001,76.728L665.296,16.128046L665.296,16.128048');
    }
    20% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,-26.288229L663.283594,-28.24781');
    }
    100% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,-26.288229L663.283594,-28.24781');
    }
  }
  @keyframes esUN0FPgdH19_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    18.75% {
      opacity: 1;
    }
    26.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH110_to {
    animation: esUN0FPgdH110_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH110_to__to {
    0% {
      transform: translate(828.317602px, -5.431736px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(736.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(730.920607px, -32.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.219355px, -122.688381px);
    }
    100% {
      transform: translate(731.219355px, -122.688381px);
    }
  }
  #esUN0FPgdH110_tr {
    animation: esUN0FPgdH110_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH110_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    7.5% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH110 {
    animation: esUN0FPgdH110_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH110_c_o {
    0% {
      opacity: 0.7;
    }
    20% {
      opacity: 0.7;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH111_to {
    animation: esUN0FPgdH111_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH111_to__to {
    0% {
      transform: translate(828.317602px, -5.431736px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(736.801035px, -27.63967px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(730.851035px, -32.48073px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.219354px, -122.688381px);
    }
    100% {
      transform: translate(731.219354px, -122.688381px);
    }
  }
  #esUN0FPgdH111_tr {
    animation: esUN0FPgdH111_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH111_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    7.5% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH111 {
    animation: esUN0FPgdH111_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH111_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH113 {
    animation-name: esUN0FPgdH113__m, esUN0FPgdH113_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes esUN0FPgdH113__m {
    0% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    30% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    37.5% {
      d: path('M853.309,237.53995L853.309,151.87312L853.306432,148.3585L853.309001,144.380103');
    }
    40% {
      d: path('M853.309,232.641L853.309,121.49963L853.309,121.605025L852.281356,122.594792');
    }
    56.25% {
      d: path('M853.309,121.605025L853.309,121.49963L665.296,76.728L665.296,76.180546');
    }
    62.5% {
      d: path('M759.302501,99.166512L665.292414,76.725021L665.296,76.728L665.296,11.229098');
    }
    68.75% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,-26.288232L664.289797,-27.26802');
    }
    100% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,-26.288232L664.289797,-27.26802');
    }
  }
  @keyframes esUN0FPgdH113_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    66.25% {
      opacity: 1;
    }
    73.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH114_to {
    animation: esUN0FPgdH114_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH114_to__to {
    0% {
      transform: translate(917.5px, 138.546297px);
    }
    25% {
      transform: translate(917.5px, 138.546297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 23.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(913.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(905.3352px, 13.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(736.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(730.920607px, -32.510292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -38.49755px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(730.9px, -120.728703px);
    }
    100% {
      transform: translate(730.9px, -120.728703px);
    }
  }
  #esUN0FPgdH114_tr {
    animation: esUN0FPgdH114_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH114_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-75deg);
    }
    55% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH114 {
    animation: esUN0FPgdH114_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH114_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 0.7;
    }
    68.75% {
      opacity: 0.7;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH115_to {
    animation: esUN0FPgdH115_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH115_to__to {
    0% {
      transform: translate(917.5px, 138.546297px);
    }
    25% {
      transform: translate(917.5px, 138.546297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 23.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(913.568884px, 15.76033px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(904.798427px, 13.4px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(736.801035px, -27.43967px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(730.920607px, -32.510292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -38.49755px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(730.9px, -120.228703px);
    }
    100% {
      transform: translate(730.9px, -120.228703px);
    }
  }
  #esUN0FPgdH115_tr {
    animation: esUN0FPgdH115_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH115_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-75deg);
    }
    55% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH115 {
    animation: esUN0FPgdH115_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH115_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    68.75% {
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH117 {
    animation-name: esUN0FPgdH117__m, esUN0FPgdH117_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes esUN0FPgdH117__m {
    0% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    81.25% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    85% {
      d: path('M853.309,244.888375L853.309,191.554615L853.309,190.489945L853.078127,188.258922');
    }
    90% {
      d: path('M853.309,235.090475L853.309,135.510627L853.309,135.382009L853.262825,134.218093');
    }
    91.25% {
      d: path('M853.309,232.641L853.309,121.490775L853.309,121.490775L850.26508,120.712728');
    }
    100% {
      d: path('M853.309,172.852398L853.309,121.49963L762.497203,100.468958L762.736434,100.468959');
    }
  }
  @keyframes esUN0FPgdH117_c_o {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    76.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #esUN0FPgdH118_to {
    animation: esUN0FPgdH118_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH118_to__to {
    0% {
      transform: translate(917.5px, 140.146297px);
    }
    75% {
      transform: translate(917.5px, 140.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.5px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(913.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(905.3352px, 13.841812px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(826.798304px, -5.828881px);
    }
  }
  #esUN0FPgdH118_tr {
    animation: esUN0FPgdH118_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH118_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #esUN0FPgdH118 {
    animation: esUN0FPgdH118_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH118_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #esUN0FPgdH119_to {
    animation: esUN0FPgdH119_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH119_to__to {
    0% {
      transform: translate(917.5px, 140.146297px);
    }
    75% {
      transform: translate(917.5px, 140.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.5px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(913.3px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(904.798427px, 13.4px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(827.121519px, -5.98073px);
    }
  }
  #esUN0FPgdH119_tr {
    animation: esUN0FPgdH119_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH119_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #esUN0FPgdH119 {
    animation: esUN0FPgdH119_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH119_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #esUN0FPgdH124 {
    animation-name: esUN0FPgdH124__m, esUN0FPgdH124_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes esUN0FPgdH124__m {
    0% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    12.5% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    17.5% {
      d: path('M781.258,146.812429L781.258,144.110571L781.527118,145.664782L780.755126,143.963766');
    }
    21.25% {
      d: path('M781.258,138.241L781.258,121.682L779.797456,121.682L778.627971,122.696102');
    }
    35% {
      d: path('M781.258,123.007286L781.258,121.682L637.785835,79.537978L637.688378,80.166713');
    }
    36.25% {
      d: path('M781.258,121.622403L781.258,121.682L628.386,76.7624L628.386,77.3561');
    }
    41.25% {
      d: path('M720.1092,103.542346L628.381986,76.766257L628.386,76.7624L628.386,20.55116');
    }
    43.75% {
      d: path('M683.41992,92.694312L628.381986,76.766257L628.386,76.7624L628.386,-5.531804');
    }
    47.5% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,-19.636903L627.386,-20.65625');
    }
    100% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,-19.636903L627.386,-20.65625');
    }
  }
  @keyframes esUN0FPgdH124_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    45% {
      opacity: 1;
    }
    53.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH125_to {
    animation: esUN0FPgdH125_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH125_to__to {
    0% {
      transform: translate(846px, 51.646297px);
    }
    12.5% {
      transform: translate(846px, 51.646297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    20% {
      transform: translate(846.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(842.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    22.5% {
      transform: translate(831.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(699.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(693.5px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(693.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    47.5% {
      transform: translate(693.9px, -120.728703px);
    }
    100% {
      transform: translate(693.9px, -120.728703px);
    }
  }
  #esUN0FPgdH125_tr {
    animation: esUN0FPgdH125_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH125_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-73deg);
    }
    35% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH125 {
    animation: esUN0FPgdH125_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH125_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.7;
    }
    47.5% {
      opacity: 0.7;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH126_to {
    animation: esUN0FPgdH126_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH126_to__to {
    0% {
      transform: translate(846px, 51.646297px);
    }
    12.5% {
      transform: translate(846px, 51.646297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    20% {
      transform: translate(846.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(842.3px, 15.5px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    22.5% {
      transform: translate(830.604721px, 12.350134px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(699.801035px, -26.390926px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(693.411987px, -31.390926px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(693.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    47.5% {
      transform: translate(693.9px, -122.228703px);
    }
    100% {
      transform: translate(693.9px, -122.228703px);
    }
  }
  #esUN0FPgdH126_tr {
    animation: esUN0FPgdH126_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH126_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-73deg);
    }
    35% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH126 {
    animation: esUN0FPgdH126_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH126_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    47.5% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH128 {
    animation-name: esUN0FPgdH128__m, esUN0FPgdH128_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes esUN0FPgdH128__m {
    0% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    51.25% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    56.25% {
      d: path('M781.258,146.812429L781.258,144.110571L781.527118,145.664782L780.755126,143.963766');
    }
    60% {
      d: path('M781.258,138.241L781.258,121.682L779.797456,121.682L778.627971,122.696102');
    }
    73.75% {
      d: path('M781.258,121.622403L781.258,121.682L628.386,76.7624L628.386,77.3561');
    }
    78.75% {
      d: path('M720.1092,103.542346L628.381986,76.766257L628.386,76.7624L628.386,20.55116');
    }
    86.25% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,-19.636903L627.386,-20.65625');
    }
    100% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,-19.636903L627.386,-20.65625');
    }
  }
  @keyframes esUN0FPgdH128_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 1;
    }
    91.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH129_to {
    animation: esUN0FPgdH129_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH129_to__to {
    0% {
      transform: translate(846px, 51.146297px);
    }
    51.25% {
      transform: translate(846px, 51.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    58.75% {
      transform: translate(846.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    60% {
      transform: translate(842.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    61.25% {
      transform: translate(831.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    72.5% {
      transform: translate(699.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    73.75% {
      transform: translate(693.5px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    75% {
      transform: translate(693.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    86.25% {
      transform: translate(693.9px, -119.728703px);
    }
    100% {
      transform: translate(693.9px, -119.728703px);
    }
  }
  #esUN0FPgdH129_tr {
    animation: esUN0FPgdH129_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH129_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-73deg);
    }
    72.5% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH129 {
    animation: esUN0FPgdH129_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH129_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 0.7;
    }
    86.25% {
      opacity: 0.7;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH130_to {
    animation: esUN0FPgdH130_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH130_to__to {
    0% {
      transform: translate(846px, 51.146297px);
    }
    51.25% {
      transform: translate(846px, 51.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    58.75% {
      transform: translate(846.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    60% {
      transform: translate(841.104721px, 14.850134px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    61.25% {
      transform: translate(830.798427px, 12.350134px);
      animation-timing-function: cubic-bezier(0.362783, 0.26751, 0.665574, 0.631248);
    }
    70% {
      transform: translate(730.481014px, -17.118051px);
      animation-timing-function: cubic-bezier(0.322679, 0.334175, 0.653773, 0.669602);
    }
    72.5% {
      transform: translate(698.801035px, -26.8px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    73.75% {
      transform: translate(693.411987px, -31.290926px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    75% {
      transform: translate(693.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    86.25% {
      transform: translate(693.9px, -121.228703px);
    }
    100% {
      transform: translate(693.9px, -121.228703px);
    }
  }
  #esUN0FPgdH130_tr {
    animation: esUN0FPgdH130_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH130_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-73deg);
    }
    72.5% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH130 {
    animation: esUN0FPgdH130_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH130_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    86.25% {
      opacity: 1;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH136 {
    animation-name: esUN0FPgdH136__m, esUN0FPgdH136_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes esUN0FPgdH136__m {
    0% {
      d: path(
        'M692.384111,111.206804L692.384111,111.087919L591.532,76.7311C591.532,76.7311,591.54,68.945745,591.54,68.945745'
      );
    }
    3.75% {
      d: path(
        'M649.161778,96.431503L649.161778,96.498797L591.532,76.7311C591.532,76.7311,591.54,19.285326,591.54,19.285326'
      );
    }
    8.75% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-20.261899,591.54,-20.261899'
      );
    }
    100% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-20.261899,591.54,-20.261899'
      );
    }
  }
  @keyframes esUN0FPgdH136_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    6.25% {
      opacity: 1;
    }
    15% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH137_to {
    animation: esUN0FPgdH137_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH137_to__to {
    0% {
      transform: translate(657px, -35.643085px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #esUN0FPgdH137 {
    animation: esUN0FPgdH137_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH137_c_o {
    0% {
      opacity: 0.7;
    }
    10% {
      opacity: 0.7;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH138_to {
    animation: esUN0FPgdH138_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH138_to__to {
    0% {
      transform: translate(657px, -35.643085px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #esUN0FPgdH138 {
    animation: esUN0FPgdH138_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH138_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    10% {
      opacity: 1;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH140 {
    animation-name: esUN0FPgdH140__m, esUN0FPgdH140_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes esUN0FPgdH140__m {
    0% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    22.5% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    30% {
      d: path(
        'M721.199,242.865L721.199,212.314L721.03054,210.946856C721.03054,210.946856,721.013534,209.569032,721.013534,209.569032'
      );
    }
    36.25% {
      d: path(
        'M721.199,234.531667L721.199,136.064L721.192993,131.635822C721.192993,131.635822,721.192993,128.271298,721.192993,128.271298'
      );
    }
    37.5% {
      d: path(
        'M721.199,232.865L721.199,120.814L721.192993,120.949437C721.192993,120.949437,721.192993,120.410429,721.192993,120.410429'
      );
    }
    48.75% {
      d: path(
        'M721.199,141.385731L721.199,120.814L615.106726,84.770798C615.106726,84.770798,607.822831,82.348307,607.822831,82.348307'
      );
    }
    51.25% {
      d: path('M721.199,121.057004L721.199,120.814L591.532,76.7311C591.532,76.7311,591.54,75.862215,591.54,75.862215');
    }
    60% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-0.261899,591.54,-0.261899'
      );
    }
    100% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-0.261899,591.54,-0.261899'
      );
    }
  }
  @keyframes esUN0FPgdH140_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    57.5% {
      opacity: 1;
    }
    65% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH141_to {
    animation: esUN0FPgdH141_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH141_to__to {
    0% {
      transform: translate(786.5px, 139.146297px);
    }
    22.5% {
      transform: translate(786.5px, 139.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(784.5px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(774.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    50% {
      transform: translate(660.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(656.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #esUN0FPgdH141_tr {
    animation: esUN0FPgdH141_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH141_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(-70deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH141 {
    animation: esUN0FPgdH141_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH141_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 0.7;
    }
    62.5% {
      opacity: 0.7;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH142_to {
    animation: esUN0FPgdH142_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH142_to__to {
    0% {
      transform: translate(786.5px, 139.146297px);
    }
    22.5% {
      transform: translate(786.5px, 139.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(783.150027px, 14.353271px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(773.8px, 10.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    50% {
      transform: translate(659.801035px, -27.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(656.851035px, -32.387789px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -121.228703px);
    }
    100% {
      transform: translate(656.9px, -121.228703px);
    }
  }
  #esUN0FPgdH142_tr {
    animation: esUN0FPgdH142_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH142_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(-70deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH142 {
    animation: esUN0FPgdH142_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH142_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    62.5% {
      opacity: 1;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH144 {
    animation-name: esUN0FPgdH144__m, esUN0FPgdH144_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes esUN0FPgdH144__m {
    0% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    68.75% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    76.25% {
      d: path(
        'M721.199,242.865L721.199,212.314L721.03054,210.946856C721.03054,210.946856,721.013534,209.569032,721.013534,209.569032'
      );
    }
    82.5% {
      d: path(
        'M721.199,234.531667L721.199,137.56998L721.170923,136.615492C721.170923,136.615492,721.168089,135.535155,721.168089,135.535155'
      );
    }
    83.75% {
      d: path(
        'M721.199,232.865L721.199,120.814001L721.199,120.814C721.199,120.814,718.851622,120.132167,718.851622,120.132167'
      );
    }
    97.5% {
      d: path('M721.199,121.057004L721.199,120.814L591.532,76.7311C591.532,76.7311,591.54,75.862215,591.54,75.862215');
    }
    100% {
      d: path(
        'M692.384111,111.206804L692.384111,111.087919L591.532,76.7311C591.532,76.7311,591.54,68.945745,591.54,68.945745'
      );
    }
  }
  @keyframes esUN0FPgdH144_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #esUN0FPgdH145_to {
    animation: esUN0FPgdH145_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH145_to__to {
    0% {
      transform: translate(786.5px, 138.146297px);
    }
    68.75% {
      transform: translate(786.5px, 138.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(784.29007px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(774.3352px, 11.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(661.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(656.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -40.096471px);
    }
  }
  #esUN0FPgdH145_tr {
    animation: esUN0FPgdH145_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH145_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-70deg);
    }
    96.25% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH145 {
    animation: esUN0FPgdH145_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH145_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #esUN0FPgdH146_to {
    animation: esUN0FPgdH146_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH146_to__to {
    0% {
      transform: translate(786.5px, 138.146297px);
    }
    68.75% {
      transform: translate(786.5px, 138.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.5px, 23.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(784.29007px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(773.8px, 11.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(660.801035px, -26.846729px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(656.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -40.096471px);
    }
  }
  #esUN0FPgdH146_tr {
    animation: esUN0FPgdH146_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH146_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-70deg);
    }
    96.25% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH146 {
    animation: esUN0FPgdH146_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH146_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #esUN0FPgdH151 {
    animation-name: esUN0FPgdH151__m, esUN0FPgdH151_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes esUN0FPgdH151__m {
    0% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    5% {
      d: path('M650.977,146.653429L650.977,145.824857L650.761566,146.078382L650.761569,145.329073');
    }
    8.75% {
      d: path('M650.977,138.082L650.977,121.682L650.560991,122.125667L650.560995,122.622405');
    }
    22.5% {
      d: path('M650.977,122.082L650.977,121.682L554.656,76.7305L554.652,76.316494');
    }
    28.75% {
      d: path('M575.124559,86.409359L575.124559,86.409359L554.656,76.7305L554.652,9.316494');
    }
    35% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
    100% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
  }
  @keyframes esUN0FPgdH151_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    32.5% {
      opacity: 1;
    }
    40% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH152_to {
    animation: esUN0FPgdH152_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH152_to__to {
    0% {
      transform: translate(715.5px, 52.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(712.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(706.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(624.801035px, -25.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(619.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(619.9px, -121.728703px);
    }
    100% {
      transform: translate(619.9px, -121.728703px);
    }
  }
  #esUN0FPgdH152_tr {
    animation: esUN0FPgdH152_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH152_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-68deg);
    }
    21.25% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH152 {
    animation: esUN0FPgdH152_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH152_c_o {
    0% {
      opacity: 0.7;
    }
    35% {
      opacity: 0.7;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH153_to {
    animation: esUN0FPgdH153_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH153_to__to {
    0% {
      transform: translate(715.5px, 52.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(711.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(705.798427px, 12.6px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(623.801035px, -26.140845px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(619.911987px, -32.40395px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(619.9px, -122.228703px);
    }
    100% {
      transform: translate(619.9px, -122.228703px);
    }
  }
  #esUN0FPgdH153_tr {
    animation: esUN0FPgdH153_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH153_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-68deg);
    }
    21.25% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH153 {
    animation: esUN0FPgdH153_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH153_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    35% {
      opacity: 1;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH155 {
    animation-name: esUN0FPgdH155__m, esUN0FPgdH155_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes esUN0FPgdH155__m {
    0% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    43.75% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    48.75% {
      d: path('M650.977,146.653429L650.977,145.824857L650.761566,146.078382L650.761569,145.329073');
    }
    52.5% {
      d: path('M650.977,138.082L650.977,121.682L650.560991,122.125667L650.560995,122.622405');
    }
    66.25% {
      d: path('M650.977,122.082L650.977,121.682L554.656,76.7305L554.652,76.316494');
    }
    72.5% {
      d: path('M575.124559,86.409359L575.124559,86.409359L554.656,76.7305L554.652,9.316494');
    }
    78.75% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
    100% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
  }
  @keyframes esUN0FPgdH155_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 1;
    }
    76.25% {
      opacity: 1;
    }
    83.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH156_to {
    animation: esUN0FPgdH156_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH156_to__to {
    0% {
      transform: translate(715.298427px, 50.146297px);
    }
    43.75% {
      transform: translate(715.298427px, 50.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(713.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(707.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(624.801035px, -25.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(619.920607px, -29.810292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -36.79755px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(619.9px, -117.878703px);
    }
    100% {
      transform: translate(619.9px, -117.878703px);
    }
  }
  #esUN0FPgdH156_tr {
    animation: esUN0FPgdH156_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH156_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-68deg);
    }
    65% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH156 {
    animation: esUN0FPgdH156_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH156_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 0.7;
    }
    78.75% {
      opacity: 0.7;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH157_to {
    animation: esUN0FPgdH157_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH157_to__to {
    0% {
      transform: translate(715.298427px, 50.146297px);
    }
    43.75% {
      transform: translate(715.298427px, 50.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(713.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(707.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(626.801035px, -24.6px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(619.920607px, -29.810292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -36.79755px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(619.9px, -117.878703px);
    }
    100% {
      transform: translate(619.9px, -117.878703px);
    }
  }
  #esUN0FPgdH157_tr {
    animation: esUN0FPgdH157_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH157_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-68deg);
    }
    65% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH157 {
    animation: esUN0FPgdH157_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH157_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 1;
    }
    78.75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH164 {
    animation-name: esUN0FPgdH164__m, esUN0FPgdH164_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes esUN0FPgdH164__m {
    0% {
      d: path('M278.699999,292.722204L278.699999,292.90171L278.531,292.7383L278.535,206.324183');
    }
    18.75% {
      d: path('M278.699999,292.722204L278.699999,292.90171L278.531,292.7383L278.535,206.324183');
    }
    36.25% {
      d: path('M278.699999,202.446342L278.699999,202.625848L278.531,202.462438L278.535,116.048321');
    }
    50% {
      d: path('M278.699999,101.248641L278.699999,101.428147L278.531,101.264737L278.535,14.85062');
    }
    55% {
      d: path('M278.699999,66.722204L278.699999,66.90171L278.531,66.7383L278.535,-19.675817');
    }
    100% {
      d: path('M278.699999,66.722204L278.699999,66.90171L278.531,66.7383L278.535,-19.675817');
    }
  }
  @keyframes esUN0FPgdH164_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    52.5% {
      opacity: 1;
    }
    58.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH165_to {
    animation: esUN0FPgdH165_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH165_to__to {
    0% {
      transform: translate(344px, 52.271297px);
    }
    18.75% {
      transform: translate(344px, 52.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    53.75% {
      transform: translate(344px, -119.228703px);
    }
    100% {
      transform: translate(344px, -119.228703px);
    }
  }
  #esUN0FPgdH165 {
    animation: esUN0FPgdH165_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH165_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 0.7;
    }
    53.75% {
      opacity: 0.7;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH166_to {
    animation: esUN0FPgdH166_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH166_to__to {
    0% {
      transform: translate(344px, 52.271297px);
    }
    18.75% {
      transform: translate(344px, 52.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    53.75% {
      transform: translate(344px, -119.228703px);
    }
    100% {
      transform: translate(344px, -119.228703px);
    }
  }
  #esUN0FPgdH166 {
    animation: esUN0FPgdH166_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH166_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    53.75% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH168 {
    animation-name: esUN0FPgdH168__m, esUN0FPgdH168_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes esUN0FPgdH168__m {
    0% {
      d: path('M278.699999,292.722204L278.699999,292.90171L278.531,292.7383L278.535,206.324183');
    }
    62.5% {
      d: path('M278.699999,292.722204L278.699999,292.90171L278.531,292.7383L278.535,206.324183');
    }
    80% {
      d: path('M278.699999,202.446342L278.699999,202.625848L278.531,202.462438L278.915001,115.219161');
    }
    93.75% {
      d: path('M278.699999,101.248641L278.699999,101.428147L278.531,101.264737L278.535,14.85062');
    }
    98.75% {
      d: path('M278.699999,66.722204L278.699999,66.90171L278.531,66.7383L278.535,-19.675817');
    }
    100% {
      d: path('M278.699999,66.722204L278.699999,66.90171L278.531,66.7383L278.535,-19.675817');
    }
  }
  @keyframes esUN0FPgdH168_c_o {
    0% {
      opacity: 0;
    }
    61.25% {
      opacity: 0;
    }
    62.5% {
      opacity: 1;
    }
    93.75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH169_to {
    animation: esUN0FPgdH169_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH169_to__to {
    0% {
      transform: translate(344px, 52.271297px);
    }
    62.5% {
      transform: translate(344px, 52.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    97.5% {
      transform: translate(344px, -119.228703px);
    }
    100% {
      transform: translate(344px, -119.228703px);
    }
  }
  #esUN0FPgdH169 {
    animation: esUN0FPgdH169_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH169_c_o {
    0% {
      opacity: 0;
    }
    61.25% {
      opacity: 0;
    }
    62.5% {
      opacity: 0.7;
    }
    97.5% {
      opacity: 0.7;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH170_to {
    animation: esUN0FPgdH170_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH170_to__to {
    0% {
      transform: translate(344px, 52.271297px);
    }
    62.5% {
      transform: translate(344px, 52.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    97.5% {
      transform: translate(344px, -119.228703px);
    }
    100% {
      transform: translate(344px, -119.228703px);
    }
  }
  #esUN0FPgdH170 {
    animation: esUN0FPgdH170_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH170_c_o {
    0% {
      opacity: 0;
    }
    61.25% {
      opacity: 0;
    }
    62.5% {
      opacity: 1;
    }
    97.5% {
      opacity: 1;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH177 {
    animation-name: esUN0FPgdH177__m, esUN0FPgdH177_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes esUN0FPgdH177__m {
    0% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    18.75% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    23.75% {
      d: path('M226.994001,149.78715L226.994,145.868001L227.0645,146.865005L226.907042,146.549048');
    }
    28.75% {
      d: path('M226.994,138.053L226.994,121.683L227.029,121.677006L228.785083,121.045093');
    }
    40% {
      d: path('M227.029,121.683L226.994,121.683L333.531,76.7383L333.535,76.696467');
    }
    42.5% {
      d: path('M245.992733,113.68997L333.531985,76.766257L333.531,76.7383L333.535,65.343379');
    }
    50% {
      d: path('M312.365799,85.714363L333.531985,76.766257L333.531,76.7383L333.535,-14.892427');
    }
    55% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,-9.65625L332.535,-10.675817');
    }
    100% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,-9.65625L332.535,-10.675817');
    }
  }
  @keyframes esUN0FPgdH177_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    52.5% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH178_to {
    animation: esUN0FPgdH178_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH178_to__to {
    0% {
      transform: translate(292px, 52.271297px);
    }
    18.75% {
      transform: translate(292px, 52.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(292px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(295.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(304.3352px, 12.1px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(398.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -119.728703px);
    }
    100% {
      transform: translate(398.699999px, -119.728703px);
    }
  }
  #esUN0FPgdH178_tr {
    animation: esUN0FPgdH178_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH178_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(68deg);
    }
    30% {
      transform: rotate(68deg);
    }
    40% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH178 {
    animation: esUN0FPgdH178_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH178_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 0.7;
    }
    53.75% {
      opacity: 0.7;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH179_to {
    animation: esUN0FPgdH179_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH179_to__to {
    0% {
      transform: translate(292px, 52.271297px);
    }
    18.75% {
      transform: translate(292px, 52.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(292px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(296.798427px, 14.51295px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(305.474547px, 11.51295px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(398.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -120.228703px);
    }
    100% {
      transform: translate(398.699999px, -120.228703px);
    }
  }
  #esUN0FPgdH179_tr {
    animation: esUN0FPgdH179_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH179_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(68deg);
    }
    40% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH179 {
    animation: esUN0FPgdH179_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH179_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    53.75% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH181 {
    animation-name: esUN0FPgdH181__m, esUN0FPgdH181_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes esUN0FPgdH181__m {
    0% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    58.75% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    63.75% {
      d: path('M226.994001,149.78715L226.994,145.868001L227.0645,146.865005L226.907042,146.549048');
    }
    68.75% {
      d: path('M226.994,138.053L226.994,121.683L227.029,121.677006L228.785083,121.045093');
    }
    81.25% {
      d: path('M227.029,121.683L226.994,121.683L333.531,76.7383L333.535,76.687317');
    }
    92.5% {
      d: path('M312.365799,85.714363L312.358799,85.857968L333.531,76.7383L333.535,-14.892427');
    }
    95% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
    100% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
  }
  @keyframes esUN0FPgdH181_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    92.5% {
      opacity: 1;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH182_to {
    animation: esUN0FPgdH182_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH182_to__to {
    0% {
      transform: translate(292px, 49.646297px);
    }
    58.75% {
      transform: translate(292px, 49.646297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(295.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(304.3352px, 12.1px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(398.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -119.378703px);
    }
    100% {
      transform: translate(398.699999px, -119.378703px);
    }
  }
  #esUN0FPgdH182_tr {
    animation: esUN0FPgdH182_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH182_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(68deg);
    }
    70% {
      transform: rotate(68deg);
    }
    81.25% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH182 {
    animation: esUN0FPgdH182_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH182_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 0.7;
    }
    95% {
      opacity: 0.7;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH183_to {
    animation: esUN0FPgdH183_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH183_to__to {
    0% {
      transform: translate(292px, 49.646297px);
    }
    58.75% {
      transform: translate(292px, 49.646297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(296.798427px, 14px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(305.174547px, 11.51295px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(396.801035px, -27.740845px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(398.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -119.378703px);
    }
    100% {
      transform: translate(398.699999px, -119.378703px);
    }
  }
  #esUN0FPgdH183_tr {
    animation: esUN0FPgdH183_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH183_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(68deg);
    }
    81.25% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH183 {
    animation: esUN0FPgdH183_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH183_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH189 {
    animation-name: esUN0FPgdH189__m, esUN0FPgdH189_c_o, esUN0FPgdH189_s_p;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes esUN0FPgdH189__m {
    0% {
      d: path(
        'M161.867,259.879L161.867,253.489998C161.867,253.489998,161.85,251.271299,161.85,251.271299L161.798427,249.396294'
      );
    }
    11.25% {
      d: path(
        'M161.867,259.879L161.867,253.489998C161.867,253.489998,161.85,251.271299,161.85,251.271299L161.798427,249.396294'
      );
    }
    15% {
      d: path(
        'M161.867,249.879L161.867,206.552332C161.867,206.552332,161.85,206.388335,161.85,206.388335L162.82053,203.852523'
      );
    }
    18.75% {
      d: path(
        'M161.867,239.879L161.867,160.864666C161.867,160.864666,161.85,161.005371,161.85,161.005371L162.092632,158.308752'
      );
    }
    20% {
      d: path(
        'M161.867,236.545667L161.867,150.968777C161.867,150.968777,161.85,151.211049,161.85,151.211049L161.85,143.127495'
      );
    }
    21.25% {
      d: path(
        'M161.867,233.212334L161.867,128.322889C161.867,128.322889,161.948322,128.392741,161.948322,128.392741L163.26745,127.025309'
      );
    }
    22.5% {
      d: path(
        'M161.867,229.879L161.867,121.677C161.867,121.677,162.046644,121.574432,162.046644,121.574432L163.267451,121.103854'
      );
    }
    32.5% {
      d: path(
        'M161.867,143.3214L161.867,121.677C161.867,121.677,277.422107,83.120632,277.422107,83.120632L280.268001,82.288075'
      );
    }
    35% {
      d: path('M161.867,121.682L161.867,121.677C161.867,121.677,296.68,76.7222,296.68,76.7222L296.854534,76.35604');
    }
    42.5% {
      d: path(
        'M235.303182,96.99487L235.521364,96.992597C235.521364,96.992597,296.68,76.7222,296.68,76.7222L296.68,3.623396'
      );
    }
    47.5% {
      d: path('M296.5,76.422261L296.9,76.422262C296.9,76.422262,296.68,76.7222,296.68,76.7222L296.68,-19.691469');
    }
    100% {
      d: path('M296.5,76.422261L296.9,76.422262C296.9,76.422262,296.68,76.7222,296.68,76.7222L296.68,-19.691469');
    }
  }
  @keyframes esUN0FPgdH189_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    45% {
      opacity: 1;
    }
    53.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes esUN0FPgdH189_s_p {
    0% {
      stroke: url(#esUN0FPgdH189-stroke);
    }
    18.75% {
      stroke: url(#esUN0FPgdH189-stroke);
    }
    20% {
      stroke: url(#esUN0FPgdH189-stroke-g1);
    }
    21.25% {
      stroke: url(#esUN0FPgdH189-stroke-g1);
    }
    22.5% {
      stroke: url(#esUN0FPgdH189-stroke-g1);
    }
    23.75% {
      stroke: url(#esUN0FPgdH189-stroke-g1);
    }
    25% {
      stroke: url(#esUN0FPgdH189-stroke-g1);
    }
    28.75% {
      stroke: url(#esUN0FPgdH189-stroke-g2);
    }
    38.75% {
      stroke: url(#esUN0FPgdH189-stroke-g3);
    }
    41.25% {
      stroke: url(#esUN0FPgdH189-stroke-g4);
    }
    100% {
      stroke: url(#esUN0FPgdH189-stroke-g4);
    }
  }
  #esUN0FPgdH189-stroke-g1 {
    animation: esUN0FPgdH189-stroke-g1__t 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH189-stroke-g1__t {
    0% {
      transform: matrix(0, 0.842525, -0.842525, 0, 736.553247, 0.009907);
    }
    20% {
      transform: matrix(0, 0.842525, -0.842525, 0, 736.553247, 0.009907);
    }
    21.25% {
      transform: matrix(0, 0.75381, -0.75381, 0, 11.961223, 0.049403);
    }
    22.5% {
      transform: matrix(0, 0.659447, -0.659447, 0, 11.961214, 0.04459);
    }
    23.75% {
      transform: matrix(-0.082247, 0.705106, -0.705106, -0.082247, 1, 0.0335);
    }
    25% {
      transform: matrix(-0.361988, 0.720365, -0.720365, -0.361988, 1.188667, 0.05707);
    }
    100% {
      transform: matrix(-0.361988, 0.720365, -0.720365, -0.361988, 1.188667, 0.05707);
    }
  }
  #esUN0FPgdH189-stroke-g1-0 {
    animation: esUN0FPgdH189-stroke-g1-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH189-stroke-g1-0__c {
    0% {
      stop-color: #7ea6ff;
    }
    20% {
      stop-color: #7ea6ff;
    }
    21.25% {
      stop-color: #7ea6ff;
    }
    22.5% {
      stop-color: #7ea6ff;
    }
    23.75% {
      stop-color: #7ea6ff;
    }
    25% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #esUN0FPgdH189-stroke-g1-1 {
    animation: esUN0FPgdH189-stroke-g1-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH189-stroke-g1-1__c {
    0% {
      stop-color: rgba(96, 105, 202, 0);
    }
    20% {
      stop-color: rgba(96, 105, 202, 0);
    }
    21.25% {
      stop-color: rgba(126, 166, 255, 0);
    }
    22.5% {
      stop-color: rgba(116, 151, 241, 0);
    }
    23.75% {
      stop-color: rgba(106, 136, 227, 0);
    }
    25% {
      stop-color: rgba(95, 120, 212, 0);
    }
    100% {
      stop-color: rgba(95, 120, 212, 0);
    }
  }
  #esUN0FPgdH189-stroke-g1-2 {
    animation: esUN0FPgdH189-stroke-g1-2__c 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH189-stroke-g1-2__c {
    0% {
      stop-color: rgba(96, 105, 202, 0);
    }
    20% {
      stop-color: rgba(96, 105, 202, 0);
    }
    21.25% {
      stop-color: rgba(126, 166, 255, 0);
    }
    22.5% {
      stop-color: rgba(116, 151, 241, 0);
    }
    23.75% {
      stop-color: rgba(106, 136, 227, 0);
    }
    25% {
      stop-color: rgba(95, 120, 212, 0);
    }
    100% {
      stop-color: rgba(95, 120, 212, 0);
    }
  }
  #esUN0FPgdH190_to {
    animation: esUN0FPgdH190_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH190_to__to {
    0% {
      transform: translate(227px, 140.246297px);
    }
    6.25% {
      transform: translate(227px, 140.246297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(230.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(239.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(359.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(361.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -120.728703px);
    }
    100% {
      transform: translate(361.9px, -120.728703px);
    }
  }
  #esUN0FPgdH190_tr {
    animation: esUN0FPgdH190_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH190_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    23.75% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH190 {
    animation: esUN0FPgdH190_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH190_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 0.7;
    }
    48.75% {
      opacity: 0.7;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH191_to {
    animation: esUN0FPgdH191_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH191_to__to {
    0% {
      transform: translate(227px, 140.246297px);
    }
    6.25% {
      transform: translate(227px, 140.246297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(231.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(240.798427px, 12.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(360.801035px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(361.851035px, -32.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -121.228703px);
    }
    100% {
      transform: translate(361.9px, -121.228703px);
    }
  }
  #esUN0FPgdH191_tr {
    animation: esUN0FPgdH191_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH191_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH191 {
    animation: esUN0FPgdH191_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH191_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    48.75% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH193 {
    animation-name: esUN0FPgdH193__m, esUN0FPgdH193_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes esUN0FPgdH193__m {
    0% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    56.25% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    63.75% {
      d: path('M161.867,237.379L161.867,154.63025L161.85,154.784629L161.867,146.865005');
    }
    65% {
      d: path('M161.867,229.879L161.867,121.677L161.85,122.622406L163.089459,122.622409');
    }
    66.25% {
      d: path('M161.867,220.862583L161.867,121.677L166.025885,120.271298L166.025885,120.271298');
    }
    71.25% {
      d: path('M161.867,184.796917L161.867,121.677L209.481569,105.790181L213.839324,104.307255');
    }
    80% {
      d: path('M161.867,121.682L161.867,121.677L296.68,76.7222L296.68,76.687317');
    }
    87.5% {
      d: path('M235.303182,96.99487L235.521364,96.992597L296.68,76.7222L296.68,3.623396');
    }
    93.75% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
    100% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
  }
  @keyframes esUN0FPgdH193_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    91.25% {
      opacity: 1;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH194_to {
    animation: esUN0FPgdH194_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH194_to__to {
    0% {
      transform: translate(227px, 139.146297px);
    }
    52.5% {
      transform: translate(227px, 139.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    65% {
      transform: translate(226.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    66.25% {
      transform: translate(230.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(239.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    78.75% {
      transform: translate(359.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    80% {
      transform: translate(361.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(361.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    93.75% {
      transform: translate(361.9px, -119.728703px);
    }
    100% {
      transform: translate(361.9px, -119.728703px);
    }
  }
  #esUN0FPgdH194_tr {
    animation: esUN0FPgdH194_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH194_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(72deg);
    }
    67.5% {
      transform: rotate(72deg);
    }
    78.75% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH194 {
    animation: esUN0FPgdH194_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH194_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 0.7;
    }
    93.75% {
      opacity: 0.7;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH195_to {
    animation: esUN0FPgdH195_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH195_to__to {
    0% {
      transform: translate(227px, 139.146297px);
    }
    52.5% {
      transform: translate(227px, 139.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    65% {
      transform: translate(226.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    66.25% {
      transform: translate(231.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(240.798427px, 12.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    78.75% {
      transform: translate(359.801035px, -27.452471px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    80% {
      transform: translate(361.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(361.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    93.75% {
      transform: translate(361.9px, -120.228703px);
    }
    100% {
      transform: translate(361.9px, -120.228703px);
    }
  }
  #esUN0FPgdH195_tr {
    animation: esUN0FPgdH195_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH195_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(72deg);
    }
    78.75% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH195 {
    animation: esUN0FPgdH195_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH195_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    93.75% {
      opacity: 0.8;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH1100 {
    animation-name: esUN0FPgdH1100__m, esUN0FPgdH1100_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes esUN0FPgdH1100__m {
    0% {
      d: path(
        'M96.711985,145.439636L96.7109,127.987317L96.711985,127.987317C96.711985,127.987317,96.711985,127.987317,96.711985,127.987317'
      );
    }
    1.25% {
      d: path(
        'M96.7109,145.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.711985,121.683001,96.711985,121.683001'
      );
    }
    8.75% {
      d: path(
        'M96.7109,135.000465L96.7109,121.68L163.798426,103.148788C163.798426,103.148788,163.798426,103.148788,163.798426,103.148788'
      );
    }
    16.25% {
      d: path(
        'M96.7109,124.229631L96.7109,121.68L258.531964,77.088615C258.531964,77.088615,258.531964,77.088614,258.531964,77.088614'
      );
    }
    17.5% {
      d: path(
        'M96.7109,122.271298L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817001,75.687789,259.817001,75.687789'
      );
    }
    30% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,-9.65625,259.817,-9.65625');
    }
    100% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,-9.65625,259.817,-9.65625');
    }
  }
  @keyframes esUN0FPgdH1100_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    26.25% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH1101_to {
    animation: esUN0FPgdH1101_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1101_to__to {
    0% {
      transform: translate(161px, 25.504306px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    1.25% {
      transform: translate(166.798427px, 15.088613px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    16.25% {
      transform: translate(322.801035px, -27.911387px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    17.5% {
      transform: translate(324px, -31.712684px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    30% {
      transform: translate(324px, -122.212684px);
    }
    100% {
      transform: translate(324px, -122.212684px);
    }
  }
  #esUN0FPgdH1101_tr {
    animation: esUN0FPgdH1101_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1101_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(73deg);
    }
    16.25% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH1101 {
    animation: esUN0FPgdH1101_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1101_c_o {
    0% {
      opacity: 0.7;
    }
    30% {
      opacity: 0.7;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH1102_to {
    animation: esUN0FPgdH1102_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1102_to__to {
    0% {
      transform: translate(161px, 25.504306px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    1.25% {
      transform: translate(166.798427px, 15.088613px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    16.25% {
      transform: translate(322.801035px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    17.5% {
      transform: translate(324px, -31.712684px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    30% {
      transform: translate(324px, -122.228703px);
    }
    100% {
      transform: translate(324px, -122.228703px);
    }
  }
  #esUN0FPgdH1102_tr {
    animation: esUN0FPgdH1102_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1102_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(73deg);
    }
    16.25% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH1102 {
    animation: esUN0FPgdH1102_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1102_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH1104 {
    animation-name: esUN0FPgdH1104__m, esUN0FPgdH1104_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes esUN0FPgdH1104__m {
    0% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    42.5% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    48.75% {
      d: path(
        'M96.7109,144.776311L96.7109,139.694875L96.839592,139.321751C96.839592,139.321751,96.867036,140.071751,96.867036,140.071751'
      );
    }
    52.5% {
      d: path(
        'M96.7109,145.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.908201,121.683001,96.908201,121.683001'
      );
    }
    60% {
      d: path(
        'M96.7109,134.021298L96.7109,121.68L170.897412,101.279844C170.897412,101.279844,170.897413,101.279844,170.897413,101.279844'
      );
    }
    66.25% {
      d: path(
        'M96.7109,124.229631L96.7109,121.68L259.817002,76.7624C259.817002,76.7624,259.817002,76.767317,259.817002,76.767317'
      );
    }
    67.5% {
      d: path(
        'M96.7109,122.271298L96.7109,121.68L259.817,76.767317C259.817,76.767317,259.817001,75.687789,259.817001,75.687789'
      );
    }
    72.5% {
      d: path(
        'M96.798427,121.438996L259.970615,77.139831L259.817,76.7298C259.817,76.7298,259.817001,47.289957,259.817001,47.289957'
      );
    }
    78.75% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
    100% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
  }
  @keyframes esUN0FPgdH1104_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    76.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH1105_to {
    animation: esUN0FPgdH1105_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1105_to__to {
    0% {
      transform: translate(161.8px, 50.787316px);
    }
    42.5% {
      transform: translate(161.8px, 50.787316px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(161.798427px, 21.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(166.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    66.25% {
      transform: translate(322.801035px, -27.448195px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(324.811985px, -31.712684px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(324.9px, -120.728703px);
    }
    100% {
      transform: translate(324.9px, -120.728703px);
    }
  }
  #esUN0FPgdH1105_tr {
    animation: esUN0FPgdH1105_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1105_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(73deg);
    }
    66.25% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH1105 {
    animation: esUN0FPgdH1105_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1105_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 0.7;
    }
    81.25% {
      opacity: 0.7;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH1106_to {
    animation: esUN0FPgdH1106_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1106_to__to {
    0% {
      transform: translate(161.8px, 50.787316px);
    }
    42.5% {
      transform: translate(161.8px, 50.787316px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(161.798427px, 21.687316px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(166.798427px, 15.671297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    66.25% {
      transform: translate(321.801035px, -27.560064px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(324.811985px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(324.9px, -121.228703px);
    }
    100% {
      transform: translate(324.9px, -121.228703px);
    }
  }
  #esUN0FPgdH1106_tr {
    animation: esUN0FPgdH1106_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1106_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(73deg);
    }
    66.25% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH1106 {
    animation: esUN0FPgdH1106_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1106_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    81.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH1108 {
    animation-name: esUN0FPgdH1108__m, esUN0FPgdH1108_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes esUN0FPgdH1108__m {
    0% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    96.25% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    100% {
      d: path(
        'M96.7109,144.112987L96.7109,141.038125L96.867035,140.414251C96.867035,140.414251,96.839592,141.664251,96.839592,141.664251'
      );
    }
  }
  @keyframes esUN0FPgdH1108_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #esUN0FPgdH1109_to {
    animation: esUN0FPgdH1109_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1109_to__to {
    0% {
      transform: translate(161.5px, 49.521297px);
    }
    96.25% {
      transform: translate(161.5px, 49.521297px);
    }
    100% {
      transform: translate(161.5px, 24.987316px);
    }
  }
  #esUN0FPgdH1109 {
    animation: esUN0FPgdH1109_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1109_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #esUN0FPgdH1110_to {
    animation: esUN0FPgdH1110_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1110_to__to {
    0% {
      transform: translate(161.5px, 49.521297px);
    }
    96.25% {
      transform: translate(161.5px, 49.521297px);
    }
    100% {
      transform: translate(161.5px, 24.987316px);
    }
  }
  #esUN0FPgdH1110 {
    animation: esUN0FPgdH1110_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1110_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #esUN0FPgdH1116 {
    animation-name: esUN0FPgdH1116__m, esUN0FPgdH1116_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes esUN0FPgdH1116__m {
    0% {
      d: path('M32.0957,249.88L32.0957,240.521298L32.0957,240.521298L32.0957,240.524434');
    }
    12.5% {
      d: path('M32.0957,249.88L32.0957,240.521298L32.0957,240.521298L32.0957,240.524434');
    }
    16.25% {
      d: path('M32.0957,244.88L32.0957,207.856471L32.0957,207.856471L33.0957,205.860605');
    }
    27.5% {
      d: path('M32.0957,229.88L32.0957,121.861988L32.0957,121.861988L32.0957,121.869117');
    }
    31.25% {
      d: path('M32.0957,202.407312L32.0957,121.678L80.044445,110.329273L80.044445,110.329272');
    }
    41.25% {
      d: path('M32.0957,129.146811L32.0957,121.678L218.801035,77.955237L218.801035,77.954434');
    }
    42.5% {
      d: path('M32.0957,119.989248L32.0957,121.678L222.955,76.874434L222.920607,76.874434');
    }
    55% {
      d: path('M222.955,76.874434L222.9557,76.874434L222.955,76.874434L222.955,-19.683594');
    }
    100% {
      d: path('M222.955,76.874434L222.9557,76.874434L222.955,76.874434L222.955,-19.683594');
    }
  }
  @keyframes esUN0FPgdH1116_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    61.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH1117_to {
    animation: esUN0FPgdH1117_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1117_to__to {
    0% {
      transform: translate(96.798427px, 143.537316px);
    }
    11.25% {
      transform: translate(96.798427px, 143.537316px);
    }
    26.25% {
      transform: translate(97.111986px, 20.771297px);
    }
    27.5% {
      transform: translate(100.798427px, 15.771297px);
    }
    41.25% {
      transform: translate(284.801035px, -27.679029px);
    }
    42.5% {
      transform: translate(287.920607px, -33.028703px);
    }
    56.25% {
      transform: translate(287.921986px, -120.728703px);
    }
    100% {
      transform: translate(287.921986px, -120.728703px);
    }
  }
  #esUN0FPgdH1117_tr {
    animation: esUN0FPgdH1117_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1117_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(75deg);
    }
    41.25% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH1117 {
    animation: esUN0FPgdH1117_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1117_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.7;
    }
    56.25% {
      opacity: 0.7;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH1118_to {
    animation: esUN0FPgdH1118_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1118_to__to {
    0% {
      transform: translate(96.798427px, 142.237316px);
    }
    11.25% {
      transform: translate(96.798427px, 142.237316px);
    }
    26.25% {
      transform: translate(97.111986px, 20.643116px);
    }
    27.5% {
      transform: translate(100.798427px, 15.771297px);
    }
    41.25% {
      transform: translate(284.833827px, -27.703774px);
    }
    42.5% {
      transform: translate(288.011986px, -33.028703px);
    }
    56.25% {
      transform: translate(288.011986px, -120.728703px);
    }
    100% {
      transform: translate(288.011986px, -120.728703px);
    }
  }
  #esUN0FPgdH1118_tr {
    animation: esUN0FPgdH1118_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1118_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(75deg);
    }
    41.25% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH1118 {
    animation: esUN0FPgdH1118_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1118_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 1;
    }
    56.25% {
      opacity: 1;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH1120 {
    animation-name: esUN0FPgdH1120__m, esUN0FPgdH1120_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes esUN0FPgdH1120__m {
    0% {
      d: path('M32.0957,249.88L32.0957,239.147317L32.091986,239.147317L32.091986,239.146294');
    }
    62.5% {
      d: path('M32.0957,249.88L32.0957,239.147317L32.091986,239.147317L32.091986,239.146294');
    }
    68.75% {
      d: path('M32.0957,233.213333L32.0957,141.403842L32.095081,141.403842L32.095081,141.259382');
    }
    70% {
      d: path('M32.0957,229.88L32.0957,121.855147L32.0957,121.855147L33.503861,121.438996');
    }
    72.5% {
      d: path('M32.0957,214.422474L32.0957,121.82984L48.429334,117.873236L49.368109,117.595802');
    }
    77.5% {
      d: path('M32.0957,183.507422L32.0957,121.779227L105.106945,104.521669L105.106945,104.521669');
    }
    87.5% {
      d: path('M32.0957,121.677317L32.0957,121.678L222.955,76.73L222.951986,76.45812');
    }
    95% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
    100% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
  }
  @keyframes esUN0FPgdH1120_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 1;
    }
    92.5% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH1121_to {
    animation: esUN0FPgdH1121_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1121_to__to {
    0% {
      transform: translate(97px, 140.121297px);
    }
    57.5% {
      transform: translate(97px, 140.121297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(96.798427px, 20.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(100.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(114.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    86.25% {
      transform: translate(283.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(287.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -120.728703px);
    }
    100% {
      transform: translate(287.9px, -120.728703px);
    }
  }
  #esUN0FPgdH1121_tr {
    animation: esUN0FPgdH1121_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1121_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(75deg);
    }
    86.25% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH1121 {
    animation: esUN0FPgdH1121_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1121_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 0.7;
    }
    96.25% {
      opacity: 0.7;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #esUN0FPgdH1122_to {
    animation: esUN0FPgdH1122_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1122_to__to {
    0% {
      transform: translate(97px, 140.121297px);
    }
    57.5% {
      transform: translate(97px, 140.121297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(96.798427px, 19.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(101.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(114.798427px, 12.497225px);
      animation-timing-function: cubic-bezier(0.357385, 0.28504, 0.680746, 0.629112);
    }
    78.75% {
      transform: translate(185.570936px, -4.302775px);
      animation-timing-function: cubic-bezier(0.323457, 0.318946, 0.641855, 0.665929);
    }
    86.25% {
      transform: translate(283.801035px, -27.302775px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(287.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -120.228703px);
    }
    100% {
      transform: translate(287.9px, -120.228703px);
    }
  }
  #esUN0FPgdH1122_tr {
    animation: esUN0FPgdH1122_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1122_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(75deg);
    }
    86.25% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #esUN0FPgdH1122 {
    animation: esUN0FPgdH1122_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes esUN0FPgdH1122_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 1;
    }
    96.25% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesBg9;
