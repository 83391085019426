// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesMobileBg7 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)', // Replace all <stop id="...-stroke-1" stopColor="#initial-color" /> with this value
  };

  return (
    <AnimatedSvg
      id="eZs2HuhKBmA1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 320 250"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="320"
      height="250"
      className="source-background"
      {...props}
    >
      <defs>
        <linearGradient
          id="eZs2HuhKBmA7-stroke"
          x1="0.370708"
          y1="0.690679"
          x2="0.381501"
          y2="0.997708"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZs2HuhKBmA7-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZs2HuhKBmA7-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <filter id="eZs2HuhKBmA8-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA8-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eZs2HuhKBmA8-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA8-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eZs2HuhKBmA8-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA8-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA8-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA8-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZs2HuhKBmA9-stroke"
          x1="-5275.007951"
          y1="0.083835"
          x2="-5275.007951"
          y2="0.902057"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZs2HuhKBmA9-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZs2HuhKBmA9-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eZs2HuhKBmA10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA10-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eZs2HuhKBmA10-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA10-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eZs2HuhKBmA10-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA10-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA10-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA10-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eZs2HuhKBmA10-filter-blur-0" stdDeviation="3,3" result="result" />
        </filter>
        <filter id="eZs2HuhKBmA11-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA11-filter-blur-0" stdDeviation="0,0" result="result" />
        </filter>
        <filter id="eZs2HuhKBmA12-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA12-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eZs2HuhKBmA12-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA12-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eZs2HuhKBmA12-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA12-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA12-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA12-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZs2HuhKBmA13-stroke"
          x1="-5275.007951"
          y1="0.083835"
          x2="-5275.007951"
          y2="0.902057"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZs2HuhKBmA13-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZs2HuhKBmA13-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eZs2HuhKBmA14-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA14-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eZs2HuhKBmA14-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA14-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eZs2HuhKBmA14-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA14-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA14-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA14-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eZs2HuhKBmA14-filter-blur-0" stdDeviation="3,3" result="result" />
        </filter>
        <filter id="eZs2HuhKBmA15-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA15-filter-blur-0" stdDeviation="0,0" result="result" />
        </filter>
        <linearGradient
          id="eZs2HuhKBmA17-stroke"
          x1="0.311309"
          y1="0.695205"
          x2="0.32485"
          y2="0.997707"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZs2HuhKBmA17-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZs2HuhKBmA17-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <filter id="eZs2HuhKBmA18-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA18-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eZs2HuhKBmA18-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA18-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eZs2HuhKBmA18-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA18-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA18-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA18-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZs2HuhKBmA19-stroke"
          x1="104.847578"
          y1="1"
          x2="104.847578"
          y2="0.084418"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZs2HuhKBmA19-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="eZs2HuhKBmA19-stroke-1"
            offset="100%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eZs2HuhKBmA20-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA20-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eZs2HuhKBmA20-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA20-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eZs2HuhKBmA20-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA20-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA20-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA20-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eZs2HuhKBmA20-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eZs2HuhKBmA22-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA22-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eZs2HuhKBmA22-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA22-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eZs2HuhKBmA22-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA22-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA22-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA22-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZs2HuhKBmA23-stroke"
          x1="2.146913"
          y1="1"
          x2="2.146913"
          y2="0.088056"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZs2HuhKBmA23-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="eZs2HuhKBmA23-stroke-1"
            offset="100%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eZs2HuhKBmA24-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA24-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eZs2HuhKBmA24-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA24-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eZs2HuhKBmA24-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA24-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA24-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA24-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eZs2HuhKBmA24-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eZs2HuhKBmA26-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA26-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eZs2HuhKBmA26-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA26-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eZs2HuhKBmA26-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA26-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA26-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA26-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZs2HuhKBmA27-stroke"
          x1="2.146913"
          y1="1"
          x2="2.146913"
          y2="0.088056"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZs2HuhKBmA27-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="eZs2HuhKBmA27-stroke-1"
            offset="100%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eZs2HuhKBmA28-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA28-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eZs2HuhKBmA28-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA28-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eZs2HuhKBmA28-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA28-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA28-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA28-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eZs2HuhKBmA28-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <linearGradient
          id="eZs2HuhKBmA31-stroke"
          x1="0.5"
          y1="0.695258"
          x2="0.5"
          y2="0.997712"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZs2HuhKBmA31-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZs2HuhKBmA31-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <filter id="eZs2HuhKBmA32-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA32-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eZs2HuhKBmA32-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA32-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eZs2HuhKBmA32-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA32-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA32-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA32-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZs2HuhKBmA33-stroke"
          x1="5.730926"
          y1="0.016643"
          x2="5.730926"
          y2="0.713515"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZs2HuhKBmA33-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZs2HuhKBmA33-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="eZs2HuhKBmA34-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA34-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eZs2HuhKBmA34-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA34-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eZs2HuhKBmA34-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA34-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA34-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA34-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eZs2HuhKBmA34-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eZs2HuhKBmA36-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA36-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eZs2HuhKBmA36-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA36-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eZs2HuhKBmA36-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA36-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA36-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA36-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZs2HuhKBmA37-stroke"
          x1="5.730926"
          y1="0.016643"
          x2="5.730926"
          y2="0.713515"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZs2HuhKBmA37-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZs2HuhKBmA37-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="eZs2HuhKBmA38-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA38-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eZs2HuhKBmA38-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA38-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eZs2HuhKBmA38-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA38-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA38-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA38-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eZs2HuhKBmA38-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <linearGradient
          id="eZs2HuhKBmA41-stroke"
          x1="0.5"
          y1="0.695267"
          x2="0.5"
          y2="0.997712"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZs2HuhKBmA41-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZs2HuhKBmA41-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <filter id="eZs2HuhKBmA42-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA42-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eZs2HuhKBmA42-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA42-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eZs2HuhKBmA42-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA42-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA42-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA42-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZs2HuhKBmA43-stroke"
          x1="35.433962"
          y1="0"
          x2="35.433962"
          y2="1.148697"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZs2HuhKBmA43-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZs2HuhKBmA43-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eZs2HuhKBmA44-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA44-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eZs2HuhKBmA44-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA44-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eZs2HuhKBmA44-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA44-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA44-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA44-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eZs2HuhKBmA44-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eZs2HuhKBmA46-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA46-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eZs2HuhKBmA46-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA46-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eZs2HuhKBmA46-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA46-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA46-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA46-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZs2HuhKBmA47-stroke"
          x1="35.433962"
          y1="0"
          x2="35.433962"
          y2="1.148697"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZs2HuhKBmA47-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZs2HuhKBmA47-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eZs2HuhKBmA48-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA48-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eZs2HuhKBmA48-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA48-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eZs2HuhKBmA48-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA48-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA48-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA48-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eZs2HuhKBmA48-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <linearGradient
          id="eZs2HuhKBmA51-stroke"
          x1="0.5"
          y1="0.695267"
          x2="0.5"
          y2="0.997712"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZs2HuhKBmA51-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZs2HuhKBmA51-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <filter id="eZs2HuhKBmA52-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA52-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eZs2HuhKBmA52-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA52-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eZs2HuhKBmA52-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA52-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA52-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA52-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZs2HuhKBmA53-stroke"
          x1="1.229089"
          y1="0.132667"
          x2="1.181174"
          y2="0.748926"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZs2HuhKBmA53-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZs2HuhKBmA53-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="eZs2HuhKBmA54-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA54-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eZs2HuhKBmA54-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA54-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eZs2HuhKBmA54-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA54-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA54-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA54-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eZs2HuhKBmA54-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eZs2HuhKBmA56-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA56-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eZs2HuhKBmA56-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA56-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eZs2HuhKBmA56-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA56-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA56-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA56-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZs2HuhKBmA57-stroke"
          x1="1.229089"
          y1="0.132667"
          x2="1.181174"
          y2="0.748926"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZs2HuhKBmA57-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZs2HuhKBmA57-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="eZs2HuhKBmA58-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA58-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eZs2HuhKBmA58-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA58-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eZs2HuhKBmA58-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA58-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA58-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA58-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eZs2HuhKBmA58-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <linearGradient
          id="eZs2HuhKBmA61-stroke"
          x1="0.5"
          y1="0.690629"
          x2="0.5"
          y2="0.997707"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZs2HuhKBmA61-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZs2HuhKBmA61-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <filter id="eZs2HuhKBmA62-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA62-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eZs2HuhKBmA62-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA62-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eZs2HuhKBmA62-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA62-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA62-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA62-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZs2HuhKBmA63-stroke"
          x1="5940.262673"
          y1="1.161515"
          x2="5940.262673"
          y2="0.096616"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZs2HuhKBmA63-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="eZs2HuhKBmA63-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eZs2HuhKBmA64-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA64-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eZs2HuhKBmA64-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA64-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eZs2HuhKBmA64-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA64-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA64-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA64-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eZs2HuhKBmA64-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eZs2HuhKBmA66-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA66-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eZs2HuhKBmA66-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA66-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eZs2HuhKBmA66-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA66-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA66-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA66-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZs2HuhKBmA67-stroke"
          x1="5940.262673"
          y1="1.161515"
          x2="5940.262673"
          y2="0.096616"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZs2HuhKBmA67-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="eZs2HuhKBmA67-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eZs2HuhKBmA68-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA68-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eZs2HuhKBmA68-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA68-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eZs2HuhKBmA68-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA68-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA68-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA68-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eZs2HuhKBmA68-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <linearGradient
          id="eZs2HuhKBmA71-stroke"
          x1="-0.002599"
          y1="1"
          x2="0.218911"
          y2="0.828707"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZs2HuhKBmA71-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="eZs2HuhKBmA71-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eZs2HuhKBmA72-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA72-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eZs2HuhKBmA72-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA72-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eZs2HuhKBmA72-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA72-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA72-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA72-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZs2HuhKBmA73-stroke"
          x1="1.25753"
          y1="0.063378"
          x2="0.000365"
          y2="0.866006"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZs2HuhKBmA73-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZs2HuhKBmA73-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="eZs2HuhKBmA74-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA74-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eZs2HuhKBmA74-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA74-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eZs2HuhKBmA74-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA74-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA74-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA74-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eZs2HuhKBmA74-filter-blur-0" stdDeviation="3,3" result="result" />
        </filter>
        <filter id="eZs2HuhKBmA75-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA75-filter-blur-0" stdDeviation="0,0" result="result" />
        </filter>
        <filter id="eZs2HuhKBmA76-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA76-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eZs2HuhKBmA76-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA76-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eZs2HuhKBmA76-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA76-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA76-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA76-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZs2HuhKBmA77-stroke"
          x1="1.25753"
          y1="0.063378"
          x2="0.000365"
          y2="0.866006"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZs2HuhKBmA77-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZs2HuhKBmA77-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="eZs2HuhKBmA78-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA78-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eZs2HuhKBmA78-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZs2HuhKBmA78-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eZs2HuhKBmA78-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZs2HuhKBmA78-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZs2HuhKBmA78-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZs2HuhKBmA78-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eZs2HuhKBmA78-filter-blur-0" stdDeviation="3,3" result="result" />
        </filter>
        <filter id="eZs2HuhKBmA79-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZs2HuhKBmA79-filter-blur-0" stdDeviation="0,0" result="result" />
        </filter>
      </defs>
      <g transform="translate(-22-2.25894)" mask="url(#eZs2HuhKBmA80)">
        <g transform="translate(.000001 0)">
          <g transform="translate(.088013 1.012683)">
            <g transform="matrix(-1 0 0 1 357.455002 0)">
              <path
                d="M32.0957,218.88v-97.202L86.156086,77.532603v-77.224072"
                fill="none"
                stroke="url(#eZs2HuhKBmA7-stroke)"
              />
              <g transform="translate(-65 105.000001)" filter="url(#eZs2HuhKBmA8-filter)">
                <path
                  id="eZs2HuhKBmA9"
                  d="M32.0957,289.88v-43.358702v0l1-.996864"
                  transform="translate(64.994651-105.187118)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZs2HuhKBmA9-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZs2HuhKBmA10_to" transform="translate(96.798427,143.537316)">
                  <g id="eZs2HuhKBmA10_tr" transform="rotate(0)">
                    <rect
                      id="eZs2HuhKBmA10"
                      width="10"
                      height="17.142857"
                      rx="0"
                      ry="0"
                      transform="scale(0.7,0.7) translate(-5,-8.571429)"
                      opacity="0"
                      filter="url(#eZs2HuhKBmA10-filter)"
                      fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eZs2HuhKBmA11_to" transform="translate(96.798427,142.237316)">
                  <g id="eZs2HuhKBmA11_tr" transform="rotate(0)">
                    <rect
                      id="eZs2HuhKBmA11"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      filter="url(#eZs2HuhKBmA11-filter)"
                      fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      strokeMiterlimit="8"
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eZs2HuhKBmA12-filter)">
                <path
                  id="eZs2HuhKBmA13"
                  d="M32.0957,289.88v-43.358702v0l1-.996864"
                  transform="translate(64.994651-105.187118)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZs2HuhKBmA13-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZs2HuhKBmA14_to" transform="translate(96.798427,143.537316)">
                  <g id="eZs2HuhKBmA14_tr" transform="rotate(0)">
                    <rect
                      id="eZs2HuhKBmA14"
                      width="10"
                      height="17.142857"
                      rx="0"
                      ry="0"
                      transform="scale(0.7,0.7) translate(-5,-8.571429)"
                      opacity="0"
                      filter="url(#eZs2HuhKBmA14-filter)"
                      fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eZs2HuhKBmA15_to" transform="translate(96.798427,142.237316)">
                  <g id="eZs2HuhKBmA15_tr" transform="rotate(0)">
                    <rect
                      id="eZs2HuhKBmA15"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      filter="url(#eZs2HuhKBmA15-filter)"
                      fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      strokeMiterlimit="8"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="matrix(-1 0 0 1 374.225189 0)">
              <path
                d="M96.7109,218.88v-97.202l35.869585-44.145397L132.817,0.34375"
                fill="none"
                stroke="url(#eZs2HuhKBmA17-stroke)"
              />
              <g transform="translate(-64 105.000001)" filter="url(#eZs2HuhKBmA18-filter)">
                <path
                  id="eZs2HuhKBmA19"
                  d="M96.711985,175.439636L96.7109,127.987317h.001085c0,0,0,0,0,0"
                  transform="translate(64-105.000001)"
                  opacity="0.2"
                  fill="none"
                  stroke="url(#eZs2HuhKBmA19-stroke)"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="eZs2HuhKBmA20_to" transform="translate(160.8,24.504306)">
                  <g id="eZs2HuhKBmA20_tr" transform="rotate(0)">
                    <rect
                      id="eZs2HuhKBmA20"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0.7"
                      filter="url(#eZs2HuhKBmA20-filter)"
                      fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eZs2HuhKBmA21_to" transform="translate(160.8,24.504307)">
                  <g id="eZs2HuhKBmA21_tr" transform="rotate(0)">
                    <rect
                      id="eZs2HuhKBmA21"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-64 105.000001)" filter="url(#eZs2HuhKBmA22-filter)">
                <path
                  id="eZs2HuhKBmA23"
                  d="M96.711985,275.439636L96.7109,227.987317h.001085c0,0,0,0,0,0"
                  transform="translate(64-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZs2HuhKBmA23-stroke)"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="eZs2HuhKBmA24_to" transform="translate(160.8,126.504306)">
                  <g id="eZs2HuhKBmA24_tr" transform="rotate(0)">
                    <rect
                      id="eZs2HuhKBmA24"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eZs2HuhKBmA24-filter)"
                      fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eZs2HuhKBmA25_to" transform="translate(160.8,126.504307)">
                  <g id="eZs2HuhKBmA25_tr" transform="rotate(0)">
                    <rect
                      id="eZs2HuhKBmA25"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-64 105.000001)" filter="url(#eZs2HuhKBmA26-filter)">
                <path
                  id="eZs2HuhKBmA27"
                  d="M96.711985,275.439636L96.7109,227.987317h.001085c0,0,0,0,0,0"
                  transform="translate(64-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZs2HuhKBmA27-stroke)"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="eZs2HuhKBmA28_to" transform="translate(160.8,126.504306)">
                  <rect
                    id="eZs2HuhKBmA28"
                    width="7"
                    height="12"
                    rx="0"
                    ry="0"
                    transform="translate(-3.5,-6)"
                    opacity="0"
                    filter="url(#eZs2HuhKBmA28-filter)"
                    fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eZs2HuhKBmA29_to" transform="translate(160.8,126.504307)">
                  <rect
                    id="eZs2HuhKBmA29"
                    width="10"
                    height="20"
                    rx="0"
                    ry="0"
                    transform="scale(0.5,0.5) translate(-5,-10)"
                    opacity="0"
                    fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
              </g>
            </g>
            <g transform="matrix(-1 0 0 1 392.03 0)">
              <path
                d="M161.867,218.879v-97.202L180.68,77.67163l.001986-77.363099"
                fill="none"
                stroke="url(#eZs2HuhKBmA31-stroke)"
              />
              <g transform="translate(-65 105.000001)" filter="url(#eZs2HuhKBmA32-filter)">
                <path
                  id="eZs2HuhKBmA33"
                  d="M161.867,269.879v-29.393436c0,0-.017-.000001-.017-.000001l-.051573-1.089269"
                  transform="translate(64.996501-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZs2HuhKBmA33-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZs2HuhKBmA34_to" transform="translate(226.779751,128.661481)">
                  <g id="eZs2HuhKBmA34_tr" transform="rotate(0)">
                    <rect
                      id="eZs2HuhKBmA34"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#eZs2HuhKBmA34-filter)"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eZs2HuhKBmA35_to" transform="translate(226.779751,128.661481)">
                  <g id="eZs2HuhKBmA35_tr" transform="rotate(0)">
                    <rect
                      id="eZs2HuhKBmA35"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eZs2HuhKBmA36-filter)">
                <path
                  id="eZs2HuhKBmA37"
                  d="M161.867,269.879v-29.393436c0,0-.017-.000001-.017-.000001l-.051573-1.089269"
                  transform="translate(64.996501-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZs2HuhKBmA37-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZs2HuhKBmA38_to" transform="translate(226.779751,128.661481)">
                  <g id="eZs2HuhKBmA38_tr" transform="rotate(0)">
                    <rect
                      id="eZs2HuhKBmA38"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#eZs2HuhKBmA38-filter)"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eZs2HuhKBmA39_to" transform="translate(226.779751,128.661481)">
                  <g id="eZs2HuhKBmA39_tr" transform="rotate(0)">
                    <rect
                      id="eZs2HuhKBmA39"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(-97.999999 0)">
              <path
                d="M161.867,218.879l.004985-97.202v-44.144397-77.224072"
                transform="translate(117.344985 0)"
                fill="none"
                stroke="url(#eZs2HuhKBmA41-stroke)"
              />
              <g transform="translate(-65 105.000001)" filter="url(#eZs2HuhKBmA42-filter)">
                <path
                  id="eZs2HuhKBmA43"
                  d="M278.994002,286.5213L278.994,225.053002l.106.000002-1.070999-1.000002"
                  transform="translate(65-93.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZs2HuhKBmA43-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZs2HuhKBmA44_to" transform="translate(344.25,128.271297)">
                  <rect
                    id="eZs2HuhKBmA44"
                    width="8.75"
                    height="15"
                    rx="0"
                    ry="0"
                    transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                    opacity="0"
                    filter="url(#eZs2HuhKBmA44-filter)"
                    fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eZs2HuhKBmA45_to" transform="translate(344.25,128.271297)">
                  <rect
                    id="eZs2HuhKBmA45"
                    width="10"
                    height="20"
                    rx="0"
                    ry="0"
                    transform="scale(0.5,0.5) translate(-5,-10)"
                    opacity="0"
                    fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eZs2HuhKBmA46-filter)">
                <path
                  id="eZs2HuhKBmA47"
                  d="M278.994002,286.5213L278.994,225.053002l.106.000002-1.070999-1.000002"
                  transform="translate(65-93.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZs2HuhKBmA47-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZs2HuhKBmA48_to" transform="translate(344.25,128.271297)">
                  <rect
                    id="eZs2HuhKBmA48"
                    width="8.75"
                    height="15"
                    rx="0"
                    ry="0"
                    transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                    opacity="0"
                    filter="url(#eZs2HuhKBmA48-filter)"
                    fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eZs2HuhKBmA49_to" transform="translate(344.25,128.271297)">
                  <rect
                    id="eZs2HuhKBmA49"
                    width="10"
                    height="20"
                    rx="0"
                    ry="0"
                    transform="scale(0.5,0.5) translate(-5,-10)"
                    opacity="0"
                    fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
              </g>
            </g>
            <g transform="translate(-28 0)">
              <path
                d="M161.867,218.879v-97.202L180.68,77.67163l.001986-77.363099"
                fill="none"
                stroke="url(#eZs2HuhKBmA51-stroke)"
              />
              <g transform="translate(-65 105.000001)" filter="url(#eZs2HuhKBmA52-filter)">
                <path
                  id="eZs2HuhKBmA53"
                  d="M161.867,259.879v-6.389002c0,0-.017-2.218699-.017-2.218699l-.051573-1.875005"
                  transform="translate(64.996501-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZs2HuhKBmA53-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZs2HuhKBmA54_to" transform="translate(226.779751,128.661481)">
                  <g id="eZs2HuhKBmA54_tr" transform="rotate(0)">
                    <rect
                      id="eZs2HuhKBmA54"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#eZs2HuhKBmA54-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eZs2HuhKBmA55_to" transform="translate(226.779751,128.661481)">
                  <g id="eZs2HuhKBmA55_tr" transform="rotate(0)">
                    <rect
                      id="eZs2HuhKBmA55"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eZs2HuhKBmA56-filter)">
                <path
                  id="eZs2HuhKBmA57"
                  d="M161.867,259.879v-6.389002c0,0-.017-2.218699-.017-2.218699l-.051573-1.875005"
                  transform="translate(64.996501-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZs2HuhKBmA57-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZs2HuhKBmA58_to" transform="translate(226.779751,128.661481)">
                  <g id="eZs2HuhKBmA58_tr" transform="rotate(0)">
                    <rect
                      id="eZs2HuhKBmA58"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#eZs2HuhKBmA58-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eZs2HuhKBmA59_to" transform="translate(226.779751,128.661481)">
                  <g id="eZs2HuhKBmA59_tr" transform="rotate(0)">
                    <rect
                      id="eZs2HuhKBmA59"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(-10.999999 0)">
              <path
                d="M96.7109,218.88v-97.202l35.869585-44.145397L132.817,0.34375"
                fill="none"
                stroke="url(#eZs2HuhKBmA61-stroke)"
              />
              <g transform="translate(-64 105.000001)" filter="url(#eZs2HuhKBmA62-filter)">
                <path
                  id="eZs2HuhKBmA63"
                  d="M96.711985,302.439636L96.7109,234.987317h.001085c0,0,0,0,0,0"
                  transform="translate(64-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZs2HuhKBmA63-stroke)"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="eZs2HuhKBmA64_to" transform="translate(160.8,124.504306)">
                  <g id="eZs2HuhKBmA64_tr" transform="rotate(0)">
                    <rect
                      id="eZs2HuhKBmA64"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eZs2HuhKBmA64-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eZs2HuhKBmA65_to" transform="translate(160.8,124.504307)">
                  <g id="eZs2HuhKBmA65_tr" transform="rotate(0)">
                    <rect
                      id="eZs2HuhKBmA65"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-64 105.000001)" filter="url(#eZs2HuhKBmA66-filter)">
                <path
                  id="eZs2HuhKBmA67"
                  d="M96.711985,302.439636L96.7109,234.987317h.001085c0,0,0,0,0,0"
                  transform="translate(64-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZs2HuhKBmA67-stroke)"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="eZs2HuhKBmA68_to" transform="translate(160.8,124.504306)">
                  <g id="eZs2HuhKBmA68_tr" transform="rotate(0)">
                    <rect
                      id="eZs2HuhKBmA68"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eZs2HuhKBmA68-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eZs2HuhKBmA69_to" transform="translate(160.8,124.504307)">
                  <g id="eZs2HuhKBmA69_tr" transform="rotate(0)">
                    <rect
                      id="eZs2HuhKBmA69"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(6 0)">
              <path
                d="M32.0957,218.88v-97.202L86.156086,77.532603v-77.224072"
                fill="none"
                stroke="url(#eZs2HuhKBmA71-stroke)"
              />
              <g transform="translate(-65 105.000001)" filter="url(#eZs2HuhKBmA72-filter)">
                <path
                  id="eZs2HuhKBmA73"
                  d="M32.0957,321.564875v-89.736339l.005349.041895v.005687"
                  transform="translate(64.994651-105.187118)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZs2HuhKBmA73-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZs2HuhKBmA74_to" transform="translate(96.798427,143.537316)">
                  <g id="eZs2HuhKBmA74_tr" transform="rotate(0)">
                    <rect
                      id="eZs2HuhKBmA74"
                      width="10"
                      height="17.142857"
                      rx="0"
                      ry="0"
                      transform="scale(0.7,0.7) translate(-5,-8.571429)"
                      opacity="0"
                      filter="url(#eZs2HuhKBmA74-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eZs2HuhKBmA75_to" transform="translate(96.798427,142.237316)">
                  <g id="eZs2HuhKBmA75_tr" transform="rotate(0)">
                    <rect
                      id="eZs2HuhKBmA75"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      filter="url(#eZs2HuhKBmA75-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      strokeMiterlimit="8"
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eZs2HuhKBmA76-filter)">
                <path
                  id="eZs2HuhKBmA77"
                  d="M32.0957,321.564875v-89.736339l.005349.041895v.005687"
                  transform="translate(64.994651-105.187118)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZs2HuhKBmA77-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZs2HuhKBmA78_to" transform="translate(96.798427,143.537316)">
                  <g id="eZs2HuhKBmA78_tr" transform="rotate(0)">
                    <rect
                      id="eZs2HuhKBmA78"
                      width="10"
                      height="17.142857"
                      rx="0"
                      ry="0"
                      transform="scale(0.7,0.7) translate(-5,-8.571429)"
                      opacity="0"
                      filter="url(#eZs2HuhKBmA78-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eZs2HuhKBmA79_to" transform="translate(96.798427,142.237316)">
                  <g id="eZs2HuhKBmA79_tr" transform="rotate(0)">
                    <rect
                      id="eZs2HuhKBmA79"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      filter="url(#eZs2HuhKBmA79-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      strokeMiterlimit="8"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <mask id="eZs2HuhKBmA80" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="825.762291"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 1.338125)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #eZs2HuhKBmA9 {
    animation-name: eZs2HuhKBmA9__m, eZs2HuhKBmA9_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZs2HuhKBmA9__m {
    0% {
      d: path('M32.0957,289.88L32.0957,246.521298L32.0957,246.521298L33.0957,245.524434');
    }
    5% {
      d: path('M32.0957,289.88L32.0957,246.521298L32.0957,246.521298L33.0957,245.524434');
    }
    21.25% {
      d: path('M32.0957,219.88L32.0957,121.861988L32.0957,121.861988L33.0957,120.869117');
    }
    35% {
      d: path('M32.0957,121.869045L32.0957,121.678L85.801035,77.955237L86.801035,76.954434');
    }
    36.25% {
      d: path('M36.601178,118.18636L86.161435,77.71972L86.161435,73.631826L86.655667,72.776826');
    }
    50% {
      d: path('M86.161435,77.676826L86.161435,77.676826L86.1598,77.676826L86.1598,-5.543385');
    }
    100% {
      d: path('M86.161435,77.676826L86.161435,77.676826L86.1598,77.676826L86.1598,-5.543385');
    }
  }
  @keyframes eZs2HuhKBmA9_c_o {
    0% {
      opacity: 0;
    }
    13.75% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    47.5% {
      opacity: 1;
    }
    53.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA10_to {
    animation: eZs2HuhKBmA10_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA10_to__to {
    0% {
      transform: translate(96.798427px, 143.537316px);
    }
    5% {
      transform: translate(96.798427px, 143.537316px);
    }
    20% {
      transform: translate(97.111986px, 20.771297px);
    }
    21.25% {
      transform: translate(99.798427px, 14.771297px);
    }
    35% {
      transform: translate(148.801035px, -25.679029px);
    }
    36.25% {
      transform: translate(150.920607px, -33.028703px);
    }
    50% {
      transform: translate(150.920607px, -113.028703px);
    }
    100% {
      transform: translate(150.920607px, -113.028703px);
    }
  }
  #eZs2HuhKBmA10_tr {
    animation: eZs2HuhKBmA10_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA10_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(50deg);
    }
    35% {
      transform: rotate(50deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZs2HuhKBmA10 {
    animation: eZs2HuhKBmA10_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA10_c_o {
    0% {
      opacity: 0;
    }
    7.5% {
      opacity: 0;
    }
    17.5% {
      opacity: 0.7;
    }
    48.75% {
      opacity: 0.7;
    }
    51.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA11_to {
    animation: eZs2HuhKBmA11_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA11_to__to {
    0% {
      transform: translate(96.798427px, 142.237316px);
    }
    5% {
      transform: translate(96.798427px, 142.237316px);
    }
    20% {
      transform: translate(97.111986px, 20.643116px);
    }
    21.25% {
      transform: translate(99.798427px, 14.771297px);
    }
    35% {
      transform: translate(148.833827px, -25.703774px);
    }
    36.25% {
      transform: translate(150.920607px, -33.028703px);
    }
    50% {
      transform: translate(150.920607px, -113.028703px);
    }
    100% {
      transform: translate(150.920607px, -113.028703px);
    }
  }
  #eZs2HuhKBmA11_tr {
    animation: eZs2HuhKBmA11_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA11_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(50deg);
    }
    35% {
      transform: rotate(50deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZs2HuhKBmA11 {
    animation: eZs2HuhKBmA11_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA11_c_o {
    0% {
      opacity: 0;
    }
    7.5% {
      opacity: 0;
    }
    17.5% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    51.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA13 {
    animation-name: eZs2HuhKBmA13__m, eZs2HuhKBmA13_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZs2HuhKBmA13__m {
    0% {
      d: path('M32.0957,289.88L32.0957,246.521298L32.0957,246.521298L33.0957,245.524434');
    }
    48.75% {
      d: path('M32.0957,289.88L32.0957,246.521298L32.0957,246.521298L33.0957,245.524434');
    }
    65% {
      d: path('M32.0957,219.88L32.0957,121.861988L32.0957,121.861988L33.0957,120.869117');
    }
    78.75% {
      d: path('M32.0957,121.869045L32.0957,121.678L85.801035,77.955237L86.801035,76.954434');
    }
    80% {
      d: path('M36.601178,118.18636L86.161435,77.71972L86.161435,73.631826L86.655667,72.776826');
    }
    93.75% {
      d: path('M86.161435,77.676826L86.161435,77.676826L86.1598,77.676826L86.1598,-5.543385');
    }
    100% {
      d: path('M86.161435,77.676826L86.161435,77.676826L86.1598,77.676826L86.1598,-5.543385');
    }
  }
  @keyframes eZs2HuhKBmA13_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    66.25% {
      opacity: 1;
    }
    91.25% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA14_to {
    animation: eZs2HuhKBmA14_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA14_to__to {
    0% {
      transform: translate(96.798427px, 143.537316px);
    }
    48.75% {
      transform: translate(96.798427px, 143.537316px);
    }
    63.75% {
      transform: translate(97.111986px, 20.771297px);
    }
    65% {
      transform: translate(99.798427px, 14.771297px);
    }
    78.75% {
      transform: translate(148.801035px, -25.679029px);
    }
    80% {
      transform: translate(150.920607px, -33.028703px);
    }
    93.75% {
      transform: translate(150.920607px, -113.028703px);
    }
    100% {
      transform: translate(150.920607px, -113.028703px);
    }
  }
  #eZs2HuhKBmA14_tr {
    animation: eZs2HuhKBmA14_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA14_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    63.75% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(50deg);
    }
    78.75% {
      transform: rotate(50deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZs2HuhKBmA14 {
    animation: eZs2HuhKBmA14_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA14_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    61.25% {
      opacity: 0.7;
    }
    92.5% {
      opacity: 0.7;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA15_to {
    animation: eZs2HuhKBmA15_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA15_to__to {
    0% {
      transform: translate(96.798427px, 142.237316px);
    }
    48.75% {
      transform: translate(96.798427px, 142.237316px);
    }
    63.75% {
      transform: translate(97.111986px, 20.643116px);
    }
    65% {
      transform: translate(99.798427px, 14.771297px);
    }
    78.75% {
      transform: translate(148.833827px, -25.703774px);
    }
    80% {
      transform: translate(150.920607px, -33.028703px);
    }
    93.75% {
      transform: translate(150.920607px, -113.028703px);
    }
    100% {
      transform: translate(150.920607px, -113.028703px);
    }
  }
  #eZs2HuhKBmA15_tr {
    animation: eZs2HuhKBmA15_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    63.75% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(50deg);
    }
    78.75% {
      transform: rotate(50deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZs2HuhKBmA15 {
    animation: eZs2HuhKBmA15_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA15_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    61.25% {
      opacity: 1;
    }
    93.75% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA19 {
    animation-name: eZs2HuhKBmA19__m, eZs2HuhKBmA19_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZs2HuhKBmA19__m {
    0% {
      d: path(
        'M96.711985,175.439636L96.7109,127.987317L96.711985,127.987317C96.711985,127.987317,96.711985,127.987317,96.711985,127.987317'
      );
    }
    1.25% {
      d: path(
        'M96.7109,175.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.711985,121.683001,96.711985,121.683001'
      );
    }
    16.25% {
      d: path(
        'M96.7109,121.678L96.7109,121.678L132.580485,77.671631C132.580485,77.671631,132.580485,77.67163,132.580485,77.67163'
      );
    }
    30% {
      d: path(
        'M132.580485,77.671631L132.580485,77.671631L132.580485,77.671631C132.580485,77.671631,132.580485,-3.79188,132.580485,-3.79188'
      );
    }
    100% {
      d: path(
        'M132.580485,77.671631L132.580485,77.671631L132.580485,77.671631C132.580485,77.671631,132.580485,-3.79188,132.580485,-3.79188'
      );
    }
  }
  @keyframes eZs2HuhKBmA19_c_o {
    0% {
      opacity: 0.2;
    }
    3.75% {
      opacity: 1;
    }
    28.75% {
      opacity: 1;
    }
    33.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA20_to {
    animation: eZs2HuhKBmA20_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA20_to__to {
    0% {
      transform: translate(160.8px, 24.504306px);
    }
    1.25% {
      transform: translate(164.798427px, 11.502826px);
    }
    16.25% {
      transform: translate(193.801035px, -23.911387px);
    }
    17.5% {
      transform: translate(196.811985px, -34.712684px);
    }
    30% {
      transform: translate(196.811985px, -114.712684px);
    }
    100% {
      transform: translate(196.811985px, -114.712684px);
    }
  }
  #eZs2HuhKBmA20_tr {
    animation: eZs2HuhKBmA20_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA20_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(39deg);
    }
    16.25% {
      transform: rotate(39deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZs2HuhKBmA20 {
    animation: eZs2HuhKBmA20_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA20_c_o {
    0% {
      opacity: 0.7;
    }
    30% {
      opacity: 0.7;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA21_to {
    animation: eZs2HuhKBmA21_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA21_to__to {
    0% {
      transform: translate(160.8px, 24.504307px);
    }
    1.25% {
      transform: translate(164.798427px, 11.502826px);
    }
    16.25% {
      transform: translate(193.801035px, -23.891881px);
    }
    17.5% {
      transform: translate(196.851035px, -34.791881px);
    }
    30% {
      transform: translate(196.851035px, -114.791881px);
    }
    100% {
      transform: translate(196.851035px, -114.791881px);
    }
  }
  #eZs2HuhKBmA21_tr {
    animation: eZs2HuhKBmA21_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA21_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(39deg);
    }
    16.25% {
      transform: rotate(39deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZs2HuhKBmA21 {
    animation: eZs2HuhKBmA21_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA21_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA23 {
    animation-name: eZs2HuhKBmA23__m, eZs2HuhKBmA23_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZs2HuhKBmA23__m {
    0% {
      d: path(
        'M96.711985,275.439636L96.7109,227.987317L96.711985,227.987317C96.711985,227.987317,96.711985,227.987317,96.711985,227.987317'
      );
    }
    35% {
      d: path(
        'M96.711985,275.439636L96.7109,227.987317L96.711985,227.987317C96.711985,227.987317,96.711985,227.987317,96.711985,227.987317'
      );
    }
    46.25% {
      d: path(
        'M96.711985,175.439636L96.7109,127.987317L96.711985,127.987317C96.711985,127.987317,96.711985,127.987317,96.711985,127.987317'
      );
    }
    47.5% {
      d: path(
        'M96.7109,175.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.711985,121.683001,96.711985,121.683001'
      );
    }
    62.5% {
      d: path(
        'M96.7109,121.678L96.7109,121.678L132.580485,77.671631C132.580485,77.671631,132.580485,77.67163,132.580485,77.67163'
      );
    }
    76.25% {
      d: path(
        'M132.580485,77.671631L132.580485,77.671631L132.580485,77.671631C132.580485,77.671631,132.580485,-3.79188,132.580485,-3.79188'
      );
    }
    100% {
      d: path(
        'M132.580485,77.671631L132.580485,77.671631L132.580485,77.671631C132.580485,77.671631,132.580485,-3.79188,132.580485,-3.79188'
      );
    }
  }
  @keyframes eZs2HuhKBmA23_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    46.25% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    72.5% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA24_to {
    animation: eZs2HuhKBmA24_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA24_to__to {
    0% {
      transform: translate(160.8px, 126.504306px);
    }
    35% {
      transform: translate(160.8px, 126.504306px);
    }
    46.25% {
      transform: translate(160.8px, 24.504306px);
    }
    47.5% {
      transform: translate(164.798427px, 11.502826px);
    }
    62.5% {
      transform: translate(193.801035px, -23.911387px);
    }
    63.75% {
      transform: translate(196.811985px, -34.712684px);
    }
    76.25% {
      transform: translate(196.811985px, -114.712684px);
    }
    100% {
      transform: translate(196.811985px, -114.712684px);
    }
  }
  #eZs2HuhKBmA24_tr {
    animation: eZs2HuhKBmA24_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA24_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    46.25% {
      transform: rotate(0deg);
    }
    47.5% {
      transform: rotate(39deg);
    }
    62.5% {
      transform: rotate(39deg);
    }
    63.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZs2HuhKBmA24 {
    animation: eZs2HuhKBmA24_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA24_c_o {
    0% {
      opacity: 0;
    }
    37.5% {
      opacity: 0;
    }
    46.25% {
      opacity: 0.7;
    }
    76.25% {
      opacity: 0.7;
    }
    77.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA25_to {
    animation: eZs2HuhKBmA25_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA25_to__to {
    0% {
      transform: translate(160.8px, 126.504307px);
    }
    35% {
      transform: translate(160.8px, 126.504307px);
    }
    46.25% {
      transform: translate(160.8px, 24.504307px);
    }
    47.5% {
      transform: translate(164.798427px, 11.502826px);
    }
    62.5% {
      transform: translate(193.801035px, -23.891881px);
    }
    63.75% {
      transform: translate(196.851035px, -34.791881px);
    }
    76.25% {
      transform: translate(196.851035px, -114.791881px);
    }
    100% {
      transform: translate(196.851035px, -114.791881px);
    }
  }
  #eZs2HuhKBmA25_tr {
    animation: eZs2HuhKBmA25_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA25_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    46.25% {
      transform: rotate(0deg);
    }
    47.5% {
      transform: rotate(39deg);
    }
    62.5% {
      transform: rotate(39deg);
    }
    63.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZs2HuhKBmA25 {
    animation: eZs2HuhKBmA25_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA25_c_o {
    0% {
      opacity: 0;
    }
    37.5% {
      opacity: 0;
    }
    46.25% {
      opacity: 1;
    }
    76.25% {
      opacity: 1;
    }
    77.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA27 {
    animation-name: eZs2HuhKBmA27__m, eZs2HuhKBmA27_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZs2HuhKBmA27__m {
    0% {
      d: path(
        'M96.711985,275.439636L96.7109,227.987317L96.711985,227.987317C96.711985,227.987317,96.711985,227.987317,96.711985,227.987317'
      );
    }
    88.75% {
      d: path(
        'M96.711985,275.439636L96.7109,227.987317L96.711985,227.987317C96.711985,227.987317,96.711985,227.987317,96.711985,227.987317'
      );
    }
    100% {
      d: path(
        'M96.711985,175.439636L96.7109,127.987317L96.711985,127.987317C96.711985,127.987317,96.711985,127.987317,96.711985,127.987317'
      );
    }
  }
  @keyframes eZs2HuhKBmA27_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0.2;
    }
  }
  #eZs2HuhKBmA28_to {
    animation: eZs2HuhKBmA28_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA28_to__to {
    0% {
      transform: translate(160.8px, 126.504306px);
    }
    88.75% {
      transform: translate(160.8px, 126.504306px);
    }
    100% {
      transform: translate(160.8px, 24.504306px);
    }
  }
  #eZs2HuhKBmA28 {
    animation: eZs2HuhKBmA28_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA28_c_o {
    0% {
      opacity: 0;
    }
    91.25% {
      opacity: 0;
    }
    97.5% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #eZs2HuhKBmA29_to {
    animation: eZs2HuhKBmA29_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA29_to__to {
    0% {
      transform: translate(160.8px, 126.504307px);
    }
    88.75% {
      transform: translate(160.8px, 126.504307px);
    }
    100% {
      transform: translate(160.8px, 24.504307px);
    }
  }
  #eZs2HuhKBmA29 {
    animation: eZs2HuhKBmA29_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA29_c_o {
    0% {
      opacity: 0;
    }
    91.25% {
      opacity: 0;
    }
    97.5% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eZs2HuhKBmA33 {
    animation-name: eZs2HuhKBmA33__m, eZs2HuhKBmA33_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZs2HuhKBmA33__m {
    0% {
      d: path(
        'M161.867,269.879L161.867,240.485564C161.867,240.485564,161.85,240.485563,161.85,240.485563L161.798427,239.396294'
      );
    }
    11.25% {
      d: path(
        'M161.867,269.879L161.867,240.485564C161.867,240.485564,161.85,240.485563,161.85,240.485563L161.798427,239.396294'
      );
    }
    26.25% {
      d: path(
        'M161.867,219.545667L161.870499,121.695859C161.870499,121.695859,161.870499,121.695861,161.870499,121.695861L161.870499,121.695859'
      );
    }
    27.5% {
      d: path(
        'M161.867006,217.615465L161.831513,121.695521C161.831513,121.695521,161.831513,121.695521,161.831513,121.695521L162.321513,120.695521'
      );
    }
    38.75% {
      d: path(
        'M161.867318,121.678L161.867318,121.678714C161.867318,121.678714,180.683499,77.67163,180.683499,77.67163L180.683499,77.67163'
      );
    }
    52.5% {
      d: path(
        'M180.683499,77.670918L180.683499,77.671631C180.683499,77.671631,180.683499,77.67163,180.683499,77.67163L180.683499,-3.712681'
      );
    }
    100% {
      d: path(
        'M180.683499,77.670918L180.683499,77.671631C180.683499,77.671631,180.683499,77.67163,180.683499,77.67163L180.683499,-3.712681'
      );
    }
  }
  @keyframes eZs2HuhKBmA33_c_o {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    27.5% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    56.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA34_to {
    animation: eZs2HuhKBmA34_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA34_to__to {
    0% {
      transform: translate(226.779751px, 128.661481px);
    }
    11.25% {
      transform: translate(226.779751px, 128.661481px);
    }
    26.25% {
      transform: translate(226.798427px, 22.771297px);
    }
    27.5% {
      transform: translate(228.798427px, 11.771297px);
    }
    38.75% {
      transform: translate(243.801035px, -23.5px);
    }
    40% {
      transform: translate(245.711986px, -32.410292px);
    }
    53.75% {
      transform: translate(245.711986px, -119.410292px);
    }
    100% {
      transform: translate(245.711986px, -119.410292px);
    }
  }
  #eZs2HuhKBmA34_tr {
    animation: eZs2HuhKBmA34_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA34_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(23deg);
    }
    38.75% {
      transform: rotate(23deg);
    }
    40% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZs2HuhKBmA34 {
    animation: eZs2HuhKBmA34_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA34_c_o {
    0% {
      opacity: 0;
    }
    13.75% {
      opacity: 0;
    }
    22.5% {
      opacity: 0.7;
    }
    53.75% {
      opacity: 0.7;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA35_to {
    animation: eZs2HuhKBmA35_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA35_to__to {
    0% {
      transform: translate(226.779751px, 128.661481px);
    }
    11.25% {
      transform: translate(226.779751px, 128.661481px);
    }
    26.25% {
      transform: translate(226.798427px, 21.271297px);
    }
    27.5% {
      transform: translate(228.798427px, 11.771297px);
    }
    38.75% {
      transform: translate(243.801035px, -23.5px);
    }
    40% {
      transform: translate(245.711986px, -32.410292px);
    }
    53.75% {
      transform: translate(245.711986px, -119.410292px);
    }
    100% {
      transform: translate(245.711986px, -119.410292px);
    }
  }
  #eZs2HuhKBmA35_tr {
    animation: eZs2HuhKBmA35_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA35_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(23deg);
    }
    38.75% {
      transform: rotate(23deg);
    }
    40% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZs2HuhKBmA35 {
    animation: eZs2HuhKBmA35_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA35_c_o {
    0% {
      opacity: 0;
    }
    13.75% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    53.75% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA37 {
    animation-name: eZs2HuhKBmA37__m, eZs2HuhKBmA37_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZs2HuhKBmA37__m {
    0% {
      d: path(
        'M161.867,269.879L161.867,240.485564C161.867,240.485564,161.85,240.485563,161.85,240.485563L161.798427,239.396294'
      );
    }
    53.75% {
      d: path(
        'M161.867,269.879L161.867,240.485564C161.867,240.485564,161.85,240.485563,161.85,240.485563L161.798427,239.396294'
      );
    }
    68.75% {
      d: path(
        'M161.867,219.545667L161.870499,121.695859C161.870499,121.695859,161.870499,121.695861,161.870499,121.695861L161.870499,121.695859'
      );
    }
    70% {
      d: path(
        'M161.867006,217.615465L161.831513,121.695521C161.831513,121.695521,161.831513,121.695521,161.831513,121.695521L162.321513,120.695521'
      );
    }
    81.25% {
      d: path(
        'M161.867318,121.678L161.867318,121.678714C161.867318,121.678714,180.683499,77.67163,180.683499,77.67163L180.683499,77.67163'
      );
    }
    95% {
      d: path(
        'M180.683499,77.670918L180.683499,77.671631C180.683499,77.671631,180.683499,77.67163,180.683499,77.67163L180.683499,-3.712681'
      );
    }
    100% {
      d: path(
        'M180.683499,77.670918L180.683499,77.671631C180.683499,77.671631,180.683499,77.67163,180.683499,77.67163L180.683499,-3.712681'
      );
    }
  }
  @keyframes eZs2HuhKBmA37_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
    92.5% {
      opacity: 1;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA38_to {
    animation: eZs2HuhKBmA38_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA38_to__to {
    0% {
      transform: translate(226.779751px, 128.661481px);
    }
    53.75% {
      transform: translate(226.779751px, 128.661481px);
    }
    68.75% {
      transform: translate(226.798427px, 22.771297px);
    }
    70% {
      transform: translate(228.798427px, 11.771297px);
    }
    81.25% {
      transform: translate(243.801035px, -23.5px);
    }
    82.5% {
      transform: translate(245.711986px, -32.410292px);
    }
    96.25% {
      transform: translate(245.711986px, -119.410292px);
    }
    100% {
      transform: translate(245.711986px, -119.410292px);
    }
  }
  #eZs2HuhKBmA38_tr {
    animation: eZs2HuhKBmA38_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA38_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(23deg);
    }
    81.25% {
      transform: rotate(23deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZs2HuhKBmA38 {
    animation: eZs2HuhKBmA38_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA38_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    65% {
      opacity: 0.7;
    }
    96.25% {
      opacity: 0.7;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA39_to {
    animation: eZs2HuhKBmA39_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA39_to__to {
    0% {
      transform: translate(226.779751px, 128.661481px);
    }
    53.75% {
      transform: translate(226.779751px, 128.661481px);
    }
    68.75% {
      transform: translate(226.798427px, 21.271297px);
    }
    70% {
      transform: translate(228.798427px, 11.771297px);
    }
    81.25% {
      transform: translate(243.801035px, -23.5px);
    }
    82.5% {
      transform: translate(245.711986px, -32.410292px);
    }
    96.25% {
      transform: translate(245.711986px, -119.410292px);
    }
    100% {
      transform: translate(245.711986px, -119.410292px);
    }
  }
  #eZs2HuhKBmA39_tr {
    animation: eZs2HuhKBmA39_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA39_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(23deg);
    }
    81.25% {
      transform: rotate(23deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZs2HuhKBmA39 {
    animation: eZs2HuhKBmA39_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA39_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    65% {
      opacity: 1;
    }
    96.25% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA43 {
    animation-name: eZs2HuhKBmA43__m, eZs2HuhKBmA43_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZs2HuhKBmA43__m {
    0% {
      d: path('M278.994002,286.5213L278.994,225.053002L279.1,225.053004L278.029001,224.053002');
    }
    18.75% {
      d: path('M278.994002,286.5213L278.994,225.053002L279.1,225.053004L278.029001,224.053002');
    }
    23.75% {
      d: path('M278.994002,221.299915L278.994,187.622573L279.1,187.622575L278.029001,186.622573');
    }
    51.25% {
      d: path('M278.994002,65.5213L278.994,-23.946998L279.1,-23.946996L278.029001,-24.946998');
    }
    100% {
      d: path('M278.994002,65.5213L278.994,-23.946998L279.1,-23.946996L278.029001,-24.946998');
    }
  }
  @keyframes eZs2HuhKBmA43_c_o {
    0% {
      opacity: 0;
    }
    27.5% {
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    47.5% {
      opacity: 1;
    }
    53.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA44_to {
    animation: eZs2HuhKBmA44_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA44_to__to {
    0% {
      transform: translate(344.25px, 128.271297px);
    }
    18.75% {
      transform: translate(344.25px, 128.271297px);
    }
    51.25% {
      transform: translate(344.25px, -117.728703px);
    }
    100% {
      transform: translate(344.25px, -117.728703px);
    }
  }
  #eZs2HuhKBmA44 {
    animation: eZs2HuhKBmA44_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA44_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    27.5% {
      opacity: 0.7;
    }
    51.25% {
      opacity: 0.7;
    }
    52.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA45_to {
    animation: eZs2HuhKBmA45_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA45_to__to {
    0% {
      transform: translate(344.25px, 128.271297px);
    }
    18.75% {
      transform: translate(344.25px, 128.271297px);
    }
    51.25% {
      transform: translate(344.25px, -117.728703px);
    }
    100% {
      transform: translate(344.25px, -117.728703px);
    }
  }
  #eZs2HuhKBmA45 {
    animation: eZs2HuhKBmA45_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA45_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    27.5% {
      opacity: 1;
    }
    51.25% {
      opacity: 1;
    }
    52.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA47 {
    animation-name: eZs2HuhKBmA47__m, eZs2HuhKBmA47_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZs2HuhKBmA47__m {
    0% {
      d: path('M278.994002,286.5213L278.994,225.053002L279.1,225.053004L278.029001,224.053002');
    }
    53.75% {
      d: path('M278.994002,286.5213L278.994,225.053002L279.1,225.053004L278.029001,224.053002');
    }
    58.75% {
      d: path('M278.994002,221.299915L278.994,187.622573L279.1,187.622575L278.029001,186.622573');
    }
    86.25% {
      d: path('M278.994002,65.5213L278.994,-23.946998L279.1,-23.946996L278.029001,-24.946998');
    }
    100% {
      d: path('M278.994002,65.5213L278.994,-23.946998L279.1,-23.946996L278.029001,-24.946998');
    }
  }
  @keyframes eZs2HuhKBmA47_c_o {
    0% {
      opacity: 0;
    }
    62.5% {
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
    82.5% {
      opacity: 1;
    }
    88.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA48_to {
    animation: eZs2HuhKBmA48_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA48_to__to {
    0% {
      transform: translate(344.25px, 128.271297px);
    }
    53.75% {
      transform: translate(344.25px, 128.271297px);
    }
    86.25% {
      transform: translate(344.25px, -117.728703px);
    }
    100% {
      transform: translate(344.25px, -117.728703px);
    }
  }
  #eZs2HuhKBmA48 {
    animation: eZs2HuhKBmA48_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA48_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    62.5% {
      opacity: 0.7;
    }
    86.25% {
      opacity: 0.7;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA49_to {
    animation: eZs2HuhKBmA49_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA49_to__to {
    0% {
      transform: translate(344.25px, 128.271297px);
    }
    53.75% {
      transform: translate(344.25px, 128.271297px);
    }
    86.25% {
      transform: translate(344.25px, -117.728703px);
    }
    100% {
      transform: translate(344.25px, -117.728703px);
    }
  }
  #eZs2HuhKBmA49 {
    animation: eZs2HuhKBmA49_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA49_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    62.5% {
      opacity: 1;
    }
    86.25% {
      opacity: 1;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA53 {
    animation-name: eZs2HuhKBmA53__m, eZs2HuhKBmA53_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZs2HuhKBmA53__m {
    0% {
      d: path(
        'M161.867,259.879L161.867,253.489998C161.867,253.489998,161.85,251.271299,161.85,251.271299L161.798427,249.396294'
      );
    }
    6.25% {
      d: path(
        'M161.867,259.879L161.867,253.489998C161.867,253.489998,161.85,251.271299,161.85,251.271299L161.798427,249.396294'
      );
    }
    22.5% {
      d: path(
        'M161.867,236.545667L161.870499,121.695859C161.870499,121.695859,161.870499,121.695861,161.870499,121.695861L162.265485,120.695859'
      );
    }
    35% {
      d: path(
        'M161.867318,121.678L161.867318,121.678714C161.867318,121.678714,180.683499,77.67163,180.683499,77.67163L180.415485,76.67163'
      );
    }
    36.25% {
      d: path(
        'M162.172897,120.963315L180.683499,77.67163C180.683499,77.67163,180.683499,75.825215,180.683499,75.825215L180.419838,75.366171'
      );
    }
    48.75% {
      d: path(
        'M180.683499,77.670918L180.683499,77.671631C180.683499,77.671631,180.683499,77.67163,180.683499,77.67163L180.683499,-3.712681'
      );
    }
    100% {
      d: path(
        'M180.683499,77.670918L180.683499,77.671631C180.683499,77.671631,180.683499,77.67163,180.683499,77.67163L180.683499,-3.712681'
      );
    }
  }
  @keyframes eZs2HuhKBmA53_c_o {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    23.75% {
      opacity: 1;
    }
    46.25% {
      opacity: 1;
    }
    52.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA54_to {
    animation: eZs2HuhKBmA54_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA54_to__to {
    0% {
      transform: translate(226.779751px, 128.661481px);
    }
    7.5% {
      transform: translate(226.779751px, 128.661481px);
    }
    22.5% {
      transform: translate(226.798427px, 19.521297px);
    }
    23.75% {
      transform: translate(229.798427px, 10.771297px);
    }
    35% {
      transform: translate(244.5px, -24.5px);
    }
    36.25% {
      transform: translate(245.711986px, -32.410292px);
    }
    50% {
      transform: translate(245.711986px, -119.410292px);
    }
    100% {
      transform: translate(245.711986px, -119.410292px);
    }
  }
  #eZs2HuhKBmA54_tr {
    animation: eZs2HuhKBmA54_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA54_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    23.75% {
      transform: rotate(23deg);
    }
    35% {
      transform: rotate(23deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZs2HuhKBmA54 {
    animation: eZs2HuhKBmA54_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA54_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    20% {
      opacity: 0.7;
    }
    50% {
      opacity: 0.7;
    }
    51.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA55_to {
    animation: eZs2HuhKBmA55_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA55_to__to {
    0% {
      transform: translate(226.779751px, 128.661481px);
    }
    7.5% {
      transform: translate(226.779751px, 128.661481px);
    }
    22.5% {
      transform: translate(226.798427px, 19.521297px);
    }
    23.75% {
      transform: translate(229.798427px, 10.771297px);
    }
    35% {
      transform: translate(244.5px, -24.5px);
    }
    36.25% {
      transform: translate(245.711986px, -32.410292px);
    }
    50% {
      transform: translate(245.711986px, -119.410292px);
    }
    100% {
      transform: translate(245.711986px, -119.410292px);
    }
  }
  #eZs2HuhKBmA55_tr {
    animation: eZs2HuhKBmA55_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA55_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    23.75% {
      transform: rotate(23deg);
    }
    35% {
      transform: rotate(23deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZs2HuhKBmA55 {
    animation: eZs2HuhKBmA55_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA55_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    51.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA57 {
    animation-name: eZs2HuhKBmA57__m, eZs2HuhKBmA57_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZs2HuhKBmA57__m {
    0% {
      d: path(
        'M161.867,259.879L161.867,253.489998C161.867,253.489998,161.85,251.271299,161.85,251.271299L161.798427,249.396294'
      );
    }
    46.25% {
      d: path(
        'M161.867,259.879L161.867,253.489998C161.867,253.489998,161.85,251.271299,161.85,251.271299L161.798427,249.396294'
      );
    }
    62.5% {
      d: path(
        'M161.867,236.545667L161.870499,121.695859C161.870499,121.695859,161.870499,121.695861,161.870499,121.695861L162.265485,120.695859'
      );
    }
    75% {
      d: path(
        'M161.867318,121.678L161.867318,121.678714C161.867318,121.678714,180.683499,77.67163,180.683499,77.67163L180.415485,76.67163'
      );
    }
    76.25% {
      d: path(
        'M162.172897,120.963315L180.683499,77.67163C180.683499,77.67163,180.683499,75.825215,180.683499,75.825215L180.419838,75.366171'
      );
    }
    88.75% {
      d: path(
        'M180.683499,77.670918L180.683499,77.671631C180.683499,77.671631,180.683499,77.67163,180.683499,77.67163L180.683499,-3.712681'
      );
    }
    100% {
      d: path(
        'M180.683499,77.670918L180.683499,77.671631C180.683499,77.671631,180.683499,77.67163,180.683499,77.67163L180.683499,-3.712681'
      );
    }
  }
  @keyframes eZs2HuhKBmA57_c_o {
    0% {
      opacity: 0;
    }
    55% {
      opacity: 0;
    }
    63.75% {
      opacity: 1;
    }
    86.25% {
      opacity: 1;
    }
    92.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA58_to {
    animation: eZs2HuhKBmA58_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA58_to__to {
    0% {
      transform: translate(226.779751px, 128.661481px);
    }
    47.5% {
      transform: translate(226.779751px, 128.661481px);
    }
    62.5% {
      transform: translate(226.798427px, 19.521297px);
    }
    63.75% {
      transform: translate(229.798427px, 10.771297px);
    }
    75% {
      transform: translate(244.5px, -24.5px);
    }
    76.25% {
      transform: translate(245.711986px, -32.410292px);
    }
    90% {
      transform: translate(245.711986px, -119.410292px);
    }
    100% {
      transform: translate(245.711986px, -119.410292px);
    }
  }
  #eZs2HuhKBmA58_tr {
    animation: eZs2HuhKBmA58_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA58_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    62.5% {
      transform: rotate(0deg);
    }
    63.75% {
      transform: rotate(23deg);
    }
    75% {
      transform: rotate(23deg);
    }
    76.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZs2HuhKBmA58 {
    animation: eZs2HuhKBmA58_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA58_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    60% {
      opacity: 0.7;
    }
    90% {
      opacity: 0.7;
    }
    91.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA59_to {
    animation: eZs2HuhKBmA59_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA59_to__to {
    0% {
      transform: translate(226.779751px, 128.661481px);
    }
    47.5% {
      transform: translate(226.779751px, 128.661481px);
    }
    62.5% {
      transform: translate(226.798427px, 19.521297px);
    }
    63.75% {
      transform: translate(229.798427px, 10.771297px);
    }
    75% {
      transform: translate(244.5px, -24.5px);
    }
    76.25% {
      transform: translate(245.711986px, -32.410292px);
    }
    90% {
      transform: translate(245.711986px, -119.410292px);
    }
    100% {
      transform: translate(245.711986px, -119.410292px);
    }
  }
  #eZs2HuhKBmA59_tr {
    animation: eZs2HuhKBmA59_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA59_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    62.5% {
      transform: rotate(0deg);
    }
    63.75% {
      transform: rotate(23deg);
    }
    75% {
      transform: rotate(23deg);
    }
    76.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZs2HuhKBmA59 {
    animation: eZs2HuhKBmA59_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA59_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    60% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    91.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA63 {
    animation-name: eZs2HuhKBmA63__m, eZs2HuhKBmA63_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZs2HuhKBmA63__m {
    0% {
      d: path(
        'M96.711985,302.439636L96.7109,234.987317L96.711985,234.987317C96.711985,234.987317,96.711985,234.987317,96.711985,234.987317'
      );
    }
    13.75% {
      d: path(
        'M96.711985,215.439636L96.7109,127.987317L96.711985,127.987317C96.711985,127.987317,96.711985,127.987317,96.711985,127.987317'
      );
    }
    15% {
      d: path(
        'M96.7109,205.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.711985,121.683001,96.711985,121.683001'
      );
    }
    30% {
      d: path(
        'M96.7109,121.678L96.7109,121.678L132.580485,77.671631C132.580485,77.671631,132.580485,77.67163,132.580485,77.67163'
      );
    }
    31.25% {
      d: path(
        'M97.29343,120.963327L132.580485,77.532603L132.580485,75.769026C132.580485,75.769026,131.580485,75.287317,131.580485,75.287317'
      );
    }
    43.75% {
      d: path(
        'M132.580485,77.671631L132.580485,77.671631L132.851035,-2.871704C132.851035,-2.871704,132.580485,-3.79188,132.580485,-3.79188'
      );
    }
    100% {
      d: path(
        'M132.580485,77.671631L132.580485,77.671631L132.851035,-2.871704C132.851035,-2.871704,132.580485,-3.79188,132.580485,-3.79188'
      );
    }
  }
  @keyframes eZs2HuhKBmA63_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    10% {
      opacity: 0.3;
    }
    15% {
      opacity: 1;
    }
    41.25% {
      opacity: 1;
    }
    47.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA64_to {
    animation: eZs2HuhKBmA64_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA64_to__to {
    0% {
      transform: translate(160.8px, 124.504306px);
    }
    1.25% {
      transform: translate(160.8px, 124.504306px);
    }
    13.75% {
      transform: translate(160.8px, 24.504306px);
    }
    15% {
      transform: translate(163.798427px, 13.502826px);
    }
    30% {
      transform: translate(193.801035px, -23.911387px);
    }
    31.25% {
      transform: translate(196.811985px, -32.712684px);
    }
    43.75% {
      transform: translate(196.811985px, -114.712684px);
    }
    100% {
      transform: translate(196.811985px, -114.712684px);
    }
  }
  #eZs2HuhKBmA64_tr {
    animation: eZs2HuhKBmA64_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA64_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    13.75% {
      transform: rotate(0deg);
    }
    15% {
      transform: rotate(39deg);
    }
    30% {
      transform: rotate(39deg);
    }
    31.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZs2HuhKBmA64 {
    animation: eZs2HuhKBmA64_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA64_c_o {
    0% {
      opacity: 0;
    }
    3.75% {
      opacity: 0;
    }
    11.25% {
      opacity: 0.7;
    }
    13.75% {
      opacity: 0.7;
    }
    43.75% {
      opacity: 0.7;
    }
    45% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA65_to {
    animation: eZs2HuhKBmA65_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA65_to__to {
    0% {
      transform: translate(160.8px, 124.504307px);
    }
    1.25% {
      transform: translate(160.8px, 124.504307px);
    }
    13.75% {
      transform: translate(160.8px, 24.504307px);
    }
    15% {
      transform: translate(163.798427px, 13.502826px);
    }
    30% {
      transform: translate(193.801035px, -23.891881px);
    }
    31.25% {
      transform: translate(196.851035px, -32.791881px);
    }
    43.75% {
      transform: translate(196.851035px, -114.791881px);
    }
    100% {
      transform: translate(196.851035px, -114.791881px);
    }
  }
  #eZs2HuhKBmA65_tr {
    animation: eZs2HuhKBmA65_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA65_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    13.75% {
      transform: rotate(0deg);
    }
    15% {
      transform: rotate(39deg);
    }
    30% {
      transform: rotate(39deg);
    }
    31.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZs2HuhKBmA65 {
    animation: eZs2HuhKBmA65_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA65_c_o {
    0% {
      opacity: 0;
    }
    3.75% {
      opacity: 0;
    }
    11.25% {
      opacity: 1;
    }
    13.75% {
      opacity: 1;
    }
    43.75% {
      opacity: 1;
    }
    45% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA67 {
    animation-name: eZs2HuhKBmA67__m, eZs2HuhKBmA67_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZs2HuhKBmA67__m {
    0% {
      d: path(
        'M96.711985,302.439636L96.7109,234.987317L96.711985,234.987317C96.711985,234.987317,96.711985,234.987317,96.711985,234.987317'
      );
    }
    51.25% {
      d: path(
        'M96.711985,302.439636L96.7109,234.987317L96.711985,234.987317C96.711985,234.987317,96.711985,234.987317,96.711985,234.987317'
      );
    }
    56.25% {
      d: path(
        'M96.711985,263.530545L96.7109,200.078226L96.711985,200.078226C96.711985,200.078226,96.711985,200.078226,96.711985,200.078226'
      );
    }
    65% {
      d: path(
        'M96.711985,195.439636L96.7109,127.987317L96.711985,127.987317C96.711985,127.987317,96.711985,127.987317,96.711985,127.987317'
      );
    }
    66.25% {
      d: path(
        'M96.7109,175.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.711985,121.683001,96.711985,121.683001'
      );
    }
    73.75% {
      d: path(
        'M96.7109,135.000465L96.7109,121.68L111.798426,103.148788C111.798426,103.148788,111.798426,103.148788,111.798426,103.148788'
      );
    }
    81.25% {
      d: path(
        'M96.7109,121.678L96.7109,121.678L132.580485,77.671631C132.580485,77.671631,132.580485,77.67163,132.580485,77.67163'
      );
    }
    95% {
      d: path(
        'M132.580485,77.671631L132.580485,77.671631L132.580485,77.671631C132.580485,77.671631,132.580485,-3.79188,132.580485,-3.79188'
      );
    }
    100% {
      d: path(
        'M132.580485,77.671631L132.580485,77.671631L132.580485,77.671631C132.580485,77.671631,132.580485,-3.79188,132.580485,-3.79188'
      );
    }
  }
  @keyframes eZs2HuhKBmA67_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    61.25% {
      opacity: 0.3;
    }
    66.25% {
      opacity: 1;
    }
    91.25% {
      opacity: 1;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA68_to {
    animation: eZs2HuhKBmA68_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA68_to__to {
    0% {
      transform: translate(160.8px, 124.504306px);
    }
    52.5% {
      transform: translate(160.8px, 124.504306px);
    }
    65% {
      transform: translate(160.8px, 24.504306px);
    }
    66.25% {
      transform: translate(164.798427px, 11.502826px);
    }
    81.25% {
      transform: translate(193.801035px, -23.911387px);
    }
    82.5% {
      transform: translate(196.811985px, -34.712684px);
    }
    95% {
      transform: translate(196.811985px, -114.712684px);
    }
    100% {
      transform: translate(196.811985px, -114.712684px);
    }
  }
  #eZs2HuhKBmA68_tr {
    animation: eZs2HuhKBmA68_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA68_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(39deg);
    }
    81.25% {
      transform: rotate(39deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZs2HuhKBmA68 {
    animation: eZs2HuhKBmA68_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA68_c_o {
    0% {
      opacity: 0;
    }
    55% {
      opacity: 0;
    }
    62.5% {
      opacity: 0.7;
    }
    65% {
      opacity: 0.7;
    }
    95% {
      opacity: 0.7;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA69_to {
    animation: eZs2HuhKBmA69_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA69_to__to {
    0% {
      transform: translate(160.8px, 124.504307px);
    }
    52.5% {
      transform: translate(160.8px, 124.504307px);
    }
    65% {
      transform: translate(160.8px, 24.504307px);
    }
    66.25% {
      transform: translate(164.798427px, 11.502826px);
    }
    81.25% {
      transform: translate(193.801035px, -23.891881px);
    }
    82.5% {
      transform: translate(196.851035px, -34.791881px);
    }
    95% {
      transform: translate(196.851035px, -114.791881px);
    }
    100% {
      transform: translate(196.851035px, -114.791881px);
    }
  }
  #eZs2HuhKBmA69_tr {
    animation: eZs2HuhKBmA69_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA69_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(39deg);
    }
    81.25% {
      transform: rotate(39deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZs2HuhKBmA69 {
    animation: eZs2HuhKBmA69_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA69_c_o {
    0% {
      opacity: 0;
    }
    55% {
      opacity: 0;
    }
    62.5% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA73 {
    animation-name: eZs2HuhKBmA73__m, eZs2HuhKBmA73_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZs2HuhKBmA73__m {
    0% {
      d: path('M32.0957,321.564875L32.0957,231.828536L32.101049,231.870431L32.101049,231.876118');
    }
    12.5% {
      d: path('M32.0957,321.564875L32.0957,231.828536L32.101049,231.870431L32.101049,231.876118');
    }
    26.25% {
      d: path('M32.0957,211.564875L32.0957,121.828536L32.101049,121.870431L32.101049,121.876118');
    }
    41.25% {
      d: path('M32.0957,129.146811L32.0957,121.678L85.801035,77.955237L85.801035,77.954434');
    }
    56.25% {
      d: path('M86.161435,77.676826L86.161435,77.676826L86.1598,77.676826L86.1598,-5.543385');
    }
    100% {
      d: path('M86.161435,77.676826L86.161435,77.676826L86.1598,77.676826L86.1598,-5.543385');
    }
  }
  @keyframes eZs2HuhKBmA73_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    26.25% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    52.5% {
      opacity: 1;
    }
    58.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA74_to {
    animation: eZs2HuhKBmA74_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA74_to__to {
    0% {
      transform: translate(96.798427px, 143.537316px);
    }
    11.25% {
      transform: translate(96.798427px, 143.537316px);
    }
    26.25% {
      transform: translate(97.111986px, 20.771297px);
    }
    27.5% {
      transform: translate(99.798427px, 14.771297px);
    }
    41.25% {
      transform: translate(148.801035px, -25.679029px);
    }
    42.5% {
      transform: translate(150.920607px, -33.028703px);
    }
    56.25% {
      transform: translate(150.920607px, -113.028703px);
    }
    100% {
      transform: translate(150.920607px, -113.028703px);
    }
  }
  #eZs2HuhKBmA74_tr {
    animation: eZs2HuhKBmA74_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA74_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(50deg);
    }
    41.25% {
      transform: rotate(50deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZs2HuhKBmA74 {
    animation: eZs2HuhKBmA74_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA74_c_o {
    0% {
      opacity: 0;
    }
    13.75% {
      opacity: 0;
    }
    22.5% {
      opacity: 0.7;
    }
    56.25% {
      opacity: 0.7;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA75_to {
    animation: eZs2HuhKBmA75_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA75_to__to {
    0% {
      transform: translate(96.798427px, 142.237316px);
    }
    11.25% {
      transform: translate(96.798427px, 142.237316px);
    }
    26.25% {
      transform: translate(97.111986px, 20.643116px);
    }
    27.5% {
      transform: translate(99.798427px, 14.771297px);
    }
    41.25% {
      transform: translate(148.833827px, -25.703774px);
    }
    42.5% {
      transform: translate(150.920607px, -33.028703px);
    }
    56.25% {
      transform: translate(150.920607px, -113.028703px);
    }
    100% {
      transform: translate(150.920607px, -113.028703px);
    }
  }
  #eZs2HuhKBmA75_tr {
    animation: eZs2HuhKBmA75_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA75_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(50deg);
    }
    41.25% {
      transform: rotate(50deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZs2HuhKBmA75 {
    animation: eZs2HuhKBmA75_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA75_c_o {
    0% {
      opacity: 0;
    }
    13.75% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    56.25% {
      opacity: 1;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA77 {
    animation-name: eZs2HuhKBmA77__m, eZs2HuhKBmA77_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZs2HuhKBmA77__m {
    0% {
      d: path('M32.0957,321.564875L32.0957,231.828536L32.101049,231.870431L32.101049,231.876118');
    }
    55% {
      d: path('M32.0957,321.564875L32.0957,231.828536L32.101049,231.870431L32.101049,231.876118');
    }
    68.75% {
      d: path('M32.0957,211.564875L32.0957,121.828536L32.101049,121.870431L32.101049,121.876118');
    }
    83.75% {
      d: path('M32.0957,129.146811L32.0957,121.678L85.801035,77.955237L85.801035,77.954434');
    }
    97.5% {
      d: path('M86.161435,77.676826L86.161435,77.676826L86.1598,77.676826L86.1598,-5.543385');
    }
    100% {
      d: path('M86.161435,77.676826L86.161435,77.676826L86.1598,77.676826L86.1598,-5.543385');
    }
  }
  @keyframes eZs2HuhKBmA77_c_o {
    0% {
      opacity: 0;
    }
    52.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 0;
    }
    72.5% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA78_to {
    animation: eZs2HuhKBmA78_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA78_to__to {
    0% {
      transform: translate(96.798427px, 143.537316px);
    }
    53.75% {
      transform: translate(96.798427px, 143.537316px);
    }
    68.75% {
      transform: translate(97.111986px, 20.771297px);
    }
    70% {
      transform: translate(99.798427px, 14.771297px);
    }
    83.75% {
      transform: translate(148.801035px, -25.679029px);
    }
    85% {
      transform: translate(150.920607px, -33.028703px);
    }
    97.5% {
      transform: translate(150.920607px, -113.028703px);
    }
    100% {
      transform: translate(150.920607px, -113.028703px);
    }
  }
  #eZs2HuhKBmA78_tr {
    animation: eZs2HuhKBmA78_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA78_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(50deg);
    }
    83.75% {
      transform: rotate(50deg);
    }
    85% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZs2HuhKBmA78 {
    animation: eZs2HuhKBmA78_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA78_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    66.25% {
      opacity: 0.7;
    }
    97.5% {
      opacity: 0.7;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZs2HuhKBmA79_to {
    animation: eZs2HuhKBmA79_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA79_to__to {
    0% {
      transform: translate(96.798427px, 142.237316px);
    }
    53.75% {
      transform: translate(96.798427px, 142.237316px);
    }
    68.75% {
      transform: translate(97.111986px, 20.643116px);
    }
    70% {
      transform: translate(99.798427px, 14.771297px);
    }
    83.75% {
      transform: translate(148.833827px, -25.703774px);
    }
    85% {
      transform: translate(150.920607px, -33.028703px);
    }
    97.5% {
      transform: translate(150.920607px, -113.028703px);
    }
    100% {
      transform: translate(150.920607px, -113.028703px);
    }
  }
  #eZs2HuhKBmA79_tr {
    animation: eZs2HuhKBmA79_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA79_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(50deg);
    }
    83.75% {
      transform: rotate(50deg);
    }
    85% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZs2HuhKBmA79 {
    animation: eZs2HuhKBmA79_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZs2HuhKBmA79_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    66.25% {
      opacity: 1;
    }
    97.5% {
      opacity: 1;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesMobileBg7;
