// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesBg2 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)', // Replace all <stop id="...-stroke-1" stopColor="#initial-color" /> with this value
  };

  return (
    <AnimatedSvg
      id="ecAex71vlN31"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 885 228"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="885"
      height="228"
      className="source-background"
      {...props}
    >
      <defs>
        <filter id="ecAex71vlN38-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ecAex71vlN38-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ecAex71vlN38-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ecAex71vlN38-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="ecAex71vlN38-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ecAex71vlN38-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ecAex71vlN38-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ecAex71vlN38-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="ecAex71vlN39-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="ecAex71vlN39-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="ecAex71vlN39-stroke-1"
            offset="51%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="ecAex71vlN39-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="ecAex71vlN310-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ecAex71vlN310-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="ecAex71vlN310-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ecAex71vlN310-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="ecAex71vlN310-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ecAex71vlN310-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ecAex71vlN310-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ecAex71vlN310-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="ecAex71vlN310-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="ecAex71vlN312-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ecAex71vlN312-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ecAex71vlN312-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ecAex71vlN312-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="ecAex71vlN312-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ecAex71vlN312-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ecAex71vlN312-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ecAex71vlN312-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="ecAex71vlN313-stroke"
          x1="-2768.078802"
          y1="0.05176"
          x2="-2768.078802"
          y2="0.886491"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="ecAex71vlN313-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="ecAex71vlN313-stroke-1"
            offset="50%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="ecAex71vlN313-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="ecAex71vlN314-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ecAex71vlN314-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="ecAex71vlN314-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ecAex71vlN314-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="ecAex71vlN314-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ecAex71vlN314-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ecAex71vlN314-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ecAex71vlN314-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="ecAex71vlN314-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="ecAex71vlN316-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ecAex71vlN316-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ecAex71vlN316-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ecAex71vlN316-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="ecAex71vlN316-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ecAex71vlN316-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ecAex71vlN316-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ecAex71vlN316-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="ecAex71vlN317-stroke"
          x1="48.07303"
          y1="0"
          x2="48.07303"
          y2="0.707618"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="ecAex71vlN317-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="ecAex71vlN317-stroke-1"
            offset="52%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="ecAex71vlN317-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="ecAex71vlN318-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ecAex71vlN318-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="ecAex71vlN318-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ecAex71vlN318-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="ecAex71vlN318-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ecAex71vlN318-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ecAex71vlN318-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ecAex71vlN318-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="ecAex71vlN318-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="ecAex71vlN322-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ecAex71vlN322-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ecAex71vlN322-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ecAex71vlN322-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="ecAex71vlN322-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ecAex71vlN322-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ecAex71vlN322-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ecAex71vlN322-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="ecAex71vlN323-stroke"
          x1="730.711595"
          y1="0.007833"
          x2="730.711595"
          y2="0.832478"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="ecAex71vlN323-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="ecAex71vlN323-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <filter id="ecAex71vlN324-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ecAex71vlN324-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="ecAex71vlN324-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ecAex71vlN324-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="ecAex71vlN324-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ecAex71vlN324-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ecAex71vlN324-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ecAex71vlN324-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="ecAex71vlN324-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="ecAex71vlN326-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ecAex71vlN326-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ecAex71vlN326-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ecAex71vlN326-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="ecAex71vlN326-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ecAex71vlN326-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ecAex71vlN326-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ecAex71vlN326-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="ecAex71vlN327-stroke"
          x1="374.271112"
          y1="0.06813"
          x2="374.271112"
          y2="0.795897"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="ecAex71vlN327-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="ecAex71vlN327-stroke-1"
            offset="52%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="ecAex71vlN327-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="ecAex71vlN328-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ecAex71vlN328-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="ecAex71vlN328-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ecAex71vlN328-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="ecAex71vlN328-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ecAex71vlN328-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ecAex71vlN328-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ecAex71vlN328-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="ecAex71vlN328-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="ecAex71vlN332-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ecAex71vlN332-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ecAex71vlN332-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ecAex71vlN332-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="ecAex71vlN332-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ecAex71vlN332-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ecAex71vlN332-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ecAex71vlN332-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="ecAex71vlN333-stroke"
          x1="730.711595"
          y1="0.007833"
          x2="730.711595"
          y2="0.832478"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="ecAex71vlN333-stroke-0" offset="0%" stopColor="#48e8c0" />
          <stop id="ecAex71vlN333-stroke-1" offset="100%" stopColor="rgba(36,99,84,0)" />
        </linearGradient>
        <filter id="ecAex71vlN334-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ecAex71vlN334-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="ecAex71vlN334-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ecAex71vlN334-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="ecAex71vlN334-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ecAex71vlN334-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ecAex71vlN334-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ecAex71vlN334-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="ecAex71vlN334-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="ecAex71vlN336-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ecAex71vlN336-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ecAex71vlN336-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ecAex71vlN336-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="ecAex71vlN336-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ecAex71vlN336-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ecAex71vlN336-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ecAex71vlN336-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="ecAex71vlN337-stroke"
          x1="374.271112"
          y1="0.06813"
          x2="374.271112"
          y2="0.795897"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="ecAex71vlN337-stroke-0" offset="0%" stopColor="#48e8c0" />
          <stop id="ecAex71vlN337-stroke-1" offset="100%" stopColor="rgba(36,99,84,0)" />
        </linearGradient>
        <filter id="ecAex71vlN338-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ecAex71vlN338-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="ecAex71vlN338-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ecAex71vlN338-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="ecAex71vlN338-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ecAex71vlN338-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ecAex71vlN338-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ecAex71vlN338-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="ecAex71vlN338-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
      </defs>
      <g transform="translate(0-1.25894)" mask="url(#ecAex71vlN340)">
        <g transform="translate(.000001 0)">
          <g transform="translate(.088013 1.012683)">
            <g transform="translate(.000001 0.624996)">
              <path d="M721.199,232.865v-112.051L591.532,76.7311l.008-76.992999" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#ecAex71vlN38-filter)">
                <path
                  id="ecAex71vlN39"
                  d="M692.384111,111.206804v-.118885L591.532,76.7311c0,0,.008-7.785355.008-7.785355"
                  transform="translate(65-105.000001)"
                  fill="none"
                  stroke="url(#ecAex71vlN39-stroke)"
                  strokeLinecap="round"
                />
                <g id="ecAex71vlN310_to" transform="translate(656.6,-36.643085)">
                  <rect
                    id="ecAex71vlN310"
                    width="6.25"
                    height="12.5"
                    rx="0"
                    ry="0"
                    transform="scale(0.8,0.8) translate(-3.125,-6.25)"
                    opacity="0.7"
                    filter="url(#ecAex71vlN310-filter)"
                    fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="ecAex71vlN311_to" transform="translate(656.6,-36.643085)">
                  <rect
                    id="ecAex71vlN311"
                    width="10"
                    height="20"
                    rx="0"
                    ry="0"
                    transform="scale(0.5,0.5) translate(-5,-10)"
                    fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#ecAex71vlN312-filter)">
                <path
                  id="ecAex71vlN313"
                  d="M721.199,252.865v-4.051l-.006-.917698c0,0,0-.755649,0-.755649"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#ecAex71vlN313-stroke)"
                  strokeLinecap="round"
                />
                <g id="ecAex71vlN314_to" transform="translate(786,138.146297)">
                  <g id="ecAex71vlN314_tr" transform="rotate(0)">
                    <rect
                      id="ecAex71vlN314"
                      width="5"
                      height="10"
                      rx="0"
                      ry="0"
                      transform="translate(-2.5,-5)"
                      opacity="0"
                      filter="url(#ecAex71vlN314-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="ecAex71vlN315_to" transform="translate(786,138.146297)">
                  <g id="ecAex71vlN315_tr" transform="rotate(0)">
                    <rect
                      id="ecAex71vlN315"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#ecAex71vlN316-filter)">
                <path
                  id="ecAex71vlN317"
                  d="M721.199,272.865v-35.718702v0c0,0-.899-1-.899-1"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#ecAex71vlN317-stroke)"
                  strokeLinecap="round"
                />
                <g id="ecAex71vlN318_to" transform="translate(786.3,137.146297)">
                  <g id="ecAex71vlN318_tr" transform="rotate(0)">
                    <rect
                      id="ecAex71vlN318"
                      width="5"
                      height="10"
                      rx="0"
                      ry="0"
                      transform="translate(-2.5,-5)"
                      opacity="0"
                      filter="url(#ecAex71vlN318-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="ecAex71vlN319_to" transform="translate(786.3,137.146297)">
                  <g id="ecAex71vlN319_tr" transform="rotate(0)">
                    <rect
                      id="ecAex71vlN319"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <path d="M161.867,229.879v-108.202L296.68,76.7222v-76.413669" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#ecAex71vlN322-filter)">
                <path
                  id="ecAex71vlN323"
                  d="M161.867,259.879v-6.389002c0,0-.017-2.218699-.017-2.218699l-.051573-1.875005"
                  transform="translate(64.996501-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#ecAex71vlN323-stroke)"
                  strokeLinecap="round"
                />
                <g id="ecAex71vlN324_to" transform="translate(227.110927,139.146297)">
                  <g id="ecAex71vlN324_tr" transform="rotate(0)">
                    <rect
                      id="ecAex71vlN324"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#ecAex71vlN324-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="ecAex71vlN325_to" transform="translate(227.110927,139.146297)">
                  <g id="ecAex71vlN325_tr" transform="rotate(0)">
                    <rect
                      id="ecAex71vlN325"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#ecAex71vlN326-filter)">
                <path
                  id="ecAex71vlN327"
                  d="M161.867,259.879v-6.389002l-.017-2.218699-.051573-1.875005"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#ecAex71vlN327-stroke)"
                  strokeLinecap="round"
                />
                <g id="ecAex71vlN328_to" transform="translate(227.298427,138.146297)">
                  <g id="ecAex71vlN328_tr" transform="rotate(0)">
                    <rect
                      id="ecAex71vlN328"
                      width="5"
                      height="10"
                      rx="0"
                      ry="0"
                      transform="translate(-2.5,-5)"
                      opacity="0"
                      filter="url(#ecAex71vlN328-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="ecAex71vlN329_to" transform="translate(227.298427,138.146297)">
                  <g id="ecAex71vlN329_tr" transform="rotate(0)">
                    <rect
                      id="ecAex71vlN329"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <mask id="ecAex71vlN340" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="825.762291"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #ecAex71vlN39 {
    animation-name: ecAex71vlN39__m, ecAex71vlN39_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes ecAex71vlN39__m {
    0% {
      d: path(
        'M692.384111,111.206804L692.384111,111.087919L591.532,76.7311C591.532,76.7311,591.54,68.945745,591.54,68.945745'
      );
    }
    10% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-20.261899,591.54,-20.261899'
      );
    }
    100% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-20.261899,591.54,-20.261899'
      );
    }
  }
  @keyframes ecAex71vlN39_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    3.75% {
      opacity: 1;
    }
    12.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ecAex71vlN310_to {
    animation: ecAex71vlN310_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN310_to__to {
    0% {
      transform: translate(656.6px, -36.643085px);
    }
    10% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #ecAex71vlN310 {
    animation: ecAex71vlN310_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN310_c_o {
    0% {
      opacity: 0.7;
    }
    10% {
      opacity: 0.7;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ecAex71vlN311_to {
    animation: ecAex71vlN311_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN311_to__to {
    0% {
      transform: translate(656.6px, -36.643085px);
    }
    10% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #ecAex71vlN311 {
    animation: ecAex71vlN311_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN311_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    10% {
      opacity: 1;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ecAex71vlN313 {
    animation-name: ecAex71vlN313__m, ecAex71vlN313_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes ecAex71vlN313__m {
    0% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    22.5% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    36.25% {
      d: path(
        'M721.199,234.531667L721.199,136.064L721.192993,131.635822C721.192993,131.635822,721.192993,128.271298,721.192993,128.271298'
      );
    }
    37.5% {
      d: path(
        'M721.199,232.865L721.199,120.814L721.192993,120.949437C721.192993,120.949437,721.192993,120.410429,721.192993,120.410429'
      );
    }
    50% {
      d: path('M721.199,121.057004L721.199,120.814L591.532,76.7311C591.532,76.7311,591.54,75.862215,591.54,75.862215');
    }
    51.25% {
      d: path(
        'M706.791556,116.131904L706.791556,115.950959L591.532,76.7311C591.532,76.7311,591.54,73.40398,591.54,73.40398'
      );
    }
    61.25% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-0.261899,591.54,-0.261899'
      );
    }
    100% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-0.261899,591.54,-0.261899'
      );
    }
  }
  @keyframes ecAex71vlN313_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    57.5% {
      opacity: 1;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ecAex71vlN314_to {
    animation: ecAex71vlN314_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN314_to__to {
    0% {
      transform: translate(786px, 138.146297px);
    }
    22.5% {
      transform: translate(786px, 138.146297px);
    }
    36.25% {
      transform: translate(786px, 23.771297px);
    }
    37.5% {
      transform: translate(784.5px, 14.771297px);
    }
    38.75% {
      transform: translate(774.3352px, 10.841812px);
    }
    50% {
      transform: translate(660.801035px, -27.231881px);
    }
    51.25% {
      transform: translate(656.920607px, -31.028703px);
    }
    52.5% {
      transform: translate(656.9px, -38.280793px);
    }
    62.5% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #ecAex71vlN314_tr {
    animation: ecAex71vlN314_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN314_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(-70deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ecAex71vlN314 {
    animation: ecAex71vlN314_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN314_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 0.7;
    }
    62.5% {
      opacity: 0.7;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ecAex71vlN315_to {
    animation: ecAex71vlN315_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN315_to__to {
    0% {
      transform: translate(786px, 138.146297px);
    }
    22.5% {
      transform: translate(786px, 138.146297px);
    }
    36.25% {
      transform: translate(786px, 22.271297px);
    }
    37.5% {
      transform: translate(783.150027px, 14.353271px);
    }
    38.75% {
      transform: translate(773.8px, 10.9px);
    }
    50% {
      transform: translate(659.801035px, -27.7px);
    }
    51.25% {
      transform: translate(656.851035px, -32.387789px);
    }
    52.5% {
      transform: translate(656.9px, -38.514307px);
    }
    62.5% {
      transform: translate(656.9px, -121.228703px);
    }
    100% {
      transform: translate(656.9px, -121.228703px);
    }
  }
  #ecAex71vlN315_tr {
    animation: ecAex71vlN315_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN315_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(-70deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ecAex71vlN315 {
    animation: ecAex71vlN315_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN315_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    62.5% {
      opacity: 1;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ecAex71vlN317 {
    animation-name: ecAex71vlN317__m, ecAex71vlN317_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes ecAex71vlN317__m {
    0% {
      d: path(
        'M721.199,272.865L721.199,237.146298L721.199,237.146298C721.199,237.146298,720.3,236.146298,720.3,236.146298'
      );
    }
    70% {
      d: path(
        'M721.199,272.865L721.199,237.146298L721.199,237.146298C721.199,237.146298,720.3,236.146298,720.3,236.146298'
      );
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    82.5% {
      d: path('M721.3,227.621261L721.199,120.814L721.199,120.814C721.199,120.814,718.8,119.971261,718.8,119.971261');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    83.75% {
      d: path(
        'M721.199,223.547667L721.199,120.814L717.881181,119.72647C717.881181,119.72647,716.965181,118.877336,716.965181,118.877336'
      );
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    97.5% {
      d: path('M721.199,121.057004L721.199,120.814L591.532,76.7311C591.532,76.7311,591.54,75.862215,591.54,75.862215');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    100% {
      d: path(
        'M692.384111,111.206804L692.384111,111.087919L591.532,76.7311C591.532,76.7311,591.54,68.945745,591.54,68.945745'
      );
    }
  }
  @keyframes ecAex71vlN317_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #ecAex71vlN318_to {
    animation: ecAex71vlN318_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN318_to__to {
    0% {
      transform: translate(786.3px, 137.146297px);
    }
    68.75% {
      transform: translate(786.3px, 137.146297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    82.5% {
      transform: translate(786.3px, 19.771297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    83.75% {
      transform: translate(783.649214px, 15.521297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    85% {
      transform: translate(774.3352px, 11.841812px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    96.25% {
      transform: translate(661.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    97.5% {
      transform: translate(656.920607px, -31.410292px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    98.75% {
      transform: translate(656.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    100% {
      transform: translate(656.9px, -40.096471px);
    }
  }
  #ecAex71vlN318_tr {
    animation: ecAex71vlN318_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN318_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-70deg);
    }
    96.25% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ecAex71vlN318 {
    animation: ecAex71vlN318_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN318_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #ecAex71vlN319_to {
    animation: ecAex71vlN319_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN319_to__to {
    0% {
      transform: translate(786.3px, 137.146297px);
    }
    68.75% {
      transform: translate(786.3px, 137.146297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    82.5% {
      transform: translate(786.3px, 19.271297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    83.75% {
      transform: translate(783.649214px, 15.521297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    85% {
      transform: translate(773.8px, 11.9px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    96.25% {
      transform: translate(660.801035px, -26.846729px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    97.5% {
      transform: translate(656.851035px, -31.410292px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    98.75% {
      transform: translate(656.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    100% {
      transform: translate(656.9px, -40.096471px);
    }
  }
  #ecAex71vlN319_tr {
    animation: ecAex71vlN319_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN319_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-70deg);
    }
    96.25% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ecAex71vlN319 {
    animation: ecAex71vlN319_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN319_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #ecAex71vlN323 {
    animation-name: ecAex71vlN323__m, ecAex71vlN323_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes ecAex71vlN323__m {
    0% {
      d: path(
        'M161.867,259.879L161.867,253.489998C161.867,253.489998,161.85,251.271299,161.85,251.271299L161.798427,249.396294'
      );
    }
    5% {
      d: path(
        'M161.867,259.879L161.867,253.489998C161.867,253.489998,161.85,251.271299,161.85,251.271299L161.798427,249.396294'
      );
    }
    20% {
      d: path(
        'M161.867,236.545667L161.801926,143.127495C161.801926,143.127495,161.85,151.211049,161.85,151.211049L161.85,143.127495'
      );
    }
    21.25% {
      d: path(
        'M161.867,233.212334L161.834463,132.402248C161.834463,132.402248,161.85,136.916728,161.85,136.916728L161.870499,136.916728'
      );
    }
    22.5% {
      d: path(
        'M161.867,229.879L161.867,121.677C161.867,121.677,161.85,122.622406,161.85,122.622406L161.8705,122.622406'
      );
    }
    32.5% {
      d: path(
        'M161.867,143.3214L161.867,121.677C161.867,121.677,277.422107,83.120632,277.422107,83.120632L280.268001,82.288075'
      );
    }
    33.75% {
      d: path(
        'M161.867,132.5017L161.867,121.677C161.867,121.677,294.804538,77.356096,294.804538,77.356096L294.804536,77.356098'
      );
    }
    35% {
      d: path('M161.867,121.682L161.867,121.677C161.867,121.677,296.68,76.7222,296.68,76.7222L296.854534,76.35604');
    }
    47.5% {
      d: path('M296.5,76.422261L296.9,76.422262C296.9,76.422262,296.68,76.7222,296.68,76.7222L296.68,-19.691469');
    }
    100% {
      d: path('M296.5,76.422261L296.9,76.422262C296.9,76.422262,296.68,76.7222,296.68,76.7222L296.68,-19.691469');
    }
  }
  @keyframes ecAex71vlN323_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    42.5% {
      opacity: 1;
    }
    51.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ecAex71vlN324_to {
    animation: ecAex71vlN324_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN324_to__to {
    0% {
      transform: translate(227.110927px, 139.146297px);
    }
    6.25% {
      transform: translate(227.110927px, 139.146297px);
    }
    21.25% {
      transform: translate(226.798427px, 23.771297px);
    }
    22.5% {
      transform: translate(230.798427px, 15.771297px);
    }
    23.75% {
      transform: translate(239.3352px, 12.841812px);
    }
    33.75% {
      transform: translate(359.801035px, -27.5px);
    }
    35% {
      transform: translate(361.920607px, -31.028703px);
    }
    36.25% {
      transform: translate(361.9px, -38.280793px);
    }
    48.75% {
      transform: translate(361.9px, -120.728703px);
    }
    100% {
      transform: translate(361.9px, -120.728703px);
    }
  }
  #ecAex71vlN324_tr {
    animation: ecAex71vlN324_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN324_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    23.75% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ecAex71vlN324 {
    animation: ecAex71vlN324_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN324_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 0.7;
    }
    48.75% {
      opacity: 0.7;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ecAex71vlN325_to {
    animation: ecAex71vlN325_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN325_to__to {
    0% {
      transform: translate(227.110927px, 139.146297px);
    }
    6.25% {
      transform: translate(227.110927px, 139.146297px);
    }
    21.25% {
      transform: translate(226.798427px, 22.271297px);
    }
    22.5% {
      transform: translate(231.798427px, 15.271297px);
    }
    23.75% {
      transform: translate(240.798427px, 12.3px);
    }
    33.75% {
      transform: translate(360.801035px, -27.891881px);
    }
    35% {
      transform: translate(361.851035px, -32.791881px);
    }
    36.25% {
      transform: translate(361.9px, -38.514307px);
    }
    48.75% {
      transform: translate(361.9px, -121.228703px);
    }
    100% {
      transform: translate(361.9px, -121.228703px);
    }
  }
  #ecAex71vlN325_tr {
    animation: ecAex71vlN325_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN325_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ecAex71vlN325 {
    animation: ecAex71vlN325_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN325_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    48.75% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ecAex71vlN327 {
    animation-name: ecAex71vlN327__m, ecAex71vlN327_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes ecAex71vlN327__m {
    0% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    52.5% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    66.25% {
      d: path('M161.867,229.879L161.815687,121.816383L161.815687,121.816383L161.815687,121.816383');
    }
    78.75% {
      d: path('M161.867,121.682L161.867,121.677L296.68,76.7222L296.68,76.687317');
    }
    81.25% {
      d: path('M182.848766,114.628534L182.911104,114.624313L296.68,76.7222L296.696501,65.302257');
    }
    93.75% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
    100% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
  }
  @keyframes ecAex71vlN327_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    87.5% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ecAex71vlN328_to {
    animation: ecAex71vlN328_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN328_to__to {
    0% {
      transform: translate(227.298427px, 138.146297px);
    }
    52.5% {
      transform: translate(227.298427px, 138.146297px);
    }
    65% {
      transform: translate(226.798427px, 23.771297px);
    }
    66.25% {
      transform: translate(230.798427px, 15.771297px);
    }
    67.5% {
      transform: translate(239.3352px, 12.841812px);
    }
    78.75% {
      transform: translate(359.801035px, -27.5px);
    }
    80% {
      transform: translate(361.920607px, -31.028703px);
    }
    81.25% {
      transform: translate(361.9px, -38.280793px);
    }
    93.75% {
      transform: translate(361.9px, -119.728703px);
    }
    100% {
      transform: translate(361.9px, -119.728703px);
    }
  }
  #ecAex71vlN328_tr {
    animation: ecAex71vlN328_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN328_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(72deg);
    }
    67.5% {
      transform: rotate(72deg);
    }
    78.75% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ecAex71vlN328 {
    animation: ecAex71vlN328_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN328_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 0.7;
    }
    93.75% {
      opacity: 0.7;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ecAex71vlN329_to {
    animation: ecAex71vlN329_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN329_to__to {
    0% {
      transform: translate(227.298427px, 138.146297px);
    }
    52.5% {
      transform: translate(227.298427px, 138.146297px);
    }
    65% {
      transform: translate(226.798427px, 22.271297px);
    }
    66.25% {
      transform: translate(231.798427px, 15.271297px);
    }
    67.5% {
      transform: translate(240.798427px, 12.3px);
    }
    78.75% {
      transform: translate(359.801035px, -27.452471px);
    }
    80% {
      transform: translate(361.851035px, -31.791881px);
    }
    81.25% {
      transform: translate(361.9px, -38.514307px);
    }
    93.75% {
      transform: translate(361.9px, -120.228703px);
    }
    100% {
      transform: translate(361.9px, -120.228703px);
    }
  }
  #ecAex71vlN329_tr {
    animation: ecAex71vlN329_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN329_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(72deg);
    }
    78.75% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ecAex71vlN329 {
    animation: ecAex71vlN329_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN329_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    93.75% {
      opacity: 0.8;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ecAex71vlN334_to {
    animation: ecAex71vlN334_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN334_to__to {
    0% {
      transform: translate(227.110927px, 139.146297px);
    }
    6.25% {
      transform: translate(227.110927px, 139.146297px);
    }
    21.25% {
      transform: translate(226.798427px, 23.771297px);
    }
    22.5% {
      transform: translate(230.798427px, 15.771297px);
    }
    23.75% {
      transform: translate(239.3352px, 12.841812px);
    }
    33.75% {
      transform: translate(359.801035px, -27.5px);
    }
    35% {
      transform: translate(361.920607px, -31.028703px);
    }
    36.25% {
      transform: translate(361.9px, -38.280793px);
    }
    48.75% {
      transform: translate(361.9px, -120.728703px);
    }
    100% {
      transform: translate(361.9px, -120.728703px);
    }
  }
  #ecAex71vlN334_tr {
    animation: ecAex71vlN334_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN334_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    23.75% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ecAex71vlN335_to {
    animation: ecAex71vlN335_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN335_to__to {
    0% {
      transform: translate(227.110927px, 139.146297px);
    }
    6.25% {
      transform: translate(227.110927px, 139.146297px);
    }
    21.25% {
      transform: translate(226.798427px, 22.271297px);
    }
    22.5% {
      transform: translate(231.798427px, 15.271297px);
    }
    23.75% {
      transform: translate(240.798427px, 12.3px);
    }
    33.75% {
      transform: translate(360.801035px, -27.891881px);
    }
    35% {
      transform: translate(361.851035px, -32.791881px);
    }
    36.25% {
      transform: translate(361.9px, -38.514307px);
    }
    48.75% {
      transform: translate(361.9px, -121.228703px);
    }
    100% {
      transform: translate(361.9px, -121.228703px);
    }
  }
  #ecAex71vlN335_tr {
    animation: ecAex71vlN335_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN335_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ecAex71vlN338_to {
    animation: ecAex71vlN338_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN338_to__to {
    0% {
      transform: translate(227.298427px, 138.146297px);
    }
    52.5% {
      transform: translate(227.298427px, 138.146297px);
    }
    65% {
      transform: translate(226.798427px, 23.771297px);
    }
    66.25% {
      transform: translate(230.798427px, 15.771297px);
    }
    67.5% {
      transform: translate(239.3352px, 12.841812px);
    }
    78.75% {
      transform: translate(359.801035px, -27.5px);
    }
    80% {
      transform: translate(361.920607px, -31.028703px);
    }
    81.25% {
      transform: translate(361.9px, -38.280793px);
    }
    93.75% {
      transform: translate(361.9px, -119.728703px);
    }
    100% {
      transform: translate(361.9px, -119.728703px);
    }
  }
  #ecAex71vlN338_tr {
    animation: ecAex71vlN338_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN338_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(72deg);
    }
    67.5% {
      transform: rotate(72deg);
    }
    78.75% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ecAex71vlN339_to {
    animation: ecAex71vlN339_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN339_to__to {
    0% {
      transform: translate(227.298427px, 138.146297px);
    }
    52.5% {
      transform: translate(227.298427px, 138.146297px);
    }
    65% {
      transform: translate(226.798427px, 22.271297px);
    }
    66.25% {
      transform: translate(231.798427px, 15.271297px);
    }
    67.5% {
      transform: translate(240.798427px, 12.3px);
    }
    78.75% {
      transform: translate(359.801035px, -27.452471px);
    }
    80% {
      transform: translate(361.851035px, -31.791881px);
    }
    81.25% {
      transform: translate(361.9px, -38.514307px);
    }
    93.75% {
      transform: translate(361.9px, -120.228703px);
    }
    100% {
      transform: translate(361.9px, -120.228703px);
    }
  }
  #ecAex71vlN339_tr {
    animation: ecAex71vlN339_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ecAex71vlN339_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(72deg);
    }
    78.75% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

export default FeedSourcesBg2;
