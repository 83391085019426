// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesBg4 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)', // Replace all <stop id="...-stroke-1" stopColor="#initial-color" /> with this value
  };

  return (
    <AnimatedSvg
      id="eFHjmrKPnoL1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 885 228"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="885"
      height="228"
      className="source-background"
      {...props}
    >
      <defs>
        <filter id="eFHjmrKPnoL9-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eFHjmrKPnoL9-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eFHjmrKPnoL9-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eFHjmrKPnoL9-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eFHjmrKPnoL9-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eFHjmrKPnoL9-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eFHjmrKPnoL9-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eFHjmrKPnoL9-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eFHjmrKPnoL10-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0"
          y2="0.992959"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eFHjmrKPnoL10-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eFHjmrKPnoL10-stroke-1"
            offset="50%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eFHjmrKPnoL10-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eFHjmrKPnoL11-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eFHjmrKPnoL11-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eFHjmrKPnoL11-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eFHjmrKPnoL11-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eFHjmrKPnoL11-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eFHjmrKPnoL11-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eFHjmrKPnoL11-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eFHjmrKPnoL11-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eFHjmrKPnoL11-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eFHjmrKPnoL13-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eFHjmrKPnoL13-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eFHjmrKPnoL13-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eFHjmrKPnoL13-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eFHjmrKPnoL13-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eFHjmrKPnoL13-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eFHjmrKPnoL13-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eFHjmrKPnoL13-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eFHjmrKPnoL14-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eFHjmrKPnoL14-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eFHjmrKPnoL14-stroke-1"
            offset="51%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eFHjmrKPnoL14-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eFHjmrKPnoL15-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eFHjmrKPnoL15-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eFHjmrKPnoL15-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eFHjmrKPnoL15-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eFHjmrKPnoL15-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eFHjmrKPnoL15-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eFHjmrKPnoL15-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eFHjmrKPnoL15-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eFHjmrKPnoL15-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eFHjmrKPnoL21-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eFHjmrKPnoL21-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eFHjmrKPnoL21-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eFHjmrKPnoL21-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eFHjmrKPnoL21-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eFHjmrKPnoL21-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eFHjmrKPnoL21-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eFHjmrKPnoL21-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eFHjmrKPnoL22-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.200995"
          y2="0.92358"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eFHjmrKPnoL22-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eFHjmrKPnoL22-stroke-1"
            offset="51%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eFHjmrKPnoL22-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eFHjmrKPnoL23-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eFHjmrKPnoL23-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eFHjmrKPnoL23-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eFHjmrKPnoL23-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eFHjmrKPnoL23-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eFHjmrKPnoL23-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eFHjmrKPnoL23-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eFHjmrKPnoL23-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eFHjmrKPnoL23-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eFHjmrKPnoL25-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eFHjmrKPnoL25-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eFHjmrKPnoL25-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eFHjmrKPnoL25-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eFHjmrKPnoL25-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eFHjmrKPnoL25-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eFHjmrKPnoL25-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eFHjmrKPnoL25-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eFHjmrKPnoL26-stroke"
          x1="-49.814583"
          y1="0.038701"
          x2="-49.814583"
          y2="0.892977"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eFHjmrKPnoL26-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eFHjmrKPnoL26-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eFHjmrKPnoL27-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eFHjmrKPnoL27-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eFHjmrKPnoL27-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eFHjmrKPnoL27-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eFHjmrKPnoL27-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eFHjmrKPnoL27-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eFHjmrKPnoL27-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eFHjmrKPnoL27-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eFHjmrKPnoL27-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eFHjmrKPnoL29-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eFHjmrKPnoL29-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eFHjmrKPnoL29-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eFHjmrKPnoL29-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eFHjmrKPnoL29-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eFHjmrKPnoL29-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eFHjmrKPnoL29-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eFHjmrKPnoL29-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eFHjmrKPnoL30-stroke"
          x1="-89.806974"
          y1="0.064793"
          x2="-89.806974"
          y2="0.890108"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eFHjmrKPnoL30-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eFHjmrKPnoL30-stroke-1"
            offset="53%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eFHjmrKPnoL30-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eFHjmrKPnoL31-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eFHjmrKPnoL31-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eFHjmrKPnoL31-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eFHjmrKPnoL31-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eFHjmrKPnoL31-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eFHjmrKPnoL31-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eFHjmrKPnoL31-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eFHjmrKPnoL31-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eFHjmrKPnoL31-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eFHjmrKPnoL35-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eFHjmrKPnoL35-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eFHjmrKPnoL35-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eFHjmrKPnoL35-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eFHjmrKPnoL35-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eFHjmrKPnoL35-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eFHjmrKPnoL35-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eFHjmrKPnoL35-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eFHjmrKPnoL36-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="matrix(0 0.761368 -0.761368 0 103.201004 0.172515)"
        >
          <stop
            id="eFHjmrKPnoL36-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eFHjmrKPnoL36-stroke-1"
            offset="52%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eFHjmrKPnoL36-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eFHjmrKPnoL37-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eFHjmrKPnoL37-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eFHjmrKPnoL37-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eFHjmrKPnoL37-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eFHjmrKPnoL37-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eFHjmrKPnoL37-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eFHjmrKPnoL37-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eFHjmrKPnoL37-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eFHjmrKPnoL37-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eFHjmrKPnoL39-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eFHjmrKPnoL39-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eFHjmrKPnoL39-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eFHjmrKPnoL39-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eFHjmrKPnoL39-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eFHjmrKPnoL39-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eFHjmrKPnoL39-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eFHjmrKPnoL39-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eFHjmrKPnoL40-stroke"
          x1="128.244976"
          y1="0.030576"
          x2="128.244978"
          y2="0.873217"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eFHjmrKPnoL40-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eFHjmrKPnoL40-stroke-1"
            offset="52%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eFHjmrKPnoL40-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eFHjmrKPnoL41-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eFHjmrKPnoL41-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eFHjmrKPnoL41-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eFHjmrKPnoL41-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eFHjmrKPnoL41-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eFHjmrKPnoL41-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eFHjmrKPnoL41-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eFHjmrKPnoL41-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eFHjmrKPnoL41-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eFHjmrKPnoL46-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eFHjmrKPnoL46-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eFHjmrKPnoL46-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eFHjmrKPnoL46-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eFHjmrKPnoL46-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eFHjmrKPnoL46-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eFHjmrKPnoL46-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eFHjmrKPnoL46-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eFHjmrKPnoL47-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="matrix(0 -0.924919 0.924919 0 -2498.525346 1.298336)"
        >
          <stop id="eFHjmrKPnoL47-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="eFHjmrKPnoL47-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eFHjmrKPnoL48-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eFHjmrKPnoL48-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eFHjmrKPnoL48-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eFHjmrKPnoL48-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eFHjmrKPnoL48-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eFHjmrKPnoL48-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eFHjmrKPnoL48-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eFHjmrKPnoL48-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eFHjmrKPnoL48-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eFHjmrKPnoL50-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eFHjmrKPnoL50-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eFHjmrKPnoL50-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eFHjmrKPnoL50-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eFHjmrKPnoL50-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eFHjmrKPnoL50-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eFHjmrKPnoL50-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eFHjmrKPnoL50-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eFHjmrKPnoL51-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 -57.180912 57.180912 0 99.491837 176.094075)"
        >
          <stop id="eFHjmrKPnoL51-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="eFHjmrKPnoL51-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eFHjmrKPnoL52-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eFHjmrKPnoL52-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eFHjmrKPnoL52-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eFHjmrKPnoL52-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eFHjmrKPnoL52-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eFHjmrKPnoL52-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eFHjmrKPnoL52-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eFHjmrKPnoL52-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eFHjmrKPnoL52-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eFHjmrKPnoL54-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eFHjmrKPnoL54-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eFHjmrKPnoL54-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eFHjmrKPnoL54-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eFHjmrKPnoL54-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eFHjmrKPnoL54-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eFHjmrKPnoL54-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eFHjmrKPnoL54-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eFHjmrKPnoL55-stroke"
          x1="-0.142435"
          y1="1.238891"
          x2="0.883069"
          y2="-0.083996"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eFHjmrKPnoL55-stroke-0" offset="2%" stopColor="rgba(64,73,168,0)" />
          <stop
            id="eFHjmrKPnoL55-stroke-1"
            offset="51%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eFHjmrKPnoL55-stroke-2"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eFHjmrKPnoL56-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eFHjmrKPnoL56-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eFHjmrKPnoL56-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eFHjmrKPnoL56-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eFHjmrKPnoL56-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eFHjmrKPnoL56-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eFHjmrKPnoL56-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eFHjmrKPnoL56-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eFHjmrKPnoL56-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eFHjmrKPnoL57-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eFHjmrKPnoL57-filter-blur-0" stdDeviation="0,0" result="result" />
        </filter>
      </defs>
      <g transform="translate(0-1.25894)" mask="url(#eFHjmrKPnoL60)">
        <g transform="translate(.000001 0)">
          <g transform="translate(.088013 1.012683)">
            <g mask="url(#eFHjmrKPnoL17)">
              <g>
                <path d="M781.258,138.241v-16.559L628.386,76.7624v-76.41865" fill="none" stroke="#6069ca" />
                <g transform="translate(-65 105.000001)" filter="url(#eFHjmrKPnoL9-filter)">
                  <path
                    id="eFHjmrKPnoL10"
                    d="M781.258,158.241v-7.559l.242-1.040176-.242-.654506"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eFHjmrKPnoL10-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eFHjmrKPnoL11_to" transform="translate(846,51.646297)">
                    <g id="eFHjmrKPnoL11_tr" transform="rotate(0)">
                      <rect
                        id="eFHjmrKPnoL11"
                        width="7.129631"
                        height="12.222224"
                        rx="0"
                        ry="0"
                        transform="scale(0.981818,0.981818) translate(-3.564815,-6.111112)"
                        opacity="0"
                        filter="url(#eFHjmrKPnoL11-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eFHjmrKPnoL12_to" transform="translate(846,51.646297)">
                    <g id="eFHjmrKPnoL12_tr" transform="rotate(0)">
                      <rect
                        id="eFHjmrKPnoL12"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eFHjmrKPnoL13-filter)">
                  <path
                    id="eFHjmrKPnoL14"
                    d="M781.258,158.241v-7.559l.242-1.040176-.242-.654506"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eFHjmrKPnoL14-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eFHjmrKPnoL15_to" transform="translate(846,50.146297)">
                    <g id="eFHjmrKPnoL15_tr" transform="rotate(0)">
                      <rect
                        id="eFHjmrKPnoL15"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-2.5,-5)"
                        opacity="0"
                        filter="url(#eFHjmrKPnoL15-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eFHjmrKPnoL16_to" transform="translate(846,50.146297)">
                    <g id="eFHjmrKPnoL16_tr" transform="rotate(0)">
                      <rect
                        id="eFHjmrKPnoL16"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="eFHjmrKPnoL17" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 605.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(.000001-4)">
              <path
                d="M590.336,232.597v-106.78L517.819,80.7272l-.001-77.20376"
                transform="translate(0 0.805403)"
                fill="none"
                stroke="#6069ca"
              />
              <g transform="translate(-65 105.000001)" filter="url(#eFHjmrKPnoL21-filter)">
                <path
                  id="eFHjmrKPnoL22"
                  d="M590.336,238.051545v-62.057573l.000001.267378-.242448-.267377"
                  transform="translate(65-104.194598)"
                  fill="none"
                  stroke="url(#eFHjmrKPnoL22-stroke)"
                  strokeLinecap="round"
                />
                <g id="eFHjmrKPnoL23_to" transform="translate(655.2,73.020302)">
                  <g id="eFHjmrKPnoL23_tr" transform="rotate(0)">
                    <rect
                      id="eFHjmrKPnoL23"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0.7"
                      filter="url(#eFHjmrKPnoL23-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eFHjmrKPnoL24_to" transform="translate(655.2,73.020302)">
                  <g id="eFHjmrKPnoL24_tr" transform="rotate(0)">
                    <rect
                      id="eFHjmrKPnoL24"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eFHjmrKPnoL25-filter)">
                <path
                  id="eFHjmrKPnoL26"
                  d="M590.336,252.597v-2.381105v0h.018"
                  transform="translate(65-104.194598)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eFHjmrKPnoL26-stroke)"
                  strokeLinecap="round"
                />
                <g id="eFHjmrKPnoL27_to" transform="translate(655.298427,137.771297)">
                  <g id="eFHjmrKPnoL27_tr" transform="rotate(0)">
                    <rect
                      id="eFHjmrKPnoL27"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eFHjmrKPnoL27-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eFHjmrKPnoL28_to" transform="translate(655.298427,137.771297)">
                  <g id="eFHjmrKPnoL28_tr" transform="rotate(0)">
                    <rect
                      id="eFHjmrKPnoL28"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eFHjmrKPnoL29-filter)">
                <path
                  id="eFHjmrKPnoL30"
                  d="M590.336,252.597v-2.381105v0h.018"
                  transform="translate(65-104.194598)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eFHjmrKPnoL30-stroke)"
                  strokeLinecap="round"
                />
                <g id="eFHjmrKPnoL31_to" transform="translate(655.2,137.646297)">
                  <rect
                    id="eFHjmrKPnoL31"
                    width="7"
                    height="12"
                    rx="0"
                    ry="0"
                    transform="translate(-3.5,-6)"
                    opacity="0"
                    filter="url(#eFHjmrKPnoL31-filter)"
                    fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eFHjmrKPnoL32_to" transform="translate(655.2,137.646297)">
                  <rect
                    id="eFHjmrKPnoL32"
                    width="10"
                    height="20"
                    rx="0"
                    ry="0"
                    transform="scale(0.5,0.5) translate(-5,-10)"
                    opacity="0"
                    fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
              </g>
            </g>
            <g>
              <path d="M292.146,229.76v-108.078l78.228-44.9059v-76.43235" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#eFHjmrKPnoL35-filter)">
                <path
                  id="eFHjmrKPnoL36"
                  d="M292.146,239.76v-1.238702l.354001-2.512982.000002-.237018"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eFHjmrKPnoL36-stroke)"
                  strokeLinecap="round"
                />
                <g id="eFHjmrKPnoL37_to" transform="translate(357.050001,139.246297)">
                  <g id="eFHjmrKPnoL37_tr" transform="rotate(0)">
                    <rect
                      id="eFHjmrKPnoL37"
                      width="7.291667"
                      height="12.5"
                      rx="0"
                      ry="0"
                      transform="scale(0.96,0.96) translate(-3.645834,-6.25)"
                      opacity="0"
                      filter="url(#eFHjmrKPnoL37-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eFHjmrKPnoL38_to" transform="translate(357.05,139.246297)">
                  <g id="eFHjmrKPnoL38_tr" transform="rotate(0)">
                    <rect
                      id="eFHjmrKPnoL38"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eFHjmrKPnoL39-filter)">
                <path
                  id="eFHjmrKPnoL40"
                  d="M292.146,239.76v-1.238702l.354001-2.512982.000002-.237018"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eFHjmrKPnoL40-stroke)"
                  strokeLinecap="round"
                />
                <g id="eFHjmrKPnoL41_to" transform="translate(357.05,139.246297)">
                  <g id="eFHjmrKPnoL41_tr" transform="rotate(0)">
                    <rect
                      id="eFHjmrKPnoL41"
                      width="7.291667"
                      height="12.5"
                      rx="0"
                      ry="0"
                      transform="scale(0.96,0.96) translate(-3.645833,-6.25)"
                      opacity="0"
                      filter="url(#eFHjmrKPnoL41-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eFHjmrKPnoL42_to" transform="translate(357.05,139.246297)">
                  <g id="eFHjmrKPnoL42_tr" transform="rotate(0)">
                    <rect
                      id="eFHjmrKPnoL42"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g mask="url(#eFHjmrKPnoL58)">
              <g transform="translate(.000001 0)">
                <path d="M96.7109,138.118v-16.438L259.817,76.7298v-76.38605" fill="none" stroke="#6069ca" />
                <g transform="translate(-64 105.000001)" filter="url(#eFHjmrKPnoL46-filter)">
                  <path
                    id="eFHjmrKPnoL47"
                    d="M96.711985,145.439636L96.7109,127.987317h.001085c0,0,0,0,0,0"
                    transform="translate(64-105.000001)"
                    fill="none"
                    stroke="url(#eFHjmrKPnoL47-stroke)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="eFHjmrKPnoL48_to" transform="translate(160.8,25.504306)">
                    <g id="eFHjmrKPnoL48_tr" transform="rotate(0)">
                      <rect
                        id="eFHjmrKPnoL48"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0.7"
                        filter="url(#eFHjmrKPnoL48-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eFHjmrKPnoL49_to" transform="translate(160.8,25.504306)">
                    <g id="eFHjmrKPnoL49_tr" transform="rotate(0)">
                      <rect
                        id="eFHjmrKPnoL49"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eFHjmrKPnoL50-filter)">
                  <path
                    id="eFHjmrKPnoL51"
                    d="M96.7109,143.118v-.064999l.1973-.999999c0,0-.109773,1.999999-.109773,1.999999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eFHjmrKPnoL51-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eFHjmrKPnoL52_to" transform="translate(161.5,50.787316)">
                    <g id="eFHjmrKPnoL52_tr" transform="rotate(0)">
                      <rect
                        id="eFHjmrKPnoL52"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0"
                        filter="url(#eFHjmrKPnoL52-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eFHjmrKPnoL53_to" transform="translate(161.5,50.787316)">
                    <g id="eFHjmrKPnoL53_tr" transform="rotate(0)">
                      <rect
                        id="eFHjmrKPnoL53"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eFHjmrKPnoL54-filter)">
                  <path
                    id="eFHjmrKPnoL55"
                    d="M96.7109,143.118v-.064999l.1973-.999999c0,0-.109773,1.999999-.109773,1.999999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eFHjmrKPnoL55-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eFHjmrKPnoL56_to" transform="translate(161.5,48.521297)">
                    <rect
                      id="eFHjmrKPnoL56"
                      width="5"
                      height="10"
                      rx="0"
                      ry="0"
                      transform="translate(-2.5,-5)"
                      opacity="0"
                      filter="url(#eFHjmrKPnoL56-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="eFHjmrKPnoL57_to" transform="translate(161.5,48.521297)">
                    <rect
                      id="eFHjmrKPnoL57"
                      width="5"
                      height="10"
                      rx="0"
                      ry="0"
                      transform="translate(-2.5,-5)"
                      opacity="0"
                      filter="url(#eFHjmrKPnoL57-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <mask id="eFHjmrKPnoL58" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 75.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
          </g>
        </g>
        <mask id="eFHjmrKPnoL60" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="825.762291"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #eFHjmrKPnoL10 {
    animation-name: eFHjmrKPnoL10__m, eFHjmrKPnoL10_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eFHjmrKPnoL10__m {
    0% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    12.5% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    17.5% {
      d: path('M781.258,146.812429L781.258,144.110571L781.527118,145.664782L780.755126,143.963766');
    }
    21.25% {
      d: path('M781.258,138.241L781.258,121.682L780.469392,121.428904L777.798427,120.771299');
    }
    36.25% {
      d: path('M781.258,121.622403L781.258,121.682L628.386,76.7624L628.386,77.3561');
    }
    47.5% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
    100% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
  }
  @keyframes eFHjmrKPnoL10_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    43.75% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eFHjmrKPnoL11_to {
    animation: eFHjmrKPnoL11_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL11_to__to {
    0% {
      transform: translate(846px, 51.646297px);
    }
    12.5% {
      transform: translate(846px, 51.646297px);
    }
    20% {
      transform: translate(846.5px, 23.521297px);
    }
    21.25% {
      transform: translate(842.798427px, 15.771297px);
    }
    22.5% {
      transform: translate(832.3352px, 12.841812px);
    }
    35% {
      transform: translate(699.801035px, -26.231881px);
    }
    36.25% {
      transform: translate(693.5px, -31.028703px);
    }
    37.5% {
      transform: translate(693.5px, -38.280793px);
    }
    47.5% {
      transform: translate(693.9px, -120.728703px);
    }
    100% {
      transform: translate(693.9px, -120.728703px);
    }
  }
  #eFHjmrKPnoL11_tr {
    animation: eFHjmrKPnoL11_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL11_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-73deg);
    }
    35% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eFHjmrKPnoL11 {
    animation: eFHjmrKPnoL11_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL11_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.7;
    }
    41.25% {
      opacity: 0.7;
    }
    51.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eFHjmrKPnoL12_to {
    animation: eFHjmrKPnoL12_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL12_to__to {
    0% {
      transform: translate(846px, 51.646297px);
    }
    12.5% {
      transform: translate(846px, 51.646297px);
    }
    20% {
      transform: translate(846.500001px, 23.521297px);
    }
    21.25% {
      transform: translate(842.3px, 15.5px);
    }
    22.5% {
      transform: translate(832.3352px, 12.841811px);
    }
    35% {
      transform: translate(699.801035px, -26.390926px);
    }
    36.25% {
      transform: translate(693.411987px, -31.390926px);
    }
    37.5% {
      transform: translate(693.5px, -38.514307px);
    }
    47.5% {
      transform: translate(693.9px, -122.228703px);
    }
    100% {
      transform: translate(693.9px, -122.228703px);
    }
  }
  #eFHjmrKPnoL12_tr {
    animation: eFHjmrKPnoL12_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL12_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-73deg);
    }
    35% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eFHjmrKPnoL12 {
    animation: eFHjmrKPnoL12_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL12_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    47.5% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eFHjmrKPnoL14 {
    animation-name: eFHjmrKPnoL14__m, eFHjmrKPnoL14_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eFHjmrKPnoL14__m {
    0% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    51.25% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    56.25% {
      d: path('M781.258,146.812429L781.258,144.110571L781.527118,145.664782L780.755126,143.963766');
    }
    60% {
      d: path('M781.258,138.241L781.258,121.682L779.797456,121.682L778.627971,122.696102');
    }
    73.75% {
      d: path('M781.258,121.622403L781.258,121.682L628.386,76.7624L628.386,77.3561');
    }
    86.25% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
    100% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
  }
  @keyframes eFHjmrKPnoL14_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 1;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eFHjmrKPnoL15_to {
    animation: eFHjmrKPnoL15_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL15_to__to {
    0% {
      transform: translate(846px, 50.146297px);
    }
    51.25% {
      transform: translate(846px, 50.146297px);
    }
    58.75% {
      transform: translate(846.5px, 23.771297px);
    }
    60% {
      transform: translate(842.798427px, 15.771297px);
    }
    61.25% {
      transform: translate(831.3352px, 12.841812px);
    }
    72.5% {
      transform: translate(702.301035px, -25.231881px);
    }
    73.75% {
      transform: translate(693.455994px, -31.159815px);
    }
    75% {
      transform: translate(693.5px, -38.280793px);
    }
    86.25% {
      transform: translate(693.9px, -119.728703px);
    }
    100% {
      transform: translate(693.9px, -119.728703px);
    }
  }
  #eFHjmrKPnoL15_tr {
    animation: eFHjmrKPnoL15_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-73deg);
    }
    72.5% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eFHjmrKPnoL15 {
    animation: eFHjmrKPnoL15_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL15_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 0.7;
    }
    82.5% {
      opacity: 0.7;
    }
    90% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eFHjmrKPnoL16_to {
    animation: eFHjmrKPnoL16_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL16_to__to {
    0% {
      transform: translate(846px, 50.146297px);
    }
    51.25% {
      transform: translate(846px, 50.146297px);
    }
    58.75% {
      transform: translate(846.5px, 22.271297px);
    }
    60% {
      transform: translate(841.104721px, 14.850134px);
    }
    61.25% {
      transform: translate(830.798427px, 12.350134px);
    }
    72.5% {
      transform: translate(703.549712px, -25.895814px);
    }
    73.75% {
      transform: translate(694.455994px, -30.159814px);
    }
    75% {
      transform: translate(693.5px, -38.514307px);
    }
    86.25% {
      transform: translate(693.9px, -121.228703px);
    }
    100% {
      transform: translate(693.9px, -121.228703px);
    }
  }
  #eFHjmrKPnoL16_tr {
    animation: eFHjmrKPnoL16_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL16_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-73deg);
    }
    72.5% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eFHjmrKPnoL16 {
    animation: eFHjmrKPnoL16_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL16_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    86.25% {
      opacity: 1;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eFHjmrKPnoL22 {
    animation-name: eFHjmrKPnoL22__m, eFHjmrKPnoL22_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eFHjmrKPnoL22__m {
    0% {
      d: path('M590.336,238.051545L590.336,175.993972L590.336001,176.26135L590.093553,175.993973');
    }
    6.25% {
      d: path('M590.336,233.506091L590.304689,136.048714L590.304689,134.224392L589.780842,130.853512');
    }
    7.5% {
      d: path('M590.336,232.597L590.298427,128.059662L590.298427,125.817L583.7183,121.82542');
    }
    8.75% {
      d: path('M590.336,222.884683L590.301843,125.817L583.709388,121.717927L577.727364,118.015545');
    }
    21.25% {
      d: path('M590.336,125.761512L590.336,125.817L517.819,80.7272L517.818,79.9168');
    }
    27.5% {
      d: path('M554.077,103.313869L554.077,103.183848L517.819,80.7272L517.818,11.72012');
    }
    32.5% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
    100% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
  }
  @keyframes eFHjmrKPnoL22_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eFHjmrKPnoL23_to {
    animation: eFHjmrKPnoL23_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL23_to__to {
    0% {
      transform: translate(655.2px, 73.020302px);
    }
    6.25% {
      transform: translate(655.798427px, 27.521297px);
    }
    7.5% {
      transform: translate(651.798427px, 19.2px);
    }
    8.75% {
      transform: translate(643.3352px, 13.841812px);
    }
    20% {
      transform: translate(586.801035px, -20.7px);
    }
    21.25% {
      transform: translate(582.920607px, -27.028703px);
    }
    22.5% {
      transform: translate(582.9px, -38.280793px);
    }
    33.75% {
      transform: translate(582.9px, -115.728703px);
    }
    100% {
      transform: translate(582.9px, -115.728703px);
    }
  }
  #eFHjmrKPnoL23_tr {
    animation: eFHjmrKPnoL23_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL23_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    6.25% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(-60deg);
    }
    20% {
      transform: rotate(-60deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eFHjmrKPnoL23 {
    animation: eFHjmrKPnoL23_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL23_c_o {
    0% {
      opacity: 0.7;
    }
    33.75% {
      opacity: 0.7;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eFHjmrKPnoL24_to {
    animation: eFHjmrKPnoL24_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL24_to__to {
    0% {
      transform: translate(655.2px, 73.020302px);
    }
    6.25% {
      transform: translate(655.798427px, 27.521297px);
    }
    7.5% {
      transform: translate(651.798427px, 19.2px);
    }
    8.75% {
      transform: translate(642.798427px, 13.25px);
    }
    20% {
      transform: translate(586.801035px, -20.855633px);
    }
    21.25% {
      transform: translate(582.851035px, -27.791881px);
    }
    22.5% {
      transform: translate(582.9px, -38.514307px);
    }
    33.75% {
      transform: translate(582.9px, -115.228703px);
    }
    100% {
      transform: translate(582.9px, -115.228703px);
    }
  }
  #eFHjmrKPnoL24_tr {
    animation: eFHjmrKPnoL24_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL24_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    6.25% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(-60deg);
    }
    20% {
      transform: rotate(-60deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eFHjmrKPnoL24 {
    animation: eFHjmrKPnoL24_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL24_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    33.75% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eFHjmrKPnoL26 {
    animation-name: eFHjmrKPnoL26__m, eFHjmrKPnoL26_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eFHjmrKPnoL26__m {
    0% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    37.5% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    50% {
      d: path('M590.336,234.415182L590.336,134.125992L590.336,134.125992L590.009483,132.602221');
    }
    51.25% {
      d: path('M590.336,232.597L590.336,125.817002L590.336,125.817002L587.775031,124.140854');
    }
    66.25% {
      d: path('M590.336,125.761512L590.336,125.817L517.819,80.7272L517.818,79.9168');
    }
    67.5% {
      d: path('M583.0842,121.271983L517.821985,80.7272L517.819,80.7272L517.818,66.077464');
    }
    78.75% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
    100% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
  }
  @keyframes eFHjmrKPnoL26_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    72.5% {
      opacity: 1;
    }
    78.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eFHjmrKPnoL27_to {
    animation: eFHjmrKPnoL27_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL27_to__to {
    0% {
      transform: translate(655.298427px, 137.771297px);
    }
    37.5% {
      transform: translate(655.298427px, 137.771297px);
    }
    50% {
      transform: translate(655.4px, 27.521297px);
    }
    51.25% {
      transform: translate(653.798427px, 20.771297px);
    }
    52.5% {
      transform: translate(643.3352px, 13.841812px);
    }
    65% {
      transform: translate(586.801035px, -20.7px);
    }
    66.25% {
      transform: translate(582.920607px, -27.028703px);
    }
    67.5% {
      transform: translate(582.960993px, -38.280793px);
    }
    78.75% {
      transform: translate(582.9px, -116.728703px);
    }
    100% {
      transform: translate(582.9px, -116.728703px);
    }
  }
  #eFHjmrKPnoL27_tr {
    animation: eFHjmrKPnoL27_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL27_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(-60deg);
    }
    65% {
      transform: rotate(-60deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eFHjmrKPnoL27 {
    animation: eFHjmrKPnoL27_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL27_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 0.7;
    }
    78.75% {
      opacity: 0.7;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eFHjmrKPnoL28_to {
    animation: eFHjmrKPnoL28_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL28_to__to {
    0% {
      transform: translate(655.298427px, 137.771297px);
    }
    37.5% {
      transform: translate(655.298427px, 137.771297px);
    }
    50% {
      transform: translate(655.4px, 27.521297px);
    }
    51.25% {
      transform: translate(653.798427px, 20.771297px);
    }
    52.5% {
      transform: translate(642.726569px, 13.25px);
    }
    65% {
      transform: translate(586.801035px, -20.855633px);
    }
    66.25% {
      transform: translate(582.920607px, -27.262684px);
    }
    67.5% {
      transform: translate(582.560992px, -38.762684px);
    }
    78.75% {
      transform: translate(582.9px, -118.228703px);
    }
    100% {
      transform: translate(582.9px, -118.228703px);
    }
  }
  #eFHjmrKPnoL28_tr {
    animation: eFHjmrKPnoL28_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL28_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(-60deg);
    }
    65% {
      transform: rotate(-60deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eFHjmrKPnoL28 {
    animation: eFHjmrKPnoL28_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL28_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    78.75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eFHjmrKPnoL30 {
    animation-name: eFHjmrKPnoL30__m, eFHjmrKPnoL30_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eFHjmrKPnoL30__m {
    0% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    90% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    100% {
      d: path('M590.336,239.869727L590.336,177.052962L590.336001,176.943168L590.469859,177.052963');
    }
  }
  @keyframes eFHjmrKPnoL30_c_o {
    0% {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    91.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eFHjmrKPnoL31_to {
    animation: eFHjmrKPnoL31_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL31_to__to {
    0% {
      transform: translate(655.2px, 137.646297px);
    }
    90% {
      transform: translate(655.2px, 137.646297px);
    }
    100% {
      transform: translate(655.203897px, 71.825773px);
    }
  }
  #eFHjmrKPnoL31 {
    animation: eFHjmrKPnoL31_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL31_c_o {
    0% {
      opacity: 0;
    }
    88.75% {
      opacity: 0;
    }
    90% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #eFHjmrKPnoL32_to {
    animation: eFHjmrKPnoL32_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL32_to__to {
    0% {
      transform: translate(655.2px, 137.646297px);
    }
    90% {
      transform: translate(655.2px, 137.646297px);
    }
    100% {
      transform: translate(655.203897px, 71.825773px);
    }
  }
  #eFHjmrKPnoL32 {
    animation: eFHjmrKPnoL32_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL32_c_o {
    0% {
      opacity: 0;
    }
    88.75% {
      opacity: 0;
    }
    90% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #eFHjmrKPnoL36 {
    animation-name: eFHjmrKPnoL36__m, eFHjmrKPnoL36_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eFHjmrKPnoL36__m {
    0% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    6.25% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    20% {
      d: path('M292.146,230.639121L292.146,137.659043L292.177124,138.677535L292.177121,139.249094');
    }
    21.25% {
      d: path('M292.146,229.76L292.146003,122.622406L292.146003,122.622405L292,123.16206');
    }
    22.5% {
      d: path('M292.146,220.770459L292.146003,122.544039L292.146001,121.694435L300.134421,117.158207');
    }
    36.25% {
      d: path('M292.146,121.885507L292.146,121.682L370.374,76.7761L370.374,77.208121');
    }
    52.5% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
    100% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
  }
  @keyframes eFHjmrKPnoL36_c_o {
    0% {
      opacity: 0;
    }
    6.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    41.25% {
      opacity: 1;
    }
    51.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eFHjmrKPnoL36-stroke {
    animation: eFHjmrKPnoL36-stroke__t 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL36-stroke__t {
    0% {
      transform: matrix(0, 0.761368, -0.761368, 0, 103.201004, 0.172515);
    }
    17.5% {
      transform: matrix(0, 0.761368, -0.761368, 0, 103.201004, 0.172515);
    }
    20% {
      transform: matrix(0, 0.713445, -0.713445, 0, 104.795476, 0.027186);
    }
    100% {
      transform: matrix(0, 0.713445, -0.713445, 0, 104.795476, 0.027186);
    }
  }
  #eFHjmrKPnoL36-stroke-0 {
    animation: eFHjmrKPnoL36-stroke-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL36-stroke-0__c {
    0% {
      stop-color: #7ea6ff;
    }
    17.5% {
      stop-color: #7ea6ff;
    }
    20% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #eFHjmrKPnoL36-stroke-1 {
    animation: eFHjmrKPnoL36-stroke-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL36-stroke-1__c {
    0% {
      stop-color: #6069ca;
    }
    17.5% {
      stop-color: #6069ca;
    }
    20% {
      stop-color: #6069ca;
    }
    100% {
      stop-color: #6069ca;
    }
  }
  #eFHjmrKPnoL36-stroke-2 {
    animation: eFHjmrKPnoL36-stroke-2__c 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL36-stroke-2__c {
    0% {
      stop-color: rgba(64, 73, 168, 0);
    }
    17.5% {
      stop-color: rgba(64, 73, 168, 0);
    }
    20% {
      stop-color: rgba(64, 73, 168, 0);
    }
    100% {
      stop-color: rgba(64, 73, 168, 0);
    }
  }
  #eFHjmrKPnoL37_to {
    animation: eFHjmrKPnoL37_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL37_to__to {
    0% {
      transform: translate(357.050001px, 139.246297px);
    }
    6.25% {
      transform: translate(357.050001px, 139.246297px);
    }
    21.25% {
      transform: translate(357.5px, 23.521297px);
    }
    22.5% {
      transform: translate(362.410189px, 13.43372px);
    }
    23.75% {
      transform: translate(367.880744px, 10.416873px);
    }
    35% {
      transform: translate(432.801035px, -26.4px);
    }
    36.25% {
      transform: translate(435.5px, -31.028703px);
    }
    37.5% {
      transform: translate(435.5px, -38.280793px);
    }
    51.25% {
      transform: translate(435.5px, -120.728703px);
    }
    100% {
      transform: translate(435.5px, -120.728703px);
    }
  }
  #eFHjmrKPnoL37_tr {
    animation: eFHjmrKPnoL37_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL37_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(62deg);
    }
    23.75% {
      transform: rotate(62deg);
    }
    35% {
      transform: rotate(62deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eFHjmrKPnoL37 {
    animation: eFHjmrKPnoL37_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL37_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 0.7;
    }
    51.25% {
      opacity: 0.7;
    }
    52.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eFHjmrKPnoL38_to {
    animation: eFHjmrKPnoL38_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL38_to__to {
    0% {
      transform: translate(357.05px, 139.246297px);
    }
    6.25% {
      transform: translate(357.05px, 139.246297px);
    }
    21.25% {
      transform: translate(357.5px, 23.521297px);
    }
    22.5% {
      transform: translate(362.410189px, 13.43372px);
    }
    23.75% {
      transform: translate(367.880744px, 10.416873px);
    }
    35% {
      transform: translate(433.202724px, -26.826559px);
    }
    36.25% {
      transform: translate(435.5px, -31.028703px);
    }
    37.5% {
      transform: translate(435.5px, -39.514307px);
    }
    51.25% {
      transform: translate(435.5px, -121.228703px);
    }
    100% {
      transform: translate(435.5px, -121.228703px);
    }
  }
  #eFHjmrKPnoL38_tr {
    animation: eFHjmrKPnoL38_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL38_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(62deg);
    }
    23.75% {
      transform: rotate(62deg);
    }
    35% {
      transform: rotate(62deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eFHjmrKPnoL38 {
    animation: eFHjmrKPnoL38_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL38_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    51.25% {
      opacity: 0.8;
    }
    52.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eFHjmrKPnoL40 {
    animation-name: eFHjmrKPnoL40__m, eFHjmrKPnoL40_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eFHjmrKPnoL40__m {
    0% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    53.75% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    70% {
      d: path('M292.146,229.76L292.146,121.682L292.146003,121.68L293.146003,121.096257');
    }
    83.75% {
      d: path('M292.146,121.885507L292.146,121.682L370.374,76.7761L370.374,77.208121');
    }
    100% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
  }
  @keyframes eFHjmrKPnoL40_c_o {
    0% {
      opacity: 0;
    }
    55% {
      opacity: 0;
    }
    60% {
      opacity: 1;
    }
    91.25% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #eFHjmrKPnoL41_to {
    animation: eFHjmrKPnoL41_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL41_to__to {
    0% {
      transform: translate(357.05px, 139.246297px);
    }
    53.75% {
      transform: translate(357.05px, 139.246297px);
    }
    68.75% {
      transform: translate(357.5px, 26.521297px);
    }
    70% {
      transform: translate(361.798427px, 14.771297px);
    }
    71.25% {
      transform: translate(367.3352px, 10.841812px);
    }
    82.5% {
      transform: translate(432.801035px, -26.4px);
    }
    83.75% {
      transform: translate(435.920607px, -31.028703px);
    }
    85% {
      transform: translate(435.7px, -38.280793px);
    }
    98.75% {
      transform: translate(435.7px, -120.728703px);
    }
    100% {
      transform: translate(435.7px, -120.728703px);
    }
  }
  #eFHjmrKPnoL41_tr {
    animation: eFHjmrKPnoL41_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL41_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(65deg);
    }
    82.5% {
      transform: rotate(65deg);
    }
    83.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eFHjmrKPnoL41 {
    animation: eFHjmrKPnoL41_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL41_c_o {
    0% {
      opacity: 0;
    }
    52.5% {
      opacity: 0;
    }
    53.75% {
      opacity: 0.7;
    }
    95% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.14;
    }
  }
  #eFHjmrKPnoL42_to {
    animation: eFHjmrKPnoL42_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL42_to__to {
    0% {
      transform: translate(357.05px, 139.246297px);
    }
    53.75% {
      transform: translate(357.05px, 139.246297px);
    }
    68.75% {
      transform: translate(357.5px, 26.521297px);
    }
    70% {
      transform: translate(361.798427px, 14.771297px);
    }
    71.25% {
      transform: translate(368.202724px, 10.173441px);
    }
    82.5% {
      transform: translate(433.202724px, -26.826559px);
    }
    83.75% {
      transform: translate(435.920608px, -31.028703px);
    }
    85% {
      transform: translate(435.7px, -39.514307px);
    }
    98.75% {
      transform: translate(435.7px, -121.228703px);
    }
    100% {
      transform: translate(435.7px, -121.228703px);
    }
  }
  #eFHjmrKPnoL42_tr {
    animation: eFHjmrKPnoL42_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL42_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(65deg);
    }
    82.5% {
      transform: rotate(65deg);
    }
    83.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eFHjmrKPnoL42 {
    animation: eFHjmrKPnoL42_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL42_c_o {
    0% {
      opacity: 0;
    }
    52.5% {
      opacity: 0;
    }
    53.75% {
      opacity: 1;
    }
    98.75% {
      opacity: 0.8;
    }
    100% {
      opacity: 0;
    }
  }
  #eFHjmrKPnoL47 {
    animation-name: eFHjmrKPnoL47__m, eFHjmrKPnoL47_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eFHjmrKPnoL47__m {
    0% {
      d: path(
        'M96.711985,145.439636L96.7109,127.987317L96.711985,127.987317C96.711985,127.987317,96.711985,127.987317,96.711985,127.987317'
      );
    }
    1.25% {
      d: path(
        'M96.7109,145.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.711985,121.683001,96.711985,121.683001'
      );
    }
    16.25% {
      d: path(
        'M96.7109,124.229631L96.7109,121.68L258.531964,77.088615C258.531964,77.088615,258.531964,77.088614,258.531964,77.088614'
      );
    }
    17.5% {
      d: path(
        'M96.7109,122.271298L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817001,75.687789,259.817001,75.687789'
      );
    }
    30% {
      d: path('M259.817,76.776103L259.817,76.776101L259.817,76.7298C259.817,76.7298,259.817,-9.65625,259.817,-9.65625');
    }
    100% {
      d: path('M259.817,76.776103L259.817,76.776101L259.817,76.7298C259.817,76.7298,259.817,-9.65625,259.817,-9.65625');
    }
  }
  @keyframes eFHjmrKPnoL47_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    33.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eFHjmrKPnoL47-stroke {
    animation: eFHjmrKPnoL47-stroke__t 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL47-stroke__t {
    0% {
      transform: matrix(0, -0.924919, 0.924919, 0, -2498.525346, 1.298336);
    }
    1.25% {
      transform: matrix(0, -1.238107, 1.238107, 0, -122.353642, 1.133493);
    }
    100% {
      transform: matrix(0, -1.238107, 1.238107, 0, -122.353642, 1.133493);
    }
  }
  #eFHjmrKPnoL47-stroke-0 {
    animation: eFHjmrKPnoL47-stroke-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL47-stroke-0__c {
    0% {
      stop-color: rgba(96, 105, 202, 0);
    }
    1.25% {
      stop-color: rgba(96, 105, 202, 0);
    }
    100% {
      stop-color: rgba(96, 105, 202, 0);
    }
  }
  #eFHjmrKPnoL47-stroke-1 {
    animation: eFHjmrKPnoL47-stroke-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL47-stroke-1__c {
    0% {
      stop-color: #7ea6ff;
    }
    1.25% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #eFHjmrKPnoL48_to {
    animation: eFHjmrKPnoL48_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL48_to__to {
    0% {
      transform: translate(160.8px, 25.504306px);
    }
    1.25% {
      transform: translate(166.798427px, 15.088613px);
    }
    16.25% {
      transform: translate(322.801035px, -27.911387px);
    }
    17.5% {
      transform: translate(323.83151px, -31.752282px);
    }
    30% {
      transform: translate(323.9px, -122.212684px);
    }
    100% {
      transform: translate(323.9px, -122.212684px);
    }
  }
  #eFHjmrKPnoL48_tr {
    animation: eFHjmrKPnoL48_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL48_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(73deg);
    }
    16.25% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eFHjmrKPnoL48 {
    animation: eFHjmrKPnoL48_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL48_c_o {
    0% {
      opacity: 0.7;
    }
    30% {
      opacity: 0.7;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eFHjmrKPnoL49_to {
    animation: eFHjmrKPnoL49_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL49_to__to {
    0% {
      transform: translate(160.8px, 25.504306px);
    }
    1.25% {
      transform: translate(166.798427px, 15.088613px);
    }
    16.25% {
      transform: translate(322.801035px, -27.891881px);
    }
    17.5% {
      transform: translate(323.83151px, -31.752282px);
    }
    30% {
      transform: translate(323.9px, -122.228703px);
    }
    100% {
      transform: translate(323.9px, -122.228703px);
    }
  }
  #eFHjmrKPnoL49_tr {
    animation: eFHjmrKPnoL49_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL49_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(73deg);
    }
    16.25% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eFHjmrKPnoL49 {
    animation: eFHjmrKPnoL49_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL49_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eFHjmrKPnoL51 {
    animation-name: eFHjmrKPnoL51__m, eFHjmrKPnoL51_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eFHjmrKPnoL51__m {
    0% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    42.5% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    48.75% {
      d: path(
        'M96.7109,144.776311L96.7109,139.694875L96.839592,139.321751C96.839592,139.321751,96.867036,140.071751,96.867036,140.071751'
      );
    }
    52.5% {
      d: path(
        'M96.7109,145.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.908201,121.683001,96.908201,121.683001'
      );
    }
    66.25% {
      d: path(
        'M96.7109,124.229631L96.7109,121.68L259.817002,76.7624C259.817002,76.7624,259.817002,76.767317,259.817002,76.767317'
      );
    }
    67.5% {
      d: path(
        'M96.7109,122.271298L96.7109,121.68L259.817,76.767317C259.817,76.767317,259.817001,75.687789,259.817001,75.687789'
      );
    }
    72.5% {
      d: path(
        'M96.798427,121.438996L259.816986,76.776103L259.817,76.7298C259.817,76.7298,259.817001,47.289957,259.817001,47.289957'
      );
    }
    78.75% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
    100% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
  }
  @keyframes eFHjmrKPnoL51_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eFHjmrKPnoL51-stroke {
    animation: eFHjmrKPnoL51-stroke__t 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL51-stroke__t {
    0% {
      transform: matrix(0, -57.180912, 57.180912, 0, 99.491837, 176.094075);
    }
    52.5% {
      transform: matrix(0, -57.180912, 57.180912, 0, 99.491837, 176.094075);
    }
    60% {
      transform: matrix(62.589539, -43.765622, 43.765622, 62.589539, 115.674411, 151.32663);
    }
    66.25% {
      transform: matrix(140.929223, -48.033988, 48.033988, 140.929223, 129.159889, 130.687092);
    }
    76.25% {
      transform: matrix(12.129785, -53.901164, 53.901164, 12.129785, 257.4, 87.901399);
    }
    78.75% {
      transform: matrix(-0.952225, -54.167943, 54.167943, -0.952225, 278.659132, 69.292375);
    }
    100% {
      transform: matrix(-0.952225, -54.167943, 54.167943, -0.952225, 278.659132, 69.292375);
    }
  }
  #eFHjmrKPnoL51-stroke-0 {
    animation: eFHjmrKPnoL51-stroke-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL51-stroke-0__c {
    0% {
      stop-color: rgba(96, 105, 202, 0);
    }
    52.5% {
      stop-color: rgba(96, 105, 202, 0);
    }
    60% {
      stop-color: rgba(96, 105, 202, 0);
    }
    66.25% {
      stop-color: rgba(96, 105, 202, 0);
    }
    76.25% {
      stop-color: rgba(96, 105, 202, 0);
    }
    78.75% {
      stop-color: rgba(96, 105, 202, 0);
    }
    100% {
      stop-color: rgba(96, 105, 202, 0);
    }
  }
  #eFHjmrKPnoL51-stroke-1 {
    animation: eFHjmrKPnoL51-stroke-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL51-stroke-1__c {
    0% {
      stop-color: #7ea6ff;
    }
    52.5% {
      stop-color: #7ea6ff;
    }
    60% {
      stop-color: #7ea6ff;
    }
    66.25% {
      stop-color: #7ea6ff;
    }
    76.25% {
      stop-color: #7ea6ff;
    }
    78.75% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #eFHjmrKPnoL52_to {
    animation: eFHjmrKPnoL52_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL52_to__to {
    0% {
      transform: translate(161.5px, 50.787316px);
    }
    42.5% {
      transform: translate(161.5px, 50.787316px);
    }
    51.25% {
      transform: translate(161.798427px, 21.771297px);
    }
    52.5% {
      transform: translate(166.798427px, 15.771297px);
    }
    66.25% {
      transform: translate(322.801035px, -27.448195px);
    }
    67.5% {
      transform: translate(324.811985px, -31.712684px);
    }
    81.25% {
      transform: translate(324.9px, -120.728703px);
    }
    100% {
      transform: translate(324.9px, -120.728703px);
    }
  }
  #eFHjmrKPnoL52_tr {
    animation: eFHjmrKPnoL52_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL52_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(73deg);
    }
    66.25% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eFHjmrKPnoL52 {
    animation: eFHjmrKPnoL52_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL52_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 0.7;
    }
    81.25% {
      opacity: 0.7;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eFHjmrKPnoL53_to {
    animation: eFHjmrKPnoL53_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL53_to__to {
    0% {
      transform: translate(161.5px, 50.787316px);
    }
    42.5% {
      transform: translate(161.5px, 50.787316px);
    }
    51.25% {
      transform: translate(161.798427px, 21.687316px);
    }
    52.5% {
      transform: translate(166.798427px, 15.671297px);
    }
    66.25% {
      transform: translate(321.801035px, -27.560064px);
    }
    67.5% {
      transform: translate(324.811985px, -31.791881px);
    }
    81.25% {
      transform: translate(324.9px, -121.228703px);
    }
    100% {
      transform: translate(324.9px, -121.228703px);
    }
  }
  #eFHjmrKPnoL53_tr {
    animation: eFHjmrKPnoL53_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL53_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(73deg);
    }
    66.25% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eFHjmrKPnoL53 {
    animation: eFHjmrKPnoL53_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL53_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    81.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eFHjmrKPnoL55 {
    animation-name: eFHjmrKPnoL55__m, eFHjmrKPnoL55_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eFHjmrKPnoL55__m {
    0% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    96.25% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    100% {
      d: path(
        'M96.7109,144.112987L96.7109,141.038125L96.867035,140.414251C96.867035,140.414251,96.839592,141.664251,96.839592,141.664251'
      );
    }
  }
  @keyframes eFHjmrKPnoL55_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eFHjmrKPnoL56_to {
    animation: eFHjmrKPnoL56_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL56_to__to {
    0% {
      transform: translate(161.5px, 48.521297px);
    }
    96.25% {
      transform: translate(161.5px, 48.521297px);
    }
    100% {
      transform: translate(161.798427px, 24.987316px);
    }
  }
  #eFHjmrKPnoL56 {
    animation: eFHjmrKPnoL56_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL56_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #eFHjmrKPnoL57_to {
    animation: eFHjmrKPnoL57_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL57_to__to {
    0% {
      transform: translate(161.5px, 48.521297px);
    }
    96.25% {
      transform: translate(161.5px, 48.521297px);
    }
    100% {
      transform: translate(161.811985px, 24.987316px);
    }
  }
  #eFHjmrKPnoL57 {
    animation: eFHjmrKPnoL57_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eFHjmrKPnoL57_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`;

export default FeedSourcesBg4;
