// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesBg5 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)', // Replace all <stop id="...-stroke-1" stopColor="#initial-color" /> with this value
  };

  return (
    <AnimatedSvg
      id="e2XfoQw7dmJ1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 885 228"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="885"
      height="228"
      className="source-background"
      {...props}
    >
      <defs>
        <filter id="e2XfoQw7dmJ9-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ9-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e2XfoQw7dmJ9-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e2XfoQw7dmJ9-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e2XfoQw7dmJ9-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e2XfoQw7dmJ9-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e2XfoQw7dmJ9-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e2XfoQw7dmJ9-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e2XfoQw7dmJ10-stroke"
          x1="0.539221"
          y1="0.188487"
          x2="0.5"
          y2="0.917302"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e2XfoQw7dmJ10-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="e2XfoQw7dmJ10-stroke-1"
            offset="50%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e2XfoQw7dmJ10-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="e2XfoQw7dmJ11-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ11-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e2XfoQw7dmJ11-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e2XfoQw7dmJ11-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e2XfoQw7dmJ11-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e2XfoQw7dmJ11-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e2XfoQw7dmJ11-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e2XfoQw7dmJ11-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e2XfoQw7dmJ11-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e2XfoQw7dmJ13-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ13-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e2XfoQw7dmJ13-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e2XfoQw7dmJ13-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e2XfoQw7dmJ13-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e2XfoQw7dmJ13-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e2XfoQw7dmJ13-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e2XfoQw7dmJ13-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e2XfoQw7dmJ14-stroke"
          x1="0.539221"
          y1="0.188487"
          x2="0.5"
          y2="0.917302"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e2XfoQw7dmJ14-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="e2XfoQw7dmJ14-stroke-1"
            offset="50%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e2XfoQw7dmJ14-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="e2XfoQw7dmJ15-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ15-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e2XfoQw7dmJ15-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e2XfoQw7dmJ15-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e2XfoQw7dmJ15-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e2XfoQw7dmJ15-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e2XfoQw7dmJ15-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e2XfoQw7dmJ15-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e2XfoQw7dmJ15-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e2XfoQw7dmJ21-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ21-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e2XfoQw7dmJ21-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e2XfoQw7dmJ21-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e2XfoQw7dmJ21-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e2XfoQw7dmJ21-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e2XfoQw7dmJ21-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e2XfoQw7dmJ21-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e2XfoQw7dmJ22-stroke"
          x1="-39.817284"
          y1="0.019674"
          x2="-39.817284"
          y2="0.893475"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e2XfoQw7dmJ22-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="e2XfoQw7dmJ22-stroke-1"
            offset="51%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e2XfoQw7dmJ22-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="e2XfoQw7dmJ23-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ23-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e2XfoQw7dmJ23-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e2XfoQw7dmJ23-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e2XfoQw7dmJ23-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e2XfoQw7dmJ23-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e2XfoQw7dmJ23-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e2XfoQw7dmJ23-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e2XfoQw7dmJ23-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e2XfoQw7dmJ25-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ25-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e2XfoQw7dmJ25-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e2XfoQw7dmJ25-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e2XfoQw7dmJ25-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e2XfoQw7dmJ25-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e2XfoQw7dmJ25-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e2XfoQw7dmJ25-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e2XfoQw7dmJ26-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="matrix(0 0.797121 -0.797121 0 33.913711 0.037832)"
        >
          <stop
            id="e2XfoQw7dmJ26-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="e2XfoQw7dmJ26-stroke-1"
            offset="52%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e2XfoQw7dmJ26-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="e2XfoQw7dmJ27-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ27-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e2XfoQw7dmJ27-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e2XfoQw7dmJ27-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e2XfoQw7dmJ27-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e2XfoQw7dmJ27-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e2XfoQw7dmJ27-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e2XfoQw7dmJ27-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e2XfoQw7dmJ27-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e2XfoQw7dmJ29-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ29-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e2XfoQw7dmJ29-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e2XfoQw7dmJ29-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e2XfoQw7dmJ29-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e2XfoQw7dmJ29-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e2XfoQw7dmJ29-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e2XfoQw7dmJ29-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e2XfoQw7dmJ30-stroke"
          x1="79.584791"
          y1="0.064793"
          x2="79.584791"
          y2="0.866052"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e2XfoQw7dmJ30-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="e2XfoQw7dmJ30-stroke-1"
            offset="53%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e2XfoQw7dmJ30-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="e2XfoQw7dmJ31-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ31-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e2XfoQw7dmJ31-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e2XfoQw7dmJ31-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e2XfoQw7dmJ31-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e2XfoQw7dmJ31-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e2XfoQw7dmJ31-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e2XfoQw7dmJ31-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e2XfoQw7dmJ31-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e2XfoQw7dmJ36-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ36-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e2XfoQw7dmJ36-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e2XfoQw7dmJ36-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e2XfoQw7dmJ36-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e2XfoQw7dmJ36-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e2XfoQw7dmJ36-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e2XfoQw7dmJ36-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e2XfoQw7dmJ37-stroke"
          x1="50.273944"
          y1="0.132555"
          x2="50.273944"
          y2="1.080189"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e2XfoQw7dmJ37-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e2XfoQw7dmJ37-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="e2XfoQw7dmJ38-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ38-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e2XfoQw7dmJ38-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e2XfoQw7dmJ38-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e2XfoQw7dmJ38-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e2XfoQw7dmJ38-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e2XfoQw7dmJ38-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e2XfoQw7dmJ38-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e2XfoQw7dmJ38-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e2XfoQw7dmJ40-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ40-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e2XfoQw7dmJ40-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e2XfoQw7dmJ40-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e2XfoQw7dmJ40-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e2XfoQw7dmJ40-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e2XfoQw7dmJ40-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e2XfoQw7dmJ40-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e2XfoQw7dmJ41-stroke"
          x1="50.273944"
          y1="0.132555"
          x2="50.273944"
          y2="1.080189"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e2XfoQw7dmJ41-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e2XfoQw7dmJ41-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="e2XfoQw7dmJ42-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ42-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e2XfoQw7dmJ42-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e2XfoQw7dmJ42-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e2XfoQw7dmJ42-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e2XfoQw7dmJ42-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e2XfoQw7dmJ42-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e2XfoQw7dmJ42-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e2XfoQw7dmJ42-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e2XfoQw7dmJ44-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ44-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e2XfoQw7dmJ44-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e2XfoQw7dmJ44-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e2XfoQw7dmJ44-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e2XfoQw7dmJ44-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e2XfoQw7dmJ44-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e2XfoQw7dmJ44-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e2XfoQw7dmJ45-stroke"
          x1="50.273944"
          y1="0.132555"
          x2="50.273944"
          y2="1.080189"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e2XfoQw7dmJ45-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e2XfoQw7dmJ45-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="e2XfoQw7dmJ46-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ46-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e2XfoQw7dmJ46-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e2XfoQw7dmJ46-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e2XfoQw7dmJ46-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e2XfoQw7dmJ46-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e2XfoQw7dmJ46-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e2XfoQw7dmJ46-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e2XfoQw7dmJ46-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e2XfoQw7dmJ52-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ52-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e2XfoQw7dmJ52-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e2XfoQw7dmJ52-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e2XfoQw7dmJ52-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e2XfoQw7dmJ52-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e2XfoQw7dmJ52-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e2XfoQw7dmJ52-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e2XfoQw7dmJ53-stroke"
          x1="85.068571"
          y1="0.051462"
          x2="85.068571"
          y2="0.996549"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e2XfoQw7dmJ53-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="e2XfoQw7dmJ53-stroke-1"
            offset="52%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e2XfoQw7dmJ53-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="e2XfoQw7dmJ54-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ54-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e2XfoQw7dmJ54-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e2XfoQw7dmJ54-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e2XfoQw7dmJ54-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e2XfoQw7dmJ54-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e2XfoQw7dmJ54-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e2XfoQw7dmJ54-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e2XfoQw7dmJ54-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e2XfoQw7dmJ56-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ56-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e2XfoQw7dmJ56-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e2XfoQw7dmJ56-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e2XfoQw7dmJ56-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e2XfoQw7dmJ56-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e2XfoQw7dmJ56-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e2XfoQw7dmJ56-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e2XfoQw7dmJ57-stroke"
          x1="241.401091"
          y1="0.030759"
          x2="241.401091"
          y2="0.745505"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e2XfoQw7dmJ57-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e2XfoQw7dmJ57-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="e2XfoQw7dmJ58-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ58-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e2XfoQw7dmJ58-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e2XfoQw7dmJ58-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e2XfoQw7dmJ58-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e2XfoQw7dmJ58-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e2XfoQw7dmJ58-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e2XfoQw7dmJ58-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e2XfoQw7dmJ58-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e2XfoQw7dmJ63-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ63-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e2XfoQw7dmJ63-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e2XfoQw7dmJ63-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e2XfoQw7dmJ63-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e2XfoQw7dmJ63-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e2XfoQw7dmJ63-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e2XfoQw7dmJ63-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e2XfoQw7dmJ64-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 -40.465441 40.465441 0 108.00414 168.364292)"
        >
          <stop id="e2XfoQw7dmJ64-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="e2XfoQw7dmJ64-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="e2XfoQw7dmJ65-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ65-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e2XfoQw7dmJ65-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e2XfoQw7dmJ65-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e2XfoQw7dmJ65-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e2XfoQw7dmJ65-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e2XfoQw7dmJ65-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e2XfoQw7dmJ65-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e2XfoQw7dmJ65-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e2XfoQw7dmJ67-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ67-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e2XfoQw7dmJ67-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e2XfoQw7dmJ67-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e2XfoQw7dmJ67-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e2XfoQw7dmJ67-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e2XfoQw7dmJ67-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e2XfoQw7dmJ67-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e2XfoQw7dmJ68-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 -40.465441 40.465441 0 108.00414 168.364292)"
        >
          <stop id="e2XfoQw7dmJ68-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="e2XfoQw7dmJ68-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="e2XfoQw7dmJ69-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ69-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e2XfoQw7dmJ69-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e2XfoQw7dmJ69-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e2XfoQw7dmJ69-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e2XfoQw7dmJ69-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e2XfoQw7dmJ69-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e2XfoQw7dmJ69-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e2XfoQw7dmJ69-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e2XfoQw7dmJ71-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ71-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e2XfoQw7dmJ71-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e2XfoQw7dmJ71-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e2XfoQw7dmJ71-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e2XfoQw7dmJ71-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e2XfoQw7dmJ71-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e2XfoQw7dmJ71-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e2XfoQw7dmJ72-stroke"
          x1="-0.142435"
          y1="1.238891"
          x2="0.883069"
          y2="-0.083996"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="e2XfoQw7dmJ72-stroke-0" offset="2%" stopColor="rgba(64,73,168,0)" />
          <stop
            id="e2XfoQw7dmJ72-stroke-1"
            offset="51%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="e2XfoQw7dmJ72-stroke-2"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="e2XfoQw7dmJ73-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ73-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e2XfoQw7dmJ73-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e2XfoQw7dmJ73-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e2XfoQw7dmJ73-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e2XfoQw7dmJ73-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e2XfoQw7dmJ73-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e2XfoQw7dmJ73-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e2XfoQw7dmJ73-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e2XfoQw7dmJ74-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e2XfoQw7dmJ74-filter-blur-0" stdDeviation="0,0" result="result" />
        </filter>
      </defs>
      <g transform="translate(0-1.25894)" mask="url(#e2XfoQw7dmJ77)">
        <g transform="translate(.000001 0)">
          <g transform="translate(.088013 1.012683)">
            <g mask="url(#e2XfoQw7dmJ17)">
              <g>
                <path d="M781.258,138.241v-16.559L628.386,76.7624v-76.41865" fill="none" stroke="#6069ca" />
                <g transform="translate(-65 105.000001)" filter="url(#e2XfoQw7dmJ9-filter)">
                  <path
                    id="e2XfoQw7dmJ10"
                    d="M781.258,178.241v-27.559-.894683-.799999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#e2XfoQw7dmJ10-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="e2XfoQw7dmJ11_to" transform="translate(846.5,51.646297)">
                    <g id="e2XfoQw7dmJ11_tr" transform="rotate(0)">
                      <rect
                        id="e2XfoQw7dmJ11"
                        width="7.129631"
                        height="12.222224"
                        rx="0"
                        ry="0"
                        transform="scale(0.981818,0.981818) translate(-3.564816,-6.111112)"
                        opacity="0"
                        filter="url(#e2XfoQw7dmJ11-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="e2XfoQw7dmJ12_to" transform="translate(846.5,51.646297)">
                    <g id="e2XfoQw7dmJ12_tr" transform="rotate(0)">
                      <rect
                        id="e2XfoQw7dmJ12"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#e2XfoQw7dmJ13-filter)">
                  <path
                    id="e2XfoQw7dmJ14"
                    d="M781.258,178.241v-27.559-.894683-.799999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#e2XfoQw7dmJ14-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="e2XfoQw7dmJ15_to" transform="translate(846.5,51.646297)">
                    <g id="e2XfoQw7dmJ15_tr" transform="rotate(0)">
                      <rect
                        id="e2XfoQw7dmJ15"
                        width="7.129631"
                        height="12.222224"
                        rx="0"
                        ry="0"
                        transform="scale(0.981818,0.981818) translate(-3.564816,-6.111112)"
                        opacity="0"
                        filter="url(#e2XfoQw7dmJ15-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="e2XfoQw7dmJ16_to" transform="translate(846.5,51.646297)">
                    <g id="e2XfoQw7dmJ16_tr" transform="rotate(0)">
                      <rect
                        id="e2XfoQw7dmJ16"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="e2XfoQw7dmJ17" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 605.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(.000001-4)">
              <path
                d="M590.336,232.597v-106.78L517.819,80.7272l-.001-77.20376"
                transform="translate(0 0.805403)"
                fill="none"
                stroke="#6069ca"
              />
              <g transform="translate(-65 105.000001)" filter="url(#e2XfoQw7dmJ21-filter)">
                <path
                  id="e2XfoQw7dmJ22"
                  d="M590.336,238.051545v-62.057573l.000001.267378-.242448-.267377"
                  transform="translate(65-104.194598)"
                  fill="none"
                  stroke="url(#e2XfoQw7dmJ22-stroke)"
                  strokeLinecap="round"
                />
                <g id="e2XfoQw7dmJ23_to" transform="translate(655.2,73.020302)">
                  <g id="e2XfoQw7dmJ23_tr" transform="rotate(0)">
                    <rect
                      id="e2XfoQw7dmJ23"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0.7"
                      filter="url(#e2XfoQw7dmJ23-filter)"
                      fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="e2XfoQw7dmJ24_to" transform="translate(655.2,73.020302)">
                  <g id="e2XfoQw7dmJ24_tr" transform="rotate(0)">
                    <rect
                      id="e2XfoQw7dmJ24"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#e2XfoQw7dmJ25-filter)">
                <path
                  id="e2XfoQw7dmJ26"
                  d="M590.336,282.597c0,0,0-42.381105,0-42.381105v1.190553l.018-1.190553"
                  transform="translate(65-104.194598)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e2XfoQw7dmJ26-stroke)"
                  strokeLinecap="round"
                />
                <g id="e2XfoQw7dmJ27_to" transform="translate(655.5,137.771297)">
                  <g id="e2XfoQw7dmJ27_tr" transform="rotate(0)">
                    <rect
                      id="e2XfoQw7dmJ27"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#e2XfoQw7dmJ27-filter)"
                      fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="e2XfoQw7dmJ28_to" transform="translate(655.5,137.771297)">
                  <g id="e2XfoQw7dmJ28_tr" transform="rotate(0)">
                    <rect
                      id="e2XfoQw7dmJ28"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#e2XfoQw7dmJ29-filter)">
                <path
                  id="e2XfoQw7dmJ30"
                  d="M590.336,272.597v-27.381105v0l.018-1"
                  transform="translate(65-104.194598)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e2XfoQw7dmJ30-stroke)"
                  strokeLinecap="round"
                />
                <g id="e2XfoQw7dmJ31_to" transform="translate(655.5,137.646297)">
                  <rect
                    id="e2XfoQw7dmJ31"
                    width="7"
                    height="12"
                    rx="0"
                    ry="0"
                    transform="translate(-3.5,-6)"
                    opacity="0"
                    filter="url(#e2XfoQw7dmJ31-filter)"
                    fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="e2XfoQw7dmJ32_to" transform="translate(655.5,137.646297)">
                  <rect
                    id="e2XfoQw7dmJ32"
                    width="10"
                    height="20"
                    rx="0"
                    ry="0"
                    transform="scale(0.5,0.5) translate(-5,-10)"
                    opacity="0"
                    fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
              </g>
            </g>
            <g transform="translate(267 0)" mask="url(#e2XfoQw7dmJ48)">
              <g transform="translate(.000001 0)">
                <line
                  x1="443.301"
                  y1="169.502"
                  x2="443.301"
                  y2="325.462"
                  transform="translate(-267.389015-178.956)"
                  fill="none"
                  stroke="#6069ca"
                />
                <g transform="translate(-64 105.000001)" filter="url(#e2XfoQw7dmJ36-filter)">
                  <path
                    id="e2XfoQw7dmJ37"
                    d="M175.911985,188.439636v-57.452319v0c0,0-.201085-.48301-.201085-.48301"
                    transform="translate(64-105.000001)"
                    fill="none"
                    stroke="url(#e2XfoQw7dmJ37-stroke)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="e2XfoQw7dmJ38_to" transform="translate(239.8,25.504306)">
                    <rect
                      id="e2XfoQw7dmJ38"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0.7"
                      filter="url(#e2XfoQw7dmJ38-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="e2XfoQw7dmJ39_to" transform="translate(239.8,25.504306)">
                    <rect
                      id="e2XfoQw7dmJ39"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g transform="translate(-64 105.000001)" filter="url(#e2XfoQw7dmJ40-filter)">
                  <path
                    id="e2XfoQw7dmJ41"
                    d="M175.911985,208.439636v-57.452319v0c0,0-.201085-.48301-.201085-.48301"
                    transform="translate(64-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#e2XfoQw7dmJ41-stroke)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="e2XfoQw7dmJ42_to" transform="translate(239.8,45.504306)">
                    <rect
                      id="e2XfoQw7dmJ42"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#e2XfoQw7dmJ42-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="e2XfoQw7dmJ43_to" transform="translate(239.8,45.504306)">
                    <rect
                      id="e2XfoQw7dmJ43"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g transform="translate(-64 105.000001)" filter="url(#e2XfoQw7dmJ44-filter)">
                  <path
                    id="e2XfoQw7dmJ45"
                    d="M175.911985,208.439636v-57.452319v0c0,0-.201085-.48301-.201085-.48301"
                    transform="translate(64-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#e2XfoQw7dmJ45-stroke)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="e2XfoQw7dmJ46_to" transform="translate(239.8,45.504306)">
                    <rect
                      id="e2XfoQw7dmJ46"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#e2XfoQw7dmJ46-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="e2XfoQw7dmJ47_to" transform="translate(239.8,45.504306)">
                    <rect
                      id="e2XfoQw7dmJ47"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <mask id="e2XfoQw7dmJ48" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 75.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g>
              <path d="M292.146,229.76v-108.078l78.228-44.9059v-76.43235" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#e2XfoQw7dmJ52-filter)">
                <path
                  id="e2XfoQw7dmJ53"
                  d="M292.146,239.76v-1.238702l.354001-2.512982.000002-.237018"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e2XfoQw7dmJ53-stroke)"
                  strokeLinecap="round"
                />
                <g id="e2XfoQw7dmJ54_to" transform="translate(358.05,139.246297)">
                  <g id="e2XfoQw7dmJ54_tr" transform="rotate(0)">
                    <rect
                      id="e2XfoQw7dmJ54"
                      width="7.291667"
                      height="12.5"
                      rx="0"
                      ry="0"
                      transform="scale(0.96,0.96) translate(-3.645833,-6.25)"
                      opacity="0"
                      filter="url(#e2XfoQw7dmJ54-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="e2XfoQw7dmJ55_to" transform="translate(358.050001,139.246297)">
                  <g id="e2XfoQw7dmJ55_tr" transform="rotate(0)">
                    <rect
                      id="e2XfoQw7dmJ55"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#e2XfoQw7dmJ56-filter)">
                <path
                  id="e2XfoQw7dmJ57"
                  d="M292.146,239.76v-1.238702l.354001-2.512982.000002-.237018"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e2XfoQw7dmJ57-stroke)"
                  strokeLinecap="round"
                />
                <g id="e2XfoQw7dmJ58_to" transform="translate(357,139.246297)">
                  <g id="e2XfoQw7dmJ58_tr" transform="rotate(0)">
                    <rect
                      id="e2XfoQw7dmJ58"
                      width="7.291667"
                      height="12.5"
                      rx="0"
                      ry="0"
                      transform="scale(0.96,0.96) translate(-3.645833,-6.25)"
                      opacity="0"
                      filter="url(#e2XfoQw7dmJ58-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="e2XfoQw7dmJ59_to" transform="translate(357,139.246297)">
                  <g id="e2XfoQw7dmJ59_tr" transform="rotate(0)">
                    <rect
                      id="e2XfoQw7dmJ59"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g mask="url(#e2XfoQw7dmJ75)">
              <g transform="translate(.000001 0)">
                <path d="M96.7109,138.118v-16.438L259.817,76.7298v-76.38605" fill="none" stroke="#6069ca" />
                <g transform="translate(-65 105.000001)" filter="url(#e2XfoQw7dmJ63-filter)">
                  <path
                    id="e2XfoQw7dmJ64"
                    d="M96.7109,144.776311v-11.72028v0c0,0-1-1-1-1"
                    transform="translate(65-105.000001)"
                    fill="none"
                    stroke="url(#e2XfoQw7dmJ64-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="e2XfoQw7dmJ65_to" transform="translate(161.798694,25)">
                    <g id="e2XfoQw7dmJ65_tr" transform="rotate(0)">
                      <rect
                        id="e2XfoQw7dmJ65"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0.7"
                        filter="url(#e2XfoQw7dmJ65-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="e2XfoQw7dmJ66_to" transform="translate(161.798694,25)">
                    <g id="e2XfoQw7dmJ66_tr" transform="rotate(0)">
                      <rect
                        id="e2XfoQw7dmJ66"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#e2XfoQw7dmJ67-filter)">
                  <path
                    id="e2XfoQw7dmJ68"
                    d="M96.7109,143.118v-.064999l.1973-.999999c0,0-.109773,1.999999-.109773,1.999999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#e2XfoQw7dmJ68-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="e2XfoQw7dmJ69_to" transform="translate(161.8,50.787316)">
                    <g id="e2XfoQw7dmJ69_tr" transform="rotate(0)">
                      <rect
                        id="e2XfoQw7dmJ69"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0"
                        filter="url(#e2XfoQw7dmJ69-filter)"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="e2XfoQw7dmJ70_to" transform="translate(161.8,50.787316)">
                    <g id="e2XfoQw7dmJ70_tr" transform="rotate(0)">
                      <rect
                        id="e2XfoQw7dmJ70"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#e2XfoQw7dmJ71-filter)">
                  <path
                    id="e2XfoQw7dmJ72"
                    d="M96.7109,143.118v-.064999l.1973-.999999c0,0-.109773,1.999999-.109773,1.999999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#e2XfoQw7dmJ72-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="e2XfoQw7dmJ73_to" transform="translate(161.8,49.521297)">
                    <rect
                      id="e2XfoQw7dmJ73"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#e2XfoQw7dmJ73-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="e2XfoQw7dmJ74_to" transform="translate(161.8,49.521297)">
                    <rect
                      id="e2XfoQw7dmJ74"
                      width="5"
                      height="10"
                      rx="0"
                      ry="0"
                      transform="translate(-2.5,-5)"
                      opacity="0"
                      filter="url(#e2XfoQw7dmJ74-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <mask id="e2XfoQw7dmJ75" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 75.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
          </g>
        </g>
        <mask id="e2XfoQw7dmJ77" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="825.762291"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #e2XfoQw7dmJ10 {
    animation-name: e2XfoQw7dmJ10__m, e2XfoQw7dmJ10_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e2XfoQw7dmJ10__m {
    0% {
      d: path('M781.258,178.241L781.258,150.682L781.258,149.787317L781.258,148.987318');
    }
    12.5% {
      d: path('M781.258,178.241L781.258,150.682L781.258,149.787317L781.258,148.987318');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    20% {
      d: path('M781.258,181.098143L781.258,129.331778L781.258,124.296582L781.258,122.622404');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.25% {
      d: path('M781.258,178.241L781.258,121.682L781,121.428904L775.970714,119.985741');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    36.25% {
      d: path('M781.258,121.622403L781.258,121.682L628.386,76.7624L628.386,77.3561');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    47.5% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
    100% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
  }
  @keyframes e2XfoQw7dmJ10_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    43.75% {
      opacity: 1;
    }
    52.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ11_to {
    animation: e2XfoQw7dmJ11_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ11_to__to {
    0% {
      transform: translate(846.5px, 51.646297px);
    }
    12.5% {
      transform: translate(846.5px, 51.646297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    20% {
      transform: translate(846.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.25% {
      transform: translate(842.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    22.5% {
      transform: translate(832.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    35% {
      transform: translate(699.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    36.25% {
      transform: translate(693.5px, -31.028703px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    37.5% {
      transform: translate(693.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    47.5% {
      transform: translate(693.9px, -120.728703px);
    }
    100% {
      transform: translate(693.9px, -120.728703px);
    }
  }
  #e2XfoQw7dmJ11_tr {
    animation: e2XfoQw7dmJ11_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ11_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-73deg);
    }
    35% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e2XfoQw7dmJ11 {
    animation: e2XfoQw7dmJ11_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ11_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.7;
    }
    47.5% {
      opacity: 0.7;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ12_to {
    animation: e2XfoQw7dmJ12_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ12_to__to {
    0% {
      transform: translate(846.5px, 51.646297px);
    }
    12.5% {
      transform: translate(846.5px, 51.646297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    20% {
      transform: translate(846.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    21.25% {
      transform: translate(842.798427px, 15.771298px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    22.5% {
      transform: translate(832.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    35% {
      transform: translate(699.801035px, -26.390926px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    36.25% {
      transform: translate(693.411987px, -31.390926px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    37.5% {
      transform: translate(693.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    47.5% {
      transform: translate(693.9px, -122.228703px);
    }
    100% {
      transform: translate(693.9px, -122.228703px);
    }
  }
  #e2XfoQw7dmJ12_tr {
    animation: e2XfoQw7dmJ12_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ12_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-73deg);
    }
    35% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e2XfoQw7dmJ12 {
    animation: e2XfoQw7dmJ12_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ12_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    47.5% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ14 {
    animation-name: e2XfoQw7dmJ14__m, e2XfoQw7dmJ14_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e2XfoQw7dmJ14__m {
    0% {
      d: path('M781.258,178.241L781.258,150.682L781.258,149.787317L781.258,148.987318');
    }
    51.25% {
      d: path('M781.258,178.241L781.258,150.682L781.258,149.787317L781.258,148.987318');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    58.75% {
      d: path('M781.258,181.098143L781.258,129.331778L781.258,124.296582L781.258,122.622404');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    60% {
      d: path('M781.258,178.241L781.258,121.682L781,121.428904L775.970714,119.985741');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    75% {
      d: path('M781.258,121.622403L781.258,121.682L628.386,76.7624L628.386,77.3561');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    86.25% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
    100% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
  }
  @keyframes e2XfoQw7dmJ14_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 1;
    }
    91.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ15_to {
    animation: e2XfoQw7dmJ15_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ15_to__to {
    0% {
      transform: translate(846.5px, 51.646297px);
    }
    51.25% {
      transform: translate(846.5px, 51.646297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    58.75% {
      transform: translate(846.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    60% {
      transform: translate(842.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    61.25% {
      transform: translate(832.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    73.75% {
      transform: translate(699.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    75% {
      transform: translate(693.5px, -31.028703px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    76.25% {
      transform: translate(693.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    86.25% {
      transform: translate(693.9px, -120.728703px);
    }
    100% {
      transform: translate(693.9px, -120.728703px);
    }
  }
  #e2XfoQw7dmJ15_tr {
    animation: e2XfoQw7dmJ15_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(-73deg);
    }
    75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e2XfoQw7dmJ15 {
    animation: e2XfoQw7dmJ15_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ15_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 0.7;
    }
    86.25% {
      opacity: 0.7;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ16_to {
    animation: e2XfoQw7dmJ16_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ16_to__to {
    0% {
      transform: translate(846.5px, 51.646297px);
    }
    51.25% {
      transform: translate(846.5px, 51.646297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    58.75% {
      transform: translate(846.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    60% {
      transform: translate(842.798427px, 15.771298px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    61.25% {
      transform: translate(832.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    73.75% {
      transform: translate(699.801035px, -26.390926px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    75% {
      transform: translate(693.411987px, -31.390926px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    76.25% {
      transform: translate(693.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    86.25% {
      transform: translate(693.9px, -122.228703px);
    }
    100% {
      transform: translate(693.9px, -122.228703px);
    }
  }
  #e2XfoQw7dmJ16_tr {
    animation: e2XfoQw7dmJ16_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ16_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(-73deg);
    }
    75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e2XfoQw7dmJ16 {
    animation: e2XfoQw7dmJ16_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ16_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    86.25% {
      opacity: 1;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ22 {
    animation-name: e2XfoQw7dmJ22__m, e2XfoQw7dmJ22_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e2XfoQw7dmJ22__m {
    0% {
      d: path('M590.336,238.051545L590.336,175.993972L590.336001,176.26135L590.093553,175.993973');
    }
    7.5% {
      d: path('M590.336,232.597L590.336,125.817L590.331985,125.817002L588.642295,124.756647');
    }
    21.25% {
      d: path('M590.336,125.761512L590.336,125.817L517.819,80.7272L517.818,79.9168');
    }
    32.5% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,2.52344');
    }
    100% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,2.52344');
    }
  }
  @keyframes e2XfoQw7dmJ22_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    31.25% {
      opacity: 1;
    }
    37.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ23_to {
    animation: e2XfoQw7dmJ23_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ23_to__to {
    0% {
      transform: translate(655.2px, 73.020302px);
    }
    6.25% {
      transform: translate(655.2px, 27.521297px);
    }
    7.5% {
      transform: translate(654.798427px, 21.771297px);
    }
    8.75% {
      transform: translate(649.3352px, 17.841812px);
    }
    20% {
      transform: translate(586.801035px, -20.7px);
    }
    21.25% {
      transform: translate(582.920607px, -27.028703px);
    }
    22.5% {
      transform: translate(582.9px, -33.280793px);
    }
    33.75% {
      transform: translate(582.9px, -115.728703px);
    }
    100% {
      transform: translate(582.9px, -115.728703px);
    }
  }
  #e2XfoQw7dmJ23_tr {
    animation: e2XfoQw7dmJ23_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ23_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    6.25% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(-60deg);
    }
    20% {
      transform: rotate(-60deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e2XfoQw7dmJ23 {
    animation: e2XfoQw7dmJ23_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ23_c_o {
    0% {
      opacity: 0.7;
    }
    33.75% {
      opacity: 0.7;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ24_to {
    animation: e2XfoQw7dmJ24_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ24_to__to {
    0% {
      transform: translate(655.2px, 73.020302px);
    }
    6.25% {
      transform: translate(655.2px, 27.521297px);
    }
    7.5% {
      transform: translate(654.798427px, 21.771297px);
    }
    8.75% {
      transform: translate(648.798427px, 17.25px);
    }
    20% {
      transform: translate(586.801035px, -20.855633px);
    }
    21.25% {
      transform: translate(582.851035px, -27.791881px);
    }
    22.5% {
      transform: translate(582.9px, -33.514307px);
    }
    33.75% {
      transform: translate(582.9px, -115.228703px);
    }
    100% {
      transform: translate(582.9px, -115.228703px);
    }
  }
  #e2XfoQw7dmJ24_tr {
    animation: e2XfoQw7dmJ24_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ24_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    6.25% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(-60deg);
    }
    20% {
      transform: rotate(-60deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e2XfoQw7dmJ24 {
    animation: e2XfoQw7dmJ24_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ24_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    33.75% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ26 {
    animation-name: e2XfoQw7dmJ26__m, e2XfoQw7dmJ26_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e2XfoQw7dmJ26__m {
    0% {
      d: path(
        'M590.336,282.597C590.336,282.597,590.336,240.215895,590.336,240.215895L590.336,241.406448L590.354,240.215895'
      );
    }
    37.5% {
      d: path(
        'M590.336,282.597C590.336,282.597,590.336,240.215895,590.336,240.215895L590.336,241.406448L590.354,240.215895'
      );
    }
    50% {
      d: path(
        'M590.336,232.597C590.336,232.597,590.336,125.817,590.336,125.817L590.336001,127.215895L588.964636,125.817002'
      );
    }
    66.25% {
      d: path('M590.336,125.761512C590.336,125.761512,590.336,125.817,590.336,125.817L517.819,80.7272L517.818,79.9168');
    }
    67.5% {
      d: path(
        'M588.905758,124.876061C588.905758,124.876061,517.818,80.866225,517.818,80.866225L517.819,80.7272L517.818,66.2129'
      );
    }
    77.5% {
      d: path(
        'M517.818,80.866225C517.818,80.866225,517.818,80.550695,517.818,80.550695L517.819,-4.2728L514.818,-6.47656'
      );
    }
    100% {
      d: path(
        'M517.818,80.866225C517.818,80.866225,517.818,80.550695,517.818,80.550695L517.819,-4.2728L514.818,-6.47656'
      );
    }
  }
  @keyframes e2XfoQw7dmJ26_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    76.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ26-stroke-0 {
    animation: e2XfoQw7dmJ26-stroke-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ26-stroke-0__c {
    0% {
      stop-color: #7ea6ff;
    }
    37.5% {
      stop-color: #7ea6ff;
    }
    51.25% {
      stop-color: #7ea6ff;
    }
    66.25% {
      stop-color: #7ea6ff;
    }
    76.25% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #e2XfoQw7dmJ26-stroke-1 {
    animation: e2XfoQw7dmJ26-stroke-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ26-stroke-1__c {
    0% {
      stop-color: #6069ca;
    }
    37.5% {
      stop-color: #6069ca;
    }
    51.25% {
      stop-color: #6069ca;
    }
    66.25% {
      stop-color: #6069ca;
    }
    76.25% {
      stop-color: #6069ca;
    }
    100% {
      stop-color: #6069ca;
    }
  }
  #e2XfoQw7dmJ26-stroke-2 {
    animation: e2XfoQw7dmJ26-stroke-2__c 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ26-stroke-2__c {
    0% {
      stop-color: rgba(64, 73, 168, 0);
    }
    37.5% {
      stop-color: rgba(64, 73, 168, 0);
    }
    51.25% {
      stop-color: rgba(64, 73, 168, 0);
    }
    66.25% {
      stop-color: rgba(64, 73, 168, 0);
    }
    76.25% {
      stop-color: rgba(64, 73, 168, 0);
    }
    100% {
      stop-color: rgba(64, 73, 168, 0);
    }
  }
  #e2XfoQw7dmJ27_to {
    animation: e2XfoQw7dmJ27_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ27_to__to {
    0% {
      transform: translate(655.5px, 137.771297px);
    }
    37.5% {
      transform: translate(655.5px, 137.771297px);
    }
    50% {
      transform: translate(655.5px, 27.771297px);
    }
    51.25% {
      transform: translate(652.798427px, 19.771297px);
    }
    52.5% {
      transform: translate(643.3352px, 13.841812px);
    }
    65% {
      transform: translate(586.801035px, -20.7px);
    }
    66.25% {
      transform: translate(582.920607px, -27.028703px);
    }
    67.5% {
      transform: translate(582.960993px, -38.280793px);
    }
    78.75% {
      transform: translate(582.9px, -116.728703px);
    }
    100% {
      transform: translate(582.9px, -116.728703px);
    }
  }
  #e2XfoQw7dmJ27_tr {
    animation: e2XfoQw7dmJ27_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ27_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(-60deg);
    }
    65% {
      transform: rotate(-60deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e2XfoQw7dmJ27 {
    animation: e2XfoQw7dmJ27_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ27_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 0.7;
    }
    78.75% {
      opacity: 0.7;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ28_to {
    animation: e2XfoQw7dmJ28_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ28_to__to {
    0% {
      transform: translate(655.5px, 137.771297px);
    }
    37.5% {
      transform: translate(655.5px, 137.771297px);
    }
    50% {
      transform: translate(655.5px, 26.271297px);
    }
    51.25% {
      transform: translate(652.798427px, 19.771297px);
    }
    52.5% {
      transform: translate(642.726569px, 13.25px);
    }
    65% {
      transform: translate(586.801035px, -20.855633px);
    }
    66.25% {
      transform: translate(582.911986px, -27.262684px);
    }
    67.5% {
      transform: translate(582.560992px, -38.762684px);
    }
    78.75% {
      transform: translate(582.9px, -118.228703px);
    }
    100% {
      transform: translate(582.9px, -118.228703px);
    }
  }
  #e2XfoQw7dmJ28_tr {
    animation: e2XfoQw7dmJ28_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ28_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(-60deg);
    }
    65% {
      transform: rotate(-60deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e2XfoQw7dmJ28 {
    animation: e2XfoQw7dmJ28_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ28_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    78.75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ30 {
    animation-name: e2XfoQw7dmJ30__m, e2XfoQw7dmJ30_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e2XfoQw7dmJ30__m {
    0% {
      d: path('M590.336,272.597L590.336,245.215895L590.336,245.215895L590.354,244.215895');
    }
    90% {
      d: path('M590.336,272.597L590.336,245.215895L590.336,245.215895L590.354,244.215895');
    }
    100% {
      d: path('M590.336,239.869727L590.336,177.052962L590.336001,176.943168L590.469859,177.052963');
    }
  }
  @keyframes e2XfoQw7dmJ30_c_o {
    0% {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    91.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #e2XfoQw7dmJ31_to {
    animation: e2XfoQw7dmJ31_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ31_to__to {
    0% {
      transform: translate(655.5px, 137.646297px);
    }
    90% {
      transform: translate(655.5px, 137.646297px);
    }
    100% {
      transform: translate(655.5px, 71.825773px);
    }
  }
  #e2XfoQw7dmJ31 {
    animation: e2XfoQw7dmJ31_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ31_c_o {
    0% {
      opacity: 0;
    }
    88.75% {
      opacity: 0;
    }
    90% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #e2XfoQw7dmJ32_to {
    animation: e2XfoQw7dmJ32_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ32_to__to {
    0% {
      transform: translate(655.5px, 137.646297px);
    }
    90% {
      transform: translate(655.5px, 137.646297px);
    }
    100% {
      transform: translate(655.5px, 71.825773px);
    }
  }
  #e2XfoQw7dmJ32 {
    animation: e2XfoQw7dmJ32_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ32_c_o {
    0% {
      opacity: 0;
    }
    88.75% {
      opacity: 0;
    }
    90% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #e2XfoQw7dmJ37 {
    animation-name: e2XfoQw7dmJ37__m, e2XfoQw7dmJ37_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e2XfoQw7dmJ37__m {
    0% {
      d: path(
        'M175.911985,188.439636L175.911985,130.987317L175.911985,130.987317C175.911985,130.987317,175.7109,130.504307,175.7109,130.504307'
      );
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    28.75% {
      d: path(
        'M175.911985,68.439636L175.911985,-15.012683L175.911985,-15.012683C175.911985,-15.012683,175.711985,-16.012683,175.711985,-16.012683'
      );
    }
    100% {
      d: path(
        'M175.911985,68.439636L175.911985,-15.012683L175.911985,-15.012683C175.911985,-15.012683,175.711985,-16.012683,175.711985,-16.012683'
      );
    }
  }
  @keyframes e2XfoQw7dmJ37_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    23.75% {
      opacity: 1;
    }
    33.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ38_to {
    animation: e2XfoQw7dmJ38_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ38_to__to {
    0% {
      transform: translate(239.8px, 25.504306px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    30% {
      transform: translate(239.8px, -124.495694px);
    }
    100% {
      transform: translate(239.8px, -124.495694px);
    }
  }
  #e2XfoQw7dmJ38 {
    animation: e2XfoQw7dmJ38_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ38_c_o {
    0% {
      opacity: 0.7;
    }
    30% {
      opacity: 0.7;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ39_to {
    animation: e2XfoQw7dmJ39_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ39_to__to {
    0% {
      transform: translate(239.8px, 25.504306px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    30% {
      transform: translate(239.8px, -124.495694px);
    }
    100% {
      transform: translate(239.8px, -124.495694px);
    }
  }
  #e2XfoQw7dmJ39 {
    animation: e2XfoQw7dmJ39_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ39_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ41 {
    animation-name: e2XfoQw7dmJ41__m, e2XfoQw7dmJ41_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e2XfoQw7dmJ41__m {
    0% {
      d: path(
        'M175.911985,208.439636L175.911985,150.987317L175.911985,150.987317C175.911985,150.987317,175.7109,150.504307,175.7109,150.504307'
      );
    }
    43.75% {
      d: path(
        'M175.911985,208.439636L175.911985,150.987317L175.911985,150.987317C175.911985,150.987317,175.7109,150.504307,175.7109,150.504307'
      );
    }
    78.75% {
      d: path(
        'M175.911985,68.439636L175.911985,-15.012683L175.911985,-15.012683C175.911985,-15.012683,175.711985,-16.012683,175.711985,-16.012683'
      );
    }
    100% {
      d: path(
        'M175.911985,68.439636L175.911985,-15.012683L175.911985,-15.012683C175.911985,-15.012683,175.711985,-16.012683,175.711985,-16.012683'
      );
    }
  }
  @keyframes e2XfoQw7dmJ41_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 1;
    }
    73.75% {
      opacity: 1;
    }
    83.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ42_to {
    animation: e2XfoQw7dmJ42_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ42_to__to {
    0% {
      transform: translate(239.8px, 45.504306px);
    }
    43.75% {
      transform: translate(239.8px, 45.504306px);
    }
    80% {
      transform: translate(239.8px, -124.495694px);
    }
    100% {
      transform: translate(239.8px, -124.495694px);
    }
  }
  #e2XfoQw7dmJ42 {
    animation: e2XfoQw7dmJ42_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ42_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 0.7;
    }
    80% {
      opacity: 0.7;
    }
    81.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ43_to {
    animation: e2XfoQw7dmJ43_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ43_to__to {
    0% {
      transform: translate(239.8px, 45.504306px);
    }
    43.75% {
      transform: translate(239.8px, 45.504306px);
    }
    80% {
      transform: translate(239.8px, -124.495694px);
    }
    100% {
      transform: translate(239.8px, -124.495694px);
    }
  }
  #e2XfoQw7dmJ43 {
    animation: e2XfoQw7dmJ43_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ43_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    81.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ45 {
    animation-name: e2XfoQw7dmJ45__m, e2XfoQw7dmJ45_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e2XfoQw7dmJ45__m {
    0% {
      d: path(
        'M175.911985,208.439636L175.911985,150.987317L175.911985,150.987317C175.911985,150.987317,175.7109,150.504307,175.7109,150.504307'
      );
    }
    93.75% {
      d: path(
        'M175.911985,208.439636L175.911985,150.987317L175.911985,150.987317C175.911985,150.987317,175.7109,150.504307,175.7109,150.504307'
      );
    }
    100% {
      d: path(
        'M175.911985,188.439636L175.911985,130.987317L175.911985,130.987317C175.911985,130.987317,175.7109,130.504307,175.7109,130.504307'
      );
    }
  }
  @keyframes e2XfoQw7dmJ45_c_o {
    0% {
      opacity: 0;
    }
    92.5% {
      opacity: 0;
    }
    93.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #e2XfoQw7dmJ46_to {
    animation: e2XfoQw7dmJ46_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ46_to__to {
    0% {
      transform: translate(239.8px, 45.504306px);
    }
    93.75% {
      transform: translate(239.8px, 45.504306px);
    }
    100% {
      transform: translate(239.8px, 25.504306px);
    }
  }
  #e2XfoQw7dmJ46 {
    animation: e2XfoQw7dmJ46_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ46_c_o {
    0% {
      opacity: 0;
    }
    92.5% {
      opacity: 0;
    }
    93.75% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #e2XfoQw7dmJ47_to {
    animation: e2XfoQw7dmJ47_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ47_to__to {
    0% {
      transform: translate(239.8px, 45.504306px);
    }
    93.75% {
      transform: translate(239.8px, 45.504306px);
    }
    100% {
      transform: translate(239.8px, 25.504306px);
    }
  }
  #e2XfoQw7dmJ47 {
    animation: e2XfoQw7dmJ47_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ47_c_o {
    0% {
      opacity: 0;
    }
    92.5% {
      opacity: 0;
    }
    93.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #e2XfoQw7dmJ53 {
    animation-name: e2XfoQw7dmJ53__m, e2XfoQw7dmJ53_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e2XfoQw7dmJ53__m {
    0% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    6.25% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    18.75% {
      d: path('M292.146,229.88137L292.146,123.887759L292.150299,124.838945L292.150299,124.853993');
    }
    20% {
      d: path('M292.146,229.76L292.146,121.682L292.146003,121.682L292.411986,121.546257');
    }
    32.5% {
      d: path('M292.146,121.885507L292.146,121.682L370.374,76.7761L370.374,77.208121');
    }
    48.75% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
    100% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
  }
  @keyframes e2XfoQw7dmJ53_c_o {
    0% {
      opacity: 0;
    }
    3.75% {
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    43.75% {
      opacity: 1;
    }
    53.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ54_to {
    animation: e2XfoQw7dmJ54_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ54_to__to {
    0% {
      transform: translate(358.05px, 139.246297px);
    }
    3.75% {
      transform: translate(358.05px, 139.246297px);
    }
    5% {
      transform: translate(357.2px, 138.621035px);
    }
    18.75% {
      transform: translate(357.2px, 20.521297px);
    }
    20% {
      transform: translate(361.798427px, 14.771297px);
    }
    21.25% {
      transform: translate(367.3352px, 10.841812px);
    }
    32.5% {
      transform: translate(432.801035px, -26.4px);
    }
    33.75% {
      transform: translate(435.920607px, -31.028703px);
    }
    35% {
      transform: translate(435.7px, -38.280793px);
    }
    48.75% {
      transform: translate(435.7px, -120.728703px);
    }
    100% {
      transform: translate(435.7px, -120.728703px);
    }
  }
  #e2XfoQw7dmJ54_tr {
    animation: e2XfoQw7dmJ54_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ54_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    18.75% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(65deg);
    }
    32.5% {
      transform: rotate(65deg);
    }
    33.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e2XfoQw7dmJ54 {
    animation: e2XfoQw7dmJ54_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ54_c_o {
    0% {
      opacity: 0;
    }
    2.5% {
      opacity: 0;
    }
    3.75% {
      opacity: 0.7;
    }
    48.75% {
      opacity: 0.7;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ55_to {
    animation: e2XfoQw7dmJ55_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ55_to__to {
    0% {
      transform: translate(358.050001px, 139.246297px);
    }
    3.75% {
      transform: translate(358.050001px, 139.246297px);
    }
    5% {
      transform: translate(357.2px, 138.621035px);
    }
    18.75% {
      transform: translate(357.2px, 20.521297px);
    }
    20% {
      transform: translate(362.798427px, 14.271297px);
    }
    21.25% {
      transform: translate(368.202724px, 10.173441px);
    }
    32.5% {
      transform: translate(433.202724px, -26.826559px);
    }
    33.75% {
      transform: translate(435.851035px, -31.791881px);
    }
    35% {
      transform: translate(435.7px, -39.514307px);
    }
    48.75% {
      transform: translate(435.7px, -121.228703px);
    }
    100% {
      transform: translate(435.7px, -121.228703px);
    }
  }
  #e2XfoQw7dmJ55_tr {
    animation: e2XfoQw7dmJ55_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ55_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    18.75% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(65deg);
    }
    32.5% {
      transform: rotate(65deg);
    }
    33.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e2XfoQw7dmJ55 {
    animation: e2XfoQw7dmJ55_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ55_c_o {
    0% {
      opacity: 0;
    }
    2.5% {
      opacity: 0;
    }
    3.75% {
      opacity: 1;
    }
    48.75% {
      opacity: 0.8;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ57 {
    animation-name: e2XfoQw7dmJ57__m, e2XfoQw7dmJ57_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e2XfoQw7dmJ57__m {
    0% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    51.25% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    66.25% {
      d: path('M292.146,230.529231L292.146,125.661912L292.173234,136.670644L292.173233,136.766017');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    67.5% {
      d: path('M292.146,229.76L292.146,121.682L294.323434,120.407665L296.798427,119.11862');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    81.25% {
      d: path('M292.146,121.885507L292.146,121.682L370.374,76.7761L370.374,77.208121');
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    97.5% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
    100% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
  }
  @keyframes e2XfoQw7dmJ57_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    92.5% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ58_to {
    animation: e2XfoQw7dmJ58_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ58_to__to {
    0% {
      transform: translate(357px, 139.246297px);
    }
    51.25% {
      transform: translate(357px, 139.246297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    66.25% {
      transform: translate(357px, 23.521297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    67.5% {
      transform: translate(361.798427px, 13.771297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.75% {
      transform: translate(367.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    80% {
      transform: translate(432.801035px, -27.4px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    81.25% {
      transform: translate(435.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    82.5% {
      transform: translate(435.7px, -38.280793px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    96.25% {
      transform: translate(435.7px, -120.728703px);
    }
    100% {
      transform: translate(435.7px, -120.728703px);
    }
  }
  #e2XfoQw7dmJ58_tr {
    animation: e2XfoQw7dmJ58_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ58_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(62deg);
    }
    68.75% {
      transform: rotate(62deg);
    }
    80% {
      transform: rotate(62deg);
    }
    81.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e2XfoQw7dmJ58 {
    animation: e2XfoQw7dmJ58_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ58_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 0.7;
    }
    96.25% {
      opacity: 0.7;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ59_to {
    animation: e2XfoQw7dmJ59_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ59_to__to {
    0% {
      transform: translate(357px, 139.246297px);
    }
    51.25% {
      transform: translate(357px, 139.246297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    66.25% {
      transform: translate(357px, 23.521297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    67.5% {
      transform: translate(361.798427px, 13.771297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    68.75% {
      transform: translate(367.3352px, 10.841813px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    80% {
      transform: translate(432.801035px, -27.399999px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    81.25% {
      transform: translate(435.851035px, -31.028703px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    82.5% {
      transform: translate(435.7px, -39.514307px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    96.25% {
      transform: translate(435.7px, -121.228703px);
    }
    100% {
      transform: translate(435.7px, -121.228703px);
    }
  }
  #e2XfoQw7dmJ59_tr {
    animation: e2XfoQw7dmJ59_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ59_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(62deg);
    }
    68.75% {
      transform: rotate(62deg);
    }
    80% {
      transform: rotate(62deg);
    }
    81.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e2XfoQw7dmJ59 {
    animation: e2XfoQw7dmJ59_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ59_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    96.25% {
      opacity: 0.8;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ64 {
    animation-name: e2XfoQw7dmJ64__m, e2XfoQw7dmJ64_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e2XfoQw7dmJ64__m {
    0% {
      d: path(
        'M96.7109,144.776311L96.7109,133.056031L96.7109,133.056031C96.7109,133.056031,95.7109,132.056031,95.7109,132.056031'
      );
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    3.75% {
      d: path(
        'M96.7109,145.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.908201,121.683001,96.908201,121.683001'
      );
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    17.5% {
      d: path(
        'M96.7109,124.229631L96.7109,121.68L259.817002,76.7624C259.817002,76.7624,259.817002,76.767317,259.817002,76.767317'
      );
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    18.75% {
      d: path(
        'M96.7109,122.271298L96.7109,121.68L259.817,76.767317C259.817,76.767317,259.817001,75.687789,259.817001,75.687789'
      );
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    31.25% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
    100% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
  }
  @keyframes e2XfoQw7dmJ64_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    28.75% {
      opacity: 1;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ64-stroke {
    animation: e2XfoQw7dmJ64-stroke__t 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ64-stroke__t {
    0% {
      transform: matrix(0, -40.465441, 40.465441, 0, 108.00414, 168.364292);
    }
    2.5% {
      transform: matrix(0, -40.465441, 40.465441, 0, 108.00414, 168.364292);
    }
    11.25% {
      transform: matrix(72.280237, -41.25805, 41.25805, 72.280237, 104.06515, 155.504307);
    }
    21.25% {
      transform: matrix(107.871354, -52.934093, 52.934093, 107.871354, 149.428645, 114.246257);
    }
    30% {
      transform: matrix(32.424197, -100.266049, 100.266049, 32.424197, 215.087645, 97.17179);
    }
    100% {
      transform: matrix(32.424197, -100.266049, 100.266049, 32.424197, 215.087645, 97.17179);
    }
  }
  #e2XfoQw7dmJ64-stroke-0 {
    animation: e2XfoQw7dmJ64-stroke-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ64-stroke-0__c {
    0% {
      stop-color: rgba(96, 105, 202, 0);
    }
    2.5% {
      stop-color: rgba(96, 105, 202, 0);
    }
    11.25% {
      stop-color: rgba(96, 105, 202, 0);
    }
    21.25% {
      stop-color: rgba(96, 105, 202, 0);
    }
    30% {
      stop-color: rgba(96, 105, 202, 0);
    }
    100% {
      stop-color: rgba(96, 105, 202, 0);
    }
  }
  #e2XfoQw7dmJ64-stroke-1 {
    animation: e2XfoQw7dmJ64-stroke-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ64-stroke-1__c {
    0% {
      stop-color: #7ea6ff;
    }
    2.5% {
      stop-color: #7ea6ff;
    }
    11.25% {
      stop-color: #7ea6ff;
    }
    21.25% {
      stop-color: #7ea6ff;
    }
    30% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #e2XfoQw7dmJ65_to {
    animation: e2XfoQw7dmJ65_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ65_to__to {
    0% {
      transform: translate(161.798694px, 25px);
    }
    2.5% {
      transform: translate(161.798427px, 21.771297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    3.75% {
      transform: translate(166.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    17.5% {
      transform: translate(322.801035px, -27.448195px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    18.75% {
      transform: translate(324.811985px, -31.712684px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    32.5% {
      transform: translate(324.9px, -120.728703px);
    }
    100% {
      transform: translate(324.9px, -120.728703px);
    }
  }
  #e2XfoQw7dmJ65_tr {
    animation: e2XfoQw7dmJ65_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ65_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    2.5% {
      transform: rotate(0deg);
    }
    3.75% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(73deg);
    }
    18.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e2XfoQw7dmJ65 {
    animation: e2XfoQw7dmJ65_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ65_c_o {
    0% {
      opacity: 0.7;
    }
    32.5% {
      opacity: 0.7;
    }
    33.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ66_to {
    animation: e2XfoQw7dmJ66_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ66_to__to {
    0% {
      transform: translate(161.798694px, 25px);
    }
    2.5% {
      transform: translate(161.798427px, 21.687316px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    3.75% {
      transform: translate(166.798427px, 15.671297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    17.5% {
      transform: translate(321.801035px, -27.560064px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    18.75% {
      transform: translate(324.811985px, -31.791881px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    32.5% {
      transform: translate(324.9px, -121.228703px);
    }
    100% {
      transform: translate(324.9px, -121.228703px);
    }
  }
  #e2XfoQw7dmJ66_tr {
    animation: e2XfoQw7dmJ66_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ66_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    2.5% {
      transform: rotate(0deg);
    }
    3.75% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(73deg);
    }
    18.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e2XfoQw7dmJ66 {
    animation: e2XfoQw7dmJ66_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ66_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    32.5% {
      opacity: 1;
    }
    33.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ68 {
    animation-name: e2XfoQw7dmJ68__m, e2XfoQw7dmJ68_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e2XfoQw7dmJ68__m {
    0% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    42.5% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    48.75% {
      d: path(
        'M96.7109,144.776311L96.7109,139.694875L96.839592,139.321751C96.839592,139.321751,96.867036,140.071751,96.867036,140.071751'
      );
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    52.5% {
      d: path(
        'M96.7109,145.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.908201,121.683001,96.908201,121.683001'
      );
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    66.25% {
      d: path(
        'M96.7109,124.229631L96.7109,121.68L259.817002,76.7624C259.817002,76.7624,259.817002,76.767317,259.817002,76.767317'
      );
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    67.5% {
      d: path(
        'M96.7109,122.271298L96.7109,121.68L259.817,76.767317C259.817,76.767317,259.817001,75.687789,259.817001,75.687789'
      );
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    80% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
    100% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
  }
  @keyframes e2XfoQw7dmJ68_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    77.5% {
      opacity: 1;
    }
    85% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ68-stroke {
    animation: e2XfoQw7dmJ68-stroke__t 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ68-stroke__t {
    0% {
      transform: matrix(0, -40.465441, 40.465441, 0, 108.00414, 168.364292);
    }
    51.25% {
      transform: matrix(0, -40.465441, 40.465441, 0, 108.00414, 168.364292);
    }
    60% {
      transform: matrix(72.280237, -41.25805, 41.25805, 72.280237, 104.06515, 155.504307);
    }
    70% {
      transform: matrix(107.871354, -52.934093, 52.934093, 107.871354, 149.428645, 114.246257);
    }
    78.75% {
      transform: matrix(32.424197, -100.266049, 100.266049, 32.424197, 215.087645, 97.17179);
    }
    100% {
      transform: matrix(32.424197, -100.266049, 100.266049, 32.424197, 215.087645, 97.17179);
    }
  }
  #e2XfoQw7dmJ68-stroke-0 {
    animation: e2XfoQw7dmJ68-stroke-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ68-stroke-0__c {
    0% {
      stop-color: rgba(96, 105, 202, 0);
    }
    51.25% {
      stop-color: rgba(96, 105, 202, 0);
    }
    60% {
      stop-color: rgba(96, 105, 202, 0);
    }
    70% {
      stop-color: rgba(96, 105, 202, 0);
    }
    78.75% {
      stop-color: rgba(96, 105, 202, 0);
    }
    100% {
      stop-color: rgba(96, 105, 202, 0);
    }
  }
  #e2XfoQw7dmJ68-stroke-1 {
    animation: e2XfoQw7dmJ68-stroke-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ68-stroke-1__c {
    0% {
      stop-color: #7ea6ff;
    }
    51.25% {
      stop-color: #7ea6ff;
    }
    60% {
      stop-color: #7ea6ff;
    }
    70% {
      stop-color: #7ea6ff;
    }
    78.75% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #e2XfoQw7dmJ69_to {
    animation: e2XfoQw7dmJ69_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ69_to__to {
    0% {
      transform: translate(161.8px, 50.787316px);
    }
    42.5% {
      transform: translate(161.8px, 50.787316px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    51.25% {
      transform: translate(161.798427px, 21.771297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    52.5% {
      transform: translate(166.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    66.25% {
      transform: translate(322.801035px, -27.448195px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    67.5% {
      transform: translate(324.811985px, -31.712684px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    81.25% {
      transform: translate(324.9px, -120.728703px);
    }
    100% {
      transform: translate(324.9px, -120.728703px);
    }
  }
  #e2XfoQw7dmJ69_tr {
    animation: e2XfoQw7dmJ69_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ69_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(73deg);
    }
    66.25% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e2XfoQw7dmJ69 {
    animation: e2XfoQw7dmJ69_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ69_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 0.7;
    }
    81.25% {
      opacity: 0.7;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ70_to {
    animation: e2XfoQw7dmJ70_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ70_to__to {
    0% {
      transform: translate(161.8px, 50.787316px);
    }
    42.5% {
      transform: translate(161.8px, 50.787316px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    51.25% {
      transform: translate(161.798427px, 21.687316px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    52.5% {
      transform: translate(166.798427px, 15.671297px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    66.25% {
      transform: translate(321.801035px, -27.560064px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    67.5% {
      transform: translate(324.811985px, -31.791881px);
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    81.25% {
      transform: translate(324.9px, -121.228703px);
    }
    100% {
      transform: translate(324.9px, -121.228703px);
    }
  }
  #e2XfoQw7dmJ70_tr {
    animation: e2XfoQw7dmJ70_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ70_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(73deg);
    }
    66.25% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e2XfoQw7dmJ70 {
    animation: e2XfoQw7dmJ70_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ70_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    81.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e2XfoQw7dmJ72 {
    animation-name: e2XfoQw7dmJ72__m, e2XfoQw7dmJ72_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e2XfoQw7dmJ72__m {
    0% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    96.25% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    100% {
      d: path(
        'M96.7109,144.112987L96.7109,141.038125L96.867035,140.414251C96.867035,140.414251,96.839592,141.664251,96.839592,141.664251'
      );
    }
  }
  @keyframes e2XfoQw7dmJ72_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #e2XfoQw7dmJ73_to {
    animation: e2XfoQw7dmJ73_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ73_to__to {
    0% {
      transform: translate(161.8px, 49.521297px);
    }
    96.25% {
      transform: translate(161.8px, 49.521297px);
    }
    100% {
      transform: translate(161.798427px, 24.987316px);
    }
  }
  #e2XfoQw7dmJ73 {
    animation: e2XfoQw7dmJ73_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ73_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #e2XfoQw7dmJ74_to {
    animation: e2XfoQw7dmJ74_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ74_to__to {
    0% {
      transform: translate(161.8px, 49.521297px);
    }
    96.25% {
      transform: translate(161.8px, 49.521297px);
    }
    100% {
      transform: translate(161.798427px, 24.987316px);
    }
  }
  #e2XfoQw7dmJ74 {
    animation: e2XfoQw7dmJ74_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e2XfoQw7dmJ74_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`;

export default FeedSourcesBg5;
