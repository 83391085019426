// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesBg11 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)', // Replace all <stop id="...-stroke-1" stopColor="#initial-color" /> with this value
  };

  return (
    <AnimatedSvg
      id="eGeRfHEqDjl1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 885 228"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="885"
      height="228"
      className="source-background"
      {...props}
    >
      <defs>
        <filter id="eGeRfHEqDjl8-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl8-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eGeRfHEqDjl8-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl8-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eGeRfHEqDjl8-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl8-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl8-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl8-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eGeRfHEqDjl9-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eGeRfHEqDjl9-stroke-0"
            offset="0%"
            stopColor={selected === 10 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eGeRfHEqDjl9-stroke-1"
            offset="49%"
            stopColor={selected === 10 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eGeRfHEqDjl9-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eGeRfHEqDjl10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl10-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eGeRfHEqDjl10-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl10-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eGeRfHEqDjl10-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl10-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl10-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl10-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eGeRfHEqDjl10-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl12-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl12-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eGeRfHEqDjl12-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl12-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eGeRfHEqDjl12-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl12-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl12-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl12-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eGeRfHEqDjl13-stroke"
          x1="-4235.389929"
          y1="0.088235"
          x2="-4235.389929"
          y2="0.74672"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eGeRfHEqDjl13-stroke-0"
            offset="0%"
            stopColor={selected === 10 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eGeRfHEqDjl13-stroke-1"
            offset="50%"
            stopColor={selected === 10 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eGeRfHEqDjl13-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eGeRfHEqDjl14-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl14-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eGeRfHEqDjl14-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl14-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eGeRfHEqDjl14-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl14-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl14-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl14-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eGeRfHEqDjl14-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl16-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl16-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eGeRfHEqDjl16-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl16-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eGeRfHEqDjl16-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl16-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl16-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl16-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eGeRfHEqDjl17-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="matrix(-0.000001 0.458519 -0.458519 -0.000001 -9.780868 0.117414)"
        >
          <stop
            id="eGeRfHEqDjl17-stroke-0"
            offset="0%"
            stopColor={selected === 10 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eGeRfHEqDjl17-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <linearGradient
          id="eGeRfHEqDjl17-stroke-g1"
          x1="850.959062"
          y1="149.281687"
          x2="850.959062"
          y2="194.179003"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eGeRfHEqDjl17-stroke-g1-0" offset="0%" stopColor="#7ea6ff" />
          <stop id="eGeRfHEqDjl17-stroke-g1-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <linearGradient
          id="eGeRfHEqDjl17-stroke-g2"
          x1="-0.310059"
          y1="0.106522"
          x2="-0.345091"
          y2="0.73869"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eGeRfHEqDjl17-stroke-g2-0" offset="0%" stopColor="#7ea6ff" />
          <stop id="eGeRfHEqDjl17-stroke-g2-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eGeRfHEqDjl18-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl18-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eGeRfHEqDjl18-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl18-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eGeRfHEqDjl18-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl18-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl18-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl18-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eGeRfHEqDjl18-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl23-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl23-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eGeRfHEqDjl23-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl23-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eGeRfHEqDjl23-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl23-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl23-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl23-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eGeRfHEqDjl24-stroke"
          x1="-2.886156"
          y1="0.111996"
          x2="-2.886156"
          y2="1.411378"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eGeRfHEqDjl24-stroke-0"
            offset="0%"
            stopColor={selected === 9 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eGeRfHEqDjl24-stroke-1"
            offset="50%"
            stopColor={selected === 9 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eGeRfHEqDjl24-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eGeRfHEqDjl25-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl25-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eGeRfHEqDjl25-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl25-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eGeRfHEqDjl25-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl25-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl25-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl25-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eGeRfHEqDjl25-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl27-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl27-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eGeRfHEqDjl27-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl27-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eGeRfHEqDjl27-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl27-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl27-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl27-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eGeRfHEqDjl28-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eGeRfHEqDjl28-stroke-0"
            offset="0%"
            stopColor={selected === 9 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eGeRfHEqDjl28-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eGeRfHEqDjl29-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl29-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eGeRfHEqDjl29-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl29-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eGeRfHEqDjl29-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl29-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl29-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl29-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eGeRfHEqDjl29-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl35-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl35-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eGeRfHEqDjl35-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl35-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eGeRfHEqDjl35-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl35-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl35-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl35-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eGeRfHEqDjl36-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eGeRfHEqDjl36-stroke-0"
            offset="0%"
            stopColor={selected === 8 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eGeRfHEqDjl36-stroke-1"
            offset="51%"
            stopColor={selected === 8 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eGeRfHEqDjl36-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eGeRfHEqDjl37-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl37-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eGeRfHEqDjl37-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl37-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eGeRfHEqDjl37-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl37-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl37-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl37-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eGeRfHEqDjl37-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl39-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl39-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eGeRfHEqDjl39-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl39-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eGeRfHEqDjl39-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl39-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl39-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl39-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eGeRfHEqDjl40-stroke"
          x1="-1560.012319"
          y1="0.037643"
          x2="-1560.012319"
          y2="0.934967"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eGeRfHEqDjl40-stroke-0"
            offset="0%"
            stopColor={selected === 8 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eGeRfHEqDjl40-stroke-1"
            offset="50%"
            stopColor={selected === 8 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eGeRfHEqDjl40-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eGeRfHEqDjl41-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl41-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eGeRfHEqDjl41-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl41-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eGeRfHEqDjl41-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl41-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl41-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl41-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eGeRfHEqDjl41-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl43-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl43-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eGeRfHEqDjl43-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl43-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eGeRfHEqDjl43-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl43-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl43-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl43-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eGeRfHEqDjl44-stroke"
          x1="-477.646532"
          y1="0.059526"
          x2="-477.646532"
          y2="0.930195"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eGeRfHEqDjl44-stroke-0"
            offset="0%"
            stopColor={selected === 8 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eGeRfHEqDjl44-stroke-1"
            offset="52%"
            stopColor={selected === 8 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eGeRfHEqDjl44-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eGeRfHEqDjl45-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl45-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eGeRfHEqDjl45-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl45-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eGeRfHEqDjl45-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl45-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl45-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl45-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eGeRfHEqDjl45-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl50-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl50-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eGeRfHEqDjl50-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl50-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eGeRfHEqDjl50-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl50-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl50-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl50-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eGeRfHEqDjl51-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eGeRfHEqDjl51-stroke-0"
            offset="0%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eGeRfHEqDjl51-stroke-1"
            offset="51%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eGeRfHEqDjl51-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eGeRfHEqDjl52-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl52-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eGeRfHEqDjl52-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl52-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eGeRfHEqDjl52-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl52-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl52-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl52-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eGeRfHEqDjl52-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl54-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl54-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eGeRfHEqDjl54-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl54-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eGeRfHEqDjl54-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl54-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl54-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl54-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eGeRfHEqDjl55-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eGeRfHEqDjl55-stroke-0"
            offset="0%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eGeRfHEqDjl55-stroke-1"
            offset="50%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eGeRfHEqDjl55-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eGeRfHEqDjl56-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl56-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eGeRfHEqDjl56-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl56-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eGeRfHEqDjl56-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl56-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl56-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl56-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eGeRfHEqDjl56-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl62-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl62-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eGeRfHEqDjl62-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl62-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eGeRfHEqDjl62-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl62-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl62-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl62-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eGeRfHEqDjl63-stroke"
          x1="41.272549"
          y1="0.100244"
          x2="41.272549"
          y2="0.893475"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eGeRfHEqDjl63-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eGeRfHEqDjl63-stroke-1"
            offset="51%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eGeRfHEqDjl63-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eGeRfHEqDjl64-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl64-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eGeRfHEqDjl64-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl64-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eGeRfHEqDjl64-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl64-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl64-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl64-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eGeRfHEqDjl64-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl66-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl66-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eGeRfHEqDjl66-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl66-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eGeRfHEqDjl66-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl66-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl66-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl66-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eGeRfHEqDjl67-stroke"
          x1="-11.911945"
          y1="0.057201"
          x2="-11.911945"
          y2="0.959828"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eGeRfHEqDjl67-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eGeRfHEqDjl67-stroke-1"
            offset="52%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eGeRfHEqDjl67-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eGeRfHEqDjl68-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl68-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eGeRfHEqDjl68-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl68-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eGeRfHEqDjl68-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl68-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl68-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl68-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eGeRfHEqDjl68-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl70-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl70-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eGeRfHEqDjl70-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl70-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eGeRfHEqDjl70-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl70-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl70-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl70-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eGeRfHEqDjl71-stroke"
          x1="-88.611899"
          y1="0.064793"
          x2="-88.611899"
          y2="0.892015"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eGeRfHEqDjl71-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eGeRfHEqDjl71-stroke-1"
            offset="53%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eGeRfHEqDjl71-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eGeRfHEqDjl72-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl72-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eGeRfHEqDjl72-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl72-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eGeRfHEqDjl72-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl72-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl72-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl72-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eGeRfHEqDjl72-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl77-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl77-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eGeRfHEqDjl77-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl77-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eGeRfHEqDjl77-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl77-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl77-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl77-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eGeRfHEqDjl78-stroke"
          x1="113.314047"
          y1="0.231324"
          x2="113.314047"
          y2="1.171339"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eGeRfHEqDjl78-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eGeRfHEqDjl78-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eGeRfHEqDjl79-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl79-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eGeRfHEqDjl79-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl79-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eGeRfHEqDjl79-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl79-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl79-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl79-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eGeRfHEqDjl79-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl81-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl81-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eGeRfHEqDjl81-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl81-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eGeRfHEqDjl81-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl81-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl81-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl81-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eGeRfHEqDjl82-stroke"
          x1="113.314047"
          y1="0.231324"
          x2="113.314047"
          y2="1.171339"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eGeRfHEqDjl82-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eGeRfHEqDjl82-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eGeRfHEqDjl83-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl83-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eGeRfHEqDjl83-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl83-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eGeRfHEqDjl83-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl83-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl83-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl83-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eGeRfHEqDjl83-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl89-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl89-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eGeRfHEqDjl89-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl89-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eGeRfHEqDjl89-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl89-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl89-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl89-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eGeRfHEqDjl90-stroke"
          x1="0"
          y1="0.041281"
          x2="0.060771"
          y2="1.028927"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eGeRfHEqDjl90-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eGeRfHEqDjl90-stroke-1"
            offset="52%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eGeRfHEqDjl90-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eGeRfHEqDjl91-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl91-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eGeRfHEqDjl91-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl91-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eGeRfHEqDjl91-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl91-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl91-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl91-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eGeRfHEqDjl91-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl93-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl93-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eGeRfHEqDjl93-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl93-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eGeRfHEqDjl93-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl93-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl93-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl93-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eGeRfHEqDjl94-stroke"
          x1="460.559385"
          y1="-0.001476"
          x2="460.559385"
          y2="0.918877"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eGeRfHEqDjl94-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eGeRfHEqDjl94-stroke-1"
            offset="52%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eGeRfHEqDjl94-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eGeRfHEqDjl95-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl95-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eGeRfHEqDjl95-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl95-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eGeRfHEqDjl95-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl95-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl95-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl95-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eGeRfHEqDjl95-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl100-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl100-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eGeRfHEqDjl100-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl100-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eGeRfHEqDjl100-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl100-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl100-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl100-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eGeRfHEqDjl101-stroke"
          x1="4.189549"
          y1="0.17152"
          x2="4.189549"
          y2="1.293981"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eGeRfHEqDjl101-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eGeRfHEqDjl101-stroke-1"
            offset="52%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eGeRfHEqDjl101-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eGeRfHEqDjl102-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl102-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eGeRfHEqDjl102-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl102-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eGeRfHEqDjl102-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl102-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl102-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl102-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eGeRfHEqDjl102-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl104-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl104-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eGeRfHEqDjl104-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl104-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eGeRfHEqDjl104-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl104-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl104-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl104-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eGeRfHEqDjl105-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.021396"
          y2="1.049288"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eGeRfHEqDjl105-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eGeRfHEqDjl105-stroke-1"
            offset="53%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eGeRfHEqDjl105-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eGeRfHEqDjl106-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl106-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eGeRfHEqDjl106-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl106-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eGeRfHEqDjl106-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl106-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl106-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl106-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eGeRfHEqDjl106-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl112-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl112-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eGeRfHEqDjl112-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl112-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eGeRfHEqDjl112-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl112-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl112-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl112-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eGeRfHEqDjl113-stroke"
          x1="10.184635"
          y1="0.052868"
          x2="10.184635"
          y2="0.888108"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eGeRfHEqDjl113-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eGeRfHEqDjl113-stroke-1" offset="100%" stopColor="rgba(119,151,242,0)" />
        </linearGradient>
        <filter id="eGeRfHEqDjl114-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl114-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eGeRfHEqDjl114-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl114-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eGeRfHEqDjl114-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl114-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl114-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl114-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eGeRfHEqDjl114-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl116-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl116-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eGeRfHEqDjl116-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl116-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eGeRfHEqDjl116-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl116-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl116-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl116-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eGeRfHEqDjl117-stroke"
          x1="1206.897185"
          y1="0.101802"
          x2="1206.897185"
          y2="0.749207"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eGeRfHEqDjl117-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eGeRfHEqDjl117-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="eGeRfHEqDjl118-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl118-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eGeRfHEqDjl118-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl118-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eGeRfHEqDjl118-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl118-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl118-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl118-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eGeRfHEqDjl118-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl123-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl123-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eGeRfHEqDjl123-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl123-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eGeRfHEqDjl123-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl123-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl123-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl123-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eGeRfHEqDjl124-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="matrix(0.84818 -0.775777 0.775777 0.84818 0.059201 1.058382)"
        >
          <stop id="eGeRfHEqDjl124-stroke-0" offset="0%" stopColor="rgba(126,166,255,0)" />
          <stop
            id="eGeRfHEqDjl124-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eGeRfHEqDjl125-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl125-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eGeRfHEqDjl125-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl125-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eGeRfHEqDjl125-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl125-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl125-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl125-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eGeRfHEqDjl125-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl127-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl127-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eGeRfHEqDjl127-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl127-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eGeRfHEqDjl127-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl127-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl127-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl127-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eGeRfHEqDjl128-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="matrix(1.091762 -1.399522 1.399522 1.091762 0.14437 1.756939)"
        >
          <stop id="eGeRfHEqDjl128-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="eGeRfHEqDjl128-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <linearGradient
          id="eGeRfHEqDjl128-stroke-g1"
          x1="248.766313"
          y1="75.879298"
          x2="256.361464"
          y2="27.930216"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eGeRfHEqDjl128-stroke-g1-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop id="eGeRfHEqDjl128-stroke-g1-1" offset="100%" stopColor="#7ea6ff" />
        </linearGradient>
        <filter id="eGeRfHEqDjl129-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl129-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eGeRfHEqDjl129-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl129-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eGeRfHEqDjl129-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl129-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl129-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl129-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eGeRfHEqDjl129-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl131-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl131-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eGeRfHEqDjl131-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl131-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eGeRfHEqDjl131-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl131-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl131-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl131-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eGeRfHEqDjl132-stroke"
          x1="-0.142435"
          y1="1.238891"
          x2="0.883069"
          y2="-0.083996"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eGeRfHEqDjl132-stroke-0" offset="2%" stopColor="rgba(64,73,168,0)" />
          <stop
            id="eGeRfHEqDjl132-stroke-1"
            offset="51%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eGeRfHEqDjl132-stroke-2"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eGeRfHEqDjl133-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl133-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eGeRfHEqDjl133-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl133-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eGeRfHEqDjl133-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl133-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl133-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl133-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eGeRfHEqDjl133-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl134-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl134-filter-blur-0" stdDeviation="0,0" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl139-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl139-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eGeRfHEqDjl139-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl139-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eGeRfHEqDjl139-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl139-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl139-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl139-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eGeRfHEqDjl140-stroke"
          x1="11.586923"
          y1="0.013299"
          x2="11.586923"
          y2="0.843196"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eGeRfHEqDjl140-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eGeRfHEqDjl140-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="eGeRfHEqDjl141-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl141-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eGeRfHEqDjl141-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl141-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eGeRfHEqDjl141-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl141-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl141-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl141-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eGeRfHEqDjl141-filter-blur-0" stdDeviation="3,3" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl142-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl142-filter-blur-0" stdDeviation="0,0" result="result" />
        </filter>
        <filter id="eGeRfHEqDjl143-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl143-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eGeRfHEqDjl143-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl143-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eGeRfHEqDjl143-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl143-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl143-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl143-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eGeRfHEqDjl144-stroke"
          x1="24380.444917"
          y1="0.07121"
          x2="24380.444917"
          y2="0.85733"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eGeRfHEqDjl144-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eGeRfHEqDjl144-stroke-1"
            offset="44%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eGeRfHEqDjl144-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eGeRfHEqDjl145-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eGeRfHEqDjl145-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eGeRfHEqDjl145-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eGeRfHEqDjl145-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eGeRfHEqDjl145-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eGeRfHEqDjl145-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eGeRfHEqDjl145-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eGeRfHEqDjl145-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eGeRfHEqDjl145-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
      </defs>
      <g transform="translate(0-1.25894)" mask="url(#eGeRfHEqDjl147)">
        <g transform="translate(.000001 0)">
          <g transform="translate(.088013 1.012683)">
            <g transform="translate(-6 0)">
              <path
                d="M853.309,232.641v-111.14137L665.296,76.728v-73.62253"
                transform="matrix(.993835 0 0 1.020627 4.6811-2.431371)"
                fill="none"
                stroke="#6069ca"
              />
              <g transform="translate(-65 105.000001)" filter="url(#eGeRfHEqDjl8-filter)">
                <path
                  id="eGeRfHEqDjl9"
                  d="M853.309,172.852398v-51.352768l-88.567326-21.284376-.520849-.000001"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  fill="none"
                  stroke="url(#eGeRfHEqDjl9-stroke)"
                  strokeLinecap="round"
                />
                <g id="eGeRfHEqDjl10_to" transform="translate(828.317602,-5.431736)">
                  <g id="eGeRfHEqDjl10_tr" transform="rotate(-75)">
                    <rect
                      id="eGeRfHEqDjl10"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0.7"
                      filter="url(#eGeRfHEqDjl10-filter)"
                      fill={selected === 10 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eGeRfHEqDjl11_to" transform="translate(828.317602,-5.431736)">
                  <g id="eGeRfHEqDjl11_tr" transform="rotate(-75)">
                    <rect
                      id="eGeRfHEqDjl11"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      fill={selected === 10 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eGeRfHEqDjl12-filter)">
                <path
                  id="eGeRfHEqDjl13"
                  d="M853.309,252.2368v-9.24321-1.766709-3.184316"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eGeRfHEqDjl13-stroke)"
                  strokeLinecap="round"
                />
                <g id="eGeRfHEqDjl14_to" transform="translate(917.8,138.546297)">
                  <g id="eGeRfHEqDjl14_tr" transform="rotate(0)">
                    <rect
                      id="eGeRfHEqDjl14"
                      width="8.235294"
                      height="14.117647"
                      rx="0"
                      ry="0"
                      transform="scale(0.85,0.85) translate(-4.117647,-7.058824)"
                      opacity="0"
                      filter="url(#eGeRfHEqDjl14-filter)"
                      fill={selected === 10 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eGeRfHEqDjl15_to" transform="translate(917.8,138.546297)">
                  <g id="eGeRfHEqDjl15_tr" transform="rotate(0)">
                    <rect
                      id="eGeRfHEqDjl15"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 10 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eGeRfHEqDjl16-filter)">
                <path
                  id="eGeRfHEqDjl17"
                  d="M853.309,252.2368v-9.24321-1.766709-3.184316"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eGeRfHEqDjl17-stroke)"
                  strokeLinecap="round"
                />
                <g id="eGeRfHEqDjl18_to" transform="translate(917.8,140.146297)">
                  <g id="eGeRfHEqDjl18_tr" transform="rotate(0)">
                    <rect
                      id="eGeRfHEqDjl18"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eGeRfHEqDjl18-filter)"
                      fill={selected === 10 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eGeRfHEqDjl19_to" transform="translate(917.8,140.146297)">
                  <g id="eGeRfHEqDjl19_tr" transform="rotate(0)">
                    <rect
                      id="eGeRfHEqDjl19"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 10 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(-12 0)" mask="url(#eGeRfHEqDjl31)">
              <g>
                <path d="M781.258,138.241v-16.559L628.386,76.7624v-76.41865" fill="none" stroke="#6069ca" />
                <g transform="translate(-65 105.000001)" filter="url(#eGeRfHEqDjl23-filter)">
                  <path
                    id="eGeRfHEqDjl24"
                    d="M781.258,158.241v-7.559l.242-1.040176-.242-.654506"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eGeRfHEqDjl24-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eGeRfHEqDjl25_to" transform="translate(846,51.646297)">
                    <g id="eGeRfHEqDjl25_tr" transform="rotate(0)">
                      <rect
                        id="eGeRfHEqDjl25"
                        width="7.129631"
                        height="12.222224"
                        rx="0"
                        ry="0"
                        transform="scale(0.981818,0.981818) translate(-3.564816,-6.111112)"
                        opacity="0"
                        filter="url(#eGeRfHEqDjl25-filter)"
                        fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eGeRfHEqDjl26_to" transform="translate(846,51.646297)">
                    <g id="eGeRfHEqDjl26_tr" transform="rotate(0)">
                      <rect
                        id="eGeRfHEqDjl26"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eGeRfHEqDjl27-filter)">
                  <path
                    id="eGeRfHEqDjl28"
                    d="M781.258,158.241v-7.559l.242-1.040176-.242-.654506"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eGeRfHEqDjl28-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eGeRfHEqDjl29_to" transform="translate(846.5,51.146297)">
                    <g id="eGeRfHEqDjl29_tr" transform="rotate(0)">
                      <rect
                        id="eGeRfHEqDjl29"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0"
                        filter="url(#eGeRfHEqDjl29-filter)"
                        fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eGeRfHEqDjl30_to" transform="translate(846.5,51.146297)">
                    <g id="eGeRfHEqDjl30_tr" transform="rotate(0)">
                      <rect
                        id="eGeRfHEqDjl30"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="eGeRfHEqDjl31" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 605.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(-18.999999 0.624996)">
              <path d="M721.199,232.865v-112.051L591.532,76.7311l.008-76.992999" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#eGeRfHEqDjl35-filter)">
                <path
                  id="eGeRfHEqDjl36"
                  d="M692.384111,111.206804v-.118885L591.532,76.7311c0,0,.008-7.785355.008-7.785355"
                  transform="translate(65-105.000001)"
                  fill="none"
                  stroke="url(#eGeRfHEqDjl36-stroke)"
                  strokeLinecap="round"
                />
                <g id="eGeRfHEqDjl37_to" transform="translate(656.6,-35.643085)">
                  <rect
                    id="eGeRfHEqDjl37"
                    width="8.75"
                    height="15"
                    rx="0"
                    ry="0"
                    transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                    opacity="0.7"
                    filter="url(#eGeRfHEqDjl37-filter)"
                    fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eGeRfHEqDjl38_to" transform="translate(656.6,-35.643085)">
                  <rect
                    id="eGeRfHEqDjl38"
                    width="10"
                    height="20"
                    rx="0"
                    ry="0"
                    transform="scale(0.5,0.5) translate(-5,-10)"
                    fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eGeRfHEqDjl39-filter)">
                <path
                  id="eGeRfHEqDjl40"
                  d="M721.199,252.865v-4.051l-.006-.917698c0,0,0-.755649,0-.755649"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eGeRfHEqDjl40-stroke)"
                  strokeLinecap="round"
                />
                <g id="eGeRfHEqDjl41_to" transform="translate(786.3,139.146297)">
                  <g id="eGeRfHEqDjl41_tr" transform="rotate(0)">
                    <rect
                      id="eGeRfHEqDjl41"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eGeRfHEqDjl41-filter)"
                      fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eGeRfHEqDjl42_to" transform="translate(786.3,139.146297)">
                  <g id="eGeRfHEqDjl42_tr" transform="rotate(0)">
                    <rect
                      id="eGeRfHEqDjl42"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eGeRfHEqDjl43-filter)">
                <path
                  id="eGeRfHEqDjl44"
                  d="M721.199,252.865v-4.051l-.006-.917698c0,0,0-.755649,0-.755649"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eGeRfHEqDjl44-stroke)"
                  strokeLinecap="round"
                />
                <g id="eGeRfHEqDjl45_to" transform="translate(786.3,138.146297)">
                  <g id="eGeRfHEqDjl45_tr" transform="rotate(0)">
                    <rect
                      id="eGeRfHEqDjl45"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eGeRfHEqDjl45-filter)"
                      fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eGeRfHEqDjl46_to" transform="translate(786.3,138.146297)">
                  <g id="eGeRfHEqDjl46_tr" transform="rotate(0)">
                    <rect
                      id="eGeRfHEqDjl46"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(-22 0)" mask="url(#eGeRfHEqDjl58)">
              <g>
                <path d="M650.977,138.082v-16.4L554.656,76.7305l-.004-76.414006" fill="none" stroke="#6069ca" />
                <g transform="translate(-65 105.000001)" filter="url(#eGeRfHEqDjl50-filter)">
                  <path
                    id="eGeRfHEqDjl51"
                    d="M650.977,158.082v-3.4l.052.000001v-2.410703"
                    transform="translate(65-105.000001)"
                    fill="none"
                    stroke="url(#eGeRfHEqDjl51-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eGeRfHEqDjl52_to" transform="translate(715.3,52.146297)">
                    <g id="eGeRfHEqDjl52_tr" transform="rotate(0)">
                      <rect
                        id="eGeRfHEqDjl52"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0.7"
                        filter="url(#eGeRfHEqDjl52-filter)"
                        fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eGeRfHEqDjl53_to" transform="translate(715.3,52.146297)">
                    <g id="eGeRfHEqDjl53_tr" transform="rotate(0)">
                      <rect
                        id="eGeRfHEqDjl53"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eGeRfHEqDjl54-filter)">
                  <path
                    id="eGeRfHEqDjl55"
                    d="M650.977,158.082v-3.4l.052.000001v-2.410703"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eGeRfHEqDjl55-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eGeRfHEqDjl56_to" transform="translate(715.298427,50.146297)">
                    <g id="eGeRfHEqDjl56_tr" transform="rotate(0)">
                      <rect
                        id="eGeRfHEqDjl56"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0"
                        filter="url(#eGeRfHEqDjl56-filter)"
                        fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eGeRfHEqDjl57_to" transform="translate(715.298427,50.146297)">
                    <g id="eGeRfHEqDjl57_tr" transform="rotate(0)">
                      <rect
                        id="eGeRfHEqDjl57"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="eGeRfHEqDjl58" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="140"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 535.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(-30.999999-4)">
              <path
                d="M590.336,232.597v-106.78L517.819,80.7272l-.001-77.20376"
                transform="translate(0 0.805403)"
                fill="none"
                stroke="#6069ca"
              />
              <g transform="translate(-65 105.000001)" filter="url(#eGeRfHEqDjl62-filter)">
                <path
                  id="eGeRfHEqDjl63"
                  d="M590.336,238.051545v-62.057573l.000001.267378-.242448-.267377"
                  transform="translate(65-104.194598)"
                  fill="none"
                  stroke="url(#eGeRfHEqDjl63-stroke)"
                  strokeLinecap="round"
                />
                <g id="eGeRfHEqDjl64_to" transform="translate(655.2,73.020302)">
                  <g id="eGeRfHEqDjl64_tr" transform="rotate(0)">
                    <rect
                      id="eGeRfHEqDjl64"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0.7"
                      filter="url(#eGeRfHEqDjl64-filter)"
                      fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eGeRfHEqDjl65_to" transform="translate(655.2,73.020302)">
                  <g id="eGeRfHEqDjl65_tr" transform="rotate(0)">
                    <rect
                      id="eGeRfHEqDjl65"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eGeRfHEqDjl66-filter)">
                <path
                  id="eGeRfHEqDjl67"
                  d="M590.336,252.597v-2.381105v0h.018"
                  transform="translate(65-104.194598)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eGeRfHEqDjl67-stroke)"
                  strokeLinecap="round"
                />
                <g id="eGeRfHEqDjl68_to" transform="translate(655.5,137.771297)">
                  <g id="eGeRfHEqDjl68_tr" transform="rotate(0)">
                    <rect
                      id="eGeRfHEqDjl68"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eGeRfHEqDjl68-filter)"
                      fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eGeRfHEqDjl69_to" transform="translate(655.5,137.771297)">
                  <g id="eGeRfHEqDjl69_tr" transform="rotate(0)">
                    <rect
                      id="eGeRfHEqDjl69"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eGeRfHEqDjl70-filter)">
                <path
                  id="eGeRfHEqDjl71"
                  d="M590.336,252.597v-2.381105v0h.018"
                  transform="translate(65-104.194598)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eGeRfHEqDjl71-stroke)"
                  strokeLinecap="round"
                />
                <g id="eGeRfHEqDjl72_to" transform="translate(655,137.646297)">
                  <rect
                    id="eGeRfHEqDjl72"
                    width="7"
                    height="12"
                    rx="0"
                    ry="0"
                    transform="translate(-3.5,-6)"
                    opacity="0"
                    filter="url(#eGeRfHEqDjl72-filter)"
                    fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eGeRfHEqDjl73_to" transform="translate(655,137.646297)">
                  <rect
                    id="eGeRfHEqDjl73"
                    width="10"
                    height="20"
                    rx="0"
                    ry="0"
                    transform="scale(0.5,0.5) translate(-5,-10)"
                    opacity="0"
                    fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
              </g>
            </g>
            <g transform="translate(165 0)" mask="url(#eGeRfHEqDjl85)">
              <g transform="translate(.000001 0)">
                <line
                  x1="443.301"
                  y1="169.502"
                  x2="443.301"
                  y2="325.462"
                  transform="translate(-164.018-168.956)"
                  fill="none"
                  stroke="#6069ca"
                />
                <g transform="translate(-65 105.000001)" filter="url(#eGeRfHEqDjl77-filter)">
                  <path
                    id="eGeRfHEqDjl78"
                    d="M278.994002,175.5213L278.994,145.053002l.106.000002-.070999-.000002"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eGeRfHEqDjl78-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eGeRfHEqDjl79_to" transform="translate(344.25,52.271297)">
                    <rect
                      id="eGeRfHEqDjl79"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#eGeRfHEqDjl79-filter)"
                      fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="eGeRfHEqDjl80_to" transform="translate(344.25,52.271297)">
                    <rect
                      id="eGeRfHEqDjl80"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eGeRfHEqDjl81-filter)">
                  <path
                    id="eGeRfHEqDjl82"
                    d="M278.994002,175.5213L278.994,145.053002l.106.000002-.070999-.000002"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eGeRfHEqDjl82-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eGeRfHEqDjl83_to" transform="translate(344.25,52.271297)">
                    <rect
                      id="eGeRfHEqDjl83"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#eGeRfHEqDjl83-filter)"
                      fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="eGeRfHEqDjl84_to" transform="translate(344.25,52.271297)">
                    <rect
                      id="eGeRfHEqDjl84"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <mask id="eGeRfHEqDjl85" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="140"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 209.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(31 0)">
              <path d="M292.146,229.76v-108.078l78.228-44.9059v-76.43235" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#eGeRfHEqDjl89-filter)">
                <path
                  id="eGeRfHEqDjl90"
                  d="M292.146,239.76v-1.238702l.354001-2.512982.000002-.237018"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eGeRfHEqDjl90-stroke)"
                  strokeLinecap="round"
                />
                <g id="eGeRfHEqDjl91_to" transform="translate(357.5,139.246297)">
                  <g id="eGeRfHEqDjl91_tr" transform="rotate(0)">
                    <rect
                      id="eGeRfHEqDjl91"
                      width="7.291667"
                      height="12.5"
                      rx="0"
                      ry="0"
                      transform="scale(0.96,0.96) translate(-3.645833,-6.25)"
                      opacity="0"
                      filter="url(#eGeRfHEqDjl91-filter)"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eGeRfHEqDjl92_to" transform="translate(357.5,139.246297)">
                  <g id="eGeRfHEqDjl92_tr" transform="rotate(0)">
                    <rect
                      id="eGeRfHEqDjl92"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eGeRfHEqDjl93-filter)">
                <path
                  id="eGeRfHEqDjl94"
                  d="M292.146,239.76v-1.238702l.354001-2.512982.000002-.237018"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eGeRfHEqDjl94-stroke)"
                  strokeLinecap="round"
                />
                <g id="eGeRfHEqDjl95_to" transform="translate(357.5,139.246297)">
                  <g id="eGeRfHEqDjl95_tr" transform="rotate(0)">
                    <rect
                      id="eGeRfHEqDjl95"
                      width="7.291667"
                      height="12.5"
                      rx="0"
                      ry="0"
                      transform="scale(0.96,0.96) translate(-3.645833,-6.25)"
                      opacity="0"
                      filter="url(#eGeRfHEqDjl95-filter)"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eGeRfHEqDjl96_to" transform="translate(357.5,139.246297)">
                  <g id="eGeRfHEqDjl96_tr" transform="rotate(0)">
                    <rect
                      id="eGeRfHEqDjl96"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(25 0)" mask="url(#eGeRfHEqDjl108)">
              <g transform="translate(.000001 0)">
                <path d="M226.994,138.053v-16.37L333.531,76.7383l.004-76.414117" fill="none" stroke="#6069ca" />
                <g transform="translate(-65 105.000001)" filter="url(#eGeRfHEqDjl100-filter)">
                  <path
                    id="eGeRfHEqDjl101"
                    d="M226.994002,161.5213l-.000002-7.468298.106.000002-.070999-.000002"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eGeRfHEqDjl101-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eGeRfHEqDjl102_to" transform="translate(291.8,52.271297)">
                    <g id="eGeRfHEqDjl102_tr" transform="rotate(0)">
                      <rect
                        id="eGeRfHEqDjl102"
                        width="8.75"
                        height="15"
                        rx="0"
                        ry="0"
                        transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                        opacity="0"
                        filter="url(#eGeRfHEqDjl102-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eGeRfHEqDjl103_to" transform="translate(291.8,52.271297)">
                    <g id="eGeRfHEqDjl103_tr" transform="rotate(0)">
                      <rect
                        id="eGeRfHEqDjl103"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eGeRfHEqDjl104-filter)">
                  <path
                    id="eGeRfHEqDjl105"
                    d="M226.994002,161.5213l-.000002-7.468298.106.000002-.070999-.000002"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eGeRfHEqDjl105-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eGeRfHEqDjl106_to" transform="translate(291.8,49.646297)">
                    <g id="eGeRfHEqDjl106_tr" transform="rotate(0)">
                      <rect
                        id="eGeRfHEqDjl106"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0"
                        filter="url(#eGeRfHEqDjl106-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eGeRfHEqDjl107_to" transform="translate(291.8,49.646297)">
                    <g id="eGeRfHEqDjl107_tr" transform="rotate(0)">
                      <rect
                        id="eGeRfHEqDjl107"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="eGeRfHEqDjl108" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="140"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 209.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(19 0)">
              <path d="M161.867,229.879v-108.202L296.68,76.7222v-76.413669" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#eGeRfHEqDjl112-filter)">
                <path
                  id="eGeRfHEqDjl113"
                  d="M161.867,259.879v-20.389002c0,0-.017-.218699-.017-.218699l-1.051573-.875005"
                  transform="translate(64.996501-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eGeRfHEqDjl113-stroke)"
                  strokeLinecap="round"
                />
                <g id="eGeRfHEqDjl114_to" transform="translate(226.81,140.246297)">
                  <g id="eGeRfHEqDjl114_tr" transform="rotate(0)">
                    <rect
                      id="eGeRfHEqDjl114"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#eGeRfHEqDjl114-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eGeRfHEqDjl115_to" transform="translate(226.81,140.246297)">
                  <g id="eGeRfHEqDjl115_tr" transform="rotate(0)">
                    <rect
                      id="eGeRfHEqDjl115"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eGeRfHEqDjl116-filter)">
                <path
                  id="eGeRfHEqDjl117"
                  d="M161.867,259.879v-24.389002l-.017-.218699.948427-.875005"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eGeRfHEqDjl117-stroke)"
                  strokeLinecap="round"
                />
                <g id="eGeRfHEqDjl118_to" transform="translate(226.8,139.146297)">
                  <g id="eGeRfHEqDjl118_tr" transform="rotate(0)">
                    <rect
                      id="eGeRfHEqDjl118"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eGeRfHEqDjl118-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eGeRfHEqDjl119_to" transform="translate(226.8,139.146297)">
                  <g id="eGeRfHEqDjl119_tr" transform="rotate(0)">
                    <rect
                      id="eGeRfHEqDjl119"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(13 0)" mask="url(#eGeRfHEqDjl135)">
              <g transform="translate(.000001 0)">
                <path d="M96.7109,138.118v-16.438L259.817,76.7298v-76.38605" fill="none" stroke="#6069ca" />
                <g transform="translate(-64 105.000001)" filter="url(#eGeRfHEqDjl123-filter)">
                  <path
                    id="eGeRfHEqDjl124"
                    d="M96.711985,145.439636L96.7109,127.987317h.001085c0,0,0,0,0,0"
                    transform="translate(64-105.000001)"
                    fill="none"
                    stroke="url(#eGeRfHEqDjl124-stroke)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="eGeRfHEqDjl125_to" transform="translate(160.8,25.504306)">
                    <g id="eGeRfHEqDjl125_tr" transform="rotate(0)">
                      <rect
                        id="eGeRfHEqDjl125"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0.7"
                        filter="url(#eGeRfHEqDjl125-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eGeRfHEqDjl126_to" transform="translate(160.8,25.504306)">
                    <g id="eGeRfHEqDjl126_tr" transform="rotate(0)">
                      <rect
                        id="eGeRfHEqDjl126"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eGeRfHEqDjl127-filter)">
                  <path
                    id="eGeRfHEqDjl128"
                    d="M96.7109,143.118v-.064999l.1973-.999999c0,0-.109773,1.999999-.109773,1.999999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eGeRfHEqDjl128-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eGeRfHEqDjl129_to" transform="translate(161.8,50.787316)">
                    <g id="eGeRfHEqDjl129_tr" transform="rotate(0)">
                      <rect
                        id="eGeRfHEqDjl129"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0"
                        filter="url(#eGeRfHEqDjl129-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eGeRfHEqDjl130_to" transform="translate(161.8,50.787316)">
                    <g id="eGeRfHEqDjl130_tr" transform="rotate(0)">
                      <rect
                        id="eGeRfHEqDjl130"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eGeRfHEqDjl131-filter)">
                  <path
                    id="eGeRfHEqDjl132"
                    d="M96.7109,143.118v-.064999l.1973-.999999c0,0-.109773,1.999999-.109773,1.999999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eGeRfHEqDjl132-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eGeRfHEqDjl133_to" transform="translate(161.8,49.521297)">
                    <rect
                      id="eGeRfHEqDjl133"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eGeRfHEqDjl133-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="eGeRfHEqDjl134_to" transform="translate(161.8,49.521297)">
                    <rect
                      id="eGeRfHEqDjl134"
                      width="5"
                      height="10"
                      rx="0"
                      ry="0"
                      transform="translate(-2.5,-5)"
                      opacity="0"
                      filter="url(#eGeRfHEqDjl134-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <mask id="eGeRfHEqDjl135" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 75.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(6 0)">
              <path d="M32.0957,229.88v-108.202L222.955,76.73v-76.413594" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#eGeRfHEqDjl139-filter)">
                <path
                  id="eGeRfHEqDjl140"
                  d="M32.0957,249.88c0,0,0-9.358702,0-9.358702v0l1-.996864"
                  transform="translate(64.996286-105.187118)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eGeRfHEqDjl140-stroke)"
                  strokeLinecap="round"
                />
                <g id="eGeRfHEqDjl141_to" transform="translate(96.798427,143.537316)">
                  <g id="eGeRfHEqDjl141_tr" transform="rotate(0)">
                    <rect
                      id="eGeRfHEqDjl141"
                      width="10"
                      height="17.142857"
                      rx="0"
                      ry="0"
                      transform="scale(0.7,0.7) translate(-5,-8.571429)"
                      opacity="0"
                      filter="url(#eGeRfHEqDjl141-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eGeRfHEqDjl142_to" transform="translate(96.798427,142.237316)">
                  <g id="eGeRfHEqDjl142_tr" transform="rotate(0)">
                    <rect
                      id="eGeRfHEqDjl142"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      filter="url(#eGeRfHEqDjl142-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      strokeMiterlimit="8"
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eGeRfHEqDjl143-filter)">
                <path
                  id="eGeRfHEqDjl144"
                  d="M32.0957,249.88v-10.732683h-.003714v-.001023"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eGeRfHEqDjl144-stroke)"
                  strokeLinecap="round"
                />
                <g id="eGeRfHEqDjl145_to" transform="translate(96.8,140.121297)">
                  <g id="eGeRfHEqDjl145_tr" transform="rotate(0)">
                    <rect
                      id="eGeRfHEqDjl145"
                      width="10"
                      height="17.142857"
                      rx="0"
                      ry="0"
                      transform="scale(0.7,0.7) translate(-5,-8.571428)"
                      opacity="0"
                      filter="url(#eGeRfHEqDjl145-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eGeRfHEqDjl146_to" transform="translate(96.8,140.121297)">
                  <g id="eGeRfHEqDjl146_tr" transform="rotate(0)">
                    <rect
                      id="eGeRfHEqDjl146"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <mask id="eGeRfHEqDjl147" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="825.762291"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #eGeRfHEqDjl9 {
    animation-name: eGeRfHEqDjl9__m, eGeRfHEqDjl9_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eGeRfHEqDjl9__m {
    0% {
      d: path('M853.309,172.852398L853.309,121.49963L764.741674,100.215254L764.220825,100.215253');
    }
    8.75% {
      d: path('M853.309,121.605025L853.309,121.49963L665.296,76.728L665.296,76.180546');
    }
    10% {
      d: path('M829.807375,115.995397L829.807375,115.903177L665.296,76.728L665.296,67.046162');
    }
    13.75% {
      d: path('M759.302501,99.166512L759.3025,99.113816L665.296,76.728L665.296,16.128048');
    }
    20% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
    100% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
  }
  @keyframes eGeRfHEqDjl9_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    16.25% {
      opacity: 1;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl10_to {
    animation: eGeRfHEqDjl10_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl10_to__to {
    0% {
      transform: translate(828.317602px, -5.431736px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(736.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(730.920607px, -32.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.219355px, -122.688381px);
    }
    100% {
      transform: translate(731.219355px, -122.688381px);
    }
  }
  #eGeRfHEqDjl10_tr {
    animation: eGeRfHEqDjl10_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl10_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    7.5% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl10 {
    animation: eGeRfHEqDjl10_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl10_c_o {
    0% {
      opacity: 0.7;
    }
    20% {
      opacity: 0.7;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl11_to {
    animation: eGeRfHEqDjl11_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl11_to__to {
    0% {
      transform: translate(828.317602px, -5.431736px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(736.801035px, -27.63967px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(730.851035px, -32.48073px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.219354px, -122.688381px);
    }
    100% {
      transform: translate(731.219354px, -122.688381px);
    }
  }
  #eGeRfHEqDjl11_tr {
    animation: eGeRfHEqDjl11_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl11_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    7.5% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl11 {
    animation: eGeRfHEqDjl11_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl11_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl13 {
    animation-name: eGeRfHEqDjl13__m, eGeRfHEqDjl13_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eGeRfHEqDjl13__m {
    0% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    30% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    37.5% {
      d: path('M853.309,237.53995L853.309,151.87312L853.306432,148.3585L853.309001,144.380103');
    }
    40% {
      d: path('M853.309,232.641L853.309,121.49963L853.309,121.605025L852.281356,122.594792');
    }
    56.25% {
      d: path('M853.309,121.605025L853.309,121.49963L665.296,76.728L665.296,76.180546');
    }
    62.5% {
      d: path('M759.302501,99.166512L759.3025,99.113816L665.296,76.728L665.296,11.229098');
    }
    68.75% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
    100% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
  }
  @keyframes eGeRfHEqDjl13_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl14_to {
    animation: eGeRfHEqDjl14_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl14_to__to {
    0% {
      transform: translate(917.8px, 138.546297px);
    }
    25% {
      transform: translate(917.8px, 138.546297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 23.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(913.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(905.3352px, 13.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(736.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(730.920607px, -32.510292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -38.49755px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(730.9px, -120.728703px);
    }
    100% {
      transform: translate(730.9px, -120.728703px);
    }
  }
  #eGeRfHEqDjl14_tr {
    animation: eGeRfHEqDjl14_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl14_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-75deg);
    }
    55% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl14 {
    animation: eGeRfHEqDjl14_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl14_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 0.7;
    }
    68.75% {
      opacity: 0.7;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl15_to {
    animation: eGeRfHEqDjl15_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl15_to__to {
    0% {
      transform: translate(917.8px, 138.546297px);
    }
    25% {
      transform: translate(917.8px, 138.546297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 23.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(913.568884px, 15.76033px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(904.798427px, 13.4px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(736.801035px, -27.43967px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(730.920607px, -32.510292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -38.49755px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(730.9px, -120.228703px);
    }
    100% {
      transform: translate(730.9px, -120.228703px);
    }
  }
  #eGeRfHEqDjl15_tr {
    animation: eGeRfHEqDjl15_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-75deg);
    }
    55% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl15 {
    animation: eGeRfHEqDjl15_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl15_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    68.75% {
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl17 {
    animation-name: eGeRfHEqDjl17__m, eGeRfHEqDjl17_c_o, eGeRfHEqDjl17_s_p;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eGeRfHEqDjl17__m {
    0% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    81.25% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    85% {
      d: path('M853.309,244.888375L853.309,197.433355L853.309,189.696815L852.437108,189.015261');
    }
    90% {
      d: path('M853.309,232.641L853.309,121.49963L853.309,121.605025L853.309,120.707886');
    }
    91.25% {
      d: path('M853.309,225.167425L853.309,121.49963L846.98854,119.942807L847.018444,119.15781');
    }
    95% {
      d: path('M853.309,202.746699L853.309,121.49963L815.808982,112.576662L815.928598,112.128092');
    }
    100% {
      d: path('M853.309,172.852398L853.309,121.49963L762.497203,100.468958L762.736434,100.468959');
    }
  }
  @keyframes eGeRfHEqDjl17_c_o {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    76.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes eGeRfHEqDjl17_s_p {
    0% {
      stroke: url(#eGeRfHEqDjl17-stroke);
    }
    86.25% {
      stroke: url(#eGeRfHEqDjl17-stroke);
    }
    88.75% {
      stroke: url(#eGeRfHEqDjl17-stroke-g1);
    }
    92.5% {
      stroke: url(#eGeRfHEqDjl17-stroke-g2);
    }
    100% {
      stroke: url(#eGeRfHEqDjl17-stroke-g2);
    }
  }
  #eGeRfHEqDjl18_to {
    animation: eGeRfHEqDjl18_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl18_to__to {
    0% {
      transform: translate(917.8px, 140.146297px);
    }
    75% {
      transform: translate(917.8px, 140.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.798427px, 21.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(913.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(905.3352px, 13.841812px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(826.798304px, -5.828881px);
    }
  }
  #eGeRfHEqDjl18_tr {
    animation: eGeRfHEqDjl18_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl18_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #eGeRfHEqDjl18 {
    animation: eGeRfHEqDjl18_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl18_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #eGeRfHEqDjl19_to {
    animation: eGeRfHEqDjl19_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl19_to__to {
    0% {
      transform: translate(917.8px, 140.146297px);
    }
    75% {
      transform: translate(917.8px, 140.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.798427px, 20.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(913.3px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(904.798427px, 13.4px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(827.121519px, -5.98073px);
    }
  }
  #eGeRfHEqDjl19_tr {
    animation: eGeRfHEqDjl19_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl19_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #eGeRfHEqDjl19 {
    animation: eGeRfHEqDjl19_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl19_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eGeRfHEqDjl24 {
    animation-name: eGeRfHEqDjl24__m, eGeRfHEqDjl24_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eGeRfHEqDjl24__m {
    0% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    12.5% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    17.5% {
      d: path('M781.258,146.812429L781.258,144.110571L781.527118,145.664782L780.755126,143.963766');
    }
    21.25% {
      d: path('M781.258,138.241L781.258,121.682L779.797456,121.682L778.627971,122.696102');
    }
    36.25% {
      d: path('M781.258,121.622403L781.258,121.682L628.386,76.7624L628.386,77.3561');
    }
    41.25% {
      d: path('M720.1092,103.542346L720.1092,103.578104L628.386,76.7624L628.386,20.55116');
    }
    43.75% {
      d: path('M683.41992,92.694312L683.41992,92.715767L628.386,76.7624L628.386,-5.531804');
    }
    47.5% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
    100% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
  }
  @keyframes eGeRfHEqDjl24_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    43.75% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl25_to {
    animation: eGeRfHEqDjl25_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl25_to__to {
    0% {
      transform: translate(846px, 51.646297px);
    }
    12.5% {
      transform: translate(846px, 51.646297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    20% {
      transform: translate(846.5px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(842.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    22.5% {
      transform: translate(831.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(699.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(693.5px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(693.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    47.5% {
      transform: translate(693.9px, -120.728703px);
    }
    100% {
      transform: translate(693.9px, -120.728703px);
    }
  }
  #eGeRfHEqDjl25_tr {
    animation: eGeRfHEqDjl25_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl25_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-73deg);
    }
    35% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl25 {
    animation: eGeRfHEqDjl25_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl25_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.7;
    }
    47.5% {
      opacity: 0.7;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl26_to {
    animation: eGeRfHEqDjl26_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl26_to__to {
    0% {
      transform: translate(846px, 51.646297px);
    }
    12.5% {
      transform: translate(846px, 51.646297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    20% {
      transform: translate(846.5px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(842.3px, 15.5px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    22.5% {
      transform: translate(830.604721px, 12.350134px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(699.801035px, -26.390926px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(693.411987px, -31.390926px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(693.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    47.5% {
      transform: translate(693.9px, -122.228703px);
    }
    100% {
      transform: translate(693.9px, -122.228703px);
    }
  }
  #eGeRfHEqDjl26_tr {
    animation: eGeRfHEqDjl26_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl26_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-73deg);
    }
    35% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl26 {
    animation: eGeRfHEqDjl26_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl26_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    47.5% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl28 {
    animation-name: eGeRfHEqDjl28__m, eGeRfHEqDjl28_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eGeRfHEqDjl28__m {
    0% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    51.25% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    56.25% {
      d: path('M781.258,146.812429L781.258,144.110571L781.527118,145.664782L780.755126,143.963766');
    }
    60% {
      d: path('M781.258,138.241L781.258,121.682L779.797456,121.682L778.627971,122.696102');
    }
    73.75% {
      d: path('M781.258,121.622403L781.258,121.682L628.386,76.7624L628.386,77.3561');
    }
    78.75% {
      d: path('M720.1092,103.542346L720.1092,103.578104L628.386,76.7624L628.386,20.55116');
    }
    86.25% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
    100% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
  }
  @keyframes eGeRfHEqDjl28_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 1;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl29_to {
    animation: eGeRfHEqDjl29_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl29_to__to {
    0% {
      transform: translate(846.5px, 51.146297px);
    }
    51.25% {
      transform: translate(846.5px, 51.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    58.75% {
      transform: translate(846.5px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    60% {
      transform: translate(842.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    61.25% {
      transform: translate(831.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    72.5% {
      transform: translate(699.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    73.75% {
      transform: translate(693.5px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    75% {
      transform: translate(693.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    86.25% {
      transform: translate(693.9px, -119.728703px);
    }
    100% {
      transform: translate(693.9px, -119.728703px);
    }
  }
  #eGeRfHEqDjl29_tr {
    animation: eGeRfHEqDjl29_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl29_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-73deg);
    }
    72.5% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl29 {
    animation: eGeRfHEqDjl29_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl29_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 0.7;
    }
    86.25% {
      opacity: 0.7;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl30_to {
    animation: eGeRfHEqDjl30_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl30_to__to {
    0% {
      transform: translate(846.5px, 51.146297px);
    }
    51.25% {
      transform: translate(846.5px, 51.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    58.75% {
      transform: translate(846.5px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    60% {
      transform: translate(841.104721px, 14.850134px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    61.25% {
      transform: translate(830.798427px, 12.350134px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    72.5% {
      transform: translate(698.801035px, -26.8px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    73.75% {
      transform: translate(693.411987px, -31.290926px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    75% {
      transform: translate(693.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    86.25% {
      transform: translate(693.9px, -121.228703px);
    }
    100% {
      transform: translate(693.9px, -121.228703px);
    }
  }
  #eGeRfHEqDjl30_tr {
    animation: eGeRfHEqDjl30_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl30_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-73deg);
    }
    72.5% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl30 {
    animation: eGeRfHEqDjl30_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl30_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    86.25% {
      opacity: 1;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl36 {
    animation-name: eGeRfHEqDjl36__m, eGeRfHEqDjl36_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eGeRfHEqDjl36__m {
    0% {
      d: path(
        'M692.384111,111.206804L692.384111,111.087919L591.532,76.7311C591.532,76.7311,591.54,68.945745,591.54,68.945745'
      );
    }
    3.75% {
      d: path(
        'M649.161778,96.431503L649.161778,96.498797L591.532,76.7311C591.532,76.7311,591.54,19.285326,591.54,19.285326'
      );
    }
    8.75% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-20.261899,591.54,-20.261899'
      );
    }
    100% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-20.261899,591.54,-20.261899'
      );
    }
  }
  @keyframes eGeRfHEqDjl36_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    3.75% {
      opacity: 1;
    }
    10% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl37_to {
    animation: eGeRfHEqDjl37_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl37_to__to {
    0% {
      transform: translate(656.6px, -35.643085px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #eGeRfHEqDjl37 {
    animation: eGeRfHEqDjl37_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl37_c_o {
    0% {
      opacity: 0.7;
    }
    10% {
      opacity: 0.7;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl38_to {
    animation: eGeRfHEqDjl38_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl38_to__to {
    0% {
      transform: translate(656.6px, -35.643085px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #eGeRfHEqDjl38 {
    animation: eGeRfHEqDjl38_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl38_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    10% {
      opacity: 1;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl40 {
    animation-name: eGeRfHEqDjl40__m, eGeRfHEqDjl40_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eGeRfHEqDjl40__m {
    0% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    22.5% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    30% {
      d: path(
        'M721.199,242.865L721.199,212.314L721.03054,210.946856C721.03054,210.946856,721.013534,209.569032,721.013534,209.569032'
      );
    }
    36.25% {
      d: path(
        'M721.199,234.531667L721.199,136.064L721.192993,131.635822C721.192993,131.635822,721.192993,128.271298,721.192993,128.271298'
      );
    }
    37.5% {
      d: path(
        'M721.199,232.865L721.199,120.814L721.192993,120.949437C721.192993,120.949437,721.192993,120.410429,721.192993,120.410429'
      );
    }
    50% {
      d: path('M721.199,121.057004L721.199,120.814L591.532,76.7311C591.532,76.7311,591.54,75.862215,591.54,75.862215');
    }
    60% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-0.261899,591.54,-0.261899'
      );
    }
    100% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-0.261899,591.54,-0.261899'
      );
    }
  }
  @keyframes eGeRfHEqDjl40_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    57.5% {
      opacity: 1;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl41_to {
    animation: eGeRfHEqDjl41_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl41_to__to {
    0% {
      transform: translate(786.3px, 139.146297px);
    }
    22.5% {
      transform: translate(786.3px, 139.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.3px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(784.5px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(774.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    50% {
      transform: translate(660.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(656.920607px, -31.208246px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #eGeRfHEqDjl41_tr {
    animation: eGeRfHEqDjl41_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl41_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(-70deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl41 {
    animation: eGeRfHEqDjl41_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl41_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 0.7;
    }
    62.5% {
      opacity: 0.7;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl42_to {
    animation: eGeRfHEqDjl42_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl42_to__to {
    0% {
      transform: translate(786.3px, 139.146297px);
    }
    22.5% {
      transform: translate(786.3px, 139.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.3px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(783.150027px, 14.353271px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(773.8px, 10.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    50% {
      transform: translate(660.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(656.851035px, -31.208246px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -121.228703px);
    }
    100% {
      transform: translate(656.9px, -121.228703px);
    }
  }
  #eGeRfHEqDjl42_tr {
    animation: eGeRfHEqDjl42_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl42_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(-70deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl42 {
    animation: eGeRfHEqDjl42_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl42_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    62.5% {
      opacity: 1;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl44 {
    animation-name: eGeRfHEqDjl44__m, eGeRfHEqDjl44_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eGeRfHEqDjl44__m {
    0% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    68.75% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    76.25% {
      d: path(
        'M721.199,242.865L721.199,212.314L721.03054,210.946856C721.03054,210.946856,721.013534,209.569032,721.013534,209.569032'
      );
    }
    82.5% {
      d: path(
        'M721.199,234.531667L721.199,137.56998L721.170923,129.949006C721.170923,129.949006,721.170923,128.771298,721.170923,128.771298'
      );
    }
    83.75% {
      d: path(
        'M721.199,232.865L721.199,120.621176L721.199,120.949436C721.199,120.949436,718.435021,120.132167,718.435021,120.132167'
      );
    }
    97.5% {
      d: path('M721.199,121.057004L721.199,120.814L591.532,76.7311C591.532,76.7311,591.54,75.862215,591.54,75.862215');
    }
    100% {
      d: path(
        'M692.384111,111.206804L692.384111,111.087919L591.532,76.7311C591.532,76.7311,591.54,68.945745,591.54,68.945745'
      );
    }
  }
  @keyframes eGeRfHEqDjl44_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eGeRfHEqDjl45_to {
    animation: eGeRfHEqDjl45_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl45_to__to {
    0% {
      transform: translate(786.3px, 138.146297px);
    }
    68.75% {
      transform: translate(786.3px, 138.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.3px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(784.29007px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(774.3352px, 11.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(661.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(656.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -40.096471px);
    }
  }
  #eGeRfHEqDjl45_tr {
    animation: eGeRfHEqDjl45_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl45_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-70deg);
    }
    96.25% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl45 {
    animation: eGeRfHEqDjl45_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl45_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #eGeRfHEqDjl46_to {
    animation: eGeRfHEqDjl46_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl46_to__to {
    0% {
      transform: translate(786.3px, 138.146297px);
    }
    68.75% {
      transform: translate(786.3px, 138.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.3px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(784.29007px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(773.8px, 11.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(660.801035px, -26.846729px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(656.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -40.096471px);
    }
  }
  #eGeRfHEqDjl46_tr {
    animation: eGeRfHEqDjl46_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl46_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-70deg);
    }
    96.25% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl46 {
    animation: eGeRfHEqDjl46_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl46_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eGeRfHEqDjl51 {
    animation-name: eGeRfHEqDjl51__m, eGeRfHEqDjl51_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eGeRfHEqDjl51__m {
    0% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    5% {
      d: path('M650.977,146.653429L650.977,145.824857L650.761566,146.078382L650.761569,145.329073');
    }
    8.75% {
      d: path('M650.977,138.082L650.977,121.682L650.560991,122.125667L650.560995,122.622405');
    }
    22.5% {
      d: path('M650.977,122.082L650.977,121.682L554.656,76.7305L554.652,76.316494');
    }
    28.75% {
      d: path('M575.124559,86.409359L575.124559,86.409359L554.656,76.7305L554.652,9.316494');
    }
    35% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
    100% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
  }
  @keyframes eGeRfHEqDjl51_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    31.25% {
      opacity: 1;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl52_to {
    animation: eGeRfHEqDjl52_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl52_to__to {
    0% {
      transform: translate(715.3px, 52.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(712.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(706.3352px, 11.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(624.801035px, -25.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(619.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(619.9px, -121.728703px);
    }
    100% {
      transform: translate(619.9px, -121.728703px);
    }
  }
  #eGeRfHEqDjl52_tr {
    animation: eGeRfHEqDjl52_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl52_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-68deg);
    }
    21.25% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl52 {
    animation: eGeRfHEqDjl52_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl52_c_o {
    0% {
      opacity: 0.7;
    }
    35% {
      opacity: 0.7;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl53_to {
    animation: eGeRfHEqDjl53_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl53_to__to {
    0% {
      transform: translate(715.3px, 52.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(711.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(705.798427px, 11.6px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(623.801035px, -26.140845px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(619.911987px, -32.40395px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(619.9px, -122.228703px);
    }
    100% {
      transform: translate(619.9px, -122.228703px);
    }
  }
  #eGeRfHEqDjl53_tr {
    animation: eGeRfHEqDjl53_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl53_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-68deg);
    }
    21.25% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl53 {
    animation: eGeRfHEqDjl53_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl53_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    35% {
      opacity: 1;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl55 {
    animation-name: eGeRfHEqDjl55__m, eGeRfHEqDjl55_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eGeRfHEqDjl55__m {
    0% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    43.75% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    48.75% {
      d: path('M650.977,146.653429L650.977,145.824857L650.761566,146.078382L650.761569,145.329073');
    }
    52.5% {
      d: path('M650.977,138.082L650.977,121.682L650.560991,122.125667L650.560995,122.622405');
    }
    66.25% {
      d: path('M650.977,122.082L650.977,121.682L554.656,76.7305L554.652,76.316494');
    }
    72.5% {
      d: path('M575.124559,86.409359L575.124559,86.409359L554.656,76.7305L554.652,9.316494');
    }
    78.75% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
    100% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
  }
  @keyframes eGeRfHEqDjl55_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl56_to {
    animation: eGeRfHEqDjl56_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl56_to__to {
    0% {
      transform: translate(715.298427px, 50.146297px);
    }
    43.75% {
      transform: translate(715.298427px, 50.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(713.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(707.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(624.801035px, -25.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(619.920607px, -29.810292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -36.79755px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(619.9px, -117.878703px);
    }
    100% {
      transform: translate(619.9px, -117.878703px);
    }
  }
  #eGeRfHEqDjl56_tr {
    animation: eGeRfHEqDjl56_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl56_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-68deg);
    }
    65% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl56 {
    animation: eGeRfHEqDjl56_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl56_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 0.7;
    }
    78.75% {
      opacity: 0.7;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl57_to {
    animation: eGeRfHEqDjl57_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl57_to__to {
    0% {
      transform: translate(715.298427px, 50.146297px);
    }
    43.75% {
      transform: translate(715.298427px, 50.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(713.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(707.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(626.801035px, -24.6px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(619.920607px, -29.810292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -36.79755px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(619.9px, -117.878703px);
    }
    100% {
      transform: translate(619.9px, -117.878703px);
    }
  }
  #eGeRfHEqDjl57_tr {
    animation: eGeRfHEqDjl57_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl57_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-68deg);
    }
    65% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl57 {
    animation: eGeRfHEqDjl57_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl57_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 1;
    }
    78.75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl63 {
    animation-name: eGeRfHEqDjl63__m, eGeRfHEqDjl63_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eGeRfHEqDjl63__m {
    0% {
      d: path('M590.336,238.051545L590.336,175.993972L590.336001,176.26135L590.093553,175.993973');
    }
    7.5% {
      d: path('M590.336,232.597L590.336,125.817L590.336001,127.215895L588.964636,125.817002');
    }
    21.25% {
      d: path('M590.336,125.761512L590.336,125.817L517.819,80.7272L517.818,79.9168');
    }
    27.5% {
      d: path('M554.077,103.313869L554.077,103.183848L517.819,80.7272L517.818,11.72012');
    }
    32.5% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
    100% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
  }
  @keyframes eGeRfHEqDjl63_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl64_to {
    animation: eGeRfHEqDjl64_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl64_to__to {
    0% {
      transform: translate(655.2px, 73.020302px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    6.25% {
      transform: translate(655.798427px, 27.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    7.5% {
      transform: translate(653.798427px, 20.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    8.75% {
      transform: translate(643.3352px, 13.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    20% {
      transform: translate(586.801035px, -20.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    21.25% {
      transform: translate(582.920607px, -27.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    22.5% {
      transform: translate(582.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    33.75% {
      transform: translate(582.9px, -115.728703px);
    }
    100% {
      transform: translate(582.9px, -115.728703px);
    }
  }
  #eGeRfHEqDjl64_tr {
    animation: eGeRfHEqDjl64_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl64_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    6.25% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(-60deg);
    }
    20% {
      transform: rotate(-60deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl64 {
    animation: eGeRfHEqDjl64_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl64_c_o {
    0% {
      opacity: 0.7;
    }
    33.75% {
      opacity: 0.7;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl65_to {
    animation: eGeRfHEqDjl65_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl65_to__to {
    0% {
      transform: translate(655.2px, 73.020302px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    6.25% {
      transform: translate(655.798427px, 26.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    7.5% {
      transform: translate(652.726569px, 19.844367px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    8.75% {
      transform: translate(642.798427px, 13.25px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    20% {
      transform: translate(586.801035px, -20.855633px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    21.25% {
      transform: translate(582.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    22.5% {
      transform: translate(582.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    33.75% {
      transform: translate(582.9px, -115.228703px);
    }
    100% {
      transform: translate(582.9px, -115.228703px);
    }
  }
  #eGeRfHEqDjl65_tr {
    animation: eGeRfHEqDjl65_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl65_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    6.25% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(-60deg);
    }
    20% {
      transform: rotate(-60deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl65 {
    animation: eGeRfHEqDjl65_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl65_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    33.75% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl67 {
    animation-name: eGeRfHEqDjl67__m, eGeRfHEqDjl67_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eGeRfHEqDjl67__m {
    0% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    40% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    46.25% {
      d: path('M590.336,239.869727L590.336,186.052962L590.336001,185.943168L590.469859,186.052963');
    }
    47.5% {
      d: path('M590.336,237.445485L590.336,173.974308L590.336001,174.36741L589.968118,172.974309');
    }
    50% {
      d: path('M590.336,232.597L590.336,125.817L590.336001,127.215895L588.964636,125.817002');
    }
    66.25% {
      d: path('M590.336,125.761512L590.336,125.817L517.819,80.7272L517.818,79.9168');
    }
    72.5% {
      d: path('M554.077,103.313869L554.077,103.183848L517.819,80.7272L517.818,11.72012');
    }
    78.75% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
    100% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
  }
  @keyframes eGeRfHEqDjl67_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    72.5% {
      opacity: 1;
    }
    78.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl68_to {
    animation: eGeRfHEqDjl68_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl68_to__to {
    0% {
      transform: translate(655.5px, 137.771297px);
    }
    37.5% {
      transform: translate(655.5px, 137.771297px);
      animation-timing-function: cubic-bezier(0.417579, 0, 0.730131, 0.396118);
    }
    50% {
      transform: translate(655.5px, 27.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    51.25% {
      transform: translate(653.798427px, 20.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    52.5% {
      transform: translate(643.3352px, 13.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(586.801035px, -20.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(582.920607px, -27.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(582.960993px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(582.9px, -116.728703px);
    }
    100% {
      transform: translate(582.9px, -116.728703px);
    }
  }
  #eGeRfHEqDjl68_tr {
    animation: eGeRfHEqDjl68_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl68_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(-60deg);
    }
    65% {
      transform: rotate(-60deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl68 {
    animation: eGeRfHEqDjl68_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl68_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 0.7;
    }
    78.75% {
      opacity: 0.7;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl69_to {
    animation: eGeRfHEqDjl69_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl69_to__to {
    0% {
      transform: translate(655.5px, 137.771297px);
    }
    37.5% {
      transform: translate(655.5px, 137.771297px);
      animation-timing-function: cubic-bezier(0.417579, 0, 0.730131, 0.396118);
    }
    50% {
      transform: translate(655px, 27.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    51.25% {
      transform: translate(652.798427px, 19.844367px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    52.5% {
      transform: translate(642.726569px, 13.25px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(586.801035px, -20.855633px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(582.911986px, -27.262684px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(582.560992px, -38.762684px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(582.9px, -118.228703px);
    }
    100% {
      transform: translate(582.9px, -118.228703px);
    }
  }
  #eGeRfHEqDjl69_tr {
    animation: eGeRfHEqDjl69_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl69_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(-60deg);
    }
    65% {
      transform: rotate(-60deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl69 {
    animation: eGeRfHEqDjl69_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl69_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    78.75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl71 {
    animation-name: eGeRfHEqDjl71__m, eGeRfHEqDjl71_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eGeRfHEqDjl71__m {
    0% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    93.75% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    100% {
      d: path('M590.336,239.869727L590.336,177.052962L590.336001,176.943168L590.469859,177.052963');
    }
  }
  @keyframes eGeRfHEqDjl71_c_o {
    0% {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    91.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eGeRfHEqDjl72_to {
    animation: eGeRfHEqDjl72_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl72_to__to {
    0% {
      transform: translate(655px, 137.646297px);
    }
    90% {
      transform: translate(655px, 137.646297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(655.203897px, 71.825773px);
    }
  }
  #eGeRfHEqDjl72 {
    animation: eGeRfHEqDjl72_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl72_c_o {
    0% {
      opacity: 0;
    }
    88.75% {
      opacity: 0;
    }
    90% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #eGeRfHEqDjl73_to {
    animation: eGeRfHEqDjl73_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl73_to__to {
    0% {
      transform: translate(655px, 137.646297px);
    }
    90% {
      transform: translate(655px, 137.646297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(655.203897px, 71.825773px);
    }
  }
  #eGeRfHEqDjl73 {
    animation: eGeRfHEqDjl73_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl73_c_o {
    0% {
      opacity: 0;
    }
    88.75% {
      opacity: 0;
    }
    90% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #eGeRfHEqDjl78 {
    animation-name: eGeRfHEqDjl78__m, eGeRfHEqDjl78_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eGeRfHEqDjl78__m {
    0% {
      d: path('M278.994002,175.5213L278.994,145.053002L279.1,145.053004L279.029001,145.053002');
    }
    20% {
      d: path('M278.994002,175.5213L278.994,145.053002L279.1,145.053004L279.029001,145.053002');
    }
    53.75% {
      d: path('M278.994002,65.5213L278.994,-4.946998L279.1,-4.946996L279.029001,-4.946998');
    }
    100% {
      d: path('M278.994002,65.5213L278.994,-4.946998L279.1,-4.946996L279.029001,-4.946998');
    }
  }
  @keyframes eGeRfHEqDjl78_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    56.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl79_to {
    animation: eGeRfHEqDjl79_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl79_to__to {
    0% {
      transform: translate(344.25px, 52.271297px);
    }
    18.75% {
      transform: translate(344.25px, 52.271297px);
    }
    53.75% {
      transform: translate(344.25px, -117.728703px);
    }
    100% {
      transform: translate(344.25px, -117.728703px);
    }
  }
  #eGeRfHEqDjl79 {
    animation: eGeRfHEqDjl79_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl79_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 0.7;
    }
    53.75% {
      opacity: 0.7;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl80_to {
    animation: eGeRfHEqDjl80_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl80_to__to {
    0% {
      transform: translate(344.25px, 52.271297px);
    }
    18.75% {
      transform: translate(344.25px, 52.271297px);
    }
    53.75% {
      transform: translate(344.25px, -117.728703px);
    }
    100% {
      transform: translate(344.25px, -117.728703px);
    }
  }
  #eGeRfHEqDjl80 {
    animation: eGeRfHEqDjl80_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl80_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    53.75% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl82 {
    animation-name: eGeRfHEqDjl82__m, eGeRfHEqDjl82_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eGeRfHEqDjl82__m {
    0% {
      d: path('M278.994002,175.5213L278.994,145.053002L279.1,145.053004L279.029001,145.053002');
    }
    62.5% {
      d: path('M278.994002,175.5213L278.994,145.053002L279.1,145.053004L279.029001,145.053002');
    }
    96.25% {
      d: path('M278.994002,65.5213L278.994,-4.946998L279.1,-4.946996L279.029001,-4.946998');
    }
    100% {
      d: path('M278.994002,65.5213L278.994,-4.946998L279.1,-4.946996L279.029001,-4.946998');
    }
  }
  @keyframes eGeRfHEqDjl82_c_o {
    0% {
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    61.25% {
      opacity: 1;
    }
    92.5% {
      opacity: 1;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl83_to {
    animation: eGeRfHEqDjl83_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl83_to__to {
    0% {
      transform: translate(344.25px, 52.271297px);
    }
    61.25% {
      transform: translate(344.25px, 52.271297px);
    }
    96.25% {
      transform: translate(344.25px, -117.728703px);
    }
    100% {
      transform: translate(344.25px, -117.728703px);
    }
  }
  #eGeRfHEqDjl83 {
    animation: eGeRfHEqDjl83_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl83_c_o {
    0% {
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    61.25% {
      opacity: 0.7;
    }
    96.25% {
      opacity: 0.7;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl84_to {
    animation: eGeRfHEqDjl84_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl84_to__to {
    0% {
      transform: translate(344.25px, 52.271297px);
    }
    61.25% {
      transform: translate(344.25px, 52.271297px);
    }
    96.25% {
      transform: translate(344.25px, -117.728703px);
    }
    100% {
      transform: translate(344.25px, -117.728703px);
    }
  }
  #eGeRfHEqDjl84 {
    animation: eGeRfHEqDjl84_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl84_c_o {
    0% {
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    61.25% {
      opacity: 1;
    }
    96.25% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl90 {
    animation-name: eGeRfHEqDjl90__m, eGeRfHEqDjl90_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eGeRfHEqDjl90__m {
    0% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    6.25% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    12.5% {
      d: path('M292.146,235.913846L292.146,233.521298L292.363848,235.008315L292.363849,235.771298');
    }
    22.5% {
      d: path('M292.146,229.76L292.146,121.682L292.146003,122.622405L292.146002,122.622405');
    }
    36.25% {
      d: path('M292.146,121.885507L292.146,121.682L370.374,76.7761L370.374,77.208121');
    }
    47.5% {
      d: path('M346.303846,91.179651L346.303846,90.994836L370.374,76.7761L370.374,-20.851828');
    }
    52.5% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
    100% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
  }
  @keyframes eGeRfHEqDjl90_c_o {
    0% {
      opacity: 0;
    }
    6.25% {
      opacity: 0;
    }
    7.5% {
      opacity: 1;
    }
    43.75% {
      opacity: 1;
    }
    52.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl91_to {
    animation: eGeRfHEqDjl91_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl91_to__to {
    0% {
      transform: translate(357.5px, 139.246297px);
    }
    6.25% {
      transform: translate(357.5px, 139.246297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(357.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(361.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(367.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(432.801035px, -26.4px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(435.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(435.7px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    51.25% {
      transform: translate(435.7px, -120.728703px);
    }
    100% {
      transform: translate(435.7px, -120.728703px);
    }
  }
  #eGeRfHEqDjl91_tr {
    animation: eGeRfHEqDjl91_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl91_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(65deg);
    }
    35% {
      transform: rotate(65deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl91 {
    animation: eGeRfHEqDjl91_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl91_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 0.7;
    }
    51.25% {
      opacity: 0.7;
    }
    52.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl92_to {
    animation: eGeRfHEqDjl92_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl92_to__to {
    0% {
      transform: translate(357.5px, 139.246297px);
    }
    6.25% {
      transform: translate(357.5px, 139.246297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(357.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(362.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(368.202724px, 10.173441px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(433.202724px, -26.826559px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(435.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(435.7px, -39.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    51.25% {
      transform: translate(435.7px, -121.228703px);
    }
    100% {
      transform: translate(435.7px, -121.228703px);
    }
  }
  #eGeRfHEqDjl92_tr {
    animation: eGeRfHEqDjl92_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl92_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(65deg);
    }
    35% {
      transform: rotate(65deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl92 {
    animation: eGeRfHEqDjl92_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl92_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    51.25% {
      opacity: 0.8;
    }
    52.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl94 {
    animation-name: eGeRfHEqDjl94__m, eGeRfHEqDjl94_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eGeRfHEqDjl94__m {
    0% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    51.25% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    57.5% {
      d: path('M292.146,235.913846L292.146,233.521298L292.363848,235.008315L292.363849,235.771298');
    }
    67.5% {
      d: path('M292.146,229.76L292.146,121.682L292.146003,122.622405L292.146002,122.622405');
    }
    81.25% {
      d: path('M292.146,121.885507L292.146,121.682L370.374,76.7761L370.374,77.208121');
    }
    92.5% {
      d: path('M346.303846,91.179651L346.303846,90.994836L370.374,76.7761L370.374,-20.851828');
    }
    97.5% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
    100% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
  }
  @keyframes eGeRfHEqDjl94_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    88.75% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl95_to {
    animation: eGeRfHEqDjl95_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl95_to__to {
    0% {
      transform: translate(357.5px, 139.246297px);
    }
    51.25% {
      transform: translate(357.5px, 139.246297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    66.25% {
      transform: translate(357.5px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    67.5% {
      transform: translate(361.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    68.75% {
      transform: translate(367.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    80% {
      transform: translate(432.801035px, -26.4px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    81.25% {
      transform: translate(435.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    82.5% {
      transform: translate(435.7px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(435.7px, -120.728703px);
    }
    100% {
      transform: translate(435.7px, -120.728703px);
    }
  }
  #eGeRfHEqDjl95_tr {
    animation: eGeRfHEqDjl95_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl95_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(65deg);
    }
    80% {
      transform: rotate(65deg);
    }
    81.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl95 {
    animation: eGeRfHEqDjl95_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl95_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 0.7;
    }
    96.25% {
      opacity: 0.7;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl96_to {
    animation: eGeRfHEqDjl96_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl96_to__to {
    0% {
      transform: translate(357.5px, 139.246297px);
    }
    51.25% {
      transform: translate(357.5px, 139.246297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    66.25% {
      transform: translate(357.5px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    67.5% {
      transform: translate(362.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    68.75% {
      transform: translate(368.202724px, 10.173441px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    80% {
      transform: translate(433.202724px, -26.826559px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    81.25% {
      transform: translate(435.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    82.5% {
      transform: translate(435.7px, -39.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(435.7px, -121.228703px);
    }
    100% {
      transform: translate(435.7px, -121.228703px);
    }
  }
  #eGeRfHEqDjl96_tr {
    animation: eGeRfHEqDjl96_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl96_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(65deg);
    }
    80% {
      transform: rotate(65deg);
    }
    81.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl96 {
    animation: eGeRfHEqDjl96_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl96_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    96.25% {
      opacity: 0.8;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl101 {
    animation-name: eGeRfHEqDjl101__m, eGeRfHEqDjl101_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eGeRfHEqDjl101__m {
    0% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    18.75% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    23.75% {
      d: path('M226.994001,149.78715L226.994,145.868001L227.0645,146.865005L226.907042,146.549048');
    }
    28.75% {
      d: path('M226.994,138.053L226.994,121.683L227.029,121.677006L228.785083,121.045093');
    }
    40% {
      d: path('M227.029,121.683L226.994,121.683L333.531,76.7383L333.535,76.696467');
    }
    42.5% {
      d: path('M245.992733,113.68997L245.963955,113.721882L333.531,76.7383L333.535,65.343379');
    }
    50% {
      d: path('M312.365799,85.714363L312.358799,85.857968L333.531,76.7383L333.535,-14.892427');
    }
    55% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
    100% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
  }
  @keyframes eGeRfHEqDjl101_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    56.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl102_to {
    animation: eGeRfHEqDjl102_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl102_to__to {
    0% {
      transform: translate(291.8px, 52.271297px);
    }
    18.75% {
      transform: translate(291.8px, 52.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(291.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(295.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(304.3352px, 12.1px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(398.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -119.728703px);
    }
    100% {
      transform: translate(398.699999px, -119.728703px);
    }
  }
  #eGeRfHEqDjl102_tr {
    animation: eGeRfHEqDjl102_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl102_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(68deg);
    }
    30% {
      transform: rotate(68deg);
    }
    40% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl102 {
    animation: eGeRfHEqDjl102_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl102_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 0.7;
    }
    53.75% {
      opacity: 0.7;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl103_to {
    animation: eGeRfHEqDjl103_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl103_to__to {
    0% {
      transform: translate(291.8px, 52.271297px);
    }
    18.75% {
      transform: translate(291.8px, 52.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(291.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(295.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(305.474547px, 11.51295px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(398.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -120.228703px);
    }
    100% {
      transform: translate(398.699999px, -120.228703px);
    }
  }
  #eGeRfHEqDjl103_tr {
    animation: eGeRfHEqDjl103_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl103_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(68deg);
    }
    40% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl103 {
    animation: eGeRfHEqDjl103_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl103_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    53.75% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl105 {
    animation-name: eGeRfHEqDjl105__m, eGeRfHEqDjl105_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eGeRfHEqDjl105__m {
    0% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    58.75% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    63.75% {
      d: path('M226.994001,149.78715L226.994,145.868001L227.0645,146.865005L226.907042,146.549048');
    }
    68.75% {
      d: path('M226.994,138.053L226.994,121.683L227.029,121.677006L228.785083,121.045093');
    }
    82.5% {
      d: path('M227.029,121.683L226.994,121.683L333.531,76.7383L333.535,76.687317');
    }
    92.5% {
      d: path('M312.365799,85.714363L312.358799,85.857968L333.531,76.7383L333.535,-14.892427');
    }
    95% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
    100% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
  }
  @keyframes eGeRfHEqDjl105_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl106_to {
    animation: eGeRfHEqDjl106_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl106_to__to {
    0% {
      transform: translate(291.8px, 49.646297px);
    }
    58.75% {
      transform: translate(291.8px, 49.646297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(295.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(304.3352px, 12.1px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(398.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -119.378703px);
    }
    100% {
      transform: translate(398.699999px, -119.378703px);
    }
  }
  #eGeRfHEqDjl106_tr {
    animation: eGeRfHEqDjl106_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl106_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(68deg);
    }
    70% {
      transform: rotate(68deg);
    }
    81.25% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl106 {
    animation: eGeRfHEqDjl106_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl106_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 0.7;
    }
    95% {
      opacity: 0.7;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl107_to {
    animation: eGeRfHEqDjl107_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl107_to__to {
    0% {
      transform: translate(291.8px, 49.646297px);
    }
    58.75% {
      transform: translate(291.8px, 49.646297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(295.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(304.3352px, 12.1px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(396.801035px, -27.740845px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(398.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -119.378703px);
    }
    100% {
      transform: translate(398.699999px, -119.378703px);
    }
  }
  #eGeRfHEqDjl107_tr {
    animation: eGeRfHEqDjl107_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl107_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(68deg);
    }
    81.25% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl107 {
    animation: eGeRfHEqDjl107_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl107_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl113 {
    animation-name: eGeRfHEqDjl113__m, eGeRfHEqDjl113_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eGeRfHEqDjl113__m {
    0% {
      d: path(
        'M161.867,259.879L161.867,239.489998C161.867,239.489998,161.85,239.271299,161.85,239.271299L160.798427,238.396294'
      );
    }
    12.5% {
      d: path(
        'M161.867,259.879L161.867,239.489998C161.867,239.489998,161.85,239.271299,161.85,239.271299L160.798427,238.396294'
      );
    }
    22.5% {
      d: path(
        'M161.865485,229.879L161.867,121.677C161.867,121.677,161.870499,121.677,161.870499,121.677L163.015485,121.296257'
      );
    }
    33.75% {
      d: path(
        'M161.866849,132.5017L161.867,121.677C161.867,121.677,293.722976,77.674288,293.722976,77.674288L293.722975,77.671629'
      );
    }
    35% {
      d: path('M161.867,121.682L161.867,121.677C161.867,121.677,296.68,76.7222,296.68,76.7222L296.68,76.726257');
    }
    42.5% {
      d: path(
        'M235.303182,96.99487L296.683499,76.696465C296.683499,76.696465,296.68,76.7222,296.68,76.7222L296.68,3.623396'
      );
    }
    47.5% {
      d: path(
        'M288.5,79.422261L296.683499,76.687317C296.683499,76.687317,296.68,76.7222,296.68,76.7222L296.68,-19.691469'
      );
    }
    100% {
      d: path(
        'M288.5,79.422261L296.683499,76.687317C296.683499,76.687317,296.68,76.7222,296.68,76.7222L296.68,-19.691469'
      );
    }
  }
  @keyframes eGeRfHEqDjl113_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    46.25% {
      opacity: 1;
    }
    52.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl114_to {
    animation: eGeRfHEqDjl114_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl114_to__to {
    0% {
      transform: translate(226.81px, 140.246297px);
    }
    6.25% {
      transform: translate(226.81px, 140.246297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 29.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(230.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(239.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(359.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(361.920607px, -31.410292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -120.728703px);
    }
    100% {
      transform: translate(361.9px, -120.728703px);
    }
  }
  #eGeRfHEqDjl114_tr {
    animation: eGeRfHEqDjl114_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl114_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    23.75% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl114 {
    animation: eGeRfHEqDjl114_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl114_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 0.7;
    }
    48.75% {
      opacity: 0.7;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl115_to {
    animation: eGeRfHEqDjl115_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl115_to__to {
    0% {
      transform: translate(226.81px, 140.246297px);
    }
    6.25% {
      transform: translate(226.81px, 140.246297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 29.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(230.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(240.798427px, 12.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(359.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(361.851035px, -31.410292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -121.228703px);
    }
    100% {
      transform: translate(361.9px, -121.228703px);
    }
  }
  #eGeRfHEqDjl115_tr {
    animation: eGeRfHEqDjl115_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl115_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl115 {
    animation: eGeRfHEqDjl115_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl115_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    48.75% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl117 {
    animation-name: eGeRfHEqDjl117__m, eGeRfHEqDjl117_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eGeRfHEqDjl117__m {
    0% {
      d: path('M161.867,259.879L161.867,235.489998L161.85,235.271299L162.798427,234.396294');
    }
    57.5% {
      d: path('M161.867,259.879L161.867,235.489998L161.85,235.271299L162.798427,234.396294');
    }
    65% {
      d: path('M161.867,229.879L161.867,121.677L161.85,122.622406L161.867,121.683');
    }
    68.75% {
      d: path('M161.867,200.370727L161.867,121.677L187.621818,113.104168L187.621818,113.104169');
    }
    78.75% {
      d: path('M161.867,121.682L161.867,121.677L296.68,76.7222L296.68,76.687317');
    }
    81.25% {
      d: path('M182.848766,114.628534L182.911104,114.624313L296.68,76.7222L296.68,68.811911');
    }
    85% {
      d: path('M214.321416,104.048336L214.47726,104.045283L296.68,76.7222L296.68,25.698802');
    }
    87.5% {
      d: path('M235.303182,96.99487L235.521364,96.992597L296.68,76.7222L296.68,3.623396');
    }
    93.75% {
      d: path('M293.4,77.746257L296.676501,76.646257L296.68,76.646257L296.68,-19.691469');
    }
    100% {
      d: path('M293.4,77.746257L296.676501,76.646257L296.68,76.646257L296.68,-19.691469');
    }
  }
  @keyframes eGeRfHEqDjl117_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    91.25% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl118_to {
    animation: eGeRfHEqDjl118_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl118_to__to {
    0% {
      transform: translate(226.8px, 139.146297px);
    }
    52.5% {
      transform: translate(226.8px, 139.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    65% {
      transform: translate(226.798427px, 20.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    66.25% {
      transform: translate(230.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(239.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    78.75% {
      transform: translate(359.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    80% {
      transform: translate(361.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(361.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    93.75% {
      transform: translate(361.9px, -119.728703px);
    }
    100% {
      transform: translate(361.9px, -119.728703px);
    }
  }
  #eGeRfHEqDjl118_tr {
    animation: eGeRfHEqDjl118_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl118_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(72deg);
    }
    67.5% {
      transform: rotate(72deg);
    }
    78.75% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl118 {
    animation: eGeRfHEqDjl118_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl118_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 0.7;
    }
    93.75% {
      opacity: 0.7;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl119_to {
    animation: eGeRfHEqDjl119_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl119_to__to {
    0% {
      transform: translate(226.8px, 139.146297px);
    }
    52.5% {
      transform: translate(226.8px, 139.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    65% {
      transform: translate(226.798427px, 20.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    66.25% {
      transform: translate(231.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(240.798427px, 12.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    78.75% {
      transform: translate(359.801035px, -27.452471px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    80% {
      transform: translate(361.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(361.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    93.75% {
      transform: translate(361.9px, -120.228703px);
    }
    100% {
      transform: translate(361.9px, -120.228703px);
    }
  }
  #eGeRfHEqDjl119_tr {
    animation: eGeRfHEqDjl119_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl119_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(72deg);
    }
    78.75% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl119 {
    animation: eGeRfHEqDjl119_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl119_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    93.75% {
      opacity: 0.8;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl124 {
    animation-name: eGeRfHEqDjl124__m, eGeRfHEqDjl124_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eGeRfHEqDjl124__m {
    0% {
      d: path(
        'M96.711985,145.439636L96.7109,127.987317L96.711985,127.987317C96.711985,127.987317,96.711985,127.987317,96.711985,127.987317'
      );
    }
    1.25% {
      d: path(
        'M96.7109,145.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.711985,121.683001,96.711985,121.683001'
      );
    }
    6.25% {
      d: path(
        'M96.7109,138.590743L96.7109,121.68L138.005514,110.264966C138.005514,110.264966,140.444625,109.711954,140.444625,109.711954'
      );
    }
    8.75% {
      d: path(
        'M96.7109,135.000465L96.7109,121.68L163.798426,103.148788C163.798426,103.148788,163.798426,103.148788,163.798426,103.148788'
      );
    }
    16.25% {
      d: path(
        'M96.7109,124.229631L96.7109,121.68L258.531964,77.088615C258.531964,77.088615,258.531964,77.088614,258.531964,77.088614'
      );
    }
    17.5% {
      d: path(
        'M96.7109,122.271298L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817001,75.687789,259.817001,75.687789'
      );
    }
    30% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,-9.65625,259.817,-9.65625');
    }
    100% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,-9.65625,259.817,-9.65625');
    }
  }
  @keyframes eGeRfHEqDjl124_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    26.25% {
      opacity: 1;
    }
    33.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl124-stroke {
    animation: eGeRfHEqDjl124-stroke__t 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl124-stroke__t {
    0% {
      transform: matrix(0.84818, -0.775777, 0.775777, 0.84818, 0.059201, 1.058382);
    }
    6.25% {
      transform: matrix(0.84818, -0.775777, 0.775777, 0.84818, 0.059201, 1.058382);
    }
    15% {
      transform: matrix(0.473698, -0.515924, 0.515924, 0.473698, 0.55846, 0.644943);
    }
    23.75% {
      transform: matrix(0.243952, -0.472403, 0.472403, 0.243952, 0.693369, 0.5);
    }
    100% {
      transform: matrix(0.243952, -0.472403, 0.472403, 0.243952, 0.693369, 0.5);
    }
  }
  #eGeRfHEqDjl124-stroke-0 {
    animation: eGeRfHEqDjl124-stroke-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl124-stroke-0__c {
    0% {
      stop-color: rgba(126, 166, 255, 0);
    }
    6.25% {
      stop-color: rgba(126, 166, 255, 0);
    }
    15% {
      stop-color: rgba(126, 166, 255, 0);
    }
    23.75% {
      stop-color: rgba(126, 166, 255, 0);
    }
    100% {
      stop-color: rgba(126, 166, 255, 0);
    }
  }
  #eGeRfHEqDjl124-stroke-1 {
    animation: eGeRfHEqDjl124-stroke-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl124-stroke-1__c {
    0% {
      stop-color: #7ea6ff;
    }
    6.25% {
      stop-color: #7ea6ff;
    }
    15% {
      stop-color: #7ea6ff;
    }
    23.75% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #eGeRfHEqDjl125_to {
    animation: eGeRfHEqDjl125_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl125_to__to {
    0% {
      transform: translate(160.8px, 25.504306px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    1.25% {
      transform: translate(166.798427px, 15.088613px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    16.25% {
      transform: translate(322.801035px, -27.911387px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    17.5% {
      transform: translate(323.811985px, -31.712684px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    30% {
      transform: translate(323.9px, -122.212684px);
    }
    100% {
      transform: translate(323.9px, -122.212684px);
    }
  }
  #eGeRfHEqDjl125_tr {
    animation: eGeRfHEqDjl125_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl125_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(73deg);
    }
    16.25% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl125 {
    animation: eGeRfHEqDjl125_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl125_c_o {
    0% {
      opacity: 0.7;
    }
    30% {
      opacity: 0.7;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl126_to {
    animation: eGeRfHEqDjl126_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl126_to__to {
    0% {
      transform: translate(160.8px, 25.504306px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    1.25% {
      transform: translate(166.798427px, 15.157724px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    16.25% {
      transform: translate(322.801035px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    17.5% {
      transform: translate(323.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    30% {
      transform: translate(323.9px, -122.228703px);
    }
    100% {
      transform: translate(323.9px, -122.228703px);
    }
  }
  #eGeRfHEqDjl126_tr {
    animation: eGeRfHEqDjl126_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl126_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(73deg);
    }
    16.25% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl126 {
    animation: eGeRfHEqDjl126_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl126_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl128 {
    animation-name: eGeRfHEqDjl128__m, eGeRfHEqDjl128_c_o, eGeRfHEqDjl128_s_p;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eGeRfHEqDjl128__m {
    0% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    42.5% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    48.75% {
      d: path(
        'M96.7109,144.776311L96.7109,139.694875L96.839592,139.321751C96.839592,139.321751,96.867036,140.071751,96.867036,140.071751'
      );
    }
    52.5% {
      d: path(
        'M96.7109,145.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.908201,121.683001,96.908201,121.683001'
      );
    }
    60% {
      d: path(
        'M96.7109,134.021298L96.7109,121.68L170.897412,101.279844C170.897412,101.279844,170.897413,101.279844,170.897413,101.279844'
      );
    }
    66.25% {
      d: path(
        'M96.7109,124.229631L96.7109,121.68L259.817002,76.7624C259.817002,76.7624,259.817002,76.767317,259.817002,76.767317'
      );
    }
    67.5% {
      d: path(
        'M96.7109,122.271298L96.7109,121.68L259.817,76.767317C259.817,76.767317,259.817001,75.687789,259.817001,75.687789'
      );
    }
    72.5% {
      d: path(
        'M96.798427,121.438996L259.970615,77.139831L259.817,76.7298C259.817,76.7298,259.817001,47.289957,259.817001,47.289957'
      );
    }
    78.75% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
    100% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
  }
  @keyframes eGeRfHEqDjl128_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    78.75% {
      opacity: 1;
    }
    83.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes eGeRfHEqDjl128_s_p {
    0% {
      stroke: url(#eGeRfHEqDjl128-stroke);
    }
    56.25% {
      stroke: url(#eGeRfHEqDjl128-stroke);
    }
    66.25% {
      stroke: url(#eGeRfHEqDjl128-stroke);
    }
    75% {
      stroke: url(#eGeRfHEqDjl128-stroke);
    }
    76.25% {
      stroke: url(#eGeRfHEqDjl128-stroke-g1);
    }
    100% {
      stroke: url(#eGeRfHEqDjl128-stroke-g1);
    }
  }
  #eGeRfHEqDjl128-stroke {
    animation: eGeRfHEqDjl128-stroke__t 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl128-stroke__t {
    0% {
      transform: matrix(1.091762, -1.399522, 1.399522, 1.091762, 0.14437, 1.756939);
    }
    56.25% {
      transform: matrix(1.091762, -1.399522, 1.399522, 1.091762, 0.14437, 1.756939);
    }
    66.25% {
      transform: matrix(0.59208, -0.685608, 0.685608, 0.59208, 0.418744, 0.969277);
    }
    75% {
      transform: matrix(0.59208, -0.685608, 0.685608, 0.59208, 0.418744, 0.969277);
    }
    100% {
      transform: matrix(0.59208, -0.685608, 0.685608, 0.59208, 0.418744, 0.969277);
    }
  }
  #eGeRfHEqDjl128-stroke-0 {
    animation: eGeRfHEqDjl128-stroke-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl128-stroke-0__c {
    0% {
      stop-color: rgba(96, 105, 202, 0);
    }
    56.25% {
      stop-color: rgba(96, 105, 202, 0);
    }
    66.25% {
      stop-color: rgba(96, 105, 202, 0);
    }
    75% {
      stop-color: rgba(96, 105, 202, 0);
    }
    100% {
      stop-color: rgba(96, 105, 202, 0);
    }
  }
  #eGeRfHEqDjl128-stroke-1 {
    animation: eGeRfHEqDjl128-stroke-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl128-stroke-1__c {
    0% {
      stop-color: #7ea6ff;
    }
    56.25% {
      stop-color: #7ea6ff;
    }
    66.25% {
      stop-color: #7ea6ff;
    }
    75% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #eGeRfHEqDjl129_to {
    animation: eGeRfHEqDjl129_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl129_to__to {
    0% {
      transform: translate(161.8px, 50.787316px);
    }
    42.5% {
      transform: translate(161.8px, 50.787316px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(161.798427px, 21.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(166.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    66.25% {
      transform: translate(322.801035px, -27.448195px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(324.811985px, -31.712684px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(324.9px, -120.728703px);
    }
    100% {
      transform: translate(324.9px, -120.728703px);
    }
  }
  #eGeRfHEqDjl129_tr {
    animation: eGeRfHEqDjl129_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl129_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(73deg);
    }
    66.25% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl129 {
    animation: eGeRfHEqDjl129_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl129_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 0.7;
    }
    81.25% {
      opacity: 0.7;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl130_to {
    animation: eGeRfHEqDjl130_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl130_to__to {
    0% {
      transform: translate(161.8px, 50.787316px);
    }
    42.5% {
      transform: translate(161.8px, 50.787316px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(161.798427px, 21.687316px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(166.798427px, 15.671297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    66.25% {
      transform: translate(321.801035px, -27.560064px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(324.811985px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(324.9px, -121.228703px);
    }
    100% {
      transform: translate(324.9px, -121.228703px);
    }
  }
  #eGeRfHEqDjl130_tr {
    animation: eGeRfHEqDjl130_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl130_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(73deg);
    }
    66.25% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl130 {
    animation: eGeRfHEqDjl130_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl130_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    81.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl132 {
    animation-name: eGeRfHEqDjl132__m, eGeRfHEqDjl132_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eGeRfHEqDjl132__m {
    0% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    96.25% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    100% {
      d: path(
        'M96.7109,144.112987L96.7109,141.038125L96.867035,140.414251C96.867035,140.414251,96.839592,141.664251,96.839592,141.664251'
      );
    }
  }
  @keyframes eGeRfHEqDjl132_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eGeRfHEqDjl133_to {
    animation: eGeRfHEqDjl133_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl133_to__to {
    0% {
      transform: translate(161.8px, 49.521297px);
    }
    96.25% {
      transform: translate(161.8px, 49.521297px);
    }
    100% {
      transform: translate(161.8px, 24.987316px);
    }
  }
  #eGeRfHEqDjl133 {
    animation: eGeRfHEqDjl133_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl133_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #eGeRfHEqDjl134_to {
    animation: eGeRfHEqDjl134_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl134_to__to {
    0% {
      transform: translate(161.8px, 49.521297px);
    }
    96.25% {
      transform: translate(161.8px, 49.521297px);
    }
    100% {
      transform: translate(161.8px, 24.987316px);
    }
  }
  #eGeRfHEqDjl134 {
    animation: eGeRfHEqDjl134_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl134_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eGeRfHEqDjl140 {
    animation-name: eGeRfHEqDjl140__m, eGeRfHEqDjl140_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eGeRfHEqDjl140__m {
    0% {
      d: path(
        'M32.0957,249.88C32.0957,249.88,32.0957,240.521298,32.0957,240.521298L32.0957,240.521298L33.0957,239.524434'
      );
    }
    11.25% {
      d: path(
        'M32.0957,249.88C32.0957,249.88,32.0957,240.521298,32.0957,240.521298L32.0957,240.521298L33.0957,239.524434'
      );
    }
    16.25% {
      d: path(
        'M32.0957,243.213333C32.0957,243.213333,32.0957,206.968195,32.0957,206.968195L32.0957,206.968195L33.10667,206.224994'
      );
    }
    26.25% {
      d: path(
        'M32.0957,229.88C32.0957,229.88,32.0957,121.861988,32.0957,121.861988L32.0957,121.861988L33.12861,121.626113'
      );
    }
    27.5% {
      d: path(
        'M32.0957,224.385463C32.0957,224.385463,32.0957,121.82519,32.0957,121.82519L41.685449,119.555445L42.201904,119.439646'
      );
    }
    41.25% {
      d: path(
        'M32.0957,129.146811C32.0957,129.146811,32.0957,121.870117,32.0957,121.870117L218.801035,77.955237L218.801035,77.954434'
      );
    }
    42.5% {
      d: path(
        'M32.0957,121.870117C32.0957,121.870117,32.0957,121.870117,32.0957,121.870117L222.955,76.874434L222.920607,76.874434'
      );
    }
    55% {
      d: path(
        'M203.86907,81.374002C203.86907,81.374002,203.8697,81.374002,203.8697,81.374002L222.955,76.874434L222.951561,-10.027791'
      );
    }
    100% {
      d: path(
        'M203.86907,81.374002C203.86907,81.374002,203.8697,81.374002,203.8697,81.374002L222.955,76.874434L222.951561,-10.027791'
      );
    }
  }
  @keyframes eGeRfHEqDjl140_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 1;
    }
    51.25% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl141_to {
    animation: eGeRfHEqDjl141_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl141_to__to {
    0% {
      transform: translate(96.798427px, 143.537316px);
    }
    11.25% {
      transform: translate(96.798427px, 143.537316px);
    }
    26.25% {
      transform: translate(97.111986px, 19.771297px);
    }
    27.5% {
      transform: translate(100.798427px, 15.771297px);
    }
    41.25% {
      transform: translate(284.801035px, -27.679029px);
    }
    42.5% {
      transform: translate(287.920607px, -33.028703px);
    }
    56.25% {
      transform: translate(287.921986px, -120.728703px);
    }
    100% {
      transform: translate(287.921986px, -120.728703px);
    }
  }
  #eGeRfHEqDjl141_tr {
    animation: eGeRfHEqDjl141_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl141_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(75deg);
    }
    41.25% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl141 {
    animation: eGeRfHEqDjl141_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl141_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.7;
    }
    56.25% {
      opacity: 0.7;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl142_to {
    animation: eGeRfHEqDjl142_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl142_to__to {
    0% {
      transform: translate(96.798427px, 142.237316px);
    }
    11.25% {
      transform: translate(96.798427px, 142.237316px);
    }
    26.25% {
      transform: translate(97.111986px, 19.643116px);
    }
    27.5% {
      transform: translate(100.798427px, 15.771297px);
    }
    41.25% {
      transform: translate(284.833827px, -27.703774px);
    }
    42.5% {
      transform: translate(288.011986px, -33.028703px);
    }
    56.25% {
      transform: translate(288.011986px, -120.728703px);
    }
    100% {
      transform: translate(288.011986px, -120.728703px);
    }
  }
  #eGeRfHEqDjl142_tr {
    animation: eGeRfHEqDjl142_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl142_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(75deg);
    }
    41.25% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl142 {
    animation: eGeRfHEqDjl142_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl142_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 1;
    }
    56.25% {
      opacity: 1;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl144 {
    animation-name: eGeRfHEqDjl144__m, eGeRfHEqDjl144_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eGeRfHEqDjl144__m {
    0% {
      d: path('M32.0957,249.88L32.0957,239.147317L32.091986,239.147317L32.091986,239.146294');
    }
    62.5% {
      d: path('M32.0957,249.88L32.0957,239.147317L32.091986,239.147317L32.091986,239.146294');
    }
    68.75% {
      d: path('M32.0957,233.213333L32.0957,145.083968L32.095081,146.169913L32.095081,147.043052');
    }
    70% {
      d: path('M32.0957,229.88L32.0957,130.271298L33.0957,126.574432L32.0957,128.622403');
    }
    71.25% {
      d: path('M32.0957,222.151237L32.0957,121.657491L34.956396,120.96432L37.4997,120.380611');
    }
    87.5% {
      d: path('M32.0957,121.677317L32.0957,121.678L222.955,76.73L222.951986,76.45812');
    }
    95% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
    100% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
  }
  @keyframes eGeRfHEqDjl144_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 1;
    }
    92.5% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl145_to {
    animation: eGeRfHEqDjl145_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl145_to__to {
    0% {
      transform: translate(96.8px, 140.121297px);
    }
    57.5% {
      transform: translate(96.8px, 140.121297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(96.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(100.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(114.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    86.25% {
      transform: translate(283.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(287.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -120.728703px);
    }
    100% {
      transform: translate(287.9px, -120.728703px);
    }
  }
  #eGeRfHEqDjl145_tr {
    animation: eGeRfHEqDjl145_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl145_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(75deg);
    }
    86.25% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl145 {
    animation: eGeRfHEqDjl145_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl145_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 0.7;
    }
    96.25% {
      opacity: 0.7;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eGeRfHEqDjl146_to {
    animation: eGeRfHEqDjl146_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl146_to__to {
    0% {
      transform: translate(96.8px, 140.121297px);
    }
    57.5% {
      transform: translate(96.8px, 140.121297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(96.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(100.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(114.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.357385, 0.28504, 0.680746, 0.629112);
    }
    78.75% {
      transform: translate(185.570936px, -4.302775px);
      animation-timing-function: cubic-bezier(0.323457, 0.318946, 0.641855, 0.665929);
    }
    86.25% {
      transform: translate(283.801035px, -27.302775px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(287.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -120.228703px);
    }
    100% {
      transform: translate(287.9px, -120.228703px);
    }
  }
  #eGeRfHEqDjl146_tr {
    animation: eGeRfHEqDjl146_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl146_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(75deg);
    }
    86.25% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eGeRfHEqDjl146 {
    animation: eGeRfHEqDjl146_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eGeRfHEqDjl146_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 1;
    }
    96.25% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesBg11;
