// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesBg10 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)', // Replace all <stop id="...-stroke-1" stopColor="#initial-color" /> with this value
  };

  return (
    <AnimatedSvg
      id="eLFwgUQ8cWq1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 885 228"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="885"
      height="228"
      className="source-background"
      {...props}
    >
      <defs>
        <filter id="eLFwgUQ8cWq8-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq8-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eLFwgUQ8cWq8-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq8-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eLFwgUQ8cWq8-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq8-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq8-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq8-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eLFwgUQ8cWq9-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="matrix(0.42908 0.588756 -0.588756 0.42908 0.37092 0.044423)"
        >
          <stop
            id="eLFwgUQ8cWq9-stroke-0"
            offset="0%"
            stopColor={selected === 9 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eLFwgUQ8cWq9-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eLFwgUQ8cWq10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq10-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eLFwgUQ8cWq10-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq10-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eLFwgUQ8cWq10-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq10-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq10-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq10-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eLFwgUQ8cWq10-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eLFwgUQ8cWq12-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq12-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eLFwgUQ8cWq12-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq12-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eLFwgUQ8cWq12-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq12-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq12-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq12-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eLFwgUQ8cWq13-stroke"
          x1="0.5"
          y1="0.13204"
          x2="0.870487"
          y2="0.866326"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eLFwgUQ8cWq13-stroke-0"
            offset="0%"
            stopColor={selected === 9 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eLFwgUQ8cWq13-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eLFwgUQ8cWq14-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq14-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eLFwgUQ8cWq14-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq14-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eLFwgUQ8cWq14-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq14-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq14-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq14-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eLFwgUQ8cWq14-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eLFwgUQ8cWq16-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq16-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eLFwgUQ8cWq16-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq16-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eLFwgUQ8cWq16-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq16-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq16-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq16-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eLFwgUQ8cWq17-stroke"
          x1="23.00811"
          y1="0.07533"
          x2="23.00811"
          y2="0.599254"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eLFwgUQ8cWq17-stroke-0"
            offset="0%"
            stopColor={selected === 9 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eLFwgUQ8cWq17-stroke-1"
            offset="52%"
            stopColor={selected === 9 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eLFwgUQ8cWq17-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eLFwgUQ8cWq18-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq18-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eLFwgUQ8cWq18-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq18-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eLFwgUQ8cWq18-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq18-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq18-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq18-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eLFwgUQ8cWq18-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eLFwgUQ8cWq23-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq23-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eLFwgUQ8cWq23-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq23-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eLFwgUQ8cWq23-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq23-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq23-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq23-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eLFwgUQ8cWq24-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eLFwgUQ8cWq24-stroke-0"
            offset="0%"
            stopColor={selected === 8 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eLFwgUQ8cWq24-stroke-1"
            offset="50%"
            stopColor={selected === 8 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eLFwgUQ8cWq24-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eLFwgUQ8cWq25-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq25-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eLFwgUQ8cWq25-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq25-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eLFwgUQ8cWq25-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq25-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq25-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq25-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eLFwgUQ8cWq25-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eLFwgUQ8cWq27-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq27-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eLFwgUQ8cWq27-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq27-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eLFwgUQ8cWq27-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq27-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq27-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq27-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eLFwgUQ8cWq28-stroke"
          x1="0.35387"
          y1="-0.005515"
          x2="0.379197"
          y2="0.688654"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eLFwgUQ8cWq28-stroke-0"
            offset="0%"
            stopColor={selected === 8 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eLFwgUQ8cWq28-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="eLFwgUQ8cWq29-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq29-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eLFwgUQ8cWq29-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq29-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eLFwgUQ8cWq29-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq29-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq29-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq29-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eLFwgUQ8cWq29-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eLFwgUQ8cWq35-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq35-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eLFwgUQ8cWq35-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq35-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eLFwgUQ8cWq35-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq35-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq35-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq35-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eLFwgUQ8cWq36-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eLFwgUQ8cWq36-stroke-0"
            offset="0%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eLFwgUQ8cWq36-stroke-1"
            offset="51%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eLFwgUQ8cWq36-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eLFwgUQ8cWq37-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq37-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eLFwgUQ8cWq37-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq37-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eLFwgUQ8cWq37-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq37-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq37-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq37-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eLFwgUQ8cWq37-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eLFwgUQ8cWq39-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq39-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eLFwgUQ8cWq39-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq39-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eLFwgUQ8cWq39-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq39-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq39-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq39-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eLFwgUQ8cWq40-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eLFwgUQ8cWq40-stroke-0"
            offset="0%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eLFwgUQ8cWq40-stroke-1"
            offset="50%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eLFwgUQ8cWq40-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eLFwgUQ8cWq41-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq41-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eLFwgUQ8cWq41-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq41-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eLFwgUQ8cWq41-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq41-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq41-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq41-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eLFwgUQ8cWq41-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eLFwgUQ8cWq43-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq43-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eLFwgUQ8cWq43-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq43-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eLFwgUQ8cWq43-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq43-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq43-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq43-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eLFwgUQ8cWq44-stroke"
          x1="-302.832393"
          y1="0.043333"
          x2="-302.832393"
          y2="0.875027"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eLFwgUQ8cWq44-stroke-0"
            offset="0%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eLFwgUQ8cWq44-stroke-1"
            offset="52%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eLFwgUQ8cWq44-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eLFwgUQ8cWq45-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq45-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eLFwgUQ8cWq45-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq45-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eLFwgUQ8cWq45-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq45-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq45-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq45-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eLFwgUQ8cWq45-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eLFwgUQ8cWq50-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq50-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eLFwgUQ8cWq50-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq50-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eLFwgUQ8cWq50-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq50-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq50-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq50-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eLFwgUQ8cWq51-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eLFwgUQ8cWq51-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eLFwgUQ8cWq51-stroke-1"
            offset="51%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eLFwgUQ8cWq51-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eLFwgUQ8cWq52-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq52-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eLFwgUQ8cWq52-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq52-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eLFwgUQ8cWq52-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq52-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq52-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq52-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eLFwgUQ8cWq52-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eLFwgUQ8cWq54-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq54-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eLFwgUQ8cWq54-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq54-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eLFwgUQ8cWq54-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq54-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq54-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq54-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eLFwgUQ8cWq55-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eLFwgUQ8cWq55-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eLFwgUQ8cWq55-stroke-1"
            offset="50%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eLFwgUQ8cWq55-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eLFwgUQ8cWq56-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq56-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eLFwgUQ8cWq56-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq56-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eLFwgUQ8cWq56-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq56-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq56-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq56-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eLFwgUQ8cWq56-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eLFwgUQ8cWq62-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq62-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eLFwgUQ8cWq62-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq62-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eLFwgUQ8cWq62-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq62-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq62-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq62-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eLFwgUQ8cWq63-stroke"
          x1="-62.601897"
          y1="0.100244"
          x2="-62.601897"
          y2="0.893475"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eLFwgUQ8cWq63-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eLFwgUQ8cWq63-stroke-1"
            offset="51%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eLFwgUQ8cWq63-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eLFwgUQ8cWq64-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq64-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eLFwgUQ8cWq64-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq64-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eLFwgUQ8cWq64-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq64-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq64-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq64-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eLFwgUQ8cWq64-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eLFwgUQ8cWq66-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq66-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eLFwgUQ8cWq66-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq66-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eLFwgUQ8cWq66-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq66-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq66-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq66-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eLFwgUQ8cWq67-stroke"
          x1="-40.916568"
          y1="0.058329"
          x2="-40.916568"
          y2="0.944892"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eLFwgUQ8cWq67-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eLFwgUQ8cWq67-stroke-1"
            offset="52%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eLFwgUQ8cWq67-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eLFwgUQ8cWq68-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq68-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eLFwgUQ8cWq68-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq68-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eLFwgUQ8cWq68-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq68-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq68-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq68-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eLFwgUQ8cWq68-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eLFwgUQ8cWq70-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq70-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eLFwgUQ8cWq70-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq70-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eLFwgUQ8cWq70-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq70-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq70-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq70-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eLFwgUQ8cWq71-stroke"
          x1="-133.259154"
          y1="0.064793"
          x2="-133.259154"
          y2="0.753021"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eLFwgUQ8cWq71-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eLFwgUQ8cWq71-stroke-1"
            offset="53%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eLFwgUQ8cWq71-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eLFwgUQ8cWq72-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq72-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eLFwgUQ8cWq72-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq72-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eLFwgUQ8cWq72-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq72-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq72-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq72-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eLFwgUQ8cWq72-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eLFwgUQ8cWq76-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq76-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eLFwgUQ8cWq76-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq76-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eLFwgUQ8cWq76-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq76-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq76-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq76-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eLFwgUQ8cWq77-stroke"
          x1="0"
          y1="0.041281"
          x2="0.060771"
          y2="1.028927"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eLFwgUQ8cWq77-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eLFwgUQ8cWq77-stroke-1"
            offset="52%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eLFwgUQ8cWq77-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eLFwgUQ8cWq78-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq78-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eLFwgUQ8cWq78-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq78-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eLFwgUQ8cWq78-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq78-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq78-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq78-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eLFwgUQ8cWq78-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eLFwgUQ8cWq80-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq80-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eLFwgUQ8cWq80-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq80-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eLFwgUQ8cWq80-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq80-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq80-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq80-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eLFwgUQ8cWq81-stroke"
          x1="0"
          y1="0.041281"
          x2="0.060771"
          y2="1.028927"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eLFwgUQ8cWq81-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eLFwgUQ8cWq81-stroke-1"
            offset="52%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eLFwgUQ8cWq81-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eLFwgUQ8cWq82-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq82-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eLFwgUQ8cWq82-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq82-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eLFwgUQ8cWq82-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq82-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq82-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq82-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eLFwgUQ8cWq82-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eLFwgUQ8cWq87-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq87-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eLFwgUQ8cWq87-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq87-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eLFwgUQ8cWq87-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq87-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq87-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq87-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eLFwgUQ8cWq88-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.021396"
          y2="1.049288"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eLFwgUQ8cWq88-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eLFwgUQ8cWq88-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eLFwgUQ8cWq89-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq89-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eLFwgUQ8cWq89-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq89-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eLFwgUQ8cWq89-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq89-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq89-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq89-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eLFwgUQ8cWq89-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eLFwgUQ8cWq91-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq91-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eLFwgUQ8cWq91-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq91-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eLFwgUQ8cWq91-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq91-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq91-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq91-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eLFwgUQ8cWq92-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.021396"
          y2="1.049288"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eLFwgUQ8cWq92-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eLFwgUQ8cWq92-stroke-1"
            offset="53%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eLFwgUQ8cWq92-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eLFwgUQ8cWq93-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq93-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eLFwgUQ8cWq93-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq93-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eLFwgUQ8cWq93-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq93-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq93-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq93-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eLFwgUQ8cWq93-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eLFwgUQ8cWq99-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq99-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eLFwgUQ8cWq99-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq99-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eLFwgUQ8cWq99-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq99-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq99-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq99-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eLFwgUQ8cWq100-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="matrix(0.232747 0.921366 -0.921366 0.232747 -0.054141 -0.038344)"
        >
          <stop
            id="eLFwgUQ8cWq100-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eLFwgUQ8cWq100-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
          <stop id="eLFwgUQ8cWq100-stroke-2" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <linearGradient
          id="eLFwgUQ8cWq100-stroke-g1"
          x1="-0.054141"
          y1="-0.038344"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eLFwgUQ8cWq100-stroke-g1-0" offset="1%" stopColor="#7ea6ff" />
          <stop id="eLFwgUQ8cWq100-stroke-g1-1" offset="51%" stopColor="#6069ca" />
          <stop id="eLFwgUQ8cWq100-stroke-g1-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <linearGradient
          id="eLFwgUQ8cWq100-stroke-g2"
          x1="-0.054141"
          y1="-0.038344"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eLFwgUQ8cWq100-stroke-g2-0" offset="0%" stopColor="#7ea6ff" />
          <stop id="eLFwgUQ8cWq100-stroke-g2-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <linearGradient
          id="eLFwgUQ8cWq100-stroke-g3"
          x1="-0.054141"
          y1="-0.038344"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eLFwgUQ8cWq100-stroke-g3-0" offset="0%" stopColor="#7ea6ff" />
          <stop id="eLFwgUQ8cWq100-stroke-g3-1" offset="53%" stopColor="#6069ca" />
          <stop id="eLFwgUQ8cWq100-stroke-g3-2" offset="100%" stopColor="rgba(64,65,69,0)" />
        </linearGradient>
        <linearGradient
          id="eLFwgUQ8cWq100-stroke-g4"
          x1="0.269781"
          y1="-0.033723"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eLFwgUQ8cWq100-stroke-g4-0" offset="0%" stopColor="#7ea6ff" />
          <stop id="eLFwgUQ8cWq100-stroke-g4-1" offset="100%" stopColor="rgba(64,65,69,0)" />
        </linearGradient>
        <filter id="eLFwgUQ8cWq101-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq101-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eLFwgUQ8cWq101-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq101-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eLFwgUQ8cWq101-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq101-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq101-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq101-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eLFwgUQ8cWq101-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eLFwgUQ8cWq103-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq103-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eLFwgUQ8cWq103-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq103-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eLFwgUQ8cWq103-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq103-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq103-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq103-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eLFwgUQ8cWq104-stroke"
          x1="8.968673"
          y1="0.095987"
          x2="8.968673"
          y2="0.885011"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eLFwgUQ8cWq104-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eLFwgUQ8cWq104-stroke-1"
            offset="52%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eLFwgUQ8cWq104-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eLFwgUQ8cWq105-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq105-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eLFwgUQ8cWq105-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq105-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eLFwgUQ8cWq105-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq105-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq105-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq105-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eLFwgUQ8cWq105-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eLFwgUQ8cWq110-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq110-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eLFwgUQ8cWq110-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq110-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eLFwgUQ8cWq110-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq110-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq110-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq110-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eLFwgUQ8cWq111-stroke"
          x1="0.097926"
          y1="0.940361"
          x2="1.104987"
          y2="0.195114"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eLFwgUQ8cWq111-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="eLFwgUQ8cWq111-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eLFwgUQ8cWq112-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq112-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eLFwgUQ8cWq112-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq112-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eLFwgUQ8cWq112-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq112-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq112-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq112-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eLFwgUQ8cWq112-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eLFwgUQ8cWq114-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq114-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eLFwgUQ8cWq114-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq114-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eLFwgUQ8cWq114-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq114-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq114-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq114-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eLFwgUQ8cWq115-stroke"
          x1="0.641634"
          y1="0.865093"
          x2="1.094031"
          y2="0.227742"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eLFwgUQ8cWq115-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="eLFwgUQ8cWq115-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eLFwgUQ8cWq116-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq116-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eLFwgUQ8cWq116-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq116-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eLFwgUQ8cWq116-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq116-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq116-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq116-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eLFwgUQ8cWq116-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eLFwgUQ8cWq118-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq118-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eLFwgUQ8cWq118-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq118-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eLFwgUQ8cWq118-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq118-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq118-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq118-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eLFwgUQ8cWq119-stroke"
          x1="-0.142435"
          y1="1.238891"
          x2="0.883069"
          y2="-0.083996"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eLFwgUQ8cWq119-stroke-0" offset="2%" stopColor="rgba(64,73,168,0)" />
          <stop
            id="eLFwgUQ8cWq119-stroke-1"
            offset="51%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eLFwgUQ8cWq119-stroke-2"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eLFwgUQ8cWq120-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq120-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eLFwgUQ8cWq120-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq120-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eLFwgUQ8cWq120-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq120-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq120-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq120-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eLFwgUQ8cWq120-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eLFwgUQ8cWq121-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq121-filter-blur-0" stdDeviation="0,0" result="result" />
        </filter>
        <filter id="eLFwgUQ8cWq126-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq126-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eLFwgUQ8cWq126-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq126-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eLFwgUQ8cWq126-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq126-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq126-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq126-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eLFwgUQ8cWq127-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="matrix(0 0.653183 -0.653183 0 3.395403 0.105802)"
        >
          <stop
            id="eLFwgUQ8cWq127-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eLFwgUQ8cWq127-stroke-1"
            offset="55%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eLFwgUQ8cWq127-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eLFwgUQ8cWq128-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq128-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eLFwgUQ8cWq128-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq128-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eLFwgUQ8cWq128-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq128-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq128-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq128-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eLFwgUQ8cWq128-filter-blur-0" stdDeviation="3,3" result="result" />
        </filter>
        <filter id="eLFwgUQ8cWq129-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq129-filter-blur-0" stdDeviation="0,0" result="result" />
        </filter>
        <filter id="eLFwgUQ8cWq130-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq130-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eLFwgUQ8cWq130-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq130-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eLFwgUQ8cWq130-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq130-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq130-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq130-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eLFwgUQ8cWq131-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.178606"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eLFwgUQ8cWq131-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eLFwgUQ8cWq131-stroke-1"
            offset="44%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eLFwgUQ8cWq131-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eLFwgUQ8cWq132-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eLFwgUQ8cWq132-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eLFwgUQ8cWq132-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eLFwgUQ8cWq132-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eLFwgUQ8cWq132-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eLFwgUQ8cWq132-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eLFwgUQ8cWq132-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eLFwgUQ8cWq132-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eLFwgUQ8cWq132-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
      </defs>
      <g transform="translate(0-1.25894)" mask="url(#eLFwgUQ8cWq134)">
        <g transform="translate(.000001 0)">
          <g transform="translate(.088013 1.012683)">
            <g transform="translate(-7 0)">
              <path
                d="M853.309,232.641v-111.14137L665.296,76.728v-73.62253"
                transform="matrix(.993835 0 0 1.020627 4.6811-2.431371)"
                fill="none"
                stroke="#6069ca"
              />
              <g transform="translate(-65 105.000001)" filter="url(#eLFwgUQ8cWq8-filter)">
                <path
                  id="eLFwgUQ8cWq9"
                  d="M853.309,172.852398v-51.352768l-88.567326-21.284376-.520849-.000001"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  fill="none"
                  stroke="url(#eLFwgUQ8cWq9-stroke)"
                  strokeLinecap="round"
                />
                <g id="eLFwgUQ8cWq10_to" transform="translate(828.317602,-5.431736)">
                  <g id="eLFwgUQ8cWq10_tr" transform="rotate(-75)">
                    <rect
                      id="eLFwgUQ8cWq10"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0.7"
                      filter="url(#eLFwgUQ8cWq10-filter)"
                      fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eLFwgUQ8cWq11_to" transform="translate(828.317602,-5.431736)">
                  <g id="eLFwgUQ8cWq11_tr" transform="rotate(-75)">
                    <rect
                      id="eLFwgUQ8cWq11"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eLFwgUQ8cWq12-filter)">
                <path
                  id="eLFwgUQ8cWq13"
                  d="M853.309,252.2368v-9.24321-1.766709-3.184316"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eLFwgUQ8cWq13-stroke)"
                  strokeLinecap="round"
                />
                <g id="eLFwgUQ8cWq14_to" transform="translate(917.5,138.546297)">
                  <g id="eLFwgUQ8cWq14_tr" transform="rotate(0)">
                    <rect
                      id="eLFwgUQ8cWq14"
                      width="8.235294"
                      height="14.117647"
                      rx="0"
                      ry="0"
                      transform="scale(0.85,0.85) translate(-4.117647,-7.058824)"
                      opacity="0"
                      filter="url(#eLFwgUQ8cWq14-filter)"
                      fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eLFwgUQ8cWq15_to" transform="translate(917.5,138.546297)">
                  <g id="eLFwgUQ8cWq15_tr" transform="rotate(0)">
                    <rect
                      id="eLFwgUQ8cWq15"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eLFwgUQ8cWq16-filter)">
                <path
                  id="eLFwgUQ8cWq17"
                  d="M853.309,252.2368v-9.24321-1.766709-3.184316"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eLFwgUQ8cWq17-stroke)"
                  strokeLinecap="round"
                />
                <g id="eLFwgUQ8cWq18_to" transform="translate(917.5,140.146297)">
                  <g id="eLFwgUQ8cWq18_tr" transform="rotate(0)">
                    <rect
                      id="eLFwgUQ8cWq18"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eLFwgUQ8cWq18-filter)"
                      fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eLFwgUQ8cWq19_to" transform="translate(917.5,140.146297)">
                  <g id="eLFwgUQ8cWq19_tr" transform="rotate(0)">
                    <rect
                      id="eLFwgUQ8cWq19"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 9 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(-12 0)" mask="url(#eLFwgUQ8cWq31)">
              <g>
                <path d="M781.258,138.241v-16.559L628.386,76.7624v-76.41865" fill="none" stroke="#6069ca" />
                <g transform="translate(-65 105.000001)" filter="url(#eLFwgUQ8cWq23-filter)">
                  <path
                    id="eLFwgUQ8cWq24"
                    d="M781.258,158.241v-7.559l.242-1.040176-.242-.654506"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eLFwgUQ8cWq24-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eLFwgUQ8cWq25_to" transform="translate(846,51.646297)">
                    <g id="eLFwgUQ8cWq25_tr" transform="rotate(0)">
                      <rect
                        id="eLFwgUQ8cWq25"
                        width="7.129631"
                        height="12.222224"
                        rx="0"
                        ry="0"
                        transform="scale(0.981818,0.981818) translate(-3.564816,-6.111112)"
                        opacity="0"
                        filter="url(#eLFwgUQ8cWq25-filter)"
                        fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eLFwgUQ8cWq26_to" transform="translate(846,51.646297)">
                    <g id="eLFwgUQ8cWq26_tr" transform="rotate(0)">
                      <rect
                        id="eLFwgUQ8cWq26"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eLFwgUQ8cWq27-filter)">
                  <path
                    id="eLFwgUQ8cWq28"
                    d="M781.258,158.241v-7.559l.242-1.040176-.242-.654506"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eLFwgUQ8cWq28-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eLFwgUQ8cWq29_to" transform="translate(846,51.146297)">
                    <g id="eLFwgUQ8cWq29_tr" transform="rotate(0)">
                      <rect
                        id="eLFwgUQ8cWq29"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0"
                        filter="url(#eLFwgUQ8cWq29-filter)"
                        fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eLFwgUQ8cWq30_to" transform="translate(846,51.146297)">
                    <g id="eLFwgUQ8cWq30_tr" transform="rotate(0)">
                      <rect
                        id="eLFwgUQ8cWq30"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 8 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="eLFwgUQ8cWq31" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 605.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(-18.999999 0.624996)">
              <path d="M721.199,232.865v-112.051L591.532,76.7311l.008-76.992999" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#eLFwgUQ8cWq35-filter)">
                <path
                  id="eLFwgUQ8cWq36"
                  d="M692.384111,111.206804v-.118885L591.532,76.7311c0,0,.008-7.785355.008-7.785355"
                  transform="translate(65-105.000001)"
                  fill="none"
                  stroke="url(#eLFwgUQ8cWq36-stroke)"
                  strokeLinecap="round"
                />
                <g id="eLFwgUQ8cWq37_to" transform="translate(656.5,-35.643085)">
                  <rect
                    id="eLFwgUQ8cWq37"
                    width="8.75"
                    height="15"
                    rx="0"
                    ry="0"
                    transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                    opacity="0.7"
                    filter="url(#eLFwgUQ8cWq37-filter)"
                    fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eLFwgUQ8cWq38_to" transform="translate(656.5,-35.643085)">
                  <rect
                    id="eLFwgUQ8cWq38"
                    width="10"
                    height="20"
                    rx="0"
                    ry="0"
                    transform="scale(0.5,0.5) translate(-5,-10)"
                    fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eLFwgUQ8cWq39-filter)">
                <path
                  id="eLFwgUQ8cWq40"
                  d="M721.199,252.865v-4.051l-.006-.917698c0,0,0-.755649,0-.755649"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eLFwgUQ8cWq40-stroke)"
                  strokeLinecap="round"
                />
                <g id="eLFwgUQ8cWq41_to" transform="translate(786.5,139.146297)">
                  <g id="eLFwgUQ8cWq41_tr" transform="rotate(0)">
                    <rect
                      id="eLFwgUQ8cWq41"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eLFwgUQ8cWq41-filter)"
                      fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eLFwgUQ8cWq42_to" transform="translate(786.5,139.146297)">
                  <g id="eLFwgUQ8cWq42_tr" transform="rotate(0)">
                    <rect
                      id="eLFwgUQ8cWq42"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eLFwgUQ8cWq43-filter)">
                <path
                  id="eLFwgUQ8cWq44"
                  d="M721.199,252.865v-4.051l-.006-.917698c0,0,0-.755649,0-.755649"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eLFwgUQ8cWq44-stroke)"
                  strokeLinecap="round"
                />
                <g id="eLFwgUQ8cWq45_to" transform="translate(786.3,138.146297)">
                  <g id="eLFwgUQ8cWq45_tr" transform="rotate(0)">
                    <rect
                      id="eLFwgUQ8cWq45"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eLFwgUQ8cWq45-filter)"
                      fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eLFwgUQ8cWq46_to" transform="translate(786.3,138.146297)">
                  <g id="eLFwgUQ8cWq46_tr" transform="rotate(0)">
                    <rect
                      id="eLFwgUQ8cWq46"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(-25 0)" mask="url(#eLFwgUQ8cWq58)">
              <g>
                <path d="M650.977,138.082v-16.4L554.656,76.7305l-.004-76.414006" fill="none" stroke="#6069ca" />
                <g transform="translate(-65 105.000001)" filter="url(#eLFwgUQ8cWq50-filter)">
                  <path
                    id="eLFwgUQ8cWq51"
                    d="M650.977,158.082v-3.4l.052.000001v-2.410703"
                    transform="translate(65-105.000001)"
                    fill="none"
                    stroke="url(#eLFwgUQ8cWq51-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eLFwgUQ8cWq52_to" transform="translate(716.298427,52.146297)">
                    <g id="eLFwgUQ8cWq52_tr" transform="rotate(0)">
                      <rect
                        id="eLFwgUQ8cWq52"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0.7"
                        filter="url(#eLFwgUQ8cWq52-filter)"
                        fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eLFwgUQ8cWq53_to" transform="translate(716.298427,52.146297)">
                    <g id="eLFwgUQ8cWq53_tr" transform="rotate(0)">
                      <rect
                        id="eLFwgUQ8cWq53"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eLFwgUQ8cWq54-filter)">
                  <path
                    id="eLFwgUQ8cWq55"
                    d="M650.977,158.082v-3.4l.052.000001v-2.410703"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eLFwgUQ8cWq55-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eLFwgUQ8cWq56_to" transform="translate(715.298427,50.146297)">
                    <g id="eLFwgUQ8cWq56_tr" transform="rotate(0)">
                      <rect
                        id="eLFwgUQ8cWq56"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0"
                        filter="url(#eLFwgUQ8cWq56-filter)"
                        fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eLFwgUQ8cWq57_to" transform="translate(715.298427,50.146297)">
                    <g id="eLFwgUQ8cWq57_tr" transform="rotate(0)">
                      <rect
                        id="eLFwgUQ8cWq57"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="eLFwgUQ8cWq58" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="140"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 535.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(-30.999999-4)">
              <path
                d="M590.336,232.597v-106.78L517.819,80.7272l-.001-77.20376"
                transform="translate(0 0.805403)"
                fill="none"
                stroke="#6069ca"
              />
              <g transform="translate(-65 105.000001)" filter="url(#eLFwgUQ8cWq62-filter)">
                <path
                  id="eLFwgUQ8cWq63"
                  d="M590.336,238.051545v-62.057573l.000001.267378-.242448-.267377"
                  transform="translate(65-104.194598)"
                  fill="none"
                  stroke="url(#eLFwgUQ8cWq63-stroke)"
                  strokeLinecap="round"
                />
                <g id="eLFwgUQ8cWq64_to" transform="translate(655,73.020302)">
                  <g id="eLFwgUQ8cWq64_tr" transform="rotate(0)">
                    <rect
                      id="eLFwgUQ8cWq64"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0.7"
                      filter="url(#eLFwgUQ8cWq64-filter)"
                      fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eLFwgUQ8cWq65_to" transform="translate(655,73.020302)">
                  <g id="eLFwgUQ8cWq65_tr" transform="rotate(0)">
                    <rect
                      id="eLFwgUQ8cWq65"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eLFwgUQ8cWq66-filter)">
                <path
                  id="eLFwgUQ8cWq67"
                  d="M590.336,252.597v-2.381105v0h.018"
                  transform="translate(65-104.194598)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eLFwgUQ8cWq67-stroke)"
                  strokeLinecap="round"
                />
                <g id="eLFwgUQ8cWq68_to" transform="translate(655.5,137.771297)">
                  <g id="eLFwgUQ8cWq68_tr" transform="rotate(0)">
                    <rect
                      id="eLFwgUQ8cWq68"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eLFwgUQ8cWq68-filter)"
                      fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eLFwgUQ8cWq69_to" transform="translate(655.5,137.771297)">
                  <g id="eLFwgUQ8cWq69_tr" transform="rotate(0)">
                    <rect
                      id="eLFwgUQ8cWq69"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eLFwgUQ8cWq70-filter)">
                <path
                  id="eLFwgUQ8cWq71"
                  d="M590.336,252.597v-2.381105v0h.018"
                  transform="translate(65-104.194598)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eLFwgUQ8cWq71-stroke)"
                  strokeLinecap="round"
                />
                <g id="eLFwgUQ8cWq72_to" transform="translate(655,137.646297)">
                  <rect
                    id="eLFwgUQ8cWq72"
                    width="7"
                    height="12"
                    rx="0"
                    ry="0"
                    transform="translate(-3.5,-6)"
                    opacity="0"
                    filter="url(#eLFwgUQ8cWq72-filter)"
                    fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eLFwgUQ8cWq73_to" transform="translate(655,137.646297)">
                  <rect
                    id="eLFwgUQ8cWq73"
                    width="10"
                    height="20"
                    rx="0"
                    ry="0"
                    transform="scale(0.5,0.5) translate(-5,-10)"
                    opacity="0"
                    fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
              </g>
            </g>
            <g transform="translate(31 0)">
              <path d="M292.146,229.76v-108.078l78.228-44.9059v-76.43235" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#eLFwgUQ8cWq76-filter)">
                <path
                  id="eLFwgUQ8cWq77"
                  d="M292.146,239.76v-1.238702l.354001-2.512982.000002-.237018"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eLFwgUQ8cWq77-stroke)"
                  strokeLinecap="round"
                />
                <g id="eLFwgUQ8cWq78_to" transform="translate(357,139.246297)">
                  <g id="eLFwgUQ8cWq78_tr" transform="rotate(0)">
                    <rect
                      id="eLFwgUQ8cWq78"
                      width="7.291667"
                      height="12.5"
                      rx="0"
                      ry="0"
                      transform="scale(0.96,0.96) translate(-3.645833,-6.25)"
                      opacity="0"
                      filter="url(#eLFwgUQ8cWq78-filter)"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eLFwgUQ8cWq79_to" transform="translate(357,139.246297)">
                  <g id="eLFwgUQ8cWq79_tr" transform="rotate(0)">
                    <rect
                      id="eLFwgUQ8cWq79"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eLFwgUQ8cWq80-filter)">
                <path
                  id="eLFwgUQ8cWq81"
                  d="M292.146,239.76v-1.238702l.354001-2.512982.000002-.237018"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eLFwgUQ8cWq81-stroke)"
                  strokeLinecap="round"
                />
                <g id="eLFwgUQ8cWq82_to" transform="translate(357,139.246297)">
                  <g id="eLFwgUQ8cWq82_tr" transform="rotate(0)">
                    <rect
                      id="eLFwgUQ8cWq82"
                      width="7.291667"
                      height="12.5"
                      rx="0"
                      ry="0"
                      transform="scale(0.96,0.96) translate(-3.645833,-6.25)"
                      opacity="0"
                      filter="url(#eLFwgUQ8cWq82-filter)"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eLFwgUQ8cWq83_to" transform="translate(357,139.246297)">
                  <g id="eLFwgUQ8cWq83_tr" transform="rotate(0)">
                    <rect
                      id="eLFwgUQ8cWq83"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(25 0)" mask="url(#eLFwgUQ8cWq95)">
              <g transform="translate(.000001 0)">
                <path d="M226.994,138.053v-16.37L333.531,76.7383l.004-76.414117" fill="none" stroke="#6069ca" />
                <g transform="translate(-65 105.000001)" filter="url(#eLFwgUQ8cWq87-filter)">
                  <path
                    id="eLFwgUQ8cWq88"
                    d="M226.994002,161.5213l-.000002-7.468298.106.000002-.070999-.000002"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eLFwgUQ8cWq88-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eLFwgUQ8cWq89_to" transform="translate(292,52.271297)">
                    <g id="eLFwgUQ8cWq89_tr" transform="rotate(0)">
                      <rect
                        id="eLFwgUQ8cWq89"
                        width="8.75"
                        height="15"
                        rx="0"
                        ry="0"
                        transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                        opacity="0"
                        filter="url(#eLFwgUQ8cWq89-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eLFwgUQ8cWq90_to" transform="translate(292,52.271297)">
                    <g id="eLFwgUQ8cWq90_tr" transform="rotate(0)">
                      <rect
                        id="eLFwgUQ8cWq90"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eLFwgUQ8cWq91-filter)">
                  <path
                    id="eLFwgUQ8cWq92"
                    d="M226.994002,161.5213l-.000002-7.468298.106.000002-.070999-.000002"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eLFwgUQ8cWq92-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eLFwgUQ8cWq93_to" transform="translate(291.8,49.646297)">
                    <g id="eLFwgUQ8cWq93_tr" transform="rotate(0)">
                      <rect
                        id="eLFwgUQ8cWq93"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0"
                        filter="url(#eLFwgUQ8cWq93-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eLFwgUQ8cWq94_to" transform="translate(291.8,49.646297)">
                    <g id="eLFwgUQ8cWq94_tr" transform="rotate(0)">
                      <rect
                        id="eLFwgUQ8cWq94"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="eLFwgUQ8cWq95" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="140"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 209.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(19 0)">
              <path d="M161.867,229.879v-108.202L296.68,76.7222v-76.413669" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#eLFwgUQ8cWq99-filter)">
                <path
                  id="eLFwgUQ8cWq100"
                  d="M161.867,259.879v-6.389002c0,0-.017-2.218699-.017-2.218699l-.051573-1.875005"
                  transform="translate(64.996501-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eLFwgUQ8cWq100-stroke)"
                  strokeLinecap="round"
                />
                <g id="eLFwgUQ8cWq101_to" transform="translate(226.8,140.246297)">
                  <g id="eLFwgUQ8cWq101_tr" transform="rotate(0)">
                    <rect
                      id="eLFwgUQ8cWq101"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#eLFwgUQ8cWq101-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eLFwgUQ8cWq102_to" transform="translate(226.8,140.246297)">
                  <g id="eLFwgUQ8cWq102_tr" transform="rotate(0)">
                    <rect
                      id="eLFwgUQ8cWq102"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eLFwgUQ8cWq103-filter)">
                <path
                  id="eLFwgUQ8cWq104"
                  d="M161.867,259.879v-6.389002l-.017-2.218699-.051573-1.875005"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eLFwgUQ8cWq104-stroke)"
                  strokeLinecap="round"
                />
                <g id="eLFwgUQ8cWq105_to" transform="translate(227,139.146297)">
                  <g id="eLFwgUQ8cWq105_tr" transform="rotate(0)">
                    <rect
                      id="eLFwgUQ8cWq105"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eLFwgUQ8cWq105-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eLFwgUQ8cWq106_to" transform="translate(227,139.146297)">
                  <g id="eLFwgUQ8cWq106_tr" transform="rotate(0)">
                    <rect
                      id="eLFwgUQ8cWq106"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(13 0)" mask="url(#eLFwgUQ8cWq122)">
              <g transform="translate(.000001 0)">
                <path d="M96.7109,138.118v-16.438L259.817,76.7298v-76.38605" fill="none" stroke="#6069ca" />
                <g transform="translate(-64 105.000001)" filter="url(#eLFwgUQ8cWq110-filter)">
                  <path
                    id="eLFwgUQ8cWq111"
                    d="M96.711985,145.439636L96.7109,127.987317h.001085c0,0,0,0,0,0"
                    transform="translate(64-105.000001)"
                    fill="none"
                    stroke="url(#eLFwgUQ8cWq111-stroke)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="eLFwgUQ8cWq112_to" transform="translate(160.8,25.504306)">
                    <g id="eLFwgUQ8cWq112_tr" transform="rotate(0)">
                      <rect
                        id="eLFwgUQ8cWq112"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0.7"
                        filter="url(#eLFwgUQ8cWq112-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eLFwgUQ8cWq113_to" transform="translate(160.8,25.504306)">
                    <g id="eLFwgUQ8cWq113_tr" transform="rotate(0)">
                      <rect
                        id="eLFwgUQ8cWq113"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eLFwgUQ8cWq114-filter)">
                  <path
                    id="eLFwgUQ8cWq115"
                    d="M96.7109,143.118v-.064999l.1973-.999999c0,0-.109773,1.999999-.109773,1.999999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eLFwgUQ8cWq115-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eLFwgUQ8cWq116_to" transform="translate(161.5,50.787316)">
                    <g id="eLFwgUQ8cWq116_tr" transform="rotate(0)">
                      <rect
                        id="eLFwgUQ8cWq116"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0"
                        filter="url(#eLFwgUQ8cWq116-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eLFwgUQ8cWq117_to" transform="translate(161.5,50.787316)">
                    <g id="eLFwgUQ8cWq117_tr" transform="rotate(0)">
                      <rect
                        id="eLFwgUQ8cWq117"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eLFwgUQ8cWq118-filter)">
                  <path
                    id="eLFwgUQ8cWq119"
                    d="M96.7109,143.118v-.064999l.1973-.999999c0,0-.109773,1.999999-.109773,1.999999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eLFwgUQ8cWq119-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eLFwgUQ8cWq120_to" transform="translate(161.5,49.521297)">
                    <rect
                      id="eLFwgUQ8cWq120"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eLFwgUQ8cWq120-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="eLFwgUQ8cWq121_to" transform="translate(161.5,49.521297)">
                    <rect
                      id="eLFwgUQ8cWq121"
                      width="5"
                      height="10"
                      rx="0"
                      ry="0"
                      transform="translate(-2.5,-5)"
                      opacity="0"
                      filter="url(#eLFwgUQ8cWq121-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <mask id="eLFwgUQ8cWq122" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 75.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(6 0)">
              <path d="M32.0957,229.88v-108.202L222.955,76.73v-76.413594" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#eLFwgUQ8cWq126-filter)">
                <path
                  id="eLFwgUQ8cWq127"
                  d="M32.0957,249.88v-9.358702v0v.003136"
                  transform="translate(64.996286-105.187118)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eLFwgUQ8cWq127-stroke)"
                  strokeLinecap="round"
                />
                <g id="eLFwgUQ8cWq128_to" transform="translate(96.798427,143.537316)">
                  <g id="eLFwgUQ8cWq128_tr" transform="rotate(0)">
                    <rect
                      id="eLFwgUQ8cWq128"
                      width="10"
                      height="17.142857"
                      rx="0"
                      ry="0"
                      transform="scale(0.7,0.7) translate(-5,-8.571429)"
                      opacity="0"
                      filter="url(#eLFwgUQ8cWq128-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eLFwgUQ8cWq129_to" transform="translate(96.798427,142.237316)">
                  <g id="eLFwgUQ8cWq129_tr" transform="rotate(0)">
                    <rect
                      id="eLFwgUQ8cWq129"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      filter="url(#eLFwgUQ8cWq129-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      strokeMiterlimit="8"
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eLFwgUQ8cWq130-filter)">
                <path
                  id="eLFwgUQ8cWq131"
                  d="M32.0957,249.88v-10.732683h-.003714v-.001023"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eLFwgUQ8cWq131-stroke)"
                  strokeLinecap="round"
                />
                <g id="eLFwgUQ8cWq132_to" transform="translate(97,140.121297)">
                  <g id="eLFwgUQ8cWq132_tr" transform="rotate(0)">
                    <rect
                      id="eLFwgUQ8cWq132"
                      width="10"
                      height="17.142857"
                      rx="0"
                      ry="0"
                      transform="scale(0.7,0.7) translate(-5,-8.571428)"
                      opacity="0"
                      filter="url(#eLFwgUQ8cWq132-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eLFwgUQ8cWq133_to" transform="translate(97,140.121297)">
                  <g id="eLFwgUQ8cWq133_tr" transform="rotate(0)">
                    <rect
                      id="eLFwgUQ8cWq133"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <mask id="eLFwgUQ8cWq134" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="825.762291"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #eLFwgUQ8cWq9 {
    animation-name: eLFwgUQ8cWq9__m, eLFwgUQ8cWq9_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eLFwgUQ8cWq9__m {
    0% {
      d: path('M853.309,172.852398L853.309,121.49963L764.741674,100.215254L764.220825,100.215253');
    }
    8.75% {
      d: path('M853.309,121.605025L853.309,121.49963L665.296,76.728L665.296,76.180546');
    }
    10% {
      d: path('M829.807375,115.995397L829.807375,115.903177L665.296,76.728L665.296,67.046162');
    }
    13.75% {
      d: path('M759.302501,99.166512L665.521578,76.727999L665.296,76.728L665.296,16.128048');
    }
    20% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.641938,-26.28823L663.283594,-28.24781');
    }
    100% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.641938,-26.28823L663.283594,-28.24781');
    }
  }
  @keyframes eLFwgUQ8cWq9_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    17.5% {
      opacity: 1;
    }
    22.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq9-stroke {
    animation: eLFwgUQ8cWq9-stroke__t 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq9-stroke__t {
    0% {
      transform: matrix(0.42908, 0.588756, -0.588756, 0.42908, 0.37092, 0.044423);
    }
    17.5% {
      transform: matrix(0.42908, 0.588756, -0.588756, 0.42908, 0.37092, 0.044423);
    }
    18.75% {
      transform: matrix(0.263815, 0.543606, -0.543606, 0.263815, 0.736185, 0.218675);
    }
    20% {
      transform: matrix(0, 0.643472, -0.643472, 0, 9.168692, 0.29408);
    }
    100% {
      transform: matrix(0, 0.643472, -0.643472, 0, 9.168692, 0.29408);
    }
  }
  #eLFwgUQ8cWq9-stroke-0 {
    animation: eLFwgUQ8cWq9-stroke-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq9-stroke-0__c {
    0% {
      stop-color: #7ea6ff;
    }
    17.5% {
      stop-color: #7ea6ff;
    }
    18.75% {
      stop-color: #7ea6ff;
    }
    20% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #eLFwgUQ8cWq9-stroke-1 {
    animation: eLFwgUQ8cWq9-stroke-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq9-stroke-1__c {
    0% {
      stop-color: rgba(64, 73, 168, 0);
    }
    17.5% {
      stop-color: rgba(64, 73, 168, 0);
    }
    18.75% {
      stop-color: rgba(64, 73, 168, 0);
    }
    20% {
      stop-color: rgba(64, 73, 168, 0);
    }
    100% {
      stop-color: rgba(64, 73, 168, 0);
    }
  }
  #eLFwgUQ8cWq10_to {
    animation: eLFwgUQ8cWq10_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq10_to__to {
    0% {
      transform: translate(828.317602px, -5.431736px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(736.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(730.920607px, -32.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.219355px, -122.688381px);
    }
    100% {
      transform: translate(731.219355px, -122.688381px);
    }
  }
  #eLFwgUQ8cWq10_tr {
    animation: eLFwgUQ8cWq10_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq10_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    7.5% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq10 {
    animation: eLFwgUQ8cWq10_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq10_c_o {
    0% {
      opacity: 0.7;
    }
    20% {
      opacity: 0.7;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq11_to {
    animation: eLFwgUQ8cWq11_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq11_to__to {
    0% {
      transform: translate(828.317602px, -5.431736px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(736.801035px, -27.63967px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(730.851035px, -32.48073px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.219354px, -122.688381px);
    }
    100% {
      transform: translate(731.219354px, -122.688381px);
    }
  }
  #eLFwgUQ8cWq11_tr {
    animation: eLFwgUQ8cWq11_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq11_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    7.5% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq11 {
    animation: eLFwgUQ8cWq11_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq11_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq13 {
    animation-name: eLFwgUQ8cWq13__m, eLFwgUQ8cWq13_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eLFwgUQ8cWq13__m {
    0% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    30% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    37.5% {
      d: path('M853.309,237.53995L853.309,151.87312L853.306432,148.3585L853.309001,144.380103');
    }
    40% {
      d: path('M853.309,232.641L853.309,121.49963L853.309,121.605025L852.281356,122.594792');
    }
    56.25% {
      d: path('M853.309,121.605025L853.309,121.49963L665.296,76.728L665.296,76.180546');
    }
    62.5% {
      d: path('M759.302501,99.166512L665.292414,76.725021L665.296,76.728L665.296,11.229098');
    }
    68.75% {
      d: path('M673.345625,78.687579L665.296,76.728001L665.296,-26.288231L665.641937,-26.288231');
    }
    100% {
      d: path('M673.345625,78.687579L665.296,76.728001L665.296,-26.288231L665.641937,-26.288231');
    }
  }
  @keyframes eLFwgUQ8cWq13_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq14_to {
    animation: eLFwgUQ8cWq14_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq14_to__to {
    0% {
      transform: translate(917.5px, 138.546297px);
    }
    25% {
      transform: translate(917.5px, 138.546297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.5px, 23.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(913.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(905.3352px, 13.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(736.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(730.920607px, -32.510292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -38.49755px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(730.9px, -120.728703px);
    }
    100% {
      transform: translate(730.9px, -120.728703px);
    }
  }
  #eLFwgUQ8cWq14_tr {
    animation: eLFwgUQ8cWq14_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq14_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-75deg);
    }
    55% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq14 {
    animation: eLFwgUQ8cWq14_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq14_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 0.7;
    }
    68.75% {
      opacity: 0.7;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq15_to {
    animation: eLFwgUQ8cWq15_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq15_to__to {
    0% {
      transform: translate(917.5px, 138.546297px);
    }
    25% {
      transform: translate(917.5px, 138.546297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.5px, 23.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(913.568884px, 15.76033px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(904.798427px, 13.4px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(736.801035px, -27.43967px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(730.920607px, -32.510292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -38.49755px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(730.9px, -120.228703px);
    }
    100% {
      transform: translate(730.9px, -120.228703px);
    }
  }
  #eLFwgUQ8cWq15_tr {
    animation: eLFwgUQ8cWq15_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-75deg);
    }
    55% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq15 {
    animation: eLFwgUQ8cWq15_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq15_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    68.75% {
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq17 {
    animation-name: eLFwgUQ8cWq17__m, eLFwgUQ8cWq17_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eLFwgUQ8cWq17__m {
    0% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    81.25% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    87.5% {
      d: path('M853.309,239.989425L853.309,167.059865L853.309,159.659375L852.585784,158.679585');
    }
    90% {
      d: path('M853.309,235.090475L853.309,136.686375L853.309,134.289808L853.067928,133.365119');
    }
    91.25% {
      d: path('M853.309,232.641L853.309,121.49963L853.309,121.605025L848.852074,120.222833');
    }
    92.5% {
      d: path('M853.309,224.099771L853.309,121.49963L841.342089,118.585587L836.54984,117.400851');
    }
    100% {
      d: path('M853.309,172.852398L853.309,121.49963L762.497203,100.468958L762.736434,100.468959');
    }
  }
  @keyframes eLFwgUQ8cWq17_c_o {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    76.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eLFwgUQ8cWq18_to {
    animation: eLFwgUQ8cWq18_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq18_to__to {
    0% {
      transform: translate(917.5px, 140.146297px);
    }
    75% {
      transform: translate(917.5px, 140.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.5px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(913.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(905.3352px, 13.841812px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(826.798304px, -5.828881px);
    }
  }
  #eLFwgUQ8cWq18_tr {
    animation: eLFwgUQ8cWq18_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq18_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #eLFwgUQ8cWq18 {
    animation: eLFwgUQ8cWq18_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq18_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #eLFwgUQ8cWq19_to {
    animation: eLFwgUQ8cWq19_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq19_to__to {
    0% {
      transform: translate(917.5px, 140.146297px);
    }
    75% {
      transform: translate(917.5px, 140.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.5px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(913.3px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(904.798427px, 13.4px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(827.121519px, -5.98073px);
    }
  }
  #eLFwgUQ8cWq19_tr {
    animation: eLFwgUQ8cWq19_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq19_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #eLFwgUQ8cWq19 {
    animation: eLFwgUQ8cWq19_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq19_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eLFwgUQ8cWq24 {
    animation-name: eLFwgUQ8cWq24__m, eLFwgUQ8cWq24_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eLFwgUQ8cWq24__m {
    0% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    12.5% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    17.5% {
      d: path('M781.258,146.812429L781.258,144.110571L781.527118,145.664782L780.755126,143.963766');
    }
    21.25% {
      d: path('M781.258,138.241L781.258,121.682L779.797456,121.682L778.627971,122.696102');
    }
    36.25% {
      d: path('M781.258,121.622403L781.258,121.682L628.386,76.7624L628.386,77.3561');
    }
    41.25% {
      d: path('M720.1092,103.542346L720.1092,103.578104L628.386,76.7624L628.386,20.55116');
    }
    43.75% {
      d: path('M683.41992,92.694312L683.41992,92.715767L628.386,76.7624L628.386,-5.531804');
    }
    47.5% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
    100% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
  }
  @keyframes eLFwgUQ8cWq24_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    43.75% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq25_to {
    animation: eLFwgUQ8cWq25_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq25_to__to {
    0% {
      transform: translate(846px, 51.646297px);
    }
    12.5% {
      transform: translate(846px, 51.646297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    20% {
      transform: translate(846.5px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(842.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    22.5% {
      transform: translate(831.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(699.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(693.5px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(693.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    47.5% {
      transform: translate(693.9px, -120.728703px);
    }
    100% {
      transform: translate(693.9px, -120.728703px);
    }
  }
  #eLFwgUQ8cWq25_tr {
    animation: eLFwgUQ8cWq25_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq25_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-73deg);
    }
    35% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq25 {
    animation: eLFwgUQ8cWq25_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq25_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.7;
    }
    47.5% {
      opacity: 0.7;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq26_to {
    animation: eLFwgUQ8cWq26_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq26_to__to {
    0% {
      transform: translate(846px, 51.646297px);
    }
    12.5% {
      transform: translate(846px, 51.646297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    20% {
      transform: translate(846.5px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(842.3px, 15.5px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    22.5% {
      transform: translate(830.604721px, 12.350134px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(699.801035px, -26.390926px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(693.411987px, -31.390926px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(693.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    47.5% {
      transform: translate(693.9px, -122.228703px);
    }
    100% {
      transform: translate(693.9px, -122.228703px);
    }
  }
  #eLFwgUQ8cWq26_tr {
    animation: eLFwgUQ8cWq26_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq26_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-73deg);
    }
    35% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq26 {
    animation: eLFwgUQ8cWq26_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq26_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    47.5% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq28 {
    animation-name: eLFwgUQ8cWq28__m, eLFwgUQ8cWq28_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eLFwgUQ8cWq28__m {
    0% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    51.25% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    56.25% {
      d: path('M781.258,146.812429L781.258,144.110571L781.527118,145.664782L780.755126,143.963766');
    }
    60% {
      d: path('M781.258,138.241L781.258,121.682L779.797456,121.682L778.627971,122.696102');
    }
    73.75% {
      d: path('M781.258,121.622403L781.258,121.682L628.386,76.7624L628.386,77.3561');
    }
    78.75% {
      d: path('M720.1092,103.542346L628.386,76.90171L628.386,76.7624L628.386,20.55116');
    }
    86.25% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,-19.228702L627.386,-20.65625');
    }
    100% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,-19.228702L627.386,-20.65625');
    }
  }
  @keyframes eLFwgUQ8cWq28_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 1;
    }
    88.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq29_to {
    animation: eLFwgUQ8cWq29_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq29_to__to {
    0% {
      transform: translate(846px, 51.146297px);
    }
    51.25% {
      transform: translate(846px, 51.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    58.75% {
      transform: translate(846.5px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    60% {
      transform: translate(842.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    61.25% {
      transform: translate(831.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    72.5% {
      transform: translate(699.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    73.75% {
      transform: translate(693.5px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    75% {
      transform: translate(693.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    86.25% {
      transform: translate(693.9px, -119.728703px);
    }
    100% {
      transform: translate(693.9px, -119.728703px);
    }
  }
  #eLFwgUQ8cWq29_tr {
    animation: eLFwgUQ8cWq29_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq29_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-73deg);
    }
    72.5% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq29 {
    animation: eLFwgUQ8cWq29_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq29_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 0.7;
    }
    86.25% {
      opacity: 0.7;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq30_to {
    animation: eLFwgUQ8cWq30_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq30_to__to {
    0% {
      transform: translate(846px, 51.146297px);
    }
    51.25% {
      transform: translate(846px, 51.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    58.75% {
      transform: translate(846.5px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    60% {
      transform: translate(841.104721px, 14.850134px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    61.25% {
      transform: translate(830.798427px, 12.350134px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    72.5% {
      transform: translate(698.801035px, -26.8px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    73.75% {
      transform: translate(693.411987px, -31.290926px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    75% {
      transform: translate(693.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    86.25% {
      transform: translate(693.9px, -121.228703px);
    }
    100% {
      transform: translate(693.9px, -121.228703px);
    }
  }
  #eLFwgUQ8cWq30_tr {
    animation: eLFwgUQ8cWq30_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq30_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-73deg);
    }
    72.5% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq30 {
    animation: eLFwgUQ8cWq30_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq30_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    86.25% {
      opacity: 1;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq36 {
    animation-name: eLFwgUQ8cWq36__m, eLFwgUQ8cWq36_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eLFwgUQ8cWq36__m {
    0% {
      d: path(
        'M692.384111,111.206804L692.384111,111.087919L591.532,76.7311C591.532,76.7311,591.54,68.945745,591.54,68.945745'
      );
    }
    3.75% {
      d: path(
        'M649.161778,96.431503L649.161778,96.498797L591.532,76.7311C591.532,76.7311,591.54,19.285326,591.54,19.285326'
      );
    }
    8.75% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-20.261899,591.54,-20.261899'
      );
    }
    100% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-20.261899,591.54,-20.261899'
      );
    }
  }
  @keyframes eLFwgUQ8cWq36_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    3.75% {
      opacity: 1;
    }
    10% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq37_to {
    animation: eLFwgUQ8cWq37_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq37_to__to {
    0% {
      transform: translate(656.5px, -35.643085px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.5px, -119.728703px);
    }
    100% {
      transform: translate(656.5px, -119.728703px);
    }
  }
  #eLFwgUQ8cWq37 {
    animation: eLFwgUQ8cWq37_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq37_c_o {
    0% {
      opacity: 0.7;
    }
    10% {
      opacity: 0.7;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq38_to {
    animation: eLFwgUQ8cWq38_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq38_to__to {
    0% {
      transform: translate(656.5px, -35.643085px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.5px, -119.728703px);
    }
    100% {
      transform: translate(656.5px, -119.728703px);
    }
  }
  #eLFwgUQ8cWq38 {
    animation: eLFwgUQ8cWq38_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq38_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    10% {
      opacity: 1;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq40 {
    animation-name: eLFwgUQ8cWq40__m, eLFwgUQ8cWq40_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eLFwgUQ8cWq40__m {
    0% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    22.5% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    30% {
      d: path(
        'M721.199,242.865L721.199,212.314L721.03054,210.946856C721.03054,210.946856,721.013534,209.569032,721.013534,209.569032'
      );
    }
    36.25% {
      d: path(
        'M721.199,234.531667L721.199,136.064L721.192993,131.635822C721.192993,131.635822,721.192993,128.271298,721.192993,128.271298'
      );
    }
    37.5% {
      d: path(
        'M721.199,232.865L721.199,120.814L721.192993,120.949437C721.192993,120.949437,721.192993,120.410429,721.192993,120.410429'
      );
    }
    50% {
      d: path('M721.199,121.057004L721.199,120.814L591.532,76.7311C591.532,76.7311,591.54,75.862215,591.54,75.862215');
    }
    61.25% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-0.261899,591.54,-0.261899'
      );
    }
    100% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-0.261899,591.54,-0.261899'
      );
    }
  }
  @keyframes eLFwgUQ8cWq40_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    57.5% {
      opacity: 1;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq41_to {
    animation: eLFwgUQ8cWq41_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq41_to__to {
    0% {
      transform: translate(786.5px, 139.146297px);
    }
    22.5% {
      transform: translate(786.5px, 139.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.5px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(784.5px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(774.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    50% {
      transform: translate(660.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(656.920607px, -31.208246px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #eLFwgUQ8cWq41_tr {
    animation: eLFwgUQ8cWq41_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq41_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(-70deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq41 {
    animation: eLFwgUQ8cWq41_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq41_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 0.7;
    }
    62.5% {
      opacity: 0.7;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq42_to {
    animation: eLFwgUQ8cWq42_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq42_to__to {
    0% {
      transform: translate(786.5px, 139.146297px);
    }
    22.5% {
      transform: translate(786.5px, 139.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.5px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(783.150027px, 14.353271px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(773.8px, 10.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    50% {
      transform: translate(659.801035px, -27.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(656.851035px, -31.208246px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -121.228703px);
    }
    100% {
      transform: translate(656.9px, -121.228703px);
    }
  }
  #eLFwgUQ8cWq42_tr {
    animation: eLFwgUQ8cWq42_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq42_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(-70deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq42 {
    animation: eLFwgUQ8cWq42_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq42_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    62.5% {
      opacity: 1;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq44 {
    animation-name: eLFwgUQ8cWq44__m, eLFwgUQ8cWq44_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eLFwgUQ8cWq44__m {
    0% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    68.75% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    76.25% {
      d: path(
        'M721.199,242.865L721.199,212.314L721.03054,210.946856C721.03054,210.946856,721.013534,209.569032,721.013534,209.569032'
      );
    }
    82.5% {
      d: path(
        'M721.199,234.531667L721.199,137.56998L721.170923,130.615492C721.170923,130.615492,721.168089,129.535155,721.168089,129.535155'
      );
    }
    83.75% {
      d: path(
        'M721.199,232.865L721.199,120.949437L721.199,120.910429C721.199,120.910429,718.653477,120.271298,718.653477,120.271298'
      );
    }
    85% {
      d: path(
        'M721.199,222.700637L721.199,120.814L709.65505,116.841813C709.65505,116.841813,707.097706,116.234109,707.097706,116.234109'
      );
    }
    97.5% {
      d: path('M721.199,121.057004L721.199,120.814L591.532,76.7311C591.532,76.7311,591.54,75.862215,591.54,75.862215');
    }
    100% {
      d: path(
        'M692.384111,111.206804L692.384111,111.087919L591.532,76.7311C591.532,76.7311,591.54,68.945745,591.54,68.945745'
      );
    }
  }
  @keyframes eLFwgUQ8cWq44_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eLFwgUQ8cWq45_to {
    animation: eLFwgUQ8cWq45_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq45_to__to {
    0% {
      transform: translate(786.3px, 138.146297px);
    }
    68.75% {
      transform: translate(786.3px, 138.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.3px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(784.5px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(774.3352px, 11.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(661.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(656.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -40.096471px);
    }
  }
  #eLFwgUQ8cWq45_tr {
    animation: eLFwgUQ8cWq45_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq45_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-70deg);
    }
    96.25% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq45 {
    animation: eLFwgUQ8cWq45_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq45_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #eLFwgUQ8cWq46_to {
    animation: eLFwgUQ8cWq46_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq46_to__to {
    0% {
      transform: translate(786.3px, 138.146297px);
    }
    68.75% {
      transform: translate(786.3px, 138.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.3px, 23.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(782.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(773.8px, 11.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(660.801035px, -26.846729px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(656.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -40.096471px);
    }
  }
  #eLFwgUQ8cWq46_tr {
    animation: eLFwgUQ8cWq46_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq46_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-70deg);
    }
    96.25% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq46 {
    animation: eLFwgUQ8cWq46_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq46_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eLFwgUQ8cWq51 {
    animation-name: eLFwgUQ8cWq51__m, eLFwgUQ8cWq51_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eLFwgUQ8cWq51__m {
    0% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    5% {
      d: path('M650.977,146.653429L650.977,145.824857L650.761566,146.078382L650.761569,145.329073');
    }
    8.75% {
      d: path('M650.977,138.082L650.977,121.682L650.560991,122.125667L650.560995,122.622405');
    }
    22.5% {
      d: path('M650.977,122.082L650.977,121.682L554.656,76.7305L554.652,76.316494');
    }
    28.75% {
      d: path('M575.124559,86.409359L575.124559,86.409359L554.656,76.7305L554.652,9.316494');
    }
    35% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
    100% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
  }
  @keyframes eLFwgUQ8cWq51_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    31.25% {
      opacity: 1;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq52_to {
    animation: eLFwgUQ8cWq52_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq52_to__to {
    0% {
      transform: translate(716.298427px, 52.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(712.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(706.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(624.801035px, -25.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(619.920607px, -31.216326px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(619.9px, -121.728703px);
    }
    100% {
      transform: translate(619.9px, -121.728703px);
    }
  }
  #eLFwgUQ8cWq52_tr {
    animation: eLFwgUQ8cWq52_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq52_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-68deg);
    }
    21.25% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq52 {
    animation: eLFwgUQ8cWq52_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq52_c_o {
    0% {
      opacity: 0.7;
    }
    35% {
      opacity: 0.7;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq53_to {
    animation: eLFwgUQ8cWq53_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq53_to__to {
    0% {
      transform: translate(716.298427px, 52.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(711.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(705.798427px, 12.6px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(623.801035px, -26.140845px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(619.920607px, -31.216326px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(619.9px, -122.228703px);
    }
    100% {
      transform: translate(619.9px, -122.228703px);
    }
  }
  #eLFwgUQ8cWq53_tr {
    animation: eLFwgUQ8cWq53_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq53_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-68deg);
    }
    21.25% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq53 {
    animation: eLFwgUQ8cWq53_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq53_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    35% {
      opacity: 1;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq55 {
    animation-name: eLFwgUQ8cWq55__m, eLFwgUQ8cWq55_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eLFwgUQ8cWq55__m {
    0% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    43.75% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    48.75% {
      d: path('M650.977,146.653429L650.977,145.824857L650.761566,146.078382L650.761569,145.329073');
    }
    52.5% {
      d: path('M650.977,138.082L650.977,121.682L650.560991,122.125667L650.560995,122.622405');
    }
    66.25% {
      d: path('M650.977,122.082L650.977,121.682L554.656,76.7305L554.652,76.316494');
    }
    72.5% {
      d: path('M575.124559,86.409359L575.124559,86.409359L554.656,76.7305L554.652,9.316494');
    }
    78.75% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
    100% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
  }
  @keyframes eLFwgUQ8cWq55_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq56_to {
    animation: eLFwgUQ8cWq56_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq56_to__to {
    0% {
      transform: translate(715.298427px, 50.146297px);
    }
    43.75% {
      transform: translate(715.298427px, 50.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(713.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(707.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(624.801035px, -25.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(619.920607px, -29.810292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -36.79755px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(619.9px, -117.878703px);
    }
    100% {
      transform: translate(619.9px, -117.878703px);
    }
  }
  #eLFwgUQ8cWq56_tr {
    animation: eLFwgUQ8cWq56_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq56_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-68deg);
    }
    65% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq56 {
    animation: eLFwgUQ8cWq56_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq56_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 0.7;
    }
    78.75% {
      opacity: 0.7;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq57_to {
    animation: eLFwgUQ8cWq57_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq57_to__to {
    0% {
      transform: translate(715.298427px, 50.146297px);
    }
    43.75% {
      transform: translate(715.298427px, 50.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(713.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(707.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(626.801035px, -24.6px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(619.920607px, -29.810292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -36.79755px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(619.9px, -117.878703px);
    }
    100% {
      transform: translate(619.9px, -117.878703px);
    }
  }
  #eLFwgUQ8cWq57_tr {
    animation: eLFwgUQ8cWq57_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq57_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-68deg);
    }
    65% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq57 {
    animation: eLFwgUQ8cWq57_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq57_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 1;
    }
    78.75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq63 {
    animation-name: eLFwgUQ8cWq63__m, eLFwgUQ8cWq63_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eLFwgUQ8cWq63__m {
    0% {
      d: path('M590.336,238.051545L590.336,175.993972L590.336001,176.26135L590.093553,175.993973');
    }
    7.5% {
      d: path('M590.336,232.597L590.336,125.817L590.298427,125.817L588.798427,124.871597');
    }
    21.25% {
      d: path('M590.336,125.761512L590.336,125.817L517.819,80.7272L517.818,79.9168');
    }
    27.5% {
      d: path('M554.077,103.313869L554.077,103.183848L517.819,80.7272L517.818,11.72012');
    }
    32.5% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
    100% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
  }
  @keyframes eLFwgUQ8cWq63_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq64_to {
    animation: eLFwgUQ8cWq64_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq64_to__to {
    0% {
      transform: translate(655px, 73.020302px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    6.25% {
      transform: translate(656px, 27.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    7.5% {
      transform: translate(653.798427px, 20.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    8.75% {
      transform: translate(645.8px, 15.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    20% {
      transform: translate(587.801035px, -20.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    21.25% {
      transform: translate(582.920607px, -27.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    22.5% {
      transform: translate(582.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    33.75% {
      transform: translate(582.9px, -115.728703px);
    }
    100% {
      transform: translate(582.9px, -115.728703px);
    }
  }
  #eLFwgUQ8cWq64_tr {
    animation: eLFwgUQ8cWq64_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq64_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    6.25% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(-57deg);
    }
    8.75% {
      transform: rotate(-57deg);
    }
    20% {
      transform: rotate(-57deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq64 {
    animation: eLFwgUQ8cWq64_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq64_c_o {
    0% {
      opacity: 0.7;
    }
    33.75% {
      opacity: 0.7;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq65_to {
    animation: eLFwgUQ8cWq65_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq65_to__to {
    0% {
      transform: translate(655px, 73.020302px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    6.25% {
      transform: translate(656px, 27.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    7.5% {
      transform: translate(653.798427px, 20.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    8.75% {
      transform: translate(645.8px, 15.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    20% {
      transform: translate(587.801035px, -20.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    21.25% {
      transform: translate(582.851035px, -27.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    22.5% {
      transform: translate(582.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    33.75% {
      transform: translate(582.9px, -115.228703px);
    }
    100% {
      transform: translate(582.9px, -115.228703px);
    }
  }
  #eLFwgUQ8cWq65_tr {
    animation: eLFwgUQ8cWq65_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq65_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    6.25% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(-57deg);
    }
    8.75% {
      transform: rotate(-57deg);
    }
    20% {
      transform: rotate(-57deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq65 {
    animation: eLFwgUQ8cWq65_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq65_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    33.75% {
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq67 {
    animation-name: eLFwgUQ8cWq67__m, eLFwgUQ8cWq67_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eLFwgUQ8cWq67__m {
    0% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    40% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    46.25% {
      d: path('M590.336,239.869727L590.336,186.052962L590.336001,185.943168L590.469859,186.052963');
    }
    50% {
      d: path('M590.336,234.415182L590.336,130.875991L590.336001,131.897713L589.340942,130.875992');
    }
    51.25% {
      d: path('M590.336,232.597L590.336,125.817L590.336001,127.215895L588.964636,125.817002');
    }
    66.25% {
      d: path('M590.336,125.761512L590.336,125.817L517.819,80.7272L517.818,79.9168');
    }
    72.5% {
      d: path('M554.077,103.313869L554.077,103.183848L517.819,80.7272L517.818,11.72012');
    }
    78.75% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
    100% {
      d: path('M517.818,80.866225L517.818,80.550695L517.819,80.7272L517.818,-6.47656');
    }
  }
  @keyframes eLFwgUQ8cWq67_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    72.5% {
      opacity: 1;
    }
    78.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq68_to {
    animation: eLFwgUQ8cWq68_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq68_to__to {
    0% {
      transform: translate(655.5px, 137.771297px);
    }
    37.5% {
      transform: translate(655.5px, 137.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    50% {
      transform: translate(655.5px, 27.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    51.25% {
      transform: translate(653.798427px, 20.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    52.5% {
      transform: translate(643.3352px, 13.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(586.801035px, -20.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(582.920607px, -27.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(582.960993px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(582.9px, -116.728703px);
    }
    100% {
      transform: translate(582.9px, -116.728703px);
    }
  }
  #eLFwgUQ8cWq68_tr {
    animation: eLFwgUQ8cWq68_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq68_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(-60deg);
    }
    65% {
      transform: rotate(-60deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq68 {
    animation: eLFwgUQ8cWq68_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq68_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 0.7;
    }
    78.75% {
      opacity: 0.7;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq69_to {
    animation: eLFwgUQ8cWq69_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq69_to__to {
    0% {
      transform: translate(655.5px, 137.771297px);
    }
    37.5% {
      transform: translate(655.5px, 137.771297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    50% {
      transform: translate(655.5px, 26.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    51.25% {
      transform: translate(653.798427px, 20.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    52.5% {
      transform: translate(642.726569px, 13.25px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(586.801035px, -20.855633px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(582.911986px, -27.262684px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(582.560992px, -38.762684px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(582.9px, -118.228703px);
    }
    100% {
      transform: translate(582.9px, -118.228703px);
    }
  }
  #eLFwgUQ8cWq69_tr {
    animation: eLFwgUQ8cWq69_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq69_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(-60deg);
    }
    65% {
      transform: rotate(-60deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq69 {
    animation: eLFwgUQ8cWq69_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq69_c_o {
    0% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    37.5% {
      opacity: 1;
    }
    78.75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq71 {
    animation-name: eLFwgUQ8cWq71__m, eLFwgUQ8cWq71_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eLFwgUQ8cWq71__m {
    0% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    93.75% {
      d: path('M590.336,252.597L590.336,250.215895L590.336,250.215895L590.354,250.215895');
    }
    100% {
      d: path('M590.336,239.869727L590.336,177.052962L590.336001,176.943168L590.469859,177.052963');
    }
  }
  @keyframes eLFwgUQ8cWq71_c_o {
    0% {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    91.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eLFwgUQ8cWq72_to {
    animation: eLFwgUQ8cWq72_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq72_to__to {
    0% {
      transform: translate(655px, 137.646297px);
    }
    90% {
      transform: translate(655px, 137.646297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(655px, 71.825773px);
    }
  }
  #eLFwgUQ8cWq72 {
    animation: eLFwgUQ8cWq72_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq72_c_o {
    0% {
      opacity: 0;
    }
    88.75% {
      opacity: 0;
    }
    90% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #eLFwgUQ8cWq73_to {
    animation: eLFwgUQ8cWq73_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq73_to__to {
    0% {
      transform: translate(655px, 137.646297px);
    }
    90% {
      transform: translate(655px, 137.646297px);
      animation-timing-function: cubic-bezier(0.463475, 0, 0.732874, 0.452826);
    }
    100% {
      transform: translate(655px, 71.825773px);
    }
  }
  #eLFwgUQ8cWq73 {
    animation: eLFwgUQ8cWq73_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq73_c_o {
    0% {
      opacity: 0;
    }
    88.75% {
      opacity: 0;
    }
    90% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #eLFwgUQ8cWq77 {
    animation-name: eLFwgUQ8cWq77__m, eLFwgUQ8cWq77_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eLFwgUQ8cWq77__m {
    0% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    6.25% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    12.5% {
      d: path('M292.146,235.913846L292.146,233.521298L292.363848,235.008315L292.363849,235.771298');
    }
    22.5% {
      d: path('M292.146,229.76L292.146,121.682L292.146003,122.622405L292.146002,122.622405');
    }
    36.25% {
      d: path('M292.146,121.885507L292.146,121.682L370.374,76.7761L370.374,77.208121');
    }
    47.5% {
      d: path('M346.303846,91.179651L346.303846,90.994836L370.374,76.7761L370.374,-20.851828');
    }
    52.5% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
    100% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
  }
  @keyframes eLFwgUQ8cWq77_c_o {
    0% {
      opacity: 0;
    }
    22.5% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    43.75% {
      opacity: 1;
    }
    52.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq78_to {
    animation: eLFwgUQ8cWq78_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq78_to__to {
    0% {
      transform: translate(357px, 139.246297px);
    }
    6.25% {
      transform: translate(357px, 139.246297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(357px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(361.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(367.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(432.801035px, -26.4px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(435.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(435.7px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    51.25% {
      transform: translate(435.7px, -120.728703px);
    }
    100% {
      transform: translate(435.7px, -120.728703px);
    }
  }
  #eLFwgUQ8cWq78_tr {
    animation: eLFwgUQ8cWq78_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq78_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(58deg);
    }
    23.75% {
      transform: rotate(58deg);
    }
    35% {
      transform: rotate(58deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq78 {
    animation: eLFwgUQ8cWq78_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq78_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 0.7;
    }
    51.25% {
      opacity: 0.7;
    }
    52.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq79_to {
    animation: eLFwgUQ8cWq79_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq79_to__to {
    0% {
      transform: translate(357px, 139.246297px);
    }
    6.25% {
      transform: translate(357px, 139.246297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(357.000001px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(361.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(368.202724px, 10.173441px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(433.202724px, -26.826559px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(435.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(435.7px, -39.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    51.25% {
      transform: translate(435.7px, -121.228703px);
    }
    100% {
      transform: translate(435.7px, -121.228703px);
    }
  }
  #eLFwgUQ8cWq79_tr {
    animation: eLFwgUQ8cWq79_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq79_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(58deg);
    }
    23.75% {
      transform: rotate(58deg);
    }
    35% {
      transform: rotate(58deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq79 {
    animation: eLFwgUQ8cWq79_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq79_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    51.25% {
      opacity: 0.8;
    }
    52.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq81 {
    animation-name: eLFwgUQ8cWq81__m, eLFwgUQ8cWq81_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eLFwgUQ8cWq81__m {
    0% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    51.25% {
      d: path('M292.146,239.76L292.146,238.521298L292.500001,236.008316L292.500003,235.771298');
    }
    57.5% {
      d: path('M292.146,235.913846L292.146,233.521298L292.363848,235.008315L292.363849,235.771298');
    }
    67.5% {
      d: path('M292.146,229.76L292.146,121.682L292.146003,122.622405L292.146002,122.622405');
    }
    81.25% {
      d: path('M292.146,121.885507L292.146,121.682L370.374,76.7761L370.374,77.208121');
    }
    92.5% {
      d: path('M346.303846,91.179651L346.303846,90.994836L370.374,76.7761L370.374,-20.851828');
    }
    97.5% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
    100% {
      d: path('M370.374,77.532604L370.374,77.356097L370.374,76.7761L370.374,-19.65625');
    }
  }
  @keyframes eLFwgUQ8cWq81_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    88.75% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq82_to {
    animation: eLFwgUQ8cWq82_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq82_to__to {
    0% {
      transform: translate(357px, 139.246297px);
    }
    51.25% {
      transform: translate(357px, 139.246297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    66.25% {
      transform: translate(357px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    67.5% {
      transform: translate(361.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    68.75% {
      transform: translate(367.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    80% {
      transform: translate(432.801035px, -26.4px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    81.25% {
      transform: translate(435.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    82.5% {
      transform: translate(435.7px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(435.7px, -120.728703px);
    }
    100% {
      transform: translate(435.7px, -120.728703px);
    }
  }
  #eLFwgUQ8cWq82_tr {
    animation: eLFwgUQ8cWq82_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq82_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(58deg);
    }
    68.75% {
      transform: rotate(58deg);
    }
    80% {
      transform: rotate(58deg);
    }
    81.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq82 {
    animation: eLFwgUQ8cWq82_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq82_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 0.7;
    }
    96.25% {
      opacity: 0.7;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq83_to {
    animation: eLFwgUQ8cWq83_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq83_to__to {
    0% {
      transform: translate(357px, 139.246297px);
    }
    51.25% {
      transform: translate(357px, 139.246297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    66.25% {
      transform: translate(357.000001px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    67.5% {
      transform: translate(361.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    68.75% {
      transform: translate(368.202724px, 10.173441px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    80% {
      transform: translate(433.202724px, -26.826559px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    81.25% {
      transform: translate(435.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    82.5% {
      transform: translate(435.7px, -39.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(435.7px, -121.228703px);
    }
    100% {
      transform: translate(435.7px, -121.228703px);
    }
  }
  #eLFwgUQ8cWq83_tr {
    animation: eLFwgUQ8cWq83_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq83_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(58deg);
    }
    68.75% {
      transform: rotate(58deg);
    }
    80% {
      transform: rotate(58deg);
    }
    81.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq83 {
    animation: eLFwgUQ8cWq83_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq83_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    93.75% {
      opacity: 0.8;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq88 {
    animation-name: eLFwgUQ8cWq88__m, eLFwgUQ8cWq88_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eLFwgUQ8cWq88__m {
    0% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    18.75% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    23.75% {
      d: path('M226.994001,149.78715L226.994,145.868001L227.0645,146.865005L226.907042,146.549048');
    }
    28.75% {
      d: path('M226.994,138.053L226.994,121.683L227.029,121.677006L228.785083,121.045093');
    }
    40% {
      d: path('M227.029,121.683L226.994,121.683L333.531,76.7383L333.535,76.696467');
    }
    42.5% {
      d: path('M245.992733,113.68997L245.963955,113.721882L333.531,76.7383L333.535,65.343379');
    }
    50% {
      d: path('M312.365799,85.714363L312.358799,85.857968L333.531,76.7383L333.535,-14.892427');
    }
    55% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
    100% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
  }
  @keyframes eLFwgUQ8cWq88_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    48.75% {
      opacity: 1;
    }
    53.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq89_to {
    animation: eLFwgUQ8cWq89_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq89_to__to {
    0% {
      transform: translate(292px, 52.271297px);
    }
    18.75% {
      transform: translate(292px, 52.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(292px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(295.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(304.3352px, 12.1px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(398.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -119.728703px);
    }
    100% {
      transform: translate(398.699999px, -119.728703px);
    }
  }
  #eLFwgUQ8cWq89_tr {
    animation: eLFwgUQ8cWq89_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq89_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(68deg);
    }
    30% {
      transform: rotate(68deg);
    }
    40% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq89 {
    animation: eLFwgUQ8cWq89_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq89_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 0.7;
    }
    53.75% {
      opacity: 0.7;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq90_to {
    animation: eLFwgUQ8cWq90_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq90_to__to {
    0% {
      transform: translate(292px, 52.271297px);
    }
    18.75% {
      transform: translate(292px, 52.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(292px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(296.798427px, 14.51295px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(305.474547px, 11.51295px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(398.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -120.228703px);
    }
    100% {
      transform: translate(398.699999px, -120.228703px);
    }
  }
  #eLFwgUQ8cWq90_tr {
    animation: eLFwgUQ8cWq90_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq90_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(68deg);
    }
    40% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq90 {
    animation: eLFwgUQ8cWq90_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq90_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    53.75% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq92 {
    animation-name: eLFwgUQ8cWq92__m, eLFwgUQ8cWq92_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eLFwgUQ8cWq92__m {
    0% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    58.75% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    63.75% {
      d: path('M226.994001,149.78715L226.994,145.868001L227.0645,146.865005L226.907042,146.549048');
    }
    68.75% {
      d: path('M226.994,138.053L226.994,121.683L227.029,121.677006L228.785083,121.045093');
    }
    82.5% {
      d: path('M227.029,121.683L226.994,121.683L333.531,76.7383L333.535,76.687317');
    }
    92.5% {
      d: path('M312.365799,85.714363L312.358799,85.857968L333.531,76.7383L333.535,-14.892427');
    }
    95% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
    100% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
  }
  @keyframes eLFwgUQ8cWq92_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq93_to {
    animation: eLFwgUQ8cWq93_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq93_to__to {
    0% {
      transform: translate(291.8px, 49.646297px);
    }
    58.75% {
      transform: translate(291.8px, 49.646297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(295.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(304.3352px, 12.1px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(398.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -119.378703px);
    }
    100% {
      transform: translate(398.699999px, -119.378703px);
    }
  }
  #eLFwgUQ8cWq93_tr {
    animation: eLFwgUQ8cWq93_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq93_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(68deg);
    }
    70% {
      transform: rotate(68deg);
    }
    81.25% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq93 {
    animation: eLFwgUQ8cWq93_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq93_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 0.7;
    }
    95% {
      opacity: 0.7;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq94_to {
    animation: eLFwgUQ8cWq94_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq94_to__to {
    0% {
      transform: translate(291.8px, 49.646297px);
    }
    58.75% {
      transform: translate(291.8px, 49.646297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(296.798427px, 14px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(305.174547px, 11.51295px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(396.801035px, -27.740845px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(398.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -119.378703px);
    }
    100% {
      transform: translate(398.699999px, -119.378703px);
    }
  }
  #eLFwgUQ8cWq94_tr {
    animation: eLFwgUQ8cWq94_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq94_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(68deg);
    }
    81.25% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq94 {
    animation: eLFwgUQ8cWq94_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq94_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq100 {
    animation-name: eLFwgUQ8cWq100__m, eLFwgUQ8cWq100_c_o, eLFwgUQ8cWq100_s_p;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eLFwgUQ8cWq100__m {
    0% {
      d: path(
        'M161.867,259.879L161.867,253.489998C161.867,253.489998,161.85,251.271299,161.85,251.271299L161.798427,249.396294'
      );
    }
    11.25% {
      d: path(
        'M161.867,259.879L161.867,253.489998C161.867,253.489998,161.85,251.271299,161.85,251.271299L161.798427,249.396294'
      );
    }
    20% {
      d: path(
        'M161.867,236.545667L161.867,150.968777C161.867,150.968777,161.85,151.211049,161.85,151.211049L161.85,143.127495'
      );
    }
    22.5% {
      d: path(
        'M161.867,229.879L161.867,121.677C161.867,121.677,161.85,122.622406,161.85,122.622406L164.089459,122.622409'
      );
    }
    32.5% {
      d: path(
        'M161.867,143.3214L161.867,121.677C161.867,121.677,277.422107,83.120632,277.422107,83.120632L280.268001,82.288075'
      );
    }
    35% {
      d: path('M161.867,121.682L161.867,121.677C161.867,121.677,296.68,76.7222,296.68,76.7222L296.854534,76.35604');
    }
    42.5% {
      d: path(
        'M235.303182,96.99487L235.521364,96.992597C235.521364,96.992597,296.68,76.7222,296.68,76.7222L296.68,3.623396'
      );
    }
    47.5% {
      d: path('M296.5,76.422261L296.9,76.422262C296.9,76.422262,296.68,76.7222,296.68,76.7222L296.68,-19.691469');
    }
    100% {
      d: path('M296.5,76.422261L296.9,76.422262C296.9,76.422262,296.68,76.7222,296.68,76.7222L296.68,-19.691469');
    }
  }
  @keyframes eLFwgUQ8cWq100_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    46.25% {
      opacity: 1;
    }
    51.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes eLFwgUQ8cWq100_s_p {
    0% {
      stroke: url(#eLFwgUQ8cWq100-stroke);
    }
    20% {
      stroke: url(#eLFwgUQ8cWq100-stroke);
    }
    28.75% {
      stroke: url(#eLFwgUQ8cWq100-stroke-g1);
    }
    38.75% {
      stroke: url(#eLFwgUQ8cWq100-stroke-g2);
    }
    41.25% {
      stroke: url(#eLFwgUQ8cWq100-stroke-g3);
    }
    43.75% {
      stroke: url(#eLFwgUQ8cWq100-stroke-g4);
    }
    47.5% {
      stroke: url(#eLFwgUQ8cWq100-stroke-g4);
    }
    100% {
      stroke: url(#eLFwgUQ8cWq100-stroke-g4);
    }
  }
  #eLFwgUQ8cWq100-stroke-g4-0 {
    animation: eLFwgUQ8cWq100-stroke-g4-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq100-stroke-g4-0__c {
    0% {
      stop-color: #7ea6ff;
    }
    43.75% {
      stop-color: #7ea6ff;
    }
    47.5% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #eLFwgUQ8cWq100-stroke-g4-1 {
    animation: eLFwgUQ8cWq100-stroke-g4-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq100-stroke-g4-1__c {
    0% {
      stop-color: rgba(64, 65, 69, 0);
    }
    43.75% {
      stop-color: rgba(64, 65, 69, 0);
    }
    47.5% {
      stop-color: rgba(64, 65, 69, 0);
    }
    100% {
      stop-color: rgba(64, 65, 69, 0);
    }
  }
  #eLFwgUQ8cWq100-stroke-g4-2 {
    animation: eLFwgUQ8cWq100-stroke-g4-2__c 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq100-stroke-g4-2__c {
    0% {
      stop-color: rgba(64, 65, 69, 0);
    }
    43.75% {
      stop-color: rgba(64, 65, 69, 0);
    }
    47.5% {
      stop-color: rgba(64, 65, 69, 0);
    }
    100% {
      stop-color: rgba(64, 65, 69, 0);
    }
  }
  #eLFwgUQ8cWq101_to {
    animation: eLFwgUQ8cWq101_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq101_to__to {
    0% {
      transform: translate(226.8px, 140.246297px);
    }
    6.25% {
      transform: translate(226.8px, 140.246297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(230.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(239.436776px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(359.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(361.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -120.728703px);
    }
    100% {
      transform: translate(361.9px, -120.728703px);
    }
  }
  #eLFwgUQ8cWq101_tr {
    animation: eLFwgUQ8cWq101_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq101_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    23.75% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq101 {
    animation: eLFwgUQ8cWq101_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq101_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 0.7;
    }
    48.75% {
      opacity: 0.7;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq102_to {
    animation: eLFwgUQ8cWq102_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq102_to__to {
    0% {
      transform: translate(226.8px, 140.246297px);
    }
    6.25% {
      transform: translate(226.8px, 140.246297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(230.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(239.436777px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(360.801035px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(361.851035px, -32.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -121.228703px);
    }
    100% {
      transform: translate(361.9px, -121.228703px);
    }
  }
  #eLFwgUQ8cWq102_tr {
    animation: eLFwgUQ8cWq102_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq102_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq102 {
    animation: eLFwgUQ8cWq102_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq102_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    48.75% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq104 {
    animation-name: eLFwgUQ8cWq104__m, eLFwgUQ8cWq104_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eLFwgUQ8cWq104__m {
    0% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    62.5% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    72.5% {
      d: path('M161.867,229.879L161.867,121.677L161.85,122.622406L163.089459,122.622409');
    }
    85% {
      d: path('M161.867,121.682L161.867,121.677L296.68,76.7222L296.68,76.687317');
    }
    87.5% {
      d: path('M182.848766,114.628534L182.911104,114.624313L296.68,76.7222L296.68,66.811911');
    }
    93.75% {
      d: path('M235.303182,96.99487L235.521364,96.992597L296.68,76.7222L296.68,3.623396');
    }
    100% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
  }
  @keyframes eLFwgUQ8cWq104_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq105_to {
    animation: eLFwgUQ8cWq105_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq105_to__to {
    0% {
      transform: translate(227px, 139.146297px);
    }
    58.75% {
      transform: translate(227px, 139.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    71.25% {
      transform: translate(227px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    72.5% {
      transform: translate(230.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    73.75% {
      transform: translate(239.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    85% {
      transform: translate(359.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    86.25% {
      transform: translate(361.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    87.5% {
      transform: translate(361.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    100% {
      transform: translate(361.9px, -119.728703px);
    }
  }
  #eLFwgUQ8cWq105_tr {
    animation: eLFwgUQ8cWq105_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq105_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(0deg);
    }
    72.5% {
      transform: rotate(72deg);
    }
    73.75% {
      transform: rotate(72deg);
    }
    85% {
      transform: rotate(72deg);
    }
    86.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq105 {
    animation: eLFwgUQ8cWq105_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq105_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #eLFwgUQ8cWq106_to {
    animation: eLFwgUQ8cWq106_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq106_to__to {
    0% {
      transform: translate(227px, 139.146297px);
    }
    58.75% {
      transform: translate(227px, 139.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    71.25% {
      transform: translate(227px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    72.5% {
      transform: translate(231.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    73.75% {
      transform: translate(240.798427px, 12.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    85% {
      transform: translate(359.801035px, -27.452471px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    86.25% {
      transform: translate(361.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    87.5% {
      transform: translate(361.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    100% {
      transform: translate(361.9px, -120.228703px);
    }
  }
  #eLFwgUQ8cWq106_tr {
    animation: eLFwgUQ8cWq106_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq106_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(0deg);
    }
    72.5% {
      transform: rotate(72deg);
    }
    85% {
      transform: rotate(72deg);
    }
    86.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq106 {
    animation: eLFwgUQ8cWq106_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq106_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    100% {
      opacity: 0.8;
    }
  }
  #eLFwgUQ8cWq111 {
    animation-name: eLFwgUQ8cWq111__m, eLFwgUQ8cWq111_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eLFwgUQ8cWq111__m {
    0% {
      d: path(
        'M96.711985,145.439636L96.7109,127.987317L96.711985,127.987317C96.711985,127.987317,96.711985,127.987317,96.711985,127.987317'
      );
    }
    1.25% {
      d: path(
        'M96.7109,145.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.711985,121.683001,96.711985,121.683001'
      );
    }
    8.75% {
      d: path(
        'M96.7109,135.000465L96.7109,121.68L163.798426,103.148788C163.798426,103.148788,163.798426,103.148788,163.798426,103.148788'
      );
    }
    16.25% {
      d: path(
        'M96.7109,124.229631L96.7109,121.68L258.531964,77.088615C258.531964,77.088615,258.531964,77.088614,258.531964,77.088614'
      );
    }
    17.5% {
      d: path(
        'M96.7109,122.271298L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817001,75.687789,259.817001,75.687789'
      );
    }
    30% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,-9.65625,259.817,-9.65625');
    }
    100% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,-9.65625,259.817,-9.65625');
    }
  }
  @keyframes eLFwgUQ8cWq111_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    22.5% {
      opacity: 1;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq112_to {
    animation: eLFwgUQ8cWq112_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq112_to__to {
    0% {
      transform: translate(160.8px, 25.504306px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    1.25% {
      transform: translate(166.798427px, 15.088613px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    16.25% {
      transform: translate(322.801035px, -27.911387px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    17.5% {
      transform: translate(324px, -31.712684px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    30% {
      transform: translate(324px, -122.212684px);
    }
    100% {
      transform: translate(324px, -122.212684px);
    }
  }
  #eLFwgUQ8cWq112_tr {
    animation: eLFwgUQ8cWq112_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq112_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(73deg);
    }
    16.25% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq112 {
    animation: eLFwgUQ8cWq112_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq112_c_o {
    0% {
      opacity: 0.7;
    }
    30% {
      opacity: 0.7;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq113_to {
    animation: eLFwgUQ8cWq113_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq113_to__to {
    0% {
      transform: translate(160.8px, 25.504306px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    1.25% {
      transform: translate(166.798427px, 15.157724px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    16.25% {
      transform: translate(322.801035px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    17.5% {
      transform: translate(324px, -31.712684px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    30% {
      transform: translate(324px, -122.228703px);
    }
    100% {
      transform: translate(324px, -122.228703px);
    }
  }
  #eLFwgUQ8cWq113_tr {
    animation: eLFwgUQ8cWq113_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq113_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(73deg);
    }
    16.25% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq113 {
    animation: eLFwgUQ8cWq113_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq113_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq115 {
    animation-name: eLFwgUQ8cWq115__m, eLFwgUQ8cWq115_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eLFwgUQ8cWq115__m {
    0% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    42.5% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    48.75% {
      d: path(
        'M96.7109,144.776311L96.7109,139.694875L96.839592,139.321751C96.839592,139.321751,96.867036,140.071751,96.867036,140.071751'
      );
    }
    52.5% {
      d: path(
        'M96.7109,145.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.908201,121.683001,96.908201,121.683001'
      );
    }
    60% {
      d: path(
        'M96.7109,134.021298L96.7109,121.68L170.897412,101.279844C170.897412,101.279844,170.897413,101.279844,170.897413,101.279844'
      );
    }
    66.25% {
      d: path(
        'M96.7109,121.677L96.7109,121.68L259.817002,76.7624C259.817002,76.7624,259.817002,76.767317,259.817002,76.767317'
      );
    }
    67.5% {
      d: path(
        'M96.7109,121.676257L96.7109,121.68L259.817,76.767317C259.817,76.767317,259.817001,75.687789,259.817001,75.687789'
      );
    }
    72.5% {
      d: path(
        'M96.798427,121.676257L259.817,76.7305L259.817,76.7298C259.817,76.7298,259.817001,47.289957,259.817001,47.289957'
      );
    }
    73.75% {
      d: path(
        'M123.968189,114.183914L259.830833,76.729117L259.817,53.2298C259.817,53.2298,259.650334,38.461747,259.650334,38.461747'
      );
    }
    80% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,-4.2702C259.817,-4.2702,258.817,-5.679304,258.817,-5.679304');
    }
    100% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,-4.2702C259.817,-4.2702,258.817,-5.679304,258.817,-5.679304');
    }
  }
  @keyframes eLFwgUQ8cWq115_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    78.75% {
      opacity: 1;
    }
    83.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq116_to {
    animation: eLFwgUQ8cWq116_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq116_to__to {
    0% {
      transform: translate(161.5px, 50.787316px);
    }
    42.5% {
      transform: translate(161.5px, 50.787316px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(161.5px, 21.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(166.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    66.25% {
      transform: translate(320.801035px, -27.448195px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(324.811985px, -31.712684px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(324.9px, -120.728703px);
    }
    100% {
      transform: translate(324.9px, -120.728703px);
    }
  }
  #eLFwgUQ8cWq116_tr {
    animation: eLFwgUQ8cWq116_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq116_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(73deg);
    }
    66.25% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq116 {
    animation: eLFwgUQ8cWq116_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq116_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 0.7;
    }
    81.25% {
      opacity: 0.7;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq117_to {
    animation: eLFwgUQ8cWq117_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq117_to__to {
    0% {
      transform: translate(161.5px, 50.787316px);
    }
    42.5% {
      transform: translate(161.5px, 50.787316px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(161.5px, 21.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(166.798427px, 15.671297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    66.25% {
      transform: translate(320.801035px, -27.448195px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(324.811985px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(324.9px, -121.228703px);
    }
    100% {
      transform: translate(324.9px, -121.228703px);
    }
  }
  #eLFwgUQ8cWq117_tr {
    animation: eLFwgUQ8cWq117_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq117_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(73deg);
    }
    66.25% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq117 {
    animation: eLFwgUQ8cWq117_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq117_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    81.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq119 {
    animation-name: eLFwgUQ8cWq119__m, eLFwgUQ8cWq119_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eLFwgUQ8cWq119__m {
    0% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    96.25% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    100% {
      d: path(
        'M96.7109,144.112987L96.7109,141.038125L96.867035,140.414251C96.867035,140.414251,96.839592,141.664251,96.839592,141.664251'
      );
    }
  }
  @keyframes eLFwgUQ8cWq119_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eLFwgUQ8cWq120_to {
    animation: eLFwgUQ8cWq120_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq120_to__to {
    0% {
      transform: translate(161.5px, 49.521297px);
    }
    96.25% {
      transform: translate(161.5px, 49.521297px);
    }
    100% {
      transform: translate(161.5px, 24.987316px);
    }
  }
  #eLFwgUQ8cWq120 {
    animation: eLFwgUQ8cWq120_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq120_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #eLFwgUQ8cWq121_to {
    animation: eLFwgUQ8cWq121_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq121_to__to {
    0% {
      transform: translate(161.5px, 49.521297px);
    }
    96.25% {
      transform: translate(161.5px, 49.521297px);
    }
    100% {
      transform: translate(161.5px, 24.987316px);
    }
  }
  #eLFwgUQ8cWq121 {
    animation: eLFwgUQ8cWq121_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq121_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eLFwgUQ8cWq127 {
    animation-name: eLFwgUQ8cWq127__m, eLFwgUQ8cWq127_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eLFwgUQ8cWq127__m {
    0% {
      d: path('M32.0957,249.88L32.0957,240.521298L32.0957,240.521298L32.0957,240.524434');
    }
    12.5% {
      d: path('M32.0957,249.88L32.0957,240.521298L32.0957,240.521298L32.0957,240.524434');
    }
    15% {
      d: path('M32.0957,246.546667L32.0957,218.744746L32.0957,218.744746L33.099414,217.032929');
    }
    27.5% {
      d: path('M32.0957,229.88L32.0957,121.861988L32.0957,121.861988L33.362785,121.626113');
    }
    31.25% {
      d: path('M32.0957,202.407312L32.0957,121.678L80.044445,110.329273L80.044445,110.329272');
    }
    41.25% {
      d: path('M32.0957,129.146811L32.0957,121.678L218.801035,77.955237L218.801035,77.954434');
    }
    42.5% {
      d: path('M32.0957,119.989248L32.0957,121.678L222.955,76.874434L222.920607,76.874434');
    }
    55% {
      d: path('M222.955,76.874434L222.9557,76.874434L222.955,76.874434L222.955,-19.683594');
    }
    100% {
      d: path('M222.955,76.874434L222.9557,76.874434L222.955,76.874434L222.955,-19.683594');
    }
  }
  @keyframes eLFwgUQ8cWq127_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 1;
    }
    51.25% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq127-stroke {
    animation: eLFwgUQ8cWq127-stroke__t 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq127-stroke__t {
    0% {
      transform: matrix(0, 0.653183, -0.653183, 0, 3.395403, 0.105802);
    }
    26.25% {
      transform: matrix(0, 0.653183, -0.653183, 0, 3.395403, 0.105802);
    }
    27.5% {
      transform: matrix(0, 0.653183, -0.653183, 0, 3.395403, 0.105802);
    }
    33.75% {
      transform: matrix(-0.835656, 0.290835, -0.290835, -0.835656, 1.064959, 0.149014);
    }
    100% {
      transform: matrix(-0.835656, 0.290835, -0.290835, -0.835656, 1.064959, 0.149014);
    }
  }
  #eLFwgUQ8cWq127-stroke-0 {
    animation: eLFwgUQ8cWq127-stroke-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq127-stroke-0__c {
    0% {
      stop-color: #7ea6ff;
    }
    26.25% {
      stop-color: #7ea6ff;
    }
    27.5% {
      stop-color: #7ea6ff;
    }
    33.75% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #eLFwgUQ8cWq127-stroke-1 {
    animation: eLFwgUQ8cWq127-stroke-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq127-stroke-1__c {
    0% {
      stop-color: #6069ca;
    }
    26.25% {
      stop-color: #6069ca;
    }
    27.5% {
      stop-color: #6069ca;
    }
    33.75% {
      stop-color: #6069ca;
    }
    100% {
      stop-color: #6069ca;
    }
  }
  #eLFwgUQ8cWq127-stroke-2 {
    animation: eLFwgUQ8cWq127-stroke-2__c 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq127-stroke-2__c {
    0% {
      stop-color: rgba(64, 73, 168, 0);
    }
    26.25% {
      stop-color: rgba(64, 73, 168, 0);
    }
    27.5% {
      stop-color: rgba(64, 73, 168, 0);
    }
    33.75% {
      stop-color: rgba(64, 73, 168, 0);
    }
    100% {
      stop-color: rgba(64, 73, 168, 0);
    }
  }
  #eLFwgUQ8cWq128_to {
    animation: eLFwgUQ8cWq128_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq128_to__to {
    0% {
      transform: translate(96.798427px, 143.537316px);
    }
    11.25% {
      transform: translate(96.798427px, 143.537316px);
    }
    26.25% {
      transform: translate(97.111986px, 20.771297px);
    }
    27.5% {
      transform: translate(100.798427px, 15.771297px);
    }
    41.25% {
      transform: translate(284.801035px, -27.679029px);
    }
    42.5% {
      transform: translate(287.920607px, -33.028703px);
    }
    56.25% {
      transform: translate(287.921986px, -120.728703px);
    }
    100% {
      transform: translate(287.921986px, -120.728703px);
    }
  }
  #eLFwgUQ8cWq128_tr {
    animation: eLFwgUQ8cWq128_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq128_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(75deg);
    }
    41.25% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq128 {
    animation: eLFwgUQ8cWq128_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq128_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.7;
    }
    56.25% {
      opacity: 0.7;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq129_to {
    animation: eLFwgUQ8cWq129_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq129_to__to {
    0% {
      transform: translate(96.798427px, 142.237316px);
    }
    11.25% {
      transform: translate(96.798427px, 142.237316px);
    }
    26.25% {
      transform: translate(97.111986px, 20.643116px);
    }
    27.5% {
      transform: translate(100.798427px, 15.771297px);
    }
    41.25% {
      transform: translate(284.833827px, -27.703774px);
    }
    42.5% {
      transform: translate(288.011986px, -33.028703px);
    }
    56.25% {
      transform: translate(288.011986px, -120.728703px);
    }
    100% {
      transform: translate(288.011986px, -120.728703px);
    }
  }
  #eLFwgUQ8cWq129_tr {
    animation: eLFwgUQ8cWq129_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq129_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(75deg);
    }
    41.25% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq129 {
    animation: eLFwgUQ8cWq129_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq129_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 1;
    }
    56.25% {
      opacity: 1;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq131 {
    animation-name: eLFwgUQ8cWq131__m, eLFwgUQ8cWq131_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eLFwgUQ8cWq131__m {
    0% {
      d: path('M32.0957,249.88L32.0957,239.147317L32.091986,239.147317L32.091986,239.146294');
    }
    62.5% {
      d: path('M32.0957,249.88L32.0957,239.147317L32.091986,239.147317L32.091986,239.146294');
    }
    71.25% {
      d: path('M32.0957,229.88L32.0957,120.271298L32.0957,121.574432L32.0957,122.622403');
    }
    87.5% {
      d: path('M32.0957,121.677317L32.0957,121.678L222.955,76.73L222.951986,76.45812');
    }
    95% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
    100% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
  }
  @keyframes eLFwgUQ8cWq131_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 1;
    }
    92.5% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq132_to {
    animation: eLFwgUQ8cWq132_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq132_to__to {
    0% {
      transform: translate(97px, 140.121297px);
    }
    57.5% {
      transform: translate(97px, 140.121297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(97px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(100.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(114.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    86.25% {
      transform: translate(283.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(287.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -120.728703px);
    }
    100% {
      transform: translate(287.9px, -120.728703px);
    }
  }
  #eLFwgUQ8cWq132_tr {
    animation: eLFwgUQ8cWq132_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq132_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(75deg);
    }
    86.25% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq132 {
    animation: eLFwgUQ8cWq132_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq132_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 0.7;
    }
    96.25% {
      opacity: 0.7;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eLFwgUQ8cWq133_to {
    animation: eLFwgUQ8cWq133_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq133_to__to {
    0% {
      transform: translate(97px, 140.121297px);
    }
    57.5% {
      transform: translate(97px, 140.121297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(97px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(101.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(114.798427px, 12.497225px);
      animation-timing-function: cubic-bezier(0.357385, 0.28504, 0.680746, 0.629112);
    }
    78.75% {
      transform: translate(185.570936px, -4.302775px);
      animation-timing-function: cubic-bezier(0.323457, 0.318946, 0.641855, 0.665929);
    }
    86.25% {
      transform: translate(283.801035px, -27.302775px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(287.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -120.228703px);
    }
    100% {
      transform: translate(287.9px, -120.228703px);
    }
  }
  #eLFwgUQ8cWq133_tr {
    animation: eLFwgUQ8cWq133_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq133_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(75deg);
    }
    86.25% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eLFwgUQ8cWq133 {
    animation: eLFwgUQ8cWq133_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eLFwgUQ8cWq133_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 1;
    }
    96.25% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesBg10;
