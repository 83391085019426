// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesBg6 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)', // Replace all <stop id="...-stroke-1" stopColor="#initial-color" /> with this value
  };

  return (
    <AnimatedSvg
      id="eUa5Wf3Q8kr1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 885 228"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="885"
      height="228"
      className="source-background"
      {...props}
    >
      <defs>
        <filter id="eUa5Wf3Q8kr8-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr8-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eUa5Wf3Q8kr8-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr8-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eUa5Wf3Q8kr8-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr8-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr8-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr8-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eUa5Wf3Q8kr9-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eUa5Wf3Q8kr9-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eUa5Wf3Q8kr9-stroke-1"
            offset="49%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eUa5Wf3Q8kr9-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eUa5Wf3Q8kr10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr10-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eUa5Wf3Q8kr10-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr10-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eUa5Wf3Q8kr10-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr10-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr10-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr10-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eUa5Wf3Q8kr10-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eUa5Wf3Q8kr12-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr12-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eUa5Wf3Q8kr12-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr12-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eUa5Wf3Q8kr12-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr12-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr12-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr12-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eUa5Wf3Q8kr13-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eUa5Wf3Q8kr13-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eUa5Wf3Q8kr13-stroke-1"
            offset="50%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eUa5Wf3Q8kr13-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eUa5Wf3Q8kr14-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr14-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eUa5Wf3Q8kr14-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr14-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eUa5Wf3Q8kr14-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr14-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr14-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr14-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eUa5Wf3Q8kr14-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eUa5Wf3Q8kr16-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr16-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eUa5Wf3Q8kr16-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr16-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eUa5Wf3Q8kr16-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr16-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr16-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr16-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eUa5Wf3Q8kr17-stroke"
          x1="-12.013167"
          y1="0.112007"
          x2="-12.003151"
          y2="0.832563"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eUa5Wf3Q8kr17-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eUa5Wf3Q8kr17-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eUa5Wf3Q8kr18-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr18-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eUa5Wf3Q8kr18-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr18-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eUa5Wf3Q8kr18-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr18-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr18-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr18-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eUa5Wf3Q8kr18-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eUa5Wf3Q8kr23-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr23-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eUa5Wf3Q8kr23-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr23-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eUa5Wf3Q8kr23-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr23-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr23-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr23-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eUa5Wf3Q8kr24-stroke"
          x1="-3.772599"
          y1="0.133903"
          x2="-3.772599"
          y2="1.083705"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eUa5Wf3Q8kr24-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eUa5Wf3Q8kr24-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eUa5Wf3Q8kr25-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr25-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eUa5Wf3Q8kr25-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr25-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eUa5Wf3Q8kr25-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr25-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr25-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr25-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eUa5Wf3Q8kr25-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eUa5Wf3Q8kr27-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr27-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eUa5Wf3Q8kr27-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr27-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eUa5Wf3Q8kr27-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr27-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr27-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr27-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eUa5Wf3Q8kr28-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eUa5Wf3Q8kr28-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eUa5Wf3Q8kr28-stroke-1"
            offset="50%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eUa5Wf3Q8kr28-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eUa5Wf3Q8kr29-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr29-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eUa5Wf3Q8kr29-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr29-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eUa5Wf3Q8kr29-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr29-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr29-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr29-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eUa5Wf3Q8kr29-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eUa5Wf3Q8kr35-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr35-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eUa5Wf3Q8kr35-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr35-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eUa5Wf3Q8kr35-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr35-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr35-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr35-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eUa5Wf3Q8kr36-stroke"
          x1="-2.824226"
          y1="0.15202"
          x2="-2.824226"
          y2="1.031686"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eUa5Wf3Q8kr36-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eUa5Wf3Q8kr36-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="eUa5Wf3Q8kr37-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr37-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eUa5Wf3Q8kr37-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr37-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eUa5Wf3Q8kr37-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr37-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr37-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr37-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eUa5Wf3Q8kr37-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eUa5Wf3Q8kr39-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr39-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eUa5Wf3Q8kr39-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr39-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eUa5Wf3Q8kr39-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr39-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr39-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr39-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eUa5Wf3Q8kr40-stroke"
          x1="-33.166174"
          y1="0.047191"
          x2="-33.166174"
          y2="0.795707"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eUa5Wf3Q8kr40-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eUa5Wf3Q8kr40-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eUa5Wf3Q8kr41-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr41-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eUa5Wf3Q8kr41-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr41-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eUa5Wf3Q8kr41-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr41-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr41-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr41-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eUa5Wf3Q8kr41-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eUa5Wf3Q8kr43-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr43-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eUa5Wf3Q8kr43-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr43-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eUa5Wf3Q8kr43-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr43-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr43-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr43-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eUa5Wf3Q8kr44-stroke"
          x1="-48.697692"
          y1="0.064617"
          x2="-48.697692"
          y2="0.835899"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eUa5Wf3Q8kr44-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eUa5Wf3Q8kr44-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eUa5Wf3Q8kr45-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr45-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eUa5Wf3Q8kr45-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr45-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eUa5Wf3Q8kr45-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr45-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr45-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr45-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eUa5Wf3Q8kr45-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eUa5Wf3Q8kr49-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr49-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eUa5Wf3Q8kr49-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr49-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eUa5Wf3Q8kr49-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr49-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr49-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr49-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eUa5Wf3Q8kr50-stroke"
          x1="80.813277"
          y1="0.13783"
          x2="80.813277"
          y2="1"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eUa5Wf3Q8kr50-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eUa5Wf3Q8kr50-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eUa5Wf3Q8kr51-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr51-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eUa5Wf3Q8kr51-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr51-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eUa5Wf3Q8kr51-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr51-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr51-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr51-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eUa5Wf3Q8kr51-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eUa5Wf3Q8kr53-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr53-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eUa5Wf3Q8kr53-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr53-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eUa5Wf3Q8kr53-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr53-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr53-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr53-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eUa5Wf3Q8kr54-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="matrix(0 0.609227 -0.609227 0 5.789196 0.15287)"
        >
          <stop
            id="eUa5Wf3Q8kr54-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eUa5Wf3Q8kr54-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eUa5Wf3Q8kr55-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr55-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eUa5Wf3Q8kr55-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr55-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eUa5Wf3Q8kr55-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr55-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr55-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr55-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eUa5Wf3Q8kr55-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eUa5Wf3Q8kr57-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr57-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eUa5Wf3Q8kr57-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr57-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eUa5Wf3Q8kr57-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr57-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr57-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr57-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eUa5Wf3Q8kr58-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="matrix(0 0.585638 -0.585638 0 17.102236 0.067326)"
        >
          <stop
            id="eUa5Wf3Q8kr58-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eUa5Wf3Q8kr58-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eUa5Wf3Q8kr59-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr59-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eUa5Wf3Q8kr59-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr59-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eUa5Wf3Q8kr59-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr59-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr59-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr59-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eUa5Wf3Q8kr59-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eUa5Wf3Q8kr64-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr64-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eUa5Wf3Q8kr64-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr64-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eUa5Wf3Q8kr64-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr64-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr64-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr64-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eUa5Wf3Q8kr65-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.021396"
          y2="1.049288"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eUa5Wf3Q8kr65-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eUa5Wf3Q8kr65-stroke-1"
            offset="52%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eUa5Wf3Q8kr65-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eUa5Wf3Q8kr66-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr66-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eUa5Wf3Q8kr66-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr66-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eUa5Wf3Q8kr66-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr66-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr66-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr66-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eUa5Wf3Q8kr66-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eUa5Wf3Q8kr68-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr68-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eUa5Wf3Q8kr68-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr68-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eUa5Wf3Q8kr68-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr68-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr68-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr68-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eUa5Wf3Q8kr69-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.021396"
          y2="1.049288"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eUa5Wf3Q8kr69-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eUa5Wf3Q8kr69-stroke-1"
            offset="53%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eUa5Wf3Q8kr69-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eUa5Wf3Q8kr70-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr70-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eUa5Wf3Q8kr70-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr70-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eUa5Wf3Q8kr70-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr70-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr70-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr70-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eUa5Wf3Q8kr70-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eUa5Wf3Q8kr76-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr76-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eUa5Wf3Q8kr76-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr76-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eUa5Wf3Q8kr76-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr76-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr76-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr76-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eUa5Wf3Q8kr77-stroke"
          x1="95.382681"
          y1="0.116314"
          x2="95.382681"
          y2="0.402464"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eUa5Wf3Q8kr77-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eUa5Wf3Q8kr77-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eUa5Wf3Q8kr78-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr78-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eUa5Wf3Q8kr78-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr78-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eUa5Wf3Q8kr78-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr78-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr78-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr78-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eUa5Wf3Q8kr78-filter-blur-0" stdDeviation="3,3" result="result" />
        </filter>
        <filter id="eUa5Wf3Q8kr79-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr79-filter-blur-0" stdDeviation="0,0" result="result" />
        </filter>
        <filter id="eUa5Wf3Q8kr80-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr80-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eUa5Wf3Q8kr80-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr80-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eUa5Wf3Q8kr80-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr80-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr80-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr80-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eUa5Wf3Q8kr81-stroke"
          x1="7.655045"
          y1="0.126347"
          x2="7.655045"
          y2="0.87891"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eUa5Wf3Q8kr81-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eUa5Wf3Q8kr81-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eUa5Wf3Q8kr82-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eUa5Wf3Q8kr82-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eUa5Wf3Q8kr82-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eUa5Wf3Q8kr82-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eUa5Wf3Q8kr82-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eUa5Wf3Q8kr82-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eUa5Wf3Q8kr82-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eUa5Wf3Q8kr82-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eUa5Wf3Q8kr82-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
      </defs>
      <g transform="translate(0-1.25894)" mask="url(#eUa5Wf3Q8kr84)">
        <g transform="translate(.000001 0)">
          <g transform="translate(.088013 1.012683)">
            <g>
              <path
                d="M853.309,232.641v-111.14137L665.296,76.728v-73.62253"
                transform="matrix(.993835 0 0 1.020627 4.6811-2.431371)"
                fill="none"
                stroke="#6069ca"
              />
              <g transform="translate(-65 105.000001)" filter="url(#eUa5Wf3Q8kr8-filter)">
                <path
                  id="eUa5Wf3Q8kr9"
                  d="M853.309,172.852398v-51.352768l-88.567326-21.284376-.520849-.000001"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  fill="none"
                  stroke="url(#eUa5Wf3Q8kr9-stroke)"
                  strokeLinecap="round"
                />
                <g id="eUa5Wf3Q8kr10_to" transform="translate(827.317602,-5.431736)">
                  <g id="eUa5Wf3Q8kr10_tr" transform="rotate(-75)">
                    <rect
                      id="eUa5Wf3Q8kr10"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0.7"
                      filter="url(#eUa5Wf3Q8kr10-filter)"
                      fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eUa5Wf3Q8kr11_to" transform="translate(827.317602,-5.431736)">
                  <g id="eUa5Wf3Q8kr11_tr" transform="rotate(-75)">
                    <rect
                      id="eUa5Wf3Q8kr11"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eUa5Wf3Q8kr12-filter)">
                <path
                  id="eUa5Wf3Q8kr13"
                  d="M853.309,252.2368v-9.24321-1.766709-3.184316"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eUa5Wf3Q8kr13-stroke)"
                  strokeLinecap="round"
                />
                <g id="eUa5Wf3Q8kr14_to" transform="translate(917.298427,137.546297)">
                  <g id="eUa5Wf3Q8kr14_tr" transform="rotate(0)">
                    <rect
                      id="eUa5Wf3Q8kr14"
                      width="5.882353"
                      height="11.764706"
                      rx="0"
                      ry="0"
                      transform="scale(0.85,0.85) translate(-2.941176,-5.882353)"
                      opacity="0"
                      filter="url(#eUa5Wf3Q8kr14-filter)"
                      fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eUa5Wf3Q8kr15_to" transform="translate(917.298427,137.546297)">
                  <g id="eUa5Wf3Q8kr15_tr" transform="rotate(0)">
                    <rect
                      id="eUa5Wf3Q8kr15"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eUa5Wf3Q8kr16-filter)">
                <path
                  id="eUa5Wf3Q8kr17"
                  d="M853.309,252.2368v-23.94006.192871l-1.006203-1.224736"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eUa5Wf3Q8kr17-stroke)"
                  strokeLinecap="round"
                />
                <g id="eUa5Wf3Q8kr18_to" transform="translate(917.298427,140.146297)">
                  <g id="eUa5Wf3Q8kr18_tr" transform="rotate(0)">
                    <rect
                      id="eUa5Wf3Q8kr18"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eUa5Wf3Q8kr18-filter)"
                      fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eUa5Wf3Q8kr19_to" transform="translate(917.298427,140.146297)">
                  <g id="eUa5Wf3Q8kr19_tr" transform="rotate(0)">
                    <rect
                      id="eUa5Wf3Q8kr19"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g mask="url(#eUa5Wf3Q8kr31)">
              <g>
                <path d="M650.977,138.082v-16.4L554.656,76.7305l-.004-76.414006" fill="none" stroke="#6069ca" />
                <g transform="translate(-65 105.000001)" filter="url(#eUa5Wf3Q8kr23-filter)">
                  <path
                    id="eUa5Wf3Q8kr24"
                    d="M650.977,158.082v-3.4l.052.000001v-2.410703"
                    transform="translate(65-105.000001)"
                    fill="none"
                    stroke="url(#eUa5Wf3Q8kr24-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eUa5Wf3Q8kr25_to" transform="translate(715.298427,51.146297)">
                    <g id="eUa5Wf3Q8kr25_tr" transform="rotate(0)">
                      <rect
                        id="eUa5Wf3Q8kr25"
                        width="5"
                        height="10"
                        rx="0"
                        ry="0"
                        transform="translate(-2.5,-5)"
                        opacity="0.7"
                        filter="url(#eUa5Wf3Q8kr25-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eUa5Wf3Q8kr26_to" transform="translate(715.298427,51.146297)">
                    <g id="eUa5Wf3Q8kr26_tr" transform="rotate(0)">
                      <rect
                        id="eUa5Wf3Q8kr26"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eUa5Wf3Q8kr27-filter)">
                  <path
                    id="eUa5Wf3Q8kr28"
                    d="M650.977,158.082v-3.4l.052.000001v-2.410703"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eUa5Wf3Q8kr28-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eUa5Wf3Q8kr29_to" transform="translate(715.298427,50.146297)">
                    <g id="eUa5Wf3Q8kr29_tr" transform="rotate(0)">
                      <rect
                        id="eUa5Wf3Q8kr29"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0"
                        filter="url(#eUa5Wf3Q8kr29-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eUa5Wf3Q8kr30_to" transform="translate(715.298427,50.146297)">
                    <g id="eUa5Wf3Q8kr30_tr" transform="rotate(0)">
                      <rect
                        id="eUa5Wf3Q8kr30"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="eUa5Wf3Q8kr31" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="140"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 535.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g>
              <path d="M520.668,233.079v-111.398L480.969,76.7549l.005-76.508643" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#eUa5Wf3Q8kr35-filter)">
                <path
                  id="eUa5Wf3Q8kr36"
                  d="M520.668,121.038711L480.969,76.901708v-.146808l.005-4.679761"
                  transform="translate(65-105.000001)"
                  fill="none"
                  stroke="url(#eUa5Wf3Q8kr36-stroke)"
                  strokeLinecap="round"
                />
                <g id="eUa5Wf3Q8kr37_to" transform="translate(546,-36.480793)">
                  <rect
                    id="eUa5Wf3Q8kr37"
                    width="8.75"
                    height="15"
                    rx="0"
                    ry="0"
                    transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                    opacity="0.7"
                    filter="url(#eUa5Wf3Q8kr37-filter)"
                    fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eUa5Wf3Q8kr38_to" transform="translate(546,-36.480793)">
                  <rect
                    id="eUa5Wf3Q8kr38"
                    width="10"
                    height="20"
                    rx="0"
                    ry="0"
                    transform="scale(0.5,0.5) translate(-5,-10)"
                    fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eUa5Wf3Q8kr39-filter)">
                <path
                  id="eUa5Wf3Q8kr40"
                  d="M520.668,273.079v-33.519294v0l-1-1.134557"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eUa5Wf3Q8kr40-stroke)"
                  strokeLinecap="round"
                />
                <g id="eUa5Wf3Q8kr41_to" transform="translate(585.8,139.646297)">
                  <g id="eUa5Wf3Q8kr41_tr" transform="rotate(0)">
                    <rect
                      id="eUa5Wf3Q8kr41"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#eUa5Wf3Q8kr41-filter)"
                      fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eUa5Wf3Q8kr42_to" transform="translate(585.8,139.646297)">
                  <g id="eUa5Wf3Q8kr42_tr" transform="rotate(0)">
                    <rect
                      id="eUa5Wf3Q8kr42"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eUa5Wf3Q8kr43-filter)">
                <path
                  id="eUa5Wf3Q8kr44"
                  d="M520.668,247.079v-12.777975-.741319-1.788408"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eUa5Wf3Q8kr44-stroke)"
                  strokeLinecap="round"
                />
                <g id="eUa5Wf3Q8kr45_to" transform="translate(585.8,133.146297)">
                  <g id="eUa5Wf3Q8kr45_tr" transform="rotate(0)">
                    <rect
                      id="eUa5Wf3Q8kr45"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eUa5Wf3Q8kr45-filter)"
                      fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eUa5Wf3Q8kr46_to" transform="translate(585.8,133.146297)">
                  <g id="eUa5Wf3Q8kr46_tr" transform="rotate(0)">
                    <rect
                      id="eUa5Wf3Q8kr46"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <path
                d="M357.283,233.189v-111.506l49.955-44.9838v-76.35545"
                transform="translate(0 0.202507)"
                fill="none"
                stroke="#6069ca"
              />
              <g transform="translate(-65 105.000001)" filter="url(#eUa5Wf3Q8kr49-filter)">
                <path
                  id="eUa5Wf3Q8kr50"
                  d="M357.283,138.189v-16.506l27.346502-25.007435c0,0,0,.176087,0,.176087"
                  transform="translate(65-104.797494)"
                  fill="none"
                  stroke="url(#eUa5Wf3Q8kr50-stroke)"
                  strokeLinecap="round"
                />
                <g id="eUa5Wf3Q8kr51_to" transform="translate(449,-6.937495)">
                  <g id="eUa5Wf3Q8kr51_tr" transform="rotate(45)">
                    <rect
                      id="eUa5Wf3Q8kr51"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0.7"
                      filter="url(#eUa5Wf3Q8kr51-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eUa5Wf3Q8kr52_to" transform="translate(449,-6.937495)">
                  <g id="eUa5Wf3Q8kr52_tr" transform="rotate(45)">
                    <rect
                      id="eUa5Wf3Q8kr52"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eUa5Wf3Q8kr53-filter)">
                <path
                  id="eUa5Wf3Q8kr54"
                  d="M357.283,248.189v-12.338507l.01,1-.193-.661493"
                  transform="translate(65-104.797494)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eUa5Wf3Q8kr54-stroke)"
                  strokeLinecap="round"
                />
                <g id="eUa5Wf3Q8kr55_to" transform="translate(422.2,137.646297)">
                  <g id="eUa5Wf3Q8kr55_tr" transform="rotate(0)">
                    <rect
                      id="eUa5Wf3Q8kr55"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#eUa5Wf3Q8kr55-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eUa5Wf3Q8kr56_to" transform="translate(422.2,137.646297)">
                  <g id="eUa5Wf3Q8kr56_tr" transform="rotate(0)">
                    <rect
                      id="eUa5Wf3Q8kr56"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eUa5Wf3Q8kr57-filter)">
                <path
                  id="eUa5Wf3Q8kr58"
                  d="M357.283,248.189v-11.860507l.01,1.522-.01-3"
                  transform="translate(65-104.797494)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eUa5Wf3Q8kr58-stroke)"
                  strokeLinecap="round"
                />
                <g id="eUa5Wf3Q8kr59_to" transform="translate(422.1,136.146297)">
                  <g id="eUa5Wf3Q8kr59_tr" transform="rotate(0)">
                    <rect
                      id="eUa5Wf3Q8kr59"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eUa5Wf3Q8kr59-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eUa5Wf3Q8kr60_to" transform="translate(422.1,136.146297)">
                  <g id="eUa5Wf3Q8kr60_tr" transform="rotate(0)">
                    <rect
                      id="eUa5Wf3Q8kr60"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g mask="url(#eUa5Wf3Q8kr72)">
              <g transform="translate(.000001 0)">
                <path d="M226.994,138.053v-16.37L333.531,76.7383l.004-76.414117" fill="none" stroke="#6069ca" />
                <g transform="translate(-65 105.000001)" filter="url(#eUa5Wf3Q8kr64-filter)">
                  <path
                    id="eUa5Wf3Q8kr65"
                    d="M226.994002,161.5213l-.000002-7.468298.106.000002-.070999-.000002"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eUa5Wf3Q8kr65-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eUa5Wf3Q8kr66_to" transform="translate(292.5,52.271297)">
                    <g id="eUa5Wf3Q8kr66_tr" transform="rotate(0)">
                      <rect
                        id="eUa5Wf3Q8kr66"
                        width="8.75"
                        height="15"
                        rx="0"
                        ry="0"
                        transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                        opacity="0"
                        filter="url(#eUa5Wf3Q8kr66-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eUa5Wf3Q8kr67_to" transform="translate(292.5,52.271297)">
                    <g id="eUa5Wf3Q8kr67_tr" transform="rotate(0)">
                      <rect
                        id="eUa5Wf3Q8kr67"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eUa5Wf3Q8kr68-filter)">
                  <path
                    id="eUa5Wf3Q8kr69"
                    d="M226.994002,161.5213l-.000002-7.468298.106.000002-.070999-.000002"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eUa5Wf3Q8kr69-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eUa5Wf3Q8kr70_to" transform="translate(291.8,49.646297)">
                    <g id="eUa5Wf3Q8kr70_tr" transform="rotate(0)">
                      <rect
                        id="eUa5Wf3Q8kr70"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0"
                        filter="url(#eUa5Wf3Q8kr70-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eUa5Wf3Q8kr71_to" transform="translate(291.8,49.646297)">
                    <g id="eUa5Wf3Q8kr71_tr" transform="rotate(0)">
                      <rect
                        id="eUa5Wf3Q8kr71"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="eUa5Wf3Q8kr72" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="140"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 209.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g>
              <path d="M32.0957,229.88v-108.202L222.955,76.73v-76.413594" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#eUa5Wf3Q8kr76-filter)">
                <path
                  id="eUa5Wf3Q8kr77"
                  d="M32.0957,249.88v-9.358702v0v.003136"
                  transform="translate(64.996286-105.187118)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eUa5Wf3Q8kr77-stroke)"
                  strokeLinecap="round"
                />
                <g id="eUa5Wf3Q8kr78_to" transform="translate(96.798427,143.537316)">
                  <g id="eUa5Wf3Q8kr78_tr" transform="rotate(0)">
                    <rect
                      id="eUa5Wf3Q8kr78"
                      width="10"
                      height="17.142857"
                      rx="0"
                      ry="0"
                      transform="scale(0.7,0.7) translate(-5,-8.571429)"
                      opacity="0"
                      filter="url(#eUa5Wf3Q8kr78-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eUa5Wf3Q8kr79_to" transform="translate(96.798427,142.237316)">
                  <g id="eUa5Wf3Q8kr79_tr" transform="rotate(0)">
                    <rect
                      id="eUa5Wf3Q8kr79"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      filter="url(#eUa5Wf3Q8kr79-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      strokeMiterlimit="8"
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eUa5Wf3Q8kr80-filter)">
                <path
                  id="eUa5Wf3Q8kr81"
                  d="M32.0957,249.88v-10.732683h-.003714l-1-1.001023"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eUa5Wf3Q8kr81-stroke)"
                  strokeLinecap="round"
                />
                <g id="eUa5Wf3Q8kr82_to" transform="translate(96.998427,140.121297)">
                  <g id="eUa5Wf3Q8kr82_tr" transform="rotate(0)">
                    <rect
                      id="eUa5Wf3Q8kr82"
                      width="10"
                      height="17.142857"
                      rx="0"
                      ry="0"
                      transform="scale(0.7,0.7) translate(-5,-8.571428)"
                      opacity="0"
                      filter="url(#eUa5Wf3Q8kr82-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eUa5Wf3Q8kr83_to" transform="translate(96.998427,140.121297)">
                  <g id="eUa5Wf3Q8kr83_tr" transform="rotate(0)">
                    <rect
                      id="eUa5Wf3Q8kr83"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <mask id="eUa5Wf3Q8kr84" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="825.762291"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #eUa5Wf3Q8kr9 {
    animation-name: eUa5Wf3Q8kr9__m, eUa5Wf3Q8kr9_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eUa5Wf3Q8kr9__m {
    0% {
      d: path('M853.309,172.852398L853.309,121.49963L764.741674,100.215254L764.220825,100.215253');
    }
    7.5% {
      d: path('M853.309,121.605025L853.309,121.49963L665.296,76.728L665.296,76.180546');
    }
    8.75% {
      d: path('M829.807375,115.995397L829.807375,115.903177L665.296,76.728L665.271333,70.49645');
    }
    10% {
      d: path('M812.181157,111.788176L812.181156,111.705837L665.296,76.728L665.2775,66.702249');
    }
    13.75% {
      d: path('M759.302501,99.166512L759.3025,99.113816L665.296,76.728L665.296,16.128048');
    }
    20% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
    100% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
  }
  @keyframes eUa5Wf3Q8kr9_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    17.5% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr10_to {
    animation: eUa5Wf3Q8kr10_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr10_to__to {
    0% {
      transform: translate(827.317602px, -5.431736px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(736.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(730.920607px, -32.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.219355px, -122.688381px);
    }
    100% {
      transform: translate(731.219355px, -122.688381px);
    }
  }
  #eUa5Wf3Q8kr10_tr {
    animation: eUa5Wf3Q8kr10_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr10_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    7.5% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eUa5Wf3Q8kr10 {
    animation: eUa5Wf3Q8kr10_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr10_c_o {
    0% {
      opacity: 0.7;
    }
    20% {
      opacity: 0.7;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr11_to {
    animation: eUa5Wf3Q8kr11_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr11_to__to {
    0% {
      transform: translate(827.317602px, -5.431736px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(736.801035px, -27.63967px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(730.851035px, -32.48073px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.219354px, -122.688381px);
    }
    100% {
      transform: translate(731.219354px, -122.688381px);
    }
  }
  #eUa5Wf3Q8kr11_tr {
    animation: eUa5Wf3Q8kr11_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr11_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    7.5% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eUa5Wf3Q8kr11 {
    animation: eUa5Wf3Q8kr11_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr11_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr13 {
    animation-name: eUa5Wf3Q8kr13__m, eUa5Wf3Q8kr13_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eUa5Wf3Q8kr13__m {
    0% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    30% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    37.5% {
      d: path('M853.309,237.53995L853.309,151.87312L853.306432,148.3585L853.309001,144.380103');
    }
    40% {
      d: path('M853.309,232.641L853.309,121.49963L853.309,121.605025L852.281356,122.594792');
    }
    56.25% {
      d: path('M853.309,121.605025L853.309,121.49963L665.296,76.728L665.296,76.180546');
    }
    62.5% {
      d: path('M759.302501,99.166512L759.3025,99.113816L665.296,76.728L665.296,11.229098');
    }
    68.75% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
    100% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
  }
  @keyframes eUa5Wf3Q8kr13_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    66.25% {
      opacity: 1;
    }
    72.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr14_to {
    animation: eUa5Wf3Q8kr14_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr14_to__to {
    0% {
      transform: translate(917.298427px, 137.546297px);
    }
    25% {
      transform: translate(917.298427px, 137.546297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 23.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(913.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(905.3352px, 13.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(736.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(730.920607px, -32.510292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -38.49755px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(730.9px, -120.728703px);
    }
    100% {
      transform: translate(730.9px, -120.728703px);
    }
  }
  #eUa5Wf3Q8kr14_tr {
    animation: eUa5Wf3Q8kr14_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr14_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-75deg);
    }
    55% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eUa5Wf3Q8kr14 {
    animation: eUa5Wf3Q8kr14_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr14_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 0.7;
    }
    68.75% {
      opacity: 0.7;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr15_to {
    animation: eUa5Wf3Q8kr15_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr15_to__to {
    0% {
      transform: translate(917.298427px, 137.546297px);
    }
    25% {
      transform: translate(917.298427px, 137.546297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 23.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(913.568884px, 15.76033px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(904.798427px, 13.4px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(736.801035px, -27.43967px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(730.920607px, -32.510292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -38.49755px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(730.9px, -120.228703px);
    }
    100% {
      transform: translate(730.9px, -120.228703px);
    }
  }
  #eUa5Wf3Q8kr15_tr {
    animation: eUa5Wf3Q8kr15_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-75deg);
    }
    55% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eUa5Wf3Q8kr15 {
    animation: eUa5Wf3Q8kr15_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr15_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    68.75% {
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr17 {
    animation-name: eUa5Wf3Q8kr17__m, eUa5Wf3Q8kr17_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eUa5Wf3Q8kr17__m {
    0% {
      d: path('M853.309,252.2368L853.309,228.29674L853.309,228.489611L852.302797,227.264875');
    }
    81.25% {
      d: path('M853.309,252.2368L853.309,228.29674L853.309,228.489611L852.302797,227.264875');
    }
    90% {
      d: path('M853.309,235.090475L853.309,128.970529L853.309,129.086858L851.422369,128.14877');
    }
    91.25% {
      d: path('M853.309,232.641L853.309,121.49963L853.309,121.605025L851.296594,120.707886');
    }
    100% {
      d: path('M853.309,172.852398L853.309,121.49963L762.497203,100.468958L762.736434,100.468959');
    }
  }
  @keyframes eUa5Wf3Q8kr17_c_o {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    76.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eUa5Wf3Q8kr18_to {
    animation: eUa5Wf3Q8kr18_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr18_to__to {
    0% {
      transform: translate(917.298427px, 140.146297px);
    }
    75% {
      transform: translate(917.298427px, 140.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(913.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(905.3352px, 13.841812px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(826.798304px, -5.828881px);
    }
  }
  #eUa5Wf3Q8kr18_tr {
    animation: eUa5Wf3Q8kr18_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr18_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #eUa5Wf3Q8kr18 {
    animation: eUa5Wf3Q8kr18_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr18_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #eUa5Wf3Q8kr19_to {
    animation: eUa5Wf3Q8kr19_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr19_to__to {
    0% {
      transform: translate(917.298427px, 140.146297px);
    }
    75% {
      transform: translate(917.298427px, 140.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(913.3px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(904.798427px, 13.4px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(827.121519px, -5.98073px);
    }
  }
  #eUa5Wf3Q8kr19_tr {
    animation: eUa5Wf3Q8kr19_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr19_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #eUa5Wf3Q8kr19 {
    animation: eUa5Wf3Q8kr19_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr19_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eUa5Wf3Q8kr24 {
    animation-name: eUa5Wf3Q8kr24__m, eUa5Wf3Q8kr24_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eUa5Wf3Q8kr24__m {
    0% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    5% {
      d: path('M650.977,146.653429L650.977,145.824857L650.761566,146.078382L650.761569,145.329073');
    }
    8.75% {
      d: path('M650.977,138.082L650.977,121.682L649.143406,120.771298L649.143407,120.771298');
    }
    22.5% {
      d: path('M650.977,122.082L650.977,121.682L554.656,76.7305L554.652,76.316494');
    }
    28.75% {
      d: path('M575.124559,86.409359L575.124559,86.409359L554.656,76.7305L554.652,9.316494');
    }
    35% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
    100% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
  }
  @keyframes eUa5Wf3Q8kr24_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    35% {
      opacity: 1;
    }
    42.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr25_to {
    animation: eUa5Wf3Q8kr25_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr25_to__to {
    0% {
      transform: translate(715.298427px, 51.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(712.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(706.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(624.801035px, -25.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(619.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(619.9px, -121.728703px);
    }
    100% {
      transform: translate(619.9px, -121.728703px);
    }
  }
  #eUa5Wf3Q8kr25_tr {
    animation: eUa5Wf3Q8kr25_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr25_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-68deg);
    }
    21.25% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eUa5Wf3Q8kr25 {
    animation: eUa5Wf3Q8kr25_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr25_c_o {
    0% {
      opacity: 0.7;
    }
    35% {
      opacity: 0.7;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr26_to {
    animation: eUa5Wf3Q8kr26_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr26_to__to {
    0% {
      transform: translate(715.298427px, 51.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(711.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(705.798427px, 12.6px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(623.801035px, -26.140845px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(619.911987px, -32.40395px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(619.9px, -122.228703px);
    }
    100% {
      transform: translate(619.9px, -122.228703px);
    }
  }
  #eUa5Wf3Q8kr26_tr {
    animation: eUa5Wf3Q8kr26_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr26_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-68deg);
    }
    21.25% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eUa5Wf3Q8kr26 {
    animation: eUa5Wf3Q8kr26_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr26_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    35% {
      opacity: 1;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr28 {
    animation-name: eUa5Wf3Q8kr28__m, eUa5Wf3Q8kr28_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eUa5Wf3Q8kr28__m {
    0% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    43.75% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    48.75% {
      d: path('M650.977,146.653429L650.977,145.824857L650.761566,146.078382L650.761569,145.329073');
    }
    52.5% {
      d: path('M650.977,138.082L650.977,121.682L650.560991,122.125667L650.560995,122.622405');
    }
    66.25% {
      d: path('M650.977,122.082L650.977,121.682L554.656,76.7305L554.652,76.316494');
    }
    72.5% {
      d: path('M575.124559,86.409359L575.124559,86.409359L554.656,76.7305L554.652,9.316494');
    }
    78.75% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
    100% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
  }
  @keyframes eUa5Wf3Q8kr28_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 1;
    }
    78.75% {
      opacity: 1;
    }
    85% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr29_to {
    animation: eUa5Wf3Q8kr29_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr29_to__to {
    0% {
      transform: translate(715.298427px, 50.146297px);
    }
    43.75% {
      transform: translate(715.298427px, 50.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(713.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(707.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(624.801035px, -25.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(619.920607px, -29.810292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -36.79755px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(619.9px, -117.878703px);
    }
    100% {
      transform: translate(619.9px, -117.878703px);
    }
  }
  #eUa5Wf3Q8kr29_tr {
    animation: eUa5Wf3Q8kr29_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr29_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-68deg);
    }
    65% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eUa5Wf3Q8kr29 {
    animation: eUa5Wf3Q8kr29_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr29_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 0.7;
    }
    78.75% {
      opacity: 0.7;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr30_to {
    animation: eUa5Wf3Q8kr30_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr30_to__to {
    0% {
      transform: translate(715.298427px, 50.146297px);
    }
    43.75% {
      transform: translate(715.298427px, 50.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(713.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(707.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(626.801035px, -24.6px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(619.920607px, -29.810292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -36.79755px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(619.9px, -117.878703px);
    }
    100% {
      transform: translate(619.9px, -117.878703px);
    }
  }
  #eUa5Wf3Q8kr30_tr {
    animation: eUa5Wf3Q8kr30_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr30_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-68deg);
    }
    65% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eUa5Wf3Q8kr30 {
    animation: eUa5Wf3Q8kr30_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr30_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 1;
    }
    78.75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr36 {
    animation-name: eUa5Wf3Q8kr36__m, eUa5Wf3Q8kr36_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eUa5Wf3Q8kr36__m {
    0% {
      d: path('M520.668,121.038711L480.969,76.901708L480.969,76.7549L480.974,72.075139');
    }
    6.25% {
      d: path('M480.974,77.356098L480.974,77.356096L480.969,-8.506848L478.974,-13.753743');
    }
    100% {
      d: path('M480.974,77.356098L480.974,77.356096L480.969,-8.506848L478.974,-13.753743');
    }
  }
  @keyframes eUa5Wf3Q8kr36_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    6.25% {
      opacity: 1;
    }
    16.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr37_to {
    animation: eUa5Wf3Q8kr37_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr37_to__to {
    0% {
      transform: translate(546px, -36.480793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    11.25% {
      transform: translate(546px, -121.728703px);
    }
    100% {
      transform: translate(546px, -121.728703px);
    }
  }
  #eUa5Wf3Q8kr37 {
    animation: eUa5Wf3Q8kr37_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr37_c_o {
    0% {
      opacity: 0.7;
    }
    11.25% {
      opacity: 0.7;
    }
    12.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr38_to {
    animation: eUa5Wf3Q8kr38_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr38_to__to {
    0% {
      transform: translate(546px, -36.480793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    11.25% {
      transform: translate(546px, -121.228703px);
    }
    100% {
      transform: translate(546px, -121.228703px);
    }
  }
  #eUa5Wf3Q8kr38 {
    animation: eUa5Wf3Q8kr38_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr38_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    11.25% {
      opacity: 1;
    }
    12.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr40 {
    animation-name: eUa5Wf3Q8kr40__m, eUa5Wf3Q8kr40_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eUa5Wf3Q8kr40__m {
    0% {
      d: path('M520.668,273.079L520.668,239.559706L520.668,239.559706L519.668,238.425149');
    }
    12.5% {
      d: path('M520.668,273.079L520.668,239.559706L520.668,239.559706L519.668,238.425149');
    }
    30% {
      d: path('M520.668,237.079L520.668,121.683L520.668,121.678L519.781986,120.683');
    }
    31.25% {
      d: path('M520.668,223.079L520.668,121.676257L520.668,121.676257L517.03066,117.503531');
    }
    43.75% {
      d: path('M520.668,130.894688L520.668,121.58369L480.971986,76.756257L480.971986,76.756257');
    }
    45% {
      d: path('M520.668,121.676257L480.971986,76.756257L480.969,76.7549L479.971986,75.756257');
    }
    53.75% {
      d: path('M480.974,77.356098L480.974,77.356096L481,-17.228702L479.974,-18.753743');
    }
    100% {
      d: path('M480.974,77.356098L480.974,77.356096L481,-17.228702L479.974,-18.753743');
    }
  }
  @keyframes eUa5Wf3Q8kr40_c_o {
    0% {
      opacity: 0;
    }
    12.5% {
      opacity: 0;
    }
    13.75% {
      opacity: 1;
    }
    53.75% {
      opacity: 1;
    }
    62.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr41_to {
    animation: eUa5Wf3Q8kr41_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr41_to__to {
    0% {
      transform: translate(585.8px, 139.646297px);
    }
    12.5% {
      transform: translate(585.8px, 139.646297px);
    }
    30% {
      transform: translate(585.798427px, 19.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    31.25% {
      transform: translate(583.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    32.5% {
      transform: translate(580.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    43.75% {
      transform: translate(547.7px, -26.2px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    45% {
      transform: translate(545.920607px, -30.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    46.25% {
      transform: translate(545.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    56.25% {
      transform: translate(545.9px, -119.728703px);
    }
    100% {
      transform: translate(545.9px, -119.728703px);
    }
  }
  #eUa5Wf3Q8kr41_tr {
    animation: eUa5Wf3Q8kr41_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr41_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    31.25% {
      transform: rotate(-45deg);
    }
    43.75% {
      transform: rotate(-45deg);
    }
    45% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eUa5Wf3Q8kr41 {
    animation: eUa5Wf3Q8kr41_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr41_c_o {
    0% {
      opacity: 0;
    }
    12.5% {
      opacity: 0;
    }
    13.75% {
      opacity: 0.7;
    }
    56.25% {
      opacity: 0.7;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr42_to {
    animation: eUa5Wf3Q8kr42_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr42_to__to {
    0% {
      transform: translate(585.8px, 139.646297px);
    }
    12.5% {
      transform: translate(585.8px, 139.646297px);
    }
    30% {
      transform: translate(585.798427px, 19.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    31.25% {
      transform: translate(582.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    32.5% {
      transform: translate(579.798427px, 10.099627px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    43.75% {
      transform: translate(547.624298px, -26.300373px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    45% {
      transform: translate(545.851035px, -30.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    46.25% {
      transform: translate(545.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    56.25% {
      transform: translate(545.9px, -120.228703px);
    }
    100% {
      transform: translate(545.9px, -120.228703px);
    }
  }
  #eUa5Wf3Q8kr42_tr {
    animation: eUa5Wf3Q8kr42_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr42_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    31.25% {
      transform: rotate(-45deg);
    }
    43.75% {
      transform: rotate(-45deg);
    }
    45% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eUa5Wf3Q8kr42 {
    animation: eUa5Wf3Q8kr42_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr42_c_o {
    0% {
      opacity: 0;
    }
    12.5% {
      opacity: 0;
    }
    13.75% {
      opacity: 1;
    }
    56.25% {
      opacity: 1;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr44 {
    animation-name: eUa5Wf3Q8kr44__m, eUa5Wf3Q8kr44_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eUa5Wf3Q8kr44__m {
    0% {
      d: path('M520.668,247.079L520.668,234.301025L520.668,233.559706L520.668,231.771298');
    }
    67.5% {
      d: path('M520.668,247.079L520.668,234.301025L520.668,233.559706L520.668,231.771298');
    }
    82.5% {
      d: path('M520.668,234.507571L520.668,125.769575L520.178163,126.399158L520.866858,125.312525');
    }
    85% {
      d: path('M520.668,234.079L520.668,121.681L520.096524,122.539067L520.900001,121.569396');
    }
    98.75% {
      d: path('M520.668,122.079L520.668,121.681L480.969,76.7549L480.974,74.246257');
    }
    100% {
      d: path('M520.668,127.412333L520.668,121.681L480.969,76.7549L480.974,66.135146');
    }
  }
  @keyframes eUa5Wf3Q8kr44_c_o {
    0% {
      opacity: 0;
    }
    66.25% {
      opacity: 0;
    }
    67.5% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eUa5Wf3Q8kr45_to {
    animation: eUa5Wf3Q8kr45_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr45_to__to {
    0% {
      transform: translate(585.8px, 133.146297px);
    }
    67.5% {
      transform: translate(585.8px, 133.146297px);
    }
    82.5% {
      transform: translate(585.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(583.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(580.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    97.5% {
      transform: translate(547.7px, -26.2px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    98.75% {
      transform: translate(545.920607px, -30.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    100% {
      transform: translate(546.2px, -38.021739px);
    }
  }
  #eUa5Wf3Q8kr45_tr {
    animation: eUa5Wf3Q8kr45_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr45_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-45deg);
    }
    97.5% {
      transform: rotate(-45deg);
    }
    98.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eUa5Wf3Q8kr45 {
    animation: eUa5Wf3Q8kr45_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr45_c_o {
    0% {
      opacity: 0;
    }
    66.25% {
      opacity: 0;
    }
    67.5% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #eUa5Wf3Q8kr46_to {
    animation: eUa5Wf3Q8kr46_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr46_to__to {
    0% {
      transform: translate(585.8px, 133.146297px);
    }
    67.5% {
      transform: translate(585.8px, 133.146297px);
    }
    82.5% {
      transform: translate(585.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(582.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(579.798427px, 10px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    97.5% {
      transform: translate(547.124298px, -26.800373px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    98.75% {
      transform: translate(545.851035px, -30.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    100% {
      transform: translate(546.2px, -38.021739px);
    }
  }
  #eUa5Wf3Q8kr46_tr {
    animation: eUa5Wf3Q8kr46_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr46_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-45deg);
    }
    97.5% {
      transform: rotate(-45deg);
    }
    98.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eUa5Wf3Q8kr46 {
    animation: eUa5Wf3Q8kr46_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr46_c_o {
    0% {
      opacity: 0;
    }
    66.25% {
      opacity: 0;
    }
    67.5% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eUa5Wf3Q8kr50 {
    animation-name: eUa5Wf3Q8kr50__m, eUa5Wf3Q8kr50_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eUa5Wf3Q8kr50__m {
    0% {
      d: path(
        'M357.283,138.189L357.283,121.683L384.629502,96.675565C384.629502,96.675565,384.629502,96.851652,384.629502,96.851652'
      );
    }
    6.25% {
      d: path(
        'M357.291333,125.04808L357.283,121.683L403.469917,80.028594C403.469917,80.028594,407.021584,76.36354,407.021584,76.36354'
      );
    }
    7.5% {
      d: path(
        'M357.293,122.419896L357.283,121.683L407.238,76.6992C407.238,76.6992,407.900001,71.065917,407.900001,71.065917'
      );
    }
    8.75% {
      d: path(
        'M367.282,113.129698L367.274,112.540182L407.238,76.553141C407.238,76.553141,407.9,63.316701,407.9,63.316701'
      );
    }
    13.75% {
      d: path(
        'M407.238,75.968908L407.238,75.968908L407.238,75.968907C407.238,75.968907,407.427143,11.282256,407.427143,11.282256'
      );
    }
    16.25% {
      d: path(
        'M407.238,75.676791L407.238,75.67679L407.238,75.67679C407.238,75.67679,407.238,-4.231209,407.238,-4.231209'
      );
    }
    100% {
      d: path(
        'M407.238,75.676791L407.238,75.67679L407.238,75.67679C407.238,75.67679,407.238,-4.231209,407.238,-4.231209'
      );
    }
  }
  @keyframes eUa5Wf3Q8kr50_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    15% {
      opacity: 1;
    }
    21.25% {
      opacity: 0.5;
    }
    100% {
      opacity: 0.5;
    }
  }
  #eUa5Wf3Q8kr51_to {
    animation: eUa5Wf3Q8kr51_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr51_to__to {
    0% {
      transform: translate(449px, -6.937495px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    6.25% {
      transform: translate(470.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    7.5% {
      transform: translate(472.202997px, -32.010292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    8.75% {
      transform: translate(472.2px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(472.2px, -118.878703px);
    }
    100% {
      transform: translate(472.2px, -118.878703px);
    }
  }
  #eUa5Wf3Q8kr51_tr {
    animation: eUa5Wf3Q8kr51_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr51_tr__tr {
    0% {
      transform: rotate(45deg);
    }
    6.25% {
      transform: rotate(45deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eUa5Wf3Q8kr51 {
    animation: eUa5Wf3Q8kr51_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr51_c_o {
    0% {
      opacity: 0.7;
    }
    20% {
      opacity: 0.7;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr52_to {
    animation: eUa5Wf3Q8kr52_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr52_to__to {
    0% {
      transform: translate(449px, -6.937495px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    6.25% {
      transform: translate(470.4px, -26.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    7.5% {
      transform: translate(472.202997px, -32.010292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    8.75% {
      transform: translate(472.2px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(472.2px, -118.878703px);
    }
    100% {
      transform: translate(472.2px, -118.878703px);
    }
  }
  #eUa5Wf3Q8kr52_tr {
    animation: eUa5Wf3Q8kr52_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr52_tr__tr {
    0% {
      transform: rotate(45deg);
    }
    6.25% {
      transform: rotate(45deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eUa5Wf3Q8kr52 {
    animation: eUa5Wf3Q8kr52_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr52_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    20% {
      opacity: 0.8;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr54 {
    animation-name: eUa5Wf3Q8kr54__m, eUa5Wf3Q8kr54_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eUa5Wf3Q8kr54__m {
    0% {
      d: path('M357.283,248.189L357.283,235.850493L357.293,236.850493L357.1,236.189');
    }
    25% {
      d: path('M357.283,248.189L357.283,235.850493L357.293,236.850493L357.1,236.189');
    }
    33.75% {
      d: path('M357.283,238.189L357.283,179.585239L358.192384,178.550684L358.192384,178.550684');
    }
    41.25% {
      d: path('M357.283,238.189L357.283,127.072164L358.963285,125.150848L358.963285,125.150848');
    }
    42.5% {
      d: path('M357.283,238.189L357.283,121.683L361.566194,117.670728L361.566195,117.762415');
    }
    56.25% {
      d: path('M357.283,121.683L357.283,121.683L407.238,76.6992L407.265249,74.260509');
    }
    57.5% {
      d: path('M365.608833,114.1857L365.608833,114.155782L407.238,76.571399L407.344593,64.549044');
    }
    61.25% {
      d: path('M390.586333,91.693801L390.586333,91.574129L407.238,76.187995L407.582624,7.41465');
    }
    63.75% {
      d: path('M407.238,76.699202L407.238,76.519693L407.238,75.932392L407.741312,-11.00828');
    }
    66.25% {
      d: path('M407.238,75.676792L407.238,75.676791L407.238,75.67679L407.9,-19.431209');
    }
    100% {
      d: path('M407.238,75.676792L407.238,75.676791L407.238,75.67679L407.9,-19.431209');
    }
  }
  @keyframes eUa5Wf3Q8kr54_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 0.8;
    }
    65% {
      opacity: 0.8;
    }
    72.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr54-stroke {
    animation: eUa5Wf3Q8kr54-stroke__t 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr54-stroke__t {
    0% {
      transform: matrix(0, 0.609227, -0.609227, 0, 5.789196, 0.15287);
    }
    41.25% {
      transform: matrix(0, 0.609227, -0.609227, 0, 5.789196, 0.15287);
    }
    48.75% {
      transform: matrix(0, 0.593575, -0.593575, 0, 1.617537, 0.132329);
    }
    100% {
      transform: matrix(0, 0.593575, -0.593575, 0, 1.617537, 0.132329);
    }
  }
  #eUa5Wf3Q8kr54-stroke-0 {
    animation: eUa5Wf3Q8kr54-stroke-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr54-stroke-0__c {
    0% {
      stop-color: #7ea6ff;
    }
    41.25% {
      stop-color: #7ea6ff;
    }
    48.75% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #eUa5Wf3Q8kr54-stroke-1 {
    animation: eUa5Wf3Q8kr54-stroke-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr54-stroke-1__c {
    0% {
      stop-color: rgba(64, 73, 168, 0);
    }
    41.25% {
      stop-color: rgba(64, 73, 168, 0);
    }
    48.75% {
      stop-color: rgba(64, 73, 168, 0);
    }
    100% {
      stop-color: rgba(64, 73, 168, 0);
    }
  }
  #eUa5Wf3Q8kr55_to {
    animation: eUa5Wf3Q8kr55_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr55_to__to {
    0% {
      transform: translate(422.2px, 137.646297px);
    }
    25% {
      transform: translate(422.2px, 137.646297px);
    }
    41.25% {
      transform: translate(422.2px, 22.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    42.5% {
      transform: translate(426.798427px, 13px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    43.75% {
      transform: translate(430.3352px, 9.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(470.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(472.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(472.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    65% {
      transform: translate(472.9px, -120.728703px);
    }
    100% {
      transform: translate(472.9px, -120.728703px);
    }
  }
  #eUa5Wf3Q8kr55_tr {
    animation: eUa5Wf3Q8kr55_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr55_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    42.5% {
      transform: rotate(45deg);
    }
    55% {
      transform: rotate(45deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eUa5Wf3Q8kr55 {
    animation: eUa5Wf3Q8kr55_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr55_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 0.7;
    }
    65% {
      opacity: 0.7;
    }
    66.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr56_to {
    animation: eUa5Wf3Q8kr56_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr56_to__to {
    0% {
      transform: translate(422.2px, 137.646297px);
    }
    25% {
      transform: translate(422.2px, 137.646297px);
    }
    41.25% {
      transform: translate(422.2px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    42.5% {
      transform: translate(426.798427px, 13px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    43.75% {
      transform: translate(430.3352px, 9.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(471.4px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(472.851035px, -32.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(472.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    65% {
      transform: translate(472.9px, -121.228703px);
    }
    100% {
      transform: translate(472.9px, -121.228703px);
    }
  }
  #eUa5Wf3Q8kr56_tr {
    animation: eUa5Wf3Q8kr56_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr56_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    42.5% {
      transform: rotate(45deg);
    }
    55% {
      transform: rotate(45deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eUa5Wf3Q8kr56 {
    animation: eUa5Wf3Q8kr56_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr56_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    66.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr58 {
    animation-name: eUa5Wf3Q8kr58__m, eUa5Wf3Q8kr58_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eUa5Wf3Q8kr58__m {
    0% {
      d: path('M357.283,248.189L357.283,236.328493L357.293,237.850493L357.283,234.850493');
    }
    77.5% {
      d: path('M357.283,248.189L357.283,236.328493L357.293,237.850493L357.283,234.850493');
    }
    88.75% {
      d: path('M357.283,248.189L357.283,128.013687L358.485249,124.682209L358.485249,124.682209');
    }
    90% {
      d: path('M357.283,248.189L357.283,121.683L359.798427,119.409886L359.798427,119.409886');
    }
    98.75% {
      d: path('M357.283,138.189L357.283,121.683L378.447064,102.839971L380.992649,101.014044');
    }
    100% {
      d: path('M357.283,138.189L357.283,121.683L364.111115,114.973587L382.557334,99.694926');
    }
  }
  @keyframes eUa5Wf3Q8kr58_c_o {
    0% {
      opacity: 0;
    }
    76.25% {
      opacity: 0;
    }
    77.5% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  #eUa5Wf3Q8kr58-stroke {
    animation: eUa5Wf3Q8kr58-stroke__t 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr58-stroke__t {
    0% {
      transform: matrix(0, 0.585638, -0.585638, 0, 17.102236, 0.067326);
    }
    87.5% {
      transform: matrix(0, 0.585638, -0.585638, 0, 17.102236, 0.067326);
    }
    91.25% {
      transform: matrix(0, 0.583369, -0.583369, 0, 5.736072, 0.02732);
    }
    100% {
      transform: matrix(-0.629087, 1.87198, -1.87198, -0.629087, 1.129087, 0.052706);
    }
  }
  #eUa5Wf3Q8kr58-stroke-0 {
    animation: eUa5Wf3Q8kr58-stroke-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr58-stroke-0__c {
    0% {
      stop-color: #7ea6ff;
    }
    87.5% {
      stop-color: #7ea6ff;
    }
    91.25% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #eUa5Wf3Q8kr58-stroke-1 {
    animation: eUa5Wf3Q8kr58-stroke-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr58-stroke-1__c {
    0% {
      stop-color: rgba(64, 73, 168, 0);
    }
    87.5% {
      stop-color: rgba(64, 73, 168, 0);
    }
    91.25% {
      stop-color: rgba(64, 73, 168, 0);
    }
    100% {
      stop-color: rgba(64, 73, 168, 0);
    }
  }
  #eUa5Wf3Q8kr59_to {
    animation: eUa5Wf3Q8kr59_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr59_to__to {
    0% {
      transform: translate(422.1px, 136.146297px);
    }
    77.5% {
      transform: translate(422.1px, 136.146297px);
    }
    88.75% {
      transform: translate(422.1px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    90% {
      transform: translate(424.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    91.25% {
      transform: translate(430.3352px, 9.841812px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(449.063292px, -7.316354px);
    }
  }
  #eUa5Wf3Q8kr59_tr {
    animation: eUa5Wf3Q8kr59_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr59_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    88.75% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(45deg);
    }
  }
  #eUa5Wf3Q8kr59 {
    animation: eUa5Wf3Q8kr59_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr59_c_o {
    0% {
      opacity: 0;
    }
    76.25% {
      opacity: 0;
    }
    77.5% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #eUa5Wf3Q8kr60_to {
    animation: eUa5Wf3Q8kr60_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr60_to__to {
    0% {
      transform: translate(422.1px, 136.146297px);
    }
    77.5% {
      transform: translate(422.1px, 136.146297px);
    }
    88.75% {
      transform: translate(422.1px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    90% {
      transform: translate(424.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    91.25% {
      transform: translate(430.3352px, 9.841812px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(449.052153px, -7.536834px);
    }
  }
  #eUa5Wf3Q8kr60_tr {
    animation: eUa5Wf3Q8kr60_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr60_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    88.75% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(45deg);
    }
  }
  #eUa5Wf3Q8kr60 {
    animation: eUa5Wf3Q8kr60_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr60_c_o {
    0% {
      opacity: 0;
    }
    76.25% {
      opacity: 0;
    }
    77.5% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eUa5Wf3Q8kr65 {
    animation-name: eUa5Wf3Q8kr65__m, eUa5Wf3Q8kr65_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eUa5Wf3Q8kr65__m {
    0% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    18.75% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    23.75% {
      d: path('M226.994001,149.78715L226.994,145.868001L227.0645,146.865005L226.907042,146.549048');
    }
    28.75% {
      d: path('M226.994,138.053L226.994,121.683L227.029,121.677006L228.785083,121.045093');
    }
    40% {
      d: path('M227.029,121.683L226.994,121.683L333.531,76.7383L333.535,76.696467');
    }
    42.5% {
      d: path('M245.992733,113.68997L245.963955,113.721882L333.531,76.7383L333.535,65.343379');
    }
    50% {
      d: path('M312.365799,85.714363L312.358799,85.857968L333.531,76.7383L333.535,-14.892427');
    }
    55% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
    100% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
  }
  @keyframes eUa5Wf3Q8kr65_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    52.5% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr66_to {
    animation: eUa5Wf3Q8kr66_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr66_to__to {
    0% {
      transform: translate(292.5px, 52.271297px);
    }
    18.75% {
      transform: translate(292.5px, 52.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(291.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(295.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(304.3352px, 12.1px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(398.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -119.728703px);
    }
    100% {
      transform: translate(398.699999px, -119.728703px);
    }
  }
  #eUa5Wf3Q8kr66_tr {
    animation: eUa5Wf3Q8kr66_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr66_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(68deg);
    }
    30% {
      transform: rotate(68deg);
    }
    40% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eUa5Wf3Q8kr66 {
    animation: eUa5Wf3Q8kr66_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr66_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 0.7;
    }
    53.75% {
      opacity: 0.7;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr67_to {
    animation: eUa5Wf3Q8kr67_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr67_to__to {
    0% {
      transform: translate(292.5px, 52.271297px);
    }
    18.75% {
      transform: translate(292.5px, 52.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(291.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(296.798427px, 14.51295px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(305.474547px, 11.51295px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(398.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -120.228703px);
    }
    100% {
      transform: translate(398.699999px, -120.228703px);
    }
  }
  #eUa5Wf3Q8kr67_tr {
    animation: eUa5Wf3Q8kr67_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr67_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(68deg);
    }
    40% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eUa5Wf3Q8kr67 {
    animation: eUa5Wf3Q8kr67_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr67_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    53.75% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr69 {
    animation-name: eUa5Wf3Q8kr69__m, eUa5Wf3Q8kr69_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eUa5Wf3Q8kr69__m {
    0% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    58.75% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    63.75% {
      d: path('M226.994001,149.78715L226.994,145.868001L227.0645,146.865005L226.907042,146.549048');
    }
    68.75% {
      d: path('M226.994,138.053L226.994,121.683L227.029,121.677006L228.785083,121.045093');
    }
    82.5% {
      d: path('M227.029,121.683L226.994,121.683L333.531,76.7383L333.535,76.687317');
    }
    92.5% {
      d: path('M312.365799,85.714363L312.358799,85.857968L333.531,76.7383L333.535,-14.892427');
    }
    95% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
    100% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
  }
  @keyframes eUa5Wf3Q8kr69_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    93.75% {
      opacity: 1;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr70_to {
    animation: eUa5Wf3Q8kr70_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr70_to__to {
    0% {
      transform: translate(291.8px, 49.646297px);
    }
    58.75% {
      transform: translate(291.8px, 49.646297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(295.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(304.3352px, 12.1px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(398.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -119.378703px);
    }
    100% {
      transform: translate(398.699999px, -119.378703px);
    }
  }
  #eUa5Wf3Q8kr70_tr {
    animation: eUa5Wf3Q8kr70_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr70_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(68deg);
    }
    70% {
      transform: rotate(68deg);
    }
    81.25% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eUa5Wf3Q8kr70 {
    animation: eUa5Wf3Q8kr70_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr70_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 0.7;
    }
    95% {
      opacity: 0.7;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr71_to {
    animation: eUa5Wf3Q8kr71_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr71_to__to {
    0% {
      transform: translate(291.8px, 49.646297px);
    }
    58.75% {
      transform: translate(291.8px, 49.646297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(296.798427px, 14px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(305.174547px, 11.51295px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(396.801035px, -27.740845px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(398.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -119.378703px);
    }
    100% {
      transform: translate(398.699999px, -119.378703px);
    }
  }
  #eUa5Wf3Q8kr71_tr {
    animation: eUa5Wf3Q8kr71_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr71_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(68deg);
    }
    81.25% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eUa5Wf3Q8kr71 {
    animation: eUa5Wf3Q8kr71_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr71_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr77 {
    animation-name: eUa5Wf3Q8kr77__m, eUa5Wf3Q8kr77_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eUa5Wf3Q8kr77__m {
    0% {
      d: path('M32.0957,249.88L32.0957,240.521298L32.0957,240.521298L32.0957,240.524434');
    }
    11.25% {
      d: path('M32.0957,249.88L32.0957,240.521298L32.0957,240.521298L32.0957,240.524434');
    }
    16.25% {
      d: path('M32.0957,249.88L32.0957,240.521298L32.0957,210.521298L32.2157,206.893034');
    }
    27.5% {
      d: path('M32.0957,229.88L32.0957,121.861988L32.0957,121.861988L32.0957,121.869117');
    }
    31.25% {
      d: path('M32.0957,202.407312L32.0957,121.678L80.044445,110.329273L80.044445,110.329272');
    }
    41.25% {
      d: path('M32.0957,129.146811L32.0957,121.678L218.801035,77.955237L218.801035,77.954434');
    }
    42.5% {
      d: path('M32.0957,119.989248L32.0957,121.678L222.955,76.874434L222.920607,76.874434');
    }
    55% {
      d: path('M222.955,76.874434L222.9557,76.874434L222.955,76.874434L222.955,-19.683594');
    }
    100% {
      d: path('M222.955,76.874434L222.9557,76.874434L222.955,76.874434L222.955,-19.683594');
    }
  }
  @keyframes eUa5Wf3Q8kr77_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 1;
    }
    51.25% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr78_to {
    animation: eUa5Wf3Q8kr78_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr78_to__to {
    0% {
      transform: translate(96.798427px, 143.537316px);
    }
    11.25% {
      transform: translate(96.798427px, 143.537316px);
    }
    26.25% {
      transform: translate(97.111986px, 20.771297px);
    }
    27.5% {
      transform: translate(100.798427px, 15.771297px);
    }
    41.25% {
      transform: translate(284.801035px, -27.679029px);
    }
    42.5% {
      transform: translate(287.920607px, -33.028703px);
    }
    56.25% {
      transform: translate(287.921986px, -120.728703px);
    }
    100% {
      transform: translate(287.921986px, -120.728703px);
    }
  }
  #eUa5Wf3Q8kr78_tr {
    animation: eUa5Wf3Q8kr78_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr78_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(75deg);
    }
    41.25% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eUa5Wf3Q8kr78 {
    animation: eUa5Wf3Q8kr78_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr78_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.7;
    }
    56.25% {
      opacity: 0.7;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr79_to {
    animation: eUa5Wf3Q8kr79_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr79_to__to {
    0% {
      transform: translate(96.798427px, 142.237316px);
    }
    11.25% {
      transform: translate(96.798427px, 142.237316px);
    }
    26.25% {
      transform: translate(97.111986px, 20.643116px);
    }
    27.5% {
      transform: translate(100.798427px, 15.771297px);
    }
    41.25% {
      transform: translate(284.833827px, -27.703774px);
    }
    42.5% {
      transform: translate(288.011986px, -33.028703px);
    }
    56.25% {
      transform: translate(288.011986px, -120.728703px);
    }
    100% {
      transform: translate(288.011986px, -120.728703px);
    }
  }
  #eUa5Wf3Q8kr79_tr {
    animation: eUa5Wf3Q8kr79_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr79_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(75deg);
    }
    41.25% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eUa5Wf3Q8kr79 {
    animation: eUa5Wf3Q8kr79_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr79_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 1;
    }
    56.25% {
      opacity: 1;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr81 {
    animation-name: eUa5Wf3Q8kr81__m, eUa5Wf3Q8kr81_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eUa5Wf3Q8kr81__m {
    0% {
      d: path('M32.0957,249.88L32.0957,239.147317L32.091986,239.147317L31.091986,238.146294');
    }
    62.5% {
      d: path('M32.0957,249.88L32.0957,239.147317L32.091986,239.147317L31.091986,238.146294');
    }
    70% {
      d: path('M32.0957,232.737143L32.0957,128.47498L32.095169,128.47498L33.117275,128.049119');
    }
    71.25% {
      d: path('M32.0957,229.88L32.0957,121.696257L32.0957,121.696257L33.454823,121.366257');
    }
    87.5% {
      d: path('M32.0957,121.677317L32.0957,121.678L222.955,76.73L222.951986,76.45812');
    }
    95% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
    100% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
  }
  @keyframes eUa5Wf3Q8kr81_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 1;
    }
    93.75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr82_to {
    animation: eUa5Wf3Q8kr82_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr82_to__to {
    0% {
      transform: translate(96.998427px, 140.121297px);
    }
    57.5% {
      transform: translate(96.998427px, 140.121297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(96.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(100.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(114.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    86.25% {
      transform: translate(283.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(287.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -120.728703px);
    }
    100% {
      transform: translate(287.9px, -120.728703px);
    }
  }
  #eUa5Wf3Q8kr82_tr {
    animation: eUa5Wf3Q8kr82_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr82_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(75deg);
    }
    86.25% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eUa5Wf3Q8kr82 {
    animation: eUa5Wf3Q8kr82_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr82_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 0.7;
    }
    96.25% {
      opacity: 0.7;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eUa5Wf3Q8kr83_to {
    animation: eUa5Wf3Q8kr83_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr83_to__to {
    0% {
      transform: translate(96.998427px, 140.121297px);
    }
    57.5% {
      transform: translate(96.998427px, 140.121297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(96.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(100.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(114.798427px, 12.497225px);
      animation-timing-function: cubic-bezier(0.357385, 0.28504, 0.680746, 0.629112);
    }
    78.75% {
      transform: translate(185.570936px, -4.302775px);
      animation-timing-function: cubic-bezier(0.323457, 0.318946, 0.641855, 0.665929);
    }
    86.25% {
      transform: translate(283.801035px, -27.302775px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(287.851035px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -120.228703px);
    }
    100% {
      transform: translate(287.9px, -120.228703px);
    }
  }
  #eUa5Wf3Q8kr83_tr {
    animation: eUa5Wf3Q8kr83_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr83_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(75deg);
    }
    86.25% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eUa5Wf3Q8kr83 {
    animation: eUa5Wf3Q8kr83_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eUa5Wf3Q8kr83_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 1;
    }
    96.25% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesBg6;
