// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesBg8 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)', // Replace all <stop id="...-stroke-1" stopColor="#initial-color" /> with this value
  };

  return (
    <AnimatedSvg
      id="e9z31OQKE0r1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 885 228"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="885"
      height="228"
      className="source-background"
      {...props}
    >
      <defs>
        <filter id="e9z31OQKE0r8-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r8-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e9z31OQKE0r8-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r8-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e9z31OQKE0r8-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r8-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r8-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r8-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9z31OQKE0r9-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9z31OQKE0r9-stroke-0"
            offset="0%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="e9z31OQKE0r9-stroke-1"
            offset="49%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9z31OQKE0r9-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="e9z31OQKE0r10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r10-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e9z31OQKE0r10-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r10-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e9z31OQKE0r10-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r10-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r10-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r10-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e9z31OQKE0r10-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e9z31OQKE0r12-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r12-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e9z31OQKE0r12-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r12-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e9z31OQKE0r12-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r12-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r12-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r12-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9z31OQKE0r13-stroke"
          x1="-7899.213805"
          y1="0.054894"
          x2="-7899.213805"
          y2="0.819586"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9z31OQKE0r13-stroke-0"
            offset="0%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="e9z31OQKE0r13-stroke-1"
            offset="50%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9z31OQKE0r13-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="e9z31OQKE0r14-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r14-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e9z31OQKE0r14-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r14-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e9z31OQKE0r14-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r14-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r14-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r14-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e9z31OQKE0r14-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e9z31OQKE0r16-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r16-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e9z31OQKE0r16-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r16-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e9z31OQKE0r16-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r16-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r16-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r16-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9z31OQKE0r17-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9z31OQKE0r17-stroke-0"
            offset="0%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="e9z31OQKE0r17-stroke-1"
            offset="52%"
            stopColor={selected === 7 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9z31OQKE0r17-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="e9z31OQKE0r18-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r18-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e9z31OQKE0r18-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r18-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e9z31OQKE0r18-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r18-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r18-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r18-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e9z31OQKE0r18-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e9z31OQKE0r23-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r23-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e9z31OQKE0r23-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r23-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e9z31OQKE0r23-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r23-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r23-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r23-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9z31OQKE0r24-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9z31OQKE0r24-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="e9z31OQKE0r24-stroke-1"
            offset="50%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9z31OQKE0r24-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="e9z31OQKE0r25-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r25-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e9z31OQKE0r25-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r25-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e9z31OQKE0r25-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r25-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r25-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r25-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e9z31OQKE0r25-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e9z31OQKE0r27-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r27-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e9z31OQKE0r27-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r27-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e9z31OQKE0r27-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r27-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r27-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r27-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9z31OQKE0r28-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9z31OQKE0r28-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="e9z31OQKE0r28-stroke-1"
            offset="51%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9z31OQKE0r28-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="e9z31OQKE0r29-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r29-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e9z31OQKE0r29-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r29-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e9z31OQKE0r29-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r29-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r29-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r29-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e9z31OQKE0r29-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e9z31OQKE0r35-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r35-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e9z31OQKE0r35-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r35-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e9z31OQKE0r35-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r35-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r35-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r35-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9z31OQKE0r36-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9z31OQKE0r36-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="e9z31OQKE0r36-stroke-1"
            offset="51%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9z31OQKE0r36-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="e9z31OQKE0r37-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r37-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e9z31OQKE0r37-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r37-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e9z31OQKE0r37-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r37-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r37-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r37-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e9z31OQKE0r37-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e9z31OQKE0r39-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r39-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e9z31OQKE0r39-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r39-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e9z31OQKE0r39-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r39-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r39-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r39-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9z31OQKE0r40-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9z31OQKE0r40-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="e9z31OQKE0r40-stroke-1"
            offset="50%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9z31OQKE0r40-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="e9z31OQKE0r41-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r41-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e9z31OQKE0r41-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r41-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e9z31OQKE0r41-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r41-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r41-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r41-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e9z31OQKE0r41-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e9z31OQKE0r43-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r43-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e9z31OQKE0r43-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r43-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e9z31OQKE0r43-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r43-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r43-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r43-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9z31OQKE0r44-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9z31OQKE0r44-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="e9z31OQKE0r44-stroke-1"
            offset="52%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9z31OQKE0r44-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="e9z31OQKE0r45-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r45-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e9z31OQKE0r45-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r45-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e9z31OQKE0r45-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r45-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r45-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r45-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e9z31OQKE0r45-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e9z31OQKE0r50-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r50-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e9z31OQKE0r50-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r50-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e9z31OQKE0r50-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r50-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r50-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r50-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9z31OQKE0r51-stroke"
          x1="-0.163933"
          y1="0.076837"
          x2="0.463667"
          y2="0.849483"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9z31OQKE0r51-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9z31OQKE0r51-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="e9z31OQKE0r52-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r52-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e9z31OQKE0r52-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r52-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e9z31OQKE0r52-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r52-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r52-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r52-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e9z31OQKE0r52-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e9z31OQKE0r54-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r54-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e9z31OQKE0r54-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r54-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e9z31OQKE0r54-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r54-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r54-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r54-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9z31OQKE0r55-stroke"
          x1="0.078482"
          y1="0.077876"
          x2="0.847402"
          y2="0.560634"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9z31OQKE0r55-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9z31OQKE0r55-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="e9z31OQKE0r56-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r56-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e9z31OQKE0r56-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r56-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e9z31OQKE0r56-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r56-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r56-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r56-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e9z31OQKE0r56-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e9z31OQKE0r63-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r63-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e9z31OQKE0r63-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r63-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e9z31OQKE0r63-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r63-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r63-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r63-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9z31OQKE0r64-stroke"
          x1="1.156841"
          y1="0.214696"
          x2="0.194169"
          y2="1.326958"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9z31OQKE0r64-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9z31OQKE0r64-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="e9z31OQKE0r65-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r65-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e9z31OQKE0r65-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r65-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e9z31OQKE0r65-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r65-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r65-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r65-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e9z31OQKE0r65-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e9z31OQKE0r67-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r67-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e9z31OQKE0r67-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r67-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e9z31OQKE0r67-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r67-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r67-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r67-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9z31OQKE0r68-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="matrix(0 0.154977 -0.154977 0 1.372093 0.008289)"
        >
          <stop
            id="e9z31OQKE0r68-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9z31OQKE0r68-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="e9z31OQKE0r69-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r69-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e9z31OQKE0r69-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r69-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e9z31OQKE0r69-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r69-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r69-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r69-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e9z31OQKE0r69-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e9z31OQKE0r75-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r75-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e9z31OQKE0r75-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r75-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e9z31OQKE0r75-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r75-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r75-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r75-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9z31OQKE0r76-stroke"
          x1="20.833613"
          y1="0.077014"
          x2="20.833613"
          y2="0.880437"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9z31OQKE0r76-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9z31OQKE0r76-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <filter id="e9z31OQKE0r77-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r77-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e9z31OQKE0r77-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r77-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e9z31OQKE0r77-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r77-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r77-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r77-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e9z31OQKE0r77-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e9z31OQKE0r79-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r79-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e9z31OQKE0r79-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r79-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e9z31OQKE0r79-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r79-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r79-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r79-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9z31OQKE0r80-stroke"
          x1="11.919349"
          y1="0.080583"
          x2="11.919349"
          y2="0.862952"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9z31OQKE0r80-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="e9z31OQKE0r80-stroke-1"
            offset="52%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9z31OQKE0r80-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="e9z31OQKE0r81-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r81-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e9z31OQKE0r81-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r81-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e9z31OQKE0r81-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r81-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r81-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r81-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e9z31OQKE0r81-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e9z31OQKE0r86-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r86-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e9z31OQKE0r86-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r86-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e9z31OQKE0r86-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r86-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r86-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r86-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9z31OQKE0r87-stroke"
          x1="-0.512417"
          y1="0.395639"
          x2="0.870359"
          y2="-0.40284"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="e9z31OQKE0r87-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="e9z31OQKE0r87-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="e9z31OQKE0r88-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r88-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e9z31OQKE0r88-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r88-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e9z31OQKE0r88-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r88-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r88-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r88-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e9z31OQKE0r88-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e9z31OQKE0r90-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r90-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e9z31OQKE0r90-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r90-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e9z31OQKE0r90-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r90-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r90-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r90-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9z31OQKE0r91-stroke"
          x1="75.085524"
          y1="1.069123"
          x2="75.085524"
          y2="0.122198"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="e9z31OQKE0r91-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="e9z31OQKE0r91-stroke-1"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="e9z31OQKE0r92-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r92-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e9z31OQKE0r92-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r92-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e9z31OQKE0r92-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r92-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r92-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r92-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e9z31OQKE0r92-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e9z31OQKE0r94-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r94-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e9z31OQKE0r94-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r94-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e9z31OQKE0r94-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r94-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r94-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r94-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9z31OQKE0r95-stroke"
          x1="-0.142435"
          y1="1.238891"
          x2="0.883069"
          y2="-0.083996"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="e9z31OQKE0r95-stroke-0" offset="2%" stopColor="rgba(64,73,168,0)" />
          <stop
            id="e9z31OQKE0r95-stroke-1"
            offset="51%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="e9z31OQKE0r95-stroke-2"
            offset="100%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="e9z31OQKE0r96-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r96-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e9z31OQKE0r96-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r96-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e9z31OQKE0r96-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r96-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r96-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r96-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e9z31OQKE0r96-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="e9z31OQKE0r97-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r97-filter-blur-0" stdDeviation="0,0" result="result" />
        </filter>
        <filter id="e9z31OQKE0r102-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r102-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e9z31OQKE0r102-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r102-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e9z31OQKE0r102-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r102-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r102-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r102-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9z31OQKE0r103-stroke"
          x1="16.836834"
          y1="0.059392"
          x2="16.836834"
          y2="0.824488"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9z31OQKE0r103-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="e9z31OQKE0r103-stroke-1"
            offset="55%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9z31OQKE0r103-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="e9z31OQKE0r104-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r104-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e9z31OQKE0r104-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r104-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e9z31OQKE0r104-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r104-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r104-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r104-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e9z31OQKE0r104-filter-blur-0" stdDeviation="3,3" result="result" />
        </filter>
        <filter id="e9z31OQKE0r105-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r105-filter-blur-0" stdDeviation="0,0" result="result" />
        </filter>
        <filter id="e9z31OQKE0r106-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r106-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="e9z31OQKE0r106-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r106-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="e9z31OQKE0r106-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r106-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r106-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r106-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="e9z31OQKE0r107-stroke"
          x1="9964.710098"
          y1="0.02487"
          x2="9964.710098"
          y2="0.639983"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="e9z31OQKE0r107-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="e9z31OQKE0r107-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="e9z31OQKE0r108-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="e9z31OQKE0r108-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="e9z31OQKE0r108-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="e9z31OQKE0r108-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="e9z31OQKE0r108-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="e9z31OQKE0r108-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="e9z31OQKE0r108-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="e9z31OQKE0r108-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="e9z31OQKE0r108-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
      </defs>
      <g transform="translate(0-1.25894)" mask="url(#e9z31OQKE0r110)">
        <g transform="translate(.000001 0)">
          <g transform="translate(.088013 1.012683)">
            <g transform="translate(-12 0)">
              <path
                d="M853.309,232.641v-111.14137L665.296,76.728v-73.62253"
                transform="matrix(.993835 0 0 1.020627 4.6811-2.431371)"
                fill="none"
                stroke="#6069ca"
              />
              <g transform="translate(-65 105.000001)" filter="url(#e9z31OQKE0r8-filter)">
                <path
                  id="e9z31OQKE0r9"
                  d="M853.309,172.852398v-51.352768l-88.567326-21.284376-.520849-.000001"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  fill="none"
                  stroke="url(#e9z31OQKE0r9-stroke)"
                  strokeLinecap="round"
                />
                <g id="e9z31OQKE0r10_to" transform="translate(828.317602,-5.431736)">
                  <g id="e9z31OQKE0r10_tr" transform="rotate(-75)">
                    <rect
                      id="e9z31OQKE0r10"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0.7"
                      filter="url(#e9z31OQKE0r10-filter)"
                      fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="e9z31OQKE0r11_to" transform="translate(828.317602,-5.431736)">
                  <g id="e9z31OQKE0r11_tr" transform="rotate(-75)">
                    <rect
                      id="e9z31OQKE0r11"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#e9z31OQKE0r12-filter)">
                <path
                  id="e9z31OQKE0r13"
                  d="M853.309,252.2368v-9.24321-1.766709-3.184316"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e9z31OQKE0r13-stroke)"
                  strokeLinecap="round"
                />
                <g id="e9z31OQKE0r14_to" transform="translate(917.8,138.546297)">
                  <g id="e9z31OQKE0r14_tr" transform="rotate(0)">
                    <rect
                      id="e9z31OQKE0r14"
                      width="8.235294"
                      height="14.117647"
                      rx="0"
                      ry="0"
                      transform="scale(0.85,0.85) translate(-4.117647,-7.058824)"
                      opacity="0"
                      filter="url(#e9z31OQKE0r14-filter)"
                      fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="e9z31OQKE0r15_to" transform="translate(917.8,138.546297)">
                  <g id="e9z31OQKE0r15_tr" transform="rotate(0)">
                    <rect
                      id="e9z31OQKE0r15"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#e9z31OQKE0r16-filter)">
                <path
                  id="e9z31OQKE0r17"
                  d="M853.309,252.2368v-9.24321-1.766709-3.184316"
                  transform="matrix(.993835 0 0 1.020627 70.6811-107.431372)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e9z31OQKE0r17-stroke)"
                  strokeLinecap="round"
                />
                <g id="e9z31OQKE0r18_to" transform="translate(917.8,140.146297)">
                  <g id="e9z31OQKE0r18_tr" transform="rotate(0)">
                    <rect
                      id="e9z31OQKE0r18"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#e9z31OQKE0r18-filter)"
                      fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="e9z31OQKE0r19_to" transform="translate(917.8,140.146297)">
                  <g id="e9z31OQKE0r19_tr" transform="rotate(0)">
                    <rect
                      id="e9z31OQKE0r19"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 7 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(-27 0)" mask="url(#e9z31OQKE0r31)">
              <g>
                <path d="M781.258,138.241v-16.559L628.386,76.7624v-76.41865" fill="none" stroke="#6069ca" />
                <g transform="translate(-65 105.000001)" filter="url(#e9z31OQKE0r23-filter)">
                  <path
                    id="e9z31OQKE0r24"
                    d="M781.258,158.241v-7.559l.242-1.040176-.242-.654506"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#e9z31OQKE0r24-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="e9z31OQKE0r25_to" transform="translate(845,51.646297)">
                    <g id="e9z31OQKE0r25_tr" transform="rotate(0)">
                      <rect
                        id="e9z31OQKE0r25"
                        width="7.129631"
                        height="12.222224"
                        rx="0"
                        ry="0"
                        transform="scale(0.981818,0.981818) translate(-3.564816,-6.111112)"
                        opacity="0"
                        filter="url(#e9z31OQKE0r25-filter)"
                        fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="e9z31OQKE0r26_to" transform="translate(845,51.646297)">
                    <g id="e9z31OQKE0r26_tr" transform="rotate(0)">
                      <rect
                        id="e9z31OQKE0r26"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#e9z31OQKE0r27-filter)">
                  <path
                    id="e9z31OQKE0r28"
                    d="M781.258,158.241v-7.559l.242-1.040176-.242-.654506"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#e9z31OQKE0r28-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="e9z31OQKE0r29_to" transform="translate(846,51.146297)">
                    <g id="e9z31OQKE0r29_tr" transform="rotate(0)">
                      <rect
                        id="e9z31OQKE0r29"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0"
                        filter="url(#e9z31OQKE0r29-filter)"
                        fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="e9z31OQKE0r30_to" transform="translate(846,51.146297)">
                    <g id="e9z31OQKE0r30_tr" transform="rotate(0)">
                      <rect
                        id="e9z31OQKE0r30"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="e9z31OQKE0r31" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 605.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(-41.999999 0.624996)">
              <path d="M721.199,232.865v-112.051L591.532,76.7311l.008-76.992999" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#e9z31OQKE0r35-filter)">
                <path
                  id="e9z31OQKE0r36"
                  d="M692.384111,111.206804v-.118885L591.532,76.7311c0,0,.008-7.785355.008-7.785355"
                  transform="translate(65-105.000001)"
                  fill="none"
                  stroke="url(#e9z31OQKE0r36-stroke)"
                  strokeLinecap="round"
                />
                <g id="e9z31OQKE0r37_to" transform="translate(656.6,-35.643085)">
                  <rect
                    id="e9z31OQKE0r37"
                    width="8.75"
                    height="15"
                    rx="0"
                    ry="0"
                    transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                    opacity="0.7"
                    filter="url(#e9z31OQKE0r37-filter)"
                    fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="e9z31OQKE0r38_to" transform="translate(656.6,-35.643085)">
                  <rect
                    id="e9z31OQKE0r38"
                    width="10"
                    height="20"
                    rx="0"
                    ry="0"
                    transform="scale(0.5,0.5) translate(-5,-10)"
                    fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#e9z31OQKE0r39-filter)">
                <path
                  id="e9z31OQKE0r40"
                  d="M721.199,252.865v-4.051l-.006-.917698c0,0,0-.755649,0-.755649"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e9z31OQKE0r40-stroke)"
                  strokeLinecap="round"
                />
                <g id="e9z31OQKE0r41_to" transform="translate(787.298427,139.146297)">
                  <g id="e9z31OQKE0r41_tr" transform="rotate(0)">
                    <rect
                      id="e9z31OQKE0r41"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#e9z31OQKE0r41-filter)"
                      fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="e9z31OQKE0r42_to" transform="translate(787.298427,139.146297)">
                  <g id="e9z31OQKE0r42_tr" transform="rotate(0)">
                    <rect
                      id="e9z31OQKE0r42"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#e9z31OQKE0r43-filter)">
                <path
                  id="e9z31OQKE0r44"
                  d="M721.199,252.865v-4.051l-.006-.917698c0,0,0-.755649,0-.755649"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e9z31OQKE0r44-stroke)"
                  strokeLinecap="round"
                />
                <g id="e9z31OQKE0r45_to" transform="translate(786.298427,138.146297)">
                  <g id="e9z31OQKE0r45_tr" transform="rotate(0)">
                    <rect
                      id="e9z31OQKE0r45"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#e9z31OQKE0r45-filter)"
                      fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="e9z31OQKE0r46_to" transform="translate(786.298427,138.146297)">
                  <g id="e9z31OQKE0r46_tr" transform="rotate(0)">
                    <rect
                      id="e9z31OQKE0r46"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g mask="url(#e9z31OQKE0r58)">
              <g transform="translate(3.000001-4)">
                <path
                  d="M590.336,232.597v-106.78L496.819,80.7272l-.001-77.20376"
                  transform="translate(0 0.805403)"
                  fill="none"
                  stroke="#6069ca"
                />
                <g transform="translate(-65 105.000001)" filter="url(#e9z31OQKE0r50-filter)">
                  <path
                    id="e9z31OQKE0r51"
                    d="M590.336,308.051545v-62.057573l.000001.267378-.242448-.267377"
                    transform="translate(65-104.194598)"
                    opacity="0"
                    fill="none"
                    stroke="url(#e9z31OQKE0r51-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="e9z31OQKE0r52_to" transform="translate(655.411985,142.946256)">
                    <g id="e9z31OQKE0r52_tr" transform="rotate(0)">
                      <rect
                        id="e9z31OQKE0r52"
                        width="8.75"
                        height="15"
                        rx="0"
                        ry="0"
                        transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                        opacity="0"
                        filter="url(#e9z31OQKE0r52-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="e9z31OQKE0r53_to" transform="translate(655.5,142.920302)">
                    <g id="e9z31OQKE0r53_tr" transform="rotate(0)">
                      <rect
                        id="e9z31OQKE0r53"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#e9z31OQKE0r54-filter)">
                  <path
                    id="e9z31OQKE0r55"
                    d="M590.336,308.051545v-62.057573l.000001.267378-.242448-.267377"
                    transform="translate(65-104.194598)"
                    opacity="0"
                    fill="none"
                    stroke="url(#e9z31OQKE0r55-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="e9z31OQKE0r56_to" transform="translate(655.411985,142.946256)">
                    <g id="e9z31OQKE0r56_tr" transform="rotate(0)">
                      <rect
                        id="e9z31OQKE0r56"
                        width="8.75"
                        height="15"
                        rx="0"
                        ry="0"
                        transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                        opacity="0"
                        filter="url(#e9z31OQKE0r56-filter)"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="e9z31OQKE0r57_to" transform="translate(655.5,142.920302)">
                    <g id="e9z31OQKE0r57_tr" transform="rotate(0)">
                      <rect
                        id="e9z31OQKE0r57"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="e9z31OQKE0r58" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 474.761028 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g mask="url(#e9z31OQKE0r71)">
              <g transform="translate(-2 0)">
                <path d="M292.146,229.76v-108.078L398.374,76.7761v-76.43235" fill="none" stroke="#6069ca" />
                <g transform="translate(-65 105.000001)" filter="url(#e9z31OQKE0r63-filter)">
                  <path
                    id="e9z31OQKE0r64"
                    d="M292.146,232.836923l-.023847-54.999639v-1.978947l.835306-1.871884"
                    transform="translate(65-105.000001)"
                    fill="none"
                    stroke="url(#e9z31OQKE0r64-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="e9z31OQKE0r65_to" transform="translate(357.017439,69.291269)">
                    <g id="e9z31OQKE0r65_tr" transform="rotate(0)">
                      <rect
                        id="e9z31OQKE0r65"
                        width="7"
                        height="11.998172"
                        rx="0"
                        ry="0"
                        transform="skewX(1) skewY(0) translate(-3.5,-5.999086)"
                        opacity="0.7"
                        filter="url(#e9z31OQKE0r65-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="e9z31OQKE0r66_to" transform="translate(357,69.292183)">
                    <g id="e9z31OQKE0r66_tr" transform="rotate(0)">
                      <rect
                        id="e9z31OQKE0r66"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#e9z31OQKE0r67-filter)">
                  <path
                    id="e9z31OQKE0r68"
                    d="M292.146,292.836923v-55.235274l.108926,1.213711v.381492"
                    transform="translate(65-105.000001)"
                    fill="none"
                    stroke="url(#e9z31OQKE0r68-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="e9z31OQKE0r69_to" transform="translate(357.017439,139.291269)">
                    <g id="e9z31OQKE0r69_tr" transform="rotate(0)">
                      <rect
                        id="e9z31OQKE0r69"
                        width="7"
                        height="11.998172"
                        rx="0"
                        ry="0"
                        transform="skewX(1) skewY(0) translate(-3.5,-5.999086)"
                        opacity="0.7"
                        filter="url(#e9z31OQKE0r69-filter)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="e9z31OQKE0r70_to" transform="translate(357,139.292183)">
                    <g id="e9z31OQKE0r70_tr" transform="rotate(0)">
                      <rect
                        id="e9z31OQKE0r70"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="e9z31OQKE0r71" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 255.929661 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(50 0)">
              <path d="M161.867,229.879v-108.202L296.68,76.7222v-76.413669" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#e9z31OQKE0r75-filter)">
                <path
                  id="e9z31OQKE0r76"
                  d="M161.867,259.879c0,0,0-24.389002,0-24.389002s-.017-.218699-.017-.218699l.948427-.875005"
                  transform="translate(64.996501-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e9z31OQKE0r76-stroke)"
                  strokeLinecap="round"
                />
                <g id="e9z31OQKE0r77_to" transform="translate(227.810927,140.246297)">
                  <g id="e9z31OQKE0r77_tr" transform="rotate(0)">
                    <rect
                      id="e9z31OQKE0r77"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#e9z31OQKE0r77-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="e9z31OQKE0r78_to" transform="translate(227.810927,140.246297)">
                  <g id="e9z31OQKE0r78_tr" transform="rotate(0)">
                    <rect
                      id="e9z31OQKE0r78"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#e9z31OQKE0r79-filter)">
                <path
                  id="e9z31OQKE0r80"
                  d="M161.867,259.879v-6.389002l-.017-2.218699-.051573-1.875005"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e9z31OQKE0r80-stroke)"
                  strokeLinecap="round"
                />
                <g id="e9z31OQKE0r81_to" transform="translate(226.8,139.146297)">
                  <g id="e9z31OQKE0r81_tr" transform="rotate(0)">
                    <rect
                      id="e9z31OQKE0r81"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#e9z31OQKE0r81-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="e9z31OQKE0r82_to" transform="translate(226.8,139.146297)">
                  <g id="e9z31OQKE0r82_tr" transform="rotate(0)">
                    <rect
                      id="e9z31OQKE0r82"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(36 0)" mask="url(#e9z31OQKE0r98)">
              <g transform="translate(.000001 0)">
                <path d="M96.7109,138.118v-16.438L259.817,76.7298v-76.38605" fill="none" stroke="#6069ca" />
                <g transform="translate(-64 105.000001)" filter="url(#e9z31OQKE0r86-filter)">
                  <path
                    id="e9z31OQKE0r87"
                    d="M96.711985,145.439636L96.7109,127.987317h.001085c0,0,0,0,0,0"
                    transform="translate(64-105.000001)"
                    fill="none"
                    stroke="url(#e9z31OQKE0r87-stroke)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="e9z31OQKE0r88_to" transform="translate(161,25.504306)">
                    <g id="e9z31OQKE0r88_tr" transform="rotate(0)">
                      <rect
                        id="e9z31OQKE0r88"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0.7"
                        filter="url(#e9z31OQKE0r88-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="e9z31OQKE0r89_to" transform="translate(161,25.504306)">
                    <g id="e9z31OQKE0r89_tr" transform="rotate(0)">
                      <rect
                        id="e9z31OQKE0r89"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#e9z31OQKE0r90-filter)">
                  <path
                    id="e9z31OQKE0r91"
                    d="M96.7109,143.118v-.064999l.1973-.999999c0,0-.109773,1.999999-.109773,1.999999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#e9z31OQKE0r91-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="e9z31OQKE0r92_to" transform="translate(161.8,50.787316)">
                    <g id="e9z31OQKE0r92_tr" transform="rotate(0)">
                      <rect
                        id="e9z31OQKE0r92"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0"
                        filter="url(#e9z31OQKE0r92-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="e9z31OQKE0r93_to" transform="translate(161.8,50.787316)">
                    <g id="e9z31OQKE0r93_tr" transform="rotate(0)">
                      <rect
                        id="e9z31OQKE0r93"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#e9z31OQKE0r94-filter)">
                  <path
                    id="e9z31OQKE0r95"
                    d="M96.7109,143.118v-.064999l.1973-.999999c0,0-.109773,1.999999-.109773,1.999999"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#e9z31OQKE0r95-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="e9z31OQKE0r96_to" transform="translate(161.5,49.521297)">
                    <rect
                      id="e9z31OQKE0r96"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#e9z31OQKE0r96-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="e9z31OQKE0r97_to" transform="translate(161.5,49.521297)">
                    <rect
                      id="e9z31OQKE0r97"
                      width="5"
                      height="10"
                      rx="0"
                      ry="0"
                      transform="translate(-2.5,-5)"
                      opacity="0"
                      filter="url(#e9z31OQKE0r97-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <mask id="e9z31OQKE0r98" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="200"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 75.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g transform="translate(20 0)">
              <path d="M32.0957,229.88v-108.202L222.955,76.73v-76.413594" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#e9z31OQKE0r102-filter)">
                <path
                  id="e9z31OQKE0r103"
                  d="M32.0957,249.88v-9.358702v0v.003136"
                  transform="translate(64.996286-105.187118)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e9z31OQKE0r103-stroke)"
                  strokeLinecap="round"
                />
                <g id="e9z31OQKE0r104_to" transform="translate(96.798427,143.537316)">
                  <g id="e9z31OQKE0r104_tr" transform="rotate(0)">
                    <rect
                      id="e9z31OQKE0r104"
                      width="10"
                      height="17.142857"
                      rx="0"
                      ry="0"
                      transform="scale(0.7,0.7) translate(-5,-8.571429)"
                      opacity="0"
                      filter="url(#e9z31OQKE0r104-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="e9z31OQKE0r105_to" transform="translate(96.798427,142.237316)">
                  <g id="e9z31OQKE0r105_tr" transform="rotate(0)">
                    <rect
                      id="e9z31OQKE0r105"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      filter="url(#e9z31OQKE0r105-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      strokeMiterlimit="8"
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#e9z31OQKE0r106-filter)">
                <path
                  id="e9z31OQKE0r107"
                  d="M32.0957,249.88v-10.732683c0,0-.003714,0-.003714,0v-.001023"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#e9z31OQKE0r107-stroke)"
                  strokeLinecap="round"
                />
                <g id="e9z31OQKE0r108_to" transform="translate(97.998427,140.121297)">
                  <g id="e9z31OQKE0r108_tr" transform="rotate(0)">
                    <rect
                      id="e9z31OQKE0r108"
                      width="10"
                      height="17.142857"
                      rx="0"
                      ry="0"
                      transform="scale(0.7,0.7) translate(-5,-8.571428)"
                      opacity="0"
                      filter="url(#e9z31OQKE0r108-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="e9z31OQKE0r109_to" transform="translate(97.998427,140.121297)">
                  <g id="e9z31OQKE0r109_tr" transform="rotate(0)">
                    <rect
                      id="e9z31OQKE0r109"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <mask id="e9z31OQKE0r110" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="825.762291"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #e9z31OQKE0r9 {
    animation-name: e9z31OQKE0r9__m, e9z31OQKE0r9_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9z31OQKE0r9__m {
    0% {
      d: path('M853.309,172.852398L853.309,121.49963L764.741674,100.215254L764.220825,100.215253');
    }
    7.5% {
      d: path('M853.309,121.605025L853.309,121.49963L665.296,76.728L665.296,76.180546');
    }
    8.75% {
      d: path('M829.807375,115.995397L829.807375,115.903177L665.296,76.728L665.296,67.046162');
    }
    10% {
      d: path('M812.181157,111.788176L812.181157,111.705837L665.296,76.728L665.296,69.013483');
    }
    11.25% {
      d: path('M794.554938,107.580954L794.554938,107.508497L665.296,76.728L665.296,51.385005');
    }
    13.75% {
      d: path('M759.302501,99.166512L759.3025,99.113816L665.296,76.728L665.296,16.128048');
    }
    20% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
    100% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
  }
  @keyframes e9z31OQKE0r9_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    17.5% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r10_to {
    animation: e9z31OQKE0r10_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r10_to__to {
    0% {
      transform: translate(828.317602px, -5.431736px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(736.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(730.920607px, -32.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.219355px, -122.688381px);
    }
    100% {
      transform: translate(731.219355px, -122.688381px);
    }
  }
  #e9z31OQKE0r10_tr {
    animation: e9z31OQKE0r10_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r10_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    7.5% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r10 {
    animation: e9z31OQKE0r10_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r10_c_o {
    0% {
      opacity: 0.7;
    }
    20% {
      opacity: 0.7;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r11_to {
    animation: e9z31OQKE0r11_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r11_to__to {
    0% {
      transform: translate(828.317602px, -5.431736px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(736.801035px, -27.63967px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(730.851035px, -32.48073px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.219354px, -122.688381px);
    }
    100% {
      transform: translate(731.219354px, -122.688381px);
    }
  }
  #e9z31OQKE0r11_tr {
    animation: e9z31OQKE0r11_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r11_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    7.5% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r11 {
    animation: e9z31OQKE0r11_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r11_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r13 {
    animation-name: e9z31OQKE0r13__m, e9z31OQKE0r13_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9z31OQKE0r13__m {
    0% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    30% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    37.5% {
      d: path('M853.309,237.53995L853.309,151.87312L853.306432,148.3585L853.309001,144.380103');
    }
    40% {
      d: path('M853.309,232.641L853.309,121.49963L853.309,121.605025L852.281356,122.594792');
    }
    56.25% {
      d: path('M853.309,121.605025L853.309,121.49963L665.296,76.728L665.296,76.180546');
    }
    62.5% {
      d: path('M759.302501,99.166512L759.3025,99.113816L665.296,76.728L665.296,11.229098');
    }
    68.75% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
    100% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
  }
  @keyframes e9z31OQKE0r13_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    66.25% {
      opacity: 1;
    }
    73.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r14_to {
    animation: e9z31OQKE0r14_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r14_to__to {
    0% {
      transform: translate(917.8px, 138.546297px);
    }
    25% {
      transform: translate(917.8px, 138.546297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 23.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(913.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(905.3352px, 13.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(736.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(730.920607px, -32.510292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -38.49755px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(730.9px, -120.728703px);
    }
    100% {
      transform: translate(730.9px, -120.728703px);
    }
  }
  #e9z31OQKE0r14_tr {
    animation: e9z31OQKE0r14_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r14_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-75deg);
    }
    55% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r14 {
    animation: e9z31OQKE0r14_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r14_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 0.7;
    }
    68.75% {
      opacity: 0.7;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r15_to {
    animation: e9z31OQKE0r15_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r15_to__to {
    0% {
      transform: translate(917.8px, 138.546297px);
    }
    25% {
      transform: translate(917.8px, 138.546297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 23.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(913.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(905.335199px, 13.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(736.801035px, -27.43967px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(730.920607px, -32.510292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -38.49755px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(730.9px, -120.228703px);
    }
    100% {
      transform: translate(730.9px, -120.228703px);
    }
  }
  #e9z31OQKE0r15_tr {
    animation: e9z31OQKE0r15_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-75deg);
    }
    55% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r15 {
    animation: e9z31OQKE0r15_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r15_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    68.75% {
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r17 {
    animation-name: e9z31OQKE0r17__m, e9z31OQKE0r17_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9z31OQKE0r17__m {
    0% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    81.25% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    91.25% {
      d: path('M853.309,232.641L853.309,121.49963L853.309,121.605025L853.309,120.707886');
    }
    100% {
      d: path('M853.309,172.852398L853.309,121.49963L762.497203,100.468958L762.736434,100.468959');
    }
  }
  @keyframes e9z31OQKE0r17_c_o {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    76.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #e9z31OQKE0r18_to {
    animation: e9z31OQKE0r18_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r18_to__to {
    0% {
      transform: translate(917.8px, 140.146297px);
    }
    75% {
      transform: translate(917.8px, 140.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(913.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(905.3352px, 13.841812px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(828.32px, -5.43px);
    }
  }
  #e9z31OQKE0r18_tr {
    animation: e9z31OQKE0r18_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r18_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #e9z31OQKE0r18 {
    animation: e9z31OQKE0r18_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r18_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #e9z31OQKE0r19_to {
    animation: e9z31OQKE0r19_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r19_to__to {
    0% {
      transform: translate(917.8px, 140.146297px);
    }
    75% {
      transform: translate(917.8px, 140.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(913.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(905.3352px, 13.841812px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(828.32px, -5.43px);
    }
  }
  #e9z31OQKE0r19_tr {
    animation: e9z31OQKE0r19_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r19_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #e9z31OQKE0r19 {
    animation: e9z31OQKE0r19_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r19_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #e9z31OQKE0r24 {
    animation-name: e9z31OQKE0r24__m, e9z31OQKE0r24_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9z31OQKE0r24__m {
    0% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    12.5% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    17.5% {
      d: path('M781.258,146.812429L781.258,144.110571L781.527118,145.664782L780.755126,143.963766');
    }
    21.25% {
      d: path('M781.258,138.241L781.258,121.682L779.797456,121.682L778.627971,122.696102');
    }
    36.25% {
      d: path('M781.258,121.622403L781.258,121.682L628.386,76.7624L628.386,77.3561');
    }
    41.25% {
      d: path('M720.1092,103.542346L720.1092,103.578104L628.386,76.7624L628.386,20.55116');
    }
    43.75% {
      d: path('M683.41992,92.694312L683.41992,92.715767L628.386,76.7624L628.386,-5.531804');
    }
    47.5% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
    100% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
  }
  @keyframes e9z31OQKE0r24_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    45% {
      opacity: 1;
    }
    53.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r25_to {
    animation: e9z31OQKE0r25_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r25_to__to {
    0% {
      transform: translate(845px, 51.646297px);
    }
    12.5% {
      transform: translate(845px, 51.646297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    20% {
      transform: translate(846.5px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(842.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    22.5% {
      transform: translate(831.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(699.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(693.5px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(693.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    47.5% {
      transform: translate(693.9px, -120.728703px);
    }
    100% {
      transform: translate(693.9px, -120.728703px);
    }
  }
  #e9z31OQKE0r25_tr {
    animation: e9z31OQKE0r25_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r25_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-73deg);
    }
    35% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r25 {
    animation: e9z31OQKE0r25_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r25_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.7;
    }
    47.5% {
      opacity: 0.7;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r26_to {
    animation: e9z31OQKE0r26_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r26_to__to {
    0% {
      transform: translate(845px, 51.646297px);
    }
    12.5% {
      transform: translate(845px, 51.646297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    20% {
      transform: translate(846.5px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(842.3px, 15.5px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    22.5% {
      transform: translate(831.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    35% {
      transform: translate(699.801035px, -26.390926px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    36.25% {
      transform: translate(693.411987px, -31.390926px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    37.5% {
      transform: translate(693.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    47.5% {
      transform: translate(693.9px, -122.228703px);
    }
    100% {
      transform: translate(693.9px, -122.228703px);
    }
  }
  #e9z31OQKE0r26_tr {
    animation: e9z31OQKE0r26_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r26_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-73deg);
    }
    35% {
      transform: rotate(-73deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r26 {
    animation: e9z31OQKE0r26_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r26_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    47.5% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r28 {
    animation-name: e9z31OQKE0r28__m, e9z31OQKE0r28_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9z31OQKE0r28__m {
    0% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    51.25% {
      d: path('M781.258,158.241L781.258,150.682L781.5,149.641824L781.258,148.987318');
    }
    56.25% {
      d: path('M781.258,146.812429L781.258,144.110571L781.527118,145.664782L780.755126,143.963766');
    }
    60% {
      d: path('M781.258,138.241L781.258,121.682L779.797456,121.682L778.627971,122.696102');
    }
    73.75% {
      d: path('M781.258,121.622403L781.258,121.682L628.386,76.7624L628.386,77.3561');
    }
    78.75% {
      d: path('M720.1092,103.542346L720.1092,103.578104L628.386,76.7624L628.386,20.55116');
    }
    86.25% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
    100% {
      d: path('M628.386,76.422261L628.386,76.422261L628.386,76.7624L628.386,-19.65625');
    }
  }
  @keyframes e9z31OQKE0r28_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    83.75% {
      opacity: 1;
    }
    92.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r29_to {
    animation: e9z31OQKE0r29_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r29_to__to {
    0% {
      transform: translate(846px, 51.146297px);
    }
    51.25% {
      transform: translate(846px, 51.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    58.75% {
      transform: translate(846px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    60% {
      transform: translate(842.575913px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    61.25% {
      transform: translate(831.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    72.5% {
      transform: translate(699.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    73.75% {
      transform: translate(693.5px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    75% {
      transform: translate(693.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    86.25% {
      transform: translate(693.9px, -119.728703px);
    }
    100% {
      transform: translate(693.9px, -119.728703px);
    }
  }
  #e9z31OQKE0r29_tr {
    animation: e9z31OQKE0r29_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r29_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-73deg);
    }
    72.5% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r29 {
    animation: e9z31OQKE0r29_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r29_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 0.7;
    }
    86.25% {
      opacity: 0.7;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r30_to {
    animation: e9z31OQKE0r30_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r30_to__to {
    0% {
      transform: translate(846px, 51.146297px);
    }
    51.25% {
      transform: translate(846px, 51.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    58.75% {
      transform: translate(846px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    60% {
      transform: translate(842.575913px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    61.25% {
      transform: translate(831.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    72.5% {
      transform: translate(698.801035px, -26.8px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    73.75% {
      transform: translate(693.411987px, -31.290926px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    75% {
      transform: translate(693.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    86.25% {
      transform: translate(693.9px, -121.228703px);
    }
    100% {
      transform: translate(693.9px, -121.228703px);
    }
  }
  #e9z31OQKE0r30_tr {
    animation: e9z31OQKE0r30_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r30_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-73deg);
    }
    72.5% {
      transform: rotate(-73deg);
    }
    73.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r30 {
    animation: e9z31OQKE0r30_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r30_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    86.25% {
      opacity: 1;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r36 {
    animation-name: e9z31OQKE0r36__m, e9z31OQKE0r36_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9z31OQKE0r36__m {
    0% {
      d: path(
        'M692.384111,111.206804L692.384111,111.087919L591.532,76.7311C591.532,76.7311,591.54,68.945745,591.54,68.945745'
      );
    }
    3.75% {
      d: path(
        'M649.161778,96.431503L649.161778,96.498797L591.532,76.7311C591.532,76.7311,591.54,19.285326,591.54,19.285326'
      );
    }
    8.75% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-20.261899,591.54,-20.261899'
      );
    }
    100% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-20.261899,591.54,-20.261899'
      );
    }
  }
  @keyframes e9z31OQKE0r36_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    3.75% {
      opacity: 1;
    }
    10% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r37_to {
    animation: e9z31OQKE0r37_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r37_to__to {
    0% {
      transform: translate(656.6px, -35.643085px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.6px, -119.728703px);
    }
    100% {
      transform: translate(656.6px, -119.728703px);
    }
  }
  #e9z31OQKE0r37 {
    animation: e9z31OQKE0r37_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r37_c_o {
    0% {
      opacity: 0.7;
    }
    10% {
      opacity: 0.7;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r38_to {
    animation: e9z31OQKE0r38_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r38_to__to {
    0% {
      transform: translate(656.6px, -35.643085px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.6px, -119.728703px);
    }
    100% {
      transform: translate(656.6px, -119.728703px);
    }
  }
  #e9z31OQKE0r38 {
    animation: e9z31OQKE0r38_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r38_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    10% {
      opacity: 1;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r40 {
    animation-name: e9z31OQKE0r40__m, e9z31OQKE0r40_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9z31OQKE0r40__m {
    0% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    22.5% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    30% {
      d: path(
        'M721.199,242.865L721.199,212.314L721.03054,210.946856C721.03054,210.946856,721.013534,209.569032,721.013534,209.569032'
      );
    }
    36.25% {
      d: path(
        'M721.199,234.531667L721.199,136.064L721.192993,131.635822C721.192993,131.635822,721.192993,128.271298,721.192993,128.271298'
      );
    }
    37.5% {
      d: path(
        'M721.199,232.865L721.199,120.814L721.192993,120.949437C721.192993,120.949437,721.192993,120.410429,721.192993,120.410429'
      );
    }
    51.25% {
      d: path('M721.199,121.057004L721.199,120.814L591.532,76.7311C591.532,76.7311,591.54,75.862215,591.54,75.862215');
    }
    60% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-0.261899,591.54,-0.261899'
      );
    }
    100% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-0.261899,591.54,-0.261899'
      );
    }
  }
  @keyframes e9z31OQKE0r40_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    67.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r41_to {
    animation: e9z31OQKE0r41_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r41_to__to {
    0% {
      transform: translate(787.298427px, 139.146297px);
    }
    22.5% {
      transform: translate(787.298427px, 139.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(784.46587px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(774.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    50% {
      transform: translate(660.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(656.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #e9z31OQKE0r41_tr {
    animation: e9z31OQKE0r41_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r41_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(-70deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r41 {
    animation: e9z31OQKE0r41_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r41_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 0.7;
    }
    62.5% {
      opacity: 0.7;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r42_to {
    animation: e9z31OQKE0r42_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r42_to__to {
    0% {
      transform: translate(787.298427px, 139.146297px);
    }
    22.5% {
      transform: translate(787.298427px, 139.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(784.46587px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(773.8px, 10.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    50% {
      transform: translate(659.801035px, -27.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(656.851035px, -32.387789px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -121.228703px);
    }
    100% {
      transform: translate(656.9px, -121.228703px);
    }
  }
  #e9z31OQKE0r42_tr {
    animation: e9z31OQKE0r42_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r42_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(-70deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r42 {
    animation: e9z31OQKE0r42_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r42_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    62.5% {
      opacity: 1;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r44 {
    animation-name: e9z31OQKE0r44__m, e9z31OQKE0r44_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9z31OQKE0r44__m {
    0% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    68.75% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    76.25% {
      d: path(
        'M721.199,242.865L721.199,212.314L721.03054,210.946856C721.03054,210.946856,721.013534,209.569032,721.013534,209.569032'
      );
    }
    83.75% {
      d: path(
        'M721.199,232.865L721.199,120.621176L721.199,121.749219C721.199,121.749219,721.199,120.72838,721.199,120.72838'
      );
    }
    90% {
      d: path(
        'M721.199,182.043184L721.199,120.708823L663.915023,101.261343C663.915023,101.261343,662.337174,100.83567,662.337174,100.83567'
      );
    }
    96.25% {
      d: path(
        'M721.199,131.221367L721.199,120.796471L603.595837,80.819474C603.595837,80.819474,595.339529,78.024458,595.339529,78.024458'
      );
    }
    97.5% {
      d: path('M721.199,121.057004L721.199,120.814L591.532,76.7311C591.532,76.7311,591.54,75.862215,591.54,75.862215');
    }
    100% {
      d: path(
        'M692.384111,111.206804L692.384111,111.087919L591.532,76.7311C591.532,76.7311,591.54,68.945745,591.54,68.945745'
      );
    }
  }
  @keyframes e9z31OQKE0r44_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #e9z31OQKE0r45_to {
    animation: e9z31OQKE0r45_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r45_to__to {
    0% {
      transform: translate(786.298427px, 138.146297px);
    }
    68.75% {
      transform: translate(786.298427px, 138.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.3px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(784.29007px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(774.3352px, 11.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(661.801035px, -26.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(656.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -40.096471px);
    }
  }
  #e9z31OQKE0r45_tr {
    animation: e9z31OQKE0r45_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r45_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-70deg);
    }
    96.25% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r45 {
    animation: e9z31OQKE0r45_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r45_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #e9z31OQKE0r46_to {
    animation: e9z31OQKE0r46_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r46_to__to {
    0% {
      transform: translate(786.298427px, 138.146297px);
    }
    68.75% {
      transform: translate(786.298427px, 138.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.3px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(784.29007px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(773.8px, 11.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(660.801035px, -26.846729px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(656.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -40.096471px);
    }
  }
  #e9z31OQKE0r46_tr {
    animation: e9z31OQKE0r46_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r46_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-70deg);
    }
    96.25% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r46 {
    animation: e9z31OQKE0r46_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r46_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #e9z31OQKE0r51 {
    animation-name: e9z31OQKE0r51__m, e9z31OQKE0r51_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9z31OQKE0r51__m {
    0% {
      d: path('M590.336,308.051545L590.336,245.993972L590.336001,246.26135L590.093553,245.993973');
    }
    6.25% {
      d: path('M590.336,308.051545L590.336,245.993972L590.336001,246.26135L590.093553,245.993973');
    }
    13.75% {
      d: path('M590.336,238.051545L590.336,175.993972L590.336001,176.26135L590.093553,175.993973');
    }
    21.25% {
      d: path('M590.336,232.597L590.336,125.817L590.336001,127.215895L588.964636,125.817002');
    }
    22.5% {
      d: path('M590.336,221.919L590.336,125.817L584.467492,123.295399L583.233364,122.006272');
    }
    23.75% {
      d: path('M590.336,211.241L590.336,125.817L576.598983,119.374903L575.502091,118.195541');
    }
    25% {
      d: path('M590.336,200.563L590.336,125.817L568.230474,115.454407L567.270818,114.384811');
    }
    28.75% {
      d: path('M590.336,168.528999L590.336,125.817L544.124946,103.692919L543.577,102.95262');
    }
    33.75% {
      d: path('M590.336,125.816999L590.336,125.817L496.818001,80.550693L496.819001,80.249455');
    }
    47.5% {
      d: path('M496.921985,80.550696L496.921985,80.550696L496.818001,80.550693L496.921985,-5.534104');
    }
    100% {
      d: path('M496.921985,80.550696L496.921985,80.550696L496.818001,80.550693L496.921985,-5.534104');
    }
  }
  @keyframes e9z31OQKE0r51_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    13.75% {
      opacity: 1;
    }
    45% {
      opacity: 1;
    }
    53.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r52_to {
    animation: e9z31OQKE0r52_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r52_to__to {
    0% {
      transform: translate(655.411985px, 142.946256px);
    }
    6.25% {
      transform: translate(655.411985px, 142.946256px);
    }
    13.75% {
      transform: translate(655.411985px, 71.946256px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    20% {
      transform: translate(655.798427px, 26.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(652.798427px, 19.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    33.75% {
      transform: translate(565.801035px, -21.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(561.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    47.5% {
      transform: translate(561.921985px, -115.728703px);
    }
    100% {
      transform: translate(561.921985px, -115.728703px);
    }
  }
  #e9z31OQKE0r52_tr {
    animation: e9z31OQKE0r52_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r52_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-65deg);
    }
    33.75% {
      transform: rotate(-65deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r52 {
    animation: e9z31OQKE0r52_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r52_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 0.7;
    }
    13.75% {
      opacity: 0.7;
    }
    47.5% {
      opacity: 0.7;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r53_to {
    animation: e9z31OQKE0r53_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r53_to__to {
    0% {
      transform: translate(655.5px, 142.920302px);
    }
    6.25% {
      transform: translate(655.5px, 142.920302px);
    }
    13.75% {
      transform: translate(655.5px, 71.920302px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    20% {
      transform: translate(655.798427px, 26.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    21.25% {
      transform: translate(652.726569px, 19.844367px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    33.75% {
      transform: translate(565.801035px, -21.855633px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(561.851035px, -30.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    47.5% {
      transform: translate(561.851985px, -115.228703px);
    }
    100% {
      transform: translate(561.851985px, -115.228703px);
    }
  }
  #e9z31OQKE0r53_tr {
    animation: e9z31OQKE0r53_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r53_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(-65deg);
    }
    33.75% {
      transform: rotate(-65deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r53 {
    animation: e9z31OQKE0r53_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r53_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    13.75% {
      opacity: 1;
    }
    47.5% {
      opacity: 1;
    }
    48.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r55 {
    animation-name: e9z31OQKE0r55__m, e9z31OQKE0r55_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9z31OQKE0r55__m {
    0% {
      d: path('M590.336,308.051545L590.336,245.993972L590.336001,246.26135L590.093553,245.993973');
    }
    52.5% {
      d: path('M590.336,308.051545L590.336,245.993972L590.336001,246.26135L590.093553,245.993973');
    }
    60% {
      d: path('M590.336,238.051545L590.336,175.993972L590.336001,176.26135L590.093553,175.993973');
    }
    67.5% {
      d: path('M590.336,232.597L590.336,125.817L590.336001,127.215895L588.964636,125.817002');
    }
    71.25% {
      d: path('M590.336,200.563L590.336,125.817L571.721877,116.756832L568.129635,114.992074');
    }
    80% {
      d: path('M590.336,125.816999L590.336,125.817L496.818001,80.550693L496.819001,80.249455');
    }
    93.75% {
      d: path('M496.921985,80.550696L496.921985,80.550696L496.818001,80.550693L496.921985,-5.534104');
    }
    100% {
      d: path('M496.921985,80.550696L496.921985,80.550696L496.818001,80.550693L496.921985,-5.534104');
    }
  }
  @keyframes e9z31OQKE0r55_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r56_to {
    animation: e9z31OQKE0r56_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r56_to__to {
    0% {
      transform: translate(655.411985px, 142.946256px);
    }
    52.5% {
      transform: translate(655.411985px, 142.946256px);
    }
    60% {
      transform: translate(655.411985px, 71.946256px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    66.25% {
      transform: translate(655.798427px, 26.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    67.5% {
      transform: translate(652.798427px, 19.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    80% {
      transform: translate(565.801035px, -21.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    81.25% {
      transform: translate(561.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    93.75% {
      transform: translate(561.921985px, -115.728703px);
    }
    100% {
      transform: translate(561.921985px, -115.728703px);
    }
  }
  #e9z31OQKE0r56_tr {
    animation: e9z31OQKE0r56_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r56_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(-65deg);
    }
    80% {
      transform: rotate(-65deg);
    }
    81.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r56 {
    animation: e9z31OQKE0r56_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r56_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 0.7;
    }
    60% {
      opacity: 0.7;
    }
    93.75% {
      opacity: 0.7;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r57_to {
    animation: e9z31OQKE0r57_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r57_to__to {
    0% {
      transform: translate(655.5px, 142.920302px);
    }
    52.5% {
      transform: translate(655.5px, 142.920302px);
    }
    60% {
      transform: translate(655.5px, 71.920302px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    66.25% {
      transform: translate(655.798427px, 26.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    67.5% {
      transform: translate(652.726569px, 19.844367px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    80% {
      transform: translate(565.801035px, -21.855633px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    81.25% {
      transform: translate(561.851035px, -30.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    93.75% {
      transform: translate(561.851985px, -115.228703px);
    }
    100% {
      transform: translate(561.851985px, -115.228703px);
    }
  }
  #e9z31OQKE0r57_tr {
    animation: e9z31OQKE0r57_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r57_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(-65deg);
    }
    80% {
      transform: rotate(-65deg);
    }
    81.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r57 {
    animation: e9z31OQKE0r57_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r57_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    93.75% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r64 {
    animation-name: e9z31OQKE0r64__m, e9z31OQKE0r64_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9z31OQKE0r64__m {
    0% {
      d: path('M292.146,232.836923L292.122153,177.837284L292.122153,175.858337L292.957459,173.986453');
    }
    3.75% {
      d: path('M292.146,230.529231L292.140038,125.720821L292.140041,125.931388L292.348866,125.463417');
    }
    5% {
      d: path('M292.146,229.76L292.122153,121.682001L292.122153,121.677001L295.622154,120.236546');
    }
    6.25% {
      d: path('M292.146,219.953228L292.146,121.682L301.055305,117.873235L304.303933,116.488279');
    }
    18.75% {
      d: path('M292.146,121.885507L292.146,121.682L398.374,76.7761L398.395286,76.7761');
    }
    31.25% {
      d: path('M398.374,76.925708L398.374,76.722201L398.374,76.7761L398.395286,-13.2239');
    }
    100% {
      d: path('M398.374,76.925708L398.374,76.722201L398.374,76.7761L398.395286,-13.2239');
    }
  }
  @keyframes e9z31OQKE0r64_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    28.75% {
      opacity: 1;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r65_to {
    animation: e9z31OQKE0r65_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r65_to__to {
    0% {
      transform: translate(357.017439px, 69.291269px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    3.75% {
      transform: translate(357.0167px, 22.246256px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    5% {
      transform: translate(362.798427px, 14.335786px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    6.25% {
      transform: translate(369.3352px, 11.841812px);
      animation-timing-function: cubic-bezier(0.360339, 0.278241, 0.678403, 0.627493);
    }
    12.5% {
      transform: translate(413.87208px, -7.577736px);
      animation-timing-function: cubic-bezier(0.320818, 0.325965, 0.64445, 0.66817);
    }
    17.5% {
      transform: translate(458.5px, -26px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    18.75% {
      transform: translate(463.5px, -32.8px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    31.25% {
      transform: translate(463.5px, -121.728703px);
    }
    100% {
      transform: translate(463.5px, -121.728703px);
    }
  }
  #e9z31OQKE0r65_tr {
    animation: e9z31OQKE0r65_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r65_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    3.75% {
      transform: rotate(0deg);
    }
    5% {
      transform: rotate(68deg);
    }
    6.25% {
      transform: rotate(68deg);
    }
    17.5% {
      transform: rotate(68deg);
    }
    18.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r65 {
    animation: e9z31OQKE0r65_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r65_c_o {
    0% {
      opacity: 0.7;
    }
    31.25% {
      opacity: 0.7;
    }
    32.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r66_to {
    animation: e9z31OQKE0r66_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r66_to__to {
    0% {
      transform: translate(357px, 69.292183px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    3.75% {
      transform: translate(357.0167px, 22.246256px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    5% {
      transform: translate(362.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.357233, 0.257258, 0.688329, 0.595794);
    }
    12.5% {
      transform: translate(413.87208px, -7.577736px);
      animation-timing-function: cubic-bezier(0.348103, 0.299265, 0.680051, 0.634734);
    }
    17.5% {
      transform: translate(458.5px, -26px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    18.75% {
      transform: translate(463.5px, -32.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    31.25% {
      transform: translate(463.5px, -121.228703px);
    }
    100% {
      transform: translate(463.5px, -121.228703px);
    }
  }
  #e9z31OQKE0r66_tr {
    animation: e9z31OQKE0r66_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r66_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    3.75% {
      transform: rotate(0deg);
    }
    5% {
      transform: rotate(68deg);
    }
    17.5% {
      transform: rotate(68deg);
    }
    18.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r66 {
    animation: e9z31OQKE0r66_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r66_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    31.25% {
      opacity: 1;
    }
    32.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r68 {
    animation-name: e9z31OQKE0r68__m, e9z31OQKE0r68_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9z31OQKE0r68__m {
    0% {
      d: path('M292.146,292.836923L292.146,237.601649L292.254926,238.81536L292.254926,239.196852');
    }
    40% {
      d: path('M292.146,292.836923L292.146,237.601649L292.254926,238.81536L292.254926,239.196852');
    }
    50% {
      d: path('M292.146,232.836923L292.146,177.601649L292.254926,178.81536L292.254926,179.196852');
    }
    53.75% {
      d: path('M292.146,230.529231L292.146,125.566541L293.213188,124.319908L293.213188,124.319908');
    }
    55% {
      d: path('M292.146,229.76L292.146,121.682L295.310002,120.236546L295.310001,120.236544');
    }
    56.25% {
      d: path('M292.146,219.953228L292.146,121.682L301.803094,118.454559L301.805028,118.454559');
    }
    68.75% {
      d: path('M292.146,121.885507L292.146,121.682L398.374,76.7761L398.395286,76.7761');
    }
    81.25% {
      d: path('M398.374,76.925708L398.374,76.722201L398.374,76.7761L398.395286,-13.2239');
    }
    100% {
      d: path('M398.374,76.925708L398.374,76.722201L398.374,76.7761L398.395286,-13.2239');
    }
  }
  @keyframes e9z31OQKE0r68_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    76.25% {
      opacity: 1;
    }
    85% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r68-stroke {
    animation: e9z31OQKE0r68-stroke__t 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r68-stroke__t {
    0% {
      transform: matrix(0, 0.154977, -0.154977, 0, 1.372093, 0.008289);
    }
    55% {
      transform: matrix(0, 0.154977, -0.154977, 0, 1.372093, 0.008289);
    }
    61.25% {
      transform: matrix(-0.628187, 0.512779, -0.512779, -0.628187, 0.403061, -0.191267);
    }
    68.75% {
      transform: matrix(0, 1.295289, -1.295289, 0, -2.141592, -0.442478);
    }
    73.75% {
      transform: matrix(-0.465146, 0.669701, -0.669701, -0.465146, 0.644932, 0.073424);
    }
    100% {
      transform: matrix(-0.465146, 0.669701, -0.669701, -0.465146, 0.644932, 0.073424);
    }
  }
  #e9z31OQKE0r68-stroke-0 {
    animation: e9z31OQKE0r68-stroke-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r68-stroke-0__c {
    0% {
      stop-color: #7ea6ff;
    }
    55% {
      stop-color: #7ea6ff;
    }
    61.25% {
      stop-color: #7ea6ff;
    }
    68.75% {
      stop-color: #7ea6ff;
    }
    73.75% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #e9z31OQKE0r68-stroke-1 {
    animation: e9z31OQKE0r68-stroke-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r68-stroke-1__c {
    0% {
      stop-color: rgba(126, 166, 255, 0);
    }
    55% {
      stop-color: rgba(126, 166, 255, 0);
    }
    61.25% {
      stop-color: rgba(126, 166, 255, 0);
    }
    68.75% {
      stop-color: rgba(126, 166, 255, 0);
    }
    73.75% {
      stop-color: rgba(126, 166, 255, 0);
    }
    100% {
      stop-color: rgba(126, 166, 255, 0);
    }
  }
  #e9z31OQKE0r69_to {
    animation: e9z31OQKE0r69_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r69_to__to {
    0% {
      transform: translate(357.017439px, 139.291269px);
    }
    40% {
      transform: translate(357.017439px, 139.291269px);
    }
    50% {
      transform: translate(357.017439px, 69.291269px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    53.75% {
      transform: translate(357.0167px, 22.246256px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    55% {
      transform: translate(362.798427px, 14.335786px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    56.25% {
      transform: translate(369.3352px, 11.841812px);
      animation-timing-function: cubic-bezier(0.357029, 0.285792, 0.680857, 0.629398);
    }
    61.25% {
      transform: translate(400.323213px, -1.43161px);
      animation-timing-function: cubic-bezier(0.333182, 0.323477, 0.663812, 0.659254);
    }
    63.75% {
      transform: translate(421.246138px, -10.164214px);
      animation-timing-function: cubic-bezier(0.330015, 0.331533, 0.662709, 0.665439);
    }
    65% {
      transform: translate(432.746138px, -15.164214px);
      animation-timing-function: cubic-bezier(0.322679, 0.334175, 0.653773, 0.669602);
    }
    67.5% {
      transform: translate(458.5px, -26px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    68.75% {
      transform: translate(463.5px, -32.8px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(463.5px, -121.728703px);
    }
    100% {
      transform: translate(463.5px, -121.728703px);
    }
  }
  #e9z31OQKE0r69_tr {
    animation: e9z31OQKE0r69_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r69_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(0deg);
    }
    55% {
      transform: rotate(68deg);
    }
    56.25% {
      transform: rotate(68deg);
    }
    67.5% {
      transform: rotate(68deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r69 {
    animation: e9z31OQKE0r69_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r69_c_o {
    0% {
      opacity: 0.7;
    }
    40% {
      opacity: 0.7;
    }
    50% {
      opacity: 0.7;
    }
    81.25% {
      opacity: 0.7;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r70_to {
    animation: e9z31OQKE0r70_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r70_to__to {
    0% {
      transform: translate(357px, 139.292183px);
    }
    40% {
      transform: translate(357px, 139.292183px);
    }
    50% {
      transform: translate(357px, 69.292183px);
      animation-timing-function: cubic-bezier(0.321993, 0.322365, 0.642884, 0.667063);
    }
    53.75% {
      transform: translate(357.001986px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    55% {
      transform: translate(362.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(458.502724px, -26px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    68.75% {
      transform: translate(463.5px, -32.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(463.5px, -121.228703px);
    }
    100% {
      transform: translate(463.5px, -121.228703px);
    }
  }
  #e9z31OQKE0r70_tr {
    animation: e9z31OQKE0r70_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r70_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    53.75% {
      transform: rotate(0deg);
    }
    55% {
      transform: rotate(68deg);
    }
    67.5% {
      transform: rotate(68deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r70 {
    animation: e9z31OQKE0r70_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r70_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    81.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r76 {
    animation-name: e9z31OQKE0r76__m, e9z31OQKE0r76_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9z31OQKE0r76__m {
    0% {
      d: path(
        'M161.867,259.879C161.867,259.879,161.867,235.489998,161.867,235.489998C161.867,235.489998,161.85,235.271299,161.85,235.271299L162.798427,234.396294'
      );
    }
    12.5% {
      d: path(
        'M161.867,259.879C161.867,259.879,161.867,235.489998,161.867,235.489998C161.867,235.489998,161.85,235.271299,161.85,235.271299L162.798427,234.396294'
      );
    }
    21.25% {
      d: path(
        'M161.867,233.212333C161.867,233.212333,161.867,121.646257,161.867,121.646257C161.867,121.646257,161.865485,121.646257,161.865485,121.646257L162.870499,121.346257'
      );
    }
    22.5% {
      d: path(
        'M161.867,229.879C161.867,229.879,161.867,121.646257,161.867,121.646257C161.867,121.646257,161.865485,121.646257,161.865485,121.646257L164.720368,120.771299'
      );
    }
    32.5% {
      d: path(
        'M161.867,143.3214C161.867,143.3214,161.867,121.677,161.867,121.677C161.867,121.677,277.422107,83.120632,277.422107,83.120632L280.268001,82.288075'
      );
    }
    35% {
      d: path(
        'M161.867,121.682C161.867,121.682,161.867,121.677,161.867,121.677C161.867,121.677,296.68,76.7222,296.68,76.7222L296.854534,76.35604'
      );
    }
    42.5% {
      d: path(
        'M235.303182,96.99487C235.303182,96.99487,235.521364,96.992597,235.521364,96.992597C235.521364,96.992597,296.68,76.7222,296.68,76.7222L296.68,3.623396'
      );
    }
    47.5% {
      d: path(
        'M296.5,76.422261C296.5,76.422261,296.9,76.422262,296.9,76.422262C296.9,76.422262,296.68,76.7222,296.68,76.7222L296.68,-19.691469'
      );
    }
    100% {
      d: path(
        'M296.5,76.422261C296.5,76.422261,296.9,76.422262,296.9,76.422262C296.9,76.422262,296.68,76.7222,296.68,76.7222L296.68,-19.691469'
      );
    }
  }
  @keyframes e9z31OQKE0r76_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    46.25% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r77_to {
    animation: e9z31OQKE0r77_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r77_to__to {
    0% {
      transform: translate(227.810927px, 140.246297px);
    }
    6.25% {
      transform: translate(227.810927px, 140.246297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 19.921297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(230.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(239.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(359.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(361.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -120.728703px);
    }
    100% {
      transform: translate(361.9px, -120.728703px);
    }
  }
  #e9z31OQKE0r77_tr {
    animation: e9z31OQKE0r77_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r77_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    23.75% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r77 {
    animation: e9z31OQKE0r77_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r77_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 0.7;
    }
    48.75% {
      opacity: 0.7;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r78_to {
    animation: e9z31OQKE0r78_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r78_to__to {
    0% {
      transform: translate(227.810927px, 140.246297px);
    }
    6.25% {
      transform: translate(227.810927px, 140.246297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 19.921297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(231.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(240.798427px, 12.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(360.801035px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(361.851035px, -32.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -121.228703px);
    }
    100% {
      transform: translate(361.9px, -121.228703px);
    }
  }
  #e9z31OQKE0r78_tr {
    animation: e9z31OQKE0r78_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r78_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r78 {
    animation: e9z31OQKE0r78_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r78_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    48.75% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r80 {
    animation-name: e9z31OQKE0r80__m, e9z31OQKE0r80_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9z31OQKE0r80__m {
    0% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    56.25% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    65% {
      d: path('M161.867,229.879L161.867,121.677L161.85,122.622406L163.089459,122.622409');
    }
    66.25% {
      d: path('M161.867,220.862583L161.867,121.677L167.644998,119.941646L168.781167,119.938742');
    }
    80% {
      d: path('M161.867,121.682L161.867,121.677L296.68,76.7222L296.68,76.687317');
    }
    87.5% {
      d: path('M235.303182,96.99487L235.521364,96.992597L296.68,76.7222L296.68,3.623396');
    }
    93.75% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
    100% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
  }
  @keyframes e9z31OQKE0r80_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    91.25% {
      opacity: 1;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r81_to {
    animation: e9z31OQKE0r81_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r81_to__to {
    0% {
      transform: translate(226.8px, 139.146297px);
    }
    52.5% {
      transform: translate(226.8px, 139.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    65% {
      transform: translate(226.798427px, 21.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    66.25% {
      transform: translate(230.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(239.436776px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    78.75% {
      transform: translate(359.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    80% {
      transform: translate(361.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(361.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    93.75% {
      transform: translate(361.9px, -119.728703px);
    }
    100% {
      transform: translate(361.9px, -119.728703px);
    }
  }
  #e9z31OQKE0r81_tr {
    animation: e9z31OQKE0r81_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r81_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(72deg);
    }
    67.5% {
      transform: rotate(72deg);
    }
    78.75% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r81 {
    animation: e9z31OQKE0r81_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r81_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 0.7;
    }
    93.75% {
      opacity: 0.7;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r82_to {
    animation: e9z31OQKE0r82_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r82_to__to {
    0% {
      transform: translate(226.8px, 139.146297px);
    }
    52.5% {
      transform: translate(226.8px, 139.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    65% {
      transform: translate(226.798427px, 21.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    66.25% {
      transform: translate(230.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(239.436777px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    78.75% {
      transform: translate(359.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    80% {
      transform: translate(361.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(361.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    93.75% {
      transform: translate(361.9px, -120.228703px);
    }
    100% {
      transform: translate(361.9px, -120.228703px);
    }
  }
  #e9z31OQKE0r82_tr {
    animation: e9z31OQKE0r82_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r82_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(72deg);
    }
    78.75% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r82 {
    animation: e9z31OQKE0r82_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r82_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    93.75% {
      opacity: 0.8;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r87 {
    animation-name: e9z31OQKE0r87__m, e9z31OQKE0r87_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9z31OQKE0r87__m {
    0% {
      d: path(
        'M96.711985,145.439636L96.7109,127.987317L96.711985,127.987317C96.711985,127.987317,96.711985,127.987317,96.711985,127.987317'
      );
    }
    1.25% {
      d: path(
        'M96.7109,145.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.711985,121.683001,96.711985,121.683001'
      );
    }
    8.75% {
      d: path(
        'M96.7109,135.000465L96.7109,121.68L163.798426,103.148788C163.798426,103.148788,163.798426,103.148788,163.798426,103.148788'
      );
    }
    16.25% {
      d: path(
        'M96.7109,124.229631L96.7109,121.68L258.531964,77.088615C258.531964,77.088615,258.531964,77.088614,258.531964,77.088614'
      );
    }
    17.5% {
      d: path(
        'M96.7109,122.271298L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817001,75.687789,259.817001,75.687789'
      );
    }
    30% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,-9.65625,259.817,-9.65625');
    }
    100% {
      d: path('M96.7109,138.118L96.7109,121.68L259.817,76.7298C259.817,76.7298,259.817,-9.65625,259.817,-9.65625');
    }
  }
  @keyframes e9z31OQKE0r87_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    26.25% {
      opacity: 1;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r88_to {
    animation: e9z31OQKE0r88_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r88_to__to {
    0% {
      transform: translate(161px, 25.504306px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    1.25% {
      transform: translate(166.798427px, 15.088613px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    16.25% {
      transform: translate(322.801035px, -27.911387px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    17.5% {
      transform: translate(323.811985px, -31.712684px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    30% {
      transform: translate(323.81px, -122.212684px);
    }
    100% {
      transform: translate(323.81px, -122.212684px);
    }
  }
  #e9z31OQKE0r88_tr {
    animation: e9z31OQKE0r88_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r88_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(73deg);
    }
    16.25% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r88 {
    animation: e9z31OQKE0r88_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r88_c_o {
    0% {
      opacity: 0.7;
    }
    30% {
      opacity: 0.7;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r89_to {
    animation: e9z31OQKE0r89_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r89_to__to {
    0% {
      transform: translate(161px, 25.504306px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    1.25% {
      transform: translate(166.798427px, 15.157724px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    16.25% {
      transform: translate(322.801035px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    17.5% {
      transform: translate(323.811985px, -31.712684px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    30% {
      transform: translate(323.81px, -122.212684px);
    }
    100% {
      transform: translate(323.81px, -122.212684px);
    }
  }
  #e9z31OQKE0r89_tr {
    animation: e9z31OQKE0r89_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r89_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(73deg);
    }
    16.25% {
      transform: rotate(73deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r89 {
    animation: e9z31OQKE0r89_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r89_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r91 {
    animation-name: e9z31OQKE0r91__m, e9z31OQKE0r91_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9z31OQKE0r91__m {
    0% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    42.5% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    48.75% {
      d: path(
        'M96.7109,144.776311L96.7109,139.694875L96.839592,139.321751C96.839592,139.321751,96.867036,140.071751,96.867036,140.071751'
      );
    }
    52.5% {
      d: path(
        'M96.7109,145.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.908201,121.683001,96.908201,121.683001'
      );
    }
    60% {
      d: path(
        'M96.7109,134.021298L96.7109,121.68L170.897412,101.279844C170.897412,101.279844,170.897413,101.279844,170.897413,101.279844'
      );
    }
    66.25% {
      d: path(
        'M96.7109,124.229631L96.7109,121.68L259.817002,76.7624C259.817002,76.7624,259.817002,76.767317,259.817002,76.767317'
      );
    }
    67.5% {
      d: path(
        'M96.7109,122.271298L96.7109,121.68L259.817,76.767317C259.817,76.767317,259.817001,75.687789,259.817001,75.687789'
      );
    }
    72.5% {
      d: path(
        'M96.798427,121.438996L259.970615,77.139831L259.817,76.7298C259.817,76.7298,259.817001,47.289957,259.817001,47.289957'
      );
    }
    77.5% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
    100% {
      d: path('M259.817,76.7222L259.9,76.722201L259.817,76.7298C259.817,76.7298,259.817,-4.679304,259.817,-4.679304');
    }
  }
  @keyframes e9z31OQKE0r91_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    76.25% {
      opacity: 1;
    }
    85% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r92_to {
    animation: e9z31OQKE0r92_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r92_to__to {
    0% {
      transform: translate(161.8px, 50.787316px);
    }
    42.5% {
      transform: translate(161.8px, 50.787316px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(161.798427px, 21.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(166.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    66.25% {
      transform: translate(322.801035px, -27.448195px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(324.811985px, -31.712684px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(324.9px, -120.728703px);
    }
    100% {
      transform: translate(324.9px, -120.728703px);
    }
  }
  #e9z31OQKE0r92_tr {
    animation: e9z31OQKE0r92_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r92_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(73deg);
    }
    66.25% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r92 {
    animation: e9z31OQKE0r92_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r92_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 0.7;
    }
    81.25% {
      opacity: 0.7;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r93_to {
    animation: e9z31OQKE0r93_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r93_to__to {
    0% {
      transform: translate(161.8px, 50.787316px);
    }
    42.5% {
      transform: translate(161.8px, 50.787316px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(161.798427px, 21.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(166.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    66.25% {
      transform: translate(322.801035px, -27.448195px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    67.5% {
      transform: translate(324.811985px, -31.712684px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(324.9px, -120.728703px);
    }
    100% {
      transform: translate(324.9px, -120.728703px);
    }
  }
  #e9z31OQKE0r93_tr {
    animation: e9z31OQKE0r93_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r93_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(73deg);
    }
    66.25% {
      transform: rotate(73deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r93 {
    animation: e9z31OQKE0r93_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r93_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    81.25% {
      opacity: 1;
    }
    82.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r95 {
    animation-name: e9z31OQKE0r95__m, e9z31OQKE0r95_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9z31OQKE0r95__m {
    0% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    96.25% {
      d: path(
        'M96.7109,143.118L96.7109,143.053001L96.9082,142.053002C96.9082,142.053002,96.798427,144.053001,96.798427,144.053001'
      );
    }
    100% {
      d: path(
        'M96.7109,144.112987L96.7109,141.038125L96.867035,140.414251C96.867035,140.414251,96.839592,141.664251,96.839592,141.664251'
      );
    }
  }
  @keyframes e9z31OQKE0r95_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #e9z31OQKE0r96_to {
    animation: e9z31OQKE0r96_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r96_to__to {
    0% {
      transform: translate(161.5px, 49.521297px);
    }
    96.25% {
      transform: translate(161.5px, 49.521297px);
    }
    100% {
      transform: translate(161.798427px, 24.987316px);
    }
  }
  #e9z31OQKE0r96 {
    animation: e9z31OQKE0r96_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r96_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #e9z31OQKE0r97_to {
    animation: e9z31OQKE0r97_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r97_to__to {
    0% {
      transform: translate(161.5px, 49.521297px);
    }
    96.25% {
      transform: translate(161.5px, 49.521297px);
    }
    100% {
      transform: translate(161.798427px, 24.987316px);
    }
  }
  #e9z31OQKE0r97 {
    animation: e9z31OQKE0r97_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r97_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    96.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #e9z31OQKE0r103 {
    animation-name: e9z31OQKE0r103__m, e9z31OQKE0r103_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9z31OQKE0r103__m {
    0% {
      d: path('M32.0957,249.88L32.0957,240.521298L32.0957,240.521298L32.0957,240.524434');
    }
    12.5% {
      d: path('M32.0957,249.88L32.0957,240.521298L32.0957,240.521298L32.0957,240.524434');
    }
    26.25% {
      d: path('M32.0957,231.546667L32.0957,127.750264L32.0957,127.750264L33.0957,124.75706');
    }
    27.5% {
      d: path('M32.0957,229.88L32.0957,121.861988L32.0957,121.861988L36.502143,120.958416');
    }
    31.25% {
      d: path('M32.0957,202.407312L32.0957,121.678L80.044445,110.329273L80.044445,110.329272');
    }
    41.25% {
      d: path('M32.0957,129.146811L32.0957,121.678L218.801035,77.955237L218.801035,77.954434');
    }
    42.5% {
      d: path('M32.0957,119.989248L32.0957,121.678L222.955,76.874434L222.920607,76.874434');
    }
    55% {
      d: path('M222.955,76.874434L222.9557,76.874434L222.955,76.874434L222.955,-19.683594');
    }
    100% {
      d: path('M222.955,76.874434L222.9557,76.874434L222.955,76.874434L222.955,-19.683594');
    }
  }
  @keyframes e9z31OQKE0r103_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 1;
    }
    51.25% {
      opacity: 1;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r104_to {
    animation: e9z31OQKE0r104_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r104_to__to {
    0% {
      transform: translate(96.798427px, 143.537316px);
    }
    11.25% {
      transform: translate(96.798427px, 143.537316px);
    }
    26.25% {
      transform: translate(97.111986px, 20.771297px);
    }
    27.5% {
      transform: translate(100.798427px, 15.771297px);
    }
    41.25% {
      transform: translate(284.801035px, -27.679029px);
    }
    42.5% {
      transform: translate(287.920607px, -33.028703px);
    }
    56.25% {
      transform: translate(287.921986px, -120.728703px);
    }
    100% {
      transform: translate(287.921986px, -120.728703px);
    }
  }
  #e9z31OQKE0r104_tr {
    animation: e9z31OQKE0r104_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r104_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(75deg);
    }
    41.25% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r104 {
    animation: e9z31OQKE0r104_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r104_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.7;
    }
    56.25% {
      opacity: 0.7;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r105_to {
    animation: e9z31OQKE0r105_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r105_to__to {
    0% {
      transform: translate(96.798427px, 142.237316px);
    }
    11.25% {
      transform: translate(96.798427px, 142.237316px);
    }
    26.25% {
      transform: translate(97.111986px, 20.643116px);
    }
    27.5% {
      transform: translate(100.798427px, 15.771297px);
    }
    41.25% {
      transform: translate(284.833827px, -27.703774px);
    }
    42.5% {
      transform: translate(288.011986px, -33.028703px);
    }
    56.25% {
      transform: translate(288.011986px, -120.728703px);
    }
    100% {
      transform: translate(288.011986px, -120.728703px);
    }
  }
  #e9z31OQKE0r105_tr {
    animation: e9z31OQKE0r105_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r105_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(75deg);
    }
    41.25% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r105 {
    animation: e9z31OQKE0r105_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r105_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 1;
    }
    56.25% {
      opacity: 1;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r107 {
    animation-name: e9z31OQKE0r107__m, e9z31OQKE0r107_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes e9z31OQKE0r107__m {
    0% {
      d: path(
        'M32.0957,249.88L32.0957,239.147317C32.0957,239.147317,32.091986,239.147317,32.091986,239.147317L32.091986,239.146294'
      );
    }
    62.5% {
      d: path(
        'M32.0957,249.88L32.0957,239.147317C32.0957,239.147317,32.091986,239.147317,32.091986,239.147317L32.091986,239.146294'
      );
    }
    70% {
      d: path(
        'M32.0957,232.737143L32.0957,127.253586C32.0957,127.253586,32.095169,128.370558,32.095169,128.370558L32.095169,139.268673'
      );
    }
    71.25% {
      d: path(
        'M32.0957,229.88L32.0957,121.682C32.0957,121.682,35.798427,120.771298,35.798427,120.771298L37.70443,120.297198'
      );
    }
    78.75% {
      d: path(
        'M32.0957,179.9403L32.0957,121.680154C32.0957,121.680154,122.178384,100.444545,122.178384,100.444545L123.203302,100.063777'
      );
    }
    85% {
      d: path(
        'M32.0957,138.323884L32.0957,121.678615C32.0957,121.678615,194.161681,83.505584,194.161681,83.505584L203.19535,81.351205'
      );
    }
    86.25% {
      d: path(
        'M32.0957,130.0006L32.0957,121.678307C32.0957,121.678307,208.558341,80.117792,208.558341,80.117792L218.073668,77.904663'
      );
    }
    87.5% {
      d: path('M32.0957,121.677317L32.0957,121.678C32.0957,121.678,222.955,76.73,222.955,76.73L222.951986,76.45812');
    }
    95% {
      d: path('M222.955,76.422262L222.9,76.422262C222.9,76.422262,222.955,76.73,222.955,76.73L222.955,-19.683594');
    }
    100% {
      d: path('M222.955,76.422262L222.9,76.422262C222.9,76.422262,222.955,76.73,222.955,76.73L222.955,-19.683594');
    }
  }
  @keyframes e9z31OQKE0r107_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 1;
    }
    92.5% {
      opacity: 1;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r108_to {
    animation: e9z31OQKE0r108_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r108_to__to {
    0% {
      transform: translate(97.998427px, 140.121297px);
    }
    57.5% {
      transform: translate(97.998427px, 140.121297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(96.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(100.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(114.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    86.25% {
      transform: translate(283.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(287.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -120.728703px);
    }
    100% {
      transform: translate(287.9px, -120.728703px);
    }
  }
  #e9z31OQKE0r108_tr {
    animation: e9z31OQKE0r108_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r108_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(75deg);
    }
    86.25% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r108 {
    animation: e9z31OQKE0r108_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r108_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 0.7;
    }
    96.25% {
      opacity: 0.7;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #e9z31OQKE0r109_to {
    animation: e9z31OQKE0r109_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r109_to__to {
    0% {
      transform: translate(97.998427px, 140.121297px);
    }
    57.5% {
      transform: translate(97.998427px, 140.121297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(96.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(100.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(114.798427px, 12.497225px);
      animation-timing-function: cubic-bezier(0.357385, 0.28504, 0.680746, 0.629112);
    }
    78.75% {
      transform: translate(185.570936px, -4.302775px);
      animation-timing-function: cubic-bezier(0.323457, 0.318946, 0.641855, 0.665929);
    }
    86.25% {
      transform: translate(283.801035px, -27.302775px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(287.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -120.228703px);
    }
    100% {
      transform: translate(287.9px, -120.228703px);
    }
  }
  #e9z31OQKE0r109_tr {
    animation: e9z31OQKE0r109_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r109_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(75deg);
    }
    86.25% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #e9z31OQKE0r109 {
    animation: e9z31OQKE0r109_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes e9z31OQKE0r109_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 1;
    }
    96.25% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesBg8;
