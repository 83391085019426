// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesMobileBg1 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)', // Replace all <stop id="...-stroke-1" stopColor="#initial-color" /> with this value
  };

  return (
    <AnimatedSvg
      id="easnhHplxR51"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 320 250"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="320"
      height="250"
      className="source-background"
      {...props}
    >
      <defs>
        <linearGradient
          id="easnhHplxR57-stroke"
          x1="0.5"
          y1="0.695267"
          x2="0.5"
          y2="0.997712"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="easnhHplxR57-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="easnhHplxR57-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <filter id="easnhHplxR58-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="easnhHplxR58-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="easnhHplxR58-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="easnhHplxR58-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="easnhHplxR58-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="easnhHplxR58-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="easnhHplxR58-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="easnhHplxR58-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="easnhHplxR59-stroke"
          x1="35.234573"
          y1="0.004001"
          x2="35.234573"
          y2="1.094081"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="easnhHplxR59-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="easnhHplxR59-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="easnhHplxR510-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="easnhHplxR510-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="easnhHplxR510-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="easnhHplxR510-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="easnhHplxR510-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="easnhHplxR510-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="easnhHplxR510-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="easnhHplxR510-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="easnhHplxR510-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="easnhHplxR512-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="easnhHplxR512-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="easnhHplxR512-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="easnhHplxR512-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="easnhHplxR512-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="easnhHplxR512-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="easnhHplxR512-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="easnhHplxR512-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="easnhHplxR513-stroke"
          x1="35.234573"
          y1="0.004001"
          x2="35.234573"
          y2="1.094081"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="easnhHplxR513-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="easnhHplxR513-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="easnhHplxR514-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="easnhHplxR514-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="easnhHplxR514-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="easnhHplxR514-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="easnhHplxR514-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="easnhHplxR514-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="easnhHplxR514-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="easnhHplxR514-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="easnhHplxR514-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
      </defs>
      <g transform="translate(-22-2.25894)" mask="url(#easnhHplxR516)">
        <g transform="translate(.000001 0)">
          <g transform="translate(.088013 1.012683)">
            <g transform="translate(-97.999999 0)">
              <path
                d="M161.867,218.879l.004985-97.202v-44.144397-77.224072"
                transform="translate(117.344985 0)"
                fill="none"
                stroke="url(#easnhHplxR57-stroke)"
              />
              <g transform="translate(-65 105.000001)" filter="url(#easnhHplxR58-filter)">
                <path
                  id="easnhHplxR59"
                  d="M278.994002,286.5213L278.994,225.053002l.106.000002-1.070999-1.000002"
                  transform="translate(65-93.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#easnhHplxR59-stroke)"
                  strokeLinecap="round"
                />
                <g id="easnhHplxR510_to" transform="translate(344.25,128.271297)">
                  <rect
                    id="easnhHplxR510"
                    width="8.75"
                    height="15"
                    rx="0"
                    ry="0"
                    transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                    opacity="0"
                    filter="url(#easnhHplxR510-filter)"
                    fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="easnhHplxR511_to" transform="translate(344.25,128.271297)">
                  <rect
                    id="easnhHplxR511"
                    width="10"
                    height="20"
                    rx="0"
                    ry="0"
                    transform="scale(0.5,0.5) translate(-5,-10)"
                    opacity="0"
                    fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#easnhHplxR512-filter)">
                <path
                  id="easnhHplxR513"
                  d="M278.994002,286.5213L278.994,225.053002l.106.000002-1.070999-1.000002"
                  transform="translate(65-93.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#easnhHplxR513-stroke)"
                  strokeLinecap="round"
                />
                <g id="easnhHplxR514_to" transform="translate(344.25,128.271297)">
                  <rect
                    id="easnhHplxR514"
                    width="8.75"
                    height="15"
                    rx="0"
                    ry="0"
                    transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                    opacity="0"
                    filter="url(#easnhHplxR514-filter)"
                    fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="easnhHplxR515_to" transform="translate(344.25,128.271297)">
                  <rect
                    id="easnhHplxR515"
                    width="10"
                    height="20"
                    rx="0"
                    ry="0"
                    transform="scale(0.5,0.5) translate(-5,-10)"
                    opacity="0"
                    fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <mask id="easnhHplxR516" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="825.762291"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #easnhHplxR59 {
    animation-name: easnhHplxR59__m, easnhHplxR59_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes easnhHplxR59__m {
    0% {
      d: path('M278.994002,286.5213L278.994,225.053002L279.1,225.053004L278.029001,224.053002');
    }
    11.25% {
      d: path('M278.994002,286.5213L278.994,225.053002L279.1,225.053004L278.029001,224.053002');
    }
    53.75% {
      d: path('M278.994002,65.5213L278.994,-23.946998L279.1,-23.946996L278.029001,-24.946998');
    }
    100% {
      d: path('M278.994002,65.5213L278.994,-23.946998L279.1,-23.946996L278.029001,-24.946998');
    }
  }
  @keyframes easnhHplxR59_c_o {
    0% {
      opacity: 0;
    }
    22.5% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    56.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #easnhHplxR510_to {
    animation: easnhHplxR510_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes easnhHplxR510_to__to {
    0% {
      transform: translate(344.25px, 128.271297px);
    }
    11.25% {
      transform: translate(344.25px, 128.271297px);
    }
    53.75% {
      transform: translate(344.25px, -117.728703px);
    }
    100% {
      transform: translate(344.25px, -117.728703px);
    }
  }
  #easnhHplxR510 {
    animation: easnhHplxR510_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes easnhHplxR510_c_o {
    0% {
      opacity: 0;
    }
    13.75% {
      opacity: 0;
    }
    20% {
      opacity: 0.7;
    }
    53.75% {
      opacity: 0.7;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #easnhHplxR511_to {
    animation: easnhHplxR511_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes easnhHplxR511_to__to {
    0% {
      transform: translate(344.25px, 128.271297px);
    }
    11.25% {
      transform: translate(344.25px, 128.271297px);
    }
    53.75% {
      transform: translate(344.25px, -117.728703px);
    }
    100% {
      transform: translate(344.25px, -117.728703px);
    }
  }
  #easnhHplxR511 {
    animation: easnhHplxR511_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes easnhHplxR511_c_o {
    0% {
      opacity: 0;
    }
    13.75% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    53.75% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #easnhHplxR513 {
    animation-name: easnhHplxR513__m, easnhHplxR513_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes easnhHplxR513__m {
    0% {
      d: path('M278.994002,286.5213L278.994,225.053002L279.1,225.053004L278.029001,224.053002');
    }
    55% {
      d: path('M278.994002,286.5213L278.994,225.053002L279.1,225.053004L278.029001,224.053002');
    }
    97.5% {
      d: path('M278.994002,65.5213L278.994,-23.946998L279.1,-23.946996L278.029001,-24.946998');
    }
    100% {
      d: path('M278.994002,65.5213L278.994,-23.946998L279.1,-23.946996L278.029001,-24.946998');
    }
  }
  @keyframes easnhHplxR513_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    93.75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #easnhHplxR514_to {
    animation: easnhHplxR514_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes easnhHplxR514_to__to {
    0% {
      transform: translate(344.25px, 128.271297px);
    }
    55% {
      transform: translate(344.25px, 128.271297px);
    }
    97.5% {
      transform: translate(344.25px, -117.728703px);
    }
    100% {
      transform: translate(344.25px, -117.728703px);
    }
  }
  #easnhHplxR514 {
    animation: easnhHplxR514_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes easnhHplxR514_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    63.75% {
      opacity: 0.7;
    }
    97.5% {
      opacity: 0.7;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #easnhHplxR515_to {
    animation: easnhHplxR515_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes easnhHplxR515_to__to {
    0% {
      transform: translate(344.25px, 128.271297px);
    }
    55% {
      transform: translate(344.25px, 128.271297px);
    }
    97.5% {
      transform: translate(344.25px, -117.728703px);
    }
    100% {
      transform: translate(344.25px, -117.728703px);
    }
  }
  #easnhHplxR515 {
    animation: easnhHplxR515_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes easnhHplxR515_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    63.75% {
      opacity: 1;
    }
    97.5% {
      opacity: 1;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesMobileBg1;
