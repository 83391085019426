// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesMobileBg5 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)', // Replace all <stop id="...-stroke-1" stopColor="#initial-color" /> with this value
  };

  return (
    <AnimatedSvg
      id="eWaJQDoI8yL1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 320 250"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="320"
      height="250"
      className="source-background"
      {...props}
    >
      <defs>
        <linearGradient
          id="eWaJQDoI8yL7-stroke"
          x1="0.370708"
          y1="0.690679"
          x2="0.381501"
          y2="0.997708"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eWaJQDoI8yL7-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eWaJQDoI8yL7-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <filter id="eWaJQDoI8yL8-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eWaJQDoI8yL8-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eWaJQDoI8yL8-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eWaJQDoI8yL8-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eWaJQDoI8yL8-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eWaJQDoI8yL8-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eWaJQDoI8yL8-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eWaJQDoI8yL8-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eWaJQDoI8yL9-stroke"
          x1="-5275.007951"
          y1="0.083835"
          x2="-5275.007951"
          y2="0.902057"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eWaJQDoI8yL9-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eWaJQDoI8yL9-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eWaJQDoI8yL10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eWaJQDoI8yL10-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eWaJQDoI8yL10-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eWaJQDoI8yL10-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eWaJQDoI8yL10-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eWaJQDoI8yL10-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eWaJQDoI8yL10-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eWaJQDoI8yL10-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eWaJQDoI8yL10-filter-blur-0" stdDeviation="3,3" result="result" />
        </filter>
        <filter id="eWaJQDoI8yL11-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eWaJQDoI8yL11-filter-blur-0" stdDeviation="0,0" result="result" />
        </filter>
        <filter id="eWaJQDoI8yL12-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eWaJQDoI8yL12-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eWaJQDoI8yL12-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eWaJQDoI8yL12-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eWaJQDoI8yL12-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eWaJQDoI8yL12-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eWaJQDoI8yL12-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eWaJQDoI8yL12-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eWaJQDoI8yL13-stroke"
          x1="-5275.007951"
          y1="0.083835"
          x2="-5275.007951"
          y2="0.902057"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eWaJQDoI8yL13-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eWaJQDoI8yL13-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eWaJQDoI8yL14-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eWaJQDoI8yL14-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eWaJQDoI8yL14-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eWaJQDoI8yL14-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eWaJQDoI8yL14-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eWaJQDoI8yL14-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eWaJQDoI8yL14-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eWaJQDoI8yL14-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eWaJQDoI8yL14-filter-blur-0" stdDeviation="3,3" result="result" />
        </filter>
        <filter id="eWaJQDoI8yL15-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eWaJQDoI8yL15-filter-blur-0" stdDeviation="0,0" result="result" />
        </filter>
        <linearGradient
          id="eWaJQDoI8yL17-stroke"
          x1="0.5"
          y1="0.695258"
          x2="0.5"
          y2="0.997712"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eWaJQDoI8yL17-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eWaJQDoI8yL17-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <filter id="eWaJQDoI8yL18-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eWaJQDoI8yL18-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eWaJQDoI8yL18-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eWaJQDoI8yL18-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eWaJQDoI8yL18-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eWaJQDoI8yL18-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eWaJQDoI8yL18-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eWaJQDoI8yL18-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eWaJQDoI8yL19-stroke"
          x1="2.127849"
          y1="0.096177"
          x2="1.940586"
          y2="1"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eWaJQDoI8yL19-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eWaJQDoI8yL19-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="eWaJQDoI8yL20-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eWaJQDoI8yL20-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eWaJQDoI8yL20-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eWaJQDoI8yL20-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eWaJQDoI8yL20-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eWaJQDoI8yL20-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eWaJQDoI8yL20-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eWaJQDoI8yL20-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eWaJQDoI8yL20-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eWaJQDoI8yL22-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eWaJQDoI8yL22-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eWaJQDoI8yL22-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eWaJQDoI8yL22-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eWaJQDoI8yL22-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eWaJQDoI8yL22-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eWaJQDoI8yL22-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eWaJQDoI8yL22-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eWaJQDoI8yL23-stroke"
          x1="2.127849"
          y1="0.096177"
          x2="1.940586"
          y2="1"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eWaJQDoI8yL23-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eWaJQDoI8yL23-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="eWaJQDoI8yL24-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eWaJQDoI8yL24-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eWaJQDoI8yL24-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eWaJQDoI8yL24-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eWaJQDoI8yL24-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eWaJQDoI8yL24-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eWaJQDoI8yL24-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eWaJQDoI8yL24-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eWaJQDoI8yL24-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <linearGradient
          id="eWaJQDoI8yL27-stroke"
          x1="0.5"
          y1="0.695267"
          x2="0.5"
          y2="0.997712"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eWaJQDoI8yL27-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eWaJQDoI8yL27-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <filter id="eWaJQDoI8yL28-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eWaJQDoI8yL28-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eWaJQDoI8yL28-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eWaJQDoI8yL28-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eWaJQDoI8yL28-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eWaJQDoI8yL28-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eWaJQDoI8yL28-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eWaJQDoI8yL28-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eWaJQDoI8yL29-stroke"
          x1="35.433962"
          y1="0"
          x2="35.433962"
          y2="1.148697"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eWaJQDoI8yL29-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eWaJQDoI8yL29-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eWaJQDoI8yL30-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eWaJQDoI8yL30-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eWaJQDoI8yL30-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eWaJQDoI8yL30-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eWaJQDoI8yL30-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eWaJQDoI8yL30-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eWaJQDoI8yL30-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eWaJQDoI8yL30-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eWaJQDoI8yL30-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eWaJQDoI8yL32-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eWaJQDoI8yL32-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eWaJQDoI8yL32-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eWaJQDoI8yL32-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eWaJQDoI8yL32-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eWaJQDoI8yL32-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eWaJQDoI8yL32-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eWaJQDoI8yL32-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eWaJQDoI8yL33-stroke"
          x1="35.433962"
          y1="0"
          x2="35.433962"
          y2="1.148697"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eWaJQDoI8yL33-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eWaJQDoI8yL33-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eWaJQDoI8yL34-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eWaJQDoI8yL34-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eWaJQDoI8yL34-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eWaJQDoI8yL34-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eWaJQDoI8yL34-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eWaJQDoI8yL34-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eWaJQDoI8yL34-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eWaJQDoI8yL34-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eWaJQDoI8yL34-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <linearGradient
          id="eWaJQDoI8yL37-stroke"
          x1="0.5"
          y1="0.695267"
          x2="0.5"
          y2="0.997712"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eWaJQDoI8yL37-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eWaJQDoI8yL37-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <filter id="eWaJQDoI8yL38-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eWaJQDoI8yL38-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eWaJQDoI8yL38-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eWaJQDoI8yL38-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eWaJQDoI8yL38-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eWaJQDoI8yL38-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eWaJQDoI8yL38-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eWaJQDoI8yL38-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eWaJQDoI8yL39-stroke"
          x1="1.530575"
          y1="0.229995"
          x2="1.0017"
          y2="0.958809"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eWaJQDoI8yL39-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eWaJQDoI8yL39-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="eWaJQDoI8yL40-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eWaJQDoI8yL40-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eWaJQDoI8yL40-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eWaJQDoI8yL40-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eWaJQDoI8yL40-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eWaJQDoI8yL40-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eWaJQDoI8yL40-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eWaJQDoI8yL40-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eWaJQDoI8yL40-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eWaJQDoI8yL42-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eWaJQDoI8yL42-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eWaJQDoI8yL42-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eWaJQDoI8yL42-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eWaJQDoI8yL42-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eWaJQDoI8yL42-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eWaJQDoI8yL42-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eWaJQDoI8yL42-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eWaJQDoI8yL43-stroke"
          x1="1.530575"
          y1="0.229995"
          x2="1.0017"
          y2="0.958809"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eWaJQDoI8yL43-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eWaJQDoI8yL43-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="eWaJQDoI8yL44-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eWaJQDoI8yL44-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eWaJQDoI8yL44-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eWaJQDoI8yL44-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eWaJQDoI8yL44-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eWaJQDoI8yL44-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eWaJQDoI8yL44-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eWaJQDoI8yL44-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eWaJQDoI8yL44-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <linearGradient
          id="eWaJQDoI8yL47-stroke"
          x1="-0.002599"
          y1="1"
          x2="0.218911"
          y2="0.828707"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eWaJQDoI8yL47-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="eWaJQDoI8yL47-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eWaJQDoI8yL48-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eWaJQDoI8yL48-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eWaJQDoI8yL48-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eWaJQDoI8yL48-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eWaJQDoI8yL48-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eWaJQDoI8yL48-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eWaJQDoI8yL48-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eWaJQDoI8yL48-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eWaJQDoI8yL49-stroke"
          x1="1.25753"
          y1="0.063378"
          x2="0.000365"
          y2="0.866006"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eWaJQDoI8yL49-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eWaJQDoI8yL49-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="eWaJQDoI8yL50-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eWaJQDoI8yL50-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eWaJQDoI8yL50-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eWaJQDoI8yL50-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eWaJQDoI8yL50-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eWaJQDoI8yL50-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eWaJQDoI8yL50-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eWaJQDoI8yL50-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eWaJQDoI8yL50-filter-blur-0" stdDeviation="3,3" result="result" />
        </filter>
        <filter id="eWaJQDoI8yL51-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eWaJQDoI8yL51-filter-blur-0" stdDeviation="0,0" result="result" />
        </filter>
        <filter id="eWaJQDoI8yL52-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eWaJQDoI8yL52-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eWaJQDoI8yL52-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eWaJQDoI8yL52-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eWaJQDoI8yL52-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eWaJQDoI8yL52-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eWaJQDoI8yL52-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eWaJQDoI8yL52-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eWaJQDoI8yL53-stroke"
          x1="1.25753"
          y1="0.063378"
          x2="0.000365"
          y2="0.866006"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eWaJQDoI8yL53-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eWaJQDoI8yL53-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="eWaJQDoI8yL54-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eWaJQDoI8yL54-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eWaJQDoI8yL54-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eWaJQDoI8yL54-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eWaJQDoI8yL54-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eWaJQDoI8yL54-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eWaJQDoI8yL54-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eWaJQDoI8yL54-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eWaJQDoI8yL54-filter-blur-0" stdDeviation="3,3" result="result" />
        </filter>
        <filter id="eWaJQDoI8yL55-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eWaJQDoI8yL55-filter-blur-0" stdDeviation="0,0" result="result" />
        </filter>
      </defs>
      <g transform="translate(-22-2.25894)" mask="url(#eWaJQDoI8yL56)">
        <g transform="translate(.000001 0)">
          <g transform="translate(.088013 1.012683)">
            <g transform="matrix(-1 0 0 1 357.455002 0)">
              <path
                d="M32.0957,218.88v-97.202L86.156086,77.532603v-77.224072"
                fill="none"
                stroke="url(#eWaJQDoI8yL7-stroke)"
              />
              <g transform="translate(-65 105.000001)" filter="url(#eWaJQDoI8yL8-filter)">
                <path
                  id="eWaJQDoI8yL9"
                  d="M32.0957,289.88v-43.358702v0v.003136"
                  transform="translate(64.994651-105.187118)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eWaJQDoI8yL9-stroke)"
                  strokeLinecap="round"
                />
                <g id="eWaJQDoI8yL10_to" transform="translate(96.798427,143.537316)">
                  <g id="eWaJQDoI8yL10_tr" transform="rotate(0)">
                    <rect
                      id="eWaJQDoI8yL10"
                      width="10"
                      height="17.142857"
                      rx="0"
                      ry="0"
                      transform="scale(0.7,0.7) translate(-5,-8.571429)"
                      opacity="0"
                      filter="url(#eWaJQDoI8yL10-filter)"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eWaJQDoI8yL11_to" transform="translate(96.798427,142.237316)">
                  <g id="eWaJQDoI8yL11_tr" transform="rotate(0)">
                    <rect
                      id="eWaJQDoI8yL11"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      filter="url(#eWaJQDoI8yL11-filter)"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      strokeMiterlimit="8"
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eWaJQDoI8yL12-filter)">
                <path
                  id="eWaJQDoI8yL13"
                  d="M32.0957,289.88v-43.358702v0v.003136"
                  transform="translate(64.994651-105.187118)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eWaJQDoI8yL13-stroke)"
                  strokeLinecap="round"
                />
                <g id="eWaJQDoI8yL14_to" transform="translate(96.798427,143.537316)">
                  <g id="eWaJQDoI8yL14_tr" transform="rotate(0)">
                    <rect
                      id="eWaJQDoI8yL14"
                      width="10"
                      height="17.142857"
                      rx="0"
                      ry="0"
                      transform="scale(0.7,0.7) translate(-5,-8.571429)"
                      opacity="0"
                      filter="url(#eWaJQDoI8yL14-filter)"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eWaJQDoI8yL15_to" transform="translate(96.798427,142.237316)">
                  <g id="eWaJQDoI8yL15_tr" transform="rotate(0)">
                    <rect
                      id="eWaJQDoI8yL15"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      filter="url(#eWaJQDoI8yL15-filter)"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                      strokeMiterlimit="8"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="matrix(-1 0 0 1 406.03 0)">
              <path
                d="M161.867,218.879v-97.202L180.68,77.67163l.001986-77.363099"
                fill="none"
                stroke="url(#eWaJQDoI8yL17-stroke)"
              />
              <g transform="translate(-65 105.000001)" filter="url(#eWaJQDoI8yL18-filter)">
                <path
                  id="eWaJQDoI8yL19"
                  d="M161.867,259.879v-6.389002c0,0-.017-2.218699-.017-2.218699l-.051573-41.875005"
                  transform="translate(64.996501-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eWaJQDoI8yL19-stroke)"
                  strokeLinecap="round"
                />
                <g id="eWaJQDoI8yL20_to" transform="translate(226.779751,128.661481)">
                  <g id="eWaJQDoI8yL20_tr" transform="rotate(0)">
                    <rect
                      id="eWaJQDoI8yL20"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#eWaJQDoI8yL20-filter)"
                      fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eWaJQDoI8yL21_to" transform="translate(226.779751,128.661481)">
                  <g id="eWaJQDoI8yL21_tr" transform="rotate(0)">
                    <rect
                      id="eWaJQDoI8yL21"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eWaJQDoI8yL22-filter)">
                <path
                  id="eWaJQDoI8yL23"
                  d="M161.867,259.879v-6.389002c0,0-.017-2.218699-.017-2.218699l-.051573-41.875005"
                  transform="translate(64.996501-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eWaJQDoI8yL23-stroke)"
                  strokeLinecap="round"
                />
                <g id="eWaJQDoI8yL24_to" transform="translate(226.779751,128.661481)">
                  <g id="eWaJQDoI8yL24_tr" transform="rotate(0)">
                    <rect
                      id="eWaJQDoI8yL24"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#eWaJQDoI8yL24-filter)"
                      fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eWaJQDoI8yL25_to" transform="translate(226.779751,128.661481)">
                  <g id="eWaJQDoI8yL25_tr" transform="rotate(0)">
                    <rect
                      id="eWaJQDoI8yL25"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(-97.999999 0)">
              <path
                d="M161.867,218.879l.004985-97.202v-44.144397-77.224072"
                transform="translate(117.344985 0)"
                fill="none"
                stroke="url(#eWaJQDoI8yL27-stroke)"
              />
              <g transform="translate(-65 105.000001)" filter="url(#eWaJQDoI8yL28-filter)">
                <path
                  id="eWaJQDoI8yL29"
                  d="M278.994002,286.5213L278.994,225.053002l.106.000002-.070999-.000002"
                  transform="translate(65-93.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eWaJQDoI8yL29-stroke)"
                  strokeLinecap="round"
                />
                <g id="eWaJQDoI8yL30_to" transform="translate(344.25,128.271297)">
                  <rect
                    id="eWaJQDoI8yL30"
                    width="8.75"
                    height="15"
                    rx="0"
                    ry="0"
                    transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                    opacity="0"
                    filter="url(#eWaJQDoI8yL30-filter)"
                    fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eWaJQDoI8yL31_to" transform="translate(344.25,128.271297)">
                  <rect
                    id="eWaJQDoI8yL31"
                    width="10"
                    height="20"
                    rx="0"
                    ry="0"
                    transform="scale(0.5,0.5) translate(-5,-10)"
                    opacity="0"
                    fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eWaJQDoI8yL32-filter)">
                <path
                  id="eWaJQDoI8yL33"
                  d="M278.994002,286.5213L278.994,225.053002l.106.000002-.070999-.000002"
                  transform="translate(65-93.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eWaJQDoI8yL33-stroke)"
                  strokeLinecap="round"
                />
                <g id="eWaJQDoI8yL34_to" transform="translate(344.25,128.271297)">
                  <rect
                    id="eWaJQDoI8yL34"
                    width="8.75"
                    height="15"
                    rx="0"
                    ry="0"
                    transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                    opacity="0"
                    filter="url(#eWaJQDoI8yL34-filter)"
                    fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eWaJQDoI8yL35_to" transform="translate(344.25,128.271297)">
                  <rect
                    id="eWaJQDoI8yL35"
                    width="10"
                    height="20"
                    rx="0"
                    ry="0"
                    transform="scale(0.5,0.5) translate(-5,-10)"
                    opacity="0"
                    fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
              </g>
            </g>
            <g transform="translate(-44 0)">
              <path
                d="M161.867,218.879v-97.202L180.68,77.67163l.001986-77.363099"
                fill="none"
                stroke="url(#eWaJQDoI8yL37-stroke)"
              />
              <g transform="translate(-65 105.000001)" filter="url(#eWaJQDoI8yL38-filter)">
                <path
                  id="eWaJQDoI8yL39"
                  d="M161.867,259.879v-10.482706c0,0-.017,0-.017,0l.948427-1"
                  transform="translate(64.996501-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eWaJQDoI8yL39-stroke)"
                  strokeLinecap="round"
                />
                <g id="eWaJQDoI8yL40_to" transform="translate(226.779751,128.661481)">
                  <g id="eWaJQDoI8yL40_tr" transform="rotate(0)">
                    <rect
                      id="eWaJQDoI8yL40"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#eWaJQDoI8yL40-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eWaJQDoI8yL41_to" transform="translate(226.779751,128.661481)">
                  <g id="eWaJQDoI8yL41_tr" transform="rotate(0)">
                    <rect
                      id="eWaJQDoI8yL41"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eWaJQDoI8yL42-filter)">
                <path
                  id="eWaJQDoI8yL43"
                  d="M161.867,259.879v-10.482706c0,0-.017,0-.017,0l.948427-1"
                  transform="translate(64.996501-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eWaJQDoI8yL43-stroke)"
                  strokeLinecap="round"
                />
                <g id="eWaJQDoI8yL44_to" transform="translate(226.779751,128.661481)">
                  <g id="eWaJQDoI8yL44_tr" transform="rotate(0)">
                    <rect
                      id="eWaJQDoI8yL44"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#eWaJQDoI8yL44-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eWaJQDoI8yL45_to" transform="translate(226.779751,128.661481)">
                  <g id="eWaJQDoI8yL45_tr" transform="rotate(0)">
                    <rect
                      id="eWaJQDoI8yL45"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(6 0)">
              <path
                d="M32.0957,218.88v-97.202L86.156086,77.532603v-77.224072"
                fill="none"
                stroke="url(#eWaJQDoI8yL47-stroke)"
              />
              <g transform="translate(-65 105.000001)" filter="url(#eWaJQDoI8yL48-filter)">
                <path
                  id="eWaJQDoI8yL49"
                  d="M32.0957,321.564875v-89.736339l.005349.041895v.005687"
                  transform="translate(64.994651-105.187118)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eWaJQDoI8yL49-stroke)"
                  strokeLinecap="round"
                />
                <g id="eWaJQDoI8yL50_to" transform="translate(96.798427,143.537316)">
                  <g id="eWaJQDoI8yL50_tr" transform="rotate(0)">
                    <rect
                      id="eWaJQDoI8yL50"
                      width="10"
                      height="17.142857"
                      rx="0"
                      ry="0"
                      transform="scale(0.7,0.7) translate(-5,-8.571429)"
                      opacity="0"
                      filter="url(#eWaJQDoI8yL50-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eWaJQDoI8yL51_to" transform="translate(96.798427,142.237316)">
                  <g id="eWaJQDoI8yL51_tr" transform="rotate(0)">
                    <rect
                      id="eWaJQDoI8yL51"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      filter="url(#eWaJQDoI8yL51-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      strokeMiterlimit="8"
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eWaJQDoI8yL52-filter)">
                <path
                  id="eWaJQDoI8yL53"
                  d="M32.0957,321.564875v-89.736339l.005349.041895v.005687"
                  transform="translate(64.994651-105.187118)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eWaJQDoI8yL53-stroke)"
                  strokeLinecap="round"
                />
                <g id="eWaJQDoI8yL54_to" transform="translate(96.798427,143.537316)">
                  <g id="eWaJQDoI8yL54_tr" transform="rotate(0)">
                    <rect
                      id="eWaJQDoI8yL54"
                      width="10"
                      height="17.142857"
                      rx="0"
                      ry="0"
                      transform="scale(0.7,0.7) translate(-5,-8.571429)"
                      opacity="0"
                      filter="url(#eWaJQDoI8yL54-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eWaJQDoI8yL55_to" transform="translate(96.798427,142.237316)">
                  <g id="eWaJQDoI8yL55_tr" transform="rotate(0)">
                    <rect
                      id="eWaJQDoI8yL55"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      filter="url(#eWaJQDoI8yL55-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      strokeMiterlimit="8"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <mask id="eWaJQDoI8yL56" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="825.762291"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #eWaJQDoI8yL9 {
    animation-name: eWaJQDoI8yL9__m, eWaJQDoI8yL9_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eWaJQDoI8yL9__m {
    0% {
      d: path('M32.0957,289.88L32.0957,246.521298L32.0957,246.521298L32.0957,246.524434');
    }
    5% {
      d: path('M32.0957,289.88L32.0957,246.521298L32.0957,246.521298L32.0957,246.524434');
    }
    10% {
      d: path('M32.0957,249.88L32.0957,206.521298L32.0957,206.521298L32.0957,206.524434');
    }
    12.5% {
      d: path('M32.0957,232.768889L32.0957,185.77161L32.0957,185.77161L32.0957,185.775633');
    }
    13.75% {
      d: path('M32.0957,224.213333L32.0957,176.396766L32.0957,176.396766L32.0957,176.401233');
    }
    18.75% {
      d: path('M32.0957,214.324444L32.0957,135.230724L32.0957,135.230724L32.0957,135.236965');
    }
    21.25% {
      d: path('M32.0957,219.88L32.0957,121.861988L32.0957,121.861988L32.0957,121.869117');
    }
    35% {
      d: path('M32.0957,129.146811L32.0957,121.678L85.801035,77.955237L85.801035,77.954434');
    }
    50% {
      d: path('M86.161435,77.676826L86.161435,77.676826L86.1598,77.676826L86.1598,-5.543385');
    }
    100% {
      d: path('M86.161435,77.676826L86.161435,77.676826L86.1598,77.676826L86.1598,-5.543385');
    }
  }
  @keyframes eWaJQDoI8yL9_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    26.25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    51.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eWaJQDoI8yL10_to {
    animation: eWaJQDoI8yL10_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL10_to__to {
    0% {
      transform: translate(96.798427px, 143.537316px);
    }
    5% {
      transform: translate(96.798427px, 143.537316px);
    }
    20% {
      transform: translate(97.111986px, 20.771297px);
    }
    21.25% {
      transform: translate(99.798427px, 14.771297px);
    }
    35% {
      transform: translate(148.801035px, -25.679029px);
    }
    36.25% {
      transform: translate(150.920607px, -33.028703px);
    }
    50% {
      transform: translate(150.920607px, -113.028703px);
    }
    100% {
      transform: translate(150.920607px, -113.028703px);
    }
  }
  #eWaJQDoI8yL10_tr {
    animation: eWaJQDoI8yL10_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL10_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(50deg);
    }
    35% {
      transform: rotate(50deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eWaJQDoI8yL10 {
    animation: eWaJQDoI8yL10_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL10_c_o {
    0% {
      opacity: 0;
    }
    7.5% {
      opacity: 0;
    }
    13.75% {
      opacity: 0.7;
    }
    48.75% {
      opacity: 0.7;
    }
    51.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eWaJQDoI8yL11_to {
    animation: eWaJQDoI8yL11_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL11_to__to {
    0% {
      transform: translate(96.798427px, 142.237316px);
    }
    5% {
      transform: translate(96.798427px, 142.237316px);
    }
    20% {
      transform: translate(97.111986px, 20.643116px);
    }
    21.25% {
      transform: translate(99.798427px, 14.771297px);
    }
    35% {
      transform: translate(148.833827px, -25.703774px);
    }
    36.25% {
      transform: translate(150.920607px, -33.028703px);
    }
    50% {
      transform: translate(150.920607px, -113.028703px);
    }
    100% {
      transform: translate(150.920607px, -113.028703px);
    }
  }
  #eWaJQDoI8yL11_tr {
    animation: eWaJQDoI8yL11_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL11_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(50deg);
    }
    35% {
      transform: rotate(50deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eWaJQDoI8yL11 {
    animation: eWaJQDoI8yL11_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL11_c_o {
    0% {
      opacity: 0;
    }
    7.5% {
      opacity: 0;
    }
    13.75% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    51.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eWaJQDoI8yL13 {
    animation-name: eWaJQDoI8yL13__m, eWaJQDoI8yL13_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eWaJQDoI8yL13__m {
    0% {
      d: path('M32.0957,289.88L32.0957,246.521298L32.0957,246.521298L32.0957,246.524434');
    }
    55% {
      d: path('M32.0957,289.88L32.0957,246.521298L32.0957,246.521298L32.0957,246.524434');
    }
    60% {
      d: path('M32.0957,249.88L32.0957,206.521298L32.0957,206.521298L32.0957,206.524434');
    }
    62.5% {
      d: path('M32.0957,232.768889L32.0957,185.77161L32.0957,185.77161L32.0957,185.775633');
    }
    63.75% {
      d: path('M32.0957,224.213333L32.0957,176.396766L32.0957,176.396766L32.0957,176.401233');
    }
    68.75% {
      d: path('M32.0957,214.324444L32.0957,135.230724L32.0957,135.230724L32.0957,135.236965');
    }
    71.25% {
      d: path('M32.0957,219.88L32.0957,121.861988L32.0957,121.861988L32.0957,121.869117');
    }
    85% {
      d: path('M32.0957,129.146811L32.0957,121.678L85.801035,77.955237L85.801035,77.954434');
    }
    100% {
      d: path('M86.161435,77.676826L86.161435,77.676826L86.1598,77.676826L86.1598,-5.543385');
    }
  }
  @keyframes eWaJQDoI8yL13_c_o {
    0% {
      opacity: 0;
    }
    63.75% {
      opacity: 0;
    }
    72.5% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eWaJQDoI8yL14_to {
    animation: eWaJQDoI8yL14_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL14_to__to {
    0% {
      transform: translate(96.798427px, 143.537316px);
    }
    55% {
      transform: translate(96.798427px, 143.537316px);
    }
    70% {
      transform: translate(97.111986px, 20.771297px);
    }
    71.25% {
      transform: translate(99.798427px, 14.771297px);
    }
    85% {
      transform: translate(148.801035px, -25.679029px);
    }
    86.25% {
      transform: translate(150.920607px, -33.028703px);
    }
    100% {
      transform: translate(150.920607px, -113.028703px);
    }
  }
  #eWaJQDoI8yL14_tr {
    animation: eWaJQDoI8yL14_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL14_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(50deg);
    }
    85% {
      transform: rotate(50deg);
    }
    86.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eWaJQDoI8yL14 {
    animation: eWaJQDoI8yL14_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL14_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    66.25% {
      opacity: 0.7;
    }
    98.75% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.35;
    }
  }
  #eWaJQDoI8yL15_to {
    animation: eWaJQDoI8yL15_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL15_to__to {
    0% {
      transform: translate(96.798427px, 142.237316px);
    }
    55% {
      transform: translate(96.798427px, 142.237316px);
    }
    70% {
      transform: translate(97.111986px, 20.643116px);
    }
    71.25% {
      transform: translate(99.798427px, 14.771297px);
    }
    85% {
      transform: translate(148.833827px, -25.703774px);
    }
    86.25% {
      transform: translate(150.920607px, -33.028703px);
    }
    100% {
      transform: translate(150.920607px, -113.028703px);
    }
  }
  #eWaJQDoI8yL15_tr {
    animation: eWaJQDoI8yL15_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(50deg);
    }
    85% {
      transform: rotate(50deg);
    }
    86.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eWaJQDoI8yL15 {
    animation: eWaJQDoI8yL15_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL15_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    66.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eWaJQDoI8yL19 {
    animation-name: eWaJQDoI8yL19__m, eWaJQDoI8yL19_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eWaJQDoI8yL19__m {
    0% {
      d: path(
        'M161.867,259.879L161.867,253.489998C161.867,253.489998,161.85,251.271299,161.85,251.271299L161.798427,209.396294'
      );
    }
    16.25% {
      d: path(
        'M161.867,259.879L161.867,253.489998C161.867,253.489998,161.85,251.271299,161.85,251.271299L161.798427,209.396294'
      );
    }
    22.5% {
      d: path(
        'M161.867,225.295667L161.869187,171.118661C161.869187,171.118661,161.862812,170.286651,161.862812,170.286651L161.843472,157.916856'
      );
    }
    23.75% {
      d: path(
        'M161.867,218.379L161.869624,154.644394C161.869624,154.644394,161.865374,154.089721,161.865374,154.089721L161.852481,147.620968'
      );
    }
    26.25% {
      d: path(
        'M161.867,215.545667L161.870499,121.695859C161.870499,121.695859,161.870499,121.695861,161.870499,121.695861L161.870499,121.695859'
      );
    }
    38.75% {
      d: path(
        'M161.867318,121.678L161.867318,121.678714C161.867318,121.678714,180.683499,77.67163,180.683499,77.67163L180.683499,77.67163'
      );
    }
    52.5% {
      d: path(
        'M180.683499,77.670918L180.683499,77.671631C180.683499,77.671631,180.683499,77.67163,180.683499,77.67163L180.683499,-3.712681'
      );
    }
    100% {
      d: path(
        'M180.683499,77.670918L180.683499,77.671631C180.683499,77.671631,180.683499,77.67163,180.683499,77.67163L180.683499,-3.712681'
      );
    }
  }
  @keyframes eWaJQDoI8yL19_c_o {
    0% {
      opacity: 0;
    }
    26.25% {
      opacity: 0;
    }
    31.25% {
      opacity: 1;
    }
    48.75% {
      opacity: 1;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eWaJQDoI8yL20_to {
    animation: eWaJQDoI8yL20_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL20_to__to {
    0% {
      transform: translate(226.779751px, 128.661481px);
    }
    11.25% {
      transform: translate(226.779751px, 128.661481px);
    }
    26.25% {
      transform: translate(226.798427px, 22.771297px);
    }
    27.5% {
      transform: translate(229.798427px, 10.771297px);
    }
    38.75% {
      transform: translate(243.801035px, -23.5px);
    }
    40% {
      transform: translate(245.711986px, -32.410292px);
    }
    53.75% {
      transform: translate(245.711986px, -119.410292px);
    }
    100% {
      transform: translate(245.711986px, -119.410292px);
    }
  }
  #eWaJQDoI8yL20_tr {
    animation: eWaJQDoI8yL20_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL20_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(23deg);
    }
    38.75% {
      transform: rotate(23deg);
    }
    40% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eWaJQDoI8yL20 {
    animation: eWaJQDoI8yL20_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL20_c_o {
    0% {
      opacity: 0;
    }
    13.75% {
      opacity: 0;
    }
    22.5% {
      opacity: 0.7;
    }
    53.75% {
      opacity: 0.7;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eWaJQDoI8yL21_to {
    animation: eWaJQDoI8yL21_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL21_to__to {
    0% {
      transform: translate(226.779751px, 128.661481px);
    }
    11.25% {
      transform: translate(226.779751px, 128.661481px);
    }
    26.25% {
      transform: translate(226.798427px, 21.271297px);
    }
    27.5% {
      transform: translate(229.798427px, 10.771297px);
    }
    38.75% {
      transform: translate(243.801035px, -23.5px);
    }
    40% {
      transform: translate(245.711986px, -32.410292px);
    }
    53.75% {
      transform: translate(245.711986px, -119.410292px);
    }
    100% {
      transform: translate(245.711986px, -119.410292px);
    }
  }
  #eWaJQDoI8yL21_tr {
    animation: eWaJQDoI8yL21_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL21_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(23deg);
    }
    38.75% {
      transform: rotate(23deg);
    }
    40% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eWaJQDoI8yL21 {
    animation: eWaJQDoI8yL21_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL21_c_o {
    0% {
      opacity: 0;
    }
    13.75% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    53.75% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eWaJQDoI8yL23 {
    animation-name: eWaJQDoI8yL23__m, eWaJQDoI8yL23_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eWaJQDoI8yL23__m {
    0% {
      d: path(
        'M161.867,259.879L161.867,253.489998C161.867,253.489998,161.85,251.271299,161.85,251.271299L161.798427,209.396294'
      );
    }
    52.5% {
      d: path(
        'M161.867,259.879L161.867,253.489998C161.867,253.489998,161.85,251.271299,161.85,251.271299L161.798427,209.396294'
      );
    }
    58.75% {
      d: path(
        'M161.867,225.295667L161.869187,171.118661C161.869187,171.118661,161.862812,170.286651,161.862812,170.286651L161.843472,157.916856'
      );
    }
    60% {
      d: path(
        'M161.867,218.379L161.869624,154.644394C161.869624,154.644394,161.865374,154.089721,161.865374,154.089721L161.852481,147.620968'
      );
    }
    62.5% {
      d: path(
        'M161.867,215.545667L161.870499,121.695859C161.870499,121.695859,161.870499,121.695861,161.870499,121.695861L161.870499,121.695859'
      );
    }
    75% {
      d: path(
        'M161.867318,121.678L161.867318,121.678714C161.867318,121.678714,180.683499,77.67163,180.683499,77.67163L180.683499,77.67163'
      );
    }
    88.75% {
      d: path(
        'M180.683499,77.670918L180.683499,77.671631C180.683499,77.671631,180.683499,77.67163,180.683499,77.67163L180.683499,-3.712681'
      );
    }
    100% {
      d: path(
        'M180.683499,77.670918L180.683499,77.671631C180.683499,77.671631,180.683499,77.67163,180.683499,77.67163L180.683499,-3.712681'
      );
    }
  }
  @keyframes eWaJQDoI8yL23_c_o {
    0% {
      opacity: 0;
    }
    62.5% {
      opacity: 0;
    }
    67.5% {
      opacity: 1;
    }
    85% {
      opacity: 1;
    }
    93.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eWaJQDoI8yL24_to {
    animation: eWaJQDoI8yL24_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL24_to__to {
    0% {
      transform: translate(226.779751px, 128.661481px);
    }
    47.5% {
      transform: translate(226.779751px, 128.661481px);
    }
    62.5% {
      transform: translate(226.798427px, 22.771297px);
    }
    63.75% {
      transform: translate(229.798427px, 10.771297px);
    }
    75% {
      transform: translate(243.801035px, -23.5px);
    }
    76.25% {
      transform: translate(245.711986px, -32.410292px);
    }
    90% {
      transform: translate(245.711986px, -119.410292px);
    }
    100% {
      transform: translate(245.711986px, -119.410292px);
    }
  }
  #eWaJQDoI8yL24_tr {
    animation: eWaJQDoI8yL24_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL24_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    62.5% {
      transform: rotate(0deg);
    }
    63.75% {
      transform: rotate(23deg);
    }
    75% {
      transform: rotate(23deg);
    }
    76.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eWaJQDoI8yL24 {
    animation: eWaJQDoI8yL24_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL24_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    58.75% {
      opacity: 0.7;
    }
    90% {
      opacity: 0.7;
    }
    91.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eWaJQDoI8yL25_to {
    animation: eWaJQDoI8yL25_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL25_to__to {
    0% {
      transform: translate(226.779751px, 128.661481px);
    }
    47.5% {
      transform: translate(226.779751px, 128.661481px);
    }
    62.5% {
      transform: translate(226.798427px, 21.271297px);
    }
    63.75% {
      transform: translate(229.798427px, 10.771297px);
    }
    75% {
      transform: translate(243.801035px, -23.5px);
    }
    76.25% {
      transform: translate(245.711986px, -32.410292px);
    }
    90% {
      transform: translate(245.711986px, -119.410292px);
    }
    100% {
      transform: translate(245.711986px, -119.410292px);
    }
  }
  #eWaJQDoI8yL25_tr {
    animation: eWaJQDoI8yL25_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL25_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    62.5% {
      transform: rotate(0deg);
    }
    63.75% {
      transform: rotate(23deg);
    }
    75% {
      transform: rotate(23deg);
    }
    76.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eWaJQDoI8yL25 {
    animation: eWaJQDoI8yL25_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL25_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    91.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eWaJQDoI8yL29 {
    animation-name: eWaJQDoI8yL29__m, eWaJQDoI8yL29_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eWaJQDoI8yL29__m {
    0% {
      d: path('M278.994002,286.5213L278.994,225.053002L279.1,225.053004L279.029001,225.053002');
    }
    11.25% {
      d: path('M278.994002,286.5213L278.994,225.053002L279.1,225.053004L279.029001,225.053002');
    }
    18.75% {
      d: path('M278.994002,206.5213L278.994,145.053002L279.1,145.053004L279.029001,145.053002');
    }
    53.75% {
      d: path('M278.994002,65.5213L278.994,-23.946998L279.1,-23.946996L279.029001,-23.946998');
    }
    100% {
      d: path('M278.994002,65.5213L278.994,-23.946998L279.1,-23.946996L279.029001,-23.946998');
    }
  }
  @keyframes eWaJQDoI8yL29_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    56.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eWaJQDoI8yL30_to {
    animation: eWaJQDoI8yL30_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL30_to__to {
    0% {
      transform: translate(344.25px, 128.271297px);
    }
    11.25% {
      transform: translate(344.25px, 128.271297px);
    }
    18.75% {
      transform: translate(344.25px, 52.271297px);
    }
    53.75% {
      transform: translate(344.25px, -117.728703px);
    }
    100% {
      transform: translate(344.25px, -117.728703px);
    }
  }
  #eWaJQDoI8yL30 {
    animation: eWaJQDoI8yL30_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL30_c_o {
    0% {
      opacity: 0;
    }
    13.75% {
      opacity: 0;
    }
    18.75% {
      opacity: 0.7;
    }
    53.75% {
      opacity: 0.7;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eWaJQDoI8yL31_to {
    animation: eWaJQDoI8yL31_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL31_to__to {
    0% {
      transform: translate(344.25px, 128.271297px);
    }
    11.25% {
      transform: translate(344.25px, 128.271297px);
    }
    18.75% {
      transform: translate(344.25px, 52.271297px);
    }
    53.75% {
      transform: translate(344.25px, -117.728703px);
    }
    100% {
      transform: translate(344.25px, -117.728703px);
    }
  }
  #eWaJQDoI8yL31 {
    animation: eWaJQDoI8yL31_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL31_c_o {
    0% {
      opacity: 0;
    }
    13.75% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    53.75% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eWaJQDoI8yL33 {
    animation-name: eWaJQDoI8yL33__m, eWaJQDoI8yL33_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eWaJQDoI8yL33__m {
    0% {
      d: path('M278.994002,286.5213L278.994,225.053002L279.1,225.053004L279.029001,225.053002');
    }
    55% {
      d: path('M278.994002,286.5213L278.994,225.053002L279.1,225.053004L279.029001,225.053002');
    }
    62.5% {
      d: path('M278.994002,206.5213L278.994,145.053002L279.1,145.053004L279.029001,145.053002');
    }
    97.5% {
      d: path('M278.994002,65.5213L278.994,-23.946998L279.1,-23.946996L279.029001,-23.946998');
    }
    100% {
      d: path('M278.994002,65.5213L278.994,-23.946998L279.1,-23.946996L279.029001,-23.946998');
    }
  }
  @keyframes eWaJQDoI8yL33_c_o {
    0% {
      opacity: 0;
    }
    61.25% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    93.75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #eWaJQDoI8yL34_to {
    animation: eWaJQDoI8yL34_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL34_to__to {
    0% {
      transform: translate(344.25px, 128.271297px);
    }
    55% {
      transform: translate(344.25px, 128.271297px);
    }
    62.5% {
      transform: translate(344.25px, 52.271297px);
    }
    97.5% {
      transform: translate(344.25px, -117.728703px);
    }
    100% {
      transform: translate(344.25px, -117.728703px);
    }
  }
  #eWaJQDoI8yL34 {
    animation: eWaJQDoI8yL34_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL34_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    62.5% {
      opacity: 0.7;
    }
    97.5% {
      opacity: 0.7;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eWaJQDoI8yL35_to {
    animation: eWaJQDoI8yL35_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL35_to__to {
    0% {
      transform: translate(344.25px, 128.271297px);
    }
    55% {
      transform: translate(344.25px, 128.271297px);
    }
    62.5% {
      transform: translate(344.25px, 52.271297px);
    }
    97.5% {
      transform: translate(344.25px, -117.728703px);
    }
    100% {
      transform: translate(344.25px, -117.728703px);
    }
  }
  #eWaJQDoI8yL35 {
    animation: eWaJQDoI8yL35_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL35_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    62.5% {
      opacity: 1;
    }
    97.5% {
      opacity: 1;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eWaJQDoI8yL39 {
    animation-name: eWaJQDoI8yL39__m, eWaJQDoI8yL39_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eWaJQDoI8yL39__m {
    0% {
      d: path(
        'M161.867,259.879L161.867,249.396294C161.867,249.396294,161.85,249.396294,161.85,249.396294L162.798427,248.396294'
      );
    }
    16.25% {
      d: path(
        'M161.867,217.545667L161.870499,121.695859C161.870499,121.695859,161.870499,121.695861,161.870499,121.695861L162.315485,120.695859'
      );
    }
    28.75% {
      d: path(
        'M161.867318,161.678L161.867318,121.678714C161.867318,121.678714,180.683499,77.67163,180.683499,77.67163L180.683499,76.67163'
      );
    }
    42.5% {
      d: path(
        'M161.870499,121.678L180.683499,77.671631C180.683499,77.671631,180.683499,77.67163,180.683499,77.67163L180.683499,-3.712681'
      );
    }
    100% {
      d: path(
        'M161.870499,121.678L180.683499,77.671631C180.683499,77.671631,180.683499,77.67163,180.683499,77.67163L180.683499,-3.712681'
      );
    }
  }
  @keyframes eWaJQDoI8yL39_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    46.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eWaJQDoI8yL40_to {
    animation: eWaJQDoI8yL40_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL40_to__to {
    0% {
      transform: translate(226.779751px, 128.661481px);
    }
    1.25% {
      transform: translate(226.779751px, 128.661481px);
    }
    16.25% {
      transform: translate(226.798427px, 19.521297px);
    }
    17.5% {
      transform: translate(229.798427px, 10.771297px);
    }
    28.75% {
      transform: translate(244.5px, -24.5px);
    }
    30% {
      transform: translate(245.711986px, -32.410292px);
    }
    36.25% {
      transform: translate(245.711986px, -66.542035px);
    }
    43.75% {
      transform: translate(245.711986px, -119.410292px);
    }
    100% {
      transform: translate(245.711986px, -119.410292px);
    }
  }
  #eWaJQDoI8yL40_tr {
    animation: eWaJQDoI8yL40_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL40_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    16.25% {
      transform: rotate(0deg);
    }
    17.5% {
      transform: rotate(23deg);
    }
    28.75% {
      transform: rotate(23deg);
    }
    30% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eWaJQDoI8yL40 {
    animation: eWaJQDoI8yL40_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL40_c_o {
    0% {
      opacity: 0;
    }
    3.75% {
      opacity: 0;
    }
    10% {
      opacity: 0.7;
    }
    43.75% {
      opacity: 0.7;
    }
    45% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eWaJQDoI8yL41_to {
    animation: eWaJQDoI8yL41_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL41_to__to {
    0% {
      transform: translate(226.779751px, 128.661481px);
    }
    1.25% {
      transform: translate(226.779751px, 128.661481px);
    }
    16.25% {
      transform: translate(226.798427px, 19.521297px);
    }
    17.5% {
      transform: translate(229.798427px, 10.771297px);
    }
    28.75% {
      transform: translate(244.5px, -24.5px);
    }
    30% {
      transform: translate(245.711986px, -32.410292px);
    }
    36.25% {
      transform: translate(245.711986px, -66.542035px);
    }
    43.75% {
      transform: translate(245.711986px, -119.410292px);
    }
    100% {
      transform: translate(245.711986px, -119.410292px);
    }
  }
  #eWaJQDoI8yL41_tr {
    animation: eWaJQDoI8yL41_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL41_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    16.25% {
      transform: rotate(0deg);
    }
    17.5% {
      transform: rotate(23deg);
    }
    28.75% {
      transform: rotate(23deg);
    }
    30% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eWaJQDoI8yL41 {
    animation: eWaJQDoI8yL41_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL41_c_o {
    0% {
      opacity: 0;
    }
    3.75% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    43.75% {
      opacity: 1;
    }
    45% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eWaJQDoI8yL43 {
    animation-name: eWaJQDoI8yL43__m, eWaJQDoI8yL43_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eWaJQDoI8yL43__m {
    0% {
      d: path(
        'M161.867,259.879L161.867,249.396294C161.867,249.396294,161.85,249.396294,161.85,249.396294L162.798427,248.396294'
      );
    }
    53.75% {
      d: path(
        'M161.867,259.879L161.867,249.396294C161.867,249.396294,161.85,249.396294,161.85,249.396294L162.798427,248.396294'
      );
    }
    70% {
      d: path(
        'M161.867,217.545667L161.870499,121.695859C161.870499,121.695859,161.870499,121.695861,161.870499,121.695861L162.315485,120.695859'
      );
    }
    82.5% {
      d: path(
        'M161.867318,161.678L161.867318,121.678714C161.867318,121.678714,180.683499,77.67163,180.683499,77.67163L180.683499,76.67163'
      );
    }
    96.25% {
      d: path(
        'M161.870499,121.678L180.683499,77.671631C180.683499,77.671631,180.683499,77.67163,180.683499,77.67163L180.683499,-3.712681'
      );
    }
    100% {
      d: path(
        'M161.870499,121.678L180.683499,77.671631C180.683499,77.671631,180.683499,77.67163,180.683499,77.67163L180.683499,-3.712681'
      );
    }
  }
  @keyframes eWaJQDoI8yL43_c_o {
    0% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    76.25% {
      opacity: 1;
    }
    93.75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #eWaJQDoI8yL44_to {
    animation: eWaJQDoI8yL44_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL44_to__to {
    0% {
      transform: translate(226.779751px, 128.661481px);
    }
    55% {
      transform: translate(226.779751px, 128.661481px);
    }
    70% {
      transform: translate(226.798427px, 19.521297px);
    }
    71.25% {
      transform: translate(229.798427px, 10.771297px);
    }
    82.5% {
      transform: translate(244.5px, -24.5px);
    }
    83.75% {
      transform: translate(245.711986px, -32.410292px);
    }
    90% {
      transform: translate(245.711986px, -66.542035px);
    }
    97.5% {
      transform: translate(245.711986px, -119.410292px);
    }
    100% {
      transform: translate(245.711986px, -119.410292px);
    }
  }
  #eWaJQDoI8yL44_tr {
    animation: eWaJQDoI8yL44_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL44_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(23deg);
    }
    82.5% {
      transform: rotate(23deg);
    }
    83.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eWaJQDoI8yL44 {
    animation: eWaJQDoI8yL44_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL44_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    63.75% {
      opacity: 0.7;
    }
    97.5% {
      opacity: 0.7;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eWaJQDoI8yL45_to {
    animation: eWaJQDoI8yL45_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL45_to__to {
    0% {
      transform: translate(226.779751px, 128.661481px);
    }
    55% {
      transform: translate(226.779751px, 128.661481px);
    }
    70% {
      transform: translate(226.798427px, 19.521297px);
    }
    71.25% {
      transform: translate(229.798427px, 10.771297px);
    }
    82.5% {
      transform: translate(244.5px, -24.5px);
    }
    83.75% {
      transform: translate(245.711986px, -32.410292px);
    }
    90% {
      transform: translate(245.711986px, -66.542035px);
    }
    97.5% {
      transform: translate(245.711986px, -119.410292px);
    }
    100% {
      transform: translate(245.711986px, -119.410292px);
    }
  }
  #eWaJQDoI8yL45_tr {
    animation: eWaJQDoI8yL45_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL45_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(23deg);
    }
    82.5% {
      transform: rotate(23deg);
    }
    83.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eWaJQDoI8yL45 {
    animation: eWaJQDoI8yL45_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL45_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    63.75% {
      opacity: 1;
    }
    97.5% {
      opacity: 1;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eWaJQDoI8yL49 {
    animation-name: eWaJQDoI8yL49__m, eWaJQDoI8yL49_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eWaJQDoI8yL49__m {
    0% {
      d: path('M32.0957,321.564875L32.0957,231.828536L32.101049,231.870431L32.101049,231.876118');
    }
    15% {
      d: path('M32.0957,321.564875L32.0957,231.828536L32.101049,231.870431L32.101049,231.876118');
    }
    18.75% {
      d: path('M32.0957,294.064875L32.0957,224.328536L32.101049,224.370431L32.101049,224.376118');
    }
    30% {
      d: path('M32.0957,211.564875L32.0957,121.828536L32.101049,121.870431L32.101049,121.876118');
    }
    33.75% {
      d: path('M32.0957,211.564875L32.0957,121.828536L41.860306,113.878942L41.860306,113.884629');
    }
    45% {
      d: path('M32.0957,129.146811L32.0957,121.678L85.801035,77.955237L85.801035,77.954434');
    }
    60% {
      d: path('M86.161435,77.676826L86.161435,77.676826L86.1598,77.676826L86.1598,-5.543385');
    }
    100% {
      d: path('M86.161435,77.676826L86.161435,77.676826L86.1598,77.676826L86.1598,-5.543385');
    }
  }
  @keyframes eWaJQDoI8yL49_c_o {
    0% {
      opacity: 0;
    }
    13.75% {
      opacity: 0;
    }
    30% {
      opacity: 0;
    }
    33.75% {
      opacity: 1;
    }
    56.25% {
      opacity: 1;
    }
    61.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eWaJQDoI8yL50_to {
    animation: eWaJQDoI8yL50_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL50_to__to {
    0% {
      transform: translate(96.798427px, 143.537316px);
    }
    15% {
      transform: translate(96.798427px, 143.537316px);
    }
    30% {
      transform: translate(97.111986px, 20.771297px);
    }
    31.25% {
      transform: translate(99.798427px, 14.771297px);
    }
    45% {
      transform: translate(148.801035px, -25.679029px);
    }
    46.25% {
      transform: translate(150.920607px, -33.028703px);
    }
    60% {
      transform: translate(150.920607px, -113.028703px);
    }
    100% {
      transform: translate(150.920607px, -113.028703px);
    }
  }
  #eWaJQDoI8yL50_tr {
    animation: eWaJQDoI8yL50_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL50_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    31.25% {
      transform: rotate(50deg);
    }
    45% {
      transform: rotate(50deg);
    }
    46.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eWaJQDoI8yL50 {
    animation: eWaJQDoI8yL50_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL50_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    23.75% {
      opacity: 0.7;
    }
    60% {
      opacity: 0.7;
    }
    61.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eWaJQDoI8yL51_to {
    animation: eWaJQDoI8yL51_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL51_to__to {
    0% {
      transform: translate(96.798427px, 142.237316px);
    }
    15% {
      transform: translate(96.798427px, 142.237316px);
    }
    30% {
      transform: translate(97.111986px, 20.643116px);
    }
    31.25% {
      transform: translate(99.798427px, 14.771297px);
    }
    45% {
      transform: translate(148.833827px, -25.703774px);
    }
    46.25% {
      transform: translate(150.920607px, -33.028703px);
    }
    60% {
      transform: translate(150.920607px, -113.028703px);
    }
    100% {
      transform: translate(150.920607px, -113.028703px);
    }
  }
  #eWaJQDoI8yL51_tr {
    animation: eWaJQDoI8yL51_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL51_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    31.25% {
      transform: rotate(50deg);
    }
    45% {
      transform: rotate(50deg);
    }
    46.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eWaJQDoI8yL51 {
    animation: eWaJQDoI8yL51_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL51_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    23.75% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    61.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eWaJQDoI8yL53 {
    animation-name: eWaJQDoI8yL53__m, eWaJQDoI8yL53_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eWaJQDoI8yL53__m {
    0% {
      d: path('M32.0957,321.564875L32.0957,231.828536L32.101049,231.870431L32.101049,231.876118');
    }
    53.75% {
      d: path('M32.0957,321.564875L32.0957,231.828536L32.101049,231.870431L32.101049,231.876118');
    }
    57.5% {
      d: path('M32.0957,294.064875L32.0957,224.328536L32.101049,224.370431L32.101049,224.376118');
    }
    68.75% {
      d: path('M32.0957,211.564875L32.0957,121.828536L32.101049,121.870431L32.101049,121.876118');
    }
    72.5% {
      d: path('M32.0957,211.564875L32.0957,121.828536L41.860306,113.878942L41.860306,113.884629');
    }
    83.75% {
      d: path('M32.0957,129.146811L32.0957,121.678L85.801035,77.955237L85.801035,77.954434');
    }
    98.75% {
      d: path('M86.161435,77.676826L86.161435,77.676826L86.1598,77.676826L86.1598,-5.543385');
    }
    100% {
      d: path('M86.161435,77.676826L86.161435,77.676826L86.1598,77.676826L86.1598,-5.543385');
    }
  }
  @keyframes eWaJQDoI8yL53_c_o {
    0% {
      opacity: 0;
    }
    52.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 0;
    }
    72.5% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #eWaJQDoI8yL54_to {
    animation: eWaJQDoI8yL54_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL54_to__to {
    0% {
      transform: translate(96.798427px, 143.537316px);
    }
    53.75% {
      transform: translate(96.798427px, 143.537316px);
    }
    68.75% {
      transform: translate(97.111986px, 20.771297px);
    }
    70% {
      transform: translate(99.798427px, 14.771297px);
    }
    83.75% {
      transform: translate(148.801035px, -25.679029px);
    }
    85% {
      transform: translate(150.920607px, -33.028703px);
    }
    98.75% {
      transform: translate(150.920607px, -113.028703px);
    }
    100% {
      transform: translate(150.920607px, -113.028703px);
    }
  }
  #eWaJQDoI8yL54_tr {
    animation: eWaJQDoI8yL54_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL54_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(50deg);
    }
    83.75% {
      transform: rotate(50deg);
    }
    85% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eWaJQDoI8yL54 {
    animation: eWaJQDoI8yL54_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL54_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    63.75% {
      opacity: 0.7;
    }
    98.75% {
      opacity: 0.7;
    }
    100% {
      opacity: 0;
    }
  }
  #eWaJQDoI8yL55_to {
    animation: eWaJQDoI8yL55_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL55_to__to {
    0% {
      transform: translate(96.798427px, 142.237316px);
    }
    53.75% {
      transform: translate(96.798427px, 142.237316px);
    }
    68.75% {
      transform: translate(97.111986px, 20.643116px);
    }
    70% {
      transform: translate(99.798427px, 14.771297px);
    }
    83.75% {
      transform: translate(148.833827px, -25.703774px);
    }
    85% {
      transform: translate(150.920607px, -33.028703px);
    }
    98.75% {
      transform: translate(150.920607px, -113.028703px);
    }
    100% {
      transform: translate(150.920607px, -113.028703px);
    }
  }
  #eWaJQDoI8yL55_tr {
    animation: eWaJQDoI8yL55_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL55_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(50deg);
    }
    83.75% {
      transform: rotate(50deg);
    }
    85% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eWaJQDoI8yL55 {
    animation: eWaJQDoI8yL55_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eWaJQDoI8yL55_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    63.75% {
      opacity: 1;
    }
    98.75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesMobileBg5;
