// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesBg7 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)', // Replace all <stop id="...-stroke-1" stopColor="#initial-color" /> with this value
  };

  return (
    <AnimatedSvg
      id="eqepmVhE4Cb1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 885 228"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="885"
      height="228"
      className="source-background"
      {...props}
    >
      <defs>
        <filter id="eqepmVhE4Cb8-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb8-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eqepmVhE4Cb8-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb8-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eqepmVhE4Cb8-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb8-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb8-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb8-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eqepmVhE4Cb9-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eqepmVhE4Cb9-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eqepmVhE4Cb9-stroke-1"
            offset="49%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eqepmVhE4Cb9-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eqepmVhE4Cb10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb10-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eqepmVhE4Cb10-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb10-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eqepmVhE4Cb10-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb10-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb10-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb10-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eqepmVhE4Cb10-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eqepmVhE4Cb12-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb12-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eqepmVhE4Cb12-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb12-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eqepmVhE4Cb12-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb12-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb12-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb12-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eqepmVhE4Cb13-stroke"
          x1="-8820.42982"
          y1="0.072397"
          x2="-8820.42982"
          y2="0.78955"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eqepmVhE4Cb13-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eqepmVhE4Cb13-stroke-1"
            offset="50%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eqepmVhE4Cb13-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eqepmVhE4Cb14-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb14-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eqepmVhE4Cb14-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb14-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eqepmVhE4Cb14-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb14-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb14-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb14-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eqepmVhE4Cb14-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eqepmVhE4Cb16-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb16-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eqepmVhE4Cb16-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb16-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eqepmVhE4Cb16-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb16-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb16-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb16-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eqepmVhE4Cb17-stroke"
          x1="0.178606"
          y1="0.116978"
          x2="0.821394"
          y2="0.883022"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eqepmVhE4Cb17-stroke-0"
            offset="0%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eqepmVhE4Cb17-stroke-1"
            offset="52%"
            stopColor={selected === 6 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eqepmVhE4Cb17-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eqepmVhE4Cb18-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb18-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eqepmVhE4Cb18-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb18-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eqepmVhE4Cb18-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb18-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb18-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb18-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eqepmVhE4Cb18-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eqepmVhE4Cb23-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb23-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eqepmVhE4Cb23-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb23-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eqepmVhE4Cb23-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb23-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb23-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb23-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eqepmVhE4Cb24-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eqepmVhE4Cb24-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eqepmVhE4Cb24-stroke-1"
            offset="51%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eqepmVhE4Cb24-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eqepmVhE4Cb25-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb25-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eqepmVhE4Cb25-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb25-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eqepmVhE4Cb25-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb25-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb25-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb25-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eqepmVhE4Cb25-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eqepmVhE4Cb27-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb27-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eqepmVhE4Cb27-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb27-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eqepmVhE4Cb27-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb27-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb27-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb27-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eqepmVhE4Cb28-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eqepmVhE4Cb28-stroke-0"
            offset="0%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eqepmVhE4Cb28-stroke-1"
            offset="50%"
            stopColor={selected === 5 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eqepmVhE4Cb28-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eqepmVhE4Cb29-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb29-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eqepmVhE4Cb29-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb29-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eqepmVhE4Cb29-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb29-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb29-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb29-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eqepmVhE4Cb29-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eqepmVhE4Cb35-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb35-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eqepmVhE4Cb35-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb35-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eqepmVhE4Cb35-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb35-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb35-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb35-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eqepmVhE4Cb36-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="matrix(0 0.691091 -0.691091 0 3969.9 0.262042)"
        >
          <stop
            id="eqepmVhE4Cb36-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eqepmVhE4Cb36-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eqepmVhE4Cb37-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb37-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eqepmVhE4Cb37-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb37-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eqepmVhE4Cb37-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb37-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb37-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb37-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eqepmVhE4Cb37-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eqepmVhE4Cb39-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb39-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eqepmVhE4Cb39-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb39-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eqepmVhE4Cb39-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb39-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb39-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb39-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eqepmVhE4Cb40-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="matrix(0 0.798912 -0.798912 0 26.967315 0.107811)"
        >
          <stop
            id="eqepmVhE4Cb40-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eqepmVhE4Cb40-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eqepmVhE4Cb41-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb41-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eqepmVhE4Cb41-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb41-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eqepmVhE4Cb41-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb41-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb41-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb41-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eqepmVhE4Cb41-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eqepmVhE4Cb43-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb43-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eqepmVhE4Cb43-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb43-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eqepmVhE4Cb43-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb43-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb43-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb43-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eqepmVhE4Cb44-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eqepmVhE4Cb44-stroke-0"
            offset="0%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eqepmVhE4Cb44-stroke-1"
            offset="53%"
            stopColor={selected === 4 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eqepmVhE4Cb44-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eqepmVhE4Cb45-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb45-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eqepmVhE4Cb45-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb45-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eqepmVhE4Cb45-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb45-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb45-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb45-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eqepmVhE4Cb45-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eqepmVhE4Cb50-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb50-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eqepmVhE4Cb50-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb50-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eqepmVhE4Cb50-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb50-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb50-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb50-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eqepmVhE4Cb51-stroke"
          x1="27.043368"
          y1="0.061949"
          x2="27.043368"
          y2="0.432841"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eqepmVhE4Cb51-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eqepmVhE4Cb51-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eqepmVhE4Cb52-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb52-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eqepmVhE4Cb52-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb52-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eqepmVhE4Cb52-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb52-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb52-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb52-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eqepmVhE4Cb52-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eqepmVhE4Cb54-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb54-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eqepmVhE4Cb54-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb54-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eqepmVhE4Cb54-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb54-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb54-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb54-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eqepmVhE4Cb55-stroke"
          x1="0.883022"
          y1="0.178606"
          x2="0.116978"
          y2="0.821394"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eqepmVhE4Cb55-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eqepmVhE4Cb55-stroke-1"
            offset="54%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eqepmVhE4Cb55-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eqepmVhE4Cb56-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb56-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eqepmVhE4Cb56-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb56-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eqepmVhE4Cb56-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb56-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb56-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb56-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eqepmVhE4Cb56-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eqepmVhE4Cb62-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb62-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eqepmVhE4Cb62-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb62-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eqepmVhE4Cb62-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb62-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb62-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb62-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eqepmVhE4Cb63-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="matrix(-0.898209 0.6777 -0.6777 -0.898209 1.030913 0.286823)"
        >
          <stop
            id="eqepmVhE4Cb63-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eqepmVhE4Cb63-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eqepmVhE4Cb64-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb64-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eqepmVhE4Cb64-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb64-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eqepmVhE4Cb64-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb64-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb64-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb64-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eqepmVhE4Cb64-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eqepmVhE4Cb66-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb66-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eqepmVhE4Cb66-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb66-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eqepmVhE4Cb66-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb66-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb66-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb66-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eqepmVhE4Cb67-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="matrix(0 0.774676 -0.774676 0 12.911949 0.088849)"
        >
          <stop
            id="eqepmVhE4Cb67-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eqepmVhE4Cb67-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eqepmVhE4Cb68-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb68-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eqepmVhE4Cb68-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb68-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eqepmVhE4Cb68-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb68-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb68-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb68-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eqepmVhE4Cb68-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eqepmVhE4Cb70-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb70-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eqepmVhE4Cb70-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb70-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eqepmVhE4Cb70-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb70-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb70-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb70-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eqepmVhE4Cb71-stroke"
          x1="1.979184"
          y1="0.087022"
          x2="1.979184"
          y2="0.652977"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eqepmVhE4Cb71-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eqepmVhE4Cb71-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eqepmVhE4Cb72-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb72-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eqepmVhE4Cb72-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb72-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eqepmVhE4Cb72-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb72-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb72-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb72-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eqepmVhE4Cb72-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eqepmVhE4Cb77-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb77-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eqepmVhE4Cb77-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb77-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eqepmVhE4Cb77-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb77-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb77-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb77-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eqepmVhE4Cb78-stroke"
          x1="0.983926"
          y1="0.296643"
          x2="0.224927"
          y2="1.220063"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eqepmVhE4Cb78-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eqepmVhE4Cb78-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eqepmVhE4Cb79-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb79-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eqepmVhE4Cb79-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb79-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eqepmVhE4Cb79-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb79-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb79-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb79-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eqepmVhE4Cb79-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eqepmVhE4Cb81-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb81-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eqepmVhE4Cb81-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb81-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eqepmVhE4Cb81-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb81-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb81-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb81-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eqepmVhE4Cb82-stroke"
          x1="0.821394"
          y1="0.116978"
          x2="0.021396"
          y2="1.049288"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eqepmVhE4Cb82-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eqepmVhE4Cb82-stroke-1"
            offset="53%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eqepmVhE4Cb82-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eqepmVhE4Cb83-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb83-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eqepmVhE4Cb83-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb83-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eqepmVhE4Cb83-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb83-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb83-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb83-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eqepmVhE4Cb83-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eqepmVhE4Cb89-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb89-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eqepmVhE4Cb89-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb89-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eqepmVhE4Cb89-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb89-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb89-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb89-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eqepmVhE4Cb90-stroke"
          x1="0.089955"
          y1="0.607313"
          x2="0.93287"
          y2="-0.05004"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eqepmVhE4Cb90-stroke-0" offset="0%" stopColor="rgba(126,166,255,0)" />
          <stop
            id="eqepmVhE4Cb90-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eqepmVhE4Cb91-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb91-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eqepmVhE4Cb91-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb91-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eqepmVhE4Cb91-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb91-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb91-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb91-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eqepmVhE4Cb91-filter-blur-0" stdDeviation="3,3" result="result" />
        </filter>
        <filter id="eqepmVhE4Cb92-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb92-filter-blur-0" stdDeviation="0,0" result="result" />
        </filter>
        <filter id="eqepmVhE4Cb93-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb93-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eqepmVhE4Cb93-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb93-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eqepmVhE4Cb93-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb93-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb93-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb93-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eqepmVhE4Cb94-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="matrix(0 0.59598 -0.59598 0 5218.102477 0.03756)"
        >
          <stop
            id="eqepmVhE4Cb94-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eqepmVhE4Cb94-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eqepmVhE4Cb95-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eqepmVhE4Cb95-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eqepmVhE4Cb95-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eqepmVhE4Cb95-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eqepmVhE4Cb95-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eqepmVhE4Cb95-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eqepmVhE4Cb95-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eqepmVhE4Cb95-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eqepmVhE4Cb95-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
      </defs>
      <g transform="translate(0-1.25894)" mask="url(#eqepmVhE4Cb97)">
        <g transform="translate(.000001 0)">
          <g transform="translate(.088013 1.012683)">
            <g>
              <path
                d="M853.309,232.641v-111.14137L665.296,76.728v-73.62253"
                transform="matrix(.993835 0 0 1.020627 4.6811-2.431371)"
                fill="none"
                stroke="#6069ca"
              />
              <g transform="translate(-65 105.000001)" filter="url(#eqepmVhE4Cb8-filter)">
                <path
                  id="eqepmVhE4Cb9"
                  d="M853.309,172.852398v-51.352768l-88.567326-21.284376-.520849-.000001"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  fill="none"
                  stroke="url(#eqepmVhE4Cb9-stroke)"
                  strokeLinecap="round"
                />
                <g id="eqepmVhE4Cb10_to" transform="translate(828.317602,-5.431736)">
                  <g id="eqepmVhE4Cb10_tr" transform="rotate(-75)">
                    <rect
                      id="eqepmVhE4Cb10"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0.7"
                      filter="url(#eqepmVhE4Cb10-filter)"
                      fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eqepmVhE4Cb11_to" transform="translate(828.317602,-5.431736)">
                  <g id="eqepmVhE4Cb11_tr" transform="rotate(-75)">
                    <rect
                      id="eqepmVhE4Cb11"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eqepmVhE4Cb12-filter)">
                <path
                  id="eqepmVhE4Cb13"
                  d="M853.309,252.2368v-9.24321-1.766709-3.184316"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eqepmVhE4Cb13-stroke)"
                  strokeLinecap="round"
                />
                <g id="eqepmVhE4Cb14_to" transform="translate(917.8,138.546297)">
                  <g id="eqepmVhE4Cb14_tr" transform="rotate(0)">
                    <rect
                      id="eqepmVhE4Cb14"
                      width="8.235294"
                      height="14.117647"
                      rx="0"
                      ry="0"
                      transform="scale(0.85,0.85) translate(-4.117647,-7.058824)"
                      opacity="0"
                      filter="url(#eqepmVhE4Cb14-filter)"
                      fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eqepmVhE4Cb15_to" transform="translate(917.8,138.546297)">
                  <g id="eqepmVhE4Cb15_tr" transform="rotate(0)">
                    <rect
                      id="eqepmVhE4Cb15"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eqepmVhE4Cb16-filter)">
                <path
                  id="eqepmVhE4Cb17"
                  d="M853.309,252.2368v-9.24321-1.766709-3.184316"
                  transform="matrix(.993835 0 0 1.020627 69.6811-107.431372)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eqepmVhE4Cb17-stroke)"
                  strokeLinecap="round"
                />
                <g id="eqepmVhE4Cb18_to" transform="translate(917.8,140.146297)">
                  <g id="eqepmVhE4Cb18_tr" transform="rotate(0)">
                    <rect
                      id="eqepmVhE4Cb18"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eqepmVhE4Cb18-filter)"
                      fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eqepmVhE4Cb19_to" transform="translate(917.8,140.146297)">
                  <g id="eqepmVhE4Cb19_tr" transform="rotate(0)">
                    <rect
                      id="eqepmVhE4Cb19"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 6 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g mask="url(#eqepmVhE4Cb31)">
              <g>
                <path d="M650.977,138.082v-16.4L554.656,76.7305l-.004-76.414006" fill="none" stroke="#6069ca" />
                <g transform="translate(-65 105.000001)" filter="url(#eqepmVhE4Cb23-filter)">
                  <path
                    id="eqepmVhE4Cb24"
                    d="M650.977,158.082v-3.4l.052.000001v-2.410703"
                    transform="translate(65-105.000001)"
                    fill="none"
                    stroke="url(#eqepmVhE4Cb24-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eqepmVhE4Cb25_to" transform="translate(715.8,52.146297)">
                    <g id="eqepmVhE4Cb25_tr" transform="rotate(0)">
                      <rect
                        id="eqepmVhE4Cb25"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0.7"
                        filter="url(#eqepmVhE4Cb25-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eqepmVhE4Cb26_to" transform="translate(715.8,52.146297)">
                    <g id="eqepmVhE4Cb26_tr" transform="rotate(0)">
                      <rect
                        id="eqepmVhE4Cb26"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eqepmVhE4Cb27-filter)">
                  <path
                    id="eqepmVhE4Cb28"
                    d="M650.977,158.082v-3.4l.052.000001v-2.410703"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eqepmVhE4Cb28-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eqepmVhE4Cb29_to" transform="translate(715.298427,50.146297)">
                    <g id="eqepmVhE4Cb29_tr" transform="rotate(0)">
                      <rect
                        id="eqepmVhE4Cb29"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0"
                        filter="url(#eqepmVhE4Cb29-filter)"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eqepmVhE4Cb30_to" transform="translate(715.298427,50.146297)">
                    <g id="eqepmVhE4Cb30_tr" transform="rotate(0)">
                      <rect
                        id="eqepmVhE4Cb30"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 5 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="eqepmVhE4Cb31" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="140"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 535.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g>
              <path d="M520.668,228.079v-106.398L480.969,76.7549l.005-76.508643" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#eqepmVhE4Cb35-filter)">
                <path
                  id="eqepmVhE4Cb36"
                  d="M520.668,121.038711v.642289L480.969,76.7549l.005-4.679761"
                  transform="translate(65-105.000001)"
                  fill="none"
                  stroke="url(#eqepmVhE4Cb36-stroke)"
                  strokeLinecap="round"
                />
                <g id="eqepmVhE4Cb37_to" transform="translate(546.2,-36.480793)">
                  <rect
                    id="eqepmVhE4Cb37"
                    width="8.75"
                    height="15"
                    rx="0"
                    ry="0"
                    transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                    opacity="0.7"
                    filter="url(#eqepmVhE4Cb37-filter)"
                    fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eqepmVhE4Cb38_to" transform="translate(546.2,-36.480793)">
                  <rect
                    id="eqepmVhE4Cb38"
                    width="10"
                    height="20"
                    rx="0"
                    ry="0"
                    transform="scale(0.5,0.5) translate(-5,-10)"
                    fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eqepmVhE4Cb39-filter)">
                <path
                  id="eqepmVhE4Cb40"
                  d="M520.668,243.079v-12.777975-.741319-1.788408"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eqepmVhE4Cb40-stroke)"
                  strokeLinecap="round"
                />
                <g id="eqepmVhE4Cb41_to" transform="translate(585.5,129.646297)">
                  <g id="eqepmVhE4Cb41_tr" transform="rotate(0)">
                    <rect
                      id="eqepmVhE4Cb41"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#eqepmVhE4Cb41-filter)"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eqepmVhE4Cb42_to" transform="translate(585.5,129.646297)">
                  <g id="eqepmVhE4Cb42_tr" transform="rotate(0)">
                    <rect
                      id="eqepmVhE4Cb42"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eqepmVhE4Cb43-filter)">
                <path
                  id="eqepmVhE4Cb44"
                  d="M520.668,233.079v-2.777975-.741319-1.788408"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eqepmVhE4Cb44-stroke)"
                  strokeLinecap="round"
                />
                <g id="eqepmVhE4Cb45_to" transform="translate(585.298427,127.771297)">
                  <g id="eqepmVhE4Cb45_tr" transform="rotate(0)">
                    <rect
                      id="eqepmVhE4Cb45"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eqepmVhE4Cb45-filter)"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eqepmVhE4Cb46_to" transform="translate(585.298427,127.771297)">
                  <g id="eqepmVhE4Cb46_tr" transform="rotate(0)">
                    <rect
                      id="eqepmVhE4Cb46"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 4 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(63 0)" mask="url(#eqepmVhE4Cb58)">
              <g>
                <line
                  x1="443.301"
                  y1="169.502"
                  x2="443.301"
                  y2="325.462"
                  transform="translate(-63.018-176.956)"
                  fill="none"
                  stroke="#6069ca"
                />
                <g transform="translate(-65 105.000001)" filter="url(#eqepmVhE4Cb50-filter)">
                  <path
                    id="eqepmVhE4Cb51"
                    d="M380.238,272.676792v-.000001-.000001l.662-95.107999"
                    transform="translate(65-104.797494)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eqepmVhE4Cb51-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eqepmVhE4Cb52_to" transform="translate(445.3,49.646297)">
                    <rect
                      id="eqepmVhE4Cb52"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#eqepmVhE4Cb52-filter)"
                      fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="eqepmVhE4Cb53_to" transform="translate(445.3,49.646297)">
                    <rect
                      id="eqepmVhE4Cb53"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eqepmVhE4Cb54-filter)">
                  <path
                    id="eqepmVhE4Cb55"
                    d="M380.238,272.676792v-.000001-.000001l.662-95.107999"
                    transform="translate(65-104.797494)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eqepmVhE4Cb55-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eqepmVhE4Cb56_to" transform="translate(445.3,49.646297)">
                    <rect
                      id="eqepmVhE4Cb56"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#eqepmVhE4Cb56-filter)"
                      fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="eqepmVhE4Cb57_to" transform="translate(445.3,49.646297)">
                    <rect
                      id="eqepmVhE4Cb57"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <mask id="eqepmVhE4Cb58" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="80"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.963299 341.931986 0.75214)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g>
              <path
                d="M357.283,228.189v-106.506l49.955-44.9838v-76.35545"
                transform="translate(0 0.202507)"
                fill="none"
                stroke="#6069ca"
              />
              <g transform="translate(-65 105.000001)" filter="url(#eqepmVhE4Cb62-filter)">
                <path
                  id="eqepmVhE4Cb63"
                  d="M357.283,138.189v-16.506l27.346502-25.007435c0,0,0,.176087,0,.176087"
                  transform="translate(65-104.797494)"
                  opacity="0.8"
                  fill="none"
                  stroke="url(#eqepmVhE4Cb63-stroke)"
                  strokeLinecap="round"
                />
                <g id="eqepmVhE4Cb64_to" transform="translate(447.5,-5.937495)">
                  <g id="eqepmVhE4Cb64_tr" transform="rotate(45)">
                    <rect
                      id="eqepmVhE4Cb64"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0.7"
                      filter="url(#eqepmVhE4Cb64-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eqepmVhE4Cb65_to" transform="translate(447.5,-5.937495)">
                  <g id="eqepmVhE4Cb65_tr" transform="rotate(45)">
                    <rect
                      id="eqepmVhE4Cb65"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eqepmVhE4Cb66-filter)">
                <path
                  id="eqepmVhE4Cb67"
                  d="M357.283,258.189v-30.338507h.01v.338507"
                  transform="translate(65-104.797494)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eqepmVhE4Cb67-stroke)"
                  strokeLinecap="round"
                />
                <g id="eqepmVhE4Cb68_to" transform="translate(422.411986,128.146297)">
                  <g id="eqepmVhE4Cb68_tr" transform="rotate(0)">
                    <rect
                      id="eqepmVhE4Cb68"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#eqepmVhE4Cb68-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eqepmVhE4Cb69_to" transform="translate(422.411986,128.146297)">
                  <g id="eqepmVhE4Cb69_tr" transform="rotate(0)">
                    <rect
                      id="eqepmVhE4Cb69"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eqepmVhE4Cb70-filter)">
                <path
                  id="eqepmVhE4Cb71"
                  d="M357.283,232.189v-4.860507l.01-.478-.01-1"
                  transform="translate(65-104.797494)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eqepmVhE4Cb71-stroke)"
                  strokeLinecap="round"
                />
                <g id="eqepmVhE4Cb72_to" transform="translate(422.1,129.146297)">
                  <g id="eqepmVhE4Cb72_tr" transform="rotate(0)">
                    <rect
                      id="eqepmVhE4Cb72"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eqepmVhE4Cb72-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eqepmVhE4Cb73_to" transform="translate(422.1,129.146297)">
                  <g id="eqepmVhE4Cb73_tr" transform="rotate(0)">
                    <rect
                      id="eqepmVhE4Cb73"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g mask="url(#eqepmVhE4Cb85)">
              <g transform="translate(.000001 0)">
                <path d="M226.994,138.053v-16.37L333.531,76.7383l.004-76.414117" fill="none" stroke="#6069ca" />
                <g transform="translate(-65 105.000001)" filter="url(#eqepmVhE4Cb77-filter)">
                  <path
                    id="eqepmVhE4Cb78"
                    d="M226.994002,161.5213l-.000002-7.468298.106.000002-.070999-.000002"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eqepmVhE4Cb78-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eqepmVhE4Cb79_to" transform="translate(291.8,52.271297)">
                    <g id="eqepmVhE4Cb79_tr" transform="rotate(0)">
                      <rect
                        id="eqepmVhE4Cb79"
                        width="8.75"
                        height="15"
                        rx="0"
                        ry="0"
                        transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                        opacity="0"
                        filter="url(#eqepmVhE4Cb79-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eqepmVhE4Cb80_to" transform="translate(291.8,52.271297)">
                    <g id="eqepmVhE4Cb80_tr" transform="rotate(0)">
                      <rect
                        id="eqepmVhE4Cb80"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eqepmVhE4Cb81-filter)">
                  <path
                    id="eqepmVhE4Cb82"
                    d="M226.994002,161.5213l-.000002-7.468298.106.000002-.070999-.000002"
                    transform="translate(65-105.000001)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eqepmVhE4Cb82-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eqepmVhE4Cb83_to" transform="translate(291.8,49.646297)">
                    <g id="eqepmVhE4Cb83_tr" transform="rotate(0)">
                      <rect
                        id="eqepmVhE4Cb83"
                        width="7"
                        height="12"
                        rx="0"
                        ry="0"
                        transform="translate(-3.5,-6)"
                        opacity="0"
                        filter="url(#eqepmVhE4Cb83-filter)"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                  <g id="eqepmVhE4Cb84_to" transform="translate(291.8,49.646297)">
                    <g id="eqepmVhE4Cb84_tr" transform="rotate(0)">
                      <rect
                        id="eqepmVhE4Cb84"
                        width="10"
                        height="20"
                        rx="0"
                        ry="0"
                        transform="scale(0.5,0.5) translate(-5,-10)"
                        opacity="0"
                        fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                      />
                    </g>
                  </g>
                </g>
              </g>
              <mask id="eqepmVhE4Cb85" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="140"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.960927 209.931986 0.921381)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g>
              <path d="M32.0957,229.88v-108.202L222.955,76.73v-76.413594" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#eqepmVhE4Cb89-filter)">
                <path
                  id="eqepmVhE4Cb90"
                  d="M32.0957,249.88v-44.732683h-.003714v.998977"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eqepmVhE4Cb90-stroke)"
                  strokeLinecap="round"
                />
                <g id="eqepmVhE4Cb91_to" transform="translate(96.798427,143.537316)">
                  <g id="eqepmVhE4Cb91_tr" transform="rotate(0)">
                    <rect
                      id="eqepmVhE4Cb91"
                      width="10"
                      height="17.142857"
                      rx="0"
                      ry="0"
                      transform="scale(0.7,0.7) translate(-5,-8.571429)"
                      opacity="0"
                      filter="url(#eqepmVhE4Cb91-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eqepmVhE4Cb92_to" transform="translate(96.798427,142.237316)">
                  <g id="eqepmVhE4Cb92_tr" transform="rotate(0)">
                    <rect
                      id="eqepmVhE4Cb92"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      filter="url(#eqepmVhE4Cb92-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      strokeMiterlimit="8"
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eqepmVhE4Cb93-filter)">
                <path
                  id="eqepmVhE4Cb94"
                  d="M32.0957,249.88v-10.732683h-.003714v-.001023"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eqepmVhE4Cb94-stroke)"
                  strokeLinecap="round"
                />
                <g id="eqepmVhE4Cb95_to" transform="translate(97.2,140.121297)">
                  <g id="eqepmVhE4Cb95_tr" transform="rotate(0)">
                    <rect
                      id="eqepmVhE4Cb95"
                      width="10"
                      height="17.142857"
                      rx="0"
                      ry="0"
                      transform="scale(0.7,0.7) translate(-5,-8.571428)"
                      opacity="0"
                      filter="url(#eqepmVhE4Cb95-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eqepmVhE4Cb96_to" transform="translate(97.2,140.121297)">
                  <g id="eqepmVhE4Cb96_tr" transform="rotate(0)">
                    <rect
                      id="eqepmVhE4Cb96"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <mask id="eqepmVhE4Cb97" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="825.762291"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #eqepmVhE4Cb9 {
    animation-name: eqepmVhE4Cb9__m, eqepmVhE4Cb9_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eqepmVhE4Cb9__m {
    0% {
      d: path('M853.309,172.852398L853.309,121.49963L764.741674,100.215254L764.220825,100.215253');
    }
    7.5% {
      d: path('M853.309,121.605025L853.309,121.49963L665.296,76.728L665.296,76.180546');
    }
    8.75% {
      d: path('M829.807375,115.995397L829.807375,115.903177L665.296,76.728L665.296,67.046162');
    }
    10% {
      d: path('M812.181157,111.788176L812.181156,111.705837L665.296,76.728L665.296,64.114533');
    }
    13.75% {
      d: path('M759.302501,99.166512L759.3025,99.113816L665.296,76.728L665.296,16.128048');
    }
    20% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
    100% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
  }
  @keyframes eqepmVhE4Cb9_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    17.5% {
      opacity: 1;
    }
    26.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb10_to {
    animation: eqepmVhE4Cb10_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb10_to__to {
    0% {
      transform: translate(828.317602px, -5.431736px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(736.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(730.920607px, -32.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.219355px, -122.688381px);
    }
    100% {
      transform: translate(731.219355px, -122.688381px);
    }
  }
  #eqepmVhE4Cb10_tr {
    animation: eqepmVhE4Cb10_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb10_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    7.5% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eqepmVhE4Cb10 {
    animation: eqepmVhE4Cb10_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb10_c_o {
    0% {
      opacity: 0.7;
    }
    20% {
      opacity: 0.7;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb11_to {
    animation: eqepmVhE4Cb11_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb11_to__to {
    0% {
      transform: translate(828.317602px, -5.431736px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    7.5% {
      transform: translate(736.801035px, -27.63967px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    8.75% {
      transform: translate(730.851035px, -32.48073px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    10% {
      transform: translate(730.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(731.219354px, -122.688381px);
    }
    100% {
      transform: translate(731.219354px, -122.688381px);
    }
  }
  #eqepmVhE4Cb11_tr {
    animation: eqepmVhE4Cb11_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb11_tr__tr {
    0% {
      transform: rotate(-75deg);
    }
    7.5% {
      transform: rotate(-75deg);
    }
    8.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eqepmVhE4Cb11 {
    animation: eqepmVhE4Cb11_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb11_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb13 {
    animation-name: eqepmVhE4Cb13__m, eqepmVhE4Cb13_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eqepmVhE4Cb13__m {
    0% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    30% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    37.5% {
      d: path('M853.309,237.53995L853.309,151.87312L853.306432,148.3585L853.309001,144.380103');
    }
    40% {
      d: path('M853.309,232.641L853.309,121.49963L853.309,121.605025L852.281356,122.594792');
    }
    56.25% {
      d: path('M853.309,121.605025L853.309,121.49963L665.296,76.728L665.296,76.180546');
    }
    62.5% {
      d: path('M759.302501,99.166512L759.3025,99.113816L665.296,76.728L665.296,11.229098');
    }
    68.75% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
    100% {
      d: path('M665.296001,76.727999L665.296,76.728001L665.296,76.728L665.296,-26.28823');
    }
  }
  @keyframes eqepmVhE4Cb13_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    66.25% {
      opacity: 1;
    }
    76.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb14_to {
    animation: eqepmVhE4Cb14_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb14_to__to {
    0% {
      transform: translate(917.8px, 138.546297px);
    }
    25% {
      transform: translate(917.8px, 138.546297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 21.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(913.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(905.3352px, 13.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(736.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(730.920607px, -32.510292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -38.49755px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(730.9px, -120.728703px);
    }
    100% {
      transform: translate(730.9px, -120.728703px);
    }
  }
  #eqepmVhE4Cb14_tr {
    animation: eqepmVhE4Cb14_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb14_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-75deg);
    }
    55% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eqepmVhE4Cb14 {
    animation: eqepmVhE4Cb14_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb14_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 0.7;
    }
    68.75% {
      opacity: 0.7;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb15_to {
    animation: eqepmVhE4Cb15_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb15_to__to {
    0% {
      transform: translate(917.8px, 138.546297px);
    }
    25% {
      transform: translate(917.8px, 138.546297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    38.75% {
      transform: translate(917.798427px, 21.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    40% {
      transform: translate(913.568884px, 15.76033px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    41.25% {
      transform: translate(904.798427px, 13.4px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(736.801035px, -27.43967px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(730.920607px, -32.510292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(730.9px, -38.49755px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    68.75% {
      transform: translate(730.9px, -120.228703px);
    }
    100% {
      transform: translate(730.9px, -120.228703px);
    }
  }
  #eqepmVhE4Cb15_tr {
    animation: eqepmVhE4Cb15_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(-75deg);
    }
    55% {
      transform: rotate(-75deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eqepmVhE4Cb15 {
    animation: eqepmVhE4Cb15_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb15_c_o {
    0% {
      opacity: 0;
    }
    23.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    68.75% {
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb17 {
    animation-name: eqepmVhE4Cb17__m, eqepmVhE4Cb17_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eqepmVhE4Cb17__m {
    0% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    81.25% {
      d: path('M853.309,252.2368L853.309,242.99359L853.309,241.226881L853.309,238.042565');
    }
    91.25% {
      d: path('M853.309,232.641L853.309,121.49963L853.309,121.605025L853.309,120.707886');
    }
    100% {
      d: path('M853.309,172.852398L853.309,121.49963L762.497203,100.468958L762.736434,100.468959');
    }
  }
  @keyframes eqepmVhE4Cb17_c_o {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    76.25% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eqepmVhE4Cb18_to {
    animation: eqepmVhE4Cb18_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb18_to__to {
    0% {
      transform: translate(917.8px, 140.146297px);
    }
    75% {
      transform: translate(917.8px, 140.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.8px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(913.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(905.3352px, 13.841812px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(826.798304px, -5.828881px);
    }
  }
  #eqepmVhE4Cb18_tr {
    animation: eqepmVhE4Cb18_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb18_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #eqepmVhE4Cb18 {
    animation: eqepmVhE4Cb18_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb18_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #eqepmVhE4Cb19_to {
    animation: eqepmVhE4Cb19_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb19_to__to {
    0% {
      transform: translate(917.8px, 140.146297px);
    }
    75% {
      transform: translate(917.8px, 140.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    90% {
      transform: translate(917.8px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    91.25% {
      transform: translate(913.3px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    92.5% {
      transform: translate(904.798427px, 13.4px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(827.121519px, -5.98073px);
    }
  }
  #eqepmVhE4Cb19_tr {
    animation: eqepmVhE4Cb19_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb19_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(0deg);
    }
    91.25% {
      transform: rotate(-75deg);
    }
    100% {
      transform: rotate(-75deg);
    }
  }
  #eqepmVhE4Cb19 {
    animation: eqepmVhE4Cb19_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb19_c_o {
    0% {
      opacity: 0;
    }
    73.75% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eqepmVhE4Cb24 {
    animation-name: eqepmVhE4Cb24__m, eqepmVhE4Cb24_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eqepmVhE4Cb24__m {
    0% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    5% {
      d: path('M650.977,146.653429L650.977,145.824857L650.761566,146.078382L650.761569,145.329073');
    }
    8.75% {
      d: path('M650.977,138.082L650.977,121.682L650.560991,122.125667L650.560995,122.622405');
    }
    22.5% {
      d: path('M650.977,122.082L650.977,121.682L554.656,76.7305L554.652,76.316494');
    }
    28.75% {
      d: path('M575.124559,86.409359L575.124559,86.409359L554.656,76.7305L554.652,9.316494');
    }
    35% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
    100% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
  }
  @keyframes eqepmVhE4Cb24_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    33.75% {
      opacity: 1;
    }
    43.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb25_to {
    animation: eqepmVhE4Cb25_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb25_to__to {
    0% {
      transform: translate(715.8px, 52.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(712.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(706.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(624.801035px, -25.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(619.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(619.9px, -121.728703px);
    }
    100% {
      transform: translate(619.9px, -121.728703px);
    }
  }
  #eqepmVhE4Cb25_tr {
    animation: eqepmVhE4Cb25_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb25_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-68deg);
    }
    21.25% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eqepmVhE4Cb25 {
    animation: eqepmVhE4Cb25_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb25_c_o {
    0% {
      opacity: 0.7;
    }
    35% {
      opacity: 0.7;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb26_to {
    animation: eqepmVhE4Cb26_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb26_to__to {
    0% {
      transform: translate(715.8px, 52.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    7.5% {
      transform: translate(715.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    8.75% {
      transform: translate(711.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    10% {
      transform: translate(705.798427px, 12.6px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    21.25% {
      transform: translate(623.801035px, -26.140845px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    22.5% {
      transform: translate(619.911987px, -32.40395px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    23.75% {
      transform: translate(619.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    35% {
      transform: translate(619.9px, -122.228703px);
    }
    100% {
      transform: translate(619.9px, -122.228703px);
    }
  }
  #eqepmVhE4Cb26_tr {
    animation: eqepmVhE4Cb26_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb26_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    8.75% {
      transform: rotate(-68deg);
    }
    21.25% {
      transform: rotate(-68deg);
    }
    22.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eqepmVhE4Cb26 {
    animation: eqepmVhE4Cb26_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb26_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    35% {
      opacity: 1;
    }
    36.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb28 {
    animation-name: eqepmVhE4Cb28__m, eqepmVhE4Cb28_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eqepmVhE4Cb28__m {
    0% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    43.75% {
      d: path('M650.977,158.082L650.977,154.682L651.029,154.682001L651.029,152.271298');
    }
    48.75% {
      d: path('M650.977,146.653429L650.977,145.824857L650.761566,146.078382L650.761569,145.329073');
    }
    52.5% {
      d: path('M650.977,138.082L650.977,121.682L650.560991,122.125667L650.560995,122.622405');
    }
    66.25% {
      d: path('M650.977,122.082L650.977,121.682L554.656,76.7305L554.652,76.316494');
    }
    72.5% {
      d: path('M575.124559,86.409359L575.124559,86.409359L554.656,76.7305L554.652,9.316494');
    }
    78.75% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
    100% {
      d: path('M554.077001,76.696465L554.077002,76.696469L554.656,76.7305L554.656,-15.012683');
    }
  }
  @keyframes eqepmVhE4Cb28_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 1;
    }
    76.25% {
      opacity: 1;
    }
    86.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb29_to {
    animation: eqepmVhE4Cb29_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb29_to__to {
    0% {
      transform: translate(715.298427px, 50.146297px);
    }
    43.75% {
      transform: translate(715.298427px, 50.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(713.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(707.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(624.801035px, -25.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(619.920607px, -29.810292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -36.79755px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(619.9px, -117.878703px);
    }
    100% {
      transform: translate(619.9px, -117.878703px);
    }
  }
  #eqepmVhE4Cb29_tr {
    animation: eqepmVhE4Cb29_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb29_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-68deg);
    }
    65% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eqepmVhE4Cb29 {
    animation: eqepmVhE4Cb29_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb29_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 0.7;
    }
    78.75% {
      opacity: 0.7;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb30_to {
    animation: eqepmVhE4Cb30_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb30_to__to {
    0% {
      transform: translate(715.298427px, 50.146297px);
    }
    43.75% {
      transform: translate(715.298427px, 50.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    51.25% {
      transform: translate(715.798427px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    52.5% {
      transform: translate(713.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    53.75% {
      transform: translate(707.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    65% {
      transform: translate(626.801035px, -24.6px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    66.25% {
      transform: translate(619.920607px, -29.810292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    67.5% {
      transform: translate(619.5px, -36.79755px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    78.75% {
      transform: translate(619.9px, -117.878703px);
    }
    100% {
      transform: translate(619.9px, -117.878703px);
    }
  }
  #eqepmVhE4Cb30_tr {
    animation: eqepmVhE4Cb30_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb30_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    52.5% {
      transform: rotate(-68deg);
    }
    65% {
      transform: rotate(-68deg);
    }
    66.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eqepmVhE4Cb30 {
    animation: eqepmVhE4Cb30_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb30_c_o {
    0% {
      opacity: 0;
    }
    42.5% {
      opacity: 0;
    }
    43.75% {
      opacity: 1;
    }
    78.75% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb36 {
    animation-name: eqepmVhE4Cb36__m, eqepmVhE4Cb36_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eqepmVhE4Cb36__m {
    0% {
      d: path('M520.668,121.038711L520.668,121.681L480.969,76.7549L480.974,72.075139');
    }
    6.25% {
      d: path('M480.974,77.356098L480.974,77.356096L480.969,76.7549L480.974,-19.753743');
    }
    100% {
      d: path('M480.974,77.356098L480.974,77.356096L480.969,76.7549L480.974,-19.753743');
    }
  }
  @keyframes eqepmVhE4Cb36_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    10% {
      opacity: 1;
    }
    18.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb36-stroke-0 {
    animation: eqepmVhE4Cb36-stroke-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb36-stroke-0__c {
    0% {
      stop-color: #7ea6ff;
    }
    1.25% {
      stop-color: #7ea6ff;
    }
    5% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #eqepmVhE4Cb36-stroke-1 {
    animation: eqepmVhE4Cb36-stroke-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb36-stroke-1__c {
    0% {
      stop-color: rgba(64, 73, 168, 0);
    }
    1.25% {
      stop-color: rgba(64, 73, 168, 0);
    }
    5% {
      stop-color: rgba(64, 73, 168, 0);
    }
    100% {
      stop-color: rgba(64, 73, 168, 0);
    }
  }
  #eqepmVhE4Cb37_to {
    animation: eqepmVhE4Cb37_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb37_to__to {
    0% {
      transform: translate(546.2px, -36.480793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    11.25% {
      transform: translate(546.2px, -121.728703px);
    }
    100% {
      transform: translate(546.2px, -121.728703px);
    }
  }
  #eqepmVhE4Cb37 {
    animation: eqepmVhE4Cb37_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb37_c_o {
    0% {
      opacity: 0.7;
    }
    11.25% {
      opacity: 0.7;
    }
    12.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb38_to {
    animation: eqepmVhE4Cb38_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb38_to__to {
    0% {
      transform: translate(546.2px, -36.480793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    11.25% {
      transform: translate(546.2px, -121.228703px);
    }
    100% {
      transform: translate(546.2px, -121.228703px);
    }
  }
  #eqepmVhE4Cb38 {
    animation: eqepmVhE4Cb38_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb38_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    11.25% {
      opacity: 1;
    }
    12.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb40 {
    animation-name: eqepmVhE4Cb40__m, eqepmVhE4Cb40_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eqepmVhE4Cb40__m {
    0% {
      d: path('M520.668,243.079L520.668,230.301025L520.668,229.559706L520.668,227.771298');
    }
    15% {
      d: path('M520.668,243.079L520.668,230.301025L520.668,229.559706L520.668,227.771298');
    }
    30% {
      d: path('M520.668,226.155923L520.668,130.036387L520.140484,130.771424L520.882155,129.738773');
    }
    31.25% {
      d: path('M520.668,225.079L520.668,121.681L520.096524,122.539067L520.900001,121.569396');
    }
    45% {
      d: path('M520.668,122.079L520.668,121.681L480.969,76.7549L480.974,74.246257');
    }
    53.75% {
      d: path('M480.974,77.356098L480.974,77.356096L480.969,76.7549L480.974,-19.753743');
    }
    100% {
      d: path('M480.974,77.356098L480.974,77.356096L480.969,76.7549L480.974,-19.753743');
    }
  }
  @keyframes eqepmVhE4Cb40_c_o {
    0% {
      opacity: 0;
    }
    13.75% {
      opacity: 0;
    }
    15% {
      opacity: 1;
    }
    52.5% {
      opacity: 1;
    }
    61.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb40-stroke {
    animation: eqepmVhE4Cb40-stroke__t 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb40-stroke__t {
    0% {
      transform: matrix(0, 0.798912, -0.798912, 0, 26.967315, 0.107811);
    }
    30% {
      transform: matrix(0, 0.798912, -0.798912, 0, 26.967315, 0.107811);
    }
    32.5% {
      transform: matrix(0, 0.978039, -0.978039, 0, 5.551138, 0.021961);
    }
    40% {
      transform: matrix(0.118049, 0.836102, -0.836102, 0.118049, 1.764201, -0.029073);
    }
    100% {
      transform: matrix(0.118049, 0.836102, -0.836102, 0.118049, 1.764201, -0.029073);
    }
  }
  #eqepmVhE4Cb40-stroke-0 {
    animation: eqepmVhE4Cb40-stroke-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb40-stroke-0__c {
    0% {
      stop-color: #7ea6ff;
    }
    30% {
      stop-color: #7ea6ff;
    }
    32.5% {
      stop-color: #7ea6ff;
    }
    40% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #eqepmVhE4Cb40-stroke-1 {
    animation: eqepmVhE4Cb40-stroke-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb40-stroke-1__c {
    0% {
      stop-color: rgba(64, 73, 168, 0);
    }
    30% {
      stop-color: rgba(64, 73, 168, 0);
    }
    32.5% {
      stop-color: rgba(64, 73, 168, 0);
    }
    40% {
      stop-color: rgba(64, 73, 168, 0);
    }
    100% {
      stop-color: rgba(64, 73, 168, 0);
    }
  }
  #eqepmVhE4Cb41_to {
    animation: eqepmVhE4Cb41_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb41_to__to {
    0% {
      transform: translate(585.5px, 129.646297px);
    }
    15% {
      transform: translate(585.5px, 129.646297px);
    }
    30% {
      transform: translate(585.5px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    31.25% {
      transform: translate(583.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    32.5% {
      transform: translate(580.3352px, 10.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    43.75% {
      transform: translate(547.7px, -26.2px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    45% {
      transform: translate(545.920607px, -30.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    46.25% {
      transform: translate(545.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    56.25% {
      transform: translate(545.9px, -119.728703px);
    }
    100% {
      transform: translate(545.9px, -119.728703px);
    }
  }
  #eqepmVhE4Cb41_tr {
    animation: eqepmVhE4Cb41_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb41_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    31.25% {
      transform: rotate(-45deg);
    }
    43.75% {
      transform: rotate(-45deg);
    }
    45% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eqepmVhE4Cb41 {
    animation: eqepmVhE4Cb41_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb41_c_o {
    0% {
      opacity: 0;
    }
    13.75% {
      opacity: 0;
    }
    15% {
      opacity: 0.7;
    }
    56.25% {
      opacity: 0.7;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb42_to {
    animation: eqepmVhE4Cb42_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb42_to__to {
    0% {
      transform: translate(585.5px, 129.646297px);
    }
    15% {
      transform: translate(585.5px, 129.646297px);
    }
    30% {
      transform: translate(585.5px, 23.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    31.25% {
      transform: translate(582.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    32.5% {
      transform: translate(579.798427px, 10.099627px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    43.75% {
      transform: translate(547.624298px, -26.300373px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    45% {
      transform: translate(545.851035px, -30.262684px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    46.25% {
      transform: translate(545.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    56.25% {
      transform: translate(545.9px, -120.228703px);
    }
    100% {
      transform: translate(545.9px, -120.228703px);
    }
  }
  #eqepmVhE4Cb42_tr {
    animation: eqepmVhE4Cb42_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb42_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    31.25% {
      transform: rotate(-45deg);
    }
    43.75% {
      transform: rotate(-45deg);
    }
    45% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eqepmVhE4Cb42 {
    animation: eqepmVhE4Cb42_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb42_c_o {
    0% {
      opacity: 0;
    }
    13.75% {
      opacity: 0;
    }
    15% {
      opacity: 1;
    }
    56.25% {
      opacity: 1;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb44 {
    animation-name: eqepmVhE4Cb44__m, eqepmVhE4Cb44_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eqepmVhE4Cb44__m {
    0% {
      d: path('M520.668,233.079L520.668,230.301025L520.668,229.559706L520.668,227.771298');
    }
    68.75% {
      d: path('M520.668,233.079L520.668,230.301025L520.668,229.559706L520.668,227.771298');
    }
    82.5% {
      d: path('M520.668,232.694385L520.668,128.391773L520.184443,129.003781L520.864309,127.90815');
    }
    83.75% {
      d: path('M520.668,224.185509L520.668,121.574432L517.691152,118.296426L517.691152,118.296426');
    }
    98.75% {
      d: path('M520.668,122.079L520.668,121.681L480.969,76.7549L480.974,74.246257');
    }
    100% {
      d: path('M520.668,127.412333L520.668,121.681L480.969,76.7549L480.974,66.135146');
    }
  }
  @keyframes eqepmVhE4Cb44_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eqepmVhE4Cb45_to {
    animation: eqepmVhE4Cb45_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb45_to__to {
    0% {
      transform: translate(585.298427px, 127.771297px);
    }
    68.75% {
      transform: translate(585.298427px, 127.771297px);
    }
    82.5% {
      transform: translate(585.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(583.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(580.862309px, 10.862847px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    97.5% {
      transform: translate(547.7px, -26.2px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    98.75% {
      transform: translate(546.2px, -30.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    100% {
      transform: translate(546.2px, -37.521739px);
    }
  }
  #eqepmVhE4Cb45_tr {
    animation: eqepmVhE4Cb45_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb45_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-45deg);
    }
    97.5% {
      transform: rotate(-45deg);
    }
    98.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eqepmVhE4Cb45 {
    animation: eqepmVhE4Cb45_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb45_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #eqepmVhE4Cb46_to {
    animation: eqepmVhE4Cb46_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb46_to__to {
    0% {
      transform: translate(585.298427px, 127.771297px);
    }
    68.75% {
      transform: translate(585.298427px, 127.771297px);
    }
    82.5% {
      transform: translate(585.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(582.798427px, 14.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(580.862308px, 10.862847px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    97.5% {
      transform: translate(547.124298px, -26.800373px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    98.75% {
      transform: translate(546.2px, -30.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    100% {
      transform: translate(546.2px, -37.521739px);
    }
  }
  #eqepmVhE4Cb46_tr {
    animation: eqepmVhE4Cb46_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb46_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-45deg);
    }
    97.5% {
      transform: rotate(-45deg);
    }
    98.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eqepmVhE4Cb46 {
    animation: eqepmVhE4Cb46_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb46_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eqepmVhE4Cb51 {
    animation-name: eqepmVhE4Cb51__m, eqepmVhE4Cb51_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eqepmVhE4Cb51__m {
    0% {
      d: path('M380.238,272.676792L380.238,272.676791L380.238,272.67679L380.9,177.568791');
    }
    16.25% {
      d: path('M380.238,272.676792L380.238,272.676791L380.238,272.67679L380.9,177.568791');
    }
    30% {
      d: path('M380.238,202.385125L380.238,202.385124L380.238,202.385123L380.9,107.277124');
    }
    46.25% {
      d: path('M380.238,73.676792L380.238,73.676791L380.238,73.67679L380.9,-21.431209');
    }
    100% {
      d: path('M380.238,73.676792L380.238,73.676791L380.238,73.67679L380.9,-21.431209');
    }
  }
  @keyframes eqepmVhE4Cb51_c_o {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    16.25% {
      opacity: 0.8;
    }
    43.75% {
      opacity: 0.8;
    }
    51.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb52_to {
    animation: eqepmVhE4Cb52_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb52_to__to {
    0% {
      transform: translate(445.3px, 49.646297px);
    }
    12.5% {
      transform: translate(445.3px, 49.646297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    45% {
      transform: translate(445.298427px, -123.353703px);
    }
    100% {
      transform: translate(445.298427px, -123.353703px);
    }
  }
  #eqepmVhE4Cb52 {
    animation: eqepmVhE4Cb52_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb52_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.7;
    }
    45% {
      opacity: 0.7;
    }
    46.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb53_to {
    animation: eqepmVhE4Cb53_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb53_to__to {
    0% {
      transform: translate(445.3px, 49.646297px);
    }
    12.5% {
      transform: translate(445.3px, 49.646297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    45% {
      transform: translate(445.298427px, -123.353703px);
    }
    100% {
      transform: translate(445.298427px, -123.353703px);
    }
  }
  #eqepmVhE4Cb53 {
    animation: eqepmVhE4Cb53_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb53_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    45% {
      opacity: 1;
    }
    46.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb55 {
    animation-name: eqepmVhE4Cb55__m, eqepmVhE4Cb55_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eqepmVhE4Cb55__m {
    0% {
      d: path('M380.238,272.676792L380.238,272.676791L380.238,272.67679L380.9,177.568791');
    }
    63.75% {
      d: path('M380.238,272.676792L380.238,272.676791L380.238,272.67679L380.9,177.568791');
    }
    77.5% {
      d: path('M380.238,202.385125L380.238,202.385124L380.238,202.385123L380.9,107.277124');
    }
    93.75% {
      d: path('M380.238,73.676792L380.238,73.676791L380.238,73.67679L380.9,-21.431209');
    }
    100% {
      d: path('M380.238,73.676792L380.238,73.676791L380.238,73.67679L380.9,-21.431209');
    }
  }
  @keyframes eqepmVhE4Cb55_c_o {
    0% {
      opacity: 0;
    }
    62.5% {
      opacity: 0;
    }
    63.75% {
      opacity: 0.8;
    }
    91.25% {
      opacity: 0.8;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb56_to {
    animation: eqepmVhE4Cb56_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb56_to__to {
    0% {
      transform: translate(445.3px, 49.646297px);
    }
    60% {
      transform: translate(445.3px, 49.646297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    92.5% {
      transform: translate(445.298427px, -123.353703px);
    }
    100% {
      transform: translate(445.298427px, -123.353703px);
    }
  }
  #eqepmVhE4Cb56 {
    animation: eqepmVhE4Cb56_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb56_c_o {
    0% {
      opacity: 0;
    }
    58.75% {
      opacity: 0;
    }
    60% {
      opacity: 0.7;
    }
    92.5% {
      opacity: 0.7;
    }
    93.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb57_to {
    animation: eqepmVhE4Cb57_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb57_to__to {
    0% {
      transform: translate(445.3px, 49.646297px);
    }
    60% {
      transform: translate(445.3px, 49.646297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    92.5% {
      transform: translate(445.298427px, -123.353703px);
    }
    100% {
      transform: translate(445.298427px, -123.353703px);
    }
  }
  #eqepmVhE4Cb57 {
    animation: eqepmVhE4Cb57_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb57_c_o {
    0% {
      opacity: 0;
    }
    58.75% {
      opacity: 0;
    }
    60% {
      opacity: 1;
    }
    92.5% {
      opacity: 1;
    }
    93.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb63 {
    animation-name: eqepmVhE4Cb63__m, eqepmVhE4Cb63_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eqepmVhE4Cb63__m {
    0% {
      d: path(
        'M357.283,138.189L357.283,121.683L384.629502,96.675565C384.629502,96.675565,384.629502,96.851652,384.629502,96.851652'
      );
    }
    7.5% {
      d: path(
        'M357.293,122.419896L357.283,121.683L407.238,76.6992C407.238,76.6992,407.900001,71.065917,407.900001,71.065917'
      );
    }
    8.75% {
      d: path(
        'M367.282,113.129698L367.274,112.540182L407.238,76.553141C407.238,76.553141,407.9,63.316701,407.9,63.316701'
      );
    }
    13.75% {
      d: path(
        'M407.238,75.968908L407.238,75.968908L407.238,75.968907C407.238,75.968907,407.427143,11.282256,407.427143,11.282256'
      );
    }
    18.75% {
      d: path(
        'M407.238,75.676791L407.238,75.67679L407.238,75.67679C407.238,75.67679,407.238,-4.231209,407.238,-4.231209'
      );
    }
    100% {
      d: path(
        'M407.238,75.676791L407.238,75.67679L407.238,75.67679C407.238,75.67679,407.238,-4.231209,407.238,-4.231209'
      );
    }
  }
  @keyframes eqepmVhE4Cb63_c_o {
    0% {
      opacity: 0.8;
    }
    18.75% {
      opacity: 0.8;
    }
    26.25% {
      opacity: 0.5;
    }
    100% {
      opacity: 0.5;
    }
  }
  #eqepmVhE4Cb63-stroke {
    animation: eqepmVhE4Cb63-stroke__t 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb63-stroke__t {
    0% {
      transform: matrix(-0.898209, 0.6777, -0.6777, -0.898209, 1.030913, 0.286823);
    }
    8.75% {
      transform: matrix(-0.898209, 0.6777, -0.6777, -0.898209, 1.030913, 0.286823);
    }
    10% {
      transform: matrix(-0.367296, 0.964523, -0.964523, -0.367296, 0.5, 0);
    }
    12.5% {
      transform: matrix(0.058424, 1.147332, -1.147332, 0.058424, -0.491329, -0.147332);
    }
    13.75% {
      transform: matrix(0, 0.967718, -0.967718, 0, -66.849855, -0.054991);
    }
    15% {
      transform: matrix(0, 0.933301, -0.933301, 0, -101.857564, -0.014659);
    }
    100% {
      transform: matrix(0, 0.933301, -0.933301, 0, -101.857564, -0.014659);
    }
  }
  #eqepmVhE4Cb63-stroke-0 {
    animation: eqepmVhE4Cb63-stroke-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb63-stroke-0__c {
    0% {
      stop-color: #7ea6ff;
    }
    8.75% {
      stop-color: #7ea6ff;
    }
    10% {
      stop-color: #7ea6ff;
    }
    12.5% {
      stop-color: #7ea6ff;
    }
    13.75% {
      stop-color: #7ea6ff;
    }
    15% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #eqepmVhE4Cb63-stroke-1 {
    animation: eqepmVhE4Cb63-stroke-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb63-stroke-1__c {
    0% {
      stop-color: rgba(64, 73, 168, 0);
    }
    8.75% {
      stop-color: rgba(64, 73, 168, 0);
    }
    10% {
      stop-color: rgba(64, 73, 168, 0);
    }
    12.5% {
      stop-color: rgba(126, 166, 255, 0);
    }
    13.75% {
      stop-color: rgba(95, 120, 212, 0);
    }
    15% {
      stop-color: rgba(64, 73, 168, 0);
    }
    100% {
      stop-color: rgba(64, 73, 168, 0);
    }
  }
  #eqepmVhE4Cb64_to {
    animation: eqepmVhE4Cb64_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb64_to__to {
    0% {
      transform: translate(447.5px, -5.937495px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    6.25% {
      transform: translate(470.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    7.5% {
      transform: translate(472.202997px, -32.010292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    8.75% {
      transform: translate(472.2px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(472.2px, -118.878703px);
    }
    100% {
      transform: translate(472.2px, -118.878703px);
    }
  }
  #eqepmVhE4Cb64_tr {
    animation: eqepmVhE4Cb64_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb64_tr__tr {
    0% {
      transform: rotate(45deg);
    }
    6.25% {
      transform: rotate(45deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eqepmVhE4Cb64 {
    animation: eqepmVhE4Cb64_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb64_c_o {
    0% {
      opacity: 0.7;
    }
    20% {
      opacity: 0.7;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb65_to {
    animation: eqepmVhE4Cb65_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb65_to__to {
    0% {
      transform: translate(447.5px, -5.937495px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    6.25% {
      transform: translate(470.4px, -26.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    7.5% {
      transform: translate(472.202997px, -32.010292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    8.75% {
      transform: translate(472.2px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(472.2px, -118.878703px);
    }
    100% {
      transform: translate(472.2px, -118.878703px);
    }
  }
  #eqepmVhE4Cb65_tr {
    animation: eqepmVhE4Cb65_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb65_tr__tr {
    0% {
      transform: rotate(45deg);
    }
    6.25% {
      transform: rotate(45deg);
    }
    7.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eqepmVhE4Cb65 {
    animation: eqepmVhE4Cb65_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb65_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    20% {
      opacity: 0.8;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb67 {
    animation-name: eqepmVhE4Cb67__m, eqepmVhE4Cb67_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eqepmVhE4Cb67__m {
    0% {
      d: path('M357.283,258.189L357.283,227.850493L357.293,227.850493L357.293,228.189');
    }
    26.25% {
      d: path('M357.283,258.189L357.283,227.850493L357.293,227.850493L357.293,228.189');
    }
    33.75% {
      d: path('M357.1,239.447725L357.283,178.850112L358.449351,177.51997L358.449351,177.51997');
    }
    41.25% {
      d: path('M357.283,227.419769L357.283,129.84973L358.416026,124.516644L358.416026,124.516644');
    }
    42.5% {
      d: path('M357.283,228.189L357.283,121.683L359.798427,118.801026L362.626854,116.450401');
    }
    56.25% {
      d: path('M357.283,121.683L357.283,121.683L407.238,76.6992L407.265249,74.260509');
    }
    57.5% {
      d: path('M365.608833,114.1857L365.608833,114.155782L407.238,76.571399L407.344593,64.549044');
    }
    61.25% {
      d: path('M390.586333,91.693801L390.586333,91.574129L407.238,76.187995L407.582624,7.41465');
    }
    63.75% {
      d: path('M407.238,76.699202L407.238,76.519693L407.238,75.932392L407.741312,-11.00828');
    }
    66.25% {
      d: path('M407.238,75.676792L407.238,75.676791L407.238,75.67679L407.9,-19.431209');
    }
    100% {
      d: path('M407.238,75.676792L407.238,75.676791L407.238,75.67679L407.9,-19.431209');
    }
  }
  @keyframes eqepmVhE4Cb67_c_o {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    26.25% {
      opacity: 0.8;
    }
    63.75% {
      opacity: 0.8;
    }
    71.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb67-stroke {
    animation: eqepmVhE4Cb67-stroke__t 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb67-stroke__t {
    0% {
      transform: matrix(0, 0.774676, -0.774676, 0, 12.911949, 0.088849);
    }
    40% {
      transform: matrix(0, 0.774676, -0.774676, 0, 12.911949, 0.088849);
    }
    47.5% {
      transform: matrix(0.046348, 0.601817, -0.601817, 0.046348, 2.151326, 0.079672);
    }
    58.75% {
      transform: matrix(0.139493, 0.728562, -0.728562, 0.139493, 1.641634, 0.019385);
    }
    100% {
      transform: matrix(0.139493, 0.728562, -0.728562, 0.139493, 1.641634, 0.019385);
    }
  }
  #eqepmVhE4Cb67-stroke-0 {
    animation: eqepmVhE4Cb67-stroke-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb67-stroke-0__c {
    0% {
      stop-color: #7ea6ff;
    }
    40% {
      stop-color: #7ea6ff;
    }
    47.5% {
      stop-color: #7ea6ff;
    }
    58.75% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #eqepmVhE4Cb67-stroke-1 {
    animation: eqepmVhE4Cb67-stroke-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb67-stroke-1__c {
    0% {
      stop-color: rgba(64, 73, 168, 0);
    }
    40% {
      stop-color: rgba(64, 73, 168, 0);
    }
    47.5% {
      stop-color: rgba(64, 73, 168, 0);
    }
    58.75% {
      stop-color: rgba(64, 73, 168, 0);
    }
    100% {
      stop-color: rgba(64, 73, 168, 0);
    }
  }
  #eqepmVhE4Cb68_to {
    animation: eqepmVhE4Cb68_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb68_to__to {
    0% {
      transform: translate(422.411986px, 128.146297px);
    }
    26.25% {
      transform: translate(422.411986px, 128.146297px);
    }
    41.25% {
      transform: translate(422.411986px, 22.246256px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    42.5% {
      transform: translate(424.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    43.75% {
      transform: translate(430.3352px, 9.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(470.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(472.5px, -31.410292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(472.5px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    65% {
      transform: translate(472.5px, -120.728703px);
    }
    100% {
      transform: translate(472.5px, -120.728703px);
    }
  }
  #eqepmVhE4Cb68_tr {
    animation: eqepmVhE4Cb68_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb68_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    42.5% {
      transform: rotate(45deg);
    }
    55% {
      transform: rotate(45deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eqepmVhE4Cb68 {
    animation: eqepmVhE4Cb68_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb68_c_o {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    26.25% {
      opacity: 0.7;
    }
    65% {
      opacity: 0.7;
    }
    66.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb69_to {
    animation: eqepmVhE4Cb69_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb69_to__to {
    0% {
      transform: translate(422.411986px, 128.146297px);
    }
    26.25% {
      transform: translate(422.411986px, 128.146297px);
    }
    41.25% {
      transform: translate(422.411986px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    42.5% {
      transform: translate(425.798427px, 15px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    43.75% {
      transform: translate(431.124298px, 8.699627px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    55% {
      transform: translate(471.4px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    56.25% {
      transform: translate(472.5px, -31.410292px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    57.5% {
      transform: translate(472.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    65% {
      transform: translate(472.5px, -121.228703px);
    }
    100% {
      transform: translate(472.5px, -121.228703px);
    }
  }
  #eqepmVhE4Cb69_tr {
    animation: eqepmVhE4Cb69_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb69_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    42.5% {
      transform: rotate(45deg);
    }
    55% {
      transform: rotate(45deg);
    }
    56.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eqepmVhE4Cb69 {
    animation: eqepmVhE4Cb69_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb69_c_o {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    26.25% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    66.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb71 {
    animation-name: eqepmVhE4Cb71__m, eqepmVhE4Cb71_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eqepmVhE4Cb71__m {
    0% {
      d: path('M357.283,232.189L357.283,227.328493L357.293,226.850493L357.283,225.850493');
    }
    77.5% {
      d: path('M357.283,232.189L357.283,227.328493L357.293,226.850493L357.283,225.850493');
    }
    87.5% {
      d: path('M357.283,231.189L357.283,139.328493L357.293,137.850493L357.283,137.850493');
    }
    88.75% {
      d: path('M357.283,229.189L357.283,121.683L357.283,121.683L358.525543,120.619481');
    }
    90% {
      d: path('M357.283,228.189L357.283,121.683L359.798427,119.512036L359.798427,119.512036');
    }
    98.75% {
      d: path('M357.283,138.189L357.283,121.683L378.447064,102.839971L380.992649,101.014044');
    }
    100% {
      d: path('M357.283,138.189L357.283,121.683L364.111115,114.973587L382.295526,99.248907');
    }
  }
  @keyframes eqepmVhE4Cb71_c_o {
    0% {
      opacity: 0;
    }
    76.25% {
      opacity: 0;
    }
    77.5% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.8;
    }
  }
  #eqepmVhE4Cb72_to {
    animation: eqepmVhE4Cb72_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb72_to__to {
    0% {
      transform: translate(422.1px, 129.146297px);
    }
    77.5% {
      transform: translate(422.1px, 129.146297px);
    }
    88.75% {
      transform: translate(422.1px, 22.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    90% {
      transform: translate(424.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    91.25% {
      transform: translate(430.3352px, 9.841812px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(449.063292px, -7.316354px);
    }
  }
  #eqepmVhE4Cb72_tr {
    animation: eqepmVhE4Cb72_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb72_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    88.75% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(45deg);
    }
  }
  #eqepmVhE4Cb72 {
    animation: eqepmVhE4Cb72_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb72_c_o {
    0% {
      opacity: 0;
    }
    76.25% {
      opacity: 0;
    }
    77.5% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #eqepmVhE4Cb73_to {
    animation: eqepmVhE4Cb73_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb73_to__to {
    0% {
      transform: translate(422.1px, 129.146297px);
    }
    77.5% {
      transform: translate(422.1px, 129.146297px);
    }
    88.75% {
      transform: translate(422.1px, 22.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    90% {
      transform: translate(424.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    91.25% {
      transform: translate(430.3352px, 9.841812px);
      animation-timing-function: cubic-bezier(0.358852, 0.281821, 0.679969, 0.628107);
    }
    100% {
      transform: translate(449.063292px, -7.316354px);
    }
  }
  #eqepmVhE4Cb73_tr {
    animation: eqepmVhE4Cb73_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb73_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    88.75% {
      transform: rotate(0deg);
    }
    90% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(45deg);
    }
  }
  #eqepmVhE4Cb73 {
    animation: eqepmVhE4Cb73_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb73_c_o {
    0% {
      opacity: 0;
    }
    76.25% {
      opacity: 0;
    }
    77.5% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eqepmVhE4Cb78 {
    animation-name: eqepmVhE4Cb78__m, eqepmVhE4Cb78_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eqepmVhE4Cb78__m {
    0% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    18.75% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    23.75% {
      d: path('M226.994001,149.78715L226.994,145.868001L227.0645,146.865005L226.907042,146.549048');
    }
    28.75% {
      d: path('M226.994,138.053L226.994,121.683L227.029,121.677006L228.785083,121.045093');
    }
    40% {
      d: path('M227.029,121.683L226.994,121.683L333.531,76.7383L333.535,76.696467');
    }
    42.5% {
      d: path('M245.992733,113.68997L245.963955,113.721882L333.531,76.7383L333.535,65.343379');
    }
    50% {
      d: path('M312.365799,85.714363L312.358799,85.857968L333.531,76.7383L333.535,-14.892427');
    }
    55% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
    100% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
  }
  @keyframes eqepmVhE4Cb78_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    52.5% {
      opacity: 1;
    }
    61.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb79_to {
    animation: eqepmVhE4Cb79_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb79_to__to {
    0% {
      transform: translate(291.8px, 52.271297px);
    }
    18.75% {
      transform: translate(291.8px, 52.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(291.798427px, 20.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(295.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(303.3352px, 12.1px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(398.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -119.728703px);
    }
    100% {
      transform: translate(398.699999px, -119.728703px);
    }
  }
  #eqepmVhE4Cb79_tr {
    animation: eqepmVhE4Cb79_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb79_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(68deg);
    }
    30% {
      transform: rotate(68deg);
    }
    40% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eqepmVhE4Cb79 {
    animation: eqepmVhE4Cb79_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb79_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 0.7;
    }
    53.75% {
      opacity: 0.7;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb80_to {
    animation: eqepmVhE4Cb80_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb80_to__to {
    0% {
      transform: translate(291.8px, 52.271297px);
    }
    18.75% {
      transform: translate(291.8px, 52.271297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    27.5% {
      transform: translate(291.798427px, 20.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    28.75% {
      transform: translate(296.798427px, 14.51295px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    30% {
      transform: translate(304.474547px, 11.51295px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    40% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    41.25% {
      transform: translate(398.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    42.5% {
      transform: translate(398.699999px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    53.75% {
      transform: translate(398.699999px, -120.228703px);
    }
    100% {
      transform: translate(398.699999px, -120.228703px);
    }
  }
  #eqepmVhE4Cb80_tr {
    animation: eqepmVhE4Cb80_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb80_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(0deg);
    }
    28.75% {
      transform: rotate(68deg);
    }
    40% {
      transform: rotate(68deg);
    }
    41.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eqepmVhE4Cb80 {
    animation: eqepmVhE4Cb80_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb80_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    53.75% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb82 {
    animation-name: eqepmVhE4Cb82__m, eqepmVhE4Cb82_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eqepmVhE4Cb82__m {
    0% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    58.75% {
      d: path('M226.994002,161.5213L226.994,154.053002L227.1,154.053004L227.029001,154.053002');
    }
    63.75% {
      d: path('M226.994001,149.78715L226.994,145.868001L227.0645,146.865005L226.907042,146.549048');
    }
    68.75% {
      d: path('M226.994,138.053L226.994,121.683L227.029,121.677006L228.785083,121.045093');
    }
    82.5% {
      d: path('M227.029,121.683L226.994,121.683L333.531,76.7383L333.535,76.687317');
    }
    91.25% {
      d: path('M312.365799,85.714363L312.358799,85.857968L333.531,76.7383L333.535,-14.892427');
    }
    95% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
    100% {
      d: path('M333.699999,76.722204L333.699999,76.90171L333.531,76.7383L333.535,-9.675817');
    }
  }
  @keyframes eqepmVhE4Cb82_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    93.75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb83_to {
    animation: eqepmVhE4Cb83_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb83_to__to {
    0% {
      transform: translate(291.8px, 49.646297px);
    }
    58.75% {
      transform: translate(291.8px, 49.646297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 21.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(295.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(303.3352px, 12.1px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(396.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(398.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -119.378703px);
    }
    100% {
      transform: translate(398.699999px, -119.378703px);
    }
  }
  #eqepmVhE4Cb83_tr {
    animation: eqepmVhE4Cb83_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb83_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(68deg);
    }
    70% {
      transform: rotate(68deg);
    }
    81.25% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eqepmVhE4Cb83 {
    animation: eqepmVhE4Cb83_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb83_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 0.7;
    }
    95% {
      opacity: 0.7;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb84_to {
    animation: eqepmVhE4Cb84_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb84_to__to {
    0% {
      transform: translate(291.8px, 49.646297px);
    }
    58.75% {
      transform: translate(291.8px, 49.646297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    67.5% {
      transform: translate(291.798427px, 21.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    68.75% {
      transform: translate(295.798427px, 14.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    70% {
      transform: translate(303.3352px, 12.1px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    81.25% {
      transform: translate(396.801035px, -27.740845px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    82.5% {
      transform: translate(398.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    83.75% {
      transform: translate(398.699999px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    95% {
      transform: translate(398.699999px, -119.378703px);
    }
    100% {
      transform: translate(398.699999px, -119.378703px);
    }
  }
  #eqepmVhE4Cb84_tr {
    animation: eqepmVhE4Cb84_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb84_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    67.5% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(68deg);
    }
    81.25% {
      transform: rotate(68deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eqepmVhE4Cb84 {
    animation: eqepmVhE4Cb84_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb84_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    96.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb90 {
    animation-name: eqepmVhE4Cb90__m, eqepmVhE4Cb90_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eqepmVhE4Cb90__m {
    0% {
      d: path('M32.0957,249.88L32.0957,205.147317L32.091986,205.147317L32.091986,206.146294');
    }
    16.25% {
      d: path('M32.0957,249.88L32.0957,205.147317L32.091986,205.147317L32.091986,206.146294');
    }
    23.75% {
      d: path('M32.0957,238.970909L32.0957,147.305852L32.094012,146.016652L32.094012,146.587808');
    }
    27.5% {
      d: path('M32.0957,229.88L32.0957,121.574432L32.0957,121.574432L32.091986,121.574433');
    }
    41.25% {
      d: path('M32.0957,121.677317L32.0957,121.678L222.955,76.73L222.951986,76.45812');
    }
    56.25% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
    100% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
  }
  @keyframes eqepmVhE4Cb90_c_o {
    0% {
      opacity: 0;
    }
    15% {
      opacity: 0;
    }
    16.25% {
      opacity: 1;
    }
    55% {
      opacity: 1;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb91_to {
    animation: eqepmVhE4Cb91_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb91_to__to {
    0% {
      transform: translate(96.798427px, 143.537316px);
    }
    11.25% {
      transform: translate(96.798427px, 143.537316px);
    }
    26.25% {
      transform: translate(97.111986px, 20.771297px);
    }
    27.5% {
      transform: translate(100.798427px, 15.771297px);
    }
    41.25% {
      transform: translate(284.801035px, -27.679029px);
    }
    42.5% {
      transform: translate(287.920607px, -33.028703px);
    }
    56.25% {
      transform: translate(287.921986px, -120.728703px);
    }
    100% {
      transform: translate(287.921986px, -120.728703px);
    }
  }
  #eqepmVhE4Cb91_tr {
    animation: eqepmVhE4Cb91_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb91_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(75deg);
    }
    41.25% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eqepmVhE4Cb91 {
    animation: eqepmVhE4Cb91_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb91_c_o {
    0% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    12.5% {
      opacity: 0.7;
    }
    56.25% {
      opacity: 0.7;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb92_to {
    animation: eqepmVhE4Cb92_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb92_to__to {
    0% {
      transform: translate(96.798427px, 142.237316px);
    }
    11.25% {
      transform: translate(96.798427px, 142.237316px);
    }
    26.25% {
      transform: translate(97.111986px, 20.643116px);
    }
    27.5% {
      transform: translate(100.798427px, 15.771297px);
    }
    41.25% {
      transform: translate(284.833827px, -27.703774px);
    }
    42.5% {
      transform: translate(288.011986px, -33.028703px);
    }
    56.25% {
      transform: translate(288.011986px, -120.728703px);
    }
    100% {
      transform: translate(288.011986px, -120.728703px);
    }
  }
  #eqepmVhE4Cb92_tr {
    animation: eqepmVhE4Cb92_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb92_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    26.25% {
      transform: rotate(0deg);
    }
    27.5% {
      transform: rotate(75deg);
    }
    41.25% {
      transform: rotate(75deg);
    }
    42.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eqepmVhE4Cb92 {
    animation: eqepmVhE4Cb92_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb92_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    11.25% {
      opacity: 1;
    }
    56.25% {
      opacity: 1;
    }
    57.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb94 {
    animation-name: eqepmVhE4Cb94__m, eqepmVhE4Cb94_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eqepmVhE4Cb94__m {
    0% {
      d: path('M32.0957,249.88L32.0957,239.147317L32.091986,239.147317L32.091986,239.146294');
    }
    62.5% {
      d: path('M32.0957,249.88L32.0957,239.147317L32.091986,239.147317L32.091986,239.146294');
    }
    68.75% {
      d: path('M32.0957,233.213333L32.0957,140.083968L32.095081,141.169913L32.095081,142.043052');
    }
    70% {
      d: path('M32.0957,229.88L32.0957,121.683L33.872405,121.290994L33.872405,121.290994');
    }
    72.5% {
      d: path('M32.0957,214.422474L32.0957,121.682286L50.445476,117.497228L50.445475,117.497227');
    }
    86.25% {
      d: path('M32.0957,121.677317L32.0957,121.678L222.955,76.73L222.951986,76.45812');
    }
    95% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
    100% {
      d: path('M222.955,76.422262L222.9,76.422262L222.955,76.73L222.955,-19.683594');
    }
  }
  @keyframes eqepmVhE4Cb94_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb94-stroke {
    animation: eqepmVhE4Cb94-stroke__t 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb94-stroke__t {
    0% {
      transform: matrix(0, 0.59598, -0.59598, 0, 5218.102477, 0.03756);
    }
    68.75% {
      transform: matrix(0, 0.59598, -0.59598, 0, 5218.102477, 0.03756);
    }
    70% {
      transform: matrix(0.000001, 0.646966, -0.646966, 0.000001, 8.39911, 0.085002);
    }
    100% {
      transform: matrix(0.000001, 0.646966, -0.646966, 0.000001, 8.39911, 0.085002);
    }
  }
  #eqepmVhE4Cb94-stroke-0 {
    animation: eqepmVhE4Cb94-stroke-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb94-stroke-0__c {
    0% {
      stop-color: #7ea6ff;
    }
    68.75% {
      stop-color: #7ea6ff;
    }
    70% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #eqepmVhE4Cb94-stroke-1 {
    animation: eqepmVhE4Cb94-stroke-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb94-stroke-1__c {
    0% {
      stop-color: rgba(64, 73, 168, 0);
    }
    68.75% {
      stop-color: rgba(64, 73, 168, 0);
    }
    70% {
      stop-color: rgba(126, 166, 255, 0);
    }
    100% {
      stop-color: rgba(126, 166, 255, 0);
    }
  }
  #eqepmVhE4Cb95_to {
    animation: eqepmVhE4Cb95_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb95_to__to {
    0% {
      transform: translate(97.2px, 140.121297px);
    }
    57.5% {
      transform: translate(97.2px, 140.121297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(97.2px, 20.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(101.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(114.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    86.25% {
      transform: translate(283.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(287.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -120.728703px);
    }
    100% {
      transform: translate(287.9px, -120.728703px);
    }
  }
  #eqepmVhE4Cb95_tr {
    animation: eqepmVhE4Cb95_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb95_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(75deg);
    }
    86.25% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eqepmVhE4Cb95 {
    animation: eqepmVhE4Cb95_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb95_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 0.7;
    }
    96.25% {
      opacity: 0.7;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eqepmVhE4Cb96_to {
    animation: eqepmVhE4Cb96_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb96_to__to {
    0% {
      transform: translate(97.2px, 140.121297px);
    }
    57.5% {
      transform: translate(97.2px, 140.121297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    70% {
      transform: translate(97.2px, 20.521297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    71.25% {
      transform: translate(102.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    72.5% {
      transform: translate(114.798427px, 12.497225px);
      animation-timing-function: cubic-bezier(0.357385, 0.28504, 0.680746, 0.629112);
    }
    78.75% {
      transform: translate(185.570936px, -4.302775px);
      animation-timing-function: cubic-bezier(0.323457, 0.318946, 0.641855, 0.665929);
    }
    86.25% {
      transform: translate(283.801035px, -27.302775px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    87.5% {
      transform: translate(287.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    88.75% {
      transform: translate(287.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    96.25% {
      transform: translate(287.9px, -120.228703px);
    }
    100% {
      transform: translate(287.9px, -120.228703px);
    }
  }
  #eqepmVhE4Cb96_tr {
    animation: eqepmVhE4Cb96_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb96_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(75deg);
    }
    86.25% {
      transform: rotate(75deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eqepmVhE4Cb96 {
    animation: eqepmVhE4Cb96_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eqepmVhE4Cb96_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    57.5% {
      opacity: 1;
    }
    96.25% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesBg7;
