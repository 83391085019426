// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesBg3 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)', // Replace all <stop id="...-stroke-1" stopColor="#initial-color" /> with this value
  };

  return (
    <AnimatedSvg
      id="eiDvPqynMlG1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 885 228"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="885"
      height="228"
      className="source-background"
      {...props}
    >
      <defs>
        <filter id="eiDvPqynMlG8-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eiDvPqynMlG8-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eiDvPqynMlG8-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eiDvPqynMlG8-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eiDvPqynMlG8-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eiDvPqynMlG8-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eiDvPqynMlG8-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eiDvPqynMlG8-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eiDvPqynMlG9-stroke"
          x1="0.25"
          y1="0.066987"
          x2="0.75"
          y2="0.933013"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eiDvPqynMlG9-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eiDvPqynMlG9-stroke-1"
            offset="51%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eiDvPqynMlG9-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eiDvPqynMlG10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eiDvPqynMlG10-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eiDvPqynMlG10-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eiDvPqynMlG10-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eiDvPqynMlG10-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eiDvPqynMlG10-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eiDvPqynMlG10-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eiDvPqynMlG10-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eiDvPqynMlG10-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eiDvPqynMlG12-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eiDvPqynMlG12-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eiDvPqynMlG12-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eiDvPqynMlG12-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eiDvPqynMlG12-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eiDvPqynMlG12-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eiDvPqynMlG12-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eiDvPqynMlG12-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eiDvPqynMlG13-stroke"
          x1="1296.369209"
          y1="0.106963"
          x2="1296.369209"
          y2="0.711237"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eiDvPqynMlG13-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop
            id="eiDvPqynMlG13-stroke-1"
            offset="50%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eiDvPqynMlG13-stroke-2" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eiDvPqynMlG14-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eiDvPqynMlG14-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eiDvPqynMlG14-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eiDvPqynMlG14-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eiDvPqynMlG14-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eiDvPqynMlG14-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eiDvPqynMlG14-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eiDvPqynMlG14-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eiDvPqynMlG14-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eiDvPqynMlG16-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eiDvPqynMlG16-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eiDvPqynMlG16-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eiDvPqynMlG16-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eiDvPqynMlG16-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eiDvPqynMlG16-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eiDvPqynMlG16-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eiDvPqynMlG16-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eiDvPqynMlG17-stroke"
          x1="-2.178928"
          y1="0.014813"
          x2="-2.178928"
          y2="0.603131"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eiDvPqynMlG17-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eiDvPqynMlG17-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="eiDvPqynMlG18-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eiDvPqynMlG18-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eiDvPqynMlG18-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eiDvPqynMlG18-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eiDvPqynMlG18-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eiDvPqynMlG18-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eiDvPqynMlG18-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eiDvPqynMlG18-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eiDvPqynMlG18-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eiDvPqynMlG23-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eiDvPqynMlG23-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eiDvPqynMlG23-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eiDvPqynMlG23-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eiDvPqynMlG23-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eiDvPqynMlG23-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eiDvPqynMlG23-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eiDvPqynMlG23-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eiDvPqynMlG24-stroke"
          x1="14.740086"
          y1="0.113522"
          x2="14.740086"
          y2="1.086358"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eiDvPqynMlG24-stroke-0"
            offset="16%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eiDvPqynMlG24-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eiDvPqynMlG25-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eiDvPqynMlG25-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eiDvPqynMlG25-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eiDvPqynMlG25-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eiDvPqynMlG25-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eiDvPqynMlG25-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eiDvPqynMlG25-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eiDvPqynMlG25-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eiDvPqynMlG25-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eiDvPqynMlG27-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eiDvPqynMlG27-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eiDvPqynMlG27-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eiDvPqynMlG27-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eiDvPqynMlG27-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eiDvPqynMlG27-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eiDvPqynMlG27-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eiDvPqynMlG27-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eiDvPqynMlG28-stroke"
          x1="16.702795"
          y1="0.143462"
          x2="16.702794"
          y2="1.166984"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eiDvPqynMlG28-stroke-0"
            offset="15%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eiDvPqynMlG28-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eiDvPqynMlG29-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eiDvPqynMlG29-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eiDvPqynMlG29-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eiDvPqynMlG29-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eiDvPqynMlG29-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eiDvPqynMlG29-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eiDvPqynMlG29-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eiDvPqynMlG29-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eiDvPqynMlG29-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eiDvPqynMlG31-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eiDvPqynMlG31-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eiDvPqynMlG31-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eiDvPqynMlG31-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eiDvPqynMlG31-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eiDvPqynMlG31-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eiDvPqynMlG31-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eiDvPqynMlG31-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eiDvPqynMlG32-stroke"
          x1="15.538313"
          y1="0.122815"
          x2="15.553068"
          y2="1"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eiDvPqynMlG32-stroke-0"
            offset="16%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eiDvPqynMlG32-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eiDvPqynMlG33-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eiDvPqynMlG33-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eiDvPqynMlG33-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eiDvPqynMlG33-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eiDvPqynMlG33-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eiDvPqynMlG33-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eiDvPqynMlG33-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eiDvPqynMlG33-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eiDvPqynMlG33-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eiDvPqynMlG39-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eiDvPqynMlG39-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eiDvPqynMlG39-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eiDvPqynMlG39-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eiDvPqynMlG39-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eiDvPqynMlG39-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eiDvPqynMlG39-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eiDvPqynMlG39-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eiDvPqynMlG40-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="matrix(0 0.605405 -0.605405 0 364.54519 0.098425)"
        >
          <stop
            id="eiDvPqynMlG40-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eiDvPqynMlG40-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <filter id="eiDvPqynMlG41-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eiDvPqynMlG41-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eiDvPqynMlG41-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eiDvPqynMlG41-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eiDvPqynMlG41-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eiDvPqynMlG41-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eiDvPqynMlG41-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eiDvPqynMlG41-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eiDvPqynMlG41-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eiDvPqynMlG43-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eiDvPqynMlG43-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eiDvPqynMlG43-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eiDvPqynMlG43-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eiDvPqynMlG43-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eiDvPqynMlG43-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eiDvPqynMlG43-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eiDvPqynMlG43-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eiDvPqynMlG44-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="matrix(0 0.54679 -0.54679 0 47.991973 0.096984)"
        >
          <stop
            id="eiDvPqynMlG44-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eiDvPqynMlG44-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <linearGradient
          id="eiDvPqynMlG44-stroke-g1"
          x1="5645.834275"
          y1="69.643326"
          x2="5645.834275"
          y2="96.882558"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)"
        >
          <stop id="eiDvPqynMlG44-stroke-g1-0" offset="0%" stopColor="#7ea6ff" />
          <stop id="eiDvPqynMlG44-stroke-g1-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eiDvPqynMlG45-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eiDvPqynMlG45-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eiDvPqynMlG45-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eiDvPqynMlG45-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eiDvPqynMlG45-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eiDvPqynMlG45-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eiDvPqynMlG45-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eiDvPqynMlG45-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eiDvPqynMlG45-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
      </defs>
      <g transform="translate(0-1.25894)" mask="url(#eiDvPqynMlG47)">
        <g transform="translate(.000001 0)">
          <g transform="translate(.088013 1.012683)">
            <g transform="translate(.000001 0.624996)">
              <path d="M721.199,232.865v-112.051L591.532,76.7311l.008-76.992999" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#eiDvPqynMlG8-filter)">
                <path
                  id="eiDvPqynMlG9"
                  d="M692.384111,111.206804v-.118885L591.532,76.7311c0,0,.008-7.785355.008-7.785355"
                  transform="translate(65-105.000001)"
                  fill="none"
                  stroke="url(#eiDvPqynMlG9-stroke)"
                  strokeLinecap="round"
                />
                <g id="eiDvPqynMlG10_to" transform="translate(656.9,-35.643085)">
                  <rect
                    id="eiDvPqynMlG10"
                    width="8.75"
                    height="15"
                    rx="0"
                    ry="0"
                    transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                    opacity="0.7"
                    filter="url(#eiDvPqynMlG10-filter)"
                    fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eiDvPqynMlG11_to" transform="translate(656.9,-35.643085)">
                  <rect
                    id="eiDvPqynMlG11"
                    width="10"
                    height="20"
                    rx="0"
                    ry="0"
                    transform="scale(0.5,0.5) translate(-5,-10)"
                    fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eiDvPqynMlG12-filter)">
                <path
                  id="eiDvPqynMlG13"
                  d="M721.199,252.865v-4.051l-.006-.917698c0,0,0-.755649,0-.755649"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eiDvPqynMlG13-stroke)"
                  strokeLinecap="round"
                />
                <g id="eiDvPqynMlG14_to" transform="translate(786.2,139.146297)">
                  <g id="eiDvPqynMlG14_tr" transform="rotate(0)">
                    <rect
                      id="eiDvPqynMlG14"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eiDvPqynMlG14-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eiDvPqynMlG15_to" transform="translate(786.2,139.146297)">
                  <g id="eiDvPqynMlG15_tr" transform="rotate(0)">
                    <rect
                      id="eiDvPqynMlG15"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eiDvPqynMlG16-filter)">
                <path
                  id="eiDvPqynMlG17"
                  d="M721.199,252.865l.101-5.724347v0c0,0-1.107-1-1.107-1"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eiDvPqynMlG17-stroke)"
                  strokeLinecap="round"
                />
                <g id="eiDvPqynMlG18_to" transform="translate(786.3,138.146297)">
                  <g id="eiDvPqynMlG18_tr" transform="rotate(0)">
                    <rect
                      id="eiDvPqynMlG18"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eiDvPqynMlG18-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eiDvPqynMlG19_to" transform="translate(786.3,138.146297)">
                  <g id="eiDvPqynMlG19_tr" transform="rotate(0)">
                    <rect
                      id="eiDvPqynMlG19"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(60 0)" mask="url(#eiDvPqynMlG35)">
              <g>
                <path
                  d="M384.283,138.189l.000004-.338506.346514-137.806744h-.346517"
                  transform="translate(0 0.202507)"
                  fill="none"
                  stroke="#6069ca"
                />
                <g transform="translate(-65 105.000001)" filter="url(#eiDvPqynMlG23-filter)">
                  <path
                    id="eiDvPqynMlG24"
                    d="M384.1,138.189v-16.506l.529502-25.007435c0,0,0,.176087,0,.176087"
                    transform="translate(65-104.797494)"
                    opacity="0.8"
                    fill="none"
                    stroke="url(#eiDvPqynMlG24-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eiDvPqynMlG25_to" transform="translate(449.5,-6.52346)">
                    <rect
                      id="eiDvPqynMlG25"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0.7"
                      filter="url(#eiDvPqynMlG25-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="eiDvPqynMlG26_to" transform="translate(449.5,-6.52346)">
                    <rect
                      id="eiDvPqynMlG26"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eiDvPqynMlG27-filter)">
                  <path
                    id="eiDvPqynMlG28"
                    d="M384.1,185.189v-16.506l.529502-25.007435c0,0,0,.176087,0,.176087"
                    transform="translate(65-104.797494)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eiDvPqynMlG28-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eiDvPqynMlG29_to" transform="translate(449.5,43.47654)">
                    <rect
                      id="eiDvPqynMlG29"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#eiDvPqynMlG29-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="eiDvPqynMlG30_to" transform="translate(449.5,43.47654)">
                    <rect
                      id="eiDvPqynMlG30"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g transform="translate(-65 105.000001)" filter="url(#eiDvPqynMlG31-filter)">
                  <path
                    id="eiDvPqynMlG32"
                    d="M384.411986,188.189v-16.506l.217516-25.007435c0,0,0,.176087,0,.176087"
                    transform="translate(65-104.797494)"
                    opacity="0"
                    fill="none"
                    stroke="url(#eiDvPqynMlG32-stroke)"
                    strokeLinecap="round"
                  />
                  <g id="eiDvPqynMlG33_to" transform="translate(449.5,43.47654)">
                    <rect
                      id="eiDvPqynMlG33"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#eiDvPqynMlG33-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                  <g id="eiDvPqynMlG34_to" transform="translate(449.5,43.47654)">
                    <rect
                      id="eiDvPqynMlG34"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <mask id="eiDvPqynMlG35" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
                <rect
                  width="80"
                  height="142.70764"
                  rx="0"
                  ry="0"
                  transform="matrix(1 0 0 0.963299 341.931986 0.75214)"
                  fill="#fff"
                  strokeWidth="0"
                />
              </mask>
            </g>
            <g>
              <path d="M161.867,229.879v-108.202L296.68,76.7222v-76.413669" fill="none" stroke="#6069ca" />
              <g transform="translate(-65 105.000001)" filter="url(#eiDvPqynMlG39-filter)">
                <path
                  id="eiDvPqynMlG40"
                  d="M161.867,259.879v-6.389002c0,0-.017-2.218699-.017-2.218699l-.051573-1.875005"
                  transform="translate(64.996501-105.000001)"
                  opacity="0"
                  fill="none"
                  fillRule="evenodd"
                  stroke="url(#eiDvPqynMlG40-stroke)"
                  strokeLinecap="round"
                />
                <g id="eiDvPqynMlG41_to" transform="translate(226.8,140.246297)">
                  <g id="eiDvPqynMlG41_tr" transform="rotate(0)">
                    <rect
                      id="eiDvPqynMlG41"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#eiDvPqynMlG41-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eiDvPqynMlG42_to" transform="translate(226.8,140.246297)">
                  <g id="eiDvPqynMlG42_tr" transform="rotate(0)">
                    <rect
                      id="eiDvPqynMlG42"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eiDvPqynMlG43-filter)">
                <path
                  id="eiDvPqynMlG44"
                  d="M161.867,259.879v-6.389002l-.017-2.218699-.051573-1.875005"
                  transform="translate(65-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eiDvPqynMlG44-stroke)"
                  strokeLinecap="round"
                />
                <g id="eiDvPqynMlG45_to" transform="translate(226.8,139.146297)">
                  <g id="eiDvPqynMlG45_tr" transform="rotate(0)">
                    <rect
                      id="eiDvPqynMlG45"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eiDvPqynMlG45-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eiDvPqynMlG46_to" transform="translate(226.8,139.146297)">
                  <g id="eiDvPqynMlG46_tr" transform="rotate(0)">
                    <rect
                      id="eiDvPqynMlG46"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <mask id="eiDvPqynMlG47" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="825.762291"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #eiDvPqynMlG9 {
    animation-name: eiDvPqynMlG9__m, eiDvPqynMlG9_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eiDvPqynMlG9__m {
    0% {
      d: path(
        'M692.384111,111.206804L692.384111,111.087919L591.532,76.7311C591.532,76.7311,591.54,68.945745,591.54,68.945745'
      );
    }
    3.75% {
      d: path(
        'M649.161778,96.431503L649.161778,96.498797L591.532,76.7311C591.532,76.7311,591.54,19.285326,591.54,19.285326'
      );
    }
    8.75% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-20.261899,591.54,-20.261899'
      );
    }
    100% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-20.261899,591.54,-20.261899'
      );
    }
  }
  @keyframes eiDvPqynMlG9_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    3.75% {
      opacity: 1;
    }
    10% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eiDvPqynMlG10_to {
    animation: eiDvPqynMlG10_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG10_to__to {
    0% {
      transform: translate(656.9px, -35.643085px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #eiDvPqynMlG10 {
    animation: eiDvPqynMlG10_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG10_c_o {
    0% {
      opacity: 0.7;
    }
    5% {
      opacity: 0.7;
    }
    13.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eiDvPqynMlG11_to {
    animation: eiDvPqynMlG11_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG11_to__to {
    0% {
      transform: translate(656.9px, -35.643085px);
      animation-timing-function: cubic-bezier(0.243859, 0.507291, 0.553051, 0.905409);
    }
    10% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #eiDvPqynMlG11 {
    animation: eiDvPqynMlG11_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG11_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    10% {
      opacity: 1;
    }
    11.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eiDvPqynMlG13 {
    animation-name: eiDvPqynMlG13__m, eiDvPqynMlG13_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eiDvPqynMlG13__m {
    0% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    22.5% {
      d: path(
        'M721.199,252.865L721.199,248.814L721.193,247.896302C721.193,247.896302,721.193,247.140653,721.193,247.140653'
      );
    }
    30% {
      d: path(
        'M721.199,242.865L721.199,212.314L721.03054,210.946856C721.03054,210.946856,721.013534,209.569032,721.013534,209.569032'
      );
    }
    36.25% {
      d: path(
        'M721.199,234.531667L721.199,136.064L721.192993,131.635822C721.192993,131.635822,721.192993,128.271298,721.192993,128.271298'
      );
    }
    37.5% {
      d: path(
        'M721.199,232.865L721.199,120.814L721.192993,120.949437C721.192993,120.949437,721.192993,120.410429,721.192993,120.410429'
      );
    }
    50% {
      d: path('M721.199,121.057004L721.199,120.814L591.532,76.7311C591.532,76.7311,591.54,75.862215,591.54,75.862215');
    }
    61.25% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-0.261899,591.54,-0.261899'
      );
    }
    100% {
      d: path(
        'M591.532001,76.731102L591.532001,77.046634L591.532,76.7311C591.532,76.7311,591.54,-0.261899,591.54,-0.261899'
      );
    }
  }
  @keyframes eiDvPqynMlG13_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    57.5% {
      opacity: 1;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eiDvPqynMlG14_to {
    animation: eiDvPqynMlG14_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG14_to__to {
    0% {
      transform: translate(786.2px, 139.146297px);
    }
    22.5% {
      transform: translate(786.2px, 139.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.2px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(784.5px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(774.3352px, 11.841812px);
      animation-timing-function: cubic-bezier(0.357029, 0.285792, 0.680857, 0.629398);
    }
    43.75% {
      transform: translate(725.704186px, -4.928812px);
      animation-timing-function: cubic-bezier(0.323844, 0.318128, 0.641658, 0.665649);
    }
    50% {
      transform: translate(658.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(656.920607px, -33.708246px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -41.280793px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -119.728703px);
    }
    100% {
      transform: translate(656.9px, -119.728703px);
    }
  }
  #eiDvPqynMlG14_tr {
    animation: eiDvPqynMlG14_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG14_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(-70deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eiDvPqynMlG14 {
    animation: eiDvPqynMlG14_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG14_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 0.7;
    }
    62.5% {
      opacity: 0.7;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eiDvPqynMlG15_to {
    animation: eiDvPqynMlG15_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG15_to__to {
    0% {
      transform: translate(786.2px, 139.146297px);
    }
    22.5% {
      transform: translate(786.2px, 139.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    36.25% {
      transform: translate(786.2px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    37.5% {
      transform: translate(783.150027px, 15.353271px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    38.75% {
      transform: translate(773.8px, 11.9px);
      animation-timing-function: cubic-bezier(0.357029, 0.285792, 0.680857, 0.629398);
    }
    43.75% {
      transform: translate(724.981387px, -5.083048px);
      animation-timing-function: cubic-bezier(0.323844, 0.318128, 0.641658, 0.665649);
    }
    50% {
      transform: translate(657.801035px, -27.7px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    51.25% {
      transform: translate(656.851035px, -33.708246px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    52.5% {
      transform: translate(656.9px, -41.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    62.5% {
      transform: translate(656.9px, -121.228703px);
    }
    100% {
      transform: translate(656.9px, -121.228703px);
    }
  }
  #eiDvPqynMlG15_tr {
    animation: eiDvPqynMlG15_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    37.5% {
      transform: rotate(-70deg);
    }
    50% {
      transform: rotate(-70deg);
    }
    51.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eiDvPqynMlG15 {
    animation: eiDvPqynMlG15_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG15_c_o {
    0% {
      opacity: 0;
    }
    21.25% {
      opacity: 0;
    }
    22.5% {
      opacity: 1;
    }
    62.5% {
      opacity: 1;
    }
    63.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eiDvPqynMlG17 {
    animation-name: eiDvPqynMlG17__m, eiDvPqynMlG17_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eiDvPqynMlG17__m {
    0% {
      d: path(
        'M721.199,252.865L721.3,247.140653L721.3,247.140653C721.3,247.140653,720.193,246.140653,720.193,246.140653'
      );
    }
    68.75% {
      d: path(
        'M721.199,252.865L721.3,247.140653L721.3,247.140653C721.3,247.140653,720.193,246.140653,720.193,246.140653'
      );
    }
    76.25% {
      d: path(
        'M721.199,242.865L721.3,209.569032L721.199,209.569032C721.199,209.569032,720.199,208.569032,720.199,208.569032'
      );
    }
    82.5% {
      d: path(
        'M721.199,234.531667L721.199,130.535155L721.170923,130.535155C721.170923,130.535155,720.168089,129.535155,720.168089,129.535155'
      );
    }
    83.75% {
      d: path('M721.199,232.865L721.199,120.814L718.199,119.814C718.199,119.814,718.199,119.72838,718.199,119.72838');
    }
    91.25% {
      d: path(
        'M721.199,165.780202L721.199,120.814L650.555537,96.771261C650.555537,96.771261,647.920051,95.99597,647.920051,95.99597'
      );
    }
    96.25% {
      d: path('M721.199,121.057004L721.199,120.814L591.532,76.7311C591.532,76.7311,591.54,75.862215,591.54,75.862215');
    }
    100% {
      d: path(
        'M692.384111,111.206804L692.384111,111.087919L591.532,76.7311C591.532,76.7311,591.54,68.945745,591.54,68.945745'
      );
    }
  }
  @keyframes eiDvPqynMlG17_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eiDvPqynMlG18_to {
    animation: eiDvPqynMlG18_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG18_to__to {
    0% {
      transform: translate(786.3px, 138.146297px);
    }
    68.75% {
      transform: translate(786.3px, 138.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.3px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(784.29007px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(774.3352px, 11.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(659.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(656.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -40.096471px);
    }
  }
  #eiDvPqynMlG18_tr {
    animation: eiDvPqynMlG18_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG18_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-70deg);
    }
    96.25% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eiDvPqynMlG18 {
    animation: eiDvPqynMlG18_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG18_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #eiDvPqynMlG19_to {
    animation: eiDvPqynMlG19_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG19_to__to {
    0% {
      transform: translate(786.3px, 138.146297px);
    }
    68.75% {
      transform: translate(786.3px, 138.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    82.5% {
      transform: translate(786.3px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    83.75% {
      transform: translate(784.29007px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    85% {
      transform: translate(773.8px, 11.9px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    96.25% {
      transform: translate(659.801035px, -27.231881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    97.5% {
      transform: translate(656.851035px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    98.75% {
      transform: translate(656.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.299023, 0.335662, 0.607116, 0.694327);
    }
    100% {
      transform: translate(656.9px, -40.096471px);
    }
  }
  #eiDvPqynMlG19_tr {
    animation: eiDvPqynMlG19_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG19_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    83.75% {
      transform: rotate(-70deg);
    }
    96.25% {
      transform: rotate(-70deg);
    }
    97.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eiDvPqynMlG19 {
    animation: eiDvPqynMlG19_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG19_c_o {
    0% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eiDvPqynMlG24 {
    animation-name: eiDvPqynMlG24__m, eiDvPqynMlG24_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eiDvPqynMlG24__m {
    0% {
      d: path(
        'M384.1,138.189L384.1,121.683L384.629502,96.675565C384.629502,96.675565,384.629502,96.851652,384.629502,96.851652'
      );
    }
    6.25% {
      d: path(
        'M384.1,119.189L384.1,92.683L384.629502,67.675565C384.629502,67.675565,384.629502,67.851652,384.629502,67.851652'
      );
    }
    20% {
      d: path(
        'M384.1,-8.811L384.1,-35.317L384.629502,-60.324435C384.629502,-60.324435,384.629502,-60.148348,384.629502,-60.148348'
      );
    }
    100% {
      d: path(
        'M384.1,-8.811L384.1,-35.317L384.629502,-60.324435C384.629502,-60.324435,384.629502,-60.148348,384.629502,-60.148348'
      );
    }
  }
  @keyframes eiDvPqynMlG24_c_o {
    0% {
      opacity: 0.8;
    }
    15% {
      opacity: 0.8;
    }
    23.75% {
      opacity: 0.5;
    }
    100% {
      opacity: 0.5;
    }
  }
  #eiDvPqynMlG25_to {
    animation: eiDvPqynMlG25_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG25_to__to {
    0% {
      transform: translate(449.5px, -6.52346px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    6.25% {
      transform: translate(449.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(449.5px, -118.878703px);
    }
    100% {
      transform: translate(449.5px, -118.878703px);
    }
  }
  #eiDvPqynMlG25 {
    animation: eiDvPqynMlG25_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG25_c_o {
    0% {
      opacity: 0.7;
    }
    20% {
      opacity: 0.7;
    }
    21.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eiDvPqynMlG26_to {
    animation: eiDvPqynMlG26_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG26_to__to {
    0% {
      transform: translate(449.5px, -6.52346px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    6.25% {
      transform: translate(449.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    20% {
      transform: translate(449.5px, -118.878703px);
    }
    100% {
      transform: translate(449.5px, -118.878703px);
    }
  }
  #eiDvPqynMlG26 {
    animation: eiDvPqynMlG26_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG26_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    16.25% {
      opacity: 0.8;
    }
    23.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eiDvPqynMlG28 {
    animation-name: eiDvPqynMlG28__m, eiDvPqynMlG28_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eiDvPqynMlG28__m {
    0% {
      d: path(
        'M384.1,185.189L384.1,168.683L384.629502,143.675565C384.629502,143.675565,384.629502,143.851652,384.629502,143.851652'
      );
    }
    32.5% {
      d: path(
        'M384.1,185.189L384.1,168.683L384.629502,143.675565C384.629502,143.675565,384.629502,143.851652,384.629502,143.851652'
      );
    }
    50% {
      d: path(
        'M384.1,138.189L384.1,121.683L384.629502,96.675565C384.629502,96.675565,384.629502,96.851652,384.629502,96.851652'
      );
    }
    56.25% {
      d: path(
        'M384.1,119.189L384.1,92.683L384.629502,67.675565C384.629502,67.675565,384.629502,67.851652,384.629502,67.851652'
      );
    }
    70% {
      d: path(
        'M384.1,-8.811L384.1,-35.317L384.629502,-60.324435C384.629502,-60.324435,384.629502,-60.148348,384.629502,-60.148348'
      );
    }
    100% {
      d: path(
        'M384.1,-8.811L384.1,-35.317L384.629502,-60.324435C384.629502,-60.324435,384.629502,-60.148348,384.629502,-60.148348'
      );
    }
  }
  @keyframes eiDvPqynMlG28_c_o {
    0% {
      opacity: 0;
    }
    31.25% {
      opacity: 0;
    }
    32.5% {
      opacity: 0.8;
    }
    50% {
      opacity: 0.8;
    }
    65% {
      opacity: 0.8;
    }
    73.75% {
      opacity: 0.5;
    }
    100% {
      opacity: 0.5;
    }
  }
  #eiDvPqynMlG29_to {
    animation: eiDvPqynMlG29_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG29_to__to {
    0% {
      transform: translate(449.5px, 43.47654px);
    }
    32.5% {
      transform: translate(449.5px, 43.47654px);
    }
    50% {
      transform: translate(449.5px, -8.02346px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    56.25% {
      transform: translate(449.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    70% {
      transform: translate(449.5px, -118.878703px);
    }
    100% {
      transform: translate(449.5px, -118.878703px);
    }
  }
  #eiDvPqynMlG29 {
    animation: eiDvPqynMlG29_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG29_c_o {
    0% {
      opacity: 0;
    }
    31.25% {
      opacity: 0;
    }
    32.5% {
      opacity: 0.7;
    }
    50% {
      opacity: 0.7;
    }
    70% {
      opacity: 0.7;
    }
    71.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eiDvPqynMlG30_to {
    animation: eiDvPqynMlG30_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG30_to__to {
    0% {
      transform: translate(449.5px, 43.47654px);
    }
    32.5% {
      transform: translate(449.5px, 43.47654px);
    }
    50% {
      transform: translate(449.5px, -8.02346px);
      animation-timing-function: cubic-bezier(0.321991, 0.322365, 0.642883, 0.667064);
    }
    56.25% {
      transform: translate(449.5px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    70% {
      transform: translate(449.5px, -118.878703px);
    }
    100% {
      transform: translate(449.5px, -118.878703px);
    }
  }
  #eiDvPqynMlG30 {
    animation: eiDvPqynMlG30_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG30_c_o {
    0% {
      opacity: 0;
    }
    31.25% {
      opacity: 0;
    }
    32.5% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    63.75% {
      opacity: 0.8;
    }
    73.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eiDvPqynMlG32 {
    animation-name: eiDvPqynMlG32__m, eiDvPqynMlG32_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eiDvPqynMlG32__m {
    0% {
      d: path(
        'M384.411986,188.189L384.411986,171.683L384.629502,146.675565C384.629502,146.675565,384.629502,146.851652,384.629502,146.851652'
      );
    }
    82.5% {
      d: path(
        'M384.411986,188.189L384.411986,171.683L384.629502,146.675565C384.629502,146.675565,384.629502,146.851652,384.629502,146.851652'
      );
    }
    100% {
      d: path(
        'M384.1,138.189L384.311986,138.189L384.629502,96.675565C384.629502,96.675565,384.629502,96.851652,384.629502,96.851652'
      );
    }
  }
  @keyframes eiDvPqynMlG32_c_o {
    0% {
      opacity: 0;
    }
    81.25% {
      opacity: 0;
    }
    82.5% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.8;
    }
  }
  #eiDvPqynMlG33_to {
    animation: eiDvPqynMlG33_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG33_to__to {
    0% {
      transform: translate(449.5px, 43.47654px);
    }
    82.5% {
      transform: translate(449.5px, 43.47654px);
    }
    100% {
      transform: translate(449.5px, -8.02346px);
    }
  }
  #eiDvPqynMlG33 {
    animation: eiDvPqynMlG33_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG33_c_o {
    0% {
      opacity: 0;
    }
    81.25% {
      opacity: 0;
    }
    82.5% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.7;
    }
  }
  #eiDvPqynMlG34_to {
    animation: eiDvPqynMlG34_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG34_to__to {
    0% {
      transform: translate(449.5px, 43.47654px);
    }
    82.5% {
      transform: translate(449.5px, 43.47654px);
    }
    100% {
      transform: translate(449.5px, -8.02346px);
    }
  }
  #eiDvPqynMlG34 {
    animation: eiDvPqynMlG34_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG34_c_o {
    0% {
      opacity: 0;
    }
    81.25% {
      opacity: 0;
    }
    82.5% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  #eiDvPqynMlG40 {
    animation-name: eiDvPqynMlG40__m, eiDvPqynMlG40_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eiDvPqynMlG40__m {
    0% {
      d: path(
        'M161.867,259.879L161.867,253.489998C161.867,253.489998,161.85,251.271299,161.85,251.271299L161.798427,249.396294'
      );
    }
    11.25% {
      d: path(
        'M161.867,259.879L161.867,253.489998C161.867,253.489998,161.85,251.271299,161.85,251.271299L161.798427,249.396294'
      );
    }
    20% {
      d: path(
        'M161.867,236.545667L161.867,150.968777C161.867,150.968777,161.85,151.211049,161.85,151.211049L161.85,143.127495'
      );
    }
    22.5% {
      d: path(
        'M161.867,229.879L161.867,121.677C161.867,121.677,161.85,122.622406,161.85,122.622406L161.801927,122.648939'
      );
    }
    32.5% {
      d: path(
        'M161.867,143.3214L161.867,121.677C161.867,121.677,277.422107,83.120632,277.422107,83.120632L280.268001,82.288075'
      );
    }
    35% {
      d: path('M161.867,121.682L161.867,121.677C161.867,121.677,296.68,76.7222,296.68,76.7222L296.854534,76.35604');
    }
    42.5% {
      d: path(
        'M235.303182,96.99487L235.521364,96.992597C235.521364,96.992597,296.68,76.7222,296.68,76.7222L296.68,3.623396'
      );
    }
    47.5% {
      d: path('M296.5,76.422261L296.9,76.422262C296.9,76.422262,296.68,76.7222,296.68,76.7222L296.68,-19.691469');
    }
    100% {
      d: path('M296.5,76.422261L296.9,76.422262C296.9,76.422262,296.68,76.7222,296.68,76.7222L296.68,-19.691469');
    }
  }
  @keyframes eiDvPqynMlG40_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    45% {
      opacity: 1;
    }
    51.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eiDvPqynMlG40-stroke-0 {
    animation: eiDvPqynMlG40-stroke-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG40-stroke-0__c {
    0% {
      stop-color: #7ea6ff;
    }
    17.5% {
      stop-color: #7ea6ff;
    }
    30% {
      stop-color: #7ea6ff;
    }
    33.75% {
      stop-color: #7ea6ff;
    }
    35% {
      stop-color: #7ea6ff;
    }
    38.75% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #eiDvPqynMlG40-stroke-1 {
    animation: eiDvPqynMlG40-stroke-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG40-stroke-1__c {
    0% {
      stop-color: rgba(96, 105, 202, 0);
    }
    17.5% {
      stop-color: rgba(96, 105, 202, 0);
    }
    30% {
      stop-color: rgba(96, 105, 202, 0);
    }
    33.75% {
      stop-color: rgba(96, 105, 202, 0);
    }
    35% {
      stop-color: rgba(96, 105, 202, 0);
    }
    38.75% {
      stop-color: rgba(96, 105, 202, 0);
    }
    100% {
      stop-color: rgba(96, 105, 202, 0);
    }
  }
  #eiDvPqynMlG41_to {
    animation: eiDvPqynMlG41_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG41_to__to {
    0% {
      transform: translate(226.8px, 140.246297px);
    }
    6.25% {
      transform: translate(226.8px, 140.246297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 23.771297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(230.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(239.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(359.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(361.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -120.728703px);
    }
    100% {
      transform: translate(361.9px, -120.728703px);
    }
  }
  #eiDvPqynMlG41_tr {
    animation: eiDvPqynMlG41_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG41_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    23.75% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eiDvPqynMlG41 {
    animation: eiDvPqynMlG41_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG41_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 0.7;
    }
    48.75% {
      opacity: 0.7;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eiDvPqynMlG42_to {
    animation: eiDvPqynMlG42_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG42_to__to {
    0% {
      transform: translate(226.8px, 140.246297px);
    }
    6.25% {
      transform: translate(226.8px, 140.246297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    21.25% {
      transform: translate(226.798427px, 22.271297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    22.5% {
      transform: translate(231.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    23.75% {
      transform: translate(240.798427px, 12.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    33.75% {
      transform: translate(360.801035px, -27.891881px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    35% {
      transform: translate(361.851035px, -32.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    36.25% {
      transform: translate(361.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    48.75% {
      transform: translate(361.9px, -121.228703px);
    }
    100% {
      transform: translate(361.9px, -121.228703px);
    }
  }
  #eiDvPqynMlG42_tr {
    animation: eiDvPqynMlG42_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG42_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(0deg);
    }
    22.5% {
      transform: rotate(72deg);
    }
    33.75% {
      transform: rotate(72deg);
    }
    35% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eiDvPqynMlG42 {
    animation: eiDvPqynMlG42_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG42_c_o {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
    }
    6.25% {
      opacity: 1;
    }
    48.75% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eiDvPqynMlG44 {
    animation-name: eiDvPqynMlG44__m, eiDvPqynMlG44_c_o, eiDvPqynMlG44_s_p;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eiDvPqynMlG44__m {
    0% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    56.25% {
      d: path('M161.867,259.879L161.867,253.489998L161.85,251.271299L161.798427,249.396294');
    }
    61.25% {
      d: path('M161.867,242.736143L161.867,184.723679L161.859714,183.772808L161.938265,182.493488');
    }
    65% {
      d: path('M161.867,229.879L161.867,122.648939L161.867,122.648939L162.043144,121.816383');
    }
    66.25% {
      d: path('M161.867,220.862583L161.867,122.567944L161.867,121.677L170.781167,118.726213');
    }
    71.25% {
      d: path('M161.867,184.796916L161.867,122.243964L210.889909,105.3298L213.197284,104.616272');
    }
    78.75% {
      d: path('M161.867,121.682L161.867,121.677L296.68,76.7222L296.68,76.687317');
    }
    81.25% {
      d: path('M182.848766,114.628534L182.911104,114.624313L296.68,76.7222L296.68,64.811911');
    }
    87.5% {
      d: path('M235.303182,96.99487L235.521364,96.992597L296.68,76.7222L296.68,3.623396');
    }
    93.75% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
    100% {
      d: path('M296.5,76.422261L296.9,76.422262L296.68,76.7222L296.68,-19.691469');
    }
  }
  @keyframes eiDvPqynMlG44_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    86.25% {
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes eiDvPqynMlG44_s_p {
    0% {
      stroke: url(#eiDvPqynMlG44-stroke);
    }
    61.25% {
      stroke: url(#eiDvPqynMlG44-stroke);
    }
    80% {
      stroke: url(#eiDvPqynMlG44-stroke);
    }
    81.25% {
      stroke: url(#eiDvPqynMlG44-stroke-g1);
    }
    100% {
      stroke: url(#eiDvPqynMlG44-stroke-g1);
    }
  }
  #eiDvPqynMlG44-stroke-0 {
    animation: eiDvPqynMlG44-stroke-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG44-stroke-0__c {
    0% {
      stop-color: #7ea6ff;
    }
    61.25% {
      stop-color: #7ea6ff;
    }
    80% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #eiDvPqynMlG44-stroke-1 {
    animation: eiDvPqynMlG44-stroke-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG44-stroke-1__c {
    0% {
      stop-color: rgba(64, 73, 168, 0);
    }
    61.25% {
      stop-color: rgba(64, 73, 168, 0);
    }
    80% {
      stop-color: rgba(64, 73, 168, 0);
    }
    100% {
      stop-color: rgba(64, 73, 168, 0);
    }
  }
  #eiDvPqynMlG45_to {
    animation: eiDvPqynMlG45_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG45_to__to {
    0% {
      transform: translate(226.8px, 139.146297px);
    }
    52.5% {
      transform: translate(226.8px, 139.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    65% {
      transform: translate(226.798427px, 21.021297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    66.25% {
      transform: translate(230.798427px, 15.771297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(239.3352px, 12.841812px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    78.75% {
      transform: translate(359.801035px, -27.5px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    80% {
      transform: translate(361.920607px, -31.028703px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(361.9px, -38.280793px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    93.75% {
      transform: translate(361.9px, -119.728703px);
    }
    100% {
      transform: translate(361.9px, -119.728703px);
    }
  }
  #eiDvPqynMlG45_tr {
    animation: eiDvPqynMlG45_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG45_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(72deg);
    }
    67.5% {
      transform: rotate(72deg);
    }
    78.75% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eiDvPqynMlG45 {
    animation: eiDvPqynMlG45_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG45_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 0.7;
    }
    93.75% {
      opacity: 0.7;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eiDvPqynMlG46_to {
    animation: eiDvPqynMlG46_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG46_to__to {
    0% {
      transform: translate(226.8px, 139.146297px);
    }
    52.5% {
      transform: translate(226.8px, 139.146297px);
      animation-timing-function: cubic-bezier(0.487274, 0, 0.688523, 0.529025);
    }
    65% {
      transform: translate(226.798427px, 21.021297px);
      animation-timing-function: cubic-bezier(0.371176, 0, 0.70086, 0.358594);
    }
    66.25% {
      transform: translate(231.798427px, 15.271297px);
      animation-timing-function: cubic-bezier(0.373936, 0.223768, 0.700142, 0.570873);
    }
    67.5% {
      transform: translate(240.798427px, 12.3px);
      animation-timing-function: cubic-bezier(0.359951, 0.261659, 0.64397, 0.64339);
    }
    78.75% {
      transform: translate(359.801035px, -27.452471px);
      animation-timing-function: cubic-bezier(0.398208, 0, 0.720007, 0.379184);
    }
    80% {
      transform: translate(361.851035px, -31.791881px);
      animation-timing-function: cubic-bezier(0.381826, 0.23922, 0.685909, 0.605408);
    }
    81.25% {
      transform: translate(361.9px, -38.514307px);
      animation-timing-function: cubic-bezier(0.223408, 0.430403, 0.462006, 0.926916);
    }
    93.75% {
      transform: translate(361.9px, -120.228703px);
    }
    100% {
      transform: translate(361.9px, -120.228703px);
    }
  }
  #eiDvPqynMlG46_tr {
    animation: eiDvPqynMlG46_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG46_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    65% {
      transform: rotate(0deg);
    }
    66.25% {
      transform: rotate(72deg);
    }
    78.75% {
      transform: rotate(72deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eiDvPqynMlG46 {
    animation: eiDvPqynMlG46_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eiDvPqynMlG46_c_o {
    0% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    93.75% {
      opacity: 0.8;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesBg3;
