// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesMobileBg4 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)', // Replace all <stop id="...-stroke-1" stopColor="#initial-color" /> with this value
  };

  return (
    <AnimatedSvg
      id="ek4mXt1QnJW1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 320 250"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="320"
      height="250"
      className="source-background"
      {...props}
    >
      <defs>
        <linearGradient
          id="ek4mXt1QnJW7-stroke"
          x1="0.370708"
          y1="0.690679"
          x2="0.381501"
          y2="0.997708"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="ek4mXt1QnJW7-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="ek4mXt1QnJW7-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <filter id="ek4mXt1QnJW8-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ek4mXt1QnJW8-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ek4mXt1QnJW8-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ek4mXt1QnJW8-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="ek4mXt1QnJW8-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ek4mXt1QnJW8-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ek4mXt1QnJW8-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ek4mXt1QnJW8-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="ek4mXt1QnJW9-stroke"
          x1="-5275.007951"
          y1="0.083835"
          x2="-5275.007951"
          y2="0.902057"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="ek4mXt1QnJW9-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="ek4mXt1QnJW9-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="ek4mXt1QnJW10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ek4mXt1QnJW10-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="ek4mXt1QnJW10-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ek4mXt1QnJW10-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="ek4mXt1QnJW10-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ek4mXt1QnJW10-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ek4mXt1QnJW10-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ek4mXt1QnJW10-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="ek4mXt1QnJW10-filter-blur-0" stdDeviation="3,3" result="result" />
        </filter>
        <filter id="ek4mXt1QnJW11-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ek4mXt1QnJW11-filter-blur-0" stdDeviation="0,0" result="result" />
        </filter>
        <filter id="ek4mXt1QnJW12-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ek4mXt1QnJW12-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ek4mXt1QnJW12-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ek4mXt1QnJW12-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="ek4mXt1QnJW12-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ek4mXt1QnJW12-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ek4mXt1QnJW12-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ek4mXt1QnJW12-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="ek4mXt1QnJW13-stroke"
          x1="-5275.007951"
          y1="0.083835"
          x2="-5275.007951"
          y2="0.902057"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="ek4mXt1QnJW13-stroke-0"
            offset="0%"
            stopColor={selected === 3 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="ek4mXt1QnJW13-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="ek4mXt1QnJW14-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ek4mXt1QnJW14-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="ek4mXt1QnJW14-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ek4mXt1QnJW14-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="ek4mXt1QnJW14-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ek4mXt1QnJW14-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ek4mXt1QnJW14-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ek4mXt1QnJW14-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="ek4mXt1QnJW14-filter-blur-0" stdDeviation="3,3" result="result" />
        </filter>
        <filter id="ek4mXt1QnJW15-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ek4mXt1QnJW15-filter-blur-0" stdDeviation="0,0" result="result" />
        </filter>
        <linearGradient
          id="ek4mXt1QnJW17-stroke"
          x1="0.5"
          y1="0.695258"
          x2="0.5"
          y2="0.997712"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="ek4mXt1QnJW17-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="ek4mXt1QnJW17-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <filter id="ek4mXt1QnJW18-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ek4mXt1QnJW18-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ek4mXt1QnJW18-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ek4mXt1QnJW18-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="ek4mXt1QnJW18-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ek4mXt1QnJW18-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ek4mXt1QnJW18-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ek4mXt1QnJW18-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="ek4mXt1QnJW19-stroke"
          x1="5378.246642"
          y1="-0.003697"
          x2="5378.246642"
          y2="0.750663"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="ek4mXt1QnJW19-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="ek4mXt1QnJW19-stroke-1" offset="100%" stopColor="rgba(96,105,202,0.14285714285714285)" />
          <stop id="ek4mXt1QnJW19-stroke-2" offset="100%" stopColor="rgba(91,100,197,0)" />
        </linearGradient>
        <filter id="ek4mXt1QnJW20-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ek4mXt1QnJW20-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="ek4mXt1QnJW20-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ek4mXt1QnJW20-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="ek4mXt1QnJW20-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ek4mXt1QnJW20-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ek4mXt1QnJW20-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ek4mXt1QnJW20-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="ek4mXt1QnJW20-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="ek4mXt1QnJW22-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ek4mXt1QnJW22-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ek4mXt1QnJW22-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ek4mXt1QnJW22-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="ek4mXt1QnJW22-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ek4mXt1QnJW22-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ek4mXt1QnJW22-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ek4mXt1QnJW22-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="ek4mXt1QnJW23-stroke"
          x1="4527.576696"
          y1="0.023755"
          x2="4527.576696"
          y2="0.838274"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="ek4mXt1QnJW23-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="ek4mXt1QnJW23-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <filter id="ek4mXt1QnJW24-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ek4mXt1QnJW24-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="ek4mXt1QnJW24-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ek4mXt1QnJW24-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="ek4mXt1QnJW24-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ek4mXt1QnJW24-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ek4mXt1QnJW24-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ek4mXt1QnJW24-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="ek4mXt1QnJW24-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <linearGradient
          id="ek4mXt1QnJW27-stroke"
          x1="0.5"
          y1="0.695267"
          x2="0.5"
          y2="0.997712"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="ek4mXt1QnJW27-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="ek4mXt1QnJW27-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <filter id="ek4mXt1QnJW28-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ek4mXt1QnJW28-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ek4mXt1QnJW28-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ek4mXt1QnJW28-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="ek4mXt1QnJW28-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ek4mXt1QnJW28-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ek4mXt1QnJW28-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ek4mXt1QnJW28-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="ek4mXt1QnJW29-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="matrix(0 0.802044 -0.802044 0 2720.726221 0.040125)"
        >
          <stop
            id="ek4mXt1QnJW29-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="ek4mXt1QnJW29-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <linearGradient
          id="ek4mXt1QnJW29-stroke-g1"
          x1="-0.054141"
          y1="-0.038344"
          x2="-0.771293"
          y2="1.103791"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="ek4mXt1QnJW29-stroke-g1-0" offset="0.5%" stopColor="#7ea6ff" />
          <stop id="ek4mXt1QnJW29-stroke-g1-1" offset="100%" stopColor="rgba(80,89,185,0)" />
        </linearGradient>
        <linearGradient
          id="ek4mXt1QnJW29-stroke-g2"
          x1="1.69266"
          y1="0.182634"
          x2="1.83098"
          y2="0.859801"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="ek4mXt1QnJW29-stroke-g2-0" offset="0%" stopColor="#7ea6ff" />
          <stop id="ek4mXt1QnJW29-stroke-g2-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <filter id="ek4mXt1QnJW30-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ek4mXt1QnJW30-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="ek4mXt1QnJW30-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ek4mXt1QnJW30-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="ek4mXt1QnJW30-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ek4mXt1QnJW30-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ek4mXt1QnJW30-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ek4mXt1QnJW30-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="ek4mXt1QnJW30-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="ek4mXt1QnJW32-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ek4mXt1QnJW32-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ek4mXt1QnJW32-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ek4mXt1QnJW32-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="ek4mXt1QnJW32-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ek4mXt1QnJW32-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ek4mXt1QnJW32-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ek4mXt1QnJW32-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="ek4mXt1QnJW33-stroke"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="matrix(0 0.724522 -0.724522 0 1098.518665 0.038051)"
        >
          <stop
            id="ek4mXt1QnJW33-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="ek4mXt1QnJW33-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <filter id="ek4mXt1QnJW34-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ek4mXt1QnJW34-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="ek4mXt1QnJW34-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ek4mXt1QnJW34-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="ek4mXt1QnJW34-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ek4mXt1QnJW34-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ek4mXt1QnJW34-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ek4mXt1QnJW34-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="ek4mXt1QnJW34-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <linearGradient
          id="ek4mXt1QnJW37-stroke"
          x1="-0.002599"
          y1="1"
          x2="0.218911"
          y2="0.828707"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="ek4mXt1QnJW37-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="ek4mXt1QnJW37-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="ek4mXt1QnJW38-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ek4mXt1QnJW38-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ek4mXt1QnJW38-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ek4mXt1QnJW38-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="ek4mXt1QnJW38-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ek4mXt1QnJW38-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ek4mXt1QnJW38-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ek4mXt1QnJW38-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="ek4mXt1QnJW39-stroke"
          x1="2082.538886"
          y1="0.112885"
          x2="2082.538886"
          y2="0.762312"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="ek4mXt1QnJW39-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="ek4mXt1QnJW39-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="ek4mXt1QnJW40-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ek4mXt1QnJW40-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="ek4mXt1QnJW40-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ek4mXt1QnJW40-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="ek4mXt1QnJW40-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ek4mXt1QnJW40-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ek4mXt1QnJW40-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ek4mXt1QnJW40-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="ek4mXt1QnJW40-filter-blur-0" stdDeviation="3,3" result="result" />
        </filter>
        <filter id="ek4mXt1QnJW41-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ek4mXt1QnJW41-filter-blur-0" stdDeviation="0,0" result="result" />
        </filter>
        <filter id="ek4mXt1QnJW42-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ek4mXt1QnJW42-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="ek4mXt1QnJW42-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ek4mXt1QnJW42-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="ek4mXt1QnJW42-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ek4mXt1QnJW42-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ek4mXt1QnJW42-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ek4mXt1QnJW42-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="ek4mXt1QnJW43-stroke"
          x1="1831.004501"
          y1="0.051315"
          x2="1831.004501"
          y2="0.876195"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="ek4mXt1QnJW43-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="ek4mXt1QnJW43-stroke-1" offset="100%" stopColor="rgba(126,166,255,0)" />
        </linearGradient>
        <filter id="ek4mXt1QnJW44-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ek4mXt1QnJW44-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="ek4mXt1QnJW44-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="ek4mXt1QnJW44-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="ek4mXt1QnJW44-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="ek4mXt1QnJW44-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="ek4mXt1QnJW44-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="ek4mXt1QnJW44-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="ek4mXt1QnJW44-filter-blur-0" stdDeviation="3,3" result="result" />
        </filter>
        <filter id="ek4mXt1QnJW45-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="ek4mXt1QnJW45-filter-blur-0" stdDeviation="0,0" result="result" />
        </filter>
      </defs>
      <g transform="translate(-22-2.25894)" mask="url(#ek4mXt1QnJW46)">
        <g transform="translate(.000001 0)">
          <g transform="translate(.088013 1.012683)">
            <g transform="matrix(-1 0 0 1 357.455002 0)">
              <path
                d="M32.0957,218.88v-97.202L86.156086,77.532603v-77.224072"
                fill="none"
                stroke="url(#ek4mXt1QnJW7-stroke)"
              />
              <g transform="translate(-65 105.000001)" filter="url(#ek4mXt1QnJW8-filter)">
                <path
                  id="ek4mXt1QnJW9"
                  d="M32.0957,289.88v-43.358702v0l1-.996864"
                  transform="translate(64.994651-105.187118)"
                  opacity="0"
                  fill="none"
                  stroke="url(#ek4mXt1QnJW9-stroke)"
                  strokeLinecap="round"
                />
                <g id="ek4mXt1QnJW10_to" transform="translate(96.798427,143.537316)">
                  <g id="ek4mXt1QnJW10_tr" transform="rotate(0)">
                    <rect
                      id="ek4mXt1QnJW10"
                      width="10"
                      height="17.142857"
                      rx="0"
                      ry="0"
                      transform="scale(0.7,0.7) translate(-5,-8.571429)"
                      opacity="0"
                      filter="url(#ek4mXt1QnJW10-filter)"
                      fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="ek4mXt1QnJW11_to" transform="translate(96.798427,142.237316)">
                  <g id="ek4mXt1QnJW11_tr" transform="rotate(0)">
                    <rect
                      id="ek4mXt1QnJW11"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      filter="url(#ek4mXt1QnJW11-filter)"
                      fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      strokeMiterlimit="8"
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#ek4mXt1QnJW12-filter)">
                <path
                  id="ek4mXt1QnJW13"
                  d="M32.0957,289.88v-43.358702v0l1-.996864"
                  transform="translate(64.994651-105.187118)"
                  opacity="0"
                  fill="none"
                  stroke="url(#ek4mXt1QnJW13-stroke)"
                  strokeLinecap="round"
                />
                <g id="ek4mXt1QnJW14_to" transform="translate(96.798427,143.537316)">
                  <g id="ek4mXt1QnJW14_tr" transform="rotate(0)">
                    <rect
                      id="ek4mXt1QnJW14"
                      width="10"
                      height="17.142857"
                      rx="0"
                      ry="0"
                      transform="scale(0.7,0.7) translate(-5,-8.571429)"
                      opacity="0"
                      filter="url(#ek4mXt1QnJW14-filter)"
                      fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="ek4mXt1QnJW15_to" transform="translate(96.798427,142.237316)">
                  <g id="ek4mXt1QnJW15_tr" transform="rotate(0)">
                    <rect
                      id="ek4mXt1QnJW15"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      filter="url(#ek4mXt1QnJW15-filter)"
                      fill={selected === 3 ? selectedStyles.bulletColor : styles.bulletColor}
                      strokeMiterlimit="8"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="matrix(-1 0 0 1 392.03 0)">
              <path
                d="M161.867,218.879v-97.202L180.68,77.67163l.001986-77.363099"
                fill="none"
                stroke="url(#ek4mXt1QnJW17-stroke)"
              />
              <g transform="translate(-65 105.000001)" filter="url(#ek4mXt1QnJW18-filter)">
                <path
                  id="ek4mXt1QnJW19"
                  d="M161.867,259.879v-6.389002c0,0-.017-2.218699-.017-2.218699l-.051573-41.875005"
                  transform="translate(64.996501-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#ek4mXt1QnJW19-stroke)"
                  strokeLinecap="round"
                />
                <g id="ek4mXt1QnJW20_to" transform="translate(226.779751,128.661481)">
                  <g id="ek4mXt1QnJW20_tr" transform="rotate(0)">
                    <rect
                      id="ek4mXt1QnJW20"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#ek4mXt1QnJW20-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="ek4mXt1QnJW21_to" transform="translate(226.779751,128.661481)">
                  <g id="ek4mXt1QnJW21_tr" transform="rotate(0)">
                    <rect
                      id="ek4mXt1QnJW21"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#ek4mXt1QnJW22-filter)">
                <path
                  id="ek4mXt1QnJW23"
                  d="M161.867,259.879v-6.389002c0,0-.017-2.218699-.017-2.218699l-.051573-41.875005"
                  transform="translate(64.996501-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#ek4mXt1QnJW23-stroke)"
                  strokeLinecap="round"
                />
                <g id="ek4mXt1QnJW24_to" transform="translate(226.779751,128.661481)">
                  <g id="ek4mXt1QnJW24_tr" transform="rotate(0)">
                    <rect
                      id="ek4mXt1QnJW24"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#ek4mXt1QnJW24-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="ek4mXt1QnJW25_to" transform="translate(226.779751,128.661481)">
                  <g id="ek4mXt1QnJW25_tr" transform="rotate(0)">
                    <rect
                      id="ek4mXt1QnJW25"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(-28 0)">
              <path
                d="M161.867,218.879v-97.202L180.68,77.67163l.001986-77.363099"
                fill="none"
                stroke="url(#ek4mXt1QnJW27-stroke)"
              />
              <g transform="translate(-65 105.000001)" filter="url(#ek4mXt1QnJW28-filter)">
                <path
                  id="ek4mXt1QnJW29"
                  d="M161.867,259.879v-6.389002c0,0-.017-2.218699-.017-2.218699l-.051573-1.875005"
                  transform="translate(64.996501-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#ek4mXt1QnJW29-stroke)"
                  strokeLinecap="round"
                />
                <g id="ek4mXt1QnJW30_to" transform="translate(226.779751,128.661481)">
                  <g id="ek4mXt1QnJW30_tr" transform="rotate(0)">
                    <rect
                      id="ek4mXt1QnJW30"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#ek4mXt1QnJW30-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="ek4mXt1QnJW31_to" transform="translate(226.779751,128.661481)">
                  <g id="ek4mXt1QnJW31_tr" transform="rotate(0)">
                    <rect
                      id="ek4mXt1QnJW31"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#ek4mXt1QnJW32-filter)">
                <path
                  id="ek4mXt1QnJW33"
                  d="M161.867,259.879v-6.389002c0,0-.017-2.218699-.017-2.218699l-.051573-1.875005"
                  transform="translate(64.996501-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#ek4mXt1QnJW33-stroke)"
                  strokeLinecap="round"
                />
                <g id="ek4mXt1QnJW34_to" transform="translate(226.779751,128.661481)">
                  <g id="ek4mXt1QnJW34_tr" transform="rotate(0)">
                    <rect
                      id="ek4mXt1QnJW34"
                      width="8.75"
                      height="15"
                      rx="0"
                      ry="0"
                      transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                      opacity="0"
                      filter="url(#ek4mXt1QnJW34-filter)"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="ek4mXt1QnJW35_to" transform="translate(226.779751,128.661481)">
                  <g id="ek4mXt1QnJW35_tr" transform="rotate(0)">
                    <rect
                      id="ek4mXt1QnJW35"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g transform="translate(6 0)">
              <path
                d="M32.0957,218.88v-97.202L86.156086,77.532603v-77.224072"
                fill="none"
                stroke="url(#ek4mXt1QnJW37-stroke)"
              />
              <g transform="translate(-65 105.000001)" filter="url(#ek4mXt1QnJW38-filter)">
                <path
                  id="ek4mXt1QnJW39"
                  d="M32.0957,321.564875v-89.736339l.005349.041895v.005687"
                  transform="translate(64.994651-105.187118)"
                  opacity="0"
                  fill="none"
                  stroke="url(#ek4mXt1QnJW39-stroke)"
                  strokeLinecap="round"
                />
                <g id="ek4mXt1QnJW40_to" transform="translate(96.798427,143.537316)">
                  <g id="ek4mXt1QnJW40_tr" transform="rotate(0)">
                    <rect
                      id="ek4mXt1QnJW40"
                      width="10"
                      height="17.142857"
                      rx="0"
                      ry="0"
                      transform="scale(0.7,0.7) translate(-5,-8.571429)"
                      opacity="0"
                      filter="url(#ek4mXt1QnJW40-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="ek4mXt1QnJW41_to" transform="translate(96.798427,142.237316)">
                  <g id="ek4mXt1QnJW41_tr" transform="rotate(0)">
                    <rect
                      id="ek4mXt1QnJW41"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      filter="url(#ek4mXt1QnJW41-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      strokeMiterlimit="8"
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#ek4mXt1QnJW42-filter)">
                <path
                  id="ek4mXt1QnJW43"
                  d="M32.0957,321.564875v-79.736339l.005349.041895v.005687"
                  transform="translate(64.994651-105.187118)"
                  opacity="0"
                  fill="none"
                  stroke="url(#ek4mXt1QnJW43-stroke)"
                  strokeLinecap="round"
                />
                <g id="ek4mXt1QnJW44_to" transform="translate(96.798427,143.537316)">
                  <g id="ek4mXt1QnJW44_tr" transform="rotate(0)">
                    <rect
                      id="ek4mXt1QnJW44"
                      width="10"
                      height="17.142857"
                      rx="0"
                      ry="0"
                      transform="scale(0.7,0.7) translate(-5,-8.571429)"
                      opacity="0"
                      filter="url(#ek4mXt1QnJW44-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="ek4mXt1QnJW45_to" transform="translate(96.798427,142.237316)">
                  <g id="ek4mXt1QnJW45_tr" transform="rotate(0)">
                    <rect
                      id="ek4mXt1QnJW45"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      filter="url(#ek4mXt1QnJW45-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                      strokeMiterlimit="8"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <mask id="ek4mXt1QnJW46" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="825.762291"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #ek4mXt1QnJW9 {
    animation-name: ek4mXt1QnJW9__m, ek4mXt1QnJW9_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes ek4mXt1QnJW9__m {
    0% {
      d: path('M32.0957,289.88L32.0957,246.521298L32.0957,246.521298L33.0957,245.524434');
    }
    3.75% {
      d: path('M32.0957,289.88L32.0957,246.521298L32.0957,246.521298L33.0957,245.524434');
    }
    21.25% {
      d: path('M32.0957,219.88L32.0957,121.861988L32.0957,121.861988L33.0957,120.869117');
    }
    35% {
      d: path('M32.0957,129.146811L32.0957,121.678L85.801035,77.955237L85.801035,77.954434');
    }
    50% {
      d: path('M86.161435,77.676826L86.161435,77.676826L86.1598,77.676826L86.1598,-5.543385');
    }
    100% {
      d: path('M86.161435,77.676826L86.161435,77.676826L86.1598,77.676826L86.1598,-5.543385');
    }
  }
  @keyframes ek4mXt1QnJW9_c_o {
    0% {
      opacity: 0;
    }
    13.75% {
      opacity: 0;
    }
    21.25% {
      opacity: 1;
    }
    48.75% {
      opacity: 1;
    }
    53.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ek4mXt1QnJW10_to {
    animation: ek4mXt1QnJW10_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW10_to__to {
    0% {
      transform: translate(96.798427px, 143.537316px);
    }
    5% {
      transform: translate(96.798427px, 143.537316px);
    }
    20% {
      transform: translate(97.111986px, 20.771297px);
    }
    21.25% {
      transform: translate(99.798427px, 14.771297px);
    }
    35% {
      transform: translate(148.801035px, -25.679029px);
    }
    36.25% {
      transform: translate(150.920607px, -33.028703px);
    }
    50% {
      transform: translate(150.920607px, -113.028703px);
    }
    100% {
      transform: translate(150.920607px, -113.028703px);
    }
  }
  #ek4mXt1QnJW10_tr {
    animation: ek4mXt1QnJW10_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW10_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(50deg);
    }
    35% {
      transform: rotate(50deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ek4mXt1QnJW10 {
    animation: ek4mXt1QnJW10_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW10_c_o {
    0% {
      opacity: 0;
    }
    7.5% {
      opacity: 0;
    }
    13.75% {
      opacity: 0.7;
    }
    48.75% {
      opacity: 0.7;
    }
    51.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ek4mXt1QnJW11_to {
    animation: ek4mXt1QnJW11_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW11_to__to {
    0% {
      transform: translate(96.798427px, 142.237316px);
    }
    5% {
      transform: translate(96.798427px, 142.237316px);
    }
    20% {
      transform: translate(97.111986px, 20.643116px);
    }
    21.25% {
      transform: translate(99.798427px, 14.771297px);
    }
    35% {
      transform: translate(148.833827px, -25.703774px);
    }
    36.25% {
      transform: translate(150.920607px, -33.028703px);
    }
    50% {
      transform: translate(150.920607px, -113.028703px);
    }
    100% {
      transform: translate(150.920607px, -113.028703px);
    }
  }
  #ek4mXt1QnJW11_tr {
    animation: ek4mXt1QnJW11_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW11_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(50deg);
    }
    35% {
      transform: rotate(50deg);
    }
    36.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ek4mXt1QnJW11 {
    animation: ek4mXt1QnJW11_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW11_c_o {
    0% {
      opacity: 0;
    }
    7.5% {
      opacity: 0;
    }
    13.75% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    51.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ek4mXt1QnJW13 {
    animation-name: ek4mXt1QnJW13__m, ek4mXt1QnJW13_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes ek4mXt1QnJW13__m {
    0% {
      d: path('M32.0957,289.88L32.0957,246.521298L32.0957,246.521298L33.0957,245.524434');
    }
    43.75% {
      d: path('M32.0957,289.88L32.0957,246.521298L32.0957,246.521298L33.0957,245.524434');
    }
    60% {
      d: path('M32.0957,219.88L32.0957,121.861988L32.0957,121.861988L33.0957,120.869117');
    }
    73.75% {
      d: path('M32.0957,129.146811L32.0957,121.678L85.801035,77.955237L85.801035,77.954434');
    }
    88.75% {
      d: path('M86.161435,77.676826L86.161435,77.676826L86.1598,77.676826L86.1598,-5.543385');
    }
    100% {
      d: path('M86.161435,77.676826L86.161435,77.676826L86.1598,77.676826L86.1598,-5.543385');
    }
  }
  @keyframes ek4mXt1QnJW13_c_o {
    0% {
      opacity: 0;
    }
    52.5% {
      opacity: 0;
    }
    61.25% {
      opacity: 1;
    }
    87.5% {
      opacity: 1;
    }
    92.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ek4mXt1QnJW14_to {
    animation: ek4mXt1QnJW14_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW14_to__to {
    0% {
      transform: translate(96.798427px, 143.537316px);
    }
    43.75% {
      transform: translate(96.798427px, 143.537316px);
    }
    58.75% {
      transform: translate(97.111986px, 20.771297px);
    }
    60% {
      transform: translate(99.798427px, 14.771297px);
    }
    73.75% {
      transform: translate(148.801035px, -25.679029px);
    }
    75% {
      transform: translate(150.920607px, -33.028703px);
    }
    88.75% {
      transform: translate(150.920607px, -113.028703px);
    }
    100% {
      transform: translate(150.920607px, -113.028703px);
    }
  }
  #ek4mXt1QnJW14_tr {
    animation: ek4mXt1QnJW14_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW14_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(50deg);
    }
    73.75% {
      transform: rotate(50deg);
    }
    75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ek4mXt1QnJW14 {
    animation: ek4mXt1QnJW14_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW14_c_o {
    0% {
      opacity: 0;
    }
    46.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 0.7;
    }
    87.5% {
      opacity: 0.7;
    }
    90% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ek4mXt1QnJW15_to {
    animation: ek4mXt1QnJW15_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW15_to__to {
    0% {
      transform: translate(96.798427px, 142.237316px);
    }
    43.75% {
      transform: translate(96.798427px, 142.237316px);
    }
    58.75% {
      transform: translate(97.111986px, 20.643116px);
    }
    60% {
      transform: translate(99.798427px, 14.771297px);
    }
    73.75% {
      transform: translate(148.833827px, -25.703774px);
    }
    75% {
      transform: translate(150.920607px, -33.028703px);
    }
    88.75% {
      transform: translate(150.920607px, -113.028703px);
    }
    100% {
      transform: translate(150.920607px, -113.028703px);
    }
  }
  #ek4mXt1QnJW15_tr {
    animation: ek4mXt1QnJW15_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(50deg);
    }
    73.75% {
      transform: rotate(50deg);
    }
    75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ek4mXt1QnJW15 {
    animation: ek4mXt1QnJW15_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW15_c_o {
    0% {
      opacity: 0;
    }
    46.25% {
      opacity: 0;
    }
    52.5% {
      opacity: 1;
    }
    88.75% {
      opacity: 1;
    }
    90% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ek4mXt1QnJW19 {
    animation-name: ek4mXt1QnJW19__m, ek4mXt1QnJW19_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes ek4mXt1QnJW19__m {
    0% {
      d: path(
        'M161.867,259.879L161.867,253.489998C161.867,253.489998,161.85,251.271299,161.85,251.271299L161.798427,209.396294'
      );
    }
    21.25% {
      d: path(
        'M161.867,259.879L161.867,253.489998C161.867,253.489998,161.85,251.271299,161.85,251.271299L161.798427,209.396294'
      );
    }
    22.5% {
      d: path(
        'M161.867,258.924579L161.867143,248.099123C161.867143,248.099123,161.850838,245.971177,161.850838,245.971177L161.801375,195.809017'
      );
    }
    31.25% {
      d: path(
        'M161.867,209.545667L161.870499,121.695859C161.870499,121.695859,161.870499,121.695861,161.870499,121.695861L161.870499,121.695859'
      );
      animation-timing-function: cubic-bezier(0.43282, 0, 0.644748, 0.740107);
    }
    41.25% {
      d: path(
        'M161.867292,128.85329L161.867578,121.680114C161.867578,121.680114,177.021513,86.266657,177.021513,86.266657L177.021513,86.266656'
      );
      animation-timing-function: cubic-bezier(0.302639, 0.622477, 0.631281, 1);
    }
    43.75% {
      d: path(
        'M161.867318,121.678L161.867318,121.678714C161.867318,121.678714,180.683499,77.67163,180.683499,77.67163L180.683499,77.67163'
      );
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    57.5% {
      d: path(
        'M180.683499,77.670918L180.683499,77.671631C180.683499,77.671631,180.683499,77.67163,180.683499,77.67163L180.683499,-3.712681'
      );
    }
    100% {
      d: path(
        'M180.683499,77.670918L180.683499,77.671631C180.683499,77.671631,180.683499,77.67163,180.683499,77.67163L180.683499,-3.712681'
      );
    }
  }
  @keyframes ek4mXt1QnJW19_c_o {
    0% {
      opacity: 0;
    }
    26.25% {
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    56.25% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ek4mXt1QnJW20_to {
    animation: ek4mXt1QnJW20_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW20_to__to {
    0% {
      transform: translate(226.779751px, 128.661481px);
    }
    16.25% {
      transform: translate(226.779751px, 128.661481px);
    }
    31.25% {
      transform: translate(226.798427px, 20.521297px);
    }
    32.5% {
      transform: translate(229.798427px, 10.771297px);
    }
    43.75% {
      transform: translate(243.801035px, -23.5px);
    }
    45% {
      transform: translate(245.711986px, -32.410292px);
    }
    58.75% {
      transform: translate(245.711986px, -119.410292px);
    }
    100% {
      transform: translate(245.711986px, -119.410292px);
    }
  }
  #ek4mXt1QnJW20_tr {
    animation: ek4mXt1QnJW20_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW20_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    31.25% {
      transform: rotate(0deg);
    }
    32.5% {
      transform: rotate(23deg);
    }
    33.75% {
      transform: rotate(23deg);
    }
    43.75% {
      transform: rotate(23deg);
    }
    45% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ek4mXt1QnJW20 {
    animation: ek4mXt1QnJW20_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW20_c_o {
    0% {
      opacity: 0;
    }
    18.75% {
      opacity: 0;
    }
    25% {
      opacity: 0.7;
    }
    58.75% {
      opacity: 0.7;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ek4mXt1QnJW21_to {
    animation: ek4mXt1QnJW21_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW21_to__to {
    0% {
      transform: translate(226.779751px, 128.661481px);
    }
    16.25% {
      transform: translate(226.779751px, 128.661481px);
    }
    31.25% {
      transform: translate(226.798427px, 20.521297px);
    }
    32.5% {
      transform: translate(229.798427px, 10.771297px);
    }
    43.75% {
      transform: translate(243.801035px, -23.5px);
    }
    45% {
      transform: translate(245.711986px, -32.410292px);
    }
    58.75% {
      transform: translate(245.711986px, -119.410292px);
    }
    100% {
      transform: translate(245.711986px, -119.410292px);
    }
  }
  #ek4mXt1QnJW21_tr {
    animation: ek4mXt1QnJW21_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW21_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    31.25% {
      transform: rotate(0deg);
    }
    32.5% {
      transform: rotate(23deg);
    }
    43.75% {
      transform: rotate(23deg);
    }
    45% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ek4mXt1QnJW21 {
    animation: ek4mXt1QnJW21_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW21_c_o {
    0% {
      opacity: 0;
    }
    18.75% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    58.75% {
      opacity: 1;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ek4mXt1QnJW23 {
    animation-name: ek4mXt1QnJW23__m, ek4mXt1QnJW23_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes ek4mXt1QnJW23__m {
    0% {
      d: path(
        'M161.867,259.879L161.867,253.489998C161.867,253.489998,161.85,251.271299,161.85,251.271299L161.798427,209.396294'
      );
    }
    58.75% {
      d: path(
        'M161.867,259.879L161.867,253.489998C161.867,253.489998,161.85,251.271299,161.85,251.271299L161.798427,209.396294'
      );
    }
    60% {
      d: path(
        'M161.867,259.153002L161.867109,249.389329C161.867109,249.389329,161.850638,247.239663,161.850638,247.239663L161.80067,194.542067'
      );
    }
    62.5% {
      d: path(
        'M161.867,253.047676L161.868024,214.904494C161.868024,214.904494,161.856002,213.335366,161.856002,213.335366L161.819528,163.720148'
      );
    }
    68.75% {
      d: path(
        'M161.867,236.545667L161.870499,121.695859C161.870499,121.695859,161.870499,121.695861,161.870499,121.695861L161.870499,121.695859'
      );
      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    82.5% {
      d: path(
        'M161.867318,121.678L161.867318,121.678714C161.867318,121.678714,180.683499,77.67163,180.683499,77.67163L180.683499,77.67163'
      );
      animation-timing-function: cubic-bezier(0.393595, 0, 0.711078, 0.42101);
    }
    85% {
      d: path(
        'M166.228416,111.478312L166.228416,111.479025C166.228416,111.479025,180.683499,77.67163,180.683499,77.67163L180.683499,58.808879'
      );
      animation-timing-function: cubic-bezier(0.317996, 0.384525, 0.566797, 1);
    }
    97.5% {
      d: path(
        'M180.683499,77.670918L180.683499,77.671631C180.683499,77.671631,180.683499,77.67163,180.683499,77.67163L180.683499,-3.712681'
      );
    }
    100% {
      d: path(
        'M180.683499,77.670918L180.683499,77.671631C180.683499,77.671631,180.683499,77.67163,180.683499,77.67163L180.683499,-3.712681'
      );
    }
  }
  @keyframes ek4mXt1QnJW23_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
    93.75% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ek4mXt1QnJW24_to {
    animation: ek4mXt1QnJW24_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW24_to__to {
    0% {
      transform: translate(226.779751px, 128.661481px);
    }
    53.75% {
      transform: translate(226.779751px, 128.661481px);
    }
    68.75% {
      transform: translate(226.798427px, 20.521297px);
    }
    70% {
      transform: translate(229.798427px, 10.771297px);
    }
    81.25% {
      transform: translate(243.801035px, -23.5px);
    }
    82.5% {
      transform: translate(245.711986px, -32.410292px);
    }
    96.25% {
      transform: translate(245.711986px, -119.410292px);
    }
    100% {
      transform: translate(245.711986px, -119.410292px);
    }
  }
  #ek4mXt1QnJW24_tr {
    animation: ek4mXt1QnJW24_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW24_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(23deg);
    }
    71.25% {
      transform: rotate(23deg);
    }
    81.25% {
      transform: rotate(23deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ek4mXt1QnJW24 {
    animation: ek4mXt1QnJW24_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW24_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    62.5% {
      opacity: 0.7;
    }
    96.25% {
      opacity: 0.7;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ek4mXt1QnJW25_to {
    animation: ek4mXt1QnJW25_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW25_to__to {
    0% {
      transform: translate(226.779751px, 128.661481px);
    }
    53.75% {
      transform: translate(226.779751px, 128.661481px);
    }
    68.75% {
      transform: translate(226.798427px, 20.521297px);
    }
    70% {
      transform: translate(229.798427px, 10.771297px);
    }
    81.25% {
      transform: translate(243.801035px, -23.5px);
    }
    82.5% {
      transform: translate(245.711986px, -32.410292px);
    }
    96.25% {
      transform: translate(245.711986px, -119.410292px);
    }
    100% {
      transform: translate(245.711986px, -119.410292px);
    }
  }
  #ek4mXt1QnJW25_tr {
    animation: ek4mXt1QnJW25_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW25_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    68.75% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(23deg);
    }
    81.25% {
      transform: rotate(23deg);
    }
    82.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ek4mXt1QnJW25 {
    animation: ek4mXt1QnJW25_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW25_c_o {
    0% {
      opacity: 0;
    }
    56.25% {
      opacity: 0;
    }
    62.5% {
      opacity: 1;
    }
    96.25% {
      opacity: 1;
    }
    97.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ek4mXt1QnJW29 {
    animation-name: ek4mXt1QnJW29__m, ek4mXt1QnJW29_c_o, ek4mXt1QnJW29_s_p;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes ek4mXt1QnJW29__m {
    0% {
      d: path(
        'M161.867,259.879L161.867,253.489998C161.867,253.489998,161.85,251.271299,161.85,251.271299L161.798427,249.396294'
      );
    }
    15% {
      d: path(
        'M161.867,236.815444L161.870458,123.219644C161.870458,123.219644,161.870262,123.193993,161.870262,123.193993L161.869666,123.172313'
      );
    }
    16.25% {
      d: path(
        'M161.867,236.545667L161.870499,121.695859C161.870499,121.695859,161.870499,121.695861,161.870499,121.695861L162.215485,120.846257'
      );
      animation-timing-function: cubic-bezier(0.43282, 0, 0.644748, 0.740107);
    }
    26.25% {
      d: path(
        'M161.867292,131.058114L161.867578,121.680114C161.867578,121.680114,175.692843,89.115845,175.692843,89.115845L175.692843,89.115845'
      );
      animation-timing-function: cubic-bezier(0.302639, 0.622477, 0.631281, 1);
    }
    28.75% {
      d: path(
        'M161.867318,121.678L161.867318,121.678714C161.867318,121.678714,180.683499,77.67163,180.683499,77.67163L180.683499,77.67163'
      );
      animation-timing-function: cubic-bezier(0.382379, 0, 0.705983, 0.399456);
    }
    32.5% {
      d: path(
        'M164.771414,114.885933L164.771414,114.886646C164.771414,114.886646,180.683499,77.67163,180.683499,77.67163L180.683499,57.110749'
      );
      animation-timing-function: cubic-bezier(0.333792, 0.331817, 0.565892, 1);
    }
    45% {
      d: path(
        'M180.683499,77.670918L180.683499,77.671631C180.683499,77.671631,180.683499,77.67163,180.683499,77.67163L180.683499,-3.712681'
      );
    }
    100% {
      d: path(
        'M180.683499,77.670918L180.683499,77.671631C180.683499,77.671631,180.683499,77.67163,180.683499,77.67163L180.683499,-3.712681'
      );
    }
  }
  @keyframes ek4mXt1QnJW29_c_o {
    0% {
      opacity: 0;
    }
    8.75% {
      opacity: 0;
    }
    21.25% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    46.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes ek4mXt1QnJW29_s_p {
    0% {
      stroke: url(#ek4mXt1QnJW29-stroke);
    }
    15% {
      stroke: url(#ek4mXt1QnJW29-stroke);
    }
    16.25% {
      stroke: url(#ek4mXt1QnJW29-stroke);
    }
    21.25% {
      stroke: url(#ek4mXt1QnJW29-stroke);
    }
    25% {
      stroke: url(#ek4mXt1QnJW29-stroke);
    }
    28.75% {
      stroke: url(#ek4mXt1QnJW29-stroke-g1);
    }
    33.75% {
      stroke: url(#ek4mXt1QnJW29-stroke-g2);
    }
    36.25% {
      stroke: url(#ek4mXt1QnJW29-stroke-g2);
    }
    100% {
      stroke: url(#ek4mXt1QnJW29-stroke-g2);
    }
  }
  #ek4mXt1QnJW29-stroke {
    animation: ek4mXt1QnJW29-stroke__t 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW29-stroke__t {
    0% {
      transform: matrix(0, 0.802044, -0.802044, 0, 2720.726221, 0.040125);
    }
    15% {
      transform: matrix(0, 0.802044, -0.802044, 0, 2720.726221, 0.040125);
    }
    16.25% {
      transform: matrix(0, 0.753213, -0.753213, 0, 2689.623321, 0.03962);
    }
    21.25% {
      transform: matrix(0, 0.56443, -0.56443, 0, 1.813385, 0.07497);
    }
    25% {
      transform: matrix(0, 0.729304, -0.729304, 0, 1.25415, 0.161211);
    }
    100% {
      transform: matrix(0, 0.729304, -0.729304, 0, 1.25415, 0.161211);
    }
  }
  #ek4mXt1QnJW29-stroke-0 {
    animation: ek4mXt1QnJW29-stroke-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW29-stroke-0__c {
    0% {
      stop-color: #7ea6ff;
    }
    15% {
      stop-color: #7ea6ff;
    }
    16.25% {
      stop-color: #7ea6ff;
    }
    21.25% {
      stop-color: #7ea6ff;
    }
    25% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #ek4mXt1QnJW29-stroke-1 {
    animation: ek4mXt1QnJW29-stroke-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW29-stroke-1__c {
    0% {
      stop-color: rgba(96, 105, 202, 0);
    }
    15% {
      stop-color: rgba(96, 105, 202, 0);
    }
    16.25% {
      stop-color: rgba(91, 100, 197, 0);
    }
    21.25% {
      stop-color: rgba(126, 166, 255, 0);
    }
    25% {
      stop-color: rgba(103, 128, 220, 0);
    }
    100% {
      stop-color: rgba(103, 128, 220, 0);
    }
  }
  #ek4mXt1QnJW29-stroke-g2 {
    animation: ek4mXt1QnJW29-stroke-g2__t 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW29-stroke-g2__t {
    0% {
      transform: matrix(0.13832, 0.677167, -0.677167, 0.13832, 1.69266, 0.182634);
    }
    33.75% {
      transform: matrix(0.13832, 0.677167, -0.677167, 0.13832, 1.69266, 0.182634);
    }
    36.25% {
      transform: matrix(0.178066, 0.920174, -0.920174, 0.178066, 2.248285, 0.079826);
    }
    100% {
      transform: matrix(0.178066, 0.920174, -0.920174, 0.178066, 2.248285, 0.079826);
    }
  }
  #ek4mXt1QnJW29-stroke-g2-0 {
    animation: ek4mXt1QnJW29-stroke-g2-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW29-stroke-g2-0__c {
    0% {
      stop-color: #7ea6ff;
    }
    33.75% {
      stop-color: #7ea6ff;
    }
    36.25% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #ek4mXt1QnJW29-stroke-g2-1 {
    animation: ek4mXt1QnJW29-stroke-g2-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW29-stroke-g2-1__c {
    0% {
      stop-color: rgba(96, 105, 202, 0);
    }
    33.75% {
      stop-color: rgba(96, 105, 202, 0);
    }
    36.25% {
      stop-color: rgba(64, 65, 69, 0);
    }
    100% {
      stop-color: rgba(64, 65, 69, 0);
    }
  }
  #ek4mXt1QnJW30_to {
    animation: ek4mXt1QnJW30_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW30_to__to {
    0% {
      transform: translate(226.779751px, 128.661481px);
    }
    1.25% {
      transform: translate(226.779751px, 128.661481px);
    }
    16.25% {
      transform: translate(226.798427px, 20.521297px);
    }
    17.5% {
      transform: translate(229.798427px, 10.771297px);
    }
    28.75% {
      transform: translate(243.801035px, -23.5px);
    }
    30% {
      transform: translate(245.711986px, -32.410292px);
    }
    43.75% {
      transform: translate(245.711986px, -119.410292px);
    }
    100% {
      transform: translate(245.711986px, -119.410292px);
    }
  }
  #ek4mXt1QnJW30_tr {
    animation: ek4mXt1QnJW30_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW30_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    16.25% {
      transform: rotate(0deg);
    }
    17.5% {
      transform: rotate(23deg);
    }
    28.75% {
      transform: rotate(23deg);
    }
    30% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ek4mXt1QnJW30 {
    animation: ek4mXt1QnJW30_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW30_c_o {
    0% {
      opacity: 0;
    }
    3.75% {
      opacity: 0;
    }
    10% {
      opacity: 0.7;
    }
    43.75% {
      opacity: 0.7;
    }
    45% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ek4mXt1QnJW31_to {
    animation: ek4mXt1QnJW31_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW31_to__to {
    0% {
      transform: translate(226.779751px, 128.661481px);
    }
    1.25% {
      transform: translate(226.779751px, 128.661481px);
    }
    16.25% {
      transform: translate(226.798427px, 20.521297px);
    }
    17.5% {
      transform: translate(229.798427px, 10.771297px);
    }
    28.75% {
      transform: translate(243.801035px, -23.5px);
    }
    30% {
      transform: translate(245.711986px, -32.410292px);
    }
    43.75% {
      transform: translate(245.711986px, -119.410292px);
    }
    100% {
      transform: translate(245.711986px, -119.410292px);
    }
  }
  #ek4mXt1QnJW31_tr {
    animation: ek4mXt1QnJW31_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW31_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    16.25% {
      transform: rotate(0deg);
    }
    17.5% {
      transform: rotate(23deg);
    }
    28.75% {
      transform: rotate(23deg);
    }
    30% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ek4mXt1QnJW31 {
    animation: ek4mXt1QnJW31_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW31_c_o {
    0% {
      opacity: 0;
    }
    3.75% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    43.75% {
      opacity: 1;
    }
    45% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ek4mXt1QnJW33 {
    animation-name: ek4mXt1QnJW33__m, ek4mXt1QnJW33_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes ek4mXt1QnJW33__m {
    0% {
      d: path(
        'M161.867,259.879L161.867,253.489998C161.867,253.489998,161.85,251.271299,161.85,251.271299L161.798427,249.396294'
      );
    }
    41.25% {
      d: path(
        'M161.867,259.879L161.867,253.489998C161.867,253.489998,161.85,251.271299,161.85,251.271299L161.798427,249.396294'
      );
    }
    57.5% {
      d: path(
        'M161.867,236.545667L161.870499,121.695859C161.870499,121.695859,161.870499,121.695861,161.870499,121.695861L161.870499,121.695859'
      );
    }
    70% {
      d: path(
        'M161.867318,121.678L161.867318,121.678714C161.867318,121.678714,180.683499,77.67163,180.683499,77.67163L180.683499,77.67163'
      );
    }
    83.75% {
      d: path(
        'M180.683499,77.670918L180.683499,77.671631C180.683499,77.671631,180.683499,77.67163,180.683499,77.67163L180.683499,-3.712681'
      );
    }
    100% {
      d: path(
        'M180.683499,77.670918L180.683499,77.671631C180.683499,77.671631,180.683499,77.67163,180.683499,77.67163L180.683499,-3.712681'
      );
    }
  }
  @keyframes ek4mXt1QnJW33_c_o {
    0% {
      opacity: 0;
    }
    48.75% {
      opacity: 0;
    }
    58.75% {
      opacity: 1;
    }
    81.25% {
      opacity: 1;
    }
    87.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ek4mXt1QnJW33-stroke {
    animation: ek4mXt1QnJW33-stroke__t 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW33-stroke__t {
    0% {
      transform: matrix(0, 0.724522, -0.724522, 0, 1098.518665, 0.038051);
    }
    56.25% {
      transform: matrix(0, 0.724522, -0.724522, 0, 1098.518665, 0.038051);
    }
    57.5% {
      transform: matrix(0, 0.585855, -0.585855, 0, 2689.623321, 0.052899);
    }
    58.75% {
      transform: matrix(0, 0.49539, -0.49539, 0, 57.000109, 0.00461);
    }
    63.75% {
      transform: matrix(0.003399, 0.670057, -0.670057, 0.003399, 1.999645, 0.124767);
    }
    70% {
      transform: matrix(0, 1.052691, -1.052691, 0, 1.816149, 0.181422);
    }
    75% {
      transform: matrix(0, 1, -1, 0, 0, 0);
    }
    76.25% {
      transform: matrix(-0.148318, 1.061296, -1.061296, -0.148318, -1.611883, -0.061296);
    }
    77.5% {
      transform: matrix(0, 0.976875, -0.976875, 0, -0.099698, 0.111465);
    }
    78.75% {
      transform: matrix(0, 0.905047, -0.905047, 0, -0.374622, 0.094953);
    }
    100% {
      transform: matrix(0, 0.905047, -0.905047, 0, -0.374622, 0.094953);
    }
  }
  #ek4mXt1QnJW33-stroke-0 {
    animation: ek4mXt1QnJW33-stroke-0__c 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW33-stroke-0__c {
    0% {
      stop-color: #7ea6ff;
    }
    56.25% {
      stop-color: #7ea6ff;
    }
    57.5% {
      stop-color: #7ea6ff;
    }
    58.75% {
      stop-color: #7ea6ff;
    }
    63.75% {
      stop-color: #7ea6ff;
    }
    70% {
      stop-color: #7ea6ff;
    }
    75% {
      stop-color: #7ea6ff;
    }
    76.25% {
      stop-color: #7ea6ff;
    }
    77.5% {
      stop-color: #7ea6ff;
    }
    78.75% {
      stop-color: #7ea6ff;
    }
    100% {
      stop-color: #7ea6ff;
    }
  }
  #ek4mXt1QnJW33-stroke-1 {
    animation: ek4mXt1QnJW33-stroke-1__c 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW33-stroke-1__c {
    0% {
      stop-color: rgba(96, 105, 202, 0);
    }
    56.25% {
      stop-color: rgba(96, 105, 202, 0);
    }
    57.5% {
      stop-color: rgba(91, 100, 197, 0);
    }
    58.75% {
      stop-color: rgba(126, 166, 255, 0);
    }
    63.75% {
      stop-color: rgba(126, 166, 255, 0);
    }
    70% {
      stop-color: rgba(80, 89, 185, 0);
    }
    75% {
      stop-color: rgba(96, 105, 202, 0);
    }
    76.25% {
      stop-color: rgba(80, 85, 136, 0);
    }
    77.5% {
      stop-color: rgba(64, 65, 69, 0);
    }
    78.75% {
      stop-color: rgba(64, 65, 71, 0);
    }
    100% {
      stop-color: rgba(64, 65, 71, 0);
    }
  }
  #ek4mXt1QnJW34_to {
    animation: ek4mXt1QnJW34_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW34_to__to {
    0% {
      transform: translate(226.779751px, 128.661481px);
    }
    42.5% {
      transform: translate(226.779751px, 128.661481px);
    }
    57.5% {
      transform: translate(226.798427px, 22.771297px);
    }
    58.75% {
      transform: translate(229.798427px, 10.771297px);
    }
    70% {
      transform: translate(243.801035px, -23.5px);
    }
    71.25% {
      transform: translate(245.711986px, -32.410292px);
    }
    85% {
      transform: translate(245.711986px, -119.410292px);
    }
    100% {
      transform: translate(245.711986px, -119.410292px);
    }
  }
  #ek4mXt1QnJW34_tr {
    animation: ek4mXt1QnJW34_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW34_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    57.5% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(23deg);
    }
    70% {
      transform: rotate(23deg);
    }
    71.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ek4mXt1QnJW34 {
    animation: ek4mXt1QnJW34_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW34_c_o {
    0% {
      opacity: 0;
    }
    45% {
      opacity: 0;
    }
    51.25% {
      opacity: 0.7;
    }
    85% {
      opacity: 0.7;
    }
    86.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ek4mXt1QnJW35_to {
    animation: ek4mXt1QnJW35_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW35_to__to {
    0% {
      transform: translate(226.779751px, 128.661481px);
    }
    42.5% {
      transform: translate(226.779751px, 128.661481px);
    }
    57.5% {
      transform: translate(226.798427px, 21.271297px);
    }
    58.75% {
      transform: translate(229.798427px, 10.771297px);
    }
    70% {
      transform: translate(243.801035px, -23.5px);
    }
    71.25% {
      transform: translate(245.711986px, -32.410292px);
    }
    85% {
      transform: translate(245.711986px, -119.410292px);
    }
    100% {
      transform: translate(245.711986px, -119.410292px);
    }
  }
  #ek4mXt1QnJW35_tr {
    animation: ek4mXt1QnJW35_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW35_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    57.5% {
      transform: rotate(0deg);
    }
    58.75% {
      transform: rotate(23deg);
    }
    70% {
      transform: rotate(23deg);
    }
    71.25% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ek4mXt1QnJW35 {
    animation: ek4mXt1QnJW35_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW35_c_o {
    0% {
      opacity: 0;
    }
    45% {
      opacity: 0;
    }
    51.25% {
      opacity: 1;
    }
    85% {
      opacity: 1;
    }
    86.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ek4mXt1QnJW39 {
    animation-name: ek4mXt1QnJW39__m, ek4mXt1QnJW39_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes ek4mXt1QnJW39__m {
    0% {
      d: path('M32.0957,321.564875L32.0957,231.828536L32.101049,231.870431L32.101049,231.876118');
    }
    26.25% {
      d: path('M32.0957,321.564875L32.0957,231.828536L32.101049,231.870431L32.101049,231.876118');
    }
    38.75% {
      d: path('M32.0957,211.564875L32.0957,121.828536L32.101049,121.870431L32.101049,121.876118');
    }
    52.5% {
      d: path('M32.0957,129.146811L32.0957,121.678L85.801035,77.955237L85.801035,77.954434');
    }
    63.75% {
      d: path('M86.161435,77.676826L86.161435,77.676826L86.1598,77.676826L86.1598,-5.543385');
    }
    100% {
      d: path('M86.161435,77.676826L86.161435,77.676826L86.1598,77.676826L86.1598,-5.543385');
    }
  }
  @keyframes ek4mXt1QnJW39_c_o {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    36.25% {
      opacity: 0;
    }
    42.5% {
      opacity: 1;
    }
    61.25% {
      opacity: 1;
    }
    67.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ek4mXt1QnJW40_to {
    animation: ek4mXt1QnJW40_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW40_to__to {
    0% {
      transform: translate(96.798427px, 143.537316px);
    }
    26.25% {
      transform: translate(96.798427px, 143.537316px);
    }
    38.75% {
      transform: translate(97.111986px, 20.771297px);
    }
    40% {
      transform: translate(99.798427px, 14.771297px);
    }
    52.5% {
      transform: translate(148.801035px, -25.679029px);
    }
    53.75% {
      transform: translate(150.920607px, -33.028703px);
    }
    63.75% {
      transform: translate(150.920607px, -113.028703px);
    }
    100% {
      transform: translate(150.920607px, -113.028703px);
    }
  }
  #ek4mXt1QnJW40_tr {
    animation: ek4mXt1QnJW40_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW40_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(50deg);
    }
    52.5% {
      transform: rotate(50deg);
    }
    53.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ek4mXt1QnJW40 {
    animation: ek4mXt1QnJW40_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW40_c_o {
    0% {
      opacity: 0;
    }
    28.75% {
      opacity: 0;
    }
    36.25% {
      opacity: 0.7;
    }
    63.75% {
      opacity: 0.7;
    }
    65% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ek4mXt1QnJW41_to {
    animation: ek4mXt1QnJW41_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW41_to__to {
    0% {
      transform: translate(96.798427px, 142.237316px);
    }
    26.25% {
      transform: translate(96.798427px, 142.237316px);
    }
    38.75% {
      transform: translate(97.111986px, 20.643116px);
    }
    40% {
      transform: translate(99.798427px, 14.771297px);
    }
    52.5% {
      transform: translate(148.833827px, -25.703774px);
    }
    53.75% {
      transform: translate(150.920607px, -33.028703px);
    }
    63.75% {
      transform: translate(150.920607px, -113.028703px);
    }
    100% {
      transform: translate(150.920607px, -113.028703px);
    }
  }
  #ek4mXt1QnJW41_tr {
    animation: ek4mXt1QnJW41_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW41_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    38.75% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(50deg);
    }
    52.5% {
      transform: rotate(50deg);
    }
    53.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ek4mXt1QnJW41 {
    animation: ek4mXt1QnJW41_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW41_c_o {
    0% {
      opacity: 0;
    }
    28.75% {
      opacity: 0;
    }
    36.25% {
      opacity: 1;
    }
    63.75% {
      opacity: 1;
    }
    65% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ek4mXt1QnJW43 {
    animation-name: ek4mXt1QnJW43__m, ek4mXt1QnJW43_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes ek4mXt1QnJW43__m {
    0% {
      d: path('M32.0957,321.564875L32.0957,241.828536L32.101049,241.870431L32.101049,241.876118');
    }
    60% {
      d: path('M32.0957,321.564875L32.0957,241.828536L32.101049,241.870431L32.101049,241.876118');
    }
    71.25% {
      d: path('M32.0957,211.564875L32.0957,121.828536L32.101049,121.870431L32.101049,121.876118');
    }
    86.25% {
      d: path('M32.0957,129.146811L32.0957,121.678L85.801035,77.955237L85.801035,77.954434');
    }
    97.5% {
      d: path('M86.161435,77.676826L86.161435,77.676826L86.1598,77.676826L86.1598,-5.543385');
    }
    100% {
      d: path('M86.161435,77.676826L86.161435,77.676826L86.1598,77.676826L86.1598,-5.543385');
    }
  }
  @keyframes ek4mXt1QnJW43_c_o {
    0% {
      opacity: 0;
    }
    58.75% {
      opacity: 0;
    }
    67.5% {
      opacity: 0;
    }
    73.75% {
      opacity: 1;
    }
    93.75% {
      opacity: 1;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ek4mXt1QnJW44_to {
    animation: ek4mXt1QnJW44_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW44_to__to {
    0% {
      transform: translate(96.798427px, 143.537316px);
    }
    60% {
      transform: translate(96.798427px, 143.537316px);
    }
    71.25% {
      transform: translate(97.111986px, 20.771297px);
    }
    72.5% {
      transform: translate(99.798427px, 14.771297px);
    }
    86.25% {
      transform: translate(148.801035px, -25.679029px);
    }
    87.5% {
      transform: translate(150.920607px, -33.028703px);
    }
    97.5% {
      transform: translate(150.920607px, -113.028703px);
    }
    100% {
      transform: translate(150.920607px, -113.028703px);
    }
  }
  #ek4mXt1QnJW44_tr {
    animation: ek4mXt1QnJW44_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW44_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(0deg);
    }
    72.5% {
      transform: rotate(50deg);
    }
    86.25% {
      transform: rotate(50deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ek4mXt1QnJW44 {
    animation: ek4mXt1QnJW44_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW44_c_o {
    0% {
      opacity: 0;
    }
    62.5% {
      opacity: 0;
    }
    70% {
      opacity: 0.7;
    }
    97.5% {
      opacity: 0.7;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #ek4mXt1QnJW45_to {
    animation: ek4mXt1QnJW45_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW45_to__to {
    0% {
      transform: translate(96.798427px, 142.237316px);
    }
    60% {
      transform: translate(96.798427px, 142.237316px);
    }
    71.25% {
      transform: translate(97.111986px, 20.643116px);
    }
    72.5% {
      transform: translate(99.798427px, 14.771297px);
    }
    86.25% {
      transform: translate(148.833827px, -25.703774px);
    }
    87.5% {
      transform: translate(150.920607px, -33.028703px);
    }
    97.5% {
      transform: translate(150.920607px, -113.028703px);
    }
    100% {
      transform: translate(150.920607px, -113.028703px);
    }
  }
  #ek4mXt1QnJW45_tr {
    animation: ek4mXt1QnJW45_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW45_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    71.25% {
      transform: rotate(0deg);
    }
    72.5% {
      transform: rotate(50deg);
    }
    86.25% {
      transform: rotate(50deg);
    }
    87.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #ek4mXt1QnJW45 {
    animation: ek4mXt1QnJW45_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes ek4mXt1QnJW45_c_o {
    0% {
      opacity: 0;
    }
    62.5% {
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
    97.5% {
      opacity: 1;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesMobileBg4;
