// This file was generated from the SVG file exported from SVGator. Do not change manually!
// Use the script in the scripts folder to generate this file
import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  selected?: number;
}

const FeedSourcesMobileBg3 = ({ selected, ...props }: Props) => {
  const selectedStyles = {
    bulletColor: 'var(--sb-selected-bullet-color)',
    bulletGlowColor: 'var(--sb-selected-bullet-glow-color)',
    tailColor: 'var(--sb-selected-tail-color)',
  };

  const styles = {
    bulletColor: 'var(--sb-bullet-color)',
    bulletGlowColor: 'var(--sb-bullet-glow-color)',
    tailColor: 'var(--sb-tail-color)',
    stopColor: 'var(--sb-line-color)', // Replace all <stop id="...-stroke-1" stopColor="#initial-color" /> with this value
  };

  return (
    <AnimatedSvg
      id="eZaKkGJWq3o1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 320 250"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="320"
      height="250"
      className="source-background"
      {...props}
    >
      <defs>
        <linearGradient
          id="eZaKkGJWq3o7-stroke"
          x1="0.311309"
          y1="0.695205"
          x2="0.32485"
          y2="0.997707"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZaKkGJWq3o7-stroke-0"
            offset="0%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZaKkGJWq3o7-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <filter id="eZaKkGJWq3o8-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZaKkGJWq3o8-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eZaKkGJWq3o8-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZaKkGJWq3o8-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eZaKkGJWq3o8-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZaKkGJWq3o8-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZaKkGJWq3o8-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZaKkGJWq3o8-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZaKkGJWq3o9-stroke"
          x1="8382.194992"
          y1="1.064326"
          x2="8382.194992"
          y2="0.084142"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZaKkGJWq3o9-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="eZaKkGJWq3o9-stroke-1"
            offset="100%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eZaKkGJWq3o10-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZaKkGJWq3o10-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eZaKkGJWq3o10-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZaKkGJWq3o10-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eZaKkGJWq3o10-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZaKkGJWq3o10-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZaKkGJWq3o10-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZaKkGJWq3o10-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eZaKkGJWq3o10-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eZaKkGJWq3o12-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZaKkGJWq3o12-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eZaKkGJWq3o12-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZaKkGJWq3o12-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eZaKkGJWq3o12-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZaKkGJWq3o12-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZaKkGJWq3o12-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZaKkGJWq3o12-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZaKkGJWq3o13-stroke"
          x1="206.256926"
          y1="1"
          x2="206.256926"
          y2="0.06163"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZaKkGJWq3o13-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="eZaKkGJWq3o13-stroke-1"
            offset="100%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eZaKkGJWq3o14-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZaKkGJWq3o14-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eZaKkGJWq3o14-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZaKkGJWq3o14-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eZaKkGJWq3o14-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZaKkGJWq3o14-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZaKkGJWq3o14-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZaKkGJWq3o14-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eZaKkGJWq3o14-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eZaKkGJWq3o16-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZaKkGJWq3o16-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eZaKkGJWq3o16-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZaKkGJWq3o16-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eZaKkGJWq3o16-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZaKkGJWq3o16-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZaKkGJWq3o16-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZaKkGJWq3o16-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZaKkGJWq3o17-stroke"
          x1="2.146913"
          y1="1"
          x2="2.146913"
          y2="0.088056"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZaKkGJWq3o17-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="eZaKkGJWq3o17-stroke-1"
            offset="100%"
            stopColor={selected === 2 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eZaKkGJWq3o18-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZaKkGJWq3o18-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eZaKkGJWq3o18-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZaKkGJWq3o18-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eZaKkGJWq3o18-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZaKkGJWq3o18-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZaKkGJWq3o18-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZaKkGJWq3o18-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eZaKkGJWq3o18-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <linearGradient
          id="eZaKkGJWq3o21-stroke"
          x1="0.5"
          y1="0.695267"
          x2="0.5"
          y2="0.997712"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZaKkGJWq3o21-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZaKkGJWq3o21-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <filter id="eZaKkGJWq3o22-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZaKkGJWq3o22-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eZaKkGJWq3o22-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZaKkGJWq3o22-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eZaKkGJWq3o22-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZaKkGJWq3o22-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZaKkGJWq3o22-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZaKkGJWq3o22-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZaKkGJWq3o23-stroke"
          x1="35.433962"
          y1="0"
          x2="35.433962"
          y2="1.148697"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZaKkGJWq3o23-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZaKkGJWq3o23-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eZaKkGJWq3o24-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZaKkGJWq3o24-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eZaKkGJWq3o24-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZaKkGJWq3o24-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eZaKkGJWq3o24-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZaKkGJWq3o24-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZaKkGJWq3o24-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZaKkGJWq3o24-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eZaKkGJWq3o24-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eZaKkGJWq3o26-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZaKkGJWq3o26-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eZaKkGJWq3o26-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZaKkGJWq3o26-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eZaKkGJWq3o26-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZaKkGJWq3o26-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZaKkGJWq3o26-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZaKkGJWq3o26-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZaKkGJWq3o27-stroke"
          x1="35.433962"
          y1="0"
          x2="35.433962"
          y2="1.148697"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZaKkGJWq3o27-stroke-0"
            offset="0%"
            stopColor={selected === 1 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZaKkGJWq3o27-stroke-1" offset="100%" stopColor="rgba(64,73,168,0)" />
        </linearGradient>
        <filter id="eZaKkGJWq3o28-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZaKkGJWq3o28-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eZaKkGJWq3o28-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZaKkGJWq3o28-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eZaKkGJWq3o28-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZaKkGJWq3o28-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZaKkGJWq3o28-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZaKkGJWq3o28-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eZaKkGJWq3o28-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <linearGradient
          id="eZaKkGJWq3o31-stroke"
          x1="0.5"
          y1="0.690629"
          x2="0.5"
          y2="0.997707"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop
            id="eZaKkGJWq3o31-stroke-0"
            offset="0%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
          <stop id="eZaKkGJWq3o31-stroke-1" offset="100%" stopColor="rgba(96,105,202,0)" />
        </linearGradient>
        <filter id="eZaKkGJWq3o32-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZaKkGJWq3o32-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eZaKkGJWq3o32-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZaKkGJWq3o32-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eZaKkGJWq3o32-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZaKkGJWq3o32-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZaKkGJWq3o32-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZaKkGJWq3o32-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZaKkGJWq3o33-stroke"
          x1="5940.262673"
          y1="1.161515"
          x2="5940.262673"
          y2="0.096616"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZaKkGJWq3o33-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="eZaKkGJWq3o33-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eZaKkGJWq3o34-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZaKkGJWq3o34-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eZaKkGJWq3o34-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZaKkGJWq3o34-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eZaKkGJWq3o34-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZaKkGJWq3o34-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZaKkGJWq3o34-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZaKkGJWq3o34-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eZaKkGJWq3o34-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
        <filter id="eZaKkGJWq3o36-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZaKkGJWq3o36-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="3,3" />
          <feOffset id="eZaKkGJWq3o36-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZaKkGJWq3o36-filter-drop-shadow-0-flood" floodColor="rgba(217,217,217,0.4)" />
          <feComposite id="eZaKkGJWq3o36-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZaKkGJWq3o36-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZaKkGJWq3o36-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZaKkGJWq3o36-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient
          id="eZaKkGJWq3o37-stroke"
          x1="5940.262673"
          y1="1.161515"
          x2="5940.262673"
          y2="0.096616"
          spreadMethod="pad"
          gradientUnits="objectBoundingBox"
          gradientTransform="translate(0 0)"
        >
          <stop id="eZaKkGJWq3o37-stroke-0" offset="0%" stopColor="rgba(96,105,202,0)" />
          <stop
            id="eZaKkGJWq3o37-stroke-1"
            offset="100%"
            stopColor={selected === 0 ? selectedStyles.tailColor : styles.tailColor}
          />
        </linearGradient>
        <filter id="eZaKkGJWq3o38-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur id="eZaKkGJWq3o38-filter-drop-shadow-0-blur" in="SourceAlpha" stdDeviation="1,2" />
          <feOffset id="eZaKkGJWq3o38-filter-drop-shadow-0-offset" dx="0" dy="0" result="tmp" />
          <feFlood id="eZaKkGJWq3o38-filter-drop-shadow-0-flood" floodColor="#7ea6ff" />
          <feComposite id="eZaKkGJWq3o38-filter-drop-shadow-0-composite" operator="in" in2="tmp" />
          <feMerge id="eZaKkGJWq3o38-filter-drop-shadow-0-merge" result="result">
            <feMergeNode id="eZaKkGJWq3o38-filter-drop-shadow-0-merge-node-1" />
            <feMergeNode id="eZaKkGJWq3o38-filter-drop-shadow-0-merge-node-2" in="SourceGraphic" />
          </feMerge>
          <feGaussianBlur id="eZaKkGJWq3o38-filter-blur-0" stdDeviation="2,2" result="result" />
        </filter>
      </defs>
      <g transform="translate(-22-2.25894)" mask="url(#eZaKkGJWq3o40)">
        <g transform="translate(.000001 0)">
          <g transform="translate(.088013 1.012683)">
            <g transform="matrix(-1 0 0 1 374.225189 0)">
              <path
                d="M96.7109,218.88v-97.202l35.869585-44.145397L132.817,0.34375"
                fill="none"
                stroke="url(#eZaKkGJWq3o7-stroke)"
              />
              <g transform="translate(-64 105.000001)" filter="url(#eZaKkGJWq3o8-filter)">
                <path
                  id="eZaKkGJWq3o9"
                  d="M96.711985,205.439636L96.7109,127.987317h.001085c0,0,0,0,0,0"
                  transform="translate(64-105.000001)"
                  opacity="0.2"
                  fill="none"
                  stroke="url(#eZaKkGJWq3o9-stroke)"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="eZaKkGJWq3o10_to" transform="translate(160.8,24.504306)">
                  <g id="eZaKkGJWq3o10_tr" transform="rotate(0)">
                    <rect
                      id="eZaKkGJWq3o10"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0.7"
                      filter="url(#eZaKkGJWq3o10-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eZaKkGJWq3o11_to" transform="translate(160.8,24.504307)">
                  <g id="eZaKkGJWq3o11_tr" transform="rotate(0)">
                    <rect
                      id="eZaKkGJWq3o11"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-64 105.000001)" filter="url(#eZaKkGJWq3o12-filter)">
                <path
                  id="eZaKkGJWq3o13"
                  d="M96.711985,275.439636L96.7109,227.987317h.001085c0,0,0,0,0,0"
                  transform="translate(64-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZaKkGJWq3o13-stroke)"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="eZaKkGJWq3o14_to" transform="translate(160.8,126.504306)">
                  <g id="eZaKkGJWq3o14_tr" transform="rotate(0)">
                    <rect
                      id="eZaKkGJWq3o14"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eZaKkGJWq3o14-filter)"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eZaKkGJWq3o15_to" transform="translate(160.8,126.504307)">
                  <g id="eZaKkGJWq3o15_tr" transform="rotate(0)">
                    <rect
                      id="eZaKkGJWq3o15"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-64 105.000001)" filter="url(#eZaKkGJWq3o16-filter)">
                <path
                  id="eZaKkGJWq3o17"
                  d="M96.711985,275.439636L96.7109,227.987317h.001085c0,0,0,0,0,0"
                  transform="translate(64-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZaKkGJWq3o17-stroke)"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="eZaKkGJWq3o18_to" transform="translate(160.8,126.504306)">
                  <rect
                    id="eZaKkGJWq3o18"
                    width="7"
                    height="12"
                    rx="0"
                    ry="0"
                    transform="translate(-3.5,-6)"
                    opacity="0"
                    filter="url(#eZaKkGJWq3o18-filter)"
                    fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eZaKkGJWq3o19_to" transform="translate(160.8,126.504307)">
                  <rect
                    id="eZaKkGJWq3o19"
                    width="10"
                    height="20"
                    rx="0"
                    ry="0"
                    transform="scale(0.5,0.5) translate(-5,-10)"
                    opacity="0"
                    fill={selected === 2 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
              </g>
            </g>
            <g transform="translate(-97.999999 0)">
              <path
                d="M161.867,218.879l.004985-97.202v-44.144397-77.224072"
                transform="translate(117.344985 0)"
                fill="none"
                stroke="url(#eZaKkGJWq3o21-stroke)"
              />
              <g transform="translate(-65 105.000001)" filter="url(#eZaKkGJWq3o22-filter)">
                <path
                  id="eZaKkGJWq3o23"
                  d="M278.994002,286.5213L278.994,225.053002l.106.000002-.070999-.000002"
                  transform="translate(65-93.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZaKkGJWq3o23-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZaKkGJWq3o24_to" transform="translate(344.25,128.271297)">
                  <rect
                    id="eZaKkGJWq3o24"
                    width="8.75"
                    height="15"
                    rx="0"
                    ry="0"
                    transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                    opacity="0"
                    filter="url(#eZaKkGJWq3o24-filter)"
                    fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eZaKkGJWq3o25_to" transform="translate(344.25,128.271297)">
                  <rect
                    id="eZaKkGJWq3o25"
                    width="10"
                    height="20"
                    rx="0"
                    ry="0"
                    transform="scale(0.5,0.5) translate(-5,-10)"
                    opacity="0"
                    fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
              </g>
              <g transform="translate(-65 105.000001)" filter="url(#eZaKkGJWq3o26-filter)">
                <path
                  id="eZaKkGJWq3o27"
                  d="M278.994002,286.5213L278.994,225.053002l.106.000002-.070999-.000002"
                  transform="translate(65-93.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZaKkGJWq3o27-stroke)"
                  strokeLinecap="round"
                />
                <g id="eZaKkGJWq3o28_to" transform="translate(344.25,128.271297)">
                  <rect
                    id="eZaKkGJWq3o28"
                    width="8.75"
                    height="15"
                    rx="0"
                    ry="0"
                    transform="scale(0.8,0.8) translate(-4.375,-7.5)"
                    opacity="0"
                    filter="url(#eZaKkGJWq3o28-filter)"
                    fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
                <g id="eZaKkGJWq3o29_to" transform="translate(344.25,128.271297)">
                  <rect
                    id="eZaKkGJWq3o29"
                    width="10"
                    height="20"
                    rx="0"
                    ry="0"
                    transform="scale(0.5,0.5) translate(-5,-10)"
                    opacity="0"
                    fill={selected === 1 ? selectedStyles.bulletColor : styles.bulletColor}
                  />
                </g>
              </g>
            </g>
            <g transform="translate(-10.999999 0)">
              <path
                d="M96.7109,218.88v-97.202l35.869585-44.145397L132.817,0.34375"
                fill="none"
                stroke="url(#eZaKkGJWq3o31-stroke)"
              />
              <g transform="translate(-64 105.000001)" filter="url(#eZaKkGJWq3o32-filter)">
                <path
                  id="eZaKkGJWq3o33"
                  d="M96.711985,302.439636L96.7109,234.987317h.001085c0,0,0,0,0,0"
                  transform="translate(64-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZaKkGJWq3o33-stroke)"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="eZaKkGJWq3o34_to" transform="translate(160.8,124.504306)">
                  <g id="eZaKkGJWq3o34_tr" transform="rotate(0)">
                    <rect
                      id="eZaKkGJWq3o34"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eZaKkGJWq3o34-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eZaKkGJWq3o35_to" transform="translate(160.8,124.504307)">
                  <g id="eZaKkGJWq3o35_tr" transform="rotate(0)">
                    <rect
                      id="eZaKkGJWq3o35"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
              <g transform="translate(-64 105.000001)" filter="url(#eZaKkGJWq3o36-filter)">
                <path
                  id="eZaKkGJWq3o37"
                  d="M96.711985,302.439636L96.7109,234.987317h.001085c0,0,0,0,0,0"
                  transform="translate(64-105.000001)"
                  opacity="0"
                  fill="none"
                  stroke="url(#eZaKkGJWq3o37-stroke)"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="eZaKkGJWq3o38_to" transform="translate(160.8,124.504306)">
                  <g id="eZaKkGJWq3o38_tr" transform="rotate(0)">
                    <rect
                      id="eZaKkGJWq3o38"
                      width="7"
                      height="12"
                      rx="0"
                      ry="0"
                      transform="translate(-3.5,-6)"
                      opacity="0"
                      filter="url(#eZaKkGJWq3o38-filter)"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
                <g id="eZaKkGJWq3o39_to" transform="translate(160.8,124.504307)">
                  <g id="eZaKkGJWq3o39_tr" transform="rotate(0)">
                    <rect
                      id="eZaKkGJWq3o39"
                      width="10"
                      height="20"
                      rx="0"
                      ry="0"
                      transform="scale(0.5,0.5) translate(-5,-10)"
                      opacity="0"
                      fill={selected === 0 ? selectedStyles.bulletColor : styles.bulletColor}
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <mask id="eZaKkGJWq3o40" type="luminance" x="-150%" y="-150%" height="400%" width="400%">
          <rect
            width="825.762291"
            height="299.51865"
            rx="0"
            ry="0"
            transform="matrix(1.071737 0 0 0.754544 0 0)"
            fill="#fff"
            strokeWidth="0"
            strokeLinejoin="round"
          />
        </mask>
      </g>
    </AnimatedSvg>
  );
};

const AnimatedSvg = styled.svg`
  #eZaKkGJWq3o9 {
    animation-name: eZaKkGJWq3o9__m, eZaKkGJWq3o9_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZaKkGJWq3o9__m {
    0% {
      d: path(
        'M96.711985,205.439636L96.7109,127.987317L96.711985,127.987317C96.711985,127.987317,96.711985,127.987317,96.711985,127.987317'
      );
    }
    1.25% {
      d: path(
        'M96.7109,195.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.711985,121.683001,96.711985,121.683001'
      );
    }
    8.75% {
      d: path(
        'M96.7109,165.000465L96.7109,121.68L111.798426,103.148788C111.798426,103.148788,111.798426,103.148788,111.798426,103.148788'
      );
    }
    16.25% {
      d: path(
        'M96.7109,121.678L96.7109,121.678L132.580485,77.671631C132.580485,77.671631,132.580485,77.67163,132.580485,77.67163'
      );
    }
    30% {
      d: path(
        'M132.580485,77.671631L132.580485,77.671631L132.580485,77.671631C132.580485,77.671631,132.580485,-3.79188,132.580485,-3.79188'
      );
    }
    100% {
      d: path(
        'M132.580485,77.671631L132.580485,77.671631L132.580485,77.671631C132.580485,77.671631,132.580485,-3.79188,132.580485,-3.79188'
      );
    }
  }
  @keyframes eZaKkGJWq3o9_c_o {
    0% {
      opacity: 0.2;
    }
    3.75% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZaKkGJWq3o10_to {
    animation: eZaKkGJWq3o10_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o10_to__to {
    0% {
      transform: translate(160.8px, 24.504306px);
    }
    1.25% {
      transform: translate(164.798427px, 11.502826px);
    }
    16.25% {
      transform: translate(193.801035px, -23.911387px);
    }
    17.5% {
      transform: translate(196.811985px, -34.712684px);
    }
    30% {
      transform: translate(196.811985px, -114.712684px);
    }
    100% {
      transform: translate(196.811985px, -114.712684px);
    }
  }
  #eZaKkGJWq3o10_tr {
    animation: eZaKkGJWq3o10_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o10_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(39deg);
    }
    16.25% {
      transform: rotate(39deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZaKkGJWq3o10 {
    animation: eZaKkGJWq3o10_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o10_c_o {
    0% {
      opacity: 0.7;
    }
    30% {
      opacity: 0.7;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZaKkGJWq3o11_to {
    animation: eZaKkGJWq3o11_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o11_to__to {
    0% {
      transform: translate(160.8px, 24.504307px);
    }
    1.25% {
      transform: translate(164.798427px, 11.502826px);
    }
    16.25% {
      transform: translate(193.801035px, -23.891881px);
    }
    17.5% {
      transform: translate(196.851035px, -34.791881px);
    }
    30% {
      transform: translate(196.851035px, -114.791881px);
    }
    100% {
      transform: translate(196.851035px, -114.791881px);
    }
  }
  #eZaKkGJWq3o11_tr {
    animation: eZaKkGJWq3o11_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o11_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    1.25% {
      transform: rotate(39deg);
    }
    16.25% {
      transform: rotate(39deg);
    }
    17.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZaKkGJWq3o11 {
    animation: eZaKkGJWq3o11_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o11_c_o {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    31.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZaKkGJWq3o13 {
    animation-name: eZaKkGJWq3o13__m, eZaKkGJWq3o13_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZaKkGJWq3o13__m {
    0% {
      d: path(
        'M96.711985,275.439636L96.7109,227.987317L96.711985,227.987317C96.711985,227.987317,96.711985,227.987317,96.711985,227.987317'
      );
    }
    35% {
      d: path(
        'M96.711985,275.439636L96.7109,227.987317L96.711985,227.987317C96.711985,227.987317,96.711985,227.987317,96.711985,227.987317'
      );
    }
    46.25% {
      d: path(
        'M96.711985,205.439636L96.7109,127.987317L96.711985,127.987317C96.711985,127.987317,96.711985,127.987317,96.711985,127.987317'
      );
    }
    47.5% {
      d: path(
        'M96.7109,195.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.711985,121.683001,96.711985,121.683001'
      );
    }
    62.5% {
      d: path(
        'M96.7109,121.678L96.7109,121.678L132.580485,77.671631C132.580485,77.671631,132.580485,77.67163,132.580485,77.67163'
      );
    }
    76.25% {
      d: path(
        'M132.580485,77.671631L132.580485,77.671631L132.580485,77.671631C132.580485,77.671631,132.580485,-3.79188,132.580485,-3.79188'
      );
    }
    100% {
      d: path(
        'M132.580485,77.671631L132.580485,77.671631L132.580485,77.671631C132.580485,77.671631,132.580485,-3.79188,132.580485,-3.79188'
      );
    }
  }
  @keyframes eZaKkGJWq3o13_c_o {
    0% {
      opacity: 0;
    }
    41.25% {
      opacity: 0;
    }
    46.25% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    76.25% {
      opacity: 1;
    }
    77.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZaKkGJWq3o14_to {
    animation: eZaKkGJWq3o14_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o14_to__to {
    0% {
      transform: translate(160.8px, 126.504306px);
    }
    35% {
      transform: translate(160.8px, 126.504306px);
    }
    46.25% {
      transform: translate(160.8px, 24.504306px);
    }
    47.5% {
      transform: translate(164.798427px, 11.502826px);
    }
    62.5% {
      transform: translate(193.801035px, -23.911387px);
    }
    63.75% {
      transform: translate(196.811985px, -34.712684px);
    }
    76.25% {
      transform: translate(196.811985px, -114.712684px);
    }
    100% {
      transform: translate(196.811985px, -114.712684px);
    }
  }
  #eZaKkGJWq3o14_tr {
    animation: eZaKkGJWq3o14_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o14_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    46.25% {
      transform: rotate(0deg);
    }
    47.5% {
      transform: rotate(39deg);
    }
    62.5% {
      transform: rotate(39deg);
    }
    63.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZaKkGJWq3o14 {
    animation: eZaKkGJWq3o14_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o14_c_o {
    0% {
      opacity: 0;
    }
    37.5% {
      opacity: 0;
    }
    46.25% {
      opacity: 0.7;
    }
    76.25% {
      opacity: 0.7;
    }
    77.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZaKkGJWq3o15_to {
    animation: eZaKkGJWq3o15_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o15_to__to {
    0% {
      transform: translate(160.8px, 126.504307px);
    }
    35% {
      transform: translate(160.8px, 126.504307px);
    }
    46.25% {
      transform: translate(160.8px, 24.504307px);
    }
    47.5% {
      transform: translate(164.798427px, 11.502826px);
    }
    62.5% {
      transform: translate(193.801035px, -23.891881px);
    }
    63.75% {
      transform: translate(196.851035px, -34.791881px);
    }
    76.25% {
      transform: translate(196.851035px, -114.791881px);
    }
    100% {
      transform: translate(196.851035px, -114.791881px);
    }
  }
  #eZaKkGJWq3o15_tr {
    animation: eZaKkGJWq3o15_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o15_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    46.25% {
      transform: rotate(0deg);
    }
    47.5% {
      transform: rotate(39deg);
    }
    62.5% {
      transform: rotate(39deg);
    }
    63.75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZaKkGJWq3o15 {
    animation: eZaKkGJWq3o15_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o15_c_o {
    0% {
      opacity: 0;
    }
    37.5% {
      opacity: 0;
    }
    46.25% {
      opacity: 1;
    }
    76.25% {
      opacity: 1;
    }
    77.5% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZaKkGJWq3o17 {
    animation-name: eZaKkGJWq3o17__m, eZaKkGJWq3o17_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZaKkGJWq3o17__m {
    0% {
      d: path(
        'M96.711985,275.439636L96.7109,227.987317L96.711985,227.987317C96.711985,227.987317,96.711985,227.987317,96.711985,227.987317'
      );
    }
    88.75% {
      d: path(
        'M96.711985,275.439636L96.7109,227.987317L96.711985,227.987317C96.711985,227.987317,96.711985,227.987317,96.711985,227.987317'
      );
    }
    100% {
      d: path(
        'M96.711985,205.439636L96.7109,127.987317L96.711985,127.987317C96.711985,127.987317,96.711985,127.987317,96.711985,127.987317'
      );
    }
  }
  @keyframes eZaKkGJWq3o17_c_o {
    0% {
      opacity: 0;
    }
    95% {
      opacity: 0;
    }
    100% {
      opacity: 0.2;
    }
  }
  #eZaKkGJWq3o18_to {
    animation: eZaKkGJWq3o18_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o18_to__to {
    0% {
      transform: translate(160.8px, 126.504306px);
    }
    88.75% {
      transform: translate(160.8px, 126.504306px);
    }
    100% {
      transform: translate(160.8px, 24.504306px);
    }
  }
  #eZaKkGJWq3o18 {
    animation: eZaKkGJWq3o18_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o18_c_o {
    0% {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    100% {
      opacity: 0.7;
    }
  }
  #eZaKkGJWq3o19_to {
    animation: eZaKkGJWq3o19_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o19_to__to {
    0% {
      transform: translate(160.8px, 126.504307px);
    }
    88.75% {
      transform: translate(160.8px, 126.504307px);
    }
    100% {
      transform: translate(160.8px, 24.504307px);
    }
  }
  #eZaKkGJWq3o19 {
    animation: eZaKkGJWq3o19_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o19_c_o {
    0% {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  #eZaKkGJWq3o23 {
    animation-name: eZaKkGJWq3o23__m, eZaKkGJWq3o23_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZaKkGJWq3o23__m {
    0% {
      d: path('M278.994002,286.5213L278.994,225.053002L279.1,225.053004L279.029001,225.053002');
    }
    11.25% {
      d: path('M278.994002,286.5213L278.994,225.053002L279.1,225.053004L279.029001,225.053002');
    }
    53.75% {
      d: path('M278.994002,65.5213L278.994,-23.946998L279.1,-23.946996L279.029001,-23.946998');
    }
    100% {
      d: path('M278.994002,65.5213L278.994,-23.946998L279.1,-23.946996L279.029001,-23.946998');
    }
  }
  @keyframes eZaKkGJWq3o23_c_o {
    0% {
      opacity: 0;
    }
    17.5% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    56.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZaKkGJWq3o24_to {
    animation: eZaKkGJWq3o24_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o24_to__to {
    0% {
      transform: translate(344.25px, 128.271297px);
    }
    11.25% {
      transform: translate(344.25px, 128.271297px);
    }
    53.75% {
      transform: translate(344.25px, -117.728703px);
    }
    100% {
      transform: translate(344.25px, -117.728703px);
    }
  }
  #eZaKkGJWq3o24 {
    animation: eZaKkGJWq3o24_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o24_c_o {
    0% {
      opacity: 0;
    }
    13.75% {
      opacity: 0;
    }
    18.75% {
      opacity: 0.7;
    }
    53.75% {
      opacity: 0.7;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZaKkGJWq3o25_to {
    animation: eZaKkGJWq3o25_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o25_to__to {
    0% {
      transform: translate(344.25px, 128.271297px);
    }
    11.25% {
      transform: translate(344.25px, 128.271297px);
    }
    53.75% {
      transform: translate(344.25px, -117.728703px);
    }
    100% {
      transform: translate(344.25px, -117.728703px);
    }
  }
  #eZaKkGJWq3o25 {
    animation: eZaKkGJWq3o25_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o25_c_o {
    0% {
      opacity: 0;
    }
    13.75% {
      opacity: 0;
    }
    18.75% {
      opacity: 1;
    }
    53.75% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZaKkGJWq3o27 {
    animation-name: eZaKkGJWq3o27__m, eZaKkGJWq3o27_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZaKkGJWq3o27__m {
    0% {
      d: path('M278.994002,286.5213L278.994,225.053002L279.1,225.053004L279.029001,225.053002');
    }
    55% {
      d: path('M278.994002,286.5213L278.994,225.053002L279.1,225.053004L279.029001,225.053002');
    }
    97.5% {
      d: path('M278.994002,65.5213L278.994,-23.946998L279.1,-23.946996L279.029001,-23.946998');
    }
    100% {
      d: path('M278.994002,65.5213L278.994,-23.946998L279.1,-23.946996L279.029001,-23.946998');
    }
  }
  @keyframes eZaKkGJWq3o27_c_o {
    0% {
      opacity: 0;
    }
    61.25% {
      opacity: 0;
    }
    68.75% {
      opacity: 1;
    }
    93.75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #eZaKkGJWq3o28_to {
    animation: eZaKkGJWq3o28_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o28_to__to {
    0% {
      transform: translate(344.25px, 128.271297px);
    }
    55% {
      transform: translate(344.25px, 128.271297px);
    }
    97.5% {
      transform: translate(344.25px, -117.728703px);
    }
    100% {
      transform: translate(344.25px, -117.728703px);
    }
  }
  #eZaKkGJWq3o28 {
    animation: eZaKkGJWq3o28_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o28_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    62.5% {
      opacity: 0.7;
    }
    97.5% {
      opacity: 0.7;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZaKkGJWq3o29_to {
    animation: eZaKkGJWq3o29_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o29_to__to {
    0% {
      transform: translate(344.25px, 128.271297px);
    }
    55% {
      transform: translate(344.25px, 128.271297px);
    }
    97.5% {
      transform: translate(344.25px, -117.728703px);
    }
    100% {
      transform: translate(344.25px, -117.728703px);
    }
  }
  #eZaKkGJWq3o29 {
    animation: eZaKkGJWq3o29_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o29_c_o {
    0% {
      opacity: 0;
    }
    57.5% {
      opacity: 0;
    }
    62.5% {
      opacity: 1;
    }
    97.5% {
      opacity: 1;
    }
    98.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZaKkGJWq3o33 {
    animation-name: eZaKkGJWq3o33__m, eZaKkGJWq3o33_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZaKkGJWq3o33__m {
    0% {
      d: path(
        'M96.711985,302.439636L96.7109,234.987317L96.711985,234.987317C96.711985,234.987317,96.711985,234.987317,96.711985,234.987317'
      );
    }
    6.25% {
      d: path(
        'M96.711985,302.439636L96.7109,234.987317L96.711985,234.987317C96.711985,234.987317,96.711985,234.987317,96.711985,234.987317'
      );
    }
    20% {
      d: path(
        'M96.711985,195.439636L96.7109,127.987317L96.711985,127.987317C96.711985,127.987317,96.711985,127.987317,96.711985,127.987317'
      );
    }
    21.25% {
      d: path(
        'M96.7109,175.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.711985,121.683001,96.711985,121.683001'
      );
    }
    36.25% {
      d: path(
        'M96.7109,121.678L96.7109,121.678L132.580485,77.671631C132.580485,77.671631,132.580485,77.67163,132.580485,77.67163'
      );
    }
    50% {
      d: path(
        'M132.580485,77.671631L132.580485,77.671631L132.580485,77.671631C132.580485,77.671631,132.580485,-3.79188,132.580485,-3.79188'
      );
    }
    100% {
      d: path(
        'M132.580485,77.671631L132.580485,77.671631L132.580485,77.671631C132.580485,77.671631,132.580485,-3.79188,132.580485,-3.79188'
      );
    }
  }
  @keyframes eZaKkGJWq3o33_c_o {
    0% {
      opacity: 0;
    }
    6.25% {
      opacity: 0;
    }
    11.25% {
      opacity: 0;
    }
    16.25% {
      opacity: 0.3;
    }
    21.25% {
      opacity: 1;
    }
    47.5% {
      opacity: 1;
    }
    53.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZaKkGJWq3o34_to {
    animation: eZaKkGJWq3o34_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o34_to__to {
    0% {
      transform: translate(160.8px, 124.504306px);
    }
    7.5% {
      transform: translate(160.8px, 124.504306px);
    }
    20% {
      transform: translate(160.8px, 24.504306px);
    }
    21.25% {
      transform: translate(164.798427px, 11.502826px);
    }
    36.25% {
      transform: translate(193.801035px, -23.911387px);
    }
    37.5% {
      transform: translate(196.811985px, -34.712684px);
    }
    50% {
      transform: translate(196.811985px, -114.712684px);
    }
    100% {
      transform: translate(196.811985px, -114.712684px);
    }
  }
  #eZaKkGJWq3o34_tr {
    animation: eZaKkGJWq3o34_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o34_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(39deg);
    }
    36.25% {
      transform: rotate(39deg);
    }
    37.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZaKkGJWq3o34 {
    animation: eZaKkGJWq3o34_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o34_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    15% {
      opacity: 0.7;
    }
    20% {
      opacity: 0.7;
    }
    50% {
      opacity: 0.7;
    }
    51.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZaKkGJWq3o35_to {
    animation: eZaKkGJWq3o35_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o35_to__to {
    0% {
      transform: translate(160.8px, 124.504307px);
    }
    7.5% {
      transform: translate(160.8px, 124.504307px);
    }
    20% {
      transform: translate(160.8px, 24.504307px);
    }
    21.25% {
      transform: translate(164.798427px, 11.502826px);
    }
    36.25% {
      transform: translate(193.801035px, -23.891881px);
    }
    37.5% {
      transform: translate(196.851035px, -34.791881px);
    }
    50% {
      transform: translate(196.851035px, -114.791881px);
    }
    100% {
      transform: translate(196.851035px, -114.791881px);
    }
  }
  #eZaKkGJWq3o35_tr {
    animation: eZaKkGJWq3o35_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o35_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(0deg);
    }
    21.25% {
      transform: rotate(39deg);
    }
    36.25% {
      transform: rotate(39deg);
    }
    37.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZaKkGJWq3o35 {
    animation: eZaKkGJWq3o35_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o35_c_o {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    15% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    51.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZaKkGJWq3o37 {
    animation-name: eZaKkGJWq3o37__m, eZaKkGJWq3o37_c_o;
    animation-duration: 8000ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  @keyframes eZaKkGJWq3o37__m {
    0% {
      d: path(
        'M96.711985,302.439636L96.7109,234.987317L96.711985,234.987317C96.711985,234.987317,96.711985,234.987317,96.711985,234.987317'
      );
    }
    46.25% {
      d: path(
        'M96.711985,302.439636L96.7109,234.987317L96.711985,234.987317C96.711985,234.987317,96.711985,234.987317,96.711985,234.987317'
      );
    }
    60% {
      d: path(
        'M96.711985,195.439636L96.7109,127.987317L96.711985,127.987317C96.711985,127.987317,96.711985,127.987317,96.711985,127.987317'
      );
    }
    61.25% {
      d: path(
        'M96.7109,175.771298L96.7109,121.68L96.798427,121.683001C96.798427,121.683001,96.711985,121.683001,96.711985,121.683001'
      );
    }
    76.25% {
      d: path(
        'M96.7109,121.678L96.7109,121.678L132.580485,77.671631C132.580485,77.671631,132.580485,77.67163,132.580485,77.67163'
      );
    }
    90% {
      d: path(
        'M132.580485,77.671631L132.580485,77.671631L132.580485,77.671631C132.580485,77.671631,132.580485,-3.79188,132.580485,-3.79188'
      );
    }
    100% {
      d: path(
        'M132.580485,77.671631L132.580485,77.671631L132.580485,77.671631C132.580485,77.671631,132.580485,-3.79188,132.580485,-3.79188'
      );
    }
  }
  @keyframes eZaKkGJWq3o37_c_o {
    0% {
      opacity: 0;
    }
    46.25% {
      opacity: 0;
    }
    51.25% {
      opacity: 0;
    }
    56.25% {
      opacity: 0.3;
    }
    61.25% {
      opacity: 1;
    }
    87.5% {
      opacity: 1;
    }
    93.75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZaKkGJWq3o38_to {
    animation: eZaKkGJWq3o38_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o38_to__to {
    0% {
      transform: translate(160.8px, 124.504306px);
    }
    47.5% {
      transform: translate(160.8px, 124.504306px);
    }
    60% {
      transform: translate(160.8px, 24.504306px);
    }
    61.25% {
      transform: translate(164.798427px, 11.502826px);
    }
    76.25% {
      transform: translate(193.801035px, -23.911387px);
    }
    77.5% {
      transform: translate(196.811985px, -34.712684px);
    }
    90% {
      transform: translate(196.811985px, -114.712684px);
    }
    100% {
      transform: translate(196.811985px, -114.712684px);
    }
  }
  #eZaKkGJWq3o38_tr {
    animation: eZaKkGJWq3o38_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o38_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(0deg);
    }
    61.25% {
      transform: rotate(39deg);
    }
    76.25% {
      transform: rotate(39deg);
    }
    77.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZaKkGJWq3o38 {
    animation: eZaKkGJWq3o38_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o38_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    55% {
      opacity: 0.7;
    }
    60% {
      opacity: 0.7;
    }
    90% {
      opacity: 0.7;
    }
    91.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  #eZaKkGJWq3o39_to {
    animation: eZaKkGJWq3o39_to__to 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o39_to__to {
    0% {
      transform: translate(160.8px, 124.504307px);
    }
    47.5% {
      transform: translate(160.8px, 124.504307px);
    }
    60% {
      transform: translate(160.8px, 24.504307px);
    }
    61.25% {
      transform: translate(164.798427px, 11.502826px);
    }
    76.25% {
      transform: translate(193.801035px, -23.891881px);
    }
    77.5% {
      transform: translate(196.851035px, -34.791881px);
    }
    90% {
      transform: translate(196.851035px, -114.791881px);
    }
    100% {
      transform: translate(196.851035px, -114.791881px);
    }
  }
  #eZaKkGJWq3o39_tr {
    animation: eZaKkGJWq3o39_tr__tr 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o39_tr__tr {
    0% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(0deg);
    }
    61.25% {
      transform: rotate(39deg);
    }
    76.25% {
      transform: rotate(39deg);
    }
    77.5% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #eZaKkGJWq3o39 {
    animation: eZaKkGJWq3o39_c_o 8000ms linear infinite normal forwards;
  }
  @keyframes eZaKkGJWq3o39_c_o {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    55% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    91.25% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default FeedSourcesMobileBg3;
